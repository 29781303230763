import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { DATATYPE } from '../../../const/const';
import { OPERATOR_CUSTOM, OPERATOR_DETAULT } from '../../../const/operator-custom';
import { OperatorCustom } from '../../../models/operator-custom';
import { DefaultValueSettingRO } from '../../../models/response/default-value-setting.ro';
import { LazyTreeNode, SearchParams, TreeNode } from '../../../models/common-model';
import { BUTTON, COMMON_TEXT } from '../../../const/text-common';
import { v4 } from 'uuid';
import { DefaultValueSettingService } from '../../../services/modules/default-value-setting.service';
import { DefaultValueSettingDTO } from '../../../models/request/default-value-setting.dto';
import { SearchType } from '../../../enum/common-enum';
import Utils from '../../../util/utils';
import {cloneDeep} from 'lodash';

@Component({
  selector: 'pivot-calculator',
  templateUrl: './calculator.component.html',
  styleUrls: ['./calculator.component.scss']
})
export class CalculatorComponent implements OnInit {
  // input set style css for common
  @Input() cssStyle: any = {};
  @Input() dataType: any;
  @Input() isDataSource: boolean = false;
  @Input() hasDefaultFuntion: boolean = false;
  // event submit data
  @Output() onClickButton = new EventEmitter<any>();
  @ViewChild('default', { static: false }) defaultPopup: any;
  @Input() defaultValueSetting: DefaultValueSettingRO[] = [];
  nodeClicked: LazyTreeNode;
  items: any[] = [{label: '+'},{label: '-'},{label: 'x'},{label: '/'},{label: '('},{label: ')'}];
  itemDefault: any[] = [{label: BUTTON.DEFAULT}]; 

  itemsCustom: any[] = []

  treeViewList:LazyTreeNode [] = [];
  //treeViewListTreeLazy:LazyTreeNode [] = [];
  treeViewNode: TreeNode[] = [];
  originTreeNode: TreeNode[] = [];
  lazyComposedData: LazyTreeNode[] = [];
  DATATYPE = DATATYPE;
  scrollHeight = 300;

  // Search bar config
  searchParams: SearchParams = {
    type: SearchType.input,
    placeholder: COMMON_TEXT.CONSTANT_SEARCH,
    selfReset: false,
    cssStyle: { width: '100%', height: '40px', 'font-size': '14px'  },
    readonly: false,
    disabled: false
  }

  // Current search keyword at dialog constant selection
  currentKeyword: string = "";
  constructor(private defaultValueSettingService: DefaultValueSettingService ) {

    this.itemsCustom =[];
    OPERATOR_CUSTOM.map((op:OperatorCustom)=>{
      this.itemsCustom.push({
        label: op.name,
        hover: op.hover,
      })
    })
   }

  ngOnInit(): void {
    if(this.hasDefaultFuntion && !this.items.find((item: any) => item.label == BUTTON.DEFAULT)) {
      this.items.push({label:  BUTTON.DEFAULT})
    }
    this.treeViewNode = this.generateDefaulValueSettingDataTree();
    this.originTreeNode = cloneDeep(this.treeViewNode);
    this.treeViewList = this.updateLazyTree(this.treeViewNode);
  }

  // event click button
  buttonClick(event: any, data: any) {
    if(data == BUTTON.DEFAULT) { 
      // Clear search keyword when close/open dialog
      this.currentKeyword = "";
      this.searchParams.selfReset = true;
      this.searchParams = cloneDeep(this.searchParams);
      this.reMapTreeView();
      this.defaultPopup.toggle(event);
    } else {
      this.onClickButton.emit(data);
    }
  }

  generateDefaulValueSettingDataTree(): TreeNode[]
  {
    let rootNodes: TreeNode[] = []
    if(!this.defaultValueSetting || this.defaultValueSetting.length == 0) return rootNodes;

    this.defaultValueSetting.forEach((df : DefaultValueSettingRO) => {
      if(df.defaultValueSetting) {
        let rootNode = new TreeNode();
        rootNode.id = df.defaultValueSetting.id
        rootNode.label = df.defaultValueSetting.defaultvaluesettingname
        rootNode.isShowExpandIcon = true;
        rootNode.isShowIcon = true;
        rootNode.data = [df.defaultValueSetting];
        rootNode.nodes = [];
        if(df.listDefaultValueSettingDetail && df.listDefaultValueSettingDetail.length > 0){
          rootNode.isLoaded = false;
          let nodeDetail = new TreeNode();
          nodeDetail.id = v4().toString();
          nodeDetail.label = '';
          nodeDetail.isShowExpandIcon = true;
          nodeDetail.isShowIcon = true;
          nodeDetail.data = [];
          nodeDetail.nodes = [];
          nodeDetail.isLoaded = false
          rootNode.nodes?.push(nodeDetail);

        }else{
          let nodeDetail = this.generateEmptyNode();
          rootNode.isLoaded = true
          rootNode.nodes?.push(nodeDetail);
        }
        rootNodes.push(rootNode);
      }
    })
    return rootNodes;
  }
  updateLazyTree(nodes?: TreeNode[]) {
    if(nodes && nodes.length) {
      this.lazyComposedData = [];
      this.serializeNodes(nodes!, 0, undefined, true);
      this.scrollHeight = (this.lazyComposedData.length*27 + 10);
      if(this.scrollHeight > 300) this.scrollHeight = 300;
      return [...this.lazyComposedData];
    }
    else return [];
  }
  serializeNodes(nodes: TreeNode[], level: number, parent?: LazyTreeNode, showIcon?: boolean) {
    if (nodes && nodes.length) {
        for (const [index,node] of nodes.entries()) {
            const rowNode = new LazyTreeNode();
            rowNode.parent = parent;
            rowNode.id = node.id;
            rowNode.level = level;
            rowNode.isFolder = (level == 0);
            rowNode.text = node.label ?? '';
            rowNode.tooltip = node.tooltip?.toString() || '';
            rowNode.expandable = Boolean(node.nodes && nodes.length);
            rowNode.node = node;
            rowNode.draggable = node.draggable;
            rowNode.style = node.style;
            rowNode.icon = node.icon;
            rowNode.loading = !(node.isLoaded ?? true);
            if (!rowNode.parent) {
              rowNode.isFirstNode = true;
            }

            if (!node.nodes || node.nodes.length === 0) {
              rowNode.isLastNode = true;
              rowNode.showIcon = showIcon
            }
            rowNode.isLastChild  = (index == (nodes.length -1));
            rowNode.isParentLastChild = level <= 1 ? [] : ([...(parent?.isParentLastChild??[]), (parent?.isLastChild ?? true)]);
            this.lazyComposedData.push(rowNode);

            if (node.nodes && node.expanded) {
              rowNode.expanded = node.expanded;
              this.serializeNodes(node.nodes, level + 1, rowNode, showIcon);
            }
        }
    }
  }
  onClickItag(node:LazyTreeNode){
    if(node.node?.data && (node.node?.data.length > 0) && node.node?.data[0].itag){
      this.defaultPopup.hide();
      this.onClickButton.emit(`${OPERATOR_DETAULT.name}(${node.node?.data[0].defaultvaluesetting.defaultvaluesettingcd};"${node.node?.data[0].kbntag}";"${node.node?.data[0].ttltag}";"${node.node?.data[0].itag}";"${node.node?.data[0].defaultvaluesetting.defaultvaluesettingname}")`);
    }
  }
  handleItemClick(node: LazyTreeNode){
    if(node.id === "no-data-node") return;
    if(node.isFolder && node.loading)
    {
      this.loadTitleTag(node.id ?? "");
    }
    this.nodeClicked = node;
    this.treeViewList = this.updateLazyTree(this.treeViewNode);
  }

  loadTitleTag(dfId: string){

    let dfClick = this.defaultValueSetting.find((df: DefaultValueSettingRO) => df.defaultValueSetting?.id == dfId);
    if (dfClick) {
      let defaultValueSettingRequest:DefaultValueSettingDTO = {
        defaultvaluesetting: dfClick.defaultValueSetting,
        listdefaultvaluesettingdetail: dfClick.listDefaultValueSettingDetail,
      };
      this.defaultValueSettingService.getTagByDf(defaultValueSettingRequest).then(response => {
        if (response.statuscode == 200) {
          let id = response.data.defaultvaluesetting.id;
          let nodeUpdate = this.treeViewNode.find((node: TreeNode) => node.id == id);
          if (nodeUpdate) {
            nodeUpdate.isLoaded = true;
            nodeUpdate.nodes = [];
            response.data.listdefaultvaluesettingdetail.forEach((dfDetail: any)=>{
                let nodeDetail = new TreeNode();
                nodeDetail.id = dfDetail.id
                nodeDetail.label = `${dfDetail.kbntag} - ${dfDetail.ttltag}`
                nodeDetail.isShowExpandIcon = true;
                nodeDetail.isShowIcon = true;
                nodeDetail.data = [dfDetail];
                nodeDetail.nodes = [];
                nodeDetail.isLoaded = true;
                if (dfDetail.listitag && dfDetail.listitag.length > 0) {
                  dfDetail.listitag.forEach((itag: any) => {
                    let nodeTag = new TreeNode();
                    nodeTag.id = v4().toString();
                    nodeTag.label = itag.itag
                    nodeTag.isShowExpandIcon = true;
                    nodeTag.isShowIcon = true;
                    itag.defaultvaluesetting = response.data.defaultvaluesetting;
                    itag.defaultvaluesettingdetail = dfDetail;
                    nodeTag.data = [itag];
                    nodeTag.nodes = [];
                    nodeTag.isLoaded = true;
                    nodeTag.isLastNode = true;
                    nodeDetail.nodes?.push(nodeTag);
                  });
                } else {
                  let nodeTag = this.generateEmptyNode();
                  nodeTag.isLoaded = true;
                  nodeTag.isLastNode = true;
                  nodeDetail.nodes?.push(nodeTag);
                }
                
                if(nodeUpdate?.nodes)
                  nodeUpdate.nodes.push(nodeDetail);
            });

          }
          this.originTreeNode = cloneDeep(this.treeViewNode);
          this.treeViewList =  this.updateLazyTree(this.treeViewNode);

        }
      })
    }
  }

  generateEmptyNode() {
    let emptyNode = new TreeNode();
    emptyNode.id = v4().toString();
    emptyNode.label = COMMON_TEXT.EMPTY_NODE;
    emptyNode.isShowExpandIcon = false;
    emptyNode.isShowIcon = false;
    emptyNode.draggable = false;
    emptyNode.isLoaded = true
    return emptyNode;
  }

  // Handle for constant value filtering 
  async filterConstantVal(event: any) {
    // Clean keyword first
    let cleanKeyword = event ? event.trim().toUpperCase() : "" ;
    
    // Keyword is null => Change to default list
    if(Utils.isNullOrEmpty(event)) {
      this.currentKeyword = "";
      this.reMapTreeView();
      return;
    }

    // The same with current keyword
    if(cleanKeyword === this.currentKeyword) return;

    // Load tree async
    await this.syncConstantTreeWithKeyword(cleanKeyword);

    // Handle searching
    this.treeViewNode = this.searchNodeRecursion(this.treeViewNode, cleanKeyword);
    this.treeViewList = this.updateLazyTree(this.treeViewNode);
    if(this.treeViewList.length == 0) {
      this.addNoDataNode();
    }
    // Hold current keyword
    this.currentKeyword = cleanKeyword;
  }

  clearTreeViewNode() {
    const emptyNode = new LazyTreeNode();
    emptyNode.loading = true;
    this.treeViewList = [ emptyNode ];
  }

  addNoDataNode() {
    const noDataNode = new LazyTreeNode();
    noDataNode.id = "no-data-node"
    noDataNode.text = COMMON_TEXT.NO_DATA;
    noDataNode.loading = true;
    noDataNode.isShowNoData = true;
    this.treeViewList = [ noDataNode ];
  }

  async syncConstantTreeWithKeyword(keyword: string) {
    // If all of tree's node has loaded, skip this step
    const isAllLoaded: boolean = this.treeLoadingStatusChecking(this.originTreeNode);
    if(isAllLoaded) {
      this.reMapTreeView();
      return;
    }

    // Preparing before search
    this.clearTreeViewNode();
    var syncData = await this.defaultValueSettingService.searchByKeyword(keyword);

    if(syncData.statuscode === 200 && syncData?.data.length) {
      syncData.data.forEach((defVal: any) => this.mapNewNode(defVal));
      this.reMapTreeView();
    }
  }

  treeLoadingStatusChecking(treeView: TreeNode[] | undefined) {
    if (!treeView || treeView.length === 0) return true;

    for (const node of treeView) {
        if (!node.isLoaded || !this.treeLoadingStatusChecking(node.nodes)) {
            return false;
        }
    }
    return true;
  }

  reMapTreeView() {
    this.treeViewNode = cloneDeep(this.originTreeNode);
    this.treeViewList = this.updateLazyTree(this.treeViewNode);
  }

  searchNodeRecursion(currentList: TreeNode[] | undefined, keyword: string): TreeNode[] {
    if (!currentList || currentList.length === 0) return [];
    
    return currentList
        .map(node => {
            const filteredNodes = this.searchNodeRecursion(node.nodes, keyword);

            const isLabelMatch = node.label?.toUpperCase().includes(keyword);
            const isLabelExactMatch = node.label?.toUpperCase() === keyword;

            // Last node and includes keyword
            if(node.isLastNode) {
              return isLabelMatch ? { ...node, nodes: filteredNodes } : null;
            }

            // Paren node but not has any node includes keyword
            if (filteredNodes.length === 0) {
              // Check if parent is the same with keyword
              return isLabelExactMatch ? { ...node } : null;
          }

            return { ...node, nodes: filteredNodes };
        }).filter(node => node !== null) as TreeNode[];
  }

  mapNewNode(data: any) {
    let id = data.defaultvaluesetting.id;
    let nodeUpdate = this.originTreeNode.find((node: TreeNode) => node.id == id);
    if (nodeUpdate) {
      nodeUpdate.isLoaded = true;
      nodeUpdate.nodes = [];
      data.listdefaultvaluesettingdetail.forEach((dfDetail: any)=>{
          let nodeDetail = new TreeNode();
          nodeDetail.id = dfDetail.id
          nodeDetail.label = `${dfDetail.kbntag} - ${dfDetail.ttltag}`
          nodeDetail.isShowExpandIcon = true;
          nodeDetail.isShowIcon = true;
          nodeDetail.data = [dfDetail];
          nodeDetail.nodes = [];
          nodeDetail.isLoaded = true;
          if (dfDetail.listitag && dfDetail.listitag.length > 0) {
            dfDetail.listitag.forEach((itag: any) => {
              let nodeTag = new TreeNode();
              nodeTag.id = v4().toString();
              nodeTag.label = itag.itag
              nodeTag.isShowExpandIcon = true;
              nodeTag.isShowIcon = true;
              itag.defaultvaluesetting = data.defaultvaluesetting;
              itag.defaultvaluesettingdetail = dfDetail;
              nodeTag.data = [itag];
              nodeTag.nodes = [];
              nodeTag.isLoaded = true;
              nodeTag.isLastNode = true;
              nodeDetail.nodes?.push(nodeTag);
            });
          } else {
            let nodeTag = this.generateEmptyNode();
            nodeTag.isLoaded = true;
            nodeTag.isLastNode = true;
            nodeDetail.nodes?.push(nodeTag);
          }
          
          if(nodeUpdate?.nodes)
            nodeUpdate.nodes.push(nodeDetail);
      });

    }
  }
}
