<div class="modal">
  <div class="modal-body" [ngClass]="containsHyperlinkButton(modalParam?.buttonArray) ? 'hyperlink' : ''">
    <div *ngIf="modalParam?.icon" class="icon" [ngStyle]="{'background-color': modalParam?.icon?.iconColor }"
      [ngClass]="{'small': modalParam?.icon?.isSmallSize}">
      <i class="pi " [ngClass]="modalParam?.icon?.iconName"></i>
    </div>
    <div *ngIf="!modalParam?.icon">
      <img class="dlg-icon" src="../../../assets/icons/exclamation-mark-blue.svg">
    </div>
    <div class="description">
      {{modalParam?.description}}
    </div>
  </div>
  <div class="modal-bottom" [ngClass]="containsHyperlinkButton(modalParam?.buttonArray) ? 'hyperlink' : ''" *ngIf="modalParam?.buttonArray">
    <pivot-text-button *ngFor="let button of modalParam?.buttonArray"
      [iconType]="button.iconType" [buttonText]="button.buttonText" [buttonType]="button.buttonType"
      [disabled]="button.disabled" (click)="handleClickEvent(button.clickFunction)"
    ></pivot-text-button>
  </div>
</div>
