import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { conditionItemModel } from 'src/app/models/zaitaku-model';
import { ZaitakuService } from 'src/app/services/modules/zaitaku.service';

@Component({
  selector: 'pivot-auto-complete-item',
  templateUrl: './auto-complete-item.component.html',
  styleUrls: ['./auto-complete-item.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AutoCompleteItemComponent implements OnInit, OnChanges {

  @Input() data: string[];
  @Input() selectedItem: string = "";
  @Input() officecd: string = "";
  @Input() keyChangeItem: string = "";
  @Input() conditionItem: conditionItemModel = new conditionItemModel();
  @Output() onChangeItemValue = new EventEmitter<string>();

  filteredItems: string[];
  fullText: string = "";
  loading: boolean = false;
  isFetchedData: boolean = false;

  constructor(private zaitakuService: ZaitakuService,) { 
    
  }

  ngOnInit(): void {
    this.filteredItems = [...this.data];
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes && changes["keyChangeItem"]) {
      let key = changes["keyChangeItem"];
      if (key && key.currentValue) {
        if(!key.previousValue || key.previousValue != key.currentValue) {
          this.getItemValuesFromCkappTitle();
        }
      }

    }
  }

  async onSearch(event: any) {
    if(!this.loading && !this.isFetchedData) await this.getItemValuesFromCkappTitle();

    const query = event.query.toLowerCase();
    const lastPart = query.split(';').pop().trim();
    if (!lastPart.length) {
      this.filteredItems = [...this.data];
    } else {
      this.filteredItems = this.data.filter(item => item.toLowerCase().includes(lastPart));
    }
  }

  onSelect(event: any) {
    if (!event) return;
    const selectedValue = event;
    if (this.fullText == "") {
      this.fullText = selectedValue;
    } else {
      if (this.fullText.trim().endsWith(";")) {
        this.fullText += selectedValue;
      } else {
        let tmpText = this.fullText.substring(0, this.fullText.lastIndexOf(';'))
        this.fullText = tmpText == "" ? selectedValue : `${tmpText};${selectedValue}`;
      }
    }

    this.selectedItem = this.fullText;
    this.onChangeItemValue.emit(this.selectedItem);
  }

  onKeyUp(event: any) {
    this.fullText = event?.target?.value ?? "";
    this.selectedItem = this.fullText;
    this.onChangeItemValue.emit(this.selectedItem);

  }

  async getItemValuesFromCkappTitle() {
    this.isFetchedData = false;
    this.loading = true;

    if (this.conditionItem.ttlcd && this.conditionItem.ttlcd && this.conditionItem.itemid) {
     this.data = await this.zaitakuService.getItemValuesByCondition(this.officecd, this.conditionItem.ttlkbn, this.conditionItem.ttlcd, this.conditionItem.itemid);
    } else {
      this.data = [];
    }
    this.isFetchedData = true;
    this.loading = false;
  }
  
  addPanelClass(className: string) {
    const panels = document.querySelectorAll('.p-autocomplete-panel');
    if (panels.length > 0) {
      panels[panels.length - 1].classList.add(className);
    }
  }
}
