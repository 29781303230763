import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { BASIC_INFO } from '../../../../app/const/text-common';
import { ModalTemplate } from '../../../../app/models/common-model';
import Utils from '../../../../app/util/utils';

@Component({
  selector: 'pivot-dialog-avatar-upload-information',
  templateUrl: './dialog-avatar-upload-information.component.html',
  styleUrls: ['./dialog-avatar-upload-information.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class DialogAvatarUploadInformationComponent implements OnInit {

  @Input() isDisplayModal: boolean = false;
  @Input() modalData: ModalTemplate = new ModalTemplate();
  @Output() onSubmitData: any = new EventEmitter<any>();

  BASIC_INFO = BASIC_INFO;
  name: string = '';

  constructor() { }

  ngOnChanges(): void {
    this.setDataforDialog();
  }

  ngOnInit(): void {
    this.setDataforDialog();
  }

  onClose() {
    this.onSubmitData.emit(null);
    this.isDisplayModal = false;
  }

  onInputName(data: any) {
    this.name = data.currentTarget?.value;
  }



  setDataforDialog() {
    if (Utils.isNullOrEmpty(this.modalData.style?.width)) {
      this.modalData.style.width = '400px';
    }
  }

}
