import { Injectable } from "@angular/core";
import { Subject } from "rxjs";

@Injectable()
export class SidebarExpandHandleService {
    private handleSidebarExpand = new Subject<string>();

    newEvent(event: any) {
        this.handleSidebarExpand.next(event);
    }

    get events$() {
        return this.handleSidebarExpand.asObservable();
    }
}