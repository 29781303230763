
import { AfterViewInit, Component, ElementRef, EventEmitter, OnDestroy, OnInit, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import * as moment from 'moment';
import { takeUntil } from 'rxjs';
import { DataType, DateFormat } from 'src/app/enum/common-enum';
import { SharedDataService } from 'src/app/services/share-data.service';
import { HeaderItem } from '../../../models/table-model';
import { BaseComponent } from '../base.component';
import { beautifyPrvHour } from '../../../_helper/helper';

@Component({
  selector: 'pivot-table-widget-setting',
  templateUrl: './table-widget-setting.component.html',
  styleUrls: ['./table-widget-setting.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class TableWidgetSettingComponent extends BaseComponent implements OnInit, AfterViewInit, OnDestroy {

  @ViewChild('table') table: ElementRef;

  @Output() loadMoreData = new EventEmitter();

  headers: Array<HeaderItem> = [];
  body: Array<any> = [];
  bodySlice: Array<any> = [];


  pageSize: number = 300;
  startIndex: number = 0;
  readonly rowHeight: number = 30;
  dataType = DataType;

  public isLoading: boolean = false;
  private isLoadedFullBody: boolean = false;

  constructor(
    private sharedDataService: SharedDataService,
  ) {
    super();
  }

  ngOnInit(): void {
    this.sharedDataService.currentFilteredWidgetSetting
      .pipe(takeUntil(this.destroy$)).subscribe(dataSource => {
        if (dataSource) {
          if (dataSource.isScrolling) {
            const nextData = dataSource.body.slice(this.bodySlice.length, this.bodySlice.length + this.pageSize);
            this.bodySlice = [...this.bodySlice, ...nextData] as any;
          } else {
            if (this.table) this.table.nativeElement.scrollTop = 0;
            this.bodySlice = dataSource.body.slice(0, this.pageSize) as any;
          }
          this.body = dataSource.body;
          this.headers = dataSource.header;
          this.isLoadedFullBody = dataSource.isLoadedFullBody;
          this.isLoading = false;
        }

      });
  }

  override ngOnDestroy(): void {
    super.ngOnDestroy();
    this.sharedDataService.resetFilteredWidgetSetting();
    this.table.nativeElement.removeEventListener('scroll', this.onTableScroll.bind(this));
  }
  ngAfterViewInit() {
    this.table.nativeElement.addEventListener('scroll', this.onTableScroll.bind(this));
  }

  onTableScroll() {
    const scrollTop = this.table.nativeElement.scrollTop;
    const scrollHeight = this.table.nativeElement.scrollHeight;
    const clientHeight = this.table.nativeElement.clientHeight;

    if (scrollTop + clientHeight >= scrollHeight - 50) {
      if (this.bodySlice.length < this.body.length) {
        const nextData = this.body.slice(this.bodySlice.length, this.bodySlice.length + this.pageSize);
        this.bodySlice = [...this.bodySlice, ...nextData];
      } else if (!this.isLoading && !this.isLoadedFullBody) {
        this.isLoading = true;
        this.loadMoreData.emit();
      }
    }

  }

  formatDataType(value: any, col: any) {
    if (value == null) return value;
    if (typeof value == 'string') {
      value = value.replace(/\\/g, "¥");
    }
    switch (col.dataType?.toUpperCase()) {
      case "DATETIME":
        if(!value) return '';
        return moment(value).format(DateFormat.FULL_SHORT_DATE);
      case "INT":
      case "FLOAT":
        if (value === 0) return 0; else if (!value) return '';
        // Special case: Totaltime but hh:mi format(col-type still a number)
        if(col.field === "PRVHOUR") {
          return beautifyPrvHour(value);
        }
        return Number.parseFloat(value).toLocaleString();
      default:
        return value;
    }
  }
}
