import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { PrimeNGConfig } from 'primeng/api';
import { DeviceType } from './enum/common-enum';
import { AuthenticationService } from './services/authentication.service';
import { DetectDeviceService, IDeviceType } from './services/detect-device.service';
import { LocalStorageKey } from './_helper/local-storage.helper';
import { LocalStorageHelper } from './_helper/local-storage.helper';
import { ROUTE_PATH } from './const/route-path';
import { NavigationEnd, Router } from "@angular/router";
import { filter } from 'rxjs/operators';
import { Title } from '@angular/platform-browser';
import { DASHBOARD_TEMPLATE_SETTING, MENU_NAME, WIDGET_TEMPLATE_LIST, WIDGET_TEMPLATE_SETTING } from './const/text-common';
import { SaucerLogService } from './services/saucer-logs/saucer-log.service';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  sidebarExpanded = false;
  isLoggedIn: boolean = false;
  isShowErrorPage = false;
  corpLocationPath = '';
  deviceType: string;
  DeviceType = DeviceType;
  isChooseCorpScreen = false
  
  constructor(
    @Inject(DOCUMENT) private document: Document,
    private config: PrimeNGConfig,
    private translateService: TranslateService,
    private authenticationService: AuthenticationService,
    private detectDeviceService: DetectDeviceService,
    private router: Router,
    private titleService: Title,
    private saucerLogService: SaucerLogService
  ) {
    this.saucerLogService.runSaucerLog();
    this.authenticationService.currentUser.subscribe((user: any) => {
      if (user && user.token) {
        this.setTheme(user);
        this.isLoggedIn = true;
      } else {
        this.isLoggedIn = false;
      }
    });

    this.router.events.pipe(filter(event => event instanceof NavigationEnd))
      .subscribe((event: any) => {
        if (event.url === '/' + LocalStorageHelper.getUrl(ROUTE_PATH.CHOOSE_CORP)) {
          this.isChooseCorpScreen = true;
        } else {
          this.isChooseCorpScreen = false;
        }
        this.setTitle(event.url)
      });

    this.detectDeviceService.currentDevice.subscribe((device: IDeviceType) => {
      if (device) this.deviceType = device.type;
    });
    
    addEventListener('storage', (event: any) => {
      if (event.key === LocalStorageKey.USERID &&
          event.newValue &&
          (String(event.newValue) !== String(this.authenticationService.userIdValue))) {
        setTimeout(() => {
          this.authenticationService.removeLoginSession();
          location.reload();
        }, 10);
      }
    });
  }

  async ngOnInit(): Promise<void> {
    this.translateService.setDefaultLang('jp');
    this.translate('jp');

  }

  setTitle(url: string) {
    if(url?.includes(ROUTE_PATH.DATASOURCE))  this.titleService.setTitle(MENU_NAME.DATASOURCE)
    else if(url?.includes(ROUTE_PATH.DATASOURCE_CREATE))  this.titleService.setTitle(MENU_NAME.DATASOURCE_CREATE)
    else if(url?.includes(ROUTE_PATH.DATASOURCE_EDIT))  this.titleService.setTitle(MENU_NAME.DATASOURCE_EDIT)
    else if(url?.includes(ROUTE_PATH.DATASOURCE_TEMPLATE))  this.titleService.setTitle(MENU_NAME.DATASOURCE_TEMPLATE)
    else if(url?.includes(ROUTE_PATH.DATASOURCE_TEMPLATE_CREATE))  this.titleService.setTitle(MENU_NAME.DATASOURCE_TEMPLATE_CREATE)
    else if(url?.includes(ROUTE_PATH.DATASOURCE_TEMPLATE_EDIT))  this.titleService.setTitle(MENU_NAME.DATASOURCE_TEMPLATE_EDIT)
    else if(url?.includes(ROUTE_PATH.HOME))  this.titleService.setTitle(MENU_NAME.HOMETEXT)
    else if(url?.includes(ROUTE_PATH.DASHBOARD_DETAIL))  this.titleService.setTitle(MENU_NAME.DASHBOARD_SETTING)
    else if(url?.includes(ROUTE_PATH.DASHBOARD_LIST))  this.titleService.setTitle(MENU_NAME.DASHBOARD_LIST)
    else if(url?.includes(ROUTE_PATH.DASHBOARD_CREATE))  this.titleService.setTitle(MENU_NAME.DASHBOARD_CREATE)
    else if(url?.includes(ROUTE_PATH.DASHBOARD_TEMPLATE))  this.titleService.setTitle(MENU_NAME.DASHBOARD_TEMPLATE)
    else if(url?.includes(ROUTE_PATH.DASHBOARD_TEMPLATE_CREATE))  this.titleService.setTitle(MENU_NAME.DASHBOARD_CREATE)
    else if(url?.includes(ROUTE_PATH.WIDGET_LIST))  this.titleService.setTitle(MENU_NAME.WIDGET_LIST)
    else if(url?.includes(ROUTE_PATH.WIDGET_CREATE))  this.titleService.setTitle(MENU_NAME.WIDGET_CREATE)
    else if(url?.includes(ROUTE_PATH.WIDGET_DETAIL))  this.titleService.setTitle(MENU_NAME.WIDGET_DETAIL)
    else if(url?.includes(ROUTE_PATH.WIDGET_TEMPLATE_CREATE))  this.titleService.setTitle(MENU_NAME.WIDGET_TEMPLATE_CREATE)
    else if(url?.includes(ROUTE_PATH.CK_REPORT))  this.titleService.setTitle(MENU_NAME.ZAITAKU)
    else if(url?.includes(ROUTE_PATH.DASHBOARD_WIDGET_TEMPLATE))  this.titleService.setTitle(MENU_NAME.DASHBOARD_WIDGET_TEMPLATE)
    else if(url?.includes(ROUTE_PATH.BASIC_INFO))  this.titleService.setTitle(MENU_NAME.BASIC_INFO)
    else if(url?.includes(ROUTE_PATH.SYSTEM_SETTING))  this.titleService.setTitle(MENU_NAME.SYSTEM_SETTING)
    else if(url?.includes(ROUTE_PATH.DASHBOARD_TEMPLATE_DETAIL)  && url?.includes('/3'))  this.titleService.setTitle(DASHBOARD_TEMPLATE_SETTING.TITLE_PREVIEW)
    else if(url?.includes(ROUTE_PATH.DASHBOARD_TEMPLATE_DETAIL)  && !url?.includes('/3'))  this.titleService.setTitle(DASHBOARD_TEMPLATE_SETTING.TITLE_EDIT)
    else if(url?.includes(ROUTE_PATH.WIDGET_TEMPLATE_DETAIL))  this.titleService.setTitle(WIDGET_TEMPLATE_SETTING.WIDGET_EDIT)
    else if(url?.includes(ROUTE_PATH.WIDGET_TEMPLATE_LIST))  this.titleService.setTitle(WIDGET_TEMPLATE_LIST.TITLE)
    else if(url?.includes(ROUTE_PATH.AGGREGATION_LIBRARY))  this.titleService.setTitle(MENU_NAME.AGGREGATION_LIBRARY)
  }

  translate(lang: string) {
    this.translateService.use(lang);
    this.translateService
      .get('primeng')
      .subscribe((res: any) => this.config.setTranslation(res));
  }

  handleToggleSidebar(isExpand: any) {
    this.sidebarExpanded = isExpand;
  }

  onLogout(): void {
    this.authenticationService.logout();
  }

  setTheme(user: any) {
    let localValue = localStorage.getItem(LocalStorageKey.THEME);
    if (localValue) {
      let themeOptions = JSON.parse(localValue);
      let option = themeOptions.filter((v: any) => v.staffCd === user.staffcode);
      if (option && option.length > 0) {
        let className = '';
        switch (option[0].theme) {
          case 1:
            className = 'dark';
            break;
          case 2:
            className = 'navi';
            break;
          default:
            className = '';
            break;
        }
        this.document.body.className = className;
      }
    }
  }
}