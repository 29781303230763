import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { ModalTemplate } from '../../../../app/models/common-model';
import { BUTTON, COMMON_TEXT, DIALOG_CONFIRM_SAVE_DATA } from '../../../../app/const/text-common';
import { ButtonType } from '../../../../app/enum/common-enum';
import { SAUCER_LOG_ACTION, SaucerLogService } from 'src/app/services/saucer-logs/saucer-log.service';
import { CONTENT_LOG } from 'src/app/config/saucer-log.config';


@Component({
  selector: 'pivot-dialog-confirm-save-data',
  templateUrl: './dialog-confirm-save-data.component.html',
  styleUrls: ['./dialog-confirm-save-data.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class DialogConfirmSaveDataComponent implements OnInit {
  @Input() data: any = null;
  @Output() onSubmitData: any = new EventEmitter();
  modalTemplate: ModalTemplate = new ModalTemplate();
  buttonType = ButtonType;
  BUTTON = BUTTON;
  DIALOG_CONFIRM = DIALOG_CONFIRM_SAVE_DATA;
  COMMON_TEXT = COMMON_TEXT;
  isSaveData: boolean = false;
  step: number = 1;

  constructor(private saucerLogService: SaucerLogService) { }

  ngOnInit(): void {
    this.modalTemplate.header = COMMON_TEXT.CONFIRM;
  }

  closeFormModal() {
    this.onSubmitData.emit(null);

    //Log
    this.saucerLogService.action({}, { 
      action: SAUCER_LOG_ACTION.MENU_TEMPLATE.CONFIRMATION_DIALOG.CANCEL
    });
  }

  changeOption(type: number) {
    this.isSaveData = type ==1 ? false: true;
  }

  cancel() {
    this.onSubmitData.emit(null);

    //Log
    this.saucerLogService.action({}, { 
      action: SAUCER_LOG_ACTION.MENU_TEMPLATE.CONFIRMATION_DIALOG.CANCEL
    });
  }

  save() {
    this.saucerLogService.action({
      content: CONTENT_LOG.SELECT + 
              (!this.isSaveData ? DIALOG_CONFIRM_SAVE_DATA.DEFAULT : DIALOG_CONFIRM_SAVE_DATA.CUSTOMIZATION)
    }, { 
      action: SAUCER_LOG_ACTION.MENU_TEMPLATE.SETTINGS_DIALOG.SETTINGS
    });

    this.onSubmitData.emit(this.isSaveData);
  }

  next() {
    //Log
    if(this.step == 1) { 
      this.saucerLogService.action({
        content: JSON.stringify({
          officeLabel: this.data?.officeLabel,
          periodName: this.data?.periodName,
          folderDatasource: this.data?.folderDatasource,
          datasourceName: this.data?.datasourceName,
          forderWidget: this.data?.forderWidget,
          widgetName: this.data?.widgetName,
          dashboardName: this.data?.dashboardName
        })
      }, { 
        action: SAUCER_LOG_ACTION.MENU_TEMPLATE.CONFIRMATION_DIALOG.NEXT
      });
    }

    this.step++;
  }

  back() {
    this.step--;

    //Log
    this.saucerLogService.action({}, { 
      action: SAUCER_LOG_ACTION.MENU_TEMPLATE.SETTINGS_DIALOG.BACK
    });
  }
}
