import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { API_APP } from '../../config/app.config';
import { ApiService } from '../api.service';
import {ErrorHandleService} from '../error-handle.service';
import { ClearProcessService } from '../clear-process.service';
import { LambdaStatus } from 'src/app/enum/common-enum';



@Injectable({ providedIn: 'root' })
export class StepFunctionService extends ApiService {
    constructor(http: HttpClient, router: Router, errorHandle: ErrorHandleService, private clearProcessService: ClearProcessService) {
        super(http, router, errorHandle);
      }

          
  intervalIdList: any[] = [];
  firstTimeCalling: boolean = true;
  
    checkStatus(arn : string) {
        let apiUrl = API_APP.STEPFUNCTION.CONTROLLER + API_APP.STEPFUNCTION.CHECK_STATUS + "/" + arn;
        return  this.get(apiUrl);
    }


  checkLambdaStatus(executionarn: any ): Promise<void> {
    return new Promise<void>((resolve) => {
      const screenCode = 1 + Math.random();
      let intervalId = setInterval(() => {

        if ( this.checkIfStopInterval(screenCode, intervalId)) {
          return;
        }
        this.checkStatus(executionarn).then(resLamda => {
          if ((resLamda.statuscode && resLamda.statuscode == 500) || (resLamda?.data?.status != LambdaStatus.RUNNING)) {
            clearInterval(intervalId);
            this.removeIntervalIdToList(intervalId);
            resolve();
          }
        });
      }, 3000);

      this.addIntervalIdToList(intervalId);
    });
  }
  
  addIntervalIdToList(id: any) {
    if (this.intervalIdList.includes(id)) return;
    this.intervalIdList.push(id);
    this.clearProcessService.setIntervalId(id);
  }

  removeIntervalIdToList(id: any) {
    this.intervalIdList = this.intervalIdList.filter(e => e != id);
    this.clearProcessService.removeIntervalIdList(id);
    this.firstTimeCalling = true;
  }

  checkIfStopInterval(screenCode: any, intervalId: any): boolean {
    let result: boolean = false;
    if (this.firstTimeCalling) {
      this.firstTimeCalling = false;
      this.clearProcessService.setScreenCode(screenCode);
    }
    const holderCode = this.clearProcessService.getScreenCode();
    if (holderCode != screenCode) {
      clearInterval(intervalId);
      this.removeIntervalIdToList(intervalId);
      result = true;
    }

    return result;
  }
}
