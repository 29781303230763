import { Component, ViewEncapsulation } from '@angular/core';
import {ChartBase} from '../chart.base';
import bb, {bar} from "billboard.js";
import {cloneDeep} from 'lodash';
import * as d3 from 'd3';
import Utils from "../../../../util/utils";

@Component({
  selector: 'pivot-stacked-bar-chart',
  templateUrl: './stacked-bar-chart.component.html',
  styleUrls: ['./stacked-bar-chart.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class StackedBarChartComponent extends ChartBase {

  nodeId = "js-chart-bar"

  override ngOnInit() {
    super.ngOnInit();
    this.data = Utils.replaceYenCharacterOnChart(this.data);
  }

  generateChart(): void {
    let groupConfig = [];
    var cate = []; 
    var col = [];
    if(this.isStackedChartSetting == true){
      let newCate: any[] = [];
      let newCol: any[][] = [];
      this.data.columns.forEach((item: any[]) => {
        newCate.push(item[0]);
      });
      this.data.categories.forEach((item: any, index: number) => {
        newCol[index] = []; 
        newCol[index].push(item); 
        this.data.columns.forEach((subitem: any[]) => {
          newCol[index].push(subitem[index + 1]); 
        });
      });
      cate = newCate;
      col = newCol;
    }else {
      cate =  this.data.categories;
      col =  this.data.columns ;
    }
    groupConfig = this.generateGroupConfig(col);
    const onrendered = ()=>{
      this.onRenderComplete.emit();
    }
    const onresize = ()=>{
      this.onStartRender.emit();
    }
    const renderChart = () =>{
      this.chart.flush();
    }
    this.chart = bb.generate({
      boost: {
        useWorker: false,
      },
      zoom: {
        enabled: this.zoomEnabled
      },
      legend: {
        show: this.data.legendShow
      },
      data: {
        columns: col,
        type: bar(),
        groups: [
          groupConfig
        ]
      },
      axis: {
        x: {
          show: this.data.axisShow,
          clipPath: false,
          type: "category",
          categories: cate,
          tick: {
            width: 80,
            multiline: false,
            culling: false,
            rotate : 40,
            autorotate: true
          }
        },
        y: {
          tick: {
            format: function(y: any) {
              return d3.format(",")(y);
            }
          }
        }
      },
      render: {
        lazy: true,
        observe: true
      },
      onrendered: onrendered,
      onresize: onresize,
      size: this.size,
      bindto: this.bindTo,
      bar: {
        width: {
          ratio: 0.9
        }
      }
    });
    this.onStartRender.emit();

    //delay render chart to have time for memory cleanup
    let timedelay = 100;
    if(this.data.columns.length>0){
      let dataCount = this.data.columns[0].length * this.data.columns.length;
      if(timedelay < dataCount*this.TIME_DELAY) timedelay= dataCount*this.TIME_DELAY;
      if(timedelay > 10000) timedelay= 10000;
    }
    setTimeout(renderChart, timedelay);
  }

  generateGroupConfig(data: any) {
    let dataHolder = cloneDeep(data);
    return dataHolder.map((e: any) => e[0]);
  }
}