import { Component, EventEmitter, Input, OnChanges, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { BUTTON, COMMON_TEXT, SYSTEM_SETTING } from '../../../app/const/text-common';
import { MESSAGE_TEXT } from '../../../app/const/message';
import { ButtonType, InputType } from '../../../app/enum/common-enum';
import { ModalTemplate } from '../../../app/models/common-model';
import { Role } from '../../../app/models/response/role.ro';
import Utils from '../../../app/util/utils';

@Component({
  selector: 'pivot-dialog-role-editor',
  templateUrl: './dialog-role-editor.component.html',
  styleUrls: ['./dialog-role-editor.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class DialogRoleEdiorComponent implements OnInit, OnChanges {

  // role edit data
  @Input() data: Role;
  @Input() modalData: ModalTemplate = new ModalTemplate();
  // event submit data
  @Output() onSubmitData: any = new EventEmitter<any>();
  // display modal variables
  @Input() isDisplayModal: boolean = false;
  // role name variables
  name: string = '';
  // new role data
  newRole: Role = new Role();
  // input params
  inputRoleParams: any = {
    type: InputType.text,
    validate: true,
    validateError: MESSAGE_TEXT.REQUIRE_INPUT,
    borderFill: true,
    maxLength: 100,
  };
  // button type
  buttonType = ButtonType;
  BUTTON = BUTTON;
  COMMON_TEXT = COMMON_TEXT;
  SYSTEM_SETTING = SYSTEM_SETTING;
  constructor() { }

  ngOnChanges(): void {
    this.setDataforDialog();
    this.inputRoleParams.isValidate = false;
  }

  ngOnInit(): void {
    // set header default
    this.modalData.header = SYSTEM_SETTING.ROLE_EDIT;
    this.setDataforDialog();

  }

  // event close popup
  onClose() {
    this.onSubmitData.emit(null);
    this.isDisplayModal = false;
  }

  // event on input change
  onInputRoleName(data: any) {
    this.name = data.currentTarget?.value;
  }

  // on submit role data
  onSubmitRoleData() {
    if (!this.name) {
      this.inputRoleParams.isValidate = true;
      this.inputRoleParams.validate = true;
      return;
    }
    this.newRole.name = this.name.trim();
    this.onSubmitData.emit(this.newRole);
    this.isDisplayModal = false;
  }

  setDataforDialog() {
    // set width for dialog
    if (Utils.isNullOrEmpty(this.modalData.style?.width)) {
      this.modalData.style.width = '400px';
    }
    if (!Utils.isNullOrEmpty(this.data)) {
      this.newRole = this.data;
      this.name = this.newRole.name;
    }
  }

  focusoutInput() {
    this.inputRoleParams.validate = false;
  }

}
