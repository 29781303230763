<pivot-multiple-pannel-template 
	[nameParams]="nameParams"
	[titlePage]="titlePage"
	[isDisplayPannel]="false"
	[isDisplayToolTip]="isDisplayToolTip"
	[name]="dashboardName"
	[isDisplayControlIcon]="mode === ScreenMode.PREVIEW ? true : false"
	(nameFieldHandler)="getDashboardName($event)"
	(backPageHandler)="backPage()">
	<div top-buttons class="dashboard-top-buttons"  *ngIf="mode === ScreenMode.PREVIEW">
		<div class="checkbox-container">
			<pivot-checkbox [value]="isFilterPublish" (onClick)="filterPublicWidgets()"></pivot-checkbox>
			<span>{{DASHBOARD_TEMPLATE_SETTING.FILTER_PUBLIC}}</span>
		</div>
		<pivot-text-button class="back-button"
			[disabled]="checkPermission"
			[buttonText]="BUTTON.EDIT"
			[buttonType]="ButtonType.SECONDARY" 
			(btnClick)="handleUpdateBtnClick()">
		</pivot-text-button>
	</div>
	<div class="dashboard-top-buttons" top-buttons *ngIf="mode === ScreenMode.ADD">
		<div class="checkbox-container">
			<pivot-checkbox  [value]="isFilterPublish" (onClick)="filterPublicWidgets()"></pivot-checkbox>
			<span>{{DASHBOARD_TEMPLATE_SETTING.FILTER_PUBLIC}}</span>
		</div>
		<pivot-text-button class="back-button" 
			[buttonType]="ButtonType.SECONDARY" 
			[buttonText]="BUTTON.ADD_WIDGET"
			(btnClick)="handleCreateBtnClick()">
		</pivot-text-button>
		<pivot-text-button class="back-button"
			[disabled]="isDisabledButton()"
			[buttonText]="BUTTON.SAVE"
			(btnClick)="handleSaveBtnClick()">
		</pivot-text-button>
	</div>
	<div class="dashboard-top-buttons" top-buttons *ngIf="mode === ScreenMode.EDIT">
		<div class="checkbox-container">
			<pivot-checkbox [value]="isFilterPublish" (onClick)="filterPublicWidgets()"></pivot-checkbox>
			<span>{{DASHBOARD_TEMPLATE_SETTING.FILTER_PUBLIC}}</span>
		</div>
		<pivot-text-button class="back-button"
			[buttonType]="ButtonType.SECONDARY" 
			[buttonText]="BUTTON.ADD_WIDGET"
			(btnClick)="handleCreateBtnClick()">
		</pivot-text-button>
		<pivot-text-button class="back-button" 
			[disabled]="isDisabledButton()" 
			[buttonText]="BUTTON.SAVE"
			(btnClick)="handleSaveBtnClick()">
		</pivot-text-button>
	</div>
	<div class="dashboard-top-buttons" top-buttons *ngIf="mode === ScreenMode.TEMPLATE">
		<pivot-text-button class="back-button" 
			[disabled]="isDisabledSetButton()"
			[buttonType]="ButtonType.SECONDARY" 
			[buttonText]="BUTTON.SET"
			(btnClick)="createWidgetTemplate()">
		</pivot-text-button>
	</div>
	<div class="dashboard-controls-popup" control-popup>
		<label class="dashboard-controls-popup--item" (click)="onDownloadImage()">{{DASHBOARD_TEMPLATE_SETTING.CP_DOWNLOAD}}</label>
		<label class="dashboard-controls-popup--item" (click)="onDuplicateDashboard()">{{BUTTON.COPY}}</label>
		<label class="dashboard-controls-popup--item" (click)="onNewDashboard()">{{DASHBOARD_TEMPLATE_SETTING.CP_NEW}}</label>
		<div class="dashboard-controls-popup--item" [ngClass]="checkPermission ? 'disable-del-option' : ''" (click)="onDeleteDashboard()">{{BUTTON.DELETE}}</div>
	</div>
	<div class="body-content" body-content>
		<div class="folder-panel">
			<pivot-search-bar class="search-folder" [searchParams]="searchParam" (onFilterData)="onFilterDataMenu($event)">
			</pivot-search-bar>
			<div class="menu-item">
				<p-panelMenu *ngIf="menuItems.length > 0" [model]="menuItems" [style]="{'width':'100%'}"></p-panelMenu>
				<div class="no-data" *ngIf="menuItems.length == 0">データがありません。</div>
			</div>
			<!-- <div class="ds-folder" *ngIf="mode === ScreenMode.TEMPLATE">
				<div class="field-wrapper">
					<span class="bold-text">{{DASHBOARD_TEMPLATE_SETTING.DS_FOLDER}}<span class="input-required">{{COMMON_TEXT.ASTERISK}}</span></span>
					<div class="field-name">{{selectedFolder.name}}</div>
				</div>
				<pivot-text-button class="new-folder-btn" (btnClick)="showFolderModal()" [buttonType]="ButtonType.SECONDARY"
					[buttonText]="BUTTON.SELECT_FOLDER"></pivot-text-button>
			</div> -->
		</div>
		<div class="dashboard-setting">
			<div class="dashboard-setting-container">
				<div class="dashboard-setting-container--body">
					<gridster [options]="options" #mygridster id="dashboardSettingDownload">
						<ng-container *ngIf="dashboardSelected">
							<gridster-item [item]="widget" *ngFor="let widget of this.dashboardSelected.setting"
								(itemResize)="widgetResize($event)" (itemChange)="widgetChange($event)">
								<div class="gridster-item-content" [id]="widget.widgetCd">
									<div class="gridster-item-content--header" [style.padding-right]="mode == ScreenMode.TEMPLATE ? '12px' : '20px'">
										<div class="title-group">
											<label class="widget-title" #widgetLabel  [pTooltip]="(widgetLabel.scrollWidth > widgetLabel.offsetWidth) ? widget.name : ''" tooltipPosition="bottom"
												tooltipStyleClass="custom-tooltip" [fitContent]="true" [escape]="false">{{widget.name}}</label>
											<img *ngIf="widget.isNew" class="new_icon-template" src="../../../assets/icons/new.svg" alt="">
										</div>

										<div class="widget-btn-group">
											<i class="pi pi-pencil pencil" *ngIf="mode != ScreenMode.PREVIEW && mode != ScreenMode.TEMPLATE"
												(click)="handleEditWidgetClick(widget)"></i>
											<i class="pi pi-times times" *ngIf="mode != ScreenMode.PREVIEW && mode != ScreenMode.TEMPLATE"
												(touchstart)="$event.stopPropagation()"
												(mousedown)="$event.stopPropagation()"
												(click)="handleRemoveWidgetClick($event, widget)"></i>
											<p-radioButton *ngIf="mode == ScreenMode.TEMPLATE" name="category" [inputId]="widget.widgetCd" name="category" 
											[value]="widget.widgetCd" (onClick)="chooseTemplate(selectedWigdetRadio, widget.datasourceCd, widget.period, widget.datasourceName)" [(ngModel)]="selectedWigdetRadio"></p-radioButton>
										</div>
									</div>
									<ng-container>
									<pivot-chart 
											*ngIf="widget.isHaveSize === false"
											[widgetId]="widget.widgetId || widget.widgetCd" 
											[widgetdetails]="widget.widgetdetails"
											[sortParams]="widget.sortArr || []"  
											[widgetResult]="widget.widgetResult"  
											[isLoadWidgetResultComplete]="widget.isLoadWidgetResultComplete"
											[type]="widget.type"
											[width]="widget.width" 
											[height]="widget.height" 
											[officeList]="officeList"
											[widgetConfig]="widget.widgetConfig"
											[filterLog]="dashboardSettingActionLog.FILTER_WIDGET"
											[sortLog]="dashboardSettingActionLog.SORT_WIDGET"
											[widget]="widget" >
										</pivot-chart>
										<div *ngIf="isLoading" class="loading">
											<div class="loader"></div>
										</div>
									</ng-container>
									<div class="gridster-item-content--description" *ngIf="widget.isHaveSize === false">
										<!-- <label *ngIf="mode == ScreenMode.TEMPLATE" class="lbl-date">【{{widget.startdate}}{{COMMON_TEXT.TILDE}}{{widget.enddate}}】</label> -->
										<label  class="lbl-date" [ngClass]="getPubicDate(widget.widgetCd) == '非公開' ? 'expired' : 'publish'">{{getPubicDate(widget.widgetCd)}}</label>
										<label class="lbl-description" [pTooltip]="getWidgetDescription(widget.widgetCd)">{{getWidgetDescription(widget.widgetCd)}}</label>
									</div>
								</div>
							</gridster-item>
						</ng-container>
					</gridster>
				</div>
			</div>
		</div>
	</div>
</pivot-multiple-pannel-template>

<pivot-confirm-delete-dialog
	[isDisplayModal]="isDisplayConfirmDeleteModal" 
	[modalData]="modalData"
	(onSubmitData)="onConfirmDeleteDlg($event)">
</pivot-confirm-delete-dialog>

<pivot-dialog-dashboard-editor
	*ngIf="isDisplayDashboardModal"
	[data]="newDashboard" 
	[isDisplayModal]="isDisplayDashboardModal" 
	[folders]="folderOwners"
	[isShowAssign]="!isDashboardTemplate"
	[isDuplicate]="isDuplicate" 
	[folderSelected]="folderCreate" 
	[logAction]="logAction"
	[modalData]="modalAddDashboard"
	(onSubmitData)="onChangeDashboardData($event)">
</pivot-dialog-dashboard-editor>

<!-- <pivot-folder-modal *ngIf="isShowFolder" 
	[folderType]="FolderType.Datasource" 
	[folderList]="folderList" 
	(closeModalHandler)="closeFolderModal()"
  (selectedFolderHandler)="changeFolder($event)" 
	[selectedFolder]="selectedFolderRow">
</pivot-folder-modal> -->

<pivot-office-service *ngIf="isDspOfficeModal" [selectedOfficeServices]="officeSelecteds"
    (closeModalHandler)="closeOfficeModal()" (selectedOfficeHandler)="selectOfficeAndCreateWidget($event)">
</pivot-office-service>

<pivot-modal-template class = "dlg-warning-template" *ngIf="isDisplayWarning" [modalTemplate]="modalDataWarning" (closeModalHandler)="closeWarning()">
	<div modal-body>
		{{warningMessage}}
	</div>
	<div modal-bottom >
		<div class="btn-grp-warning-template">
			<pivot-text-button  (btnClick)="closeWarning()" [buttonType]="ButtonType.SECONDARY"
				[buttonText]="BUTTON.CANCEL">
			</pivot-text-button>
		</div>
	</div>
  </pivot-modal-template>