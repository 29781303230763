import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { CalendarTypeView } from 'primeng/calendar';
import { DATE_MAX_VALUE, DATE_MIN_VALUE } from '../../../../app/const/const';

@Component({
  selector: 'pivot-calendar',
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.scss']
})
export class CalendarComponent implements OnInit, OnChanges {

  @Input() dateFormat: string = '';
  @Input() value?: Date | string;
  @Input() inputId: string = '';
  @Input() minDate?: Date;
  @Input() maxDate?: Date;
  @Input() style: any;
  @Input() inputStyle: any;
  @Input() isShowTime: boolean = false;
  @Input() isTimeOnly: boolean = false;
  @Input() disabled: boolean = false;
  @Input() disabledDates: Date[] = [];
  @Input() disabledDays: number[] = [];
  @Input() showButtonBar: boolean = false;
  @Input() view: CalendarTypeView = 'date';
  @Input() canClear = false;
  @Input() appendTo: any = null;
  @Output() valueChange = new EventEmitter();

  DATE_MIN = DATE_MIN_VALUE;
  DATE_MAX = DATE_MAX_VALUE;

  constructor() { }
  ngOnChanges(changes: SimpleChanges): void {
    this.value = this.value ? new Date(this.value) : undefined;
  }

  ngOnInit(): void {
    this.value = this.value ? new Date(this.value) : undefined;
  }

  handleValueChange(value: any) {
    this.valueChange.emit(value);
  }

  clearText() {
    this.value = undefined;
    this.valueChange.emit(undefined);
  }
}
