import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ListBoxItems, ListBoxParams } from '../../../../app/models/common-model';

@Component({
  selector: 'pivot-list-box',
  templateUrl: './list-box.component.html',
  styleUrls: ['./list-box.component.scss']
})
export class ListBoxComponent implements OnInit {
  @Input() params: ListBoxParams = new ListBoxParams();
  @Input() selectOptionParams: any = null;
  @Input() selection = [];
  @Output() onSortData = new EventEmitter<any>();
  @Output() onFilterData = new EventEmitter<any>();
  @Output() onRemoveData = new EventEmitter<any>();
  @Output() onDelete = new EventEmitter<any>();
  @Output() onSelectionChange = new EventEmitter<any>();
  dragdrop: boolean = true;
  displayfilter: boolean = true;
  itemSelected: any = null;

  constructor() { }

  ngOnInit(): void {
    if (this.params?.onDragDropItem != false) {
      this.dragdrop = true;
    }
    else {
      this.dragdrop = false;
    }

    if (this.params?.displayfilter !== undefined) {
      this.displayfilter = this.params.displayfilter;
    }
  }

  // event filter data
  filterData(event: any) {
    if (this.params?.onfilter) {
      this.onFilterData.emit(event);
    }
  }

  // remove item for array data
  deleteItem(event: ListBoxItems) {
    this.params.items = this.params.items.filter(x => x.value != event.value);
    this.onDelete.emit(event);
    this.onRemoveData.emit(this.params.items);
  }

  // get selected item
  selectionChange(event: any) {
    this.itemSelected = event?.value;
    this.onSelectionChange.emit(event);
  }

  // event get list data drag and drop
  sortListData() {
    this.onSortData.emit(this.params.items);
  }

}
