import { ChangeDetectorRef, Component, OnInit, ViewEncapsulation } from '@angular/core';
import { MESSAGE_TEXT } from '../../../../app/const/message';
import { NotifyParams } from '../../../../app/models/common-model';
import { ProcessNotificationService } from '../../../../app/services/process-notification.service';
import {TypeOfNotification} from 'src/app/enum/common-enum';

@Component({
  selector: 'pivot-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class NotificationComponent implements OnInit {
  dataNotify = {};
  isDisplay: boolean = false;
  status: boolean = true;
  notifyContent: string = "";
  MESSAGE_TEXT = MESSAGE_TEXT;
  typeOfNoti: TypeOfNotification = TypeOfNotification.SYNC_DATASOURCE;
  type = TypeOfNotification;
  constructor(
    private processNotificationService: ProcessNotificationService,
    private cdr: ChangeDetectorRef
  ) {
    this.checkPageNotifyHandler();
  }

  ngOnInit(): void {
  }

  checkPageNotifyHandler(): void {
    this.processNotificationService.dataNotify
      .subscribe(
        (dataNotify: NotifyParams) => {
          if (this.isDisplay) {
            setTimeout(() => {
              this.handleNotify(dataNotify);
            }, 2000);
          } else {
            this.handleNotify(dataNotify);
          }

        }, (error) => {
        }
      );
  }

  handleNotify(dataNotify: NotifyParams) {
    if (this.isDisplay !== dataNotify.isDisplay) {
      this.isDisplay = dataNotify.isDisplay;
      this.status = dataNotify.status;
      this.notifyContent = dataNotify.value;
      this.typeOfNoti = dataNotify.typeOfNoti;
      setTimeout(() => {
        this.isDisplay = false;
      }, 2000);
      this.cdr.detectChanges();
    }
  }
}
