<div class="pv-header">
    <img class="hd-logo" src="../../../../assets/images/pivotlogo.svg" [routerLink]="[ROUTE_PATH.HOME]" [routerLinkActive]="'active'">
    <!-- <div class="hd-info" (click)="openHelperDocument()">
        <i class="pi pi-question-circle hd-icon"></i>
    </div> -->
    <div class="hd-avatar" id="hdavatar" (click)="showProfile()">
        <img class="avatar" [src]="userAvatarUrl" [ngClass]="avatarValid ? 'block' : 'hidden'" (error)="handleAvatarError($event)">
        <img class="avatar" [ngClass]="avatarValid ? 'hidden' : 'block'" src="../../../../assets/images/user-avatar.svg">
    </div>

    <div id="hdprofile" *ngIf="vspProfile" class="hd-profile">
        <pivot-text-button id="hdmyaccount" class="hd-button btn-text btn-account" (btnClick)="gotoMyAccount()"
            [buttonType]="buttonType.SECONDARY" [buttonText]="BUTTON.MY_ACCOUNT">
        </pivot-text-button>
        <div class="btn-help">
          <div class="badge" *ngIf="countUnread > 0">
            <div class="">{{countUnread}}</div>
          </div>
          <pivot-text-button id="hdsystemsetting" class="hd-button btn-text btn-setting" (btnClick)="gotoHelp()"
            [buttonType]="buttonType.SECONDARY" [buttonText]="BUTTON.HELP"
            [style.display]="deviceType == DeviceType.MOBILE || deviceType == DeviceType.TABLET ? 'none' : 'flex'">
          </pivot-text-button>
        </div>
        <div  *ngIf="isDisplaySystemSetting" class="line-edit"></div>
        <pivot-text-button id="hdsystemsetting" *ngIf="isDisplaySystemSetting" class="hd-button btn-text btn-setting" (btnClick)="systemSetting()"
            [buttonType]="buttonType.SECONDARY" [buttonText]="BUTTON.SYS_SETTING" [style.display]="deviceType == DeviceType.MOBILE || deviceType == DeviceType.TABLET ? 'none' : 'flex'">
        </pivot-text-button>
        <div class="line-edit"></div>
        <pivot-text-button class="hd-button btn-text btn-logout" (btnClick)="logout()"
            [buttonType]="buttonType.SECONDARY" [buttonText]="BUTTON.LOGOUT">
        </pivot-text-button>
    </div>
</div>

<pivot-confirm-del-selected-dialog [message]="messageLogout" 
    [isDisplayModal]="isDisplayModalLogout" 
    [modalData]="modalLogout"
    [btnOkText]="btnOkLogout"
    [btnCancelText]="btnCancelLogout"
    (onSubmitData)="onOkLogout($event)">
</pivot-confirm-del-selected-dialog >