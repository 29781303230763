import { Dictionary } from "lodash";
import { ButtonIconType, ButtonType, DataSourceColumnType, InputType, SearchType, TypeOfNotification } from "../enum/common-enum";
import { WidgetDetail } from "./request/widget.dto";

export class ButtonItem {
  buttonText = "";
  buttonType: ButtonType = ButtonType.PRIMARY;
  iconType?: ButtonIconType;
  clickFunction?: Function;
  disabled: boolean = false;
}

export class IconModal {
  iconName: string = "";
  iconColor: string = "";
  isSmallSize?: boolean = true;
}

export class ModalParam {
  title: string = "";
  description: string = "";
  icon?: IconModal;
  buttonArray?: Array<ButtonItem> = [];
}

export class RadioItem {
  key: string = '';
  label: string = '';
}

export class RadioOutputItem {
  data: RadioItem = new RadioItem();
  value: boolean = false;
}

export class ModalTemplate {
  isShowHeader: boolean = true;
  header: string = "";
  isDismissableMask: boolean = false; //If false => prevent hidding the dialog when click the modal background;
  isModal: boolean = true;
  breakpoints?: any = {};
  style: any = {};
  hasBottom: boolean = true;
  isResizable: boolean = false;
  closable: boolean = true;
  appendTo: string = '';
  styleClass: string = '';
  closeOnEscape: boolean = true;
  hasFooter?: boolean = false;
}

export class ListDatasourceMix{
  name: string;
  date?: string;
  iconNew?:boolean = false;
}

export class TreeViewInputParams {
  nodes: TreeNode[] = [];
  dragScope?: string = '';
  checkbox?: boolean; // enable checkbox tree mode
  expanded?: boolean; //Expand all at start
  allowParentSelect?: boolean = true;
  expandIcon?: string = '';
  collapseIcon?: string = '';
  selectable?: boolean;
  hoverable?: boolean = false;
  isDisplayTooltip?: boolean = false;
  isMultiDragItem?: boolean = false;
}

export class TreeNode {
  id?: string;
  labelId? : string;
  nodes?: TreeNode[];
  icon?: string;
  label?: string;
  selected?: boolean = false;
  expanded?: boolean = false;
  parent?: TreeNode;
  isShowCheckBox?: boolean = true;
  draggable?: boolean = false;
  isShowExpandIcon?: boolean = false;
  isShowIcon?: boolean = true;
  isLastNode?: boolean = false;
  name?: string;
  type?: string;
  data?: any[] = [];
  tooltip?: any[] = [];
  hidden? = false;
  singleSelected?: boolean = false;
  canDelete?: boolean = false;
  hasAction?: boolean = false;
  style?: any = {};
  visible?:boolean = false;
  highlighted?: boolean = false;
  childinfarray?: []; 
  parentInf?: string;
  isLoaded?: boolean = false;
  isSpin?: boolean = false;
}

export class RowOfTableStaff{
  id?: string;
  labelId? : string;
  selected?: boolean = false;
  draggable?: boolean = false;
  name?: string;
  highlighted?: boolean = false;
  fullName?: string
}

export class ListBoxItems {
  name: string = '';
  value: any = null;
  data?: any = {};
  isFilter?: boolean = false;
  onFilter?: boolean = false;
}

export class ListBoxParams {
  items: ListBoxItems[] = [];
  itemDisplayText: string = '';
  displayfilter?: boolean = true;
  onfilter?: boolean = true;
  cssStyle?: any = {};
  onDragDropItem?: boolean = true;
  styleClass?: string = '';
  listStyle?: any = {};
  listStyleClass?: string = '';
  disabled?: boolean = false;
}

export class ListOptionParams extends ListBoxItems {
  defaultValue?: any = null;
  hilightColor?: any = '';
}
export class ListItemParams {
  id?: string = '';
  items: ListOptionParams[] = [];
  itemDisplayText: string = '';
  itemDisplayTooltip?: string = '';
  cssStyle?: any = {};
  options: ListBoxItems[] = [];
  onDragDropItem?: boolean = true;
  isDisplayTooltip?: boolean = false;
  isBorderLeftColor?: boolean = false;
}

export class SearchParams {
  type: SearchType = SearchType.input;
  placeholder?: string = '';
  cssStyle: any = {};
  readonly: boolean = false;
  disabled: boolean = false;
  dataSource?: any[] = [];
  comboDisplayText?: string = '';
  defaultValue?: string = '';
  selected?: any;
  isFilter?: boolean = true;
  maxLength?: number | null = null;
  objectData?: any = {}; 
  borderRed?: boolean = false;
  selfReset?: boolean = false; // If true, search textbox will be reset when re-open
}

export class InputParams {
  readonly: boolean = false; // readOnly input status
  disabled: boolean = false; // disable input status
  validate: boolean = false; // check validate for input
  validateError: string = ''; // validate error string 
  placeholder: string = '';  // input place holder
  type: InputType = InputType.text; // input type
  inputStyle: any = null; // input style css
  pencil: boolean = false; // show/hide pencil 
  borderFill: boolean = true; // set border fill for input
  borderRed?: boolean = false;
  isValidate?: boolean = false;
  isError?: boolean = false;
  maxLength?: number;
  minLength?: number;
  hidden?: boolean = false;
}

export class NotifyParams {
  isDisplay: boolean = false;
  status: boolean = true;
  value: string = "";
  typeOfNoti: TypeOfNotification = TypeOfNotification.SYNC_DATASOURCE;
}

export class DropdownOption {
  name: string;
  value: number;
}

export class FilterItem {
  id: string;
  name: string;
  checked: boolean = false;
  isLabel: boolean = false;
  parent: string;
  level?: string;
}

export class ChartData {
  categories: string[];
  columns: any[][];
  legendShow: boolean = true;
  axisShow: boolean = true;
  graphConfig: any ;
}

export type operator = {
  type: string
  label: string
  value: WidgetDetail | string
};

export type MstFilter = {
  typecd: string
  groupcd: string
  itemcd: string
  content: string
  sortno?: number
}

export type item = { name?: string, value: string, type: string, items?: any }
export type option = { name: string, value: string, data?: any }
export type optionInt = { name: string, key: number }
export type paramInt = { name: string, value: number }
export type paramStr = { name: string, value: string, visible: boolean }

type json = string | number | boolean | JsonObject | json[] | null
export type JsonObject = {[x: string]: json}

export class TitleMapping {
  id?: string = "";
  officecd?: string;
  blockcd? : string;
  unitcd?: string;
  ttlkbn?: string;
  ttlcd?: string;
  itemid?: string;
  columnname? : string;
  displayname: string;
  columntype: number = DataSourceColumnType.NORMAL;
  sdate?: string;
  edate?: string;
  sortno: number;
  datatype: string;
  notekbn?: number = 0;
  dockbn?: boolean;
  delflg: boolean = false;
  table?: string;
  tablealias?: string;
  datetimetype?: string;
  startdatecolumn?: string;
  enddatecolumn?: string;
  tooltip?: string;
  outputdata?: OutputData = {};
  innerjoin?: string;
  tablecondition?: string;
  tabtype?: number;
  titleuse?: string;
  wtype?: number;
  im?: number;
  parentinf?:string
}

export class OutputData {
  type? : number = 0;
  classcd?: string = "";
  table?: string = "";
  joincolumn?: string = "";
  columnname?: string = "";
  format?: string= "";
  blankcondition?: string = "";
  condition?: string = "";
  constructor(data : any) {
    this.type =  data?.type ??  0 ;
    this.classcd = data?.classCd ?? "" ;
    this.table = data?.table ?? "";
    this.joincolumn = data?.joinColumn ?? "";
    this.columnname = data?.columnName ?? "";
    this.format = data?.format ?? "";
    this.blankcondition = data?.blankCondition ?? "";
    this.condition = data?.condition ?? "";
  }
}

/**
 * 0 : have use yet
 * 1 : use in table
 * 2 : rows
 * 3 : cols
 * 4 : value
 * 6 : value & rows | 4 + 2
 * 7 : value & cols | 4 + 3
 * 8: footer
 * 9: summaryColumn
 * 10: Hidden_Value
 */
 export type columntype = 0|1 |2|3| 4 |6|7| 8 | 9 | 10
 
 export class LazyTreeNode {
  id?: string = "";
  text?: string = "";
  level?: number = 0;
  parent?: LazyTreeNode;
  tooltip?: string = '';
  expanded: boolean = false;
  expandable?: boolean = false;
  node?: TreeNode;
  isFirstNode?: boolean = false;
  isLastNode?: boolean = false;
  showIcon?: boolean = false;
  draggable?: boolean = false;
  style?:  any = {};
  icon?: string = "";
  isFolder?: boolean;
  treeData?: TreeViewInputParams;
  lazyData?: LazyTreeNode[];
  isLastChild?: boolean = false;
  isParentLastChild?:boolean[] = [];
  selected: boolean = false;
  loading:boolean = false;
  isShowNoData: boolean = false;
 }

 export class TemplatedDialogItem {
  datasourceNm?: string = '';
  existDatasourceCd?: string = '';
  isOptionCreate?: boolean = false;
  officeCds?: any[] = [];
  period?: string = '';
  selectedFolder?: any;
  selectedOffices?: any[] = [];
  selectedRangeDate?: any;
}

export class ExcelHeader extends ListBoxItems {
  index: number = 0;
  dataType: any = undefined
  defaultValue?: string = ""
  typeColumn: 0 | 1 ; //0-orignal column (datasource column), 1= added column by user
  constructor(data: any, index: number) {
    super()
    this.index = index;
    this.dataType = "VARCHAR";
    this.data = data;
    this.name = data ;
    this.value = index;
  }
}


export class SettingTargetTable {
  id: string;
  type: 0 | 1 ; //0 = column, 1 = row
  targetColumn: any;
  valueColumn : any = [];
  lessThan: string; //Color code;
  greaterThan: string;

}

export class WidgetGraphSetting {
  targetColumn: any;
  graphType: any ;//: 0 | 1; // 0 - Bar 棒, 1 - Line 折れ線
  isSecondAxis: boolean;
  rowIndex: number;
}

export type bycd = { cd: string, labelcd: string, showIcon?: boolean, checkbox?: boolean , isLoaded?: boolean, isLastestNode?: boolean }