import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { cloneDeep } from 'lodash';
import { SAUCER_LOG_ACTION } from 'src/app/config/saucer-log.config';
import { COMMON_TEXT, DEFAULT_VALUE_SETTING } from 'src/app/const/text-common';
import { SearchType } from 'src/app/enum/common-enum';
import { SearchParams } from 'src/app/models/common-model';
import { SaucerLogService } from 'src/app/services/saucer-logs/saucer-log.service';
import { DefaultValueSettingDetail } from 'src/app/models/response/default-value-setting.ro';

@Component({
  selector: 'pivot-default-value-detail',
  templateUrl: './default-value-setting-detail.component.html',
  styleUrls: ['./default-value-setting-detail.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class DefaultValueSettingDetailComponent implements OnInit {

  @Input() item: any;
  @Input() listkbntag: any[] = [];
  @Input() listItemConditionActive: DefaultValueSettingDetail[];
  @Output() onChangeItem = new EventEmitter<any>();
  @Output() onDeleteItem = new EventEmitter<any>();
  COMMON_TEXT = COMMON_TEXT;
  DEFAULT_VALUE_SETTING = DEFAULT_VALUE_SETTING;
  searchConfigKbnTag: SearchParams = {
    type: SearchType.combo,
    placeholder: COMMON_TEXT.SEARCH,
    cssStyle: { height: '40px', margin: '10px 5px 10px 0px' },
    readonly: false,
    disabled: false,
    maxLength: 50,
    dataSource: this.listkbntag,
    defaultValue: '',
    comboDisplayText: 'name',
    selected: {},
    
  };

  searchConfigTTLTag: SearchParams = {
    type: SearchType.combo,
    placeholder: COMMON_TEXT.SEARCH,
    cssStyle: { height: '40px', margin: '10px 5px 10px 0px' },
    readonly: false,
    disabled: false,
    maxLength: 50,
    dataSource: [],
    defaultValue: '',
    comboDisplayText: 'name',
    selected: {},
  };

  SAUCER_LOG_ACTION = SAUCER_LOG_ACTION;

  constructor(private saucerLogService: SaucerLogService) {

  }

  async ngOnInit(): Promise<void> {
    this.loadDropdown();
  }

  ngOnChanges(){
    this.searchConfigKbnTag.borderRed = this.item.invalidKbntag;
    this.searchConfigTTLTag.borderRed = this.item.invalidTtltag;

    this.loadDropdown();
  }

  loadDropdown() {
    this.searchConfigKbnTag.dataSource = this.listkbntag;
    if(this.item.kbntag != null) {
      this.searchConfigKbnTag.defaultValue = this.item.kbntag;
      let lstTag = this.listkbntag.find((item:any) => item.kbntag == this.item.kbntag);
      let datattlTag: any[] = [];
      if(lstTag) {
        lstTag.ttltags.map((ttltag:any) =>{
          if(this.listItemConditionActive.some(x => x.ttltag == ttltag.ttltag && this.item.kbntag == ttltag.kbntag)) return;
          
          datattlTag.push({
            name: ttltag.ttltag,
            item: ttltag
          });
          
        })
      }
      if(this.item.ttltag != null) {
        this.searchConfigTTLTag.defaultValue = this.item.ttltag;
      }
      this.searchConfigTTLTag.dataSource = datattlTag;
      this.searchConfigTTLTag = cloneDeep(this.searchConfigTTLTag); 
    }
  }

  onClickBtnDelete(){
    this.onDeleteItem.emit(this.item)
  }

  onChangeKBNTag(event:any){
    let datattlTag: any[] = [];
    this.item.kbntag = event[0].name;
    this.item.ttltag = null,
    event[0].ttltags.map((ttltag:any) =>{
      datattlTag.push({
        name: ttltag.ttltag,
        item: ttltag
      })
    })
    this.searchConfigTTLTag.dataSource = datattlTag;
    this.searchConfigTTLTag.defaultValue = "";
    this.searchConfigTTLTag = cloneDeep(this.searchConfigTTLTag); 

    this.onChangeItem.emit(this.item);
  }
  onChangeTTLTag(event:any){
    this.item.ttltag = event[0].name;
    this.onChangeItem.emit(this.item);
  }

}
