<div class="image-cropper">
  <div ly-dialog-content>

    <div *ngIf="ready">
      <button class="icon-btn" (click)="cropper.zoomIn()" ly-button appearance="icon">
        <ly-icon>zoom_in</ly-icon>
      </button>
      <button class="icon-btn" (click)="cropper.zoomOut()" ly-button appearance="icon">
        <ly-icon>zoom_out</ly-icon>
      </button>
      <button class="icon-btn" (click)="cropper.center()" ly-button appearance="icon">
        <ly-icon>filter_center_focus</ly-icon>
      </button>
      <button class="icon-btn" (click)="cropper.rotate(-90)" ly-button appearance="icon">
        <ly-icon>rotate_90_degrees_ccw</ly-icon>
      </button>
      <button class="icon-btn" (click)="fileInput.click()" ly-button appearance="icon" [lyMf]="1">
        <ly-icon>image</ly-icon>
      </button>
      <!-- Hidden input -->
      <input hidden (change)="onSelectFile($event)" type='file' #fileInput>
    </div>

    <ly-img-cropper [config]="myConfig" [(scale)]="scale" (ready)="ready = true" (cleaned)="ready = false"
      (minScale)="minScale = $event" (cropped)="onCropped($event)" (loaded)="onLoaded($event)"
      (error)="onError($event)">
    </ly-img-cropper>
  </div>

  <div *ngIf="ready" class="flex image-cropper__footer" style="padding-top: 10px;">
    <!-- <button pButton type="button" label="{{BUTTON.CANCEL}}" class="p-mr-2 p-button-outlined p-ml-auto button-cancel "
      (click)="dialogRef.close()"></button>
    <button pButton type="button" label="{{BUTTON.SAVE}}" class="btn-confirm"
      (click)="dialogRef.close(cropper.crop())"></button> -->

      <pivot-text-button class="btn-add-dashboard" (btnClick)="dialogRef.close()" [buttonType]="buttonType.SECONDARY"
          [buttonText]="BUTTON.CANCEL">
      </pivot-text-button>
      <pivot-text-button class="btn-add-dashboard" (btnClick)="dialogRef.close(cropper.crop())"
          [buttonType]="buttonType.PRIMARY" [buttonText]="BUTTON.SAVE">
      </pivot-text-button>
  </div>
</div>
