import { EventEmitter, Injectable } from '@angular/core';
import {BehaviorSubject, Observable, Subscription} from 'rxjs';
import {HTTP_STATUS} from '../config/app.config';
import {ERROR_TEXT, SQL_ERROR_TITLE} from '../const/error-text';
import {ROUTE_PATH} from '../const/route-path';
import {Router} from '@angular/router';
import Utils from '../util/utils';
import {ProcessLoadingService} from './loading.service';
import {bool} from 'aws-sdk/clients/signer';
import {NOT_FOUND} from '../const/text-common';

export interface errorDialogTemplate {
  statuscode: number,
  title: string,
  isDisplayErrorDialog: boolean,
  subtitle: string,
  content: string,
  functionMessage: string;
}
@Injectable({
  providedIn: 'root'
})
export class ErrorHandleService {
  public responseValueSub: BehaviorSubject<errorDialogTemplate>;
  public responseValue: Observable<errorDialogTemplate>;
  public isByPassConfirmDialogSub: BehaviorSubject<boolean>;
  public isByPassConfirmDialog: Observable<boolean>;
  public backURLSub: BehaviorSubject<string>;
  public backURL: Observable<string>;
  apiServiceSubscriber: Subscription | undefined;
  errorContent: any = new EventEmitter<any>();
  functionTitle: string = "";
  isHasErrorDialogOrErrorScreenDisplay: boolean = false;

  isNotShowFunctionTitle: boolean = false;
  constructor( 
    private router: Router,
    private loadingService: ProcessLoadingService,) { 
    this.responseValueSub = new BehaviorSubject<any>("");
    this.responseValue = this.responseValueSub.asObservable();
    this.backURLSub = new BehaviorSubject<string>("");
    this.backURL = this.backURLSub.asObservable();
    this.isByPassConfirmDialogSub = new BehaviorSubject<boolean>(false);
    this.isByPassConfirmDialog = this.isByPassConfirmDialogSub.asObservable();
  }

  public errorRouting(error: any, data: any) {
    this.loadingService.isLoading.emit(false);
    this.isByPassConfirmDialogSub.next(true);
    let fuctionTitleHolder = this.isNotShowFunctionTitle ? "" :  this.functionTitle;
    this.isNotShowFunctionTitle = false;
    if(this.isHasErrorDialogOrErrorScreenDisplay == false) {
      this.isHasErrorDialogOrErrorScreenDisplay = true;
      switch(error) {
        case HTTP_STATUS.NOT_CONTENT:
          let res: errorDialogTemplate = {
            statuscode: error,
            title: ERROR_TEXT.NOT_CONTENT_TITLE,
            subtitle: ERROR_TEXT.NOT_CONTENT,
            content: "",
            functionMessage: fuctionTitleHolder,
            isDisplayErrorDialog: false
          };
          this.responseValueSub.next(res);
          this.navigateTo([ROUTE_PATH.PAGE_ERROR]);
          break;
        case HTTP_STATUS.BAD_GATEWAY:
          let res2: errorDialogTemplate = {
            statuscode: data.status,
            title: ERROR_TEXT.BAD_GATEWAY_TITLE,
            subtitle: ERROR_TEXT.BAD_GATEWAY,
            content: "",
            functionMessage: fuctionTitleHolder,
            isDisplayErrorDialog: false
          };
          this.responseValueSub.next(res2);
          this.navigateTo([ROUTE_PATH.PAGE_ERROR]);
          break;
        case HTTP_STATUS.BAD_REQUEST:
          let res3: errorDialogTemplate = {
            statuscode: data.status,
            title: ERROR_TEXT.BAD_REQUEST_TITLE,
            subtitle: ERROR_TEXT.BAD_REQUEST,
            content: "",
            functionMessage: fuctionTitleHolder,
            isDisplayErrorDialog: false
          };
          this.responseValueSub.next(res3);
          this.navigateTo([ROUTE_PATH.PAGE_ERROR]);
          break;
        case HTTP_STATUS.FORBIDDEN:
          let res4: errorDialogTemplate = {
            statuscode: data.status,
            title: ERROR_TEXT.FORBIDDEN_TITLE,
            subtitle: ERROR_TEXT.FORBIDDEN,
            content: "",
            functionMessage: fuctionTitleHolder,
            isDisplayErrorDialog: false
          };
          this.responseValueSub.next(res4);
          this.navigateTo([ROUTE_PATH.PAGE_ERROR]);
          break;
        case HTTP_STATUS.INTERBAL_SERVER_ERROR:
          if(data.error) {
            let errorDetails = data.error.error;
            if(errorDetails.sql && errorDetails.sql == true) {
              let head = errorDetails.reason.split("-");
              let titleStr = SQL_ERROR_TITLE['SELECT'];
              if(head.length == 2) {
                titleStr = this.getTitleErrorForSQLError(head[0]);
              }
              
              let res10: errorDialogTemplate = {
                statuscode: error,
                title: titleStr,
                subtitle:  `エラー: ${(head[1]) ? head[1] : "XXX"}`,
                content: "",
                functionMessage: "",
                isDisplayErrorDialog: true
             };
              this.responseValueSub.next(res10);
            }
            else if(errorDetails.lambda && errorDetails.lambda == true) {
              let res12: errorDialogTemplate = {
                statuscode: error || 500,
                title: ERROR_TEXT.LAMBDA,
                subtitle: "エラー: 500",
                content: "",
                functionMessage: fuctionTitleHolder,
                isDisplayErrorDialog: true
             };
              this.responseValueSub.next(res12);
            } 
            else if(errorDetails.other && errorDetails.other == true) {
              let res13: errorDialogTemplate = {
                statuscode: error,
                title: ERROR_TEXT.OHTHER,
                subtitle:  "",
                content: "",
                functionMessage: fuctionTitleHolder,
                isDisplayErrorDialog: true
             };
              this.responseValueSub.next(res13);
            } 
          }
          else 
          {
            let res5: errorDialogTemplate = {
              statuscode: data.status,
              title: ERROR_TEXT.INTERNAL_SERVER_ERROR_TITLE,
              subtitle: ERROR_TEXT.INTERNAL_SERVER_ERROR,
              content: "",
              functionMessage: fuctionTitleHolder,
              isDisplayErrorDialog: false
            };
            this.responseValueSub.next(res5);
            this.navigateTo([ROUTE_PATH.PAGE_ERROR]);
          }
          break;
        case HTTP_STATUS.TIME_OUT:
          let res6: errorDialogTemplate = {
            statuscode: data.status,
            title: ERROR_TEXT.TIME_OUT_TITLE,
            subtitle: ERROR_TEXT.TIME_OUT,
            content: "",
            functionMessage: fuctionTitleHolder,
            isDisplayErrorDialog: false
          };
          this.responseValueSub.next(res6);
          this.navigateTo([ROUTE_PATH.PAGE_ERROR]);
          break;
        case HTTP_STATUS.UNAUTHORIZED:
          let res7: errorDialogTemplate = {
            statuscode: data.status,
            title: ERROR_TEXT.UNAUTHORIZED_TITLE,
            subtitle: ERROR_TEXT.UNAUTHORIZED,
            content: "",
            functionMessage: fuctionTitleHolder,
            isDisplayErrorDialog: false
          };
          this.backURLSub.next(ROUTE_PATH.AUTH);
          this.responseValueSub.next(res7);
          this.navigateTo([ROUTE_PATH.PAGE_ERROR]);
          break;
        case HTTP_STATUS.NOT_FOUND:
          this.customErrorDetails(404, NOT_FOUND.TITLE, NOT_FOUND.CONTENT, "", NOT_FOUND.SUB_TITLE, false)
          this.navigateTo([ROUTE_PATH.PAGE_NOT_FOUND]);
          break;
        case 0:
          let res8: errorDialogTemplate = {
            statuscode: data.status,
            title: ERROR_TEXT.CONNECTION_ERROR_TITLE,
            subtitle: "",
            content: "",
            functionMessage: fuctionTitleHolder,
            isDisplayErrorDialog: true
          };
          this.responseValueSub.next(res8);
          break;
        default:
          let res9: errorDialogTemplate = {
            statuscode: data.status,
            title: ERROR_TEXT.BAD_GATEWAY_TITLE,
            subtitle: ERROR_TEXT.BAD_GATEWAY,
            content: "",
            functionMessage: fuctionTitleHolder,
            isDisplayErrorDialog: false
          };
          this.responseValueSub.next(res9);
          this.navigateTo([ROUTE_PATH.PAGE_ERROR]);
      }
    }
  }

  public setIsCloseErrorDialogOrErrorScreen(isCloseCurrentErrorElement: boolean) {
    this.isHasErrorDialogOrErrorScreenDisplay = !isCloseCurrentErrorElement;
  }

  public navigateTo(commands: any[] = []) {
    this.router.navigate(commands);
  }

  setFunctionTitle(titleString: any) {
    if(this.functionTitle != titleString)
      this.functionTitle = titleString;
  }

  getTitleErrorForSQLError(code: string): string {
    if(Utils.isNullOrEmpty(code)) {
      code = "SELECT"; //default value if got error
      return SQL_ERROR_TITLE[code];
    }
    code = code.trim().toLocaleUpperCase();
    if(code != "SELECT" && code != "INSERT" && code != "UPDATE" && code != "DELETE")
      code = "OTHER"
    return SQL_ERROR_TITLE[code];
  }

  openUnknowErrorDialog() {
    let res: errorDialogTemplate = {
      statuscode: 500,
      title: ERROR_TEXT.OHTHER,
      subtitle:  "",
      content: "",
      functionMessage: "",
      isDisplayErrorDialog: true
    };
    this.responseValueSub.next(res);
    this.isHasErrorDialogOrErrorScreenDisplay = true;
  }


  customErrorDetails(statusCode: number, titleContent: string, subTitle: string, contentMessage: string, funcMessage: string, isDisplayDialog: boolean) {
    let res: errorDialogTemplate = {
      statuscode: statusCode,
      title: titleContent,
      subtitle:  subTitle,
      content: contentMessage,
      functionMessage: funcMessage,
      isDisplayErrorDialog: isDisplayDialog
    };
    this.responseValueSub.next(res);
  }

  setIsNotShowFunctionTitle(isShow: boolean) {
    this.isNotShowFunctionTitle = isShow;
  }
}
