export class zaitakuSettingModel {
    id: string;
    officecd: string;
    jigno: string;
    siteino: string;
    configs: configColummModel[];
}

export class configColummModel {
    id: string;
    conditionname: string;
    sdate: string;
    edate: string;
    countingtype: number;
    columncd: string;
    conditions: conditionItemModel[]
}

export class conditionItemModel {
    id: string;
    ttlkbn: string;
    ttlcd: string;
    itemid: string;
    notekbn: string;
    dockbn: string;
    wtype: number;
    im?: number;
    itemname: string;
    comparison: string;
    val: string;
}

export class itemZaitakuAutoSyncModel {
    id: string;
    month: string;
    columncd: string;
}

export class requestSyncDataModel {
    syncItems: itemZaitakuAutoSyncModel[];
    admissions: any;
    dischargesToHome: any;
}