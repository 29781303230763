import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject, throwError } from 'rxjs';
import { catchError, filter, switchMap, take, takeUntil, tap } from 'rxjs/operators';

import { API_APP, HTTP_STATUS } from '../config/app.config';
import { AUTH_TOKEN_INTERCEPTOR_FILTER } from '../config/auth.config';
import { LocalStorageKey } from '../_helper/local-storage.helper';
import { AuthenticationService } from '../services/authentication.service';
import { HttpCancelService } from "../services/http-cancel.service";
import { environment } from '../../environments/environment';
import {ErrorHandleService} from '../services/error-handle.service';
import {checkIfUserIsSupporterOrAdmin} from './helper';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
    private refreshTokenInProgress = false;
    private refreshTokenSubject: Subject<any> = new BehaviorSubject<any>(null);

    constructor(
        private authService: AuthenticationService,
        private cancelService: HttpCancelService,
        private errorHandle : ErrorHandleService,
        @Inject(AUTH_TOKEN_INTERCEPTOR_FILTER)
        protected filterRequest: (req: HttpRequest<any>) => boolean
    ) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const user = this.authService.currentUserValue;
        const accessToken = user?.token;
        const isAllowCallAPI = this.authService.isAllowAllAfterAPICall;
        if(isAllowCallAPI) {
            if(!request.url.includes(environment.PIVOT_CLIENT_API_URL )){
                return next.handle(request);
              }
            if (request.url === (environment.PIVOT_CLIENT_API_URL + API_APP.AUTH.LOGIN_BY_TOKEN)) {
                const headerAuthorizationToken = request.headers.get('Authorization') || "";
                const headerRefreshToken = request.headers.get('refreshtoken') || "";
                return next.handle(request).pipe(
                    takeUntil(
                        this.cancelService.cancelPendingRequest$.pipe(
                            tap(() => { throw new Error('Request is canceled'); })
                        )
                    ),
                    catchError((error) => {
                        error.status = this.checkTimeOutOrConnectionError(error);
                        if (error.status === HTTP_STATUS.UNAUTHORIZED) {
                            if (!this.refreshTokenInProgress) {
                                this.refreshTokenInProgress = true;
                                this.refreshTokenSubject.next(null);
                                return this.authService.requestAccessToken(headerRefreshToken, headerAuthorizationToken).pipe(
                                    switchMap((res) => {
                                        this.refreshTokenInProgress = false;
                                        this.refreshTokenSubject.next(res.data.accesstoken);

                                        return next.handle(this.injectTokenForLoginViaTokenReq(request, res.data.accesstoken, headerRefreshToken)).pipe(
                                            takeUntil(
                                                this.cancelService.cancelPendingRequest$.pipe(tap(() => { throw new Error('Request is canceled'); }))
                                            )
                                        );
                                    }),
                                    catchError((e) => {
                                        e.status = this.checkTimeOutOrConnectionError(e);
                                        this.refreshTokenInProgress = false;
                                        this.errorHandle.errorRouting(e.status, e);
                                        return throwError(() => new Error(e));
                                    })
                                );
                            } else {
                                return this.refreshTokenSubject.pipe(
                                    filter(result => result !== null),
                                    take(1),
                                    switchMap((at) => {
                                        return next.handle(this.injectToken(request, user, at)).pipe(
                                            takeUntil(
                                                this.cancelService.cancelPendingRequest$.pipe(tap(() => { throw new Error('Request is canceled'); }))
                                            )
                                        );
                                    }),
                                );
                            }
                        }
                        return throwError(() => new Error(error));
                    })
                );
            } else if (!this.filterRequest(request) && !(request.body instanceof File) && accessToken) {
                if (request.url.includes(API_APP.AUTH.REFRESH_TOKEN)) {
                    return next.handle(this.injectToken(request, user, accessToken)).pipe(
                        takeUntil(
                            this.cancelService.cancelPendingRequest$.pipe(
                                tap(() => { throw new Error('Request is canceled'); })
                            )
                        ),
                        catchError((error: any) => {
                            error.status = this.checkTimeOutOrConnectionError(error);
                            this.errorHandle.errorRouting(error.status, error);
                            return throwError(() => new Error(error));
                            })
                    );
                }
                return next.handle(this.injectToken(request, user, accessToken)).pipe(
                    takeUntil(
                        this.cancelService.cancelPendingRequest$.pipe(
                            tap(() => { throw new Error('Request is canceled'); })
                        )
                    ),
                    catchError((error) => {
                        error.status = this.checkTimeOutOrConnectionError(error);
                        if (error.status === HTTP_STATUS.UNAUTHORIZED) {
                            if (!this.refreshTokenInProgress) {
                                this.refreshTokenInProgress = true;
                                this.refreshTokenSubject.next(null);
                                return this.authService.requestAccessToken().pipe(
                                    switchMap((res) => {
                                        console.log(res);
                                        this.refreshTokenInProgress = false;
                                        this.refreshTokenSubject.next(res.data.accesstoken);
                                        
                                        // set new token
                                        const currentUser = this.authService.getData(LocalStorageKey.CURRENT_USER);
                                        currentUser.token = res.data.accesstoken;
                                        currentUser.refreshtoken = res.data.refreshtoken;
                                        this.authService.setCurrentUser(currentUser);

                                        return next.handle(this.injectToken(request, user, res.data.accesstoken)).pipe(
                                            takeUntil(
                                                this.cancelService.cancelPendingRequest$.pipe(tap(() => { throw new Error('Request is canceled'); }))
                                            )
                                        );
                                    }),
                                    catchError((e) => {
                                        this.refreshTokenInProgress = false;
                                        e.status = this.checkTimeOutOrConnectionError(e);
                                        if (e.status === HTTP_STATUS.UNAUTHORIZED || e?.error?.responemessage === "ERROR_GET_NEW_TOKEN") {
                                            this.authService.logout();
                                        }
                                        else
                                            this.errorHandle.errorRouting(e.status, e);
                                        return throwError(() => new Error(e));
                                    })
                                );
                            } else {
                                return this.refreshTokenSubject.pipe(
                                    filter(result => result !== null),
                                    take(1),
                                    switchMap((at) => {
                                        return next.handle(this.injectToken(request, user, at)).pipe(
                                            takeUntil(
                                                this.cancelService.cancelPendingRequest$.pipe(tap(() => { throw new Error('Request is canceled'); }))
                                            )
                                        );
                                    }),
                                );
                            }
                        }
                        else
                            this.errorHandle.errorRouting(error.status, error);
                        return throwError(() => new Error(error));
                    })
                );
            } else {
                return next.handle(request);
            }
        }
        else 
            return throwError(() => new Error());
    }

    injectToken(request: HttpRequest<any>, user: any, accessToken: string): HttpRequest<any> {
        let currentUser = this.authService.currentUserValue;
        let isAdminOrSupporter = checkIfUserIsSupporterOrAdmin(currentUser);
        return request.clone({
            setHeaders: {
                Authorization: `${accessToken}`,
                staffcd: `${user.staffcode}`,
                corpcd: '1',
                consoletoken: `${user.consoletoken}` || '',
                companyId: `${this.authService.companyIdValue}`,
                isadminorsupport: isAdminOrSupporter ? '1' : '0',
                custid: user.companylist ? user.companylist[0].custid.toString() : "",
                stage: user.loginstage ? user.loginstage : "auth"
            },
        });
    }

    injectTokenForLoginViaTokenReq(request: HttpRequest<any>, accessToken: string, refreshToken: string): HttpRequest<any> {
        return request.clone({
            setHeaders: {
                Authorization: `${accessToken}`,
                refreshtoken: `${refreshToken}`,
                module: 'PASS'
            },
        });
    }

    checkTimeOutOrConnectionError(error: any) {
        if (error.error instanceof ProgressEvent && error.status == 0) {
            if (navigator.onLine) {
                error.status = 504;
            }
        }
        return error.status;
    }
}
