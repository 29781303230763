import { ActivityLog, BaseModel } from "../api-model";

export class DataSource extends ActivityLog {
  id?: string;
  dsstructCd?: string;
  folderCd?: string; // フォルダ
  folderName?: string;
  name: string; // データソース名
  folderId: string; // フォルダ
  description?: string;
  config: DataSourceConfig; // 事業所選択 & 列
  lastSync?: string; // 最終日更新
  delflg?: boolean;
}

export class DataSourceList extends ActivityLog {
  id?: string;
  folderId?: string; // フォルダ
  dsstructCd?: string;
  folderCd?: string; // フォルダ
  folderName?: string;
  name: string; // データソース名
  description?: string;
  lastSync?: string; // 最終日更新
  delflg?: boolean;
}

export class DataSourceConfig {
  offices: Array<string>; // 事業所選択 Array of officeId
  columns: Array<Column>; // 列
  period: number; // 期間 (1.今月, 2.先月, 3.先々月, 4.本年, 5.前年)
  queryString: string; // 参照データ取得
}

export class Column {
  id?: string;
  itemId: string; // "officeId-ttlKbn-ttlCd-itmId"
  itemName?: string; // tagName or fieldName
  isTag?: string; // if isTag is true then itemId = ''
}

export class DatasourceSyncDataModel extends BaseModel {
  datasourceCd: string;
  dsName: string;
  wgUsed: string;
  staff: string;
  date: string;
  status: string;
  'statusMessage-subText': string;
  statusMessage: string;
  foldercd: string;
  lastsync?: string;
  officeidlst?: string;
  period?: string;
  querystr?: string;
  columnList?: string;
  checked: boolean = false;
  progressResult: boolean = true;
  startdate?: string;
  enddate?: string;
  dstype: number;
  aggregationtype: string;
  dspageflg?: boolean = false;
  dspblockunit?: boolean = false;
  calcage?: Date;
  lapsedday?: Date;
  widgetname: string;
  widgetcd: string;
  dashboardname: string;
}

export class DatasourceSyncModel {
  id: string;
  packcd: string;
  dsstructcd: string;
  dsname: string;
  querystr: string;
  startdate: string;
  enddate: string;
  columnlist: string;
  officeidlst: string;
  dstype: number;
  period: string;
  aggregationtype: string;
  dspageflg?: boolean = false;
  dspblockunit?: boolean = false;
  calcage?: Date;
  lapsedday?: Date;
  relatedwidgetlist: string[]
}




