import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { ButtonType, InputType, SearchType } from '../../../enum/common-enum';
import { ModalTemplate, SearchParams } from '../../../models/common-model';
import { BUTTON, COMMON_TEXT, CREATE_DASHBOARD_TEMPLATE, FOLDER_DIALOG } from '../../../const/text-common';
import { Office } from 'src/app/models/response/office.ro';
import { cloneDeep} from 'lodash';
import { FolderService } from 'src/app/services/modules/folder.service';
import { FOLDER_TYPE } from 'src/app/models/response/folder.ro';
import { bool } from 'aws-sdk/clients/signer';
import { SAUCER_LOG_ACTION, SaucerLogService } from 'src/app/services/saucer-logs/saucer-log.service';

@Component({
  selector: 'pivot-dialog-select-datasource',
  templateUrl: './dialog-select-datasource.component.html',
  styleUrls: ['./dialog-select-datasource.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class DialogSelectDataSourceComponent implements OnInit, OnDestroy  {
  @Input() data: any;
  @Output() closeModalHandler = new EventEmitter();
  assignPermission: boolean = false;

  CREATE_DASHBOARD_TEMPLATE = CREATE_DASHBOARD_TEMPLATE;
  COMMON_TEXT = COMMON_TEXT;
  modalTemplate: ModalTemplate = new ModalTemplate();
  BUTTON = BUTTON;
  buttonType = ButtonType;

  inputDesParams: any = {
    type: InputType.text,
    validate: false,
    borderFill: true,
    maxLength: 200,
    disabled: false
  };

  searchFolderParams: SearchParams = {
		type: SearchType.combo,
    cssStyle: { width: '390px', height: '40px', 'font-size': '0.875rem' },
		readonly: false,
		disabled: false,
		maxLength: 100,
		defaultValue: '',
		comboDisplayText: 'name',
    placeholder: COMMON_TEXT.SELECTED
	};

  officeSelecteds: Array<Office> = [];
  selectedDtsFolder: any = null;
  folderSelectCurrent: any = null;
  isValidateDtsFolder: boolean = false;
  datasourceNm: string = '';
  dtsNameExist: string = '';
  selectedExistDs: any[] = [];
  existDatasources: any[] = [];
  existDatasource: any = null;
  folderList: any[] = [];

  dashboardSelecteds: Array<any> = [];
  isOptionCreate: boolean = true;
  isShowMsgExistDs: boolean = false;
  selectedPeriod: any = {};
  selectedRangeDate: any = {};
  datasourceCd: string = '';
  isSupporterAdmin: boolean = false;

  listDashboards: Array<any> = [];
  filterDashboard: Array<any> = [];
  isLoading: bool = false;
  isDisplayFolderModal = false;
  modalFolder: ModalTemplate = new ModalTemplate();
  folderType: number = FOLDER_TYPE.Datasource;

  SAUCER_LOG_ACTION = SAUCER_LOG_ACTION;

  constructor(
    private folderService: FolderService,
    private saucerLogService: SaucerLogService
  ){}

  async ngOnInit() {
    let dialogData: any = this.data;
    this.modalTemplate.header = CREATE_DASHBOARD_TEMPLATE.DATASOURCE_DISPLAY_DESTINATION;
    if(dialogData) {
      this.dashboardSelecteds = dialogData.dashboardSelecteds;
      this.existDatasources = dialogData.existDatasources;
      this.isOptionCreate = dialogData.isOptionCreate;
      this.inputDesParams.disabled = this.isOptionCreate? false : true;
      this.isShowMsgExistDs = dialogData.isShowMsgExistDs;
      this.selectedPeriod = dialogData.selectedPeriod;
      this.selectedRangeDate = dialogData.selectedRangeDate;
      this.datasourceCd = dialogData.datasourceCd;
      this.datasourceNm = !this.isOptionCreate? '' : dialogData.datasourceNm;
      this.isSupporterAdmin = dialogData.isSupporterAdmin;
      this.officeSelecteds = dialogData.officeSelecteds;
      this.folderList = dialogData.folderList || [];
      this.selectedDtsFolder = !this.isOptionCreate? null : dialogData.selectedDtsFolder;
      this.selectedExistDs = dialogData.selectedExistDs || [];
      this.folderSelectCurrent = cloneDeep(this.selectedDtsFolder);
    }
    await this.getListFolderDatasource();
  }

  async getListFolderDatasource(reLoad = false) {
    if(this.folderList.length && !reLoad) {
      this.setSearchFolderParams(this.selectedDtsFolder?.folderCd);
    } else {
      this.isLoading = true;
      await this.folderService.getListFolderHavePermissionEdit(FOLDER_TYPE.Datasource.toString(), this.isSupporterAdmin).then((resutl: any) => {
          if (resutl.statuscode == 200) {
              this.folderList = resutl?.data || [];
              this.setSearchFolderParams(null);
          }
          this.isLoading = false;
      });
    }
  }

  setSearchFolderParams(selectedItem: any) {
    this.searchFolderParams.dataSource = this.folderList.map(x => {x.value = x.folderCd; return x;});
    if(selectedItem)
      this.searchFolderParams.selected = selectedItem;
    this.searchFolderParams = cloneDeep(this.searchFolderParams);
  }

  ngOnDestroy(): void {
    this.cancel();
  }

  cancel() {
    const data = {
      isCancel: true,
      folderList: this.folderList,
      selectedDtsFolder: this.folderSelectCurrent,
    }
    this.closeModalHandler.emit(data);
  }

  save() {
    if(this.selectedExistDs?.length > 0) {
      this.existDatasource = this.selectedExistDs[0];
      this.dtsNameExist = this.selectedExistDs[0].dsname || '';
    }

    const data = {
      isCancel: false,
      selectedDtsFolder: this.selectedDtsFolder,
      datasourceNm: this.isOptionCreate? this.datasourceNm : this.dtsNameExist,
      isOptionCreate: this.isOptionCreate,
      folderList: this.folderList,
      existDatasource: this.existDatasource,
      selectedExistDs: this.selectedExistDs
    }

    //Log
    const newLog = {
      datasourceCd: this.isOptionCreate? this.datasourceCd : undefined,
      datasourceNm: this.isOptionCreate? this.datasourceNm : undefined,
      folderCd: this.isOptionCreate? this.selectedDtsFolder?.folderCd : undefined,
      folderName: this.isOptionCreate? this.selectedDtsFolder?.folderName : undefined,
      selectedExistDs: !this.isOptionCreate && this.selectedExistDs.length ? {
        dsstructcd: this.selectedExistDs[0].dsstructcd,
        dsname: this.selectedExistDs[0].dsname,
      } : undefined
    }
    this.saucerLogService.action({
      content: JSON.stringify({
        old: null,
        new: newLog
      })
    }, { 
      action: SAUCER_LOG_ACTION.MENU_TEMPLATE.DATASOURCE_SAVE_DESTINATION_SETTING_DIALOG.SELECT
    });

    this.closeModalHandler.emit(data);
  }

  disableButtonSave() {
    if(this.isOptionCreate) {
      if(!this.selectedDtsFolder || !this.datasourceNm) return true;
      else return false;
    }
    else {
      if(this.selectedExistDs && this.selectedExistDs.length > 0) return false;
      else return true;
    }
  }

  onCheckboxDts(option: number) {
    if (option == 1) {
      this.isOptionCreate = true
      this.inputDesParams.disabled = false;
      if(this.selectedDtsFolder) {
        this.searchFolderParams.selected = this.selectedDtsFolder?.folderCd;
        this.searchFolderParams = cloneDeep(this.searchFolderParams);
      }
    } else if (option == 2 && this.existDatasources?.length > 0) {
      this.isOptionCreate = false;
      this.inputDesParams.disabled = true;
      if (!this.isShowMsgExistDs) return;
      this.isValidateDtsFolder = false;
    }
  }

  selectedFolder(event: any) {
    if(!event || !event.length) return;
    this.selectedDtsFolder = event[0];
  }

  onInputDescription(event: any) {
    this.datasourceNm = event.currentTarget?.value;
  }

  onSelectionDatasourceChange(event: any) {
    if (this.isOptionCreate) return
    this.selectedExistDs = event.value
    this.existDatasource = this.selectedExistDs[0]
    this.dtsNameExist = event.value[0]?.dsname
  }

  // open dialog add new folder
  openDialogAddFolder() {
    this.modalFolder.header = FOLDER_DIALOG.FOLDER_DLG_CREATE;
    this.modalFolder.style = { 'width': '393px' };
    this.modalFolder.breakpoints = { '960px': '75vw', '640px': '100vw' };
    this.isDisplayFolderModal = true;

    //Log
    this.saucerLogService.action({}, { 
      action: SAUCER_LOG_ACTION.MENU_TEMPLATE.DATASOURCE_SAVE_DESTINATION_SETTING_DIALOG.ADD
    });
    
  }

  // change data for folder
  async onChangeDataFolder(data: any) {
    this.isDisplayFolderModal = false;
    if(data) {
      await this.getListFolderDatasource(true);

      let findFolder = this.folderList?.find(s=>s.name == data?.name);
      this.selectedDtsFolder = null;
      if(findFolder) {
        this.mapFolderData();
        findFolder.value = findFolder.folderCd
        this.selectedDtsFolder = findFolder;
        if(findFolder?.folderCd) this.searchFolderParams.selected = findFolder.folderCd;
        this.searchFolderParams = cloneDeep(this.searchFolderParams);


      }
      this.isLoading = false;


    }
  }

  mapFolderData() {
    this.searchFolderParams.dataSource = this.folderList?.map(x => {
      x.value = x.folderCd
      return x;
    });
  }
}
