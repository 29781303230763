import { toFullwidth, toFullwidthKana } from "japanese-string-utils";

export default class StringUtils {

    // Covert half width to full width
    public static toFullWidthJP(str: string | undefined): string {
        if (str == undefined) return '';
        return toFullwidth(toFullwidthKana(str));
    }

    /**
    * count byte for string
    */
    public static checkValidateInput(maxlength: number, event: any) {
        let len = 0;
        let tempstr = event;
        if (tempstr != undefined) {
            for (let i = 0; i < tempstr.length; i++) {
                let code = tempstr.charCodeAt(i);
                if ((code >= 0x0 && code < 0x81) || (code === 0xf8f0) || (code >= 0xff61 && code < 0xffa0) || (code >= 0xf8f1 && code < 0xf8f4)) {
                    len += 1;
                } else {
                    len += 2;
                }
            }
        }
        if (len <= maxlength) {
            return true;
        }
        else {
            return false;
        }
    }
}