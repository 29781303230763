import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { SYNCDATA_STATUS } from '../../../app/const/text-common';
import { API_APP, PIVOT_CLIENT_API_URL } from '../../../app/config/app.config';
import { DATE_MIN_STRING } from '../../../app/const/const';
import { DateFormat, FormatType, SyncDataStatus } from '../../../app/enum/common-enum';
import { ResponseData } from '../../../app/models/api-model';
import { DataSourceList, DatasourceSyncDataModel } from '../../../app/models/response/datasource.ro';
import { ApiService } from '../api.service';
import {ErrorHandleService} from '../error-handle.service';
import { DataSearch } from 'src/app/models/request/datasource.dto';
import { ListBoxItems } from 'src/app/models/common-model';
import { Observable } from 'rxjs';
import { SaucerLogService } from '../saucer-logs/saucer-log.service';
@Injectable({ providedIn: 'root' })
export class DataSourceService extends ApiService {
  public sessionDatasourceByFolder : any =[];
  constructor(
    http: HttpClient, 
    router: Router, 
    errorHandle: ErrorHandleService,
    private saucerLogService : SaucerLogService
  ) 
  {
    super(http, router, errorHandle);
  }

  async getByFolder(dataSource: string) {
    let parameter = [
      dataSource
    ];
    let apiUrl = API_APP.DATASOURCE.CONTROLLER + API_APP.DATASOURCE.FOLDER;
    let resData = await this.get(apiUrl, parameter);
    this.sessionDatasourceByFolder = resData?.data || [];
    return await this.mapModel(resData);
  }

  // データ更新
  async getDataUpdate(isSupporterOrAdmin?: boolean) {
    isSupporterOrAdmin = (isSupporterOrAdmin) ? isSupporterOrAdmin : false
    let apiUrl = API_APP.DATASOURCE.CONTROLLER + API_APP.DATASOURCE.GETDATAUPDATE+ "/" + isSupporterOrAdmin;
    let resData = await this.get(apiUrl);
    return await this.mapModelDataUpd(resData);
  }
  
  async getTableWithFilter(datasourceCd: string, widgetCd: string, dashboardName: string,isSupporterOrAdmin?: boolean){
    const dataSearch = new DataSearch();
    dataSearch.datasourceCd = datasourceCd;
    dataSearch.widgetCd = widgetCd;
    dataSearch.dashboardName = dashboardName;
    let apiUrl = API_APP.DATASOURCE.CONTROLLER + API_APP.DATASOURCE.GETDATAUPDATEWITHFILTER + "/" + isSupporterOrAdmin;;
    let resData = await this.post(apiUrl,[],dataSearch);
    return await this.mapModelDataUpd(resData);
  }

  async mapModelDataUpd(data: any) {
    let res: ResponseData<DatasourceSyncDataModel[]> = new ResponseData<DatasourceSyncDataModel[]>();
    let dataArr = [];
    res.statuscode = data.statuscode;
    res.statusmessage = data.statusmessage;
    if (res.statuscode === 200) {
      for await (const item of data.data) {
        let ds = new DatasourceSyncDataModel();
        ds.id = item.id;
        ds.datasourceCd = item.dsstructcd;
        ds.dsName = item.dsname;
        ds.wgUsed = item.widgetnamelst;
        ds.statusMessage = SYNCDATA_STATUS.NOT_UPDATED;
        ds.status = SyncDataStatus.NOT_UPDATED;
        ds.querystr = item.querystr;
        ds.foldercd = item.foldercd
        ds.officeidlst = item.officeidlst;
        ds.period = item.period;
        ds.columnList = item.columnlist;
        ds.dstype = item.dstype;
        ds.aggregationtype = item.aggregationtype;
        ds.dspblockunit = item.dspblockunit;
        ds['statusMessage-subText'] = "";
        ds.dashboardname = item.dashboardname;
        ds.widgetname = item.widgetname;
        ds.widgetcd = item.widgetcd;
        if (item.updstfcd) {
          ds.staff = item.updstfnm;
          ds.date = (moment(item.upddate)).format(DateFormat.FULL_SHORT_DATE);
        } else {
          ds.staff = item.insstfnm;
          ds.date = (moment(item.insdate)).format(DateFormat.FULL_SHORT_DATE)
        }
        ds.lastsync = item.lastsync ? (moment(item.lastsync)).format(DateFormat.FULL_SHORT_DATE) : ds.date;
        dataArr.push(ds);
      }
    }
    res.data = dataArr;
    return res;
  }

  async mapModel(data: any) {
    let res: ResponseData<DataSourceList[]> = new ResponseData<DataSourceList[]>();
    let dataArr = [];
    res.statuscode = data.statuscode;
    res.statusmessage = data.statusmessage;
    if (res.statuscode === 200) {
      for await (const src of data.data) {
        let dataSource = new DataSourceList();
        dataSource.id = src.id;
        dataSource.name = src.dsname;
        dataSource.description = src.dsdesc;
        dataSource.folderCd = src.foldercd;
        dataSource.id = src.id;
        dataSource.insdate = src.insdate;
        dataSource.insstfcd = src.insstfcd;
        dataSource.dsstructCd = src.dsstructcd;
        dataSource.lastSync = src.lastsync;
        dataSource.upddate = src.upddate;
        dataSource.updstfcd = src.updstfcd;
        dataSource.delflg = src.delflg;
        dataSource.insstfnm = src.insstfnm;
        dataSource.updstfnm = src.updstfnm;

        if (src.updstfcd) {
          dataSource.displayNm = src.updstfnm;
          dataSource.displayDate = src.upddate && src.upddate !== DATE_MIN_STRING ? (moment(src.upddate)).format(DateFormat.FULL_SHORT_DATE) : '';
        } else {
          dataSource.displayNm = src.insstfnm;
          dataSource.displayDate = src.insdate && src.insdate !== DATE_MIN_STRING ? (moment(src.insdate)).format(DateFormat.FULL_SHORT_DATE) : '';
        }

        dataArr.push(dataSource);
      }
    }
    res.data = dataArr;
    return res;
  }

  async delete(data: string[], actionLog: any) {
    let uri = API_APP.DATASOURCE.CONTROLLER + API_APP.DATASOURCE.DELETE;
    let dataDeleted = this.sessionDatasourceByFolder?.filter((item: any) => data.includes(item.dsstructcd));
    let oldData = dataDeleted?.map((item: any) => ({ dsstructcd: item.dsstructcd, dsname: item.dsname }));
    const apiPath = uri.replace(PIVOT_CLIENT_API_URL, "");
    let resData = await this.post(uri, [], data)
    let content = JSON.stringify({
      old: oldData,
      new: null
    });
    if (resData?.statuscode === 200) {
      this.saucerLogService.system({
        apiPath,
        body: resData,
        statusCode: resData.statuscode,
        content
      }, { action: actionLog?.DELETE_DATASOURCE });
    } else {
      this.saucerLogService.error({
        apiPath,
        body: resData,
        statusCode: resData.statuscode,
        content
      }, { action: actionLog?.DELETE_DATASOURCE });
    }
    return resData;
  }

  createAndUpdate(bodyRequest: any) {
    let uri = API_APP.DATASOURCE.CONTROLLER + API_APP.DATASOURCE.INSERTORUPDATE;
    return this.post(uri, [], bodyRequest);
  }

  countTitleMappingByOffices(bodyRequest: any): Promise<any> {
    let apiUrl = API_APP.TITLE.CONTROLLER + API_APP.TITLE.OFFICE + '/count';
    return this.post(apiUrl, [], bodyRequest);
  }

  getTitleAndTagFromOffice(requestBody: any): Promise<any> {
    let apiUrl = API_APP.TITLE.CONTROLLER + API_APP.TITLE.OFFICE;
    return this.post(apiUrl, [], requestBody);
  }

  getTagDataByList(bodyRequest: any): Promise<any> {
    let apiUrl = API_APP.TITLE.CONTROLLER + API_APP.TITLE.GETTAGDATAL;
    return this.post(apiUrl, [], bodyRequest);
  }

  getDataSourceStructByCode(dsStructCode: string) {
    let apiUrl = API_APP.DATASOURCE.CONTROLLER + API_APP.DATASOURCE.DETAIL + '/' + dsStructCode;
    return this.get(apiUrl,);
  }


  async concurrentPromisePool<T>(tasks: (() => Promise<T>)[], limit: number): Promise<T[]> {
    const results: T[] = [];
    for (let i = 0; i < tasks.length; i += limit) {
      const group = tasks.slice(i, i + limit);
      const executing: Promise<T>[] = [];
  
      for (const task of group) {
        const p = Promise.resolve().then(task);
        executing.push(p);
      }
  
      const groupResults = await Promise.all(executing);
      results.push(...groupResults);
    }
  
    return results;
  }


  async getPaginatedDataAsync(urlS3:string): Promise<any> {
    return this.get(urlS3).then(x=>x);
  }

  async getDataSourcePreviewFromS3(CD: string, isTemplate: boolean = false): Promise<any> {
    let apiUrl = API_APP.DATASOURCE.CONTROLLER + API_APP.DATASOURCE.RED_DATA_TOP100;

    const bodyRequest = {
      dSStructCD: CD,
      isTemplate: isTemplate
    }
    let res = await this.post(apiUrl, [], bodyRequest);
    let data = [];
    if (res.statuscode === 200)
      data = res.data;

    return {
      "statuscode": data.statuscode,
      "data": data
    };
  }

  //Should remove function getUrlDataS3
  async getUrlDataS3(dSStructCD: string, isTemplate:boolean): Promise<any> {
    let apiUrl = API_APP.DATASOURCE.CONTROLLER + API_APP.DATASOURCE.GETDATASOURCES3;
    return this.post(apiUrl,[isTemplate],[dSStructCD]).then(x => {
      return x.data
    });
  }

  getBasicInfo(dsStructCode: string) {
    let parameter = [
      dsStructCode
    ];
    let apiUrl = API_APP.DATASOURCE.CONTROLLER + API_APP.DATASOURCE.GETBASICINFO;
    return this.get(apiUrl, parameter);
  }


  async previewDataSourceTable(bodyRequest: any, actionLog: any): Promise<any> {
    let apiUrl = API_APP.DATASOURCE.CONTROLLER + API_APP.DATASOURCE.PREVIEW;
    const apiPath = apiUrl.replace(PIVOT_CLIENT_API_URL, "");
    let resData = await this.post(apiUrl, [], bodyRequest);
    if (resData.statuscode === 200) {
      this.saucerLogService.system({
        apiPath,
        body: resData,
        statusCode: resData.statuscode,
      }, {action: actionLog?.PREVIEW});
    } else {
      this.saucerLogService.error({
        apiPath,
        statusCode: resData.statuscode,
        body: resData
      }, {action: actionLog?.PREVIEW});
    }

    return resData;
  }

  syncData(bodyRequest: any, packcd: string) {
    let uri = API_APP.DATASOURCE.CONTROLLER + API_APP.DATASOURCE.SYNCDATA + "/" + packcd;
    return this.postObservable(uri, [], bodyRequest);
  }

  async getListDatasourceCds(widgets: any[], isTemplate:boolean) {
    let uri = API_APP.DATASOURCE.CONTROLLER + API_APP.DATASOURCE.GETLISTDATASOURCECD;
    if(isTemplate){
      uri = API_APP.DATASOURCE.CONTROLLER + API_APP.DATASOURCE.GETLISTDATASOURCECDTEMPLATE;
    }
    let resData = await this.post(uri, [], widgets);
    return resData;
  }
  
  async cloneDataSourceTemplate(bodyRequest: any) {
    let uri = API_APP.DATASOURCE.CONTROLLER + API_APP.DATASOURCE.CLONETEMPLATE;
    return this.post(uri, [], bodyRequest);
  }

  async previewDataSourceTemplate(bodyRequest: any) {
    let uri = API_APP.DATASOURCE.CONTROLLER + API_APP.DATASOURCE.PREVIEWTEMPLATE;
    return this.post(uri, [], bodyRequest);
  }

  async checkTitleExist(bodyRequest: any) {
    let uri = API_APP.DATASOURCE.CONTROLLER + API_APP.DATASOURCE.CHECK_TITLE_EXIST;
    return this.post(uri, [], bodyRequest);
  }

  async deleteUnsedDataSourceTemplate(datasourceCd: string): Promise<any> {
    let apiUrl = API_APP.DATASOURCE.CONTROLLER + API_APP.DATASOURCE.DELTEDSTEMPLATE;
    return await this.post(apiUrl, [], [datasourceCd]);
  }


  async getTitleByTag(bodyRequest: any): Promise<any> {
    let apiUrl = API_APP.TITLE.CONTROLLER + API_APP.TITLE.GETTITLEBYTAG;
    return this.post(apiUrl, [], bodyRequest);
  }

  async getExistDatasources(bodyRequest: any) {
    let apiUrl = API_APP.DATASOURCE.CONTROLLER + API_APP.DATASOURCE.EXIST;
    return this.post(apiUrl, [], bodyRequest);
  }

  async getDataSourceType(datasource: any): Promise<any> {
    let apiUrl = API_APP.DATASOURCE.CONTROLLER + API_APP.DATASOURCE.GETDSTYPE;
    return this.post(apiUrl, [], datasource);
  }

  getUsedWidgetByDatasourceCd(datasourceCd: string): Promise<any> {
    let apiUrl = API_APP.DATASOURCE.CONTROLLER + "/" + datasourceCd + API_APP.WIDGET.GETUSEDWIDGETS;
    return this.get(apiUrl);
  }

  getResultOnS3(datasourceCd: string): Promise<any> {
    let apiUrl = API_APP.DATASOURCE.CONTROLLER + API_APP.DATASOURCE.GETRESULTS3 + "/"+ datasourceCd;
    return this.get(apiUrl);
  }

  async getHeaderForDatasource(items: string[]) {
    const apiUrl = API_APP.DATASOURCE.CONTROLLER + API_APP.DATASOURCE.GETHEADERTABLE;
    const resData = await this.post(apiUrl, undefined, items);
    return resData;
  }

  async getDatasourceTemplate(dsStructCode: string) {
    let parameter = [
      dsStructCode
    ];
    let apiUrl = API_APP.DATASOURCE.CONTROLLER + API_APP.DATASOURCE.GETDATASOURCETEMPLATE;
    return this.get(apiUrl, parameter);
  } 
  
  getDatasourceByIndexUrls(selectedItems: any, dSStructCD: string, isTemplate:boolean, lastProcessedKey: string, dsType: any = 1): Promise<any> {
    const apiUrl = API_APP.DATASOURCE.CONTROLLER + API_APP.DATASOURCE.GET_DATAOSOURCE_BY_INDEXURLS;
    // Update NoFomat when get data raw
    selectedItems = selectedItems.map((x:any) => {
      if (x?.formattype?.includes('A')) {
        x.formattype = FormatType.NoFormatA
      }
      return x;
    })
    const body = {
      selectedItems : selectedItems,
      dSStructCD: dSStructCD,
      isTemplate: isTemplate,
      lastProcessedKey: lastProcessedKey,
      dsType: dsType
    }
    return this.post(apiUrl, undefined, body).then(x => {
      return x
    });
  }

  getCountS3ByDSStructCD(dSStructCD: string, isTemplate:boolean): Promise<any> {
    const apiUrl = API_APP.DATASOURCE.CONTROLLER + API_APP.DATASOURCE.GET_COUNT_S3_BY_DSSTRUCTCD;
    const body = {
      dSStructCD: dSStructCD,
      isTemplate: isTemplate
    }
    return this.post(apiUrl, undefined, body).then(x => {
      return x.data
    });
  }
  getDistinctColumnValueFromDS(datasourceCd: string, isTemplate:boolean, columnName: string, indexes: number[]): Observable<any> {
    const apiUrl = API_APP.DATASOURCE.CONTROLLER + API_APP.DATASOURCE.GET_DISTINCT_COLUMN_VALUE_FROM_DS;
    const body = {
      datasourceCd: datasourceCd,
      isTemplate: isTemplate,
      columnName: columnName,
      indexes: indexes,
    }
    return this.postObservable(apiUrl, undefined, body);
  }
  getMinMaxByColumnName(dSStructCD: string, isTemplate:boolean, columnName: string, selectedItems: ListBoxItems[]) {
    const apiUrl = API_APP.DATASOURCE.CONTROLLER +  API_APP.DATASOURCE.GET_MIN_MAX_BY_COLUMN_NAME;
    const body = {
      dSStructCD: dSStructCD,
      isTemplate: isTemplate,
      columnName: columnName,
      selectedItems: selectedItems
    }
    return this.post(apiUrl, undefined, body).then(x => {
      return x.data
    });
  }
}
