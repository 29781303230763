<pivot-modal-template *ngIf="isDisplayModal" [modalTemplate]="modalData" (closeModalHandler)="onClose()">
    <div modal-body>
        <div class="dialog-role-edit-content">
            <div class="role-name">
                <label>{{SYSTEM_SETTING.ROLE_NAME}} <span class="role-required">{{COMMON_TEXT.ASTERISK}}</span></label>
                <pivot-textbox [inputParams]="inputRoleParams" (onInput)="onInputRoleName($event)"
                    [inputValue]="name" (onFocusOut)="focusoutInput()"></pivot-textbox>
            </div>
        </div>
    </div>
    <div modal-bottom class="dialog-role-edit-content">
        <div class="btn-grp">
            <pivot-text-button class="btn-add-dashboard" (btnClick)="onClose()" [buttonType]="buttonType.SECONDARY"
                [buttonText]="BUTTON.CANCEL">
            </pivot-text-button>
            <pivot-text-button class="btn-add-dashboard" (btnClick)="onSubmitRoleData()"
                [buttonType]="buttonType.PRIMARY" [buttonText]="BUTTON.SAVE">
            </pivot-text-button>
        </div>
    </div>
</pivot-modal-template>