<p-dialog appendTo="{{modalTemplate.appendTo}}" styleClass="{{modalTemplate.styleClass}}" class="modal" [showHeader]="modalTemplate.isShowHeader" [header]="modalTemplate.header" 
  [(visible)]="isDisplay" [modal]="modalTemplate.isModal" 
  [breakpoints]="modalTemplate.breakpoints" [style]="modalTemplate.style"
  [dismissableMask]="modalTemplate.isDismissableMask"
  (onHide)="closeModal()" [resizable]="modalTemplate.isResizable"
  [closeOnEscape] = "modalTemplate.closeOnEscape"
  [closable]="modalTemplate.closable">
  <div class="modal-body">
    <ng-content select="[modal-body]"></ng-content>
  </div>
  <div *ngIf="modalTemplate.hasBottom" class="modal-bottom">
    <ng-content select="[modal-bottom]"></ng-content>
  </div>
  <ng-template pTemplate="footer" *ngIf="modalTemplate.hasFooter ?? false" >
    <ng-content select="[modal-footer]"></ng-content>
  </ng-template>
</p-dialog>
