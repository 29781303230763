import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { BulkDataSourceTable, TableData } from '../models/table-model';
import { DistinctedColumnOfDataRawModel } from '../models/request/datasource.dto';

@Injectable()
export class SharedDataService {

  private data: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  private notify: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  data$: Observable<any> = this.data.asObservable();
  notify$: Observable<any> = this.notify.asObservable();
  private storeData: any = {};

  constructor() { }


  clearStoreData() {
    this.storeData = {};
  }

  setItem(key: string, value: any) {
    this.storeData[key] = value;
  }

  getItem(key: string) {
    return this.storeData[key] || null;
  }
  removeItem(key: string) {
    delete this.storeData[key];
  }

  setDataObservable(newData: any) {
    this.data.next(newData);
  }

  setNotifyObservable(newData: any) {
    this.notify.next(newData);
  }
  //------WidgetSetting-------
  private filteredWidgetSetting = new BehaviorSubject<BulkDataSourceTable | null>(null);
  currentFilteredWidgetSetting = this.filteredWidgetSetting.asObservable();
  changeFilteredWidgetSetting(data: BulkDataSourceTable) {
    this.filteredWidgetSetting.next(data);
  }
  resetFilteredWidgetSetting() {
    this.filteredWidgetSetting.next(null);
  }

  private distinctedColumnsWidgetSetting = new BehaviorSubject<DistinctedColumnOfDataRawModel[]>([]);
  currentDistinctedColumnsWidgetSetting = this.distinctedColumnsWidgetSetting.asObservable();
  changeDistinctedColumnsWidgetSetting(data: DistinctedColumnOfDataRawModel[]) {
    this.distinctedColumnsWidgetSetting.next(data);
  }
  resetDistinctedColumnWidgetSetting() {
    this.distinctedColumnsWidgetSetting.next([]);
  }
  //------End WidgetSetting-------

}