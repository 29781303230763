import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { ListItemParams, ListBoxItems, paramStr } from '../../../../app/models/common-model';
import { DATATYPE, PivotOptions } from '../../../../app/const/const';
import Utils from 'src/app/util/utils';
import { DataSourceColumnType, TreeViewTab, FormatType } from '../../../../app/enum/common-enum';
import { cloneDeep } from 'lodash';
@Component({
  selector: 'pivot-list-item',
  templateUrl: './list-item.component.html',
  styleUrls: ['./list-item.component.scss']
})
export class ListItemComponent implements OnInit, OnChanges {
  @Input() params: ListItemParams = new ListItemParams();
  @Input() baseOn: string;
  @Input() isFilterGroup: boolean = false;
  @Input() isBorderButton: boolean = false;
  @Input() isRowOrColumn: boolean = false;
  @Input() forFooter: boolean = false;
  @Input() selection: any = [];
  @Input() isDsSetting: boolean = false;
  @Output() onSortData = new EventEmitter<any>();
  @ViewChild('lb', { static: false }) listOp: any;
  @Output() onSelectionChange = new EventEmitter<any>();
  @Output() onChangeOption = new EventEmitter<any>();
  @Output() onRemoveData = new EventEmitter<any>();
  @Output() onDelete = new EventEmitter<any>();
  @Output() onClick = new EventEmitter<any>();
  @Output() onFilterData = new EventEmitter<any>();
  labelStyle: any = {};
  dragdrop: boolean = true;
  itemSelected: any = null;
  options: ListBoxItems[] = [];
  currentOptions: ListBoxItems[] = [];
  selectOption: any = null;
  showOption = true
  isClick: boolean = false;
  treeViewTab = TreeViewTab;
  DataSourceColumnType = DataSourceColumnType;
  constructor() { }

  ngOnInit(): void {
    // set options
    if (this.params && this.params?.options !== undefined) {
      this.options =  this.params.options;
      this.currentOptions = this.params.options;
    }
    if (this.params && this.params?.cssStyle === undefined) {
      this.params.cssStyle = { width: '200px' };
    }
    this.dragdrop = !this.forFooter;
    if (!Utils.isNullOrEmpty(this.params?.items)) {
      if (!Utils.isNullOrEmpty(this.params?.items[0]?.defaultValue.name)) {
        this.labelStyle = { 'width': '60%' };
      }
      else {
        this.labelStyle = { 'width': '100%' };
      }
    }
    this.isClick = false;
  }

  // event get list data drag and drop
  sortListData() {
    this.onSortData.emit(this.params.items);
  }

  // show popup menu list option
  onShowListOption(event: any, defaultValue: any) {
    if (this.showOption) {
      this.selectOption = defaultValue;
      this.listOp.toggle(event);
    }
  }

  // selected option
  onSelectedOption(data: any) {
    // set default value
    this.params.items.map((s: any) => {
      if (s.value == this.itemSelected[0]?.value) {
        s.defaultValue = data?.value;
      }
    });
    let submitData: any = {
      itemSelected: this.itemSelected,
      optionSelected: data?.value
    };
    this.onChangeOption.emit(submitData);
    this.listOp.hide();
  }

  // remove item for array data
  deleteItem(event: any) {
    var index = this.params.items.indexOf(event);
    this.params?.items?.splice(index, 1);
    this.onRemoveData.emit(event);
    this.onDelete.emit(event);
  }

  // get selected item
  selectionChange(event: any) {
    this.itemSelected = event?.value;
    const item = this.itemSelected[0]
    if(this.isRowOrColumn) {
      if(item[this.baseOn] != DATATYPE.Date) 
        {
          this.options = cloneDeep(this.options).filter(o => o.value?.toString()?.includes(FormatType.Group) ||  o.value?.toString()?.includes(FormatType.NoFormatA));
        }
        else {
          this.options = this.currentOptions;
        }
    }
    this.showOption = !item[this.baseOn] || item[this.baseOn] === DATATYPE.Date || item[this.baseOn] === DATATYPE.Number || item[this.baseOn] === DATATYPE.Float || this.isFilterGroup
    this.onSelectionChange.emit(event);
    if(this.isClick) {
      this.onClick.emit(event);
    }
    this.isClick = false
  }

  filterData(data: any) {
    this.onFilterData.emit(data)
  }

  onClickOption(event: any) {
    this.isClick = true;
  }

  onShowDialogFilter(data: any) {
    if(PivotOptions.filter(o => o.value === data?.value?.value)) {
      let submitData: any = {
        itemSelected: this.itemSelected,
        optionSelected: data?.value
      };
      this.onChangeOption.emit(submitData);
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes && changes['params']?.currentValue) {
      this.params = changes['params']?.currentValue;
      //Only DATASOURCE need to filter options
      this.options = changes['params']?.currentValue.options.filter((x: paramStr) => { 
        if(x.visible != null) return x.visible
        else return x;
      });
      if(this.currentOptions.length == 0) this.currentOptions = cloneDeep(this.options);
    }
  }
}
