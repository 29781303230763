import { createXNode } from "./helper";

export function getValue(el: HTMLInputElement) {
  return isInputOrTextAreaElement(el) ? el.value : el.textContent;
}
export function isInputOrTextAreaElement(el: HTMLElement): boolean {
  return el != null && (el.nodeName == 'INPUT' || el.nodeName == 'TEXTAREA');
};

export function getCaretPosition(el: HTMLInputElement, iframe: (HTMLIFrameElement |null)): number {
  if (isInputOrTextAreaElement(el)) {
    var val = el.value;
    return val.slice(0, el.selectionStart ?? 0).length;
  }
  else {
    var selObj = getWindowSelection(iframe); //window.getSelection();
    if (selObj && selObj.rangeCount > 0) {
      var selRange = selObj.getRangeAt(0);
      var preCaretRange = selRange.cloneRange();
      preCaretRange.selectNodeContents(el);
      preCaretRange.setEnd(selRange.endContainer, selRange.endOffset);
      var position = preCaretRange.toString().length;
      return position;
    }
  }
  return 0
}

function getWindowSelection(iframe: (HTMLIFrameElement | null)): (Selection | null){
  if (!iframe) {
    return window.getSelection();
  } else {
    return iframe.contentWindow?.getSelection() ?? null;
  }
}
export function setCaretPosition(el: HTMLInputElement, pos: number, iframe: (HTMLIFrameElement | null ) = null) {
  if (isInputOrTextAreaElement(el) && el.selectionStart) {
    el.focus();
    el.setSelectionRange(pos, pos);
  }
  else {
    let range = getDocument(iframe)?.createRange();
    if(range != null ){
      range?.setStart(el, pos);
      range?.collapse(true);
      let sel = getWindowSelection(iframe);
      sel?.removeAllRanges();
      sel?.addRange(range);
    }
  }
}

function getDocument(iframe: (HTMLIFrameElement | null)) : (Document | null) {
  if (!iframe) {
    return document;
  } else {
    return (iframe.contentWindow?.document ?? null);
  }
}

export function getContentEditableCaretCoords(ctx: { iframe: (HTMLIFrameElement | null), parent?: Element }) {
  let markerTextChar = '\ufeff';
  let markerId = 'sel_' + new Date().getTime() + '_' + Math.random().toString().substr(2);
  let doc = getDocument(ctx ? ctx.iframe : null);
  let sel = getWindowSelection(ctx ? ctx.iframe : null);
  let prevRange = sel?.getRangeAt(0);

  // create new range and set postion using prevRange
  let range = doc?.createRange();
  if(sel?.anchorNode ){
    range?.setStart(sel?.anchorNode, prevRange?.startOffset ?? 0);
    range?.setEnd(sel?.anchorNode, prevRange?.startOffset ?? 0);
    range?.collapse(false);
  }


  // Create the marker element containing a single invisible character
  // using DOM methods and insert it at the position in the range
  let markerEl = doc?.createElement('span');
  if( markerEl && doc && range){
    markerEl.id = markerId;
    markerEl.appendChild(doc?.createTextNode(markerTextChar));
    range.insertNode(markerEl);
    sel?.removeAllRanges();
    if(prevRange){
      sel?.addRange(prevRange);
    }
  }

  let coordinates = {
    left: 0,
    top: markerEl?.offsetHeight??0
  };

  localToRelativeCoordinates(ctx, markerEl ??  null, coordinates);

  markerEl?.parentNode?.removeChild(markerEl);
  return coordinates;
}


function localToRelativeCoordinates(
  ctx: { iframe: (HTMLIFrameElement | null), parent?: Element },
  element: (Element | null),
  coordinates: { top: number; left: number }
) {
  let obj = <HTMLElement>element;
  let iframe = ctx ? ctx.iframe : null;
  while (obj) {
    if (ctx.parent != null && ctx.parent == obj) {
      break;
    }
    coordinates.left += obj.offsetLeft + obj.clientLeft;
    coordinates.top += obj.offsetTop + obj.clientTop;
    obj = <HTMLElement>obj.offsetParent;
    if (!obj && iframe) {
      obj = iframe;
      iframe = null;
    }
  }
  obj = <HTMLElement>element;
  iframe = ctx ? ctx.iframe : null;
  while (obj !== getDocument(null)?.body && obj != null) {
    if (ctx.parent != null && ctx.parent == obj) {
      break;
    }
    if (obj.scrollTop && obj.scrollTop > 0) {
      coordinates.top -= obj.scrollTop;
    }
    if (obj.scrollLeft && obj.scrollLeft > 0) {
      coordinates.left -= obj.scrollLeft;
    }
    obj = <HTMLElement>obj.parentNode;
    if (!obj && iframe) {
      obj = iframe;
      iframe = null;
    }
  }
}

export function insertValue(
  el: HTMLInputElement,
  start: number,
  end: number,
  text: string,
  iframe: HTMLIFrameElement,
  noRecursion: boolean = false
) {
  if (isTextElement(el)) {
    let val = getValue(el);
    setValue(el, val?.substring(0, start) + text + val?.substring(end, val?.length));
    setCaretPosition(el, start + text.length, iframe);
  }
  else if (!noRecursion) {
    let selObj: (Selection | null)  = getWindowSelection(iframe);
    if (selObj && selObj.rangeCount > 0) {
      var selRange = selObj.getRangeAt(0);
      var position = selRange.startOffset;
      var anchorNode = selObj.anchorNode;
      var x = createXNode(text);// document.createElement("SPAN");
      x.textContent = text;
      ///x.classList.add("ment ion")
      var x2 = document.createTextNode('\u00A0');

      insertAfter((end - start) , x,x2, anchorNode)
    }
  }
}

function insertAfter(inexSub : number, newNode :HTMLElement, newNode2:Node, existingNode?: (Node | null)) {
  
  if(existingNode){
    let value =  existingNode.nodeValue?.substring(0,existingNode.nodeValue.length - inexSub);
    existingNode.nodeValue = value ?? "";
  }
 
  let nexNode =  existingNode?.nextSibling;
  if(nexNode){
    existingNode?.parentNode?.insertBefore(newNode, nexNode);
    existingNode?.parentNode?.insertBefore(newNode2, nexNode);
    var x232 = (<HTMLInputElement>newNode2);
    setCaretPosition(x232,1,null)
  }else{
    existingNode?.parentNode?.appendChild(newNode);
    existingNode?.parentNode?.appendChild(newNode2);
    var x232 = (<HTMLInputElement>newNode2);
    setCaretPosition(x232,1,null)
  }

}



export function isTextElement(el: HTMLElement): boolean {
  return el != null && (el.nodeName == 'INPUT' || el.nodeName == 'TEXTAREA' || el.nodeName == '#text');
};

export  function setValue(el: HTMLInputElement, value: any) {
  if (isInputOrTextAreaElement(el)) {
    el.value = value;
  }
  else {

   el.textContent = value;
  }
}
