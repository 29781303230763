import { Observable } from 'rxjs';
import { CanDeactivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { DashboardSettingComponent } from '../../module/dashboard/dashboard-setting/dashboard-setting.component';
import { ROUTE_PATH } from '../../../app/const/route-path';

export class CanDeactivateDashboard implements CanDeactivate<DashboardSettingComponent> {
    constructor() {

    }
    canDeactivate(component: DashboardSettingComponent,
        currentRoute: ActivatedRouteSnapshot,
        currentState: RouterStateSnapshot,
        nextState?: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
        if (nextState?.url === ("/" + ROUTE_PATH.AUTH)) return true;
        return component.canDeactivate(nextState?.url);
    }
}