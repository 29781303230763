<pivot-modal-template [modalTemplate]="modalData"
    (closeModalHandler)="onSubmiDataCondition(false)">
    <div modal-body>
        <div class="condition-content">
            <div class="condition-name-grp">
                <label>{{COMMON_TEXT.FILTER_CONDITION}}</label>
                <label class="clear-filter" (click)="clearFilter()">{{COMMON_TEXT.CLEAR_FILTER}}</label>
                <pivot-search-bar [searchParams]="searchParams" (onFilterData)="onFilterCondition($event)">
                </pivot-search-bar>
            </div>
            <div class="margin-grp">
                <pivot-calendar *ngIf="!selected || selected.value === NoFilter" [disabled]="true" class="disable">
                </pivot-calendar>
                <pivot-textbox *ngIf="(selected?.type === MstFilterGroup.VARCHAR || selected?.type === MstFilterGroup.INT) && !isNumberType"
                    [ngClass]="isRequireInput ? 'require' : ''"
                    [inputParams]="inputParams"
                    [(inputValue)]="value.filtervalue"
                    (onFocus)="onFocusTextBox()"
                    [canClear]="true"
                >
                </pivot-textbox>
                <pivot-textbox *ngIf="(selected?.type === MstFilterGroup.VARCHAR || selected?.type === MstFilterGroup.INT) && isNumberType"
                    [ngClass]="isRequireInput ? 'require' : ''"
                    [inputParams]="inputParamsNumber"
                    [(inputValue)]="value.filtervalue"
                    [canClear]="true"
                >
                </pivot-textbox>
                <pivot-calendar *ngIf="!isDisableDate && selected?.type === MstFilterGroup.DATATIME"
                    [ngClass]="isDisableDate ? 'disable' : isRequireInput ? 'require' : ''"
                    [disabled]="isDisableDate"
                    [dateFormat]="dateFormat"
                    [(value)]="value.filtervalue"
                    [canClear]="true"
                >
                </pivot-calendar>
                <input *ngIf="selected?.type === MstFilterGroup.DATATIME && isDisableDate" class="disable"
                    [value]="currentSelectedRange" disabled />
                <p-multiSelect *ngIf="selected.type === 'totaldays'" [options]="dropdownOptions"  class="filter-dropdown" [(ngModel)]="selectedItemInOptions" optionLabel="name"></p-multiSelect>
                <p *ngIf="isRequireInput" class="error-msg">{{COMMON_TEXT.REQUIRE_ITEM}}</p>
                <p *ngIf="!isNumberType && selected?.type != MstFilterGroup.DATATIME" class="msg-filter">{{COMMON_TEXT.MULTIFILTER}}</p>
            </div>
        </div>
    </div>

    <div modal-bottom class="condition-content">
        <div class="btn-grp">
            <pivot-text-button class="btn-condition" (btnClick)="onSubmiDataCondition(false)"
                [buttonType]="buttonType.SECONDARY" [buttonText]="BUTTON.CANCEL">
            </pivot-text-button>
            <pivot-text-button class="btn-add-dashboard" (btnClick)="onSubmiDataCondition(true)"
                [buttonType]="buttonType.SECONDARY" [buttonText]="BUTTON.SET">
            </pivot-text-button>
        </div>
    </div>
</pivot-modal-template>