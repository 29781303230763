import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'pivot-single-pannel-template',
  templateUrl: './single-pannel-template.component.html',
  styleUrls: ['./single-pannel-template.component.scss']
})
export class SinglePannelTemplateComponent implements OnInit {
  @Input() titlePage: string = ""; 
  isExpanded: boolean = false;
  constructor() { }

  ngOnInit(): void {
    //Testing will be removed later
    //this.titlePage = "Title page";
    //EndTesting
  }

}
