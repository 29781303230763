<div id="tableWrapper" class="table-wrapper">

  <div *ngIf="config.isResponsive" class="mobile-control">
    <div  class="select-all">
      <pivot-checkbox [disabled]="disableCheckbox"  class="check-all" [value]="checkedAll" (onClick)="checkAll()"></pivot-checkbox>
      <span>{{COMMON_TEXT.SELECT_ALL}}</span>
    </div>
    <div class="sort-wrapper">
      <pivot-text-button [iconType]="iconType.SORT" [buttonType]="buttonType.INFO" buttonText="SORT"
        (btnClick)="showSortPopup()">
      </pivot-text-button>
      <div id="sort-popup" *ngIf="isShowSortPopup" class="control-popup">
        <label>{{COMMON_TEXT.SORT_COLUMN}} </label>
        <div class="field">
          <pivot-search-bar [searchParams]="sortColumnList" (onFilterData)="setSortField($event)">
          </pivot-search-bar>
        </div>
        <label>{{COMMON_TEXT.SORT_MODE}} </label>
        <div class="field">
          <div class="mode">
            <pivot-checkbox [disabled]="disableCheckbox" [value]="isAscending" (onClick)="sortModeChange(0)"></pivot-checkbox>
            <i class="pi pi-sort-amount-up-alt"></i>
          </div>
          <div class="mode">
            <pivot-checkbox [disabled]="disableCheckbox" [value]="isDescending" (onClick)="sortModeChange(1)"></pivot-checkbox>
            <i class="pi pi-sort-amount-down"></i>
          </div>
        </div>
      </div>
    </div>
    <div class="filter-wrapper">
      <pivot-text-button [iconType]="iconType.FILTER" [buttonType]="buttonType.INFO" buttonText="FILTER"
        (btnClick)="showFilterPopup()">
      </pivot-text-button>
      <div id="filter-popup" *ngIf="isShowFilterPopup" class="control-popup">
        <label>{{COMMON_TEXT.FILTER_COLUMN}}</label>
        <div class="field">
          <pivot-search-bar [searchParams]="filterColumnList" (onFilterData)="setFilterField($event)">
          </pivot-search-bar>
        </div>
        <label>{{COMMON_TEXT.VALUE}}</label>
        <div class="field">
          <p-multiSelect [options]="filterFields" [(ngModel)]="selectedFilterField" (ngModelChange)="filterData(false)"
            optionLabel="name" optionValue="code">
          </p-multiSelect>
        </div>
      </div>
    </div>
    <span class="reset" (click)="resetAll()">{{COMMON_TEXT.RESET_ALL}}</span>
  </div>
  <div class="table-content">
    <div *ngIf="config.showPagination" class="pagination-wrapper" >
      <ng-content select="[action-buttons]">
      </ng-content>
      <pivot-pagination [totalRecord]="totalRecord" [currentPage]="paginateParams.currentPage" [itemsPerPage]="paginateParams.pageSize" (paginateData)="paginateData($event)"></pivot-pagination>
    </div>
      <p-table [id]="tableId" class="pivot-table" [ngClass]="{'no-query-media': !config.isResponsive}" 
      [responsiveLayout]="config.isResponsive? 'stack': 'scroll'"
      [columns]="headerData" [value]="bodyData" columnResizeMode="expand" [ngStyle]="config.tableStyle"
      [tableStyle]="config.inlineTableStyle">
        <ng-template pTemplate="caption" *ngIf="config.caption">
          <div [innerHtml]="config.caption"></div>
        </ng-template>
        <ng-template pTemplate="header" let-columns>
          <tr (onDragStart)="dragStart($event,columns)" (onDragEnd)="dragEnd(columns)" dragHandle=".drag-icon"
          [pDraggable]="dragScopeView" [pDraggableDisabled]="!staffInfor">
            <th *ngIf="config.showCheckboxTitle && localDataRecords.length > 0 " class="col-checkbox col-checkbox-title">
              <div class="header-content">
                <span [innerHtml]="config.titleCheckbox"></span>
              </div>
              <div class="chebox-all-tile ">
                <pivot-checkbox [disabled]="disableCheckbox" class="checkbox"  [value]="checkedAll" (onClick)="checkAll()"></pivot-checkbox>
              </div>          
            </th>
            <th *ngIf="config.showCheckbox && localDataRecords.length > 0 " class="col-checkbox">
              <pivot-checkbox *ngIf="!disableHeaderCheckbox" [disabled]="disableCheckbox" class="checkbox"  [value]="checkedAll" (onClick)="checkAll()"></pivot-checkbox>
            </th>
            <th class="col-index" *ngIf="config.showIndex"><span>{{COMMON_TEXT.NUMBER}}</span></th>
            <th *ngIf="config.hoverShowCheckbox" [ngClass]="config.hoverBoderNone? 'col-checkbox-hover-none-border': 'col-checkbox-hover'" >
              <pivot-checkbox [disabled]="disableCheckbox" *ngIf="config.hoverShowCheckbox && localDataRecords.length > 0"  class="checkbox-hover" [ngClass]="{'checked': checkedAll}" 
                  [value]="checkedAll" (onClick)="checkAll()"></pivot-checkbox>
            </th>
            <th *ngFor="let col of columns; let i = index" [style]="getStyle(col.field, 'header')" 
            [ngClass]="{'hidden': !col.visible, 'no-border-left': config.hoverShowCheckbox && i === 0 && col.visible, 'col-checkbox col-checkbox-title':col.dataType === dataType.CHECKBOX}"
            [attr.colspan]="col.attribute['isMergeCells'] === true ? col.attribute.childrenCols.length : 1"
            [attr.rowspan]="col.attribute['isMergeCells'] === true ? 1 : 2">  
              <div class="header-content"> 
                <span [innerHtml]="col.title"></span>
                <div class="header-action">
                  <ng-container *ngIf="col.sortable">
                    <div class="icon-wrapper" [ngClass]="col.field === sortField.field  ? 'active': '' ">
                      <i class="pi " [ngClass]="col.field === sortField.field  ? sortIcon +' sort-active' : 'pi-sort-alt'"
                        (click)="clickSortIcon(col.field)"></i>
                    </div>
                  </ng-container>
                  <ng-container *ngIf="col.filterable">
                    <div class="icon-wrapper" [ngClass]="col.field === filterField  ? 'active': '' ">
                      <i class="pi pi-filter" (click)="clickFilterIcon(col.field, $event)"></i>
                    </div>
                    <div id="filter-popup" class="filter-control-popup "
                      [ngClass]="['filter-popup-' + col.field,  isShowFilterPopup && filterField === col.field ? 'visible': '' ]">
                      <div class="header-popup">
                        <div class="check-all">
                          <span *ngIf="isFilterAll" class="check-all">{{COMMON_TEXT.ALL_SELECTED}}</span>
                        </div>
                        <i class="pi pi-times" (click)="isShowFilterPopup = false"></i>
                      </div>
                      <p-listbox [options]="filterFields" [(ngModel)]="selectedFilterField"
                        (ngModelChange)="filterData(true)" [checkbox]="true" [filter]="true" [multiple]="true"
                        optionLabel="name" [listStyle]="{'max-height':'250px'}" [style]="{'width':'15rem'}">
                      </p-listbox>
                    </div>
                  </ng-container>
                </div>
              </div>
              <div *ngIf="col.dataType === dataType.CHECKBOX" class="chebox-all-tile" [ngClass]="getClass(col, 'row')">
                <pivot-checkbox *ngIf="bodyData.length > 0" [disabled]="false" class="checkbox"  [value]="col.checkedAll" (onClick)="checkAllField(col.field)"></pivot-checkbox>
              </div>   
            </th>
          </tr>

          <tr *ngIf="isHasMergeCell">
            <th *ngIf="config.showCheckbox && localDataRecords.length > 0 " class="col-checkbox">
              <pivot-checkbox [disabled]="disableCheckbox" class="checkbox"  [value]="checkedAll" (onClick)="checkAll()"></pivot-checkbox>
            </th>
            <th class="col-index" *ngIf="config.showIndex"><span>{{COMMON_TEXT.NUMBER}}</span></th>
            <th *ngIf="config.hoverShowCheckbox" [ngClass]="config.hoverBoderNone? 'col-checkbox-hover-none-border': 'col-checkbox-hover'">
              <pivot-checkbox [disabled]="disableCheckbox" *ngIf="config.hoverShowCheckbox && localDataRecords.length > 0"  class="checkbox-hover" [ngClass]="{'checked': checkedAll}" 
                  [value]="checkedAll" (onClick)="checkAll()"></pivot-checkbox>
            </th>
            <th *ngFor="let col of totalDayDetailValues['data']; let i = index" [style]="getStyle(col.field, 'header')"
            [ngClass]="{'hidden': !col.visible, 'no-border-left': config.hoverShowCheckbox && i === 0 && col.visible}">  
              <div class="header-content">
                <span [innerHtml]="col.title"></span>
                <div class="header-action">
                  <ng-container *ngIf="col.sortable">
                    <div class="icon-wrapper" [ngClass]="col.field === sortField.field  ? 'active': '' ">
                      <i class="pi " [ngClass]="col.field === sortField.field  ? sortIcon +' sort-active' : 'pi-sort-alt'"
                        (click)="clickSortIcon(col.field)"></i>
                    </div>
                  </ng-container>
                  <ng-container *ngIf="col.filterable">
                    <div class="icon-wrapper" [ngClass]="col.field === filterField  ? 'active': '' ">
                      <i class="pi pi-filter" (click)="clickFilterIcon(col.field, $event)"></i>
                    </div>
                    <div id="filter-popup" class="filter-control-popup "
                      [ngClass]="['filter-popup-' + col.field,  isShowFilterPopup && filterField === col.field ? 'visible': '' ]">
                      <div class="header-popup">
                        <div class="check-all">
                          <span *ngIf="isFilterAll" class="check-all">{{COMMON_TEXT.ALL_SELECTED}}</span>
                        </div>
                        <i class="pi pi-times" (click)="isShowFilterPopup = false"></i>
                      </div>
                      <p-listbox [options]="filterFields" [(ngModel)]="selectedFilterField"
                        (ngModelChange)="filterData(true)" [checkbox]="true" [filter]="true" [multiple]="true"
                        optionLabel="name" [listStyle]="{'max-height':'250px'}" [style]="{'width':'15rem'}">
                      </p-listbox>
                    </div>
                  </ng-container>
                </div>
              </div>
            </th>
          </tr>

        </ng-template>
        <ng-template pTemplate="body" let-columns="columns" let-rowIndex="rowIndex" let-rowData >
          <tr (onDragStart)="dragStart($event,rowData)" (onDragEnd)="dragEnd(rowData)" dragHandle=".drag-icon"
          [ngClass]="{'selected-row': staffInfor ? selectedRowCheck(rowData) : setSelectedRowStyle(rowData), 'selected-row-staff-infor': staffInfor, 'drag-inprogress': dragInProgress}"
          (click)="checkRowSeleted(rowData)" [pDraggable]="dragScopeView" [pDraggableDisabled]="!staffInfor" >
            <td *ngIf="config.showCheckboxTitle" class="col-checkbox ">
              <pivot-checkbox [disabled]="true" class="checkbox" [value]="rowData['checked']" (onClick)="checkRow(rowData)"
                [style]="rowData['rowCheckboxStyle']">
              </pivot-checkbox>
              <span class="col-index mobile-view" *ngIf="config.showIndex" (click)="onClickRow(rowData, -1)">
                {{(this.paginateParams.currentPage - 1) * this.paginateParams.pageSize + (rowIndex + 1)}}
              </span>
            </td>
            <td *ngIf="config.showCheckbox" class="col-checkbox">
              <pivot-checkbox [disabled]="disableCheckbox" class="checkbox" [value]="rowData['checked']" (onClick)="checkRow(rowData)"
                [style]="rowData['rowCheckboxStyle']">
              </pivot-checkbox>
              <span class="col-index mobile-view" *ngIf="config.showIndex" (click)="onClickRow(rowData, -1)">
                {{(this.paginateParams.currentPage - 1) * this.paginateParams.pageSize + (rowIndex + 1)}}
              </span>
            </td>
            <td class="col-index desktop-view" *ngIf="config.showIndex" (click)="onClickRow(rowData, -1); config.isAllowCheckedAllInRow ? checkRow(rowData) : null">
              {{ setRowIndex(rowIndex) }}
            </td>
            <td *ngIf="config.hoverShowCheckbox" [ngClass]="config.hoverBoderNone? 'checkbox-hover-wrapper-none-border': 'checkbox-hover-wrapper'">
              <pivot-checkbox
                [disabled]="disableCheckbox"
                [style]="rowData['rowCheckboxStyle']"
                class="checkbox-hover" [ngClass]="{'checked': rowData['checked']}" 
                [value]="rowData['checked']" (onClick)="checkRow(rowData)">
              </pivot-checkbox>
            </td>
            <td *ngFor="let col of columns; let i = index" [style]="getStyle(col.field, 'row')"
              [ngClass]="{'hidden': !col.visible || col.attribute.isMergeCells === true, 'no-border-left': config.hoverShowCheckbox && i ===0 && col.visible, 'col-checkbox': col.dataType === dataType.CHECKBOX}"
              (click)="onClickRow(rowData, i); config.isAllowCheckedAllInRow ? checkRow(rowData) : null"
              [class]="getClass(col, 'row', rowData)">
           
              <span class="p-column-title">{{col.title}}</span>
              <div  class="cell-content" (click)="rowData.officeCd && checkRow(rowData)" [ngClass]="{'has-sub-text': col.isHasSubText }">
                <ng-container class = "drag-add-staff" *ngIf="col.dataType === dataType.DRAG_ADD_STAFF" >
                  <div class="drag-staff">
                      <i id="drag-icon"  class="pi pi-ellipsis-v drag-icon"></i>
                  </div>
                </ng-container>
                <ng-container *ngIf="![dataType.PROGRESS, dataType.STATUS, dataType.WIDGET, dataType.INPUT, dataType.CHECKBOX, dataType.COMBOBOX].includes(col.dataType)">
                  <span [ngClass]="{'text-content-header': col.isHasSubText && rowData[col.field+'-subText'] }">
                    {{col.dataType === dataType.DATETIME ? formatDate(rowData[col.field],rowData, col.field) : col.dataType === dataType.NUMBER ? formatNumber(rowData[col.field]) : rowData[col.field] }}
                  </span>
                  <span *ngIf="col.isHasSubText && rowData[col.field+'-subText']" class="sub-text">
                    {{rowData[col.field+'-subText'] }}
                  </span>
                </ng-container>
                <ng-container *ngIf="loadicon === true  && [dataType.IMG].includes(col.dataType) " >
                  <span *ngIf="rowData.process === processType.UPDATING">
                    <div class = "status-row">
                      <img class = "amination" src ="../../../../assets/icons/circle.svg">
                      <img class = "check" src ="../../../../assets/icons/check.svg"  >
                    </div>
                  </span>
                  <span *ngIf="rowData.process === processType.UPDATE_COMPLETE">
                    <div class = "status-row">
                      <img src ="../../../../assets/icons/checkdone.svg">
                    </div>
                  </span>
                  <span *ngIf="rowData.process === processType.ERROR">
                    <div class = "status-row">
                      <img src ="../../../../assets/icons/checkerror.svg" >
                    </div>
                  </span>
                </ng-container>
                <ng-container *ngIf="col.field === 'totaldays'">
                </ng-container>
                <ng-container *ngIf="col.dataType === dataType.PROGRESS">
                  <p-progressBar [value]="rowData[col.field]" [showValue]="false" ></p-progressBar>
                  <div [ngClass]="rowData['progressResult'] ? 'progress-bar-value' : 'progress-bar-value-err'">{{rowData['progressResult'] ? (rowData[col.field] + '%') : COMMON_TEXT.FAIL}}</div>
                </ng-container> 
                <ng-container *ngIf="col.dataType === dataType.STATUS">
                  <div  [ngClass]="{
                    'tb-black-color': rowData['status'] == processType.NOT_UPDATED, 
                    'tb-light-blue-color': rowData['status'] == processType.UPDATING,
                    'tb-green-color': rowData['status'] == processType.UPDATE_COMPLETE,
                    'tb-red-color': rowData['status'] == processType.ERROR
                  }">{{rowData[col.field]}}</div>
                  <span *ngIf="col.isHasSubText && rowData[col.field+'-subText']" class="sub-text">
                    {{rowData[col.field+'-subText'] }}
                  </span>
                </ng-container>  
                <ng-container *ngIf="col.dataType === dataType.INPUT">
                  <input class="input-value" [tabIndex]="findRowIndex(rowData) + i" (focus)="inputFocus($event.target, col.field, rowData)"
                    (input)="inputChange($event.target, col.field, rowData)"
                    (keyup)="inputKeyUp($event, col.field, rowData)" [value]="rowData[col.field]" 
                    (keydown)="inputKeyDown($event)"
                    (focusout)="inputFocusOut($event.target, col.field, rowData)" [value]="rowData[col.field]"/>
                </ng-container>          
                <ng-container *ngIf="col.dataType === dataType.WIDGET">
                  <span [ngClass]="{'text-content-header': col.isHasSubText && rowData[col.field+'-subText'] }">
                    {{col.dataType === dataType.DATETIME ? formatDate(rowData[col.field],rowData, col.field) : col.dataType === dataType.NUMBER ? formatNumber(rowData[col.field]) : rowData[col.field] }}
                  </span>
                  <div class="sub-text-widget">
                    <span *ngIf="col.isHasSubText && rowData[col.field+'-subText']" class="sub-text">
                      {{formatDateForWidgetType(rowData[col.field+'-subText']) }}
                    </span>
                    <span *ngIf="col.isHasSubText && rowData[col.field+'-subText']"
                      [ngClass]="getTitlePublishWidget(rowData[col.field+'-subText']) == '非公開' ? 'sub-text-expired' : 'sub-text-publish'">
                      {{getTitlePublishWidget(rowData[col.field+'-subText']) }}
                    </span>
                  </div>
                </ng-container>
                <ng-container *ngIf="col.dataType === dataType.CHECKBOX">
                  <pivot-checkbox [disabled]="false" class="checkbox" [value]="rowData[col.field]" (onClick)="checkField( col.field, rowData, rowIndex)"
                  [style]="rowData['rowCheckboxStyle']">
                  </pivot-checkbox>
                </ng-container>
                <ng-container *ngIf="col.dataType === dataType.COMBOBOX">
                  <p-dropdown  class="combobox-value"  
                    (onBlur)="onBlurDropDown($event.target, col.field, rowData)" 
                    [tabIndex]="findRowIndex(rowData) + i" 
                    [(ngModel)]="rowData[col.field]" 
                    appendTo="body" 
                    (onChange)="onChangeDropDown(col.field, rowData)"
                    [options]="col.attribute.dataCombobox" 
                    optionValue="value" 
                    optionLabel="title">
                  </p-dropdown>    
                </ng-container>
                <ng-container class="function-group" *ngIf="col.dataType === dataType.EDIT_DELETE_FUNCTION_BTN">
                  <div class="button-group">
                    <pivot-icon-button
                      (onClick)="onEditDataRowByBtn(rowData)" [label]="buttonText.EDIT" 
                      [disabled]=" config.disableEditBtnCondition && config.disableEditBtnCondition.length > 0 && config.disableEditBtnCondition.includes(rowData.roleCd)" 
                      [buttonType]="buttonType.INFO" icon="assets/icons/edit_pen.svg" 
                      [styleClass]="'pivot-table-edit-btn edit'" class="icon-btn">
                    </pivot-icon-button>
                    <div class="line"></div>
                    <pivot-icon-button 
                      (onClick)="onEditDeleteRowByBtn(rowData)" 
                      [disabled]="config.disableDeleteBtnCondition && config.disableDeleteBtnCondition.length > 0 && config.disableDeleteBtnCondition.includes(rowData.roleCd)" 
                      [label]="buttonText.DELETE" [buttonType]="buttonType.DANGER" 
                      icon="assets/icons/trash.svg" class="icon-btn" [styleClass]="'pivot-table-edit-btn delete'">
                    </pivot-icon-button>
                  </div>   
                </ng-container>
              </div>
            </td>
            <td (click)="config.isAllowCheckedAllInRow ? checkRow(rowData) : null" *ngFor="let col of totalDayDetailValues['data']; let i = index">
              <thead class="p-table-thead">
                <span [ngClass]="{'text-content-header': col.isHasSubText && rowData[col.field+'-subText'] }">
                  {{ rowData[col.field] }}
                </span>
              </thead>
            </td>
          </tr>
        </ng-template>
        <ng-template pTemplate="emptymessage">
          <tr class="row-no-data" [ngClass]="{'no-header': (headerData.length == 0)}">
            <td class="no-data" [attr.colspan]="cols">
              {{config.emptyMessage}}
            </td>
          </tr>
        </ng-template>
      </p-table>
      <div *ngIf="isLoading" class="loading">
        <div class="loader"></div>
      </div>
  </div>
</div>