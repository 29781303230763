<div class="number-chart">
  <div class="text-container">
    <svg
    id="my-svg"
    width="100%"
    height="100%"
    [attr.viewBox]="viewBoxOfSmallNumber ? '0 0 350 75' : '0 0 500 75'"
    alight-items = "center"
    preserveAspectRatio="xMinYMid meet"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    preserveAspectRatio="xMidYMid meet">
        <text  class = "text"
          x="50%"
          y="50%"
          dominant-baseline="middle"
          [ngStyle]="{
            'font-size': viewBoxOfSmallNumber ? '80px' : '74px'
          }"
          text-anchor="middle"
        >{{value}}</text>
    </svg>
  </div>
</div>

