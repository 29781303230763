import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { cloneDeep, findIndex } from 'lodash';
import { DialogService } from 'primeng/dynamicdialog';
import { DASHBOARD, FOLDER } from '../../const/table-col-define';
import { BUTTON, COMMON_TEXT, DASHBOARD_LIST, DASHBOARD_TEMPLATE_LIST, FOLDER_DIALOG } from '../../../app/const/text-common';
import { ConfirmDialogComponent } from '../../component/common/confirm-dialog/confirm-dialog.component';
import { ROUTE_PATH } from '../../const/route-path';
import { ButtonIconType, ButtonType, CheckAllMode, DataType, DialogType, ScreenMode, SearchType, UserAction } from '../../enum/common-enum';
import { ModalTemplate, SearchParams } from '../../models/common-model';
import { FolderAddDTO } from '../../models/request/folder.dto';
import { DashboardList } from '../../models/response/dashboard.ro';
import { Folder, FOLDER_TYPE } from '../../models/response/folder.ro';
import { TableData } from '../../models/table-model';
import { ProcessLoadingService } from '../../services/loading.service';
import { DashboardService } from '../../services/modules/dashboard.service';
import { FolderService } from '../../services/modules/folder.service';
import { WidgetService } from '../../services/modules/widget.service';
import Utils from '../../util/utils';
import { LocalStorageHelper } from '../../_helper/local-storage.helper';
import {AuthenticationService} from 'src/app/services/authentication.service';
import {ErrorHandleService} from 'src/app/services/error-handle.service';
import {FUNCTION_TITLE_TEXT} from 'src/app/const/error-text';
import { SAUCER_LOG_ACTION, SaucerLogService } from '../../../app/services/saucer-logs/saucer-log.service';
import { CONTENT_LOG } from '../../../app/config/saucer-log.config';

@Component({
  selector: 'pivot-dashboard-list',
  templateUrl: './dashboard-list.component.html',
  styleUrls: ['./dashboard-list.component.scss']
})
export class DashboardListComponent implements OnInit {
  isDashboardTemplate: boolean;
  // title form
  titlePage: string = "";
  isDisplayModalDelete: boolean = false;
  // folder data edit
  folderEditData: any = null;
  // folder selected
  folderSelected: any = null;
  // folder selected row
  folderSelecteRow: any = null;
  // folder data edit 
  dashboardEditData: any = null;
  deleteArray: string[] = [];
  // display modal variables
  isDisplayFolderModal: boolean = false;
  isDisplayDashboardModal: boolean = false;
  SAUCER_LOG_ACTION = SAUCER_LOG_ACTION;
  // delete folder flag
  deleteFolderFlg: boolean = false;
  // delete dashboard flag
  deleteDashboardFlg: boolean = false;
  // modal templete
  modalFolder: ModalTemplate = new ModalTemplate();
  modalAddDashboard: ModalTemplate = new ModalTemplate();
  modalDelete: ModalTemplate = new ModalTemplate();
  buttonType = ButtonType;
  // display button
  dspBtnEditFolder: boolean = false;
  dspBtnDeleteFolder: boolean = false;
  dspBtnEditDashboard: boolean = false;
  dspBtnDeleteDashboard: boolean = false;

  //disable button
  disableBtnAddFolder: boolean = false;
  disableBtnEditFolder: boolean = false;
  disableBtnDeleteFolder: boolean = false;
  disableBtnAddDashboard: boolean = false;
  disableBtnEditDashboard: boolean = false;
  disableBtnDeleteDashboard: boolean = false;
  assignPermission: boolean = false;

  // btn add dashboard
  addDashboardStr: string = BUTTON.DASHBOARD_CREATE;
  // icon button
  iconType = ButtonIconType;
  // is update dashboard
  isUpdate: boolean = false;
  //  array folder selected
  folderSelecteds: any[] = [];
  // array dashboard selected
  dashboardSelecteds: any[] = [];
  // search folder params
  searchFolder: SearchParams = {
    type: SearchType.input,
    placeholder: COMMON_TEXT.SEARCH,
    cssStyle: { height: '46px', margin: '10px 5px 10px 0px' },
    readonly: false,
    disabled: false,
    maxLength: 100
  };

  // search dashboard params
  searchDashboard: SearchParams = {
    type: SearchType.input,
    placeholder: COMMON_TEXT.SEARCH,
    cssStyle: { height: '46px', margin: '11px', width: '550px' },
    readonly: false,
    disabled: false,
    maxLength: 100
  };

  // folder list 
  folders: Folder[] = [];
  // list folder edit
  folderEdits: Folder[] = [];
  folderData: TableData;
  // list dashboard
  dashboards: DashboardList[] = [];
  // table dashboard params
  dashboardTableData: TableData;
  delTxt: string = '';

  isTableDashLoading: boolean = false;
  BUTTON = BUTTON;
  folderType: number = FOLDER_TYPE.Dashboard;
  selectedFoldersRole: number = UserAction.VIEW;
  logAction: any;
  checkAllMode = CheckAllMode;
  isSupporterAdmin: boolean;

  constructor(private modalService: DialogService,
    private folderService: FolderService,
    private dashboardService: DashboardService,
    private processLoadingService: ProcessLoadingService,
    private widgetService: WidgetService,
    private authenticationService: AuthenticationService,
    private router: Router,
    private errorHandleService: ErrorHandleService,
    private saucerLogService : SaucerLogService
  ) {
    this.isDashboardTemplate = this.router.url.includes("dashboard-template") ? true : false;
    this.logAction = this.isDashboardTemplate ? SAUCER_LOG_ACTION.DASHBOARD_TEMPLATE_LIST : SAUCER_LOG_ACTION.DASHBOARD_LIST;
    this.titlePage = this.isDashboardTemplate ? DASHBOARD_TEMPLATE_LIST.TITLE : DASHBOARD_LIST.TITLE;
    this.saucerLogService.action({},{ action: this.logAction.VIEW });
  }

  async ngOnInit() {
    this.errorHandleService.setFunctionTitle(FUNCTION_TITLE_TEXT.SCREEN_INIT_FAIL);
    this.folderEditData = [];
    this.folders = [];
    this.isSupporterAdmin = await this.authenticationService.isAdminOrSupporter();
    this.processLoadingService.isLoading.emit(true);
    this.configTableFolder();
    this.configTableDashboard();
    await this.fetchAllData();
    let folderData = cloneDeep(this.folderData);
    folderData.body = this.folders;
    this.folderData = folderData;
    // redirect url after create datasource
    let dashboardSelect = this.dashboardService.getDashboardItem();
    if (!Utils.isNullOrEmpty(dashboardSelect?.dashboard)) {
      let findFolder = this.folders.find((f: any) => f.folderCd === dashboardSelect.dashboard?.folderCd);
      if (findFolder != null) {
        this.folderSelecteRow = findFolder;
        await this.onFilterDashboard(findFolder);
      }
    }
    this.processLoadingService.isLoading.emit(false);
  }

  // onfilter data folder
  onFilterFolderData(folderName: any) {
    this.saucerLogService.action({
      content: CONTENT_LOG.SEARCH_FOLDER + folderName,
    }, 
    {
      action: this.isDashboardTemplate ? SAUCER_LOG_ACTION.DASHBOARD_TEMPLATE_LIST.SEARCH_FOLDER : SAUCER_LOG_ACTION.DASHBOARD_LIST.SEARCH_FOLDER
    });
    if (folderName == '') {
      this.bindingFolderTable(this.folders);
    }
    else {
      this.bindingFolderTable(this.folders.filter((s: any) => s.name?.toUpperCase()?.trim().includes(folderName?.toUpperCase()?.trim()?.trim())));
    }
  }

  // filter dashboard data
  onFilterDashboardData(dashboardName: any) {
    this.saucerLogService.action({
      content: 'ダッシュボード検索 :' + dashboardName,
    }, 
    {
      action: this.isDashboardTemplate ? SAUCER_LOG_ACTION.DASHBOARD_TEMPLATE_LIST.SEARCH_DASHBOARD : SAUCER_LOG_ACTION.DASHBOARD_LIST.SEARCH_DASHBOARD
    });
    
    this.configDashboardTableRow(false);
    this.dashboardService.setDashboardItem(0, undefined);
    let tempName = dashboardName;
    if (tempName) {
      tempName = tempName.trim();
    }

    if (!tempName) {
      if (!Utils.isNullOrEmpty(this.folderSelected)) {
        this.bindingDashboardTable(this.dashboards.filter((s) => s.folderCd === this.folderSelected.folderCd));
      }
      else {
        this.bindingDashboardTable(this.dashboards);
      }

    }
    else {
      this.bindingDashboardTable(this.dashboards.filter((s: any) => s.name?.toUpperCase()?.trim().includes(dashboardName?.toUpperCase()?.trim())));
    }

    this.dashboardSelecteds = this.dashboardTableData.body.filter(d => d.checked);
    if (this.dashboardSelecteds.length >= 1) {
      this.dspBtnDeleteDashboard = true;
      this.dspBtnEditDashboard = this.dashboardSelecteds.length === 1;
    } else {
      this.dspBtnDeleteDashboard = false;
      this.dspBtnEditDashboard = false;
    }
  }

  // open dialog add new folder
  openDialogAddFolder() {
    this.errorHandleService.setFunctionTitle(FUNCTION_TITLE_TEXT.ADD_DATA_FAIL);
    this.folderEditData = null;
    this.modalFolder.header = FOLDER_DIALOG.FOLDER_DLG_CREATE;
    this.modalFolder.style = { 'width': '393px' };
    this.modalFolder.breakpoints = { '960px': '75vw', '640px': '100vw' };
    this.isDisplayFolderModal = true;
  }

  // open dialog edit folder
  openDialogEditFolder() {
    this.modalFolder.header = FOLDER_DIALOG.FOLDER_DLG_CREATE;
    this.errorHandleService.setFunctionTitle(FUNCTION_TITLE_TEXT.UPDATE_DATA_FAIL);
    this.modalFolder.style = { 'width': '393px' };
    this.modalFolder.breakpoints = { '960px': '75vw', '640px': '100vw' };
    this.isDisplayFolderModal = true;
    if (this.folderSelecteds?.length === 1) {
      this.folderEditData = cloneDeep(this.folderSelecteds[0]);
    }
  }

  // open dialog add new dashboard
  openDialogAddDashboard() {
    this.saucerLogService.action({
      content: CONTENT_LOG.SHOW_DIALOG + ": " + CONTENT_LOG.DASHBOARD_CREATION,
    }, 
    {
      action: this.logAction.CREATE_DASHBOARD_DIALOG
    });
    this.widgetService.pass({ widgetCd: undefined, folderCd: '', mode: ScreenMode.ADD });
    this.widgetService.setCreatingByTemplate({ widgetcdTemplate: null, dsStructCd: null, folderDSCd: null});
    this.dashboardService.setListPublicData([]);
    this.dashboardService.setListNewItem([]);
    this.dashboardEditData = null;
    this.modalAddDashboard.header = DASHBOARD_LIST.DASHBOARD_DLG_TITLE;
    this.modalAddDashboard.style = { 'width': '557px' };
    this.modalAddDashboard.breakpoints = { '960px': '75vw', '640px': '100vw' };
    this.isDisplayDashboardModal = true;
  }

  // event delete folder
  onDeleteFolder() {
    this.deleteArray = [];
    this.errorHandleService.setFunctionTitle(FUNCTION_TITLE_TEXT.DELETE_DATA_FAIL);
    this.folderSelecteds?.forEach(fd => {
      this.deleteArray.push(fd.name);
    });
    this.deleteFolderFlg = true;
    this.isDisplayModalDelete = true;
    this.deleteDashboardFlg = false;
    this.delTxt = COMMON_TEXT.FOLDER;
  }

  // event show confirm modal
  async onSubmitDeleteModel(type: boolean) {
    if (!type) {
      this.isDisplayModalDelete = false;
      return;
    }
    if (this.deleteFolderFlg) {
      let folders: FolderAddDTO[] = [];
      // delete folder
      if (this.folderSelecteds.length > 0) {
        this.folderSelecteds?.forEach((f: any) => {
          let index = findIndex(this.folders, (item) => {
            return item.name == f.name;
          }, 0);
          if (index != -1) {
            f.assigncd = f.assign?.assignCd;
            folders.push(f);
          }
        });

        this.processLoadingService.isLoading.emit(true);
        let deleteFDResult = await this.folderService.delete(folders);
        if (deleteFDResult.statuscode == 200) {
          await this.fetchAllData();
          this.folderData.body = this.folders;
          this.dspBtnDeleteFolder = false;
          this.dspBtnEditFolder = false;
          
          if (this.folderSelected && this.folderSelecteds.some(f => f.folderCd === this.folderSelected.folderCd)) {
            this.dashboardSelecteds = [];
            this.dspBtnDeleteDashboard = false;
            this.dspBtnEditDashboard = false;
            this.dashboards = [];
            this.bindingDashboardTable(this.dashboards);
            this.openDialogSuccess(DialogType.delete);
          }
        }
        this.bindingFolderTable(this.folders);
      }
      this.processLoadingService.isLoading.emit(false);
      this.folderSelecteds = [];
      this.deleteFolderFlg = false;
    }
    else if (this.deleteDashboardFlg) {
      this.isTableDashLoading = true;
      // delete dashboard
      let resDel = await this.dashboardService.delete(this.dashboardSelecteds.map(x => x.dashboardCd));
      if (resDel.data) {
        let dbData = await this.dashboardService.getByFolder(this.folderSelected.folderCd);
        this.dashboards = dbData || [];
        this.dashboardSelecteds = [];
        this.dspBtnDeleteDashboard = false;
        this.dspBtnEditDashboard = false;
        this.bindingDashboardTable(this.dashboards);
        this.isTableDashLoading = false;
        this.openDialogSuccess(DialogType.delete);
      }
      else
      this.isTableDashLoading = false;

      this.dashboardSelecteds = [];
      this.deleteDashboardFlg = false;
    }

    this.isDisplayModalDelete = false;
  }

  // event delete dashboard
  onDeleteDashboard() {
    this.saucerLogService.action({
      content: CONTENT_LOG.SHOW_DIALOG + ": " + this.logAction.DELETE_DASHBOARD.content,
    }, 
    {
      action: this.logAction.DELETE_DASHBOARD
    });
    this.deleteArray = [];
    this.errorHandleService.setFunctionTitle(FUNCTION_TITLE_TEXT.DELETE_DATA_FAIL);
    this.dashboardSelecteds?.forEach(db => {
      this.deleteArray.push(db.name);
    });
    this.deleteDashboardFlg = true;
    this.deleteFolderFlg = false;
    this.isDisplayModalDelete = true;
    this.delTxt = COMMON_TEXT.DASHBOARD;
  }

  openDialogSuccess(dialogType: DialogType) {
    this.modalService.open(ConfirmDialogComponent, {
      data: {
        dialogType: dialogType,
      }
    });
  }

  // change data for folder
  async onChangeDataFolder(data: any) {
    this.isDisplayFolderModal = false;
    if(data != false) {
      if (!Utils.isNullOrEmpty(data)) {
        this.processLoadingService.isLoading.emit(true);
        await this.getFolderData();
        this.processLoadingService.isLoading.emit(false);
        await this.onFilterDashboard(this.folderSelected);
        // update folder
        if (!Utils.isNullOrEmpty(this.folderEditData) && !Utils.isNullOrEmpty(data)) {
          this.openDialogSuccess(DialogType.update);
        }
        // insert folder
        if (!Utils.isNullOrEmpty(data) && Utils.isNullOrEmpty(this.folderEditData)) {
          this.openDialogSuccess(DialogType.save);
        }
      }
    }
  }

  async getFolderData() {
    await this.getListFolderData();
    this.configTableFolder();
    this.folderData.body = this.folders;
    this.dspBtnEditFolder = false;
    this.dspBtnDeleteFolder = false;
    this.folderSelecteds = [];
  }
  // get folder data
  async getListFolderData() {
    let code: string = FOLDER_TYPE.Dashboard.toString();
    let data = this.isDashboardTemplate? 
              await this.folderService.getFolderTemplateByType(FOLDER_TYPE.Dashboard.toString()): 
              await this.folderService.getByType(FOLDER_TYPE.Dashboard.toString(), this.isSupporterAdmin);
    if (data.statuscode == 200) {
      this.folders = data.data || [];
      this.folderEdits = this.folders?.filter(f=>f.act != UserAction.VIEW);
      this.folders.sort();
    }
  }


  // on change data for dashboard
  async onChangeDataDashboard(data: any) {
    this.isDisplayDashboardModal = false;
    if(data && data != false) {
      // update dashboard
      if (!Utils.isNullOrEmpty(this.dashboardEditData) && !Utils.isNullOrEmpty(data)) {
        this.isTableDashLoading = true;
        let dbData = await this.dashboardService.getByFolder(this.folderSelected.folderCd);
        this.dashboards = dbData || [];
        this.bindingDashboardTable();
        setTimeout(() => this.bindingDashboardTable(this.dashboards), 0);
        this.isTableDashLoading = false;
        this.openDialogSuccess(DialogType.update);
        this.dashboardSelecteds = [];
        this.dspBtnDeleteDashboard = false;
        this.dspBtnEditDashboard = false;
      }
    }
    this.dashboardEditData = new DashboardList();
    this.isUpdate = false;
  }

  // event show dialog edit dashboard
  onEditDashboard() {
    this.saucerLogService.action({
      content: CONTENT_LOG.SHOW_DIALOG + ": " + CONTENT_LOG.DASHBOARD_EDITING + " ( folderName: " + this.dashboardSelecteds[0]?.folderName + ", dashboardCd: " + this.dashboardSelecteds[0]?.dashboardCd + ", dashboardName: " + this.dashboardSelecteds[0]?.name + " )" ,
    }, 
    {
      action: this.logAction.UPDATE_DASHBOARD_DIALOG
    });
    this.modalAddDashboard.header = DASHBOARD_LIST.DASHBOARD_DLG_TITLE;
    this.errorHandleService.setFunctionTitle(FUNCTION_TITLE_TEXT.UPDATE_DATA_FAIL);
    this.modalAddDashboard.style = { 'width': '557px' };
    this.modalAddDashboard.breakpoints = { '960px': '75vw', '640px': '100vw' };
    this.isDisplayDashboardModal = true;
    this.isUpdate = true;
    if (this.dashboardSelecteds?.length === 1) {
      this.dashboardEditData = cloneDeep(this.dashboardSelecteds[0]);
    }
  }

  configDashboardTableRow(isSelect: boolean) {
    let dashboardTable = cloneDeep(this.dashboardTableData);
    dashboardTable.config.isSelectedRow = isSelect;
    this.dashboardTableData = cloneDeep(dashboardTable);
  }

  movetoDashboardView(event: any) {
    this.configDashboardTableRow(true);
    this.dashboardService.setDashboardItem(ScreenMode.PREVIEW, event);
    this.dashboardService.setListNewItem([]);
    this.dashboardService.setListDataRemove([]);
    this.dashboardService.setListPublicData([]);
    this.dashboardService.setFeaturePublicChange(null);
    this.saucerLogService.action(
      {
        content: JSON.stringify({
          folderCd: event.folderCd,
          folderName: event.folderName,
          dashboardCd: event.dashboardCd,
          dashboardName: event.name,
        })
      }, 
      {
        action: this.logAction.DASHBOARD_SELECTION
      }
    );
    if (this.isDashboardTemplate) {
      this.router.navigate([LocalStorageHelper.getUrl(ROUTE_PATH.DASHBOARD_TEMPLATE_DETAIL), event.dashboardCd, 3]);
    } else {
      this.router.navigate([LocalStorageHelper.getUrl(ROUTE_PATH.DASHBOARD_DETAIL), event.dashboardCd, 3]);
    }
  }

  // checked row for folder table
  checkRowsFolderTable(data: any) {
    if (data.data?.length >= 1) {
      this.dspBtnDeleteFolder = true;
      if (data.data?.length === 1) {
        this.dspBtnEditFolder = true;
      }
      else {
        this.dspBtnEditFolder = false;
      }

      this.folders.forEach((d: any) => {
        const checkedRow = data.data?.find((item: any) => item.id === d.id);
        d.checked = Boolean(checkedRow);
      });
    }
    else {
      this.dspBtnDeleteFolder = false;
      this.dspBtnEditFolder = false;

      this.folders.forEach((d: any) => d.checked = false);
    }
    this.folderSelecteds = data?.data;

    if (this.folderSelecteds?.length > 0) {
      this.selectedFoldersRole = this.getPermission(this.folderSelecteds);
      this.setPermission(this.selectedFoldersRole);
    } else {
      this.dspBtnEditFolder = false;
      this.dspBtnDeleteFolder = false;
    }

  }

  getPermission(data: any[]) {
    let permission = UserAction.OWNER;
    data.forEach(element => {
      if (element.assign?.act < permission) permission = element.assign?.act;
    });
    return permission;
  }
  setPermission(act: number, type: string = 'folder') {
    this.disableBtnAddFolder = false;
    if (type == 'folder') {
      switch (act) {
        case UserAction.VIEW:
          this.disableBtnDeleteFolder = true;
          this.disableBtnEditFolder = true;
          this.assignPermission = false;
          break;
        case UserAction.EDIT:
          this.disableBtnDeleteFolder = false;
          this.disableBtnEditFolder = false;
          this.assignPermission = false;
          break;
        case UserAction.MANAGER:
          this.disableBtnDeleteFolder = false;
          this.disableBtnEditFolder = false;
          this.assignPermission = true;
          break;
        case UserAction.OWNER:
          this.disableBtnDeleteFolder = false;
          this.disableBtnEditFolder = false;
          this.assignPermission = true;
          break;
        default:
          this.disableBtnDeleteFolder = true;
          this.disableBtnEditFolder = true;
          this.assignPermission = false;
          break;
      }
    } else if (type == 'dashboard') {
      switch (act) {
        case UserAction.VIEW:
          this.disableBtnDeleteDashboard = true;
          this.disableBtnEditDashboard = true;
          this.disableBtnAddDashboard = true;
          break;
        case UserAction.EDIT:
          this.disableBtnDeleteDashboard = false;
          this.disableBtnEditDashboard = false;
          this.disableBtnAddDashboard = false;
          break;
        case UserAction.MANAGER:
          this.disableBtnDeleteDashboard = false;
          this.disableBtnEditDashboard = false;
          this.disableBtnAddDashboard = false;
          break;
        case UserAction.OWNER:
          this.disableBtnDeleteDashboard = false;
          this.disableBtnEditDashboard = false;
          this.disableBtnAddDashboard = false;
          break;
        default:
          this.disableBtnDeleteDashboard = true;
          this.disableBtnEditDashboard = true;
          this.disableBtnAddDashboard = true;
          break;
      }
    }
  }

  checkRowsDashboardTable(data: any) {
    if (data.data?.length >= 1) {
      this.dspBtnDeleteDashboard = true;
      this.dspBtnEditDashboard = data.data?.length === 1;

      this.dashboards.forEach((d: any) => {
        const currentRow = this.dashboardTableData.body.find(b => b.id === d.id);
        if (currentRow) {
          d.checked = currentRow.checked;
        }
      });
      this.dashboardSelecteds = data?.data;
    }
    else {
      this.dspBtnDeleteDashboard = false;
      this.dspBtnEditDashboard = false;
      const outsideSelecteds = this.dashboards.filter(d => this.dashboardSelecteds.every(data => data.id !== d.id));
      this.dashboards.forEach((d: any) => { 
        if (outsideSelecteds.every(o => o.id !== d.id)) {
          d.checked = false;
        }
      });
      this.dashboardSelecteds = outsideSelecteds;
    }
  }

  // binding data for table dashboard
  bindingFolderTable(data: any[] = []) {
    let folderTable = cloneDeep(this.folderData);
    folderTable.body = data;
    this.folderData = cloneDeep(folderTable);
  }

  // binding data for table folder
  bindingDashboardTable(data: any[] = []) {
    let dashboardTable = cloneDeep(this.dashboardTableData);
    dashboardTable.body = data;
    this.dashboardTableData = cloneDeep(dashboardTable);
  }

  // event click row in table folder
  async onFilterDashboard(data: any) {
    this.errorHandleService.setFunctionTitle(FUNCTION_TITLE_TEXT.SWITCHING_FAIL);
    this.dashboardService.setListPublicData([]);
    this.dspBtnEditDashboard = false;
    this.dspBtnDeleteDashboard = false;
    this.folderSelected = data;
    this.setPermission(data?.act, 'dashboard');
    this.isTableDashLoading = true;
    this.dashboardSelecteds = [];
    if(!Utils.isNullOrEmpty(data?.folderCd)) {
      let dbData = await this.dashboardService.getByFolder(data?.folderCd);
      this.dashboards = dbData || [];
    }
    this.bindingDashboardTable(this.dashboards);
    this.isTableDashLoading = false;
  }

  // fetch data
  async fetchAllData() {
    this.processLoadingService.isLoading.emit(true);
    let resData : any = {};
    resData = this.isDashboardTemplate? await this.folderService.getFolderTemplateByType(FOLDER_TYPE.Dashboard.toString()): 
            await this.folderService.getByType(FOLDER_TYPE.Dashboard.toString(), this.isSupporterAdmin);

    if (resData.statuscode == 200) {
      this.folders = resData?.data || [];
      this.folderEdits = this.folders?.filter(f=>f.act != UserAction.VIEW);
    }
    this.processLoadingService.isLoading.emit(false);
  }

  // config data table dashboard
  configTableDashboard() {
    this.dashboardTableData = {
      config: {
        id: 'dashboard-1',
        caption: '',
        emptyMessage: COMMON_TEXT.NO_DATA,
        showIndex: false,
        showCheckbox: false,
        showPagination: true,
        hoverShowCheckbox: true,
        isResponsive: true,
        isSelectedRow: true,
        tableStyle: { 'margin-top': '4px', 'font-weight': 'unset !important' },
        inlineTableStyle: { 'table-layout': 'fixed', 'width': '100%' }
      },
      header: [
        {
          field: 'name',
          title: DASHBOARD.NAME,
          sortable: false,
          filterable: false,
          attribute: {
            header: { width: '30%' },
            row: {},
            rowClass: 'table-dashboard-row-text-overflow'
          },
          dataType: DataType.TEXT,
          visible: true
        },
        {
          field: 'description',
          title: DASHBOARD.DESCRIPTION,
          sortable: false,
          filterable: false,
          attribute: {
            header: { width: '30%' },
            row: {},
            rowClass: 'table-dashboard-row-text-overflow'
          },
          dataType: DataType.TEXT,
          visible: true
        },
        {
          field: 'folderId',
          title: DASHBOARD.FOLDERID,
          sortable: false,
          filterable: false,
          attribute: {
            header: { width: '0%' },
            row: {}
          },
          dataType: DataType.TEXT,
          visible: false
        },
        {
          field: 'folderName',
          title: DASHBOARD.FOLDERNAME,
          sortable: false,
          filterable: false,
          attribute: {
            header: { width: '20%' },
            row: {},
            rowClass: 'table-dashboard-row-text-overflow'
          },
          dataType: DataType.TEXT,
          visible: true
        },
        {
          field: 'displayNm',
          title: DASHBOARD.DISPLAYNM,
          sortable: false,
          filterable: false,
          attribute: {
            header: { width: '10%' },
            row: {},
            rowClass: 'table-dashboard-row-center table-dashboard-row-text-overflow'
          },
          dataType: DataType.TEXT,
          visible: true
        },
        {
          field: 'displayDate',
          title: DASHBOARD.DISPLAYDATE,
          sortable: false,
          filterable: false,
          attribute: {
            header: { width: '10%' },
            row: {},
            rowClass: 'table-dashboard-row-center table-dashboard-row-text-overflow'
          },
          dataType: DataType.TEXT,
          visible: true
        }
      ],
      body: []
    };
  }

  // config data table folder
  configTableFolder() {
    // table folder params
    this.folderData = {
      config: {
        id: '1',
        caption: '',
        emptyMessage: COMMON_TEXT.NO_DATA,
        showIndex: false,
        showCheckbox: false,
        showPagination: false,
        hoverShowCheckbox: true,
        isResponsive: true,
        isSelectedRow: true,
        tableStyle: { 'border-bottom': 'solid 1px #969696' }
      },
      header: [
        {
          field: 'name',
          title: FOLDER.DATANAME,
          sortable: false,
          filterable: false,
          attribute: {
            header: { width: '100%' },
            row: {}
          },
          dataType: DataType.TEXT,
          visible: true,
          isHasSubText: true
        },
        {
          field: 'id',
          title: 'id',
          sortable: false,
          filterable: false,
          attribute: {
            header: { width: '100%' },
            row: {}
          },
          dataType: DataType.TEXT,
          visible: false
        },
        {
          field: 'folderType',
          title: 'folderType',
          sortable: false,
          filterable: false,
          attribute: {
            header: { width: '100%' },
            row: {}
          },
          dataType: DataType.TEXT,
          visible: false
        },
        {
          field: 'assign',
          title: 'assign',
          sortable: false,
          filterable: false,
          attribute: {
            header: { width: '100%' },
            row: {}
          },
          dataType: DataType.TEXT,
          visible: false
        },
        {
          field: 'delflg',
          title: 'delflg',
          sortable: false,
          filterable: false,
          attribute: {
            header: { width: '100%' },
            row: {}
          },
          dataType: DataType.TEXT,
          visible: false
        }
      ],
      body: []
    };
  }
}

