<div class="help-list">
  <div class="help-list-heading">
    {{HELP_LIST.TITLE}}
  </div>
  <div class="help-list-content">
    <div class="help-list-header">
      <div class="help-list-category">        
        <pivot-search-bar 
            [searchParams]="categoryParams" 
            (onFilterData)="onSelectCategory($event)">
        </pivot-search-bar>
      </div>
      <div class="help-list-ccjsite">
        {{HELP_LIST.CCJ_SITE}}<a saucerLogger [logMessage] = "logMessageAction"  href="https://support.carekarte.jp/" target="_blank">{{HELP_LIST.HERE}}</a>
      </div>
    </div>

    <div class="help-list-content-scoll-view">
      <div *ngFor="let item of filterByCategory(lstHelp, selectedCode)" class="help-item" (click) = "onClickItem(item)">
        <div class="help-header">
          <div class="title">{{item.title??""}}</div>
          <div class="tab-unread" *ngIf="item.unread == '1'">{{HELP_LIST.UNREAD}}</div>
        </div>
        <div class="time-inserted">
          {{getInsertdate(item)}}
        </div>
        <div class="body-help">
          <div *ngFor="let content of item.displayContent">
            <div class="div-content" *ngIf="content.type == 'div'">
              {{content.content}}
            </div>
            <div *ngIf="content.type == 'img'">
              <img src="{{content.content}}" alt="Image">
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div>
    <pivot-help-detail-dialog [data] = "heplItem" [showDialog]="showDialog" (closeDialog) = "onCloseDialog($event)"></pivot-help-detail-dialog>
  </div>
</div>