<button
    #button
    [attr.type]="type"
    [attr.aria-label]="ariaLabel"
    [class]="styleClass"
    [ngStyle]="style"
    [style]="styles"
    [disabled]="disabled || loading"
    [ngClass]="buttonClass()"
    (click)="onClick.emit($event); button.blur()"
    (focus)="onFocus.emit($event)"
    (blur)="onBlur.emit($event)"
>
    <ng-content></ng-content>
    <ng-container *ngIf="loading">
        <ng-container *ngIf="!loadingIconTemplate">
            <span *ngIf="loadingIcon" [class]="'pivot-button-loading-icon' + icon" [ngClass]="iconClass()"></span>
        </ng-container>
        <span *ngIf="loadingIconTemplate" class="pivot-button-loading-icon">
            <ng-template *ngTemplateOutlet="loadingIconTemplate"></ng-template>
        </span>
    </ng-container>
    <ng-container *ngIf="!loading">
        <span *ngIf="icon && !iconTemplate" [class]="icon" [ngClass]="iconClass()">
            <svg-icon [src]="icon" class="button-svg"></svg-icon>
        </span>
    </ng-container>
    <span class="pivot-button-label" [attr.aria-hidden]="icon && !label" *ngIf="!contentTemplate && label">{{ label }}</span>
</button>