
import { Directive, ElementRef, Renderer2, AfterViewChecked } from '@angular/core';

@Directive({
  selector: '[appTargetBlank]',
})
export class TargetBlankDirective implements AfterViewChecked {
  constructor(private elementRef: ElementRef, private renderer: Renderer2) {}
  
  ngAfterViewChecked(){
    const links = this.elementRef.nativeElement.querySelectorAll('a');
    links.forEach((link: any) => {
      this.renderer.setAttribute(link, 'target', '_blank');
    });
  }
}
