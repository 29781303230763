<pivot-modal-template class="widget-result-update-dialog" *ngIf="isDisplayModal" [modalTemplate]="modalData"
  (closeModalHandler)="onClose()">
  <div modal-body style="height: 100%;">
    <div class="content header" >
      <div class="content override">
        <label class = "label">{{SYSTEM_SETTING.SEARCH_AREA}}</label>
      </div>
      <div class="title">
        <div class="search-bars">
          <div class = "element">
            <div class="search-bar-label">{{UPDATE_DATA.WIDGET_TITLE}}</div>
            <pivot-search-bar class="search-bar" (onFilterData)="onFilterwidgetData($event)" [placeHolderFilter]="HOME.SEARCH_WIDGET" 
            [searchParams]="searchParamsWidget"  ></pivot-search-bar>
          </div>
          <div class = "element">
            <div class="search-bar-label">{{UPDATE_DATA.DATA_SOURCE_TITLE}}</div>
            <pivot-search-bar class="search-bar" (onFilterData)="onFilterDatasourceData($event)" class="search-bar" [searchParams]="searchParamsDataSource" [placeHolderFilter]="HOME.SEARCH_DATASOURCE"></pivot-search-bar>
          </div>
          <div class = "element">
            <div class="search-bar-label">{{UPDATE_DATA.DASHBOARD_TITLE}}</div>
            <pivot-search-bar class="search-bar" (onFilterData)="onFilterDashboardData($event)" [placeHolderFilter]="HOME.SEARCH_DASHBOARD" 
            [searchParams]="searchParamsDashboard"></pivot-search-bar>
          </div>
          <div class = "search-btn-group">
            <pivot-text-button [iconType]="iconType.SEARCH" (btnClick)="onSearch()" class="update-button"  [buttonType]="buttonType.SECONDARY" [buttonText]="BUTTON.SEARCH">
            </pivot-text-button>  
          </div>
          
        </div>
      </div>
    </div>
    <div class="content body">
      <div class="widget-table">
        <pivot-table [isLoading]="isLoadingWidgetTable" [data]="filteredTable" [selectedRows]="widgetSelecteds" 
          [checkAllMode]="checkAllMode.ALL_PAGE " (checkRows)="checkRowsDashboardTable($event)" [loadicon]="loadicon" [checked]="checked" [proccess]="process"  >
          <div action-buttons class="table-button-wrapper"></div>
        </pivot-table>
      </div>
    </div>
    <div modal-bottom class="content footer">
      <div class="over-ds-select-error" *ngIf="isOverDatasourceSelected">
        <span>{{TEXT_COMMON.ERROR_MESSAGE}}</span>
      </div>
      <div>
        <div class="checkbox-container" >
          <div class="checkbox-container-override">
            <label class = "label">{{SYSTEM_SETTING.RELATED_WIDGET_UPDATE}}
              <div class= "check-box-button">
                <label class="checkbox-label" >
                  <pivot-radio-button [disabled]="disableRadioBtn" [styleClass]="'widget-update-radio-btn'" [isChecked]="isAllowSyncWidget" (onClick)="onCheckbox(true)">
                  </pivot-radio-button>
                  <span>{{UPDATE_DATA.CHECK_BOX_WORK}}</span>
                </label>
                <label class="checkbox-label" >
                  <pivot-radio-button [disabled]="disableRadioBtn" [styleClass]="'widget-update-radio-btn'" [isChecked]="!isAllowSyncWidget" (onClick)="onCheckbox(false)">
                  </pivot-radio-button>
                  <span>{{UPDATE_DATA.CHECK_BOX_NOT_WORK}}</span>
                </label>
              </div>
            </label>
          </div>
        </div>
        <pivot-text-button [iconType]="iconType.CLOSE_DIALOG" (btnClick)="onClose()" [buttonType]="buttonType.SECONDARY" [buttonText]="BUTTON.CANCEL">
        </pivot-text-button>
        <div class="sync-btn" [ngClass]="disableSyncBtn || isOverDatasourceSelected ? 'disable' : ''">
          <button [disabled]="disableSyncBtn || isOverDatasourceSelected" class="button-sync-widget"
          (click)="onSync()">
          <div class="img-of-button" >
              <img class="text-of-button" [ngClass]="isDarkMode ? 'dark-mode' : 'default'">{{BUTTON.UPDATE}}
            </div>
          </button>
        </div>
      </div>
    </div>
  </div>
</pivot-modal-template>