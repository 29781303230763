import { FolderService } from '../../../services/modules/folder.service';
import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { cloneDeep } from 'lodash';
import { ButtonType, DataType, SearchType } from '../../../enum/common-enum';
import { ModalTemplate, SearchParams } from '../../../models/common-model';
import { Folder, FOLDER_TYPE } from '../../../models/response/folder.ro';
import { TableData } from '../../../models/table-model';
import Utils from '../../../util/utils';
import { BUTTON, COMMON_TEXT, FOLDER_DIALOG } from '../../../../app/const/text-common';
import { FOLDER_MODAL } from '../../../const/table-col-define';
import { Router } from '@angular/router';
import {AuthenticationService} from 'src/app/services/authentication.service';
import { SaucerLogService } from 'src/app/services/saucer-logs/saucer-log.service';
import { CONTENT_LOG } from 'src/app/config/saucer-log.config';

@Component({
  selector: 'pivot-folder-modal',
  templateUrl: './folder-modal.component.html',
  styleUrls: ['./folder-modal.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class FolderModalComponent implements OnInit {
  @Input() folderList: any[] = [];
  @Input() selectedFolder: any = {};
  @Input() folderType:  FOLDER_TYPE = FOLDER_TYPE.Datasource;
  @Input() appendTo: string = '';
  @Input() styleClass: string = '';
  @Input() editorAppendTo: string = '';
  @Input() editorStyleClass: string = '';
  @Input() folderActionLog: any;
  
  @Output() closeModalHandler = new EventEmitter();
  @Output() selectedFolderHandler = new EventEmitter<any>();
  @Output() onChangeFolderHandler = new EventEmitter<any>();   

  BUTTON = BUTTON;
  COMMON_TEXT = COMMON_TEXT;
  modalTemplate: ModalTemplate = new ModalTemplate();
  buttonType = ButtonType;
  searchParams: SearchParams = {
    type: SearchType.input,
    placeholder: COMMON_TEXT.SEARCH,
    cssStyle: { width: '100%', height: '40px' },
    readonly: false,
    disabled: false
  }
  tableData: TableData = {
    config: {
      id: 'folder-service',
      caption: '',
      emptyMessage: COMMON_TEXT.NO_DATA,
      showIndex: true,
      showCheckbox: false,
      hoverShowCheckbox: false,
      showPagination: false,
      isResponsive: false,
      tableStyle: {},
      isSelectedRow: true
    },
    header: [
      {
        field: 'id',
        title: FOLDER_MODAL.ID,
        sortable: false,
        filterable: false,
        attribute: {
          header: { width: '10%' },
          row: {}
        },
        dataType: DataType.TEXT,
        visible: false
      },
      {
        field: 'name',
        title: FOLDER_MODAL.NAME,
        sortable: false,
        filterable: false,
        attribute: {
          header: { width: '90%' },
          row: {}
        },
        dataType: DataType.TEXT,
        visible: true
      },
    ],
    body: []
  };
  isFilterAll: boolean = true;
  isShowModal: boolean = false;
  selectedServices: Array<Folder> = [];
  isDisplayFolderModal: boolean = false;
  isTemplateChoosingPage: boolean = false;
  isTemplate: boolean;
  listServices: Array<Folder> = [];
  loadingTable: boolean = true;
  data: any[] =[];
  folderData: any = null;
  modalFolder: ModalTemplate = new ModalTemplate();
  isSupporterAdmin: boolean;

  constructor(
    private folderService: FolderService, 
    private router: Router,
    private authenticationService: AuthenticationService,
    private saucerLogService : SaucerLogService
  ) { }

  async ngOnInit() {
    this.isSupporterAdmin = await this.authenticationService.isAdminOrSupporter();
    this.modalTemplate.header = BUTTON.SELECT_FOLDER;
    this.modalTemplate.style = { 'width': '500px', 'height': '600px' };
    this.modalTemplate.appendTo = this.appendTo;
    this.modalTemplate.styleClass = this.styleClass;

    this.modalFolder.header = FOLDER_DIALOG.FOLDER_DLG_CREATE;
    this.modalFolder.style = { 'width': '393px' };
    this.modalFolder.appendTo = this.editorAppendTo;
    this.modalFolder.styleClass = this.editorStyleClass;

    this.loadingTable = false;
    this.data = this.folderList;
    this.tableData.body = this.folderList;
    this.tableData = cloneDeep(this.tableData);
    this.isTemplateChoosingPage = this.router.url.includes("widget") ? true : false;
    this.isTemplate = ((this.router.url.includes("dashboard-template") || this.router.url.includes("datasource-template")) && !this.isTemplateChoosingPage) ? true : false;
  }

  closeFormModal() {
    this.closeModalHandler.emit();
  }

  onFilterOutData(folderName: any) { 
    this.saucerLogService.action(
      {
        content: CONTENT_LOG.SEARCH_FOLDER + folderName
      }, 
      {
        action: this.folderActionLog?.SEARCH_FOLDER_SELECTION
      }
    );
    let folders = this.folderList.filter(o => {
      return o.name?.includes(folderName);

    });
    let table = cloneDeep(this.tableData);
    table.body = folders;
    this.tableData = table;
  }

  clickRowTable(data: Folder) {
    this.selectedFolder = this.folderList.find(f => f.id === data.id) || data;
  }

  save() {
    //Save folder
    this.selectedFolderHandler.emit(this.selectedFolder);
    this.closeModalHandler.emit();

  }
  cancel() {
    this.closeModalHandler.emit();
  }

  createNewFolder() {
    this.isDisplayFolderModal = true;
  }

  async onChangeDataFolder(data: any) {
    this.loadingTable = true;
    // insert folder
    if (!Utils.isNullOrEmpty(data) && Utils.isNullOrEmpty(this.folderData)) {
      let folderRes = await this.folderService.getByType(this.folderType.toString(), this.isSupporterAdmin);
      if (folderRes && folderRes.statuscode == 200) {
        this.folderList = folderRes?.data?.filter(s => s.act != 1) || [];
      }
      this.onChangeFolderHandler.emit(this.folderList);
      let table = cloneDeep(this.tableData);
      table.body = this.folderList;
      this.tableData = table;
    }
    this.loadingTable = false;
    this.isDisplayFolderModal = false;
  }
}
