<pivot-modal-template *ngIf="isDisplayModal" [modalTemplate]="modalData"
    (closeModalHandler)="onClose()">
    <div modal-body>
        <div class="content body">
            <div>
                <div>{{BASIC_INFO.AVATAR_RULE_CONTENT_1}}</div>
                <div>{{BASIC_INFO.AVATAR_RULE_CONTENT_2}}</div>
                <div>{{BASIC_INFO.AVATAR_RULE_CONTENT_3}}</div>
            </div>
        </div>
    </div>
    <div modal-bottom class="content footer">
    </div>
</pivot-modal-template>