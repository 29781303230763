import { Component, EventEmitter, Input, OnChanges, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import Utils from 'src/app/util/utils';
import { MESSAGE_TEXT } from '../../../../../app/const/message';
import { BUTTON, COMMON_TEXT } from '../../../../../app/const/text-common';
import { ButtonType, SaveType } from '../../../../../app/enum/common-enum';
import { ModalTemplate } from '../../../../../app/models/common-model';

@Component({
  selector: 'pivot-dialog-chart',
  templateUrl: './dialog-chart.component.html',
  styleUrls: ['./dialog-chart.component.scss'],
  encapsulation: ViewEncapsulation.None,
})


export class DialogChartComponent implements OnInit, OnChanges {
  buttonType = ButtonType;
  saveType = SaveType;
  BUTTON = BUTTON;
  MESSAGE_TEXT = MESSAGE_TEXT;
  isDspBtnCanel: boolean = true;
  description: string = MESSAGE_TEXT.CONFIRM_CHART;
  modalData: ModalTemplate = new ModalTemplate();

  constructor(public ref: DynamicDialogRef,
    public config: DynamicDialogConfig) {
      this.config.showHeader = true;
      this.config.style = { 
        width: '700px',
        header: COMMON_TEXT.CONFIRM
      };
     }

  ngOnChanges(): void {
  }

  ngOnInit(): void {
    let dlgConfig = this.config?.data;
    if(dlgConfig) {
      if(!Utils.isNullOrEmpty(dlgConfig.isDspBtnCanel)) this.isDspBtnCanel = dlgConfig.isDspBtnCanel;
      this.description = dlgConfig.description ?  dlgConfig.description : MESSAGE_TEXT.CONFIRM_CHART;
    }
  }

  onSubmit(data: boolean) {
    this.ref.close(data);
  }
}
