import { Injectable } from '@angular/core';
import { DeviceDetectorService } from 'ngx-device-detector';
import { BehaviorSubject, Observable } from 'rxjs';
import { DeviceType } from '../enum/common-enum';

export interface IDeviceType {
  type: DeviceType;
}
@Injectable({ providedIn: 'root' })
export class DetectDeviceService {

  public currentStatus!: DeviceType;
  public currentDeviceSubject: BehaviorSubject<any>;
  public currentDevice: Observable<IDeviceType>;

  constructor(private deviceService: DeviceDetectorService) {
    const deviceType = this.getDeviceType();
    this.currentDeviceSubject = new BehaviorSubject<any>({ type: deviceType });
    this.currentDevice = this.currentDeviceSubject.asObservable();

    window.onresize = () => {
      this.setDeviceValue();
    };
  }

  getDeviceType(): DeviceType {
    if (this.deviceService.isMobile()) {
      return DeviceType.MOBILE;
    } else if (this.deviceService.isTablet()) {
      return DeviceType.TABLET;
    }

    if (window.innerWidth < 540) {
      return DeviceType.MOBILE;
    }
    return DeviceType.DESKTOP;
  }

  setDeviceValue(): void {
    const deviceType = this.getDeviceType();
    this.currentDeviceSubject.value.type = deviceType;
    this.currentDeviceSubject.next(this.currentDeviceSubject.value);
  }
}
