import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { cloneDeep, get } from 'lodash';
import { DialogService } from 'primeng/dynamicdialog';
import { FOLDER, WIDGET } from '../../const/table-col-define';
import { BUTTON, COMMON_TEXT, FOLDER_DIALOG, WIDGET_LIST, WIDGET_TEMPLATE_LIST } from '../../../app/const/text-common';
import { ConfirmDialogComponent } from '../../component/common/confirm-dialog/confirm-dialog.component';
import { ROUTE_PATH } from '../../const/route-path';
import { ButtonIconType, ButtonType, CheckAllMode, DataType, DialogType, ScreenMode, SearchType, UserAction } from '../../enum/common-enum';
import { ModalTemplate, SearchParams } from '../../models/common-model';
import { FolderAddDTO } from '../../models/request/folder.dto';
import { Folder, FOLDER_TYPE } from '../../models/response/folder.ro';
import { widget, WidgetList } from '../../models/response/widget.ro';
import { TableData } from '../../models/table-model';
import { ProcessLoadingService } from '../../services/loading.service';
import { FolderService } from '../../services/modules/folder.service';
import { WidgetService } from '../../services/modules/widget.service';
import Utils from '../../util/utils';
import { LocalStorageHelper } from '../../_helper/local-storage.helper';
import {AuthenticationService} from 'src/app/services/authentication.service';
import {ErrorHandleService} from 'src/app/services/error-handle.service';
import {FUNCTION_TITLE_TEXT} from 'src/app/const/error-text';
import { SAUCER_LOG_ACTION, SaucerLogService } from 'src/app/services/saucer-logs/saucer-log.service';
import { ACTION_LOG, SCREEN_NAME } from 'src/app/config/saucer-log.config';

@Component({
  selector: 'pivot-widget-list',
  templateUrl: './widget-list.component.html',
  styleUrls: ['./widget-list.component.scss']
})
export class WidgetListComponent implements OnInit, OnDestroy {

  BUTTON = BUTTON;
  folderType = FOLDER_TYPE;

  // title form
  wigetTitle: string;
  // folder data edit
  folderEditData: any = null;
  // folder data edit 
  widgetEditData: any = null;
  // display modal variables
  isDisplayFolderModal: boolean = false;
  isDisplaywidgetModal: boolean = false;
  // delete folder flag
  deleteFolderFlg: boolean = false;
  // delete dashboard flag
  deleteWidgetFlg: boolean = false;
  isDisplayModalDelete: boolean = false;
  // modal templete
  modalFolder: ModalTemplate = new ModalTemplate();
  modalAddwidget: ModalTemplate = new ModalTemplate();
  modalDelete: ModalTemplate = new ModalTemplate();
  buttonType = ButtonType;
  // disable button
  dspBtnEditFolder: boolean = false;
  dspBtnDelFolder: boolean = false;
  dspBtnDelWidget: boolean = false;
  dspBtnAddWidget: boolean = false;
  // btn add widget
  addWidgetStr: string = BUTTON.WIDGET_CREATE;
  // icon button
  iconType = ButtonIconType;
  //  array folder selected
  folderSelecteds: any[] = [];
  // array widget selected
  widgetSelecteds: any[] = [];
  deleteArray: string[] = [];
  // search folder params
  searchFolder: SearchParams = {
    type: SearchType.input,
    placeholder: COMMON_TEXT.SEARCH,
    cssStyle: { height: '46px', margin: '10px 5px 10px 0px' },
    readonly: false,
    disabled: false,
    maxLength: 100
  };

  // search widget params
  searchWidget: SearchParams = {
    type: SearchType.input,
    placeholder: COMMON_TEXT.SEARCH,
    cssStyle: { height: '46px', margin: '11px', width: '550px' },
    readonly: false,
    disabled: false,
    maxLength: 100
  };

  // folder list 
  folders: Folder[] = [];
  folderData: TableData;
  // list widget
  widgets: WidgetList[] = [];
  // table widget params
  widgetTableData: TableData;
  delTxt: string = "";
  // folder selected
  folderSelected: any = null;

  widgetActionButtonDisabled: boolean = false;
  folderActionButtonDisabled: boolean = false;
  selectedFolder: Folder | null = null;
  assignPermission: boolean = false;
  isLoadingWidgetTable: boolean = false;
  isWidgetTemplate: boolean;
  checkAllMode = CheckAllMode;
  dashboardName: any[];
  isDeleteWidget: boolean = false;
  isSupporterAdmin: boolean;
  // flag check if site navigate to widget-setting screen
  isMoveToWidgetSettingScreen: boolean = false;

  //#region Log
  SAUCER_LOG_ACTION = SAUCER_LOG_ACTION;
  actionLog = SAUCER_LOG_ACTION.WIDGET_LIST
  //#endregion

  constructor(
    private router: Router,
    private modalService: DialogService,
    private folderService: FolderService,
    private widgetService: WidgetService,
    private loading: ProcessLoadingService,
    private authenticationService: AuthenticationService,
    private processLoadingService: ProcessLoadingService,
    private errorHandleService: ErrorHandleService,
    private saucerLogService: SaucerLogService
  ) {
    this.isWidgetTemplate = this.router.url.includes("widget-template") ? true : false;
    if(this.isWidgetTemplate) this.actionLog = SAUCER_LOG_ACTION.WIDGET_LIST_TEMPLATE;
    this.wigetTitle = this.isWidgetTemplate ? WIDGET_TEMPLATE_LIST.TITLE : WIDGET_LIST.TITLE;

    //Log
    this.saucerLogService.action({}, { action: this.actionLog.VIEW });
    
  }
  ngOnDestroy(): void {
    if(!this.isMoveToWidgetSettingScreen) {
      this.widgetService.pass({ widgetCd: undefined, folderCd: '', mode: ScreenMode.ADD });
    }
  }

  async ngOnInit() {
    this.isSupporterAdmin = await this.authenticationService.isAdminOrSupporter();
    this.errorHandleService.setFunctionTitle(FUNCTION_TITLE_TEXT.SCREEN_INIT_FAIL);
    this.configTableFolder();
    this.configTablewidget();
    await this.fetchAllData();
    let folderData = cloneDeep(this.folderData);
    folderData.body = this.folders;
    this.folderData = folderData;
    let widget = this.widgetService.pick();
    if (!Utils.isNullOrEmpty(widget)) {
      this.folderSelected = this.folders?.find(fd => fd.folderCd == widget?.folderCd);
      await this.onFilterWidget(this.folderSelected);
    }
    else {
      this.dspBtnAddWidget = !(this.folderSelected == null);
    }
  }

  // onfilter data folder
  onFilterFolderData(folderName: string) {
    let cloneName = folderName;
    if (cloneName) {
      cloneName = cloneName.trim();
    }

    if (!cloneName) {
      this.bindingFolderTable(this.folders);
    }
    else {
      this.bindingFolderTable(this.folders.filter((s: any) => s.name?.toUpperCase().includes(cloneName?.toUpperCase())));
    }

    //Log
    this.saucerLogService.action({
      content: (this.actionLog?.SEARCH_FOLDER_SELECTION?.content || "") + folderName
    }, { 
      action: this.actionLog.SEARCH_FOLDER_SELECTION
    });
  }

  // filter widget data
  onFilterwidgetData(widgetName: string) {
    let cloneName = widgetName;
    if (cloneName) {
      cloneName = cloneName.trim();
    }

    if (!cloneName) {
      this.bindingWidgetTable(this.widgets);
    }
    else {
      this.bindingWidgetTable(this.widgets.filter((s: any) => s.name?.toUpperCase().includes(cloneName?.toUpperCase())));
    }

    this.widgetSelecteds = this.widgetTableData.body.filter(d => d.checked);
    this.dspBtnDelWidget = this.widgetSelecteds.length >= 1;

    //Log
    this.saucerLogService.action({
      content: this.actionLog.SEARCH_WIDGET.content + widgetName
    }, { 
      action: this.actionLog.SEARCH_WIDGET
    });
  }

  // open dialog add new folder
  openDialogAddFolder() {
    this.errorHandleService.setFunctionTitle(FUNCTION_TITLE_TEXT.ADD_DATA_FAIL);
    this.folderEditData = null;
    this.assignPermission = (this.folderSelecteds[0]?.act != UserAction.VIEW && this.folderSelecteds[0]?.act != UserAction.EDIT) ? true : false;
    this.modalFolder.header = FOLDER_DIALOG.FOLDER_DLG_CREATE;
    this.modalFolder.style = { 'width': '393px' };
    this.modalFolder.breakpoints = { '960px': '75vw', '640px': '100vw' };
    this.isDisplayFolderModal = true;
  }

  // open dialog edit folder
  openDialogEditFolder() {
    this.modalFolder.header = FOLDER_DIALOG.FOLDER_DLG_CREATE;
    this.errorHandleService.setFunctionTitle(FUNCTION_TITLE_TEXT.UPDATE_DATA_FAIL);
    this.modalFolder.style = { 'width': '393px' };
    this.modalFolder.breakpoints = { '960px': '75vw', '640px': '100vw' };
    this.isDisplayFolderModal = true;
    if (this.folderSelecteds?.length === 1) {
      this.folderEditData = cloneDeep(this.folderSelecteds[0]);
    }
  }

  // event delete folder
  onDeleteFolder() {
    this.isDeleteWidget = false;
    this.errorHandleService.setFunctionTitle(FUNCTION_TITLE_TEXT.DELETE_DATA_FAIL);
    this.deleteArray = [];
    this.folderSelecteds?.forEach(fd => {
      this.deleteArray.push(fd.name);
    });
    this.deleteFolderFlg = true;
    this.deleteWidgetFlg = false;
    this.isDisplayModalDelete = true;
    this.delTxt = COMMON_TEXT.FOLDER;
  }

  // event show confirm modal
  async onShowConfirmDeleteModel(type: boolean) {
    if (!type) {
      this.isDisplayModalDelete = false;
      return;
    }
    if (this.deleteFolderFlg) {
      // delete folder
      if (this.folderSelecteds.length > 0) {
        let deleteRes = null;
        const folders: FolderAddDTO[] = [];
        this.folderSelecteds.forEach(f => folders.push({ ...f, assigncd: f.assign?.assignCd }));
        try {
          deleteRes = await this.folderService.delete(folders);
        } catch (ex) {
        }

        if (deleteRes && deleteRes.statuscode == 200) {
          this.folders = this.folders.filter(f => !this.folderSelecteds.some(s => s.folderCd === f.folderCd));
          this.bindingFolderTable(this.folders);
          await this.fetchAllData();
          this.hideFolderActionButtons();
          if (this.selectedFolder && this.folderSelecteds.find(f => f.folderCd === this.selectedFolder?.folderCd)) {
            this.selectedFolder = null;
            this.dspBtnAddWidget = false;
            this.unbindingWidget();
          }
          this.selectedFolder = this.folders.length == 0 ? null : this.selectedFolder;
          this.openDialogSuccess(DialogType.delete);
        }
      }
      this.folderSelecteds = [];
      this.deleteFolderFlg = false;
    }
    else if (this.deleteWidgetFlg) {
      // delete widget
      if (this.widgetSelecteds.length > 0) {
        // call delete api
        this.processLoadingService.isLoading.emit(true);
        let deleteRes = null;
        try {
          const log = this.widgetSelecteds.map(x => ({
            widgetCd: x.widgetCd,
            name: x.name,
            folderCd: x.folderCd,
            folderName: x.folderName
          }));
          deleteRes = await this.widgetService.delete(this.widgetSelecteds.map(w => w.widgetCd), this.actionLog, log);
        } catch (e) {
        }

        this.dspBtnDelWidget = false;
        this.processLoadingService.isLoading.emit(false);

        if (deleteRes && deleteRes.statuscode == 200) {
          this.widgets = this.widgets.filter(w => !this.widgetSelecteds.some(s => s.widgetCd === w.widgetCd));

          this.bindingWidgetTable(this.widgets);
          this.openDialogSuccess(DialogType.delete);
        }
      }
      this.widgetSelecteds = []
      this.deleteWidgetFlg = true;
    }

    this.isDisplayModalDelete = false;
  }

  // event delete widget
  async onDeletewidget() {
    this.errorHandleService.setFunctionTitle(FUNCTION_TITLE_TEXT.DELETE_DATA_FAIL);
    this.isDeleteWidget = true;
    if (this.widgetActionButtonDisabled) {
      return;
    }
    this.deleteArray = [];
    await this.getAllDashboardNameThatUseSelectedWidget( this.widgetSelecteds);
    this.widgetSelecteds?.forEach(wdget => {
      this.deleteArray.push(wdget.name);
    })

    this.deleteWidgetFlg = true;
    this.deleteFolderFlg = false;
    this.isDisplayModalDelete = true;
    this.delTxt = COMMON_TEXT.WIDGET;
  }

  openDialogSuccess(dialogType: DialogType) {
    this.modalService.open(ConfirmDialogComponent, {
      data: {
        dialogType: dialogType,
      }
    });
  }

  // change data for folder
  async onChangeDataFolder(data: any) {
    if(data != false) {
      // update folder
      if (!Utils.isNullOrEmpty(this.folderEditData) && !Utils.isNullOrEmpty(data)) {
        this.folders?.map((p: any) => {
          if (p.id == data?.id) {
            p.name = data.name;
            p.description = data.description;
            p.accessName = data?.accessName;
          }
        });
        this.folderSelecteds = [];
        await this.fetchAllData();
        this.hideFolderActionButtons();
        this.selectedFolder = this.folders.find(x => x.folderCd == this.selectedFolder?.folderCd) || null;
        if (this.selectedFolder) await this.onFilterWidget(this.selectedFolder);
        this.openDialogSuccess(DialogType.update);
      }
      // insert folder
      if (!Utils.isNullOrEmpty(data) && Utils.isNullOrEmpty(this.folderEditData)) {
        this.folders.push(data);
        if (this.selectedFolder) await this.onFilterWidget(this.selectedFolder);
        this.openDialogSuccess(DialogType.save);
        this.folderSelecteds = [];
        await this.fetchAllData();
        this.hideFolderActionButtons();
      }
    }
    this.dspBtnAddWidget = !(this.selectedFolder == null);
    this.isDisplayFolderModal = false;
  }

  hideFolderActionButtons() {
    this.dspBtnEditFolder = false;
    this.dspBtnDelFolder = false;
  }

  unbindingWidget() {
    this.widgets = [];
    this.bindingWidgetTable(this.widgets);
    this.dspBtnDelWidget = false;
  }

  // on change data for widget
  onChangeDataWidget(data: any) {
    this.isDisplaywidgetModal = false;
    // update widget
    if (!Utils.isNullOrEmpty(this.widgetEditData) && !Utils.isNullOrEmpty(data)) {
      this.widgets?.map((d: any) => {
        if (d.id == data.id) {
          d.name = data.name,
            d.description = data.description,
            d.folderId = data.folderId,
            d.folderName = data.folderName
        }
      });
      this.openDialogSuccess(DialogType.update);
    }
    // insert widget
    if (!Utils.isNullOrEmpty(data) && Utils.isNullOrEmpty(this.widgetEditData)) {
      this.widgets.push(data);
      this.openDialogSuccess(DialogType.save);
    }
    this.widgetEditData = new WidgetList();
  }

  // checked row for folder table
  checkRowsFolderTable(data: any) {
    if (data.data?.length >= 1) {
      this.dspBtnDelFolder = true;
      if (data.data?.length === 1) {
        this.dspBtnEditFolder = true;
      }
      else {
        this.dspBtnEditFolder = false;
      }

      this.folders.forEach((d: any) => {
        const checkedRow = data.data?.find((item: any) => item.id === d.id);
        d.checked = Boolean(checkedRow);
      });
    }
    else {
      this.dspBtnDelFolder = false;
      this.dspBtnEditFolder = false;

      this.folders.forEach((d: any) => d.checked = false);
    }
    this.folderSelecteds = data?.data;

    this.folderActionButtonDisabled = this.folderSelecteds.some(f => f.assign?.act === UserAction.VIEW);

    const folderAct = get(this.folderSelecteds, "[0].assign.act");
    this.assignPermission = folderAct === UserAction.MANAGER || folderAct === UserAction.OWNER;
  }

  checkRowswidgetTable(data: any) {
    if (data.data?.length >= 1) {
      this.dspBtnDelWidget = true;

      this.widgets.forEach((d: any) => {
        const currentRow = this.widgetTableData.body.find(b => b.id === d.id);
        if (currentRow) {
          d.checked = currentRow.checked;
        }
      });
      this.widgetSelecteds = data?.data;
    }
    else {
      this.dspBtnDelWidget = false;
      const outsideSelecteds = this.widgets.filter(d => this.widgetSelecteds.every(data => data.id !== d.id));
      this.widgets.forEach((d: any) => { 
        if (outsideSelecteds.every(o => o.id !== d.id)) {
          d.checked = false;
        }
      });
      this.widgetSelecteds = outsideSelecteds;
    }
  }

  // binding data for table widget
  bindingFolderTable(data: any[] = []) {
    let folderTable = cloneDeep(this.folderData);
    folderTable.body = data;
    this.folderData = cloneDeep(folderTable);
  }

  // binding data for table folder
  bindingWidgetTable(data: any[] = []) {
    let widgetTable = cloneDeep(this.widgetTableData);
    widgetTable.body = data;
    this.widgetTableData = cloneDeep(widgetTable);
  }

  // event click row in table folder
  async onFilterWidget(data: Folder) {
    this.errorHandleService.setFunctionTitle(FUNCTION_TITLE_TEXT.SWITCHING_FAIL);
    this.selectedFolder = data;
    if (this.selectedFolder) {
      this.dspBtnAddWidget = true;
      this.dspBtnDelWidget = false;
      this.widgetActionButtonDisabled = this.selectedFolder.assign?.act === UserAction.VIEW;
    }
    else {
      this.dspBtnAddWidget = false;
    }

    this.bindingWidgetTable(this.widgets?.filter((d) => d.folderId === data.id));

    this.isLoadingWidgetTable = true;
    let widgetRes = null;
    try {
      widgetRes = this.isWidgetTemplate ? await this.widgetService.getWidgetTemplatesByFolder(data.folderCd || '') : await this.widgetService.getByFolder(data.folderCd || '');
    } catch (e) {
    }
    this.isLoadingWidgetTable = false;
    if (widgetRes && widgetRes.data) {
      this.widgets = widgetRes.data;

      // custom logic for display data in table
      this.widgets.forEach((w: any) => {
        w.folderName = data.folderName;
        if (this.isWidgetTemplate) {
          w["name-subText"] = w.publicSDate + " ~ " + (w.publicEDate ? w.publicEDate : '未定');
        }
      });
    }

    this.bindingWidgetTable(this.widgets?.filter(d => d.folderCd === data.folderCd));
  }

  // move to widget view screen
  movetoWidgetViewScreen(event: WidgetList) {
    //Log
    this.saucerLogService.action({
      content: 'ウィジェット選択' + " ( widgetCd: " + event.widgetCd + ", widgetName: " + event.name + " )"
      }, { 
        action: this.actionLog.VIEW_WIDGET_SELECTION
      });

    this.isMoveToWidgetSettingScreen = true;
    var widget: widget = { mode: ScreenMode.PREVIEW, widgetCd: event.widgetCd, folderCd: event.folderCd as string }
    if (this.widgetActionButtonDisabled) {
      this.widgetService.pass(widget);
      if (this.isWidgetTemplate) {
        this.router.navigate([LocalStorageHelper.getUrl(ROUTE_PATH.WIDGET_TEMPLATE_DETAIL)]);
      } else {
        this.router.navigate([LocalStorageHelper.getUrl(ROUTE_PATH.WIDGET_DETAIL)]);
      }
      return;
    }

    widget.mode = ScreenMode.EDIT
    this.widgetService.pass(widget);
    if (this.isWidgetTemplate) {
      this.router.navigate([LocalStorageHelper.getUrl(ROUTE_PATH.WIDGET_TEMPLATE_DETAIL)]);
    } else {
      this.router.navigate([LocalStorageHelper.getUrl(ROUTE_PATH.WIDGET_DETAIL)]);
    }
  }

  // fetch data
  async fetchAllData() {
    this.processLoadingService.isLoading.emit(true);
    let res = null;
    try {
      res = this.isWidgetTemplate ? await this.folderService.getFolderTemplateByType(FOLDER_TYPE.Widget.toString()) : await this.folderService.getByType(FOLDER_TYPE.Widget.toString(), this.isSupporterAdmin);
    } catch (e) {
    }

    if (res && res.statuscode === 200) {
      this.folders = res.data || [];
      this.folderData.body = this.folders;
      this.folderData = cloneDeep(this.folderData);
    }

    this.dspBtnAddWidget = !(this.folders.length == 0);
    this.processLoadingService.isLoading.emit(false);
  }

  // config data table widget
  configTablewidget() {
    this.widgetTableData = {
      config: {
        id: '1',
        caption: '',
        emptyMessage: COMMON_TEXT.NO_DATA,
        showIndex: false,
        showCheckbox: false,
        showPagination: true,
        hoverShowCheckbox: true,
        isResponsive: true,
        isSelectedRow: true,
        tableStyle: { 'margin-top': '4px', 'font-weight': 'unset !important' },
        inlineTableStyle: { 'table-layout': 'fixed', 'width': '100%' }
      },
      header: [
        {
          field: 'name',
          title: WIDGET.NAME,
          sortable: false,
          filterable: false,
          attribute: {
            header: { width: '30%' },
            row: {},
            rowClass: 'table-row-text-overflow'
          },
          dataType: this.isWidgetTemplate ? DataType.WIDGET : DataType.TEXT,
          visible: true,
          isHasSubText: this.isWidgetTemplate ? true : false,
        },
        {
          field: 'description',
          title: WIDGET.DESCRIPTION,
          sortable: false,
          filterable: false,
          attribute: {
            header: { width: '30%' },
            row: {},
            rowClass: 'table-row-text-overflow'
          },
          dataType: DataType.TEXT,
          visible: true
        },
        {
          field: 'folderName',
          title: WIDGET.FOLDERNAME,
          sortable: false,
          filterable: false,
          attribute: {
            header: { width: '20%' },
            row: {},
            rowClass: 'table-row-text-overflow'
          },
          dataType: DataType.TEXT,
          visible: true
        },
        {
          field: 'displayNm',
          title: WIDGET.DISPLAYNM,
          sortable: false,
          filterable: false,
          attribute: {
            header: { width: '10%' },
            row: {},
            rowClass: 'table-row-text-overflow table-row-text-center'
          },
          dataType: DataType.TEXT,
          visible: true
        },
        {
          field: 'displayDate',
          title: WIDGET.DISPLAYDATE,
          sortable: false,
          filterable: false,
          attribute: {
            header: { width: '10%' },
            row: {},
            rowClass: 'table-row-text-overflow table-row-text-center'
          },
          dataType: DataType.TEXT,
          visible: true
        }
      ],
      body: []
    };
  }

  // config data table folder
  configTableFolder() {
    // table folder params
    this.folderData = {
      config: {
        id: '1',
        caption: '',
        emptyMessage: COMMON_TEXT.NO_DATA,
        showIndex: false,
        showCheckbox: false,
        showPagination: false,
        hoverShowCheckbox: true,
        isSelectedRow: true,
        isResponsive: true,
        tableStyle: { 'border-bottom': 'solid 1px #969696' }
      },
      header: [
        {
          field: 'name',
          title: FOLDER.WIDGETNAME,
          sortable: false,
          filterable: false,
          attribute: {
            header: { width: '100%' },
            row: {}
          },
          dataType: DataType.TEXT,
          visible: true,
          isHasSubText: true
        },
        {
          field: 'id',
          title: 'id',
          sortable: false,
          filterable: false,
          attribute: {
            header: { width: '100%' },
            row: {}
          },
          dataType: DataType.TEXT,
          visible: false
        },
        {
          field: 'folderType',
          title: 'folderType',
          sortable: false,
          filterable: false,
          attribute: {
            header: { width: '100%' },
            row: {}
          },
          dataType: DataType.TEXT,
          visible: false
        },
        {
          field: 'assign',
          title: 'assign',
          sortable: false,
          filterable: false,
          attribute: {
            header: { width: '100%' },
            row: {}
          },
          dataType: DataType.TEXT,
          visible: false
        },
        {
          field: 'delflg',
          title: 'delflg',
          sortable: false,
          filterable: false,
          attribute: {
            header: { width: '100%' },
            row: {}
          },
          dataType: DataType.TEXT,
          visible: false
        }
      ],
      body: []
    };
  }

  createWidgetBtnClick() {
    if (this.widgetActionButtonDisabled) {
      return;
    }
    this.isMoveToWidgetSettingScreen = true;
    var widget: widget = { mode: ScreenMode.ADD, folderCd: this.selectedFolder?.folderCd as string }
    this.widgetService.pass(widget);
    if (this.isWidgetTemplate) {
      this.router.navigate([LocalStorageHelper.getUrl(ROUTE_PATH.WIDGET_TEMPLATE_CREATE)]);
    } else {
      this.router.navigate([LocalStorageHelper.getUrl(ROUTE_PATH.WIDGET_CREATE)]);
    }

    //Log
    this.saucerLogService.action({
      content: SCREEN_NAME.WIDGET_SETTING.WIDGET_CREATION
		  }, { 
			action: this.actionLog.VIEW_WIDGET_CREATION
    });

  }

  async getAllDashboardNameThatUseSelectedWidget(arr: any[]) {
    this.loading.isLoading.emit(true);
    let queryStr = this.generatateQueryStr(arr);
    if(queryStr != "")
    {
      let result = await this.widgetService.getDashboardList(queryStr);
        if(result.data != null && result.data.length > 0)
        {
          this.dashboardName = result.data[0].listDashboardName;
        }
      this.loading.isLoading.emit(false);
    }
  }

  generatateQueryStr(dataArray: any[]) {
    let queryStr = "";
    dataArray.forEach((ele: any) => {
      queryStr += ele.widgetCd;
      queryStr += "_";
    })
   return queryStr.slice(0, -1);
  } 
  
}
