import {Component, OnInit} from "@angular/core";
import { CORP_SELECTION, LOGIN} from '../../../../app/const/text-common';
import { ROUTE_PATH } from '../../../const/route-path';
import { ButtonType, DeviceType, OperatingSystemName } from '../../../enum/common-enum';
import { AuthenticationService } from '../../../services/authentication.service';
import { LocalStorageHelper } from '../../../_helper/local-storage.helper';
import { Router } from '@angular/router';
import { ProcessLoadingService } from '../../../services/loading.service';
import { LoginInfoModel } from "../../../models/login-info.model";
import { HelpListService } from "../../../services/modules/help-list.service";
import { Observable } from "rxjs";
import { ErrorHandleService } from "../../../services/error-handle.service";
import { API_APP } from "src/app/config/app.config";
import { SAUCER_LOG_ACTION } from "src/app/config/saucer-log.config";
import { SaucerLogService } from "src/app/services/saucer-logs/saucer-log.service";

@Component({
  selector: 'pivot-corp-selection',
  templateUrl: './corp-selection.component.html',
  styleUrls: [
    '../login.component.scss',
    './corp-selection.component.scss']
})
export class CorpSelectionComponent implements OnInit {
  CORP_SELECTION = CORP_SELECTION;
  LOGIN = LOGIN;

  buttonType = ButtonType;
  DeviceType = DeviceType;
  deviceType!: string;
  currentOS: string | null = '';
  OS = OperatingSystemName;

  authcorplist: any[] = [];
  hasErr = false;
  templateCorp: any;

  constructor(
    private authenticationService: AuthenticationService,
    private router: Router,
    private processLoadingService: ProcessLoadingService,
    private helpListService:  HelpListService,
    private errorHandleService: ErrorHandleService,
    private saucerLogService : SaucerLogService
    ) {

    this.processLoadingService.isLoading.emit(false);
    if (this.authenticationService.companyIdValue != null && this.authenticationService.companyIdValue != -1) {
      this.helpListService.setCheckPopUp(true);
      this.helpListService.setCheckOffice(true);
      this.router.navigate([LocalStorageHelper.getUrl(ROUTE_PATH.HOME)]);
    }
    this.authenticationService.authCorpList.subscribe((authCorpLst: any) => {
      if (!authCorpLst) {
        this.helpListService.setCheckPopUp(true);
        this.helpListService.setCheckOffice(true);
        this.router.navigate([LocalStorageHelper.getUrl(ROUTE_PATH.HOME)]);
      } else {
        this.authcorplist = [...authCorpLst];
      }
    });
    this.authenticationService.corpTemplateSetting.subscribe((corp: any) => {
      if (corp) {
        this.templateCorp = corp;
        this.authcorplist = this.authcorplist.filter((item: any) => item.organizationid != this.templateCorp?.organizationid)
      }
    })
  }

  ngOnInit(): void {
  }

  async onChoose(corp: any, istemplateSetting: boolean): Promise<void> {
    this.processLoadingService.isLoading.emit(true);
    const user = this.authenticationService.currentUserValue;
    const queryAccessToken = user?.token;
    const queryRefreshToken = user?.refreshtoken;
    const customParams = {module: "PIVOT", companyid: corp.companyid, istemplateSetting: istemplateSetting}
    
    await this.authenticationService.loginViaToken(queryAccessToken, queryRefreshToken, customParams)
      .then(
        async (data: any) => {
          let loginInfo = new LoginInfoModel();
          loginInfo.organizationid = corp.organizationid;
          loginInfo.organizationname = corp.organizationname;
          loginInfo.companyid = corp.companyid;
          loginInfo.custid = data.data.companylist ? data.data.companylist[0].custid : "";
          LocalStorageHelper.setLoginInfo(loginInfo)
          if (data) {
            if (data == "Unauthorized") {
               this.authenticationService.logout();
               return;
            }
            if (istemplateSetting) {
              data.data.loginstage = "console";
            } else {
              data.data.loginstage = "auth";
            }
            const processRes = await this.authenticationService.processAfterCallLoginApi(data);
            switch (processRes.statuscode) {
              case 200:
                const account = data.data;
                this.saucerLogService.system(
                  {
                    apiPath: API_APP.AUTH.LOGIN_BY_TOKEN,
                    statusCode: processRes.statuscode,
                    content: JSON.stringify({
                      corp
                    })
                  },
                  {
                    action: SAUCER_LOG_ACTION.LOGIN.SUCCESS
                  }
                );
                this.authenticationService.setCurrentUser(account);
                if (istemplateSetting) {
                  this.router.navigate([LocalStorageHelper.getUrl(ROUTE_PATH.DATASOURCE_TEMPLATE)]);
                } else {
                  this.helpListService.setCheckPopUp(true);
                  this.helpListService.setCheckOffice(true);
                  this.router.navigate([LocalStorageHelper.getUrl(ROUTE_PATH.HOME)]);
                }
                
                break;
                    
              case 401:
              default:
                this.hasErr = true;
                this.saucerLogService.error(
                  {
                    apiPath: API_APP.AUTH.LOGIN_BY_TOKEN,
                    statusCode: processRes.statuscode,
                    errorMessage: processRes?.message,
                    content: JSON.stringify({
                      corp
                    })
                  },
                  {
                    action: SAUCER_LOG_ACTION.LOGIN.FAILED
                  }
                );
                break;
            }
          } else {
            this.hasErr = true;
          }
          this.processLoadingService.isLoading.emit(false);
        },
        (error: any) => {
          console.log(error);
          this.hasErr = true;
          this.processLoadingService.isLoading.emit(false);
        });



  }

  canDeactivate(url: string | undefined): boolean | Observable<boolean> | Promise<boolean> {
    this.errorHandleService.setIsCloseErrorDialogOrErrorScreen(false);
    return true;
  }

}
