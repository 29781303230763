import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { cloneDeep } from 'lodash';
import { COMMON_TEXT } from '../../../../app/const/text-common';
import { SearchType } from '../../../../app/enum/common-enum';
import { SearchParams } from '../../../../app/models/common-model';
import Utils from '../../../../app/util/utils';
import { SaucerLogService } from 'src/app/services/saucer-logs/saucer-log.service';

@Component({
  selector: 'pivot-search-bar',
  templateUrl: './search-bar.component.html',
  styleUrls: ['./search-bar.component.scss']
})
export class SearchBarComponent implements OnInit, OnChanges {

  @Input() filterLog: {screenName: string, action: string, body: string, content: string} | null = null;
  @Input() searchParams: SearchParams = new SearchParams();
  @Output() onFilterData = new EventEmitter<any>();
  @Input() placeHolderFilter: string;
  @Input() _displayFilter: any = null;
  @Input() _disable: boolean = false;
  @Input() _required: boolean = false;
  @Input() columnName: string;
  @Input() validateInput: boolean = false;
  @Output() columnNameChange = new EventEmitter<string>();
  _targetFilter: any = null;
  dspSearchPanel: boolean = false;
  searchValue: string = '';
  inputSearchId: string = '';
  popupStyle: any = {};
  dataFilters = [];
  selectedData: any[] = [];
  isFilter: boolean = true;
  outputData: any = null;
  listFilterDspText: string = '';
  comboInputFocus: boolean = false;
  textInputFocus: boolean = false;
  SearchType = SearchType;
  constructor(private saucerLogService : SaucerLogService) {
  }
  ngOnChanges(changes: SimpleChanges): void {
    this.selectedData = [];
    this.searchParams = cloneDeep(this.searchParams);
    if(this.searchParams.selfReset) {
      this.searchValue = '';
    }
    else
      this.searchValue = this.searchParams?.defaultValue == undefined ? '' : this.searchParams?.defaultValue;
    let selectItems: any[] = [];
    let findItemSelect = this.searchParams.dataSource?.find((d: any) => d.value == this.searchParams.selected || d.name == this.searchValue);
    this.searchValue = this.searchValue || findItemSelect?.name
    if (findItemSelect != undefined) {
      selectItems.push(findItemSelect);
      this.selectedData = selectItems;
    }
  }

  ngOnInit(): void {
    this.setDspTextForDlgFilter();
    this.searchValue = this.searchParams?.defaultValue == undefined ? '' : this.searchParams?.defaultValue;
    let findItemSelect = this.searchParams.dataSource?.find((d: any) => d.value == this.searchParams.selected || d.name == this.searchValue);
    this.searchValue = this.searchValue || findItemSelect?.name
    // set placeholder for textbox
    if (this.searchParams?.placeholder === undefined) {
      this.searchParams.placeholder = COMMON_TEXT.SEARCH;
    }
    this.inputSearchId = this.getRandomIdSearch();
    if (!Utils.isNullOrEmpty(this.searchParams?.isFilter)) {
      this.isFilter = (this.searchParams.isFilter || false);
    }
  }

  // random id textbox
  getRandomIdSearch() {
    return Math.floor(Math.random() * 10000).toString();
  }

  onClickIconSearch() {
    if (this.filterLog) {
      this.saucerLogService.action({
        content: (this.filterLog.content ?  this.filterLog.content : "") + this.searchValue
      }, { 
        action: this.filterLog
      });
    }
    this.onFilterData.emit(this.searchValue?.trim());
  }

  setInputFocus() {
    let inputElement = document.getElementById(this.inputSearchId);
    if (inputElement !== undefined) {
      inputElement?.focus();
    }
  }

  // input onkeyup
  onKeyUps(event: any) {
    this.searchValue = event.currentTarget?.value;
    if (event?.keyCode == 13) {
      if (this.filterLog) {
        this.saucerLogService.action({
          content: (this.filterLog.content ? this.filterLog.content : "") + this.searchValue
        }, { 
          action: this.filterLog
        });
      }
      this.onFilterData.emit(this.searchValue?.trim());
    }
  }

  // input on keydown
  onKeyDowns(event: any) {
    this.searchValue = event.currentTarget?.value;
  }

  // event input data
  onInputData(event: any) {
    this.searchValue = event.currentTarget?.value;
  }

  // input focus
  onFocusIn(event: any) {
    this.searchValue = event.currentTarget?.value;
    this.textInputFocus = true;
  }

  // event forcusout input
  onFocusOutInput(event: any) {
    this.searchValue = event.currentTarget?.value;
    this.textInputFocus = false;
  }

  // click input show dialog filter
  onClickDropdown(event: any, target?: any) {
    let inputSearch = document.getElementById(this.inputSearchId);
    if (!Utils.isNullOrEmpty(inputSearch)) {
      this.popupStyle = { 'width': inputSearch?.offsetWidth + 'px' };
    }
    this._displayFilter = event;
    if(target) {
      this._targetFilter = target;
    }
    this.dspSearchPanel = true;
  }
  onKeyDownsInputInsert(event: any){
    if (event.key == "\""){ 
      event.preventDefault();
    }
  }
  // filter output data
  filterOutData(event: any) {
    let data: any = [];
    if (event.length > 1) {
      let item = event[event.length - 1];
      if (item !== undefined) {
        data.push(item);
      }
      if (data !== undefined) {
        this.setInputValue(data);
      }
    }
    else {
      data = event;
    }

    if (data[0] !== undefined) {
      this.setInputValue(data[0]);
    }
    this.selectedData = data;

    this._displayFilter = null;
    if (this.searchParams.type === SearchType.combo || this.searchParams.type === SearchType.insert) {
      this.onFilterData.emit(data);
    }
    else {
      this.onFilterData.emit(null);
    }
  }

  setInputValue(data: any) {
    if (this.searchParams.comboDisplayText != undefined) {
      this.searchValue = data[this.searchParams.comboDisplayText];
    }
    else {
      let column = Object.keys(data);
      this.searchValue = data[column[0]];
    }
  }
  // set display text for dialog filter
  setDspTextForDlgFilter() {
    let params = this.searchParams;
    if (params?.type === SearchType.combo) {
      if (params?.dataSource === undefined) {
        this.searchParams.dataSource = [];
      }
      if (params.comboDisplayText !== undefined) {
        this.listFilterDspText = params.comboDisplayText;
      }
      else {
        let row: any = {};
        let dataSource = this.searchParams?.dataSource;
        if (dataSource != undefined && dataSource.length > 0) {
          row = dataSource[0];
        }
        let column = Object.keys(row);
        if (column[0] != undefined) {
          this.listFilterDspText = column[0];
        }
      }
    }
    else if(params?.type == SearchType.insert){
      if (params.comboDisplayText !== undefined) {
        this.listFilterDspText = params.comboDisplayText;
      }
     
    }
  }

  onHidePanelSearch() {
    this.dspSearchPanel = false;
  }

  handleComboInputFocus(event: any) {
    this.comboInputFocus = true;
  }

  handleComboInputFocusOut(event: any) {
    this.comboInputFocus = false;
    this._displayFilter = null;
  }

}
