import { Injectable } from '@angular/core';
import { SyncDataStatusBackEnd } from '../../../app/enum/common-enum';
import { sharedDataKey } from '../../../app/const/const';
import { NotifyParams } from '../../../app/models/common-model';
import { LocalStorageHelper } from '../../../app/_helper/local-storage.helper';
import { ProcessNotificationService } from '../process-notification.service';
import { SharedDataService } from '../share-data.service';
import { SyncLogDataService } from './sync-log-data.service';
import {ErrorHandleService} from '../error-handle.service';
import { SAUCER_LOG_ACTION, SaucerLogService } from '../saucer-logs/saucer-log.service';

@Injectable()
export class HandleSyncDataDataService {

    private _syncLogDataService: SyncLogDataService;
    private _sharedDataService: SharedDataService;
    private _processNotificationService: ProcessNotificationService;
    public inProgress: boolean = false; 
    protected errorsyncDatasourceCds: string[] = [];
    protected listWidgetOnSync: string[] = []
    private lstSyncDataSourceFail: any[] = [];

    constructor(
        private syncLogDataService: SyncLogDataService,
        private sharedDataService: SharedDataService,
        private processNotificationService: ProcessNotificationService,
        private errorHandleService: ErrorHandleService,
        private saucerLogService : SaucerLogService
    ) {
        this._syncLogDataService = syncLogDataService;
        this._sharedDataService = sharedDataService;
        this._processNotificationService = processNotificationService;

    }

    private async getLogs(packCd: string) {
        let res = await this._syncLogDataService.getByPackCd(packCd);
        if (res.statuscode == 200) {
            let dataRes = res.data;
            this.handleDataLogs(res.data);
   
            let isRepeat = false;
            for await (const item of dataRes) {
                if (item.status == SyncDataStatusBackEnd.WAITING || item.status == SyncDataStatusBackEnd.INPROGRESS) {
                    isRepeat = true;
                }  
            }

            if (!isRepeat) {
                LocalStorageHelper.setPackCd("");
                LocalStorageHelper.setSyncData(dataRes);
                this.inProgress = false;
            } else {
                let _this = this;
                setTimeout(function () { 
                    _this.getLogs(packCd);
                 }, 3000); // <-- timeout 3 seconds
            }
            let tmp = {
                'key': 'eventSyncLogData',
                'value': null
            }
            this._sharedDataService.setDataObservable(tmp);
        } else {
            this.inProgress = false;
        }
    }

    public repeatSyncData() {
        this.lstSyncDataSourceFail = [];
        let packCd = "";
        packCd = LocalStorageHelper.getPackCd();
        if (!packCd) {
            this.inProgress = false;
            this.listWidgetOnSync = [];
        } else {
            this.inProgress = true;
            LocalStorageHelper.setSyncData(null);
            this.getLogs(packCd);
        }

    }

    private handleDataLogs(data: any[]) {
        let preData: any[] = this._sharedDataService.getItem(sharedDataKey.SYNCLOGS);
        let lstSyncDataSource: any[] = [];
        let isWriteSaucerLog = true;
        this._sharedDataService.setItem(sharedDataKey.PRESYNCLOGS, preData);
        this._sharedDataService.setItem(sharedDataKey.SYNCLOGS, data);

        if (data && preData) {
            data.forEach(element => {
                lstSyncDataSource.push({
                    dsstructcd: element.dsstructcd,
                    dsname: element.dsname,
                    dstype: element.dstype,
                    lastsync: element.lastsync,
                    packcd: element.packcd
                });
                if (element.status == SyncDataStatusBackEnd.WAITING || element.status == SyncDataStatusBackEnd.INPROGRESS){
                    isWriteSaucerLog = false;
                }
                if (element.status == SyncDataStatusBackEnd.FAIL || element.status == SyncDataStatusBackEnd.SUCCEED) {
                    let tmp = preData.find(x => x.id == element.id);
                    if (tmp) {
                        if (tmp.status != element.status) {
                            let notifyParams = new NotifyParams();
                            notifyParams.isDisplay = true;
                            notifyParams.status = (element.status == SyncDataStatusBackEnd.FAIL ? false : true);
                            notifyParams.value = element.dsname;
                            this._processNotificationService.dataNotify.emit(notifyParams);
                        }
                    }
                }
                if(element.status == SyncDataStatusBackEnd.FAIL) {
                    if(!this.errorsyncDatasourceCds.includes(element.dsstructcd)) {
                        this.errorsyncDatasourceCds.push(element.dsstructcd);
                        this.lstSyncDataSourceFail.push({
                            dsstructcd: element.dsstructcd,
                            dsname: element.dsname
                        });
                    }
                }
            });

            if (isWriteSaucerLog) {
                this.saucerLogService.system(
                    {
                        content: JSON.stringify({
                            lstSyncDataSource,
                            lstSyncDataSourceFail: this.lstSyncDataSourceFail
                        })
                    }, 
                    { 
                        action: SAUCER_LOG_ACTION.HOME.SYNC_LATEST_DATA
                    }
                );
            }
        }
    }
    
    public clearErrorDatasourceCds() {
        this.errorsyncDatasourceCds = [];
    }

    public setListWidgetOnSync(widgetList: string[]) {
        if(widgetList)
            this.listWidgetOnSync = widgetList;
    }

    public getListWidgetOnSync() {
        return this.listWidgetOnSync;
    }
}