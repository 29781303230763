import { HttpClient, HttpParams } from '@angular/common/http';
import { Router } from '@angular/router';
import { EventEmitter, Injectable } from '@angular/core';
import * as moment from 'moment';
import { isPubishWidget } from 'src/app/_helper/helper';
import { DATE_MIN_STRING } from '../../../app/const/const';
import { DateFormat, ScreenMode } from '../../../app/enum/common-enum';
import { ResponseData } from '../../../app/models/api-model';
import { WidgetRequest } from '../../../app/models/request/widget.dto';
import { PivotTableConfig, widget, Widget, WidgetList } from '../../../app/models/response/widget.ro';
import { API_APP, PIVOT_CLIENT_API_URL } from '../../config/app.config';
import { ApiService } from '../api.service';
import { AuthenticationService } from '../../services/authentication.service';
import { LocalStorageKey } from './../../_helper/local-storage.helper';
import {ErrorHandleService} from '../error-handle.service';
import { SaucerLogService } from '../saucer-logs/saucer-log.service';
import { SYNCDATA_STATUS } from 'src/app/const/text-common';
import { catchError, Observable, of } from 'rxjs';



@Injectable({ providedIn: 'root' })
export class WidgetService extends ApiService {
  private widgetListItemMode: ScreenMode;
  private widget: widget | null;
  private creatingByTemplate: any = null;
  private widgetDetail: string = "";
  private widgetFilters: any[] = [];
  private searchKeys: string[] = [];
  public footertype$ = new EventEmitter<string>()
  public summaryColumntype$ = new EventEmitter<string>()

  constructor(
    http: HttpClient,
    router: Router, 
    errorHandle: ErrorHandleService,
    private authenticationService: AuthenticationService,
    private saucerLogService: SaucerLogService
  ) {
    super(http, router, errorHandle);
  }

  public pick() {
    return this.widget;
  }

  public pass(widget: widget) {
    this.widget = widget
  }

  public getCreatingByTemplate() {
    return this.creatingByTemplate;
  }

  public setCreatingByTemplate(value: any) {
    this.creatingByTemplate = { widgetcdTemplate: value.widgetcdTemplate, dsStructCd: value.dsStructCd, folderDSCd: value.folderCd, useExistDs: value.useExistDs, lstOffices: value.lstOffices  ?? "", 
      name: value.name, type: value.type, desc: value.desc, graphconfig: value.graphconfig
    };
  }

  getWidgetListItemMode(): ScreenMode {
    return this.widgetListItemMode;
  }

  async getAll(): Promise<ResponseData> {
    const apiUrl = API_APP.WIDGET.CONTROLLER + API_APP.WIDGET.ALL;
    const resData = await this.get(apiUrl);

    return this.mapModel(resData);
  }

  async updateDataWideget(widgetSelectd: any) {
    const parameter = [
      widgetSelectd
    ];
    const apiUrl = API_APP.WIDGET.CONTROLLER + API_APP.WIDGET.UPDATE_DATA_WIDEGET;
    return this.get(apiUrl, parameter);
  }

  getDetail(code: string) {
    const parameter = [
      code
    ];
    const apiUrl = API_APP.WIDGET.CONTROLLER + API_APP.WIDGET.DETAIL;
    return this.get(apiUrl, parameter);
  }

  async getByFolder(code: string) {
    const parameter = [
      code
    ];
    const apiUrl = API_APP.WIDGET.CONTROLLER + API_APP.WIDGET.BY_FOLDER;
    const resData = await this.get(apiUrl, parameter);
    return this.mapModel(resData);
  }

  async delete(items: Widget[], sessionWidgetAction: any, oldLog: any) {
    const apiUrl = API_APP.WIDGET.CONTROLLER + API_APP.WIDGET.DELETE;
    const apiPath = apiUrl.replace(PIVOT_CLIENT_API_URL, "");
    const resData = await this.post(apiUrl, undefined, items);

    //Log
    if (resData?.statuscode === 200) {
      this.saucerLogService.system({
        apiPath,
        body: JSON.stringify(items),
        statusCode: resData.statuscode,
        content: JSON.stringify({
          old: oldLog,
          new: null
        })
      }, { action: sessionWidgetAction?.DELETE_WIDGET });
    } else {
      this.saucerLogService.error({
        apiPath,
        statusCode: resData.statuscode,
        body: JSON.stringify(items),
        content: API_APP.WIDGET.DELETE + " " + SYNCDATA_STATUS.ERROR
      }, { action: sessionWidgetAction?.DELETE_WIDGET });
    }

    return this.mapModel(resData);
  }

  getWidgetList(datasourceCd: string) {
    const parameter = [
      datasourceCd
    ];
    const apiUrl = API_APP.WIDGET.CONTROLLER + API_APP.WIDGET.GET_WIDGET_BY_DATASOURCECD;
    return this.get(apiUrl, parameter);
  }

  getDashboardList(datasourceCd: string) {
    const parameter = [
      datasourceCd
    ];
    const apiUrl = API_APP.WIDGET.CONTROLLER + API_APP.WIDGET. GET_DASHBOARD_BY_WIDGETCD;
    return this.get(apiUrl, parameter);
  }


  mapModel(data: any) {
    const staffs = this.authenticationService.getData(LocalStorageKey.ALL_STAFF) || [];

    const res: ResponseData<WidgetList[]> = new ResponseData<WidgetList[]>();
    const dataArr = [];
    res.statuscode = data.statuscode;
    res.statusmessage = data.statusmessage;
    if (data.statuscode == 200) {
      for (const element of data.data || []) {
        const widgetList = new WidgetList();
        widgetList.id = element.id;
        widgetList.widgetCd = element.widgetcd;
        widgetList.name = element.widgetname;
        widgetList.chartType = element.charttype;
        widgetList.description = element.widgetdesc;
        widgetList.folderCd = element.foldercd;
        widgetList.delflg = element.delflg;
        widgetList.insdate = element.insdate;
        widgetList.upddate = element.upddate;
        widgetList.insstfcd = element.insstfcd;
        widgetList.sortcoltype = element.sortcoltype;
        widgetList.updstfcd = element.updstfcd;
        widgetList.insstfnm = staffs?.find((stf: any) => stf.staffCd == element.insstfcd)?.fullName || element.insstfnm;
        widgetList.updstfnm = staffs?.find((stf: any) => stf.staffCd == element.updstfcd)?.fullName || element.updstfnm;
        widgetList.publicSDate = element.publicsdate;
        widgetList.publicEDate = element.publicedate;
        widgetList.isPublish = isPubishWidget(element.publicsdate, element.publicedate);

        if (element.updstfcd) {
          widgetList.displayNm = widgetList.updstfnm;
          widgetList.displayDate = element.upddate && element.upddate !== DATE_MIN_STRING ? (moment(element.upddate)).format(DateFormat.FULL_SHORT_DATE) : '';
        } else {
          widgetList.displayNm = widgetList.insstfnm;
          widgetList.displayDate = element.insdate && element.insdate !== DATE_MIN_STRING ? (moment(element.insdate)).format(DateFormat.FULL_SHORT_DATE) : '';
        }

        dataArr.push(widgetList);
      }
    }
    res.data = dataArr;
    return res;
  }

  getDataSourceList(request: any): Promise<ResponseData> {
    let apiUrl = API_APP.WIDGET.CONTROLLER + API_APP.WIDGET.DS;
    return this.post(apiUrl, [], request);
  }
  async concurrentPromisePool<T>(tasks: (() => Promise<T>)[], limit: number): Promise<T[]> {
    const results: T[] = [];
    for (let i = 0; i < tasks.length; i += limit) {
      const group = tasks.slice(i, i + limit);
      const executing: Promise<T>[] = [];
  
      for (const task of group) {
        const p = Promise.resolve().then(task);
        executing.push(p);
      }
  
      const groupResults = await Promise.all(executing);
      results.push(...groupResults);
    }
  
    return results;
  }


  async getTotalCount(isTemplate: boolean, datasourceCd: string): Promise<ResponseData> {
    let parameter = [datasourceCd, isTemplate];
    let apiUrl = API_APP.WIDGET.CONTROLLER + API_APP.WIDGET.GETTOTALCOUNT;
    return this.get(apiUrl, parameter);
  }

  getWidget(widgetCd: string): Promise<ResponseData> {
    let parameter = [widgetCd];
    let apiUrl = API_APP.WIDGET.CONTROLLER + API_APP.WIDGET.GET;
    return this.get(apiUrl, parameter);
  }

  async getWidgetByWidgetcd(widgetCd: string): Promise<ResponseData> {
    let parameter = [widgetCd];
    let apiUrl = API_APP.WIDGET.CONTROLLER + API_APP.WIDGET.GET_BY_WIDGETCD;
    return this.get(apiUrl, parameter);
  }

  getContentLog(data: any) {
    let details: any[] = data?.widgetdetails || undefined;
    const contentLogWidget = {
      widgetcd: data?.widget?.widgetcd ,
      widgetname: data?.widget?.widgetname,
      details: details?.map((x:any) => ({
        columnname: x.columnname,
        displayname: x.displayname
      }))
    };
    return contentLogWidget;
  }

  postWidget(widget: WidgetRequest) {
    let url = API_APP.WIDGET.CONTROLLER + API_APP.WIDGET.POST
    return this.post(url, [], widget)
  }

  getColumnList(datasourceCd: string): Promise<ResponseData> {
    let parameter = [datasourceCd];
    let apiUrl = API_APP.WIDGET.CONTROLLER + API_APP.WIDGET.DATASOURCE;
    return this.get(apiUrl, parameter);
  }

  mapModelDetail(data: any) {
    const res: ResponseData<Widget[]> = new ResponseData<Widget[]>();
    const dataArr = [];
    res.statuscode = data.statuscode;
    res.statusmessage = data.statusmessage;
    if (data.statuscode == 200) {
      for (const element of data.data || []) {
        const widget = new Widget();
        widget.id = element.id;
        widget.widgetCd = element.widgetcd;
        widget.name = element.widgetname;
        widget.chartType = element.charttype;
        widget.description = element.widgetdesc;
        widget.folderCd = element.foldercd;
        widget.delflg = element.delflg;
        widget.dataTableConfig = [];
        widget.pivotTableConfig = new PivotTableConfig();
        widget.insdate = element.insdate || new Date(element.insdate);
        widget.upddate = element.insdate || new Date(element.upddate);
        widget.insstfcd = element.insstfcd;
        widget.updstfcd = element.updstfcd;
        widget.insstfnm = element.insstfnm;
        widget.updstfnm = element.updstfnm;
        widget.sortcoltype = element.sortcoltype;
        dataArr.push(widget);
      }
    }
    res.data = dataArr;
    return res;
  }

  async getByDashboard(code: string): Promise<ResponseData<Widget[]>> {
    const parameter = [
      code
    ];
    const apiUrl = API_APP.WIDGET.CONTROLLER + API_APP.WIDGET.GET_BY_DASHBOARD;
    const resData = await this.get(apiUrl, parameter);

    return this.mapModelDetail(resData);
  }


  async getWidgetTemplatesByDashboardCd( dashboardCd: string) {
    const apiUrl = API_APP.WIDGET.CONTROLLER + "/" + dashboardCd + API_APP.WIDGET.TEMPLATE;
    const resData = await this.get(apiUrl);

    return this.mapModel(resData);
  }

  getDataSourceTemplateTable(CDs: string[]): Promise<any> {
    let apiUrl = API_APP.WIDGET.CONTROLLER + API_APP.WIDGET.DSTABLETEMPLATE;
    return this.post(apiUrl, [], CDs);
  }

  getWidgetTemplate(widgetCd: string): Promise<ResponseData> {
    let apiUrl = API_APP.WIDGET.CONTROLLER + API_APP.WIDGET.GET  + "/" + widgetCd + API_APP.WIDGET.TEMPLATE;
    return this.get(apiUrl);
  }

  getDataSourceListTemplate(request: any): Promise<ResponseData> {
    let apiUrl = API_APP.WIDGET.CONTROLLER + API_APP.WIDGET.DSTEMPLATE;
    return this.post(apiUrl, [], request);
  }

  async getWidgetTemplatesByFolder(code: string) {
    const apiUrl = API_APP.WIDGET.CONTROLLER + API_APP.WIDGET.BY_FOLDER + "/" + code + API_APP.WIDGET.TEMPLATE;
    const resData = await this.get(apiUrl);
    return this.mapModel(resData);
  }

  async getAllTemplates(): Promise<ResponseData> {
    const apiUrl = API_APP.WIDGET.CONTROLLER + API_APP.WIDGET.ALL+ API_APP.WIDGET.TEMPLATE;
    const resData = await this.get(apiUrl);

    return this.mapModel(resData);
  }

  async countListDSandColumn(isAdnminOrSupport?: boolean): Promise<ResponseData> {
    let apiUrl = API_APP.WIDGET.CONTROLLER + API_APP.WIDGET.COUNT;
    if(isAdnminOrSupport) {
      apiUrl += "/true"
    }
    else 
      apiUrl += "/false"
    return this.get(apiUrl);
  }

  getWidgetConfig(widgetCd: string, isTemplate: boolean = false): Promise<ResponseData> {
    let apiUrl = API_APP.WIDGET.CONTROLLER + "/" + widgetCd + API_APP.WIDGET.GETCONFIG + "/" + isTemplate;
    return this.get(apiUrl);
  }

  /**
   * Get widget config with parallel
   * @param list widgetCd
   * @param isTemplate - option
   * @returns list Widget config
   */
  async getWidgetConfigs(widgetCdList: string[], isTemplate: boolean = false): Promise<any[]> {
    const widgetConfigPromises = widgetCdList.map(widgetCd =>
      this.getWidgetConfig(widgetCd, isTemplate)
    );
  
    const results = await Promise.all(widgetConfigPromises);
    const finalResult = results
      .filter(res => res.statuscode === 200 && res.data)
      .map(res => res.data);
  
    return finalResult;
  }

  async countListDSandColumnTemplate(): Promise<ResponseData> {
    let apiUrl = API_APP.WIDGET.CONTROLLER + API_APP.WIDGET.COUNTTEMPLATE;
    return this.get(apiUrl);
  }

  async updateSortColType(bodyData: any, isUpdate: boolean) {
    let parameter = [
      isUpdate
    ];
    let apiUrl = API_APP.WIDGET.CONTROLLER + API_APP.WIDGET.UPDATESORTCOL;
    let resData = await this.post(apiUrl, parameter, bodyData);
    return resData;
  }

  getDasourceCdByWidgetCD(widgetCd: string): Promise<ResponseData> {
    let apiUrl = API_APP.WIDGET.CONTROLLER + API_APP.WIDGET.GET + "/" + widgetCd + API_APP.WIDGET.DATASOURCE;
    return this.get(apiUrl);
  }

  async getPeriodInfor(widgets: any[], isTemplate:boolean) {
    let uri = API_APP.WIDGET.CONTROLLER + API_APP.WIDGET.GETPERIOD  + "/" + isTemplate;
    let resData = await this.post(uri, [], widgets);
    return resData;
  }

  async findAllWidgetBySearchText(items: string[]) {
    const apiUrl = API_APP.WIDGET.CONTROLLER + API_APP.WIDGET.FIND_WIDGETS;
    const resData = await this.post(apiUrl, undefined, items);

    return this.mapModel(resData);
  }

  async getListWidgetBySearchText(items: string[], isDashboard = false) {
    let parameter = [
      isDashboard
    ];
    const apiUrl = API_APP.WIDGET.CONTROLLER + API_APP.WIDGET.FIND_TEMPLATE_WIDGETS_BY_WIDGETS;
    const resData = await this.post(apiUrl, parameter, items);

    return resData;
  }

  async countListWidgetBySearchText(items: string[]) {
    const apiUrl = API_APP.WIDGET.CONTROLLER + API_APP.WIDGET.COUNT_LIST_WIDGET_TEMPLATE;
    const resData = await this.post(apiUrl, undefined, items);

    return resData;
  }

  public getWidgetDetail() {
    return this.widgetDetail;
  }

  public setListWidgetTemplateFilter(widgets: any[], searchKeys: string[]) {
    this.widgetFilters = widgets;
    this.searchKeys = searchKeys;
  }

  public getListWidgetTemplateFilter() {
    return  { widgetFilters: this.widgetFilters, searchKeys: this.searchKeys };
  }

  public setWidgetDetail(widget: any) {
    this.widgetDetail = widget
  }

  async fetchWidgetTemplates() {
    const apiUrl = API_APP.WIDGET.CONTROLLER + API_APP.WIDGET.GET_LIST_WIDGET_TEMPLATE;
    const resData = await this.get(apiUrl);
    return resData;
  }
}
