export class ZaitakuSettingDTO {
    isupdate : boolean;
    id: string;
    conditionname: string;
    jigno: string;
    siteino: string;
    officecd: string;
    sdate: string;
    edate: string;
    countingtype: number;
    columncd: string;
    conditions: string;
}