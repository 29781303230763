import { BaseModel } from "../api-model";

export class SyncLogDataModel extends BaseModel {
  packcd: string;
  dsstructcd: string;
  querystr: string;
  message: string;
  status: number
}




