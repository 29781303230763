<pivot-modal-template class="user-defined-modal form-modal" [modalTemplate]="modalUserdefined"
(closeModalHandler)="closeFormModal()" >
<div class="user-defined-modal-body" modal-body>
  <div class="controls">
    <div class="row">
      <label>{{COMMON_TEXT.COLUMN_NAME}}
        <span class="input-required">{{COMMON_TEXT.ASTERISK}}</span>
      </label>
    </div>
    <div class="row">
      <pivot-search-bar [searchParams]="searchParams" [validateInput]="validateInput" (columnNameChange) = "onChangeColumnName($event)" [(columnName)] = "columnName" (onFilterData)="onSelectColumn($event)" ></pivot-search-bar>
      <div class="error-mesage" >
        <label *ngIf="validateInput">{{messageError}}</label>
      </div>
    </div>
    <div class="row">
      <pivot-input-formula  #inputFormula [isDefinedColumn] = "true" [isDataSource]="true" [displayCols]="displayCols" [colUsed] = "colUsed" [selected]="columnSelected"></pivot-input-formula>
    </div>
    <div class="row">
     <label>{{COMMON_TEXT.DESCRIPTION_DEFINED_COLUMN}}</label>
    </div>
  </div>  
</div>
<div class="user-defined-modal-bottom" modal-footer>
  <div class="left-pannel">
    <pivot-text-button [buttonType]="buttonType.DANGER" (btnClick)="onDelete()" [disabled]="!columnSelected" [buttonText]="BUTTON.DELETE"></pivot-text-button>
  </div>
  <div class="right-pannel">
    <pivot-text-button (btnClick)="oncCancel()" [buttonType]="buttonType.SECONDARY" [buttonText]="BUTTON.CANCEL"></pivot-text-button>
    <pivot-text-button (btnClick)="onSubmit()" [buttonType]="buttonType.SECONDARY"  [buttonText]="BUTTON.ADD"></pivot-text-button>
  </div>
</div>
</pivot-modal-template>

