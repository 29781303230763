<pivot-modal-template class="dialog-staff-edit" *ngIf="isDisplayModal" [modalTemplate]="modalData"
    (closeModalHandler)="onClose()">
    <div modal-body>
        <div class="content body">
            <div class="item">
                <label>{{SYSTEM_SETTING.ROLE_SELECT}} <span class="item-required">{{COMMON_TEXT.ASTERISK}}</span></label>
                <pivot-search-bar [ngClass]="isEmptyRole ? 'item-error' : ''" [searchParams]="accessParams"
                    (onFilterData)="onAccessFilterData($event)">
                </pivot-search-bar>
                <div class="item-error-msg" *ngIf="isEmptyRole">{{MESSAGE_TEXT.REQUIRE_SELECT_ROLE}}</div>
            </div>
            <div class="item">
                <label>{{SYSTEM_SETTING.GROUP_SELECT}}</label>
                <pivot-auto-complete [data]="listTeam" [field]="teamDspField" [selectedData]="selectedTeams"
                    (onChangeData)="onChangeTeamData($event)"></pivot-auto-complete>
            </div>
        </div>
    </div>
    <div modal-bottom class="content footer">
        <div>
            <pivot-text-button class="text-btn" [iconType]="iconType.CLOSE_DIALOG" (btnClick)="onClose()" [buttonType]="buttonType.SECONDARY" [buttonText]="BUTTON.CANCEL">
            </pivot-text-button>
            <pivot-icon-button [styleClass]="'staff-edit-save-dialog-btn'" (onClick)="handleSubmit()" icon="assets/icons/staff_save.svg" [buttonType]="buttonType.PRIMARY" [label]="BUTTON.SAVE"></pivot-icon-button>
        </div>
    </div>
</pivot-modal-template>