export class DefaultValueSetting {
  id?: string;
  defaultvaluesettingcd?: string;
  defaultvaluesettingname?: string;
  officeidlst?: string;
  invalidflg?: boolean;
  delflg?: boolean;
  insstfcd?: string;
  insdate?: Date;
  updstfcd?: string;
  upddate?: Date;
  isChecked?: boolean;
  isEditing?: boolean;
}
export class DefaultValueSettingDetail {
  id?: string;
  defaultvaluesettingcd?: string;
  kbntag?: string;
  ttltag?: string;
  delflg?: boolean;
  insstfcd?: string;
  insdate?: Date;
  updstfcd?: string;
  upddate?: Date;

  invalidKbntag: boolean;
  invalidTtltag: boolean;
}


export class DefaultValueSettingRO {
  defaultValueSetting?: DefaultValueSetting;
  listDefaultValueSettingDetail?: DefaultValueSettingDetail[];
}

