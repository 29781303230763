import { ElementRef } from "@angular/core";
import { OPERATOR_DETAULT, OPERATOR_MATH, OPERATOR_TYPE } from "../const/operator-custom";
import { createXNode } from "./helper";
import { getCaretPosition, setCaretPosition } from "./mention-helper";
import { operator } from "../models/common-model";
import { WidgetDetail } from "../models/request/widget.dto";
import { DataSourceColumnType, DataSourceType } from "../enum/common-enum";
import { UnicodeCharacterOperator, UnicodeCusor } from "../const/unicode-characters";
import { cloneDeep, values } from "lodash";
import { DATA_SOURCE_DEFAULT_HEADERS, DATA_SOURCE_DEFAULT_HEADERS_MASTER } from "../const/const";
import { DefaultValueSettingRO } from "../models/response/default-value-setting.ro";

export function includeMathOperator(value:string) :boolean{
  let isInclude = false;
  OPERATOR_MATH.map((x:string)=>{
    if(value.includes(x)){
      isInclude = true;
    }
  })
  return isInclude;
}
export function generateHtmlCusor(html: any, index: number){
  var cusorNode = createXNode('',null, OPERATOR_TYPE.OPERATOR_CUSOR)
  cusorNode.id = OPERATOR_TYPE.OPERATOR_CUSOR;
  cusorNode.setAttribute('x-index', index.toString())
  html.appendChild(cusorNode)
}
export function generateHtmlOperator(html: any, item: any, index: number, nextItem : any ){
  if(includeMathOperator(item.label) && !item.label.includes("\"")){
    var empty = document.createTextNode('\u00A0'); 
    html.appendChild(empty)
  }
  var textNode = document.createTextNode(decodeCharactorOperators(item.label.trim()))
  html.appendChild(textNode);
  if(includeMathOperator(item.label) && !item.label.includes("\"")){
    var empty = document.createTextNode('\u00A0'); 
    html.appendChild(empty)
  }
}
export function generateHtml(html: any, item: any, index: number, contentEditable?: boolean | null){
  let span = createOperator(item, contentEditable);
  span.setAttribute('x-index', index.toString())
  html.appendChild(span)
}

export function removeAllCusor(operatorEditorEmelent: ElementRef) {
  let chileCusor :any[] = [];
  for(var i = 0 ; i<  (operatorEditorEmelent.nativeElement?.children.length ?? 0); i++){
    if(operatorEditorEmelent.nativeElement?.children[i].id == OPERATOR_TYPE.OPERATOR_CUSOR) {
      chileCusor.push(operatorEditorEmelent.nativeElement?.children[i]);
    }
  }
  for(var  i = 0 ; i < chileCusor.length; i++){
    operatorEditorEmelent.nativeElement?.removeChild(chileCusor[i]);
  }
 }


 export function getArchorNode(focusNode: Node, operatorEditorEmelent: ElementRef): Node{
  let pos = getCaretPosition(operatorEditorEmelent.nativeElement,null);
  let anchorNode: Node = focusNode;
  let lengtext = 0;
  operatorEditorEmelent.nativeElement.childNodes.forEach((node:any)=>{
      lengtext = lengtext + node.textContent.length;
      if(lengtext <= pos){
        anchorNode = node;
      }
    });
  return anchorNode;
 }

 export function createOperator(item: any, contentEditable?: boolean | null): HTMLSpanElement{
  var span = createXNode(item.label, null , item.type);
  if(contentEditable) {span = createXNode(item.label, null , item.type, contentEditable)}
  var hidden = createXNode(JSON.stringify(item), 'hidden')
  span.appendChild(hidden)
  return span
 }

 export function insertCusor( operatorEditorEmelent: ElementRef) {
  var cusorNode = createXNode('',null, OPERATOR_TYPE.OPERATOR_CUSOR)
  cusorNode.id = OPERATOR_TYPE.OPERATOR_CUSOR;
  let pos = getCaretPosition(operatorEditorEmelent.nativeElement,null);

  if(pos == 0) {
    removeAllCusor(operatorEditorEmelent);
    if(operatorEditorEmelent.nativeElement.childNodes.length > 0){
      let nodeNext = operatorEditorEmelent.nativeElement.childNodes[0];
      operatorEditorEmelent.nativeElement?.insertBefore(cusorNode, nodeNext);
    }else{
      operatorEditorEmelent.nativeElement?.appendChild(cusorNode);
    }
    return;
  }

  let selObj =  window.getSelection();

  var anchorNode = selObj?.anchorNode;
  var focusNode = selObj?.focusNode;

  if((<HTMLInputElement>focusNode).id == 'js-operator-editor'){
    if(focusNode){
      anchorNode = getArchorNode(focusNode, operatorEditorEmelent);
    }
  }

  if((<HTMLInputElement>anchorNode).id == 'js-operator-editor'){
   return
  }
  
  removeAllCusor(operatorEditorEmelent);

  if(focusNode?.nodeName == "#text"){
    let value = focusNode?.nodeValue;
    let pos2 = getCaretPosition((<HTMLInputElement>focusNode),null);
    let valueFirst = value?.substring(0,pos2);
    let valueLatst = value?.substring(pos2,value.length);
    let nodeText1 = document.createTextNode(valueFirst??"");
    let nodeText2 = document.createTextNode(valueLatst??"");
    let next = anchorNode?.nextSibling;
    if(focusNode){
      operatorEditorEmelent.nativeElement?.removeChild(focusNode)
    }
    if(next){
      operatorEditorEmelent.nativeElement?.insertBefore(nodeText1, next);
      operatorEditorEmelent.nativeElement?.insertBefore(cusorNode, next);
      operatorEditorEmelent.nativeElement?.insertBefore(nodeText2, next);
    }else{
      operatorEditorEmelent.nativeElement?.appendChild(nodeText1);
      operatorEditorEmelent.nativeElement?.appendChild(cusorNode);
      operatorEditorEmelent.nativeElement?.appendChild(nodeText2);
    }
  } else {
    let next = anchorNode?.nextSibling;
    if(next){
      operatorEditorEmelent.nativeElement?.insertBefore(cusorNode, next);
    }else{
      operatorEditorEmelent.nativeElement?.appendChild(cusorNode);
    }
  }
 }

 export function insertValuePaste(valuePaste: string ,operatorEditorEmelent: ElementRef) {
  var textNode = document.createTextNode(valuePaste??"");
  var spanEpmty = document.createTextNode(''); 
  let selObj =  window.getSelection();
  let range  =selObj?.getRangeAt(0);
  if(range?.endContainer == range?.startContainer){// highlight only 1 node
    var anchorNode = selObj?.anchorNode;
    var focusNode = selObj?.focusNode;


    if((<HTMLInputElement>focusNode).id == 'js-operator-editor'){
      if(focusNode){
        anchorNode = getArchorNode(focusNode, operatorEditorEmelent);
      }
    }
  
    if((<HTMLInputElement>anchorNode).id == 'js-operator-editor'){
      operatorEditorEmelent.nativeElement?.appendChild(textNode);
      operatorEditorEmelent.nativeElement?.appendChild(spanEpmty);
      
    } else {
      if(focusNode?.nodeName == "#text"){
        let value = focusNode?.nodeValue;
        let pos2 = getCaretPosition((<HTMLInputElement>focusNode),null);
        let valueFirst = value?.substring(0,pos2);
        if(selObj && (selObj?.anchorOffset != selObj?.focusOffset)){// remove highlight text
          let offset =  (selObj?.focusOffset < selObj?.anchorOffset)? selObj?.focusOffset: selObj?.anchorOffset;
           valueFirst = value?.substring(0, offset);
        }
        let valueLatst = value?.substring(pos2,value.length);
        // let valueLatst = value?.substring(pos2,value.length);
        let nodeText1 = document.createTextNode(valueFirst??"");
        let nodeText2 = document.createTextNode(valueLatst??"");
        let next = anchorNode?.nextSibling;
        if(focusNode){
          operatorEditorEmelent.nativeElement?.removeChild(focusNode)
        }
        if(next){
          operatorEditorEmelent.nativeElement?.insertBefore(nodeText1, next);
          operatorEditorEmelent.nativeElement?.insertBefore(textNode, next);
          operatorEditorEmelent.nativeElement?.insertBefore(spanEpmty, next);
          operatorEditorEmelent.nativeElement?.insertBefore(nodeText2, next);
        }else{
          operatorEditorEmelent.nativeElement?.appendChild(nodeText1);
          operatorEditorEmelent.nativeElement?.appendChild(textNode);
          operatorEditorEmelent.nativeElement?.appendChild(spanEpmty);
          operatorEditorEmelent.nativeElement?.appendChild(nodeText2);
        }
      } else {
        let next = anchorNode?.nextSibling;
        if(next){
          operatorEditorEmelent.nativeElement?.insertBefore(textNode, next);
          operatorEditorEmelent.nativeElement?.insertBefore(spanEpmty,next);
        }else{
          operatorEditorEmelent.nativeElement?.appendChild(textNode);
          operatorEditorEmelent.nativeElement?.appendChild(spanEpmty);
        }
      }
    }
  
    let spanEpmtyHTML = (<HTMLInputElement><unknown>textNode);
    
    setCaretPosition(spanEpmtyHTML,valuePaste.length,null);
  }
 }

export function ReplaceAllCharactorOperator(valustring: any): any {
  valustring = valustring.replace(/”/g,"\"");
  let regexQuote = /"([^"]*)"/g;

  const matches = valustring.match(regexQuote);
  matches?.map((op: any) => {
    let opReplace = encodeCharactorOperators(op);
    valustring = valustring.replaceAll(op, opReplace);
  })
  return valustring
}

 export function getOperatorFromString(valustring:any):any[]{
  valustring = ReplaceAllCharactorOperator(valustring);
  let regex = /([;=<>!%()−；（）x＋＝＞＜！％ー+\-*\/])/g;
  let result = valustring.split(regex).filter((str:any) => str !== "");
  var listItem: operator[] = [];
  for(var i = 0 ; i < result.length ;i ++){
    if(result[i].trim()){
      let item = {} as operator
      item.type = 'operator'
      if(result[i].includes("(") ||result[i].includes(")") 
      ||result[i].includes("（") ||result[i].includes("）")
      ||result[i].includes("+") || result[i].includes("-")
      ||result[i].includes("*") ||result[i].includes("/")
      ||result[i].includes("＋") ||result[i].includes("ー")
      ||result[i].includes("−")
      ||result[i].includes("x") ||result[i].includes(";")
      ||result[i].includes("＞") ||result[i].includes("＜")
      ||result[i].includes("！") ||result[i].includes("＝")
      ||result[i].includes("％") ||result[i].includes("；")) {
        switch (result[i]) {
          case '＋': result[i] = '+'; break;
          case 'ー': result[i] = '-'; break;
          case '−': result[i] = '-'; break;
          case '（': result[i] = '('; break;
          case '）': result[i] = ')'; break;
          case '；': result[i] = ';'; break;
          case '＝': result[i] = '='; break;
          case '＞': result[i] = '>'; break;
          case '＜': result[i] = '<'; break;
          case '！': result[i] = '!'; break;
          case '％': result[i] = '%'; break;
        }
      }
      item.label = decodeCharactorOperators(result[i].trim());
      item.value = decodeCharactorOperators(result[i].trim());
      listItem.push(item);
    }
   
  }
 return listItem;
}

export function checkCusorBetweenQuotes(chilNodes : Node[] ): boolean{

  let valueCheck = false;
  let text = "";
  chilNodes.forEach((node, i) => {
    let item = {} as operator
    let nodeLE =  node as HTMLElement;
    if(nodeLE.id == OPERATOR_TYPE.OPERATOR_CUSOR ){
      text = text + UnicodeCusor;
    }else{
      text = text+ node.textContent
    }
  });
  text = text.replace(/”/g,"\"");
  let regexQuote = /"([^"]*)"/g
  const matches =  text.match(regexQuote);
  matches?.map((matche: any)=>{
    if(matche.includes(UnicodeCusor)){
      valueCheck = true;
    }
  })

  return valueCheck;
}
export function checkCusorBetweenOperatorQuotes(listOperator : operator[]): boolean{
  let valueCheck = false;
  let text = "";
  listOperator.forEach((op, i) => {
    if(op.value == OPERATOR_TYPE.OPERATOR_CUSOR ){
      text = text + UnicodeCusor;
    }else{
      text = text+ op.label
    }
  });
  text = text.replace(/”/g,"\"");
  let regexQuote = /"([^"]*)"/g
  const matches =  text.match(regexQuote);
  matches?.map((matche: any)=>{
    if(matche.includes(UnicodeCusor)){
      valueCheck = true;
    }
  })

  return valueCheck;
}
export function replaceOperator(chilNodes : ChildNode[] ): ChildNode[]{
  let indexCusor = chilNodes.findIndex((node) => ((node as HTMLElement).id == OPERATOR_TYPE.OPERATOR_CUSOR));
  if(indexCusor!=0 && indexCusor != (chilNodes.length-1)){
    let nodeBefore = chilNodes[indexCusor-1];
    let arrytextBefore = nodeBefore.textContent?.replace(/”/g,"\"").split(/"/);//.filter(part => part.trim() !== '');;
    if(arrytextBefore) {
      let value = arrytextBefore.pop();
      if(value){
        value = encodeCharactorOperators(value);
        let newValue = arrytextBefore.join("\"").replace(/”/g,"\"");
        if(newValue && newValue != ''){
          newValue = `${newValue}\"${value}`;
        }else{
          newValue = `\"${value}`;
        }
        nodeBefore.textContent = newValue;
      }
    }
    let nodeAfter = chilNodes[indexCusor+1];
    let arrytext2After  = nodeAfter.textContent?.replace(/”/g,"\"").split(/"/);//.filter(part => part.trim() !== '');;
    if(arrytext2After && arrytext2After.length >0){
      let value =  arrytext2After?.shift();
      value = encodeCharactorOperators(value);
      let newValue = `${value}\"`;
      if(arrytext2After && arrytext2After.length >0){
        newValue = arrytext2After.join("\"").replace(/”/g,"\"");;
        if(newValue && newValue != ''){
          newValue = `${value}\"${newValue}`;
        }else{
          newValue = `${value}\"`;
        }
      }
      nodeAfter.textContent = newValue;
    }else{
      if(nodeAfter.textContent){
        nodeAfter.textContent =  nodeAfter.textContent.replace(/”/g,"\"");
      }
     
    }
  }

  return chilNodes;
}

export function getOperatorFormChildNode( chilNodes : Node[]):operator[] {
  let operators: operator[] = [];
  chilNodes.forEach((node, i) => {
    let item = {} as operator
    let nodeLE =  node as HTMLElement;
    if (node.nodeName === '#text') {
      if(node?.textContent) {
        var listOperator = getOperatorFromString(node.textContent);
        operators = [...operators, ...listOperator]
      }
    } else if(nodeLE.id == OPERATOR_TYPE.OPERATOR_CUSOR || nodeLE.nodeValue == OPERATOR_TYPE.OPERATOR_CUSOR ){
      item.label = "";
      item.value = OPERATOR_TYPE.OPERATOR_CUSOR;
      item.type = OPERATOR_TYPE.OPERATOR_CUSOR;
      operators.push(item);
    }
    else {
      if(node.childNodes[1]?.textContent) {
        item = JSON.parse(node.childNodes[1].textContent as string)
        operators.push(item)
      }
    }
  })
  return operators;
}

export function copyOperatorHandle(event: any, rounding: any){
  let selection = window.getSelection();
  if (!selection?.isCollapsed) {
    let count = selection?.rangeCount ?? 0;
    for(var i = 0;i< count ; i ++){
      var range = selection?.getRangeAt(i);
      let operatorsCopy = [];
      if(range?.commonAncestorContainer.parentNode?.nodeName == "SPAN"){
        let nodeP = range?.commonAncestorContainer.parentNode as Node;
        operatorsCopy = getOperatorFormChildNode([...[], nodeP]);
      }else{
        let selectionContents = range?.cloneContents();

        let chide = selectionContents?.childNodes ?? [];
        operatorsCopy = getOperatorFormChildNode(Array.from(chide));
      };

      let dataTranfer = {operators: operatorsCopy, rounding: rounding}
      event.clipboardData.setData("text/plain", JSON.stringify(dataTranfer));
      event.preventDefault();
    }
  } 
}

export function checkHighlight(): boolean{
  let selection = window.getSelection();
  return selection?.isCollapsed ?? false;
}

export function getOperatorFormClipboardData(event: any) :any {
  let data = null;
  try {
    let dataString = event.clipboardData.getData("text/plain")
    data = JSON.parse(dataString);
  }catch  {
  }

  return data;
}

export function replaceFullWidthNumerals(s: string) {
  return s.replace( /[\uFF10-\uFF19]/g,
    function(m: any){
      return String.fromCharCode( m.charCodeAt() - 0xFEE0 );
    }
  );
}
export function encodeOperatorsWithCusor(operators: operator[]) : operator[]{
  let indexCusor = operators.findIndex((op: any) => (op.value == OPERATOR_TYPE.OPERATOR_CUSOR));
    if(indexCusor > 0){
      if(operators[indexCusor - 1].type == OPERATOR_TYPE.OPERATOR){
        operators[indexCusor - 1].value = encodeCharactorOperators(operators[indexCusor - 1].value);
        operators[indexCusor - 1].label = encodeCharactorOperators(operators[indexCusor - 1].label);
      };
    }
    if(indexCusor < (operators.length - 1)){
      if(operators[indexCusor + 1].type == OPERATOR_TYPE.OPERATOR){
        operators[indexCusor + 1].value = encodeCharactorOperators(operators[indexCusor + 1].value);
        operators[indexCusor + 1].label = encodeCharactorOperators(operators[indexCusor + 1].label)
      };
    }
  return operators;
}

export function getOperatorString(operators: operator[], columnDataSource?: boolean) :string {
  if(checkCusorBetweenOperatorQuotes(operators)){
    operators = encodeOperatorsWithCusor(operators);
  }
  let opString = '';
  opString = operators.map((x: any) => {
    if(x.value == OPERATOR_TYPE.OPERATOR_CUSOR) return '';
    switch (x.type.trim()) {
      case 'operator':
        switch (x.value) {
          case 'x': return '*'
          case '＋': return '+'
          case 'ー': return '-'
          case '−': return '-'
          case 'x': return '*'
          default: {
            var value = ReplaceAllCharactorOperator(x.value);
            value = replaceFullWidthNumerals(value);
            value = decodeCharactorOperators(value);
           return value;
          }
        }
      case 'column':
        if(columnDataSource){
          return `{${x.value.columnId}}`
        }
        else{
          return `{${(x.value as WidgetDetail).columnname}}`
        }
        

      default: return x.value
    }
  }).join('');

  return opString;
}

export function updateOperatorWithColName(allColumns: any[], dsType:number): any[]{
  let allColDisplay:any[] =[];
  allColDisplay =[...allColumns];
  let defaultColumn:any[] = []
  if(dsType == DataSourceType.TITLE){
    [...DATA_SOURCE_DEFAULT_HEADERS].filter((col:any) => col.visible).map((col: any)=>{
    let dataCol = col;
     dataCol.displayname = col.title;
     defaultColumn.push({value: `COL${col.sortno}`, name: col.title, columnname: col.field.toUpperCase(), defaultValue: col.dataType, data: dataCol, columnId: col.field.toUpperCase()});
   });
   allColDisplay = [...defaultColumn, ...allColumns]
 }else if(dsType == DataSourceType.MASTER){
  [...DATA_SOURCE_DEFAULT_HEADERS_MASTER].filter((col:any) => col.visible).map((col: any)=>{
    let dataCol = col;
     dataCol.displayname = col.title;
     defaultColumn.push({value: `COL${col.sortno}`, name: col.title, columnname: col.field.toUpperCase(), defaultValue: col.dataType, data: dataCol, columnId: col.field.toUpperCase()});
   });
   allColDisplay = [...defaultColumn, ...allColumns]
 }
  allColumns.map((col: any) => {
    if(col.columntype == DataSourceColumnType.USER_DEFINED && !col.delflg) {
      let regex = /\{(.*?)\}/g
      if(!col.operator) return col;
      const matches = col.operator.match(regex);
      if(matches){
        matches.map((matche: string)=> {
          let columnId = matche.replace('{','').replace('}','');
          let colDs = allColDisplay.find((colDs)=> colDs.columnId == columnId);
          if(!colDs) return col;
          if(colDs.columntype == DataSourceColumnType.MASTER ){
            col.operator = col.operator.replaceAll(columnId , `${colDs.tablealias}_${colDs.columnname}`);
          }else if(colDs.columntype == DataSourceColumnType.SEIKUY ){
            col.operator = col.operator.replaceAll(columnId , `${colDs.table}_${colDs.columnname}`);
          }else {
            col.operator = col.operator.replaceAll(columnId , colDs.columnname);
          }
  
        })
      }
    }
    return col;
  });
  return allColumns;
}

export function updateOperatorWithColId(allColumns: any[], dsType:number): any[]{
  let allColDisplay: any[] = [];
  allColDisplay = [...allColumns];
  let defaultColumn: any[] = []
  if (dsType == DataSourceType.TITLE) {
    [...DATA_SOURCE_DEFAULT_HEADERS].filter((col: any) => col.visible).map((col: any) => {
      let dataCol = col;
      dataCol.displayname = col.title;
      dataCol.delflg = false;
      dataCol.columnname = col.field.toUpperCase();
      defaultColumn.push({ value: `COL${col.sortno}`, name: col.title, columnname: col.field.toUpperCase(), defaultValue: col.dataType, data: dataCol, columnId: col.field.toUpperCase() });
    });
  }else if(dsType == DataSourceType.MASTER){
    [...DATA_SOURCE_DEFAULT_HEADERS_MASTER].filter((col: any) => col.visible).map((col: any) => {
      let dataCol = col;
      dataCol.displayname = col.title;
      dataCol.columnname = col.field.toUpperCase();
      defaultColumn.push({ value: `COL${col.sortno}`, name: col.title, columnname: col.field.toUpperCase(), defaultValue: col.dataType, data: dataCol, columnId: col.field.toUpperCase() });
    });
  }
  allColDisplay = [...defaultColumn, ...allColumns]
  allColDisplay =allColDisplay.filter((col: any) => !col.data.delflg)
  allColumns.map((col: any) => {
    if(col.data.columntype == DataSourceColumnType.USER_DEFINED && !col.data.delflg) {
      let regex = /\{(.*?)\}/g
      if(!col.operator) return col;

      // Clone col.operator vào col.operatorSaucerLog
      col.operatorSaucerLog = col.operator;
      const matches = col.operator.match(regex);
      if(matches)
      matches.map((matche: string)=> {
        let columnname = matche.replace('{','').replace('}','');

        let colDs = allColDisplay.find((colDs)=> colDs.data.columnname == columnname);
        if(!colDs) {
          if(columnname.includes("_")){
            columnname = columnname.split("_")[1];
          }
          colDs = allColDisplay.find((colDs)=> colDs.data.columnname == columnname);
          if(!colDs) return col;
        }
  
        if(colDs.data.columntype == DataSourceColumnType.MASTER){
          col.operator = col.operator.replaceAll(`${colDs.data.tablealias}_${colDs.data.columnname}`, colDs.columnId);
          col.operatorSaucerLog = col.operatorSaucerLog.replaceAll(`${colDs.data.tablealias}_${colDs.data.columnname}`, colDs.name);
        } if(colDs.data.columntype == DataSourceColumnType.SEIKUY ){
          col.operator = col.operator.replaceAll(`${colDs.data.table}_${colDs.data.columnname}`, colDs.columnId);
          col.operatorSaucerLog = col.operatorSaucerLog.replaceAll(`${colDs.data.table}_${colDs.data.columnname}`, colDs.name);
        } else {
          col.operator = col.operator.replaceAll(columnname , colDs.columnId);
          col.operatorSaucerLog = col.operatorSaucerLog.replaceAll(columnname, colDs.name);
        }
      })
    }
    return col;
  });
  return allColumns;
}

export function encodeCharactorOperators(str: any): string{
  UnicodeCharacterOperator.map((char)=>{
    str = str?.replace(char.regex, char.code);
  });
  return str
}

export function decodeCharactorOperators(str: any): string{
  UnicodeCharacterOperator.map((char)=>{
    str = str?.replace(char.regexDecode, char.name);
  });
  return str
}


export function mergeTextNode(childNodes: ChildNode[]): ChildNode[] {
  const mergedNodes: ChildNode[] = [];
  let concatenatedText = '';
  for (let i = 0; i < childNodes.length; i++) {
    const currentNode = childNodes[i];

    if (currentNode.nodeType === Node.TEXT_NODE) {
      concatenatedText += currentNode.textContent || '';
      const nextNode = childNodes[i + 1];
      if (!nextNode || nextNode.nodeType !== Node.TEXT_NODE) {
        const newText = currentNode.ownerDocument!.createTextNode(concatenatedText);
        mergedNodes.push(newText);
        concatenatedText = '';
      }
    } else {
      mergedNodes.push(currentNode);
    }
  }

  return mergedNodes;
}

export function moveCursorToEndLine(nativeElement: any){
  var range,selection;
  if(document.createRange)//Firefox, Chrome, Opera, Safari, IE 9+
  {
      range = document.createRange();//Create a range (a range is a like the selection but invisible)
      range.selectNodeContents(nativeElement);//Select the entire contents of the element with the range
      range.collapse(false);//collapse the range to the end point. false means collapse to end rather than the start
      selection = window.getSelection();//get the selection object (allows you to change selection)
      selection?.removeAllRanges();//remove any selections already made
      selection?.addRange(range);//make the range you have just created the visible selection
  }
  
}


export function checkOperatorDefault(operator: string){
  const strRe = `(DEFAULT)\\(([^()]+(?:\\((?:[^()]+|\\((?:[^()]+|\\([^()]*\\))*\\))*\\))*)\\)`;
  let operatorReplace = ReplaceAllCharactorOperator(operator);
  let mapOp = operatorReplace.match(strRe);
  if(mapOp && mapOp.length >=3 ){
    return decodeCharactorOperators(mapOp[2]);
  } 
  return null;    
}

export function ReplaceAllDefaultOperator(formula: any, lstDefaultFuntion:any[]): any {
  let defOperatorPara = checkOperatorDefault(formula);
  if(defOperatorPara != null) {
    lstDefaultFuntion.push("DEFAULT(" + defOperatorPara+ ")");
    formula = formula.replace("DEFAULT(" + defOperatorPara+ ")", "DEFAULT_"+ (lstDefaultFuntion.length - 1));
    let result = ReplaceAllDefaultOperator(formula, lstDefaultFuntion);
    lstDefaultFuntion = result.lstdefault;
    formula = result.formula;
  }
  return {
    formula: formula,
    lstdefault: lstDefaultFuntion
  }
 }
 export function getLabeDefaultFuntion(valuePara: string): any{
    let lable = '';
    let params = valuePara.replace(/\"/g, "").split(";");
    if(params.length >= 5){
      lable = `${params[4]} > ${params[1]} - ${params[2]} > ${params[3]}`;
    }
    return lable;
 }