<div class="pivot-filter">
    <p-overlayPanel appendTo="{{appendToPosition}}" #op [styleClass]="class ? class + ' filterData' : 'filterData'"
        [style]="cssStyle" (onShow)="displayPopup()" (onHide)="removeClickEvent()">
        <ng-template pTemplate>
            <div class="box-action-filter" id="panelFilter">
                <span class="p-input-icon-right {{isMutiple ? 'text-box-mutiple-search' : 'text-box-search'}}"
                    *ngIf="isFilter">
                    <i [class]="inputSearch.value ? 'pi pi-times' : 'pi pi-search'"
                        (click)="onSearchData(inputSearch.value = ''); inputSearch.focus()"></i>
                    <input #inputSearch type="text" pInputText [maxLength]="maxLength" (input)="onSearchData($event)" 
                        [placeholder]="placeHolderFilter" />
                </span>
                <p-listbox [group]="isGroup" [checkbox]="false" [multiple]="isMutiple" [options]="dataFilters"
                    [(ngModel)]="selectedData" optionLabel={{displayText}}
                    (onChange)="onSelectedData($event)" [emptyMessage]="COMMON_TEXT.NO_DATA">
                    <ng-template let-option pTemplate="item">
                        <div>{{option.name}}</div>
                    </ng-template>
                </p-listbox>
                <span class="p-listbox-empty-message" *ngIf="dataFilters && dataFilters.length === 0 && isGroup">
                    {{COMMON_TEXT.NO_DATA}}
                </span>
            </div>
        </ng-template>
    </p-overlayPanel>
</div>