<p-scroller
    [items]="virtualData"
    [scrollHeight]="scrollHeight + 'px'"
    [itemSize]="rowHeight"
    [lazy]="true"
    (onLazyLoad)="loadData($event)"
    styleClass="lazy-virtual-scroller"
    >
    <ng-template let-item pTemplate="item">
        <pivot-lazy-tree-node *ngIf="item" [nodeClicked] = "nodeClicked" [node]="item" (nodeClick)="handleClick($event)" (leafNodeClick)="onClickLeafNode($event)" [dragScope]="dragScope">
        </pivot-lazy-tree-node>
    </ng-template>
</p-scroller>