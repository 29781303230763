import { OperatingSystemName } from "../enum/common-enum";

export default class Utils {
  // Check Null/Empty or value
  public static isNullOrEmpty(value: any): boolean {
    if (value === undefined || value === null) return true;
    if (value?.toString()?.trim() === '') return true;
    return false;
  }
  public static getOS(): OperatingSystemName | null {
    const userAgent = window.navigator.userAgent.toLowerCase();
    const macosPlatforms = /(macintosh|macintel|macppc|mac68k|macos)/i;
    const windowsPlatforms = /(win32|win64|windows|wince)/i;
    let os: OperatingSystemName | null = null;
  
    if (macosPlatforms.test(userAgent)) {
      os = OperatingSystemName.Mac;
    } else if (windowsPlatforms.test(userAgent)) {
      os = OperatingSystemName.Windows;
    }
  
    return os;
  }

  public static replaceYenCharacterOnChart(data: any) {
    if (!data) {
      return;
    }
    let categoriesReplaced = data?.categories?.map((x: any) => {
      if (typeof x == 'string') {
        x = x.replace(/\\/g, "¥");
      }
      return x;
    });

    data.categories = categoriesReplaced;
    
    data?.columns?.map((x: any) => {
      for (let key in x){
        if (typeof x[key] == 'string') {
          x[key] = x[key].replace(/\\/g, "¥");
        }
      }
      return [...x];
    });
    
    return data;
  }
}
