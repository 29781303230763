import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import Utils from 'src/app/util/utils';
import { COMMON_TEXT } from '../../../../const/text-common';
import { LazyTreeNode, TreeNode } from '../../../../models/common-model';

@Component({
  selector: 'pivot-lazy-tree-node',
  templateUrl: './lazy-tree-node.component.html',
  styleUrls: ['./lazy-tree-node.component.scss'],
})
export class LazyTreeNodeComponent implements OnInit, OnChanges {

  @Input() node: LazyTreeNode = new LazyTreeNode();
  @Input() nodeTree?: TreeNode[] = [];
  @Input() nodeClicked: LazyTreeNode;
  @Input() dragScope: string;

  @Output() nodeClick = new EventEmitter<any>();
  @Output() leafNodeClick = new EventEmitter<any>();

  showTootip: boolean = false;
  COMMON_TEXT = COMMON_TEXT;
  treeNodeStepSrc: string = "";
  expandIcon: string = '';
  collapseIcon: string = '';
  MAX_LENGTH_TOOLTIP = 100
  constructor() { 
    const bodyElement = document.getElementsByTagName("body")[0];
    const isDarkMode = bodyElement.classList.contains("navi") || bodyElement.classList.contains("dark") ? true : false;
    this.treeNodeStepSrc = isDarkMode ? '../../../../../assets/icons/tree-node-step-white.svg' : '../../../../../assets/icons/tree-node-step.svg';
    if (isDarkMode) {
      this.expandIcon = '../../../assets/icons/folder-opened-white.svg';
      this.collapseIcon = '../../../assets/icons/folder-white.svg';
    } else {
      this.expandIcon = '../../../assets/icons/folder-opened.svg';
      this.collapseIcon = '../../../assets/icons/folder.svg';
    }
  }
  ngOnChanges(changes: SimpleChanges): void {
    if(this.node.tooltip && this.node.tooltip?.length > this.MAX_LENGTH_TOOLTIP) {
      this.node.tooltip = this.node.tooltip.substring(0, this.MAX_LENGTH_TOOLTIP) + '\r\n...';
    }
  }

  ngOnInit(): void {
 
  }
  dragOver(evnt:any){
    document.body.classList.add('dragging');
  }
  dragEnd(evnt:any){
    document.body.classList.remove('dragging');
  }
  handleNodeClick(event: any) {
    if (!this.node.isLastNode) {
      this.node.expanded = !this.node.expanded;
      if (this.node.node) {
        this.node.node.expanded = this.node.expanded;
      }
      this.nodeClick.emit(this.node);
    } else {
      this.leafNodeClick.emit(this.node);
    }
  }

  dragStart(e: any) {
    e.dataTransfer.setData("lazy-tree-node", JSON.stringify(this.node));
  }
  mouseEnterContainer(event:any){
    const target = event.target as HTMLElement;
    if(this.nodeClicked.id == this.node.id){
      target.classList.remove('unhover');
      target.classList.add('hoverNoAnimation');
    }else{
      target.classList.remove('unhover');
      target.classList.add('hover');
    }

  }
  mouseLeaveContainer(event:any){
    const target = event.target as HTMLElement;
    target.classList.add('unhover');
    target.classList.remove('hover');
    target.classList.remove('hoverNoAnimation');
  }

}