<div class="pivot-chart-bulk" >
    <table class="pivot-table" #table>
        <thead>
            <tr>
                <ng-container *ngFor="let col of headers; let i = index">
                    <th class="first">{{col.title}}</th>
                </ng-container>
            </tr>
        </thead>
        <tbody *ngIf="headers.length">
            <ng-container *ngFor="let row of bodySlice">
                <tr [style.height.px]="rowHeight">
                    <ng-container *ngFor="let col of headers; let i = index">
                        <td>{{formatDataType(row[col.field], col)}}</td>
                    </ng-container>
                </tr>
            </ng-container>
            <tr *ngIf="isLoading" class="loading" [style.height.px]="rowHeight">
                <td [attr.colspan]="headers.length" class="loader"></td>
            </tr>
        </tbody>
    </table>
</div>