import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BUTTON } from '../../../../app/const/text-common';

// 桁追加ボタン
@Component({
  selector: 'pivot-additional-arrow-button',
  templateUrl: './additional-arrow-button.component.html',
  styleUrls: ['./additional-arrow-button.component.scss']
})
export class AdditionalArrowButtonComponent implements OnInit {

  @Input() width: number = 0;
  @Input() height: number = 0;
  @Output() onClick = new EventEmitter();
  BUTTON = BUTTON;
  constructor() { }

  ngOnInit(): void {
  }

  handleClick(event: any) {
    this.onClick.emit(event);
  }

  getButtonStyle() {
    const style: any = {};

    if (this.width) {
      style.width = `${this.width}px`;
    }

    if (this.height) {
      style.height = `${this.height}px`;
    }

    return style;
  }
}
