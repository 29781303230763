<div class="sidebar" [ngClass]="{ 'expanded': isExpanded, 'sidebar-hidden': deviceType == DeviceType.MOBILE || deviceType == DeviceType.TABLET}">
  <div class="header" (click)="handleSidebarToggle($event)">
    <div class="sidebar-toggle">
      <img [src]="getMainMenuIconSource('hamburger')" class="pi-bars" />
      <img [src]="getMainMenuIconSource('r-arrow')" class="pi-angle-double-right" />
      <img [src]="getMainMenuIconSource('l-arrow')" class="pi-angle-double-left" />
    </div>
  </div>
  <div class="content">
    <div *ngIf="!data || data.length == 0" class="skeleton">
      <div class="skeleton-icon"></div>
      <div class="skeleton-text"></div>
    </div>
    <!-- parent menu-->
    <ng-container *ngFor="let menu of data">
      <div class="link" [ngClass]="{'with-children': hasChild(menu)}">
        <div class="content" [routerLink]="[menu.routerLink]" [routerLinkActive]="'active'"
          (click)="hasChild(menu) && expandCollapseChild($event)" (mouseenter)="handleMouseEnter($event, menu)"
          (mouseleave)="handleMouseLeave($event, menu)">
          <div class="icon" [id]="getMenuImageId(menu)" [style]="getImageSrc(menu)" [ngClass]="menu.icon"></div>
          <div class="title" tooltip="Text">{{menu.name}}</div>

          <!-- up down icon for parent has child-->
          <div class="expand-collapse" *ngIf="hasChild(menu)">
            <i class="pi pi-chevron-down display-none"></i>
            <i class="pi pi-chevron-up"></i>
          </div>
        </div>

        <!-- child menu-->
        <div class="children display-none">
          <ng-container *ngFor="let childMenu of menu.childs">
            <div class="link">
              <div class="content" [routerLink]="[childMenu.routerLink]" [routerLinkActive]="'active'"
                (mouseenter)="handleMouseEnter($event, childMenu)" (mouseleave)="handleMouseLeave($event, childMenu)">
                <div class="icon" [id]="getMenuImageId(childMenu)" [style]="getImageSrc(childMenu)" [ngClass]="childMenu.icon"></div>
                <div class="title">{{childMenu.name}}</div>
              </div>
            </div>
          </ng-container>
        </div>
      </div>
    </ng-container>
  </div>
  <div class="footer"></div>
</div>