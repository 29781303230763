import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { cloneDeep } from 'lodash';
import { MESSAGE_TEXT } from '../../../../app/const/message';
import { BUTTON, COMMON_TEXT, SYSTEM_SETTING } from '../../../../app/const/text-common';
import { ButtonIconType, ButtonType, SearchType } from '../../../../app/enum/common-enum';
import { ModalTemplate } from '../../../../app/models/common-model';
import { Role } from '../../../../app/models/response/role.ro';
import { Team } from '../../../../app/models/response/team.ro';

@Component({
  selector: 'pivot-dialog-staff-edit',
  templateUrl: './dialog-staff-edit.component.html',
  styleUrls: ['./dialog-staff-edit.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class DialogStaffEditComponent implements OnInit {
  @Input() data: Role[];
  @Input() listTeam: Team[];
  @Input() teamDspField: string;
  @Input() selectedTeams: Team[];
  @Input() selectedDataRolecd: string;
  @Input() isDisplayModal: boolean = false;
  @Input() modalData: ModalTemplate = new ModalTemplate();
  @Output() onSubmitData: any = new EventEmitter<any>();
  BUTTON = BUTTON;
  COMMON_TEXT = COMMON_TEXT;
  SYSTEM_SETTING = SYSTEM_SETTING;
  MESSAGE_TEXT = MESSAGE_TEXT
  buttonType = ButtonType;
  selectRole: any;
  isEmptyRole: boolean = false;
  selectTeam: Team[] | undefined;
  dropdownData: any[] = [];
  accessParams: any = {
    type: SearchType.combo,
    placeholder: '',
    isFilter: false,
    cssStyle: { height: '40px', width: '380px' },
    readonly: false,
    disabled: false,
    comboDisplayText: 'name'
  };
  iconType = ButtonIconType;
  constructor() { }

  ngOnInit(): void {
    this.modalData.header = SYSTEM_SETTING.STAFF_INFO_UPDATE;
    this.dropdownData = this.data.map(r => ({ name: r.name, value: r.roleCd }));
    this.setDefaulValue();
    this.accessParams.dataSource = this.dropdownData;
    this.accessParams = cloneDeep(this.accessParams);
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.selectRole = null;
    this.selectTeam = undefined;
    this.isEmptyRole = false;
    if (changes['selectedDataRolecd']) {
      this.setDefaulValue();
    }
    if (changes['data']) {
      this.dropdownData = this.data.map(r => ({ name: r.name, value: r.roleCd }));
      this.setDefaulValue();
      this.accessParams.dataSource = this.dropdownData;
      this.accessParams = cloneDeep(this.accessParams);
    }
  }

  setDefaulValue() {
    if (!this.selectedDataRolecd) {
      this.accessParams.defaultValue = "";
      return;
    }
    const role = this.data.find(r => r.roleCd == this.selectedDataRolecd);
    if (!role) {
      return;
    }
    this.accessParams.defaultValue = role.name;
  }

  onClose() {
    this.onSubmitData.emit(null);
    this.isDisplayModal = false;
  }

  handleSubmit() {
    if (this.selectRole || this.selectedDataRolecd) {
      const role = this.selectRole && this.selectRole.value ? this.data.find(r => r.roleCd === this.selectRole.value) : null;
      let returnData = {
        role: role,
        teams: this.selectTeam
      }
      this.onSubmitData.emit(returnData);
      this.isDisplayModal = false;
      this.isEmptyRole = false;
    } else {
      this.isEmptyRole = true;
    }
  }

  onAccessFilterData(value: any[]) {
    this.isEmptyRole = false;
    this.selectRole = value[0];
  }

  onChangeTeamData(data: any) {
    this.selectTeam = data;
  }
}
