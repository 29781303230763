import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { take } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class RequestService {
    private subject = new Subject<any>();
    private totalRequests = 0;
    private completedRequests = 0;

    sendRequest() {
        this.totalRequests++;
    }

    completedRequest() {
        this.completedRequests++;
        if (this.checkCompletedRequests()) {
            this.completedRequests = 0;
            this.totalRequests = 0;
            this.subject.next(true);
        }
    }

    checkCompletedRequests(): boolean {
        return this.completedRequests === this.totalRequests;
    }

    onCompletedRequests(): Observable<any> {
        return this.subject.asObservable().pipe(take(1));
    }
}
