import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { cloneDeep } from 'lodash';
import { createXNode, formulaToOperator } from 'src/app/_helper/helper';
import { setCaretPosition } from 'src/app/_helper/mention-helper';
import { checkCusorBetweenQuotes, checkHighlight, checkOperatorDefault, copyOperatorHandle, createOperator, decodeCharactorOperators, generateHtml, generateHtmlCusor, generateHtmlOperator, getLabeDefaultFuntion, getOperatorFormChildNode, getOperatorFormClipboardData, getOperatorFromString, insertCusor, insertValuePaste, mergeTextNode, moveCursorToEndLine, removeAllCusor, ReplaceAllCharactorOperator, replaceOperator } from 'src/app/_helper/operator-custom-helper';
import { DATATYPE, DSCUSTOM, RoundingOption } from 'src/app/const/const';
import { OPERATOR_CUSTOM, OPERATOR_CUSTOM_NAME, OPERATOR_DETAULT, OPERATOR_TYPE } from 'src/app/const/operator-custom';
import { BUTTON, COMMON_TEXT, WIDGET_SETTING } from 'src/app/const/text-common';
import { SearchType } from 'src/app/enum/common-enum';
import { operator, SearchParams } from 'src/app/models/common-model';
import { OperatorCustom } from 'src/app/models/operator-custom';
import { WidgetDetail } from 'src/app/models/request/widget.dto';
import { DefaultValueSettingRO } from 'src/app/models/response/default-value-setting.ro';
import Utils from 'src/app/util/utils';


@Component({
  selector: 'pivot-input-formula',
  templateUrl: './input-formula.component.html',
  styleUrls: ['./input-formula.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class InputFormulaComponent implements OnInit {

  @ViewChild('operatorEditor') operatorEditorEmelent: ElementRef;
  @Input() displayCols: any[];
  @Input() colUsed: any[];
  @Input() selected: WidgetDetail | null;
  @Input() hasTargetValue: boolean = false;
  @Input() isTarget: boolean = false;
  @Input() isDataSource: boolean = false;
  @Input() hasDefaultFuntion: boolean = false;
  @Input() isDefinedColumn: boolean = false;
  @Input() labelButton: string = WIDGET_SETTING.DEFINED_COL_CONDITION;

  @Input() defaultValueSetting: DefaultValueSettingRO[] = [];
  
  column: WidgetDetail = new WidgetDetail();
  cursorIndex: number | undefined;
  // 切り捨てる
  rbtKirisuteAll: boolean = true;
  // 切り上げる
  rbtKiriageAll: boolean = false;
  // 四捨五入する
  rbtRoundAll: boolean = false;
  operators: Array<operator> = []
  selectedColIndex: number = 0;
  usingDataType: any;
  itemsMention: any = {};
  operatorHtml: SafeHtml;
  _sanitizer: DomSanitizer;
  option: number = 0;
  item: number = 0;
  options: any[] = [ 
    {name: '小数点第一を', value: RoundingOption.DecimalFirst}, 
    {name: '小数点第二を', value: RoundingOption.DecimalSecond},
    {name: '少数点第三位を', value: RoundingOption.DecimalThird},
    {name: '一の位を', value: RoundingOption.Unit}, 
    {name: '十の位を', value: RoundingOption.Tens}, 
    {name: '百の位を', value: RoundingOption.Hundreds}];
  optionParams: SearchParams = {
    type: SearchType.combo,
    placeholder: '',
    isFilter: false,
    defaultValue: '小数点以下を',
    cssStyle: { height: '32px', width: 'calc(70% - 5px)', 'font-size': '14px', float: 'right', 'margin-right' : '10px' },
    readonly: false,
    disabled: false,
    dataSource: this.options,
    comboDisplayText: 'name'
  };

  COMMON_TEXT = COMMON_TEXT;
  WIDGET_SETTING = WIDGET_SETTING;
  DATATYPE = DATATYPE;
  clickFocusinEditor: boolean = false;
  editorFocused = false;
  constructor(sanitizer: DomSanitizer,
  ) {
    this._sanitizer = sanitizer;
  }

  ngOnInit(): void {
    this.itemsMention.isRenderDropdown = this.isDataSource;
    this.itemsMention.itemsFunction = [];
    this.itemsMention.itemsColumn = [];
    if(this.displayCols){
      this.displayCols.map((column:any) =>{
        this.itemsMention.itemsColumn.push(
          { label: column.name , type: OPERATOR_TYPE.COLUMN, value: column });
      });
    }
  }
  ngOnChanges(): void {
    if(this.isDataSource){
      this.initColumnDataSource();
      this.itemsMention.itemsColumn = [];
      if(this.displayCols){
      this.displayCols.map((column:any) =>{
        this.itemsMention.itemsColumn.push(
          { label: column.name , type: OPERATOR_TYPE.COLUMN, value: column  });
      });
    }
    }else {
      this.initColumnWidget();
    }
    this.onGenerateHtml();
  }
  initColumnDataSource(){
    if (this.selected) {
      this.column = this.selected
      this.operators = formulaToOperator(this.column, 'operator', this.displayCols, true, this.colUsed);
    }
  }
  initColumnWidget(){
    if (this.selected) {
      if(this.selected.datasourcecd === DSCUSTOM) {
        this.column = this.selected
        this.operators = formulaToOperator(this.column, 'operator', this.displayCols);
      }else {
        if(this.hasTargetValue) {
          this.operators = formulaToOperator(this.selected, 'targetValue' , this.displayCols);
        }
        this.displayCols = this.displayCols.filter(x => x.columnname !== this.column.columnname);
      }
      this.displayCols = this.displayCols.filter(x => x.columnname !== this.column.columnname);
      this.setDataType()
      if(!Utils.isNullOrEmpty(this.selected?.rounding)) {
        let rounding = this.selected.rounding?.split(',') || [];
        this.item = parseInt(rounding[0]?.toString());
        this.option = parseInt(rounding[1]?.toString());
        this.rbtKirisuteAll = this.item == 0 ? true: false;
        this.rbtKiriageAll = this.item == 1 ? true: false;
        this.rbtRoundAll = this.item == 2 ? true: false;
        this.optionParams.defaultValue = this.options.find(s => s.value === this.option)?.name;

      }else{
        this.rbtKirisuteAll = true;
        this.rbtKiriageAll = false;
        this.rbtRoundAll = false;
        this.optionParams.defaultValue = this.options[0]?.name;
      }
    }
    else {
      this.rbtKirisuteAll = true;
      this.rbtKiriageAll = false;
      this.rbtRoundAll = false;
      this.optionParams.defaultValue = this.options[0]?.name;
    }
  }
  setDataType() {
    let opratorCustom = this.operators.filter(x => x.type == OPERATOR_TYPE.OPERATOR_CUSTOM);
    if(opratorCustom.length > 0 ) {
      this.usingDataType = DATATYPE.String;
      return;
    }
    let tmp = this.operators.filter(x => x.type == OPERATOR_TYPE.COLUMN);
    if (!tmp || tmp.length == 0) {
      this.usingDataType = null;
    } else {
      if (this.selected) {
        let val = tmp[0].value as WidgetDetail;
        if(val.datatype){
          this.usingDataType = val.datatype;
        }
      }
    }
  }
  getData(): any{
    this.operators.map((op:any)=>{
      if(op.label && op.type == OPERATOR_TYPE.OPERATOR) op.label = decodeCharactorOperators(op.label);
      if(op.value && op.type == OPERATOR_TYPE.OPERATOR) op.value = decodeCharactorOperators(op.value);
    })
    return {
      operators : this.operators,
      usingDataType: this.usingDataType, 
      rounding: this.item + ','+ this.option
    };
  }
  onCheckedOptionChange(value: any) {
    this.item = parseInt(value);
    if(value === '0') {
      this.rbtKirisuteAll = true;
      this.rbtKiriageAll = false;
      this.rbtRoundAll = false;
    }
    else if(value == '1') {
      this.rbtKirisuteAll = false;
      this.rbtKiriageAll = true;
      this.rbtRoundAll = false;
    }
    else {
      this.rbtKirisuteAll = false;
      this.rbtKiriageAll = false;
      this.rbtRoundAll = true;
    }
  }

  onFilterOption(data: any) {
     this.option = data[0].value;
  }

  onClickCalculator(event: any) {
    if(event.includes(OPERATOR_DETAULT.name)){
     
      let defaultPara = checkOperatorDefault(event);
      if(defaultPara != null) {
        let labelDefault = getLabeDefaultFuntion(defaultPara)
        let valueDefault = event;
        let itemOperators : operator[] = [
          { type: OPERATOR_TYPE.OPERATOR_CUSTOM, label: labelDefault, value: valueDefault}
        ]
        this.pushToFormula(itemOperators);
      }
      
    }
   else if(OPERATOR_CUSTOM_NAME.includes(event)) {
      let itemOperators : operator[] = [
        { type: OPERATOR_TYPE.OPERATOR_CUSTOM, label: event, value: event},
        { type: OPERATOR_TYPE.OPERATOR, label: "(", value: "("},
        { type: OPERATOR_TYPE.OPERATOR, label: ")", value: ")"}
      ]
      this.pushToFormula(itemOperators);
    }else{
      let item: operator = { type: OPERATOR_TYPE.OPERATOR, label: event, value: event, }
      this.pushToFormula([item])
    }
  }
  pushToFormula(items: operator[]) {
    let cursorIndex = this.operators.find((x:any) => x.type == OPERATOR_TYPE.OPERATOR_CUSOR);
    if(cursorIndex){
      this.cursorIndex = this.operators.indexOf(cursorIndex);
    } 
    for (let i = 0; i < items.length; i++) {
      const item = items[i];
      if (this.cursorIndex !== undefined) {
        this.operators.splice(this.cursorIndex, 0, item)
        this.cursorIndex++
      } else {
        this.operators.push(item)
      }
    }
    this.onGenerateHtml();
  }
  onClickColumn(i: number) {
    this.selectedColIndex = i;
    this.onGenerateHtml()
  }

  onClickArrowBtn(event: any) {
    let { columnname, displayname, datatype, ...abc } = this.displayCols[this.selectedColIndex];
    let item: operator = { type: 'column', label: displayname, value: { columnname, displayname, datatype, ...abc } }
    this.pushToFormula([item])
    this.usingDataType = datatype;
  }

  onFocusinEditor(event: any) {
    removeAllCusor(this.operatorEditorEmelent);
    if(!this.clickFocusinEditor){
      moveCursorToEndLine(this.operatorEditorEmelent.nativeElement);
    }
    this.editorFocused = true;
    this.clickFocusinEditor = false;
  }
 
  onClickEditor(event: any) {
    if(!this.editorFocused) this.clickFocusinEditor =true;
  }

  onFocusoutEditor(event: any) {
    this.editorFocused = false;
    if(checkHighlight()) {
      insertCusor(this.operatorEditorEmelent);
    }
    let target = event.target as HTMLElement

    let childArray = mergeTextNode(Array.from(target.childNodes));
    if(checkCusorBetweenQuotes(childArray)){
      childArray = replaceOperator(childArray)
    }
    
    this.operators = []
    this.operators = getOperatorFormChildNode(childArray);
    this.onGenerateHtml();
    this.setDataType();
  }

  onCopyEditor(event: any) {
    copyOperatorHandle(event, this.item + ','+ this.option);
  }
  
  onPasteEditor(event: any) {
    event.preventDefault();
    let data  = getOperatorFormClipboardData(event);
    if(data && (typeof data === 'object')) {
      this.item = parseInt(data.rounding.split(",")[0]) ;
      this.option = parseInt(data.rounding.split(",")[1]);
      this.rbtKirisuteAll = this.item == 0 ? true: false;
      this.rbtKiriageAll = this.item == 1 ? true: false;
      this.rbtRoundAll = this.item == 2 ? true: false;
      this.operators = (data.operators);
      this.optionParams.defaultValue = this.options.find(s => s.value === this.option)?.name;
      let optionParamsNew = cloneDeep(this.optionParams) 
      this.optionParams = optionParamsNew;// trigger change pivot-search-bar 
      this.onGenerateHtml();
    }else{
      data = (event.clipboardData.getData("text").replace(/\n/g, ''));
      insertValuePaste(data, this.operatorEditorEmelent);
    }

  }

  itemSelectedMetion(event:any){
    let selObj =  window.getSelection();
    if (selObj && selObj.rangeCount > 0) {
      var anchorNode = selObj.anchorNode;
      let item:any = {};
      item.label = event.label;
      item.type = event.type;
      item.value = event.label;
      if(item.type == OPERATOR_TYPE.COLUMN){
        item.value = event.value;
      }
      var span = createOperator(item);
      var empty = document.createTextNode('\u00A0'); 
      var spanEpmty = document.createTextNode(' '); 

      if( event.type == OPERATOR_TYPE.OPERATOR_CUSTOM){
        spanEpmty = document.createTextNode('('); 
      }
      var nexNode =  anchorNode?.nextSibling;
      if(anchorNode?.nodeName == "#text"){
        let searchStringLength = event.pos - event.startPos;
        let nodeTextValue = anchorNode.nodeValue ?? "";
        nodeTextValue = nodeTextValue?.substring(0, nodeTextValue?.length - searchStringLength);
        anchorNode.nodeValue = nodeTextValue;
      }
      if(nexNode) {
        nexNode?.parentNode?.insertBefore(span, nexNode);
        nexNode?.parentNode?.insertBefore(empty, nexNode);
        nexNode?.parentNode?.insertBefore(spanEpmty, nexNode);

      }else{
        anchorNode?.parentNode?.appendChild(span);
        anchorNode?.parentNode?.appendChild(empty);
        anchorNode?.parentNode?.appendChild(spanEpmty);
     
      } 
      let spanEpmtyHTML = (<HTMLInputElement><unknown>spanEpmty);
      if( item.type == OPERATOR_TYPE.OPERATOR_CUSTOM){
        setCaretPosition(spanEpmtyHTML,1,null);
      }else{
        setCaretPosition(spanEpmtyHTML,0,null);
      }
    }
  }
  onGenerateHtml() {
    let html = createXNode();
    this.operators.forEach((item: any, i: number) => {
      if(item.type == OPERATOR_TYPE.OPERATOR_CUSOR) {
        generateHtmlCusor(html, i);

      } else if(item.type == OPERATOR_TYPE.OPERATOR) {
        let nextItem;
        if((i + 1) < this.operators.length){ nextItem = this.operators[i+1];}
        generateHtmlOperator(html,item , i, nextItem);

      }
      else {
        generateHtml(html, item, i);
      }
    });
    this.operatorHtml = this._sanitizer.bypassSecurityTrustHtml(html.innerHTML);
  }
}

