import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { bycd, TreeNode } from '../models/common-model';
import { FolderService } from './modules/folder.service';
import Utils from '../util/utils';
import TreeUtils from '../util/treeUtils';

interface NodeTrans {
  id: string;
  nodes: TreeNode[];
}

@Injectable({
  providedIn: 'root'
})
export class NodeTransferringService {

  // For update origin TreeNode
  public loadedNodeDataSub: BehaviorSubject<TreeNode | null>;
  public loadedNodeData: Observable<TreeNode | null>;

  // Trigger update for target node
  public pipelineSubject: BehaviorSubject<NodeTrans>;
  public pipeline: Observable<NodeTrans>;

  // Data check
  public checkSub: BehaviorSubject<string | null>;
  public check: Observable<string | null>;

  private onProcessNode: string[] = [];
  constructor(
    private folderService: FolderService
  ) { 
    this.pipelineSubject = new BehaviorSubject<NodeTrans>({id: "null", nodes: []});
    this.pipeline = this.pipelineSubject?.asObservable();
    this.loadedNodeDataSub = new BehaviorSubject<TreeNode | null>(null);
    this.loadedNodeData = this.loadedNodeDataSub.asObservable();
    this.checkSub = new BehaviorSubject<string | null>(null);
    this.check = this.checkSub.asObservable();
  }

  clearTrigger() {
    this.pipelineSubject.next({id: "reset-command", nodes: []});
  }

  async getNodesByLevel (node: TreeNode, level: number, isTemplate: boolean) {
    // Cancel action if node is on data fetching process
    if(this.onProcessNode.includes(node.id ?? ""))
      return [];
    else
      this.onProcessNode.push(node.id ?? "");

    if(Utils.isNullOrEmpty(node.id)) return [];
    let result: TreeNode[] = [];
    var res = await this.folderService.getDatasourceFolderStructByLevel([node.id ?? ""], level, true, isTemplate);
    // Config level of tree
    const hierarchyCD: bycd[] = [
      { cd: 'foldercd', labelcd: 'foldername', showIcon: true, },
      { cd: 'datasourcecd', labelcd: 'dsname', checkbox: true, isLoaded: true, isLastestNode: true},
      { cd: 'columnname', labelcd: 'displayname', isLoaded: true }
    ]
    // Render folder node
    result = TreeUtils.arrayToTreeNode(res, hierarchyCD, [], undefined);
    this.onProcessNode = this.onProcessNode.filter(ele => ele !== node.id);
    return result;
  }

  async getFolderWithKeywork (searchText: string, isSupporterAdmin: boolean, isTemplate: boolean) {
    if(Utils.isNullOrEmpty(searchText)) return [];
    let result: TreeNode[] = [];
    var res = await this.folderService.getStructByKeywork(searchText.trim().toUpperCase(), isSupporterAdmin, isTemplate);
    // Config level of tree
    const hierarchyCD: bycd[] = [
      { cd: 'foldercd', labelcd: 'foldername', showIcon: true, },
      { cd: 'datasourcecd', labelcd: 'dsname', checkbox: true, isLoaded: true, isLastestNode: true},
      { cd: 'columnname', labelcd: 'displayname', isLoaded: true }
    ]
    // Render folder node
    result = TreeUtils.arrayToTreeNode(res, hierarchyCD, [], undefined);
    return result;
  }
}