import { ChangeDetectorRef, Component, ElementRef, OnDestroy, OnInit, QueryList, ViewChild, ViewChildren, ViewEncapsulation } from '@angular/core';
import { ProcessLoadingService } from '../../../app/services/loading.service';
import { COMMON_TEXT, DATA_UPDATE, BUTTON } from '../../../app/const/text-common';
import { ButtonType, DeviceType, GraphType, InputType, WidgetSelectDataType } from '../../../app/enum/common-enum';
import { DetectDeviceService, IDeviceType } from '../../../app/services/detect-device.service';
import * as gridster from 'angular-gridster2';
import { WidgetService } from '../../../app/services/modules/widget.service';
import { Router } from '@angular/router';
import { LocalStorageHelper } from '../../../app/_helper/local-storage.helper';
import { ROUTE_PATH } from '../../../app/const/route-path';
import { cloneDeep, orderBy } from 'lodash';
import { FilterConditionRequest, WidgetResultRequest } from '../../../app/models/request/widget.dto';
import { WidgetResultService } from '../../../app/services/modules/widget-result.service';
import { DSCUSTOM, DSTARGET, FooterName, SummaryColumnName } from '../../../app/const/const';
import { isOldTemplate } from '../../../app/_helper/helper';
import { DashboardService } from '../../../app/services/modules/dashboard.service';
import { TreeNode, TreeViewInputParams } from '../../../app/models/common-model';
import { SearchLogService } from 'src/app/services/modules/search-log.service';
import { TemplateViewLogService } from 'src/app/services/modules/template-view-log.service';
import { SAUCER_LOG_ACTION, SaucerLogService } from 'src/app/services/saucer-logs/saucer-log.service';
import { CONTENT_LOG } from 'src/app/config/saucer-log.config';

@Component({
  selector: 'pivot-list-widget-template',
  templateUrl: './list-widget-template.component.html',
  styleUrls: ['./list-widget-template.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ListWidgetTemplateComponent implements OnInit, OnDestroy {

  COMMON_TEXT = COMMON_TEXT;
  DATA_UPDATE = DATA_UPDATE;
  BUTTON = BUTTON;
  isDarkMode: boolean = true;
  widgets: any[] = [];
  options!: gridster.GridsterConfig;
  DeviceType = DeviceType;
  deviceType!: string;
  allWidgets: any[] = [];
  currentWidgets: any[] = [];
  searchText: string = '';
  periods: any[] = [];
  widgetHistorys: any[] = [];
  allWidgetTemplate: any[] = [];
  filterCondition: FilterConditionRequest = new FilterConditionRequest();
  // viewchild
  @ViewChild('op', { static: false }) listOp: any;
  @ViewChild('grister', { read: ElementRef, static: false }) grister: ElementRef|undefined;
  @ViewChildren('frequentlyTooltips') frequentlyTooltips: QueryList<ElementRef>;
  @ViewChildren('searchListTooltips') searchListTooltips: QueryList<ElementRef>;
  @ViewChildren('dashboardNewTooltips') dashboardNewTooltips: QueryList<ElementRef>;
  @ViewChildren('historyTooltips') historyTooltips: QueryList<ElementRef>;
  // Pagination by scroll
  pageSize: number = 20; // The number of rows to be displayed per page (10 rows per page).
  rangePageSizes: number[] = [10, 20, 50];
  readonly rowHeight: number = 295; // The height of each row in pixels (295px per row).
  readonly buffer: number = 10;  // The number of additional rows loaded to create a smooth scrolling experience, used to pre-load data during scrolling (10 extra rows).
  currentPage: number = 1; // The index of the page currently being viewed by the user (starting from page 1).
  previousPage: number = 1; // The index of the page that the user viewed before the current page (used to track the previously viewed page).
  // input description params
  inputDesParams: any = {
    type: InputType.text,
    validate: false,
    borderFill: true,
    maxLength: 200,
    disabled: false
  };
  // key để lưu local store
  keyLocalStorage: string = 'widgetHistory';
  GraphType: any = GraphType;
  // danh sách key search
  searchList: string[] = [];
  // danh sách mục thường xuyên sử dụng
  frequentlyUseds: string[] = [];
  // danh sách dashboard new
  dashboardNews: any[] = [];
  // danh sách tất cả dashboard template
  dashboards: any[] = [];
  buttonType = ButtonType;

  widgetCds: string[] = [];
  // status hiển thị skeleton cho mục thường xuyên sử dụng
  showSkeletonFrequentlyUseds = true;
  // status hiển thị skeleton cho mục dashboard new.
  showSkeletonDashboardNews = true;
  // status hiển thị skeleton cho list keys search được search nhiều nhất
  showSkeletonSearchList = true;
  // status hiển thị skeleton cho list widget mới truy cập gần đây
  showSkeletonWidget = true;
  // status hiển thị dashboard dialog
  isDisplayDashboardDlg: boolean = false;
  // danh sách widget được hiển thị trên grid
  visibleWidgets:  any[] = [];
  // lưu danh sách các page đã click qua
  pageUseds: number[] = [];
  // isSearchList = true searh theo keys isSearchList = false thì search theo dashboard or widgetcd
  isSearchList: boolean = false;
  // isDashboard = true search data theo dashbboardCD isDashboard = false thì search theo keys or widgetcd
  isDashboard: boolean = false;
  keySearchDefault: string = '事故';
  isLoadWidgetHistory: boolean = false;
  // Mảng kiểm tra tràn của frequently
  isFrequentlyTextOver: boolean[] = [];
  // Mảng chứa nội dung tooltip cho từng phần tử trong frequently
  frequentlyTooltipText: string[] = [];
  // Mảng kiểm tra tràn của SearchList
  isSearchListTextOver: boolean[] = [];
  // Mảng chứa nội dung tooltip cho từng phần tử trong SearchList
  searchListTooltipText: string[] = [];
  // Mảng kiểm tra tràn của dashboardNew
  isDashboardNewTextOver: boolean[] = [];
  // Mảng chứa nội dung tooltip cho từng phần tử trong dashboardNew
  dashboardNewTooltipText: string[] = [];
  isHistoryTextOver: boolean[] = []; // Mảng kiểm tra tràn của History
  historyTooltipText: string[] = []; // Mảng chứa nội dung tooltip cho từng phần tử trong History
  dashboardTreeData: TreeViewInputParams;
  dashboardNodeSelected: any = null;
  // biến để kiểm tra điều kiện có call api khi click vô page hay không isCallApi = true thì sẽ call api khi click qua trang khác.
  //  isCallApi = false không call api lấy data đã load về trước đó
  isCallApi: boolean = false;

  constructor(private loadingService: ProcessLoadingService,
              private detectDeviceService: DetectDeviceService,
              private widgetService: WidgetService,
			  private routerNavigate: Router,
			  private widgetResultService: WidgetResultService,
			  private dashboardService: DashboardService,
			  private searchLogService: SearchLogService,
			  private templateViewLogService: TemplateViewLogService,
			  private cdr: ChangeDetectorRef,
        private saucerLogService: SaucerLogService
  ) {
    this.detectDeviceService.currentDevice.subscribe((device: IDeviceType) => {
			if (device) this.deviceType = device.type;
		});
	
    //Log
    this.saucerLogService.action({}, { 
      action: SAUCER_LOG_ACTION.MENU_TEMPLATE.VIEW
    });
  }

	async ngOnInit() {
		this.loadingService.isLoading.emit(true);
		this.isLoadWidgetHistory = false;
		// lấy danh sách thường xuyên tìm kiếm
		this.getFrequentlySearches();
		// set option cho grister
		this.setGristerOptions()
		// get list case search trước đó
		this.searchList = this.getArrayHistoryFromLocalStorage('listSearchKey');
		if(!this.searchList || this.searchList.length == 0) {
			this.searchList = [];
			this.searchList.push(this.keySearchDefault);
		}
		this.showSkeletonWidget = true;
		//lấy tất cả widget từ template có trong dashboard
		await Promise.all([
			this.widgetService.fetchWidgetTemplates(),
			// lấy danh sách dashboard map theo trạng thái isNew
			this.getListDashboardNew()
		]).then(async res => {
			this.widgets = [];
			const [widgetData] = res;
			if(widgetData.statuscode == 200) {
				let allData = widgetData.data || [];
				allData = this.mapWidgetIsNew(allData);
			    // sort widget theo trạng thái isNew
				allData = cloneDeep(this.sortData(allData));
				this.allWidgets = cloneDeep(allData);
				this.templateViewLogGetByStaff();
				// map chart type và icon chart để hiển thị bên trong combobox search
				this.mapAllWidget();
				await this.searchWidgetSkeleton();
			}
		});
	}

	// tìm kiếm danh sách widget theo key search bật, tắt skeleton khi load data
	async searchWidgetSkeleton(isHistory: boolean = false, isFrequentlyUsed: boolean = false) {
		this.showSkeletonWidget = true;
		await this.onSearchWidgets(isHistory, isFrequentlyUsed);
		this.showSkeletonWidget = false;
	}

	// map chart type và icon chart để hiển thị bên trong combobox search
	mapAllWidget() {
		// map chart type và icon chart để hiển thị bên trong combobox search
		this.allWidgets = this.allWidgets.filter(s =>s.widgetname)?.map(s => {
			s.type = s.chartType;
			s.src = this.getChartIcon(s);
			s.name = s.widgetname;
			return s
		});
		this.currentWidgets = cloneDeep(this.allWidgets);
	}

	// phân trang khi data vượt quá page size
	async paginateData(page: any) {
	  if(!page) return;
		// set page size nếu current page được reset về 1 và page size thay đổi thì gán lại page size
		if(this.pageSize != page.pageSize) {
			this.pageSize = cloneDeep(page.pageSize);
			// reset page click
			this.pageUseds = [];
			this.resetPage();
			this.isCallApi = true;
		}
		else if(this.currentPage != page.currentPage) {
			// gán lại current page
			this.currentPage = cloneDeep(page.currentPage);
			this.isCallApi = true;
		}

	  	//Lấy thêm các phần tử mới cho visibleWidgets
		let nextItems: any[] = [];
		this.visibleWidgets = [];
		 // gán lại previousPage
		 if(this.currentPage > 1) this.previousPage = this.currentPage - 1;
		 this.showSkeletonWidget = true;
		 // nếu gage đó đã call api lấy data rồi thì không call nữa lấy data đã có để paging
		 let pageClick = this.pageUseds?.find(page => page == this.currentPage);
		 if(pageClick) {
			 let offsetRows = (this.currentPage - 1) * this.pageSize;
			 // lấy danh sách widget để thiển thị theo page
			 this.visibleWidgets = cloneDeep(this.widgets)?.slice(offsetRows, this.currentPage * this.pageSize);
			 setTimeout(() => {
				 this.showSkeletonWidget = false;
			 }, 300);
			 return;
		 }
		 else
		 {
			if(this.isCallApi) {
				this.isCallApi = false;
				// Lấy dữ liệu cho trang hiện tại
				let items = this.fetchViewportData();
				if (items?.length > 0) {
					this.showSkeletonWidget = true;
					let allData = await this.getListWidgetByPageSize(items);
					let widgetNexts = this.mapWidgetIsNew(allData || []);
					widgetNexts = this.sortData(widgetNexts);
					nextItems = widgetNexts;
					this.visibleWidgets = nextItems;
					this.widgets = this.widgets?.concat(nextItems);
					this.widgets = cloneDeep(this.widgets);
					if(this.currentPage !=  this.previousPage)
						this.showSkeletonWidget = false;
				}
				this.pageUseds.push(this.currentPage);
			}
		 }
	}

	ngOnDestroy(): void {
		// nếu pathname không phải màn hình list template hoặc màn hình create template thì clear list widget filter về []
		let pathname = window.location.pathname;
		if (!pathname?.includes(ROUTE_PATH.LIST_ITEM_TEMPLATE) &&
		  !pathname?.includes(ROUTE_PATH.CREATE_WIDGET_TEMPLATE)) {
		  this.widgetService.setListWidgetTemplateFilter([], []);
		}
	}

	// lấy danh sách thường xuyên tìm kiếm
	getFrequentlySearches() {
		this.searchLogService.getFrequentlySearches()
							.then(res => { 
								this.frequentlyUseds = res;
								this.showSkeletonFrequentlyUseds = false;
							});

	}

	// sort data giảm dần theo latestdate
	sortData(data: any[] = []) {
		if(!data || data.length == 0) return data;
		if(data[0]['latestdate']) {
			return orderBy(data, ['latestdate'], ['desc']);
		} else {
			return  orderBy(data, item => new Date(item.upddate || item.insdate), ['desc']);
		}
	}

	// map thêm trạng thái isNew cho widget dựa theo (startDate or Upddate)
	mapWidgetIsNew(widgets: any[] = []) {
		return widgets.map(widget => {
			widget.isNew = (!widget.publicsdate) ? false : (isOldTemplate(widget.latestdate) ? false : true );
			return widget;
		})
	}

	// lựa chọn widget để tìm kiếm
	async onSelectedWidgetFilter(widget: any) {
		this.searchList = [];
		if(widget?.value?.name) {
			this.searchText = widget.value.name;
		}
		this.listOp.hide();
		// tìm kiếm list widget theo widget name vừa được chọn
		await this.searchWidgetSkeleton();
		// sau khi search gán lại allwidget bằng currentWidgets trước đó
		this.allWidgets = cloneDeep(this.currentWidgets);
	}

	// lấy data theo pageSize
	fetchViewportData() {
		let slicedRows;
		if (this.currentPage == 1) {
		  slicedRows = cloneDeep(this.widgetCds?.slice(0, this.pageSize));
		} else {
		  let offsetRows = (this.currentPage - 1) * this.pageSize;
		  slicedRows = cloneDeep(this.widgetCds?.slice(offsetRows, this.currentPage * this.pageSize));
		}
		return slicedRows;
	}

	// reset default (currentPage, previousPage)
	resetPage() {
		this.currentPage = 1;
		this.previousPage = 1;
		this.widgets = [];
		this.visibleWidgets = [];
	}

	// on input search key
	onInputSearch(searchValue: any) {
		this.allWidgets = cloneDeep(this.currentWidgets);
		this.searchText = searchValue?.target?.value || '';
		if(this.searchText != '') {	
			// tìm kiếm theo widget name
			let txt = this.searchText?.toString()?.toLowerCase()?.trim();
			this.allWidgets = this.allWidgets.filter(s=> s.contents?.toLowerCase()?.includes(txt));
		}
		else
		{
			this.allWidgets = cloneDeep(this.currentWidgets);
		}
	}

	//#region Filters widgets based on the presence of specific tags in their descriptions.
	removeInvalidTags(input: string): string[] {
		if (!input.includes('#')) return [];
		// Tách chuỗi theo dấu '#' và loại bỏ phần đầu nếu không có dấu #
		return input
			.split('#') // Tách chuỗi theo dấu '#'
			.slice(1) // Bỏ qua phần đầu chuỗi nếu không có dấu '#'
			.filter(Boolean); // Loại bỏ các phần tử rỗng hoặc falsy
	}
	
	// Hàm kiểm tra text
	isTextValid(input: any, text: string): boolean {
		let inputReq: string = input?.toString()?.toLowerCase()?.trim() ?? "";
		const validTags = this.removeInvalidTags(inputReq); // Return array các tag hợp lệ
		return this.isSubstringInArray(text, validTags);
	}

	isSubstringInArray(input: string, array: string[]): boolean {
		return array.some(item => item.includes(input));
	}

	// clear search value
	clearSearchData() {
		this.searchList = []
		this.searchText = '';
	}

	// tìm kiếm widget theo widget code
	async searchByWidgetCd(widgetCd: string) {
		if(widgetCd) {
			this.showSkeletonSearchList = false;
			this.resetPage();
			this.showSkeletonWidget = true;
			this.isSearchList = false;
			this.isDarkMode = false;
			await this.findListWidget([widgetCd]);
			this.widgetCds = [widgetCd];
			this.setVisibleWidgets();
			this.showSkeletonWidget = false;
		}
	}

	// get widget data
	async getWidgetData(widgetcd: string) {
		this.clearSearchData();
		this.pageUseds = [];
		this.saveArrayHistoryToLocalStorage('listSearchKey', []);
		this.loadingService.isLoading.emit(true);
		if(widgetcd) {
		   await this.searchByWidgetCd(widgetcd)
		}
		this.showSkeletonWidget = false;
		this.loadingService.isLoading.emit(false);

		//Log
		this.saucerLogService.action({
			content: CONTENT_LOG.HISTORY_SELECTION + ": " + this.widgetHistorys.find(x => x.widgetcd == widgetcd)?.widgetname ?? ""
		}, { 
			action: SAUCER_LOG_ACTION.MENU_TEMPLATE.SELECT_HISTORY
		});
	}

	async onChangeTagItem(item: any) {
		this.clearSearchData();
		this.visibleWidgets = [];
		this.pageUseds = [];
		this.searchList.push(item);
		await this.searchWidgetSkeleton(false, true);
		this.allWidgets = cloneDeep(this.currentWidgets);

		//Log
		this.saucerLogService.action({
			content: CONTENT_LOG.FREQUENTLY_USED_SELECTION + ": " + item
		}, { 
			action: SAUCER_LOG_ACTION.MENU_TEMPLATE.SELECT_FREQUENTLY_USED
		});
	}

	async onChangeDashboardItem(dashboardcd: any, isShowMore: boolean) {
		if(!dashboardcd) return;
		this.dashboardNodeSelected = dashboardcd;
		this.setExpandedDashboardTreeData(dashboardcd);
		this.pageUseds =[];
		this.saveArrayHistoryToLocalStorage('listSearchKey', []);
		this.loadingService.isLoading.emit(true);
		this.showSkeletonWidget = true;
		this.clearSearchData();
		this.showSkeletonSearchList = false;
		this.isSearchList = false;
		this.isDashboard = true;
		this.resetPage();
		await this.findListWidget([dashboardcd], true);
		if(this.widgets?.length > 0) this.widgetCds = this.widgets?.map(w => w.widgetcd) as string[];
		this.setVisibleWidgets();
		this.showSkeletonWidget = false;
		this.allWidgets = cloneDeep(this.currentWidgets);
		this.loadingService.isLoading.emit(false);

		//Log
		if(isShowMore) {
			this.saucerLogService.action({
				content: JSON.stringify({
					dashboardcd: dashboardcd,
				})
			}, { 
				action: SAUCER_LOG_ACTION.MENU_TEMPLATE.TEMPLATE_DIALOG.SELECT_TEMPLATE_DIALOG
			});
		} else {
			this.saucerLogService.action({
				content: JSON.stringify({
					dashboardcd: dashboardcd,
					dashboardname: this.dashboards.find(x => x.dashboardcd == dashboardcd)?.dashboardname
				})
			}, { 
				action: SAUCER_LOG_ACTION.MENU_TEMPLATE.SELECT_LATEST_PUBLISHED
			});
		}

	}

	// Hàm kiểm tra tràn văn bản và hiển thị tooltip
	checkTextOverflow(type: number) {
		// list danh sách cần show tooltip
		const tooltips = [
		  // tooltip phần tìm kiếm thường xuyên
		  { event: this.frequentlyTooltips, flag: this.isFrequentlyTextOver, text: this.frequentlyTooltipText },
		  // tooltip phần search list
		  { event: this.searchListTooltips, flag: this.isSearchListTextOver, text: this.searchListTooltipText },
		  // tooltip phần dashboard new
		  { event: this.dashboardNewTooltips, flag: this.isDashboardNewTextOver, text: this.dashboardNewTooltipText },
		  // tooltip phần danh sách widget truy cập gần nhất
		  { event: this.historyTooltips, flag: this.isHistoryTextOver, text: this.historyTooltipText }
		];

		// lây tooltip theo type 0: tìm kiếm thường xuyên, 1: search list, 2: dashboard new, 4: phần danh sách widget truy cập gần nhất
		let tooltip = tooltips[type];
		if (!tooltip || !tooltip?.event) return;

		tooltip?.event?.forEach((container: ElementRef, index: number) => {
		  if(!container) return;
		  const divElement = container.nativeElement;
		  // isOverflowing = true bật tooltip, nếu isOverflowing = false tắt tooltip
		  const isOverflowing = divElement.scrollWidth > divElement.clientWidth || divElement.scrollHeight > divElement.clientHeight;
		  tooltip.flag[index] = isOverflowing;
		  // gán tooltip text nếu isOverflowing = true ngược lại gắn là rỗng
		  tooltip.text[index] = isOverflowing ? divElement.innerText : '';
		});
	}

	// set lại width, height, position cho widget
	widgetResize(event: any) {
		event?.itemComponent.height 
		let position = JSON.stringify({ "x": event?.itemComponent?.$item?.x, "y": event?.itemComponent?.$item?.y, 
				"rows": event?.itemComponent?.$item?.rows, "cols": event?.itemComponent?.$item?.cols, "width": event?.item?.width, "height": event?.item?.height })
		if (this.visibleWidgets?.length > 0) {
			this.visibleWidgets?.map((w: any) => {
				if (w.widgetcd == event.item?.widgetcd) {
					w.widgetPosition = position;
					w.type = event?.item?.type;
					w.width = (event?.itemComponent?.width > 20) ? event?.itemComponent?.width - 20 : 500;
					w.height = (event?.itemComponent?.height > 80) ? event?.itemComponent?.height  - 80: 500;
					w.rows = event?.itemComponent?.$item?.rows;
					w.cols = event?.itemComponent?.$item?.cols;
					w.x = event?.itemComponent?.$item?.x;
					w.y = event?.itemComponent?.$item?.y;
				}
			});
		}
	}

	// set option default cho grister
	setGristerOptions() {
		this.options = {
			compactType: gridster.CompactType.CompactUpAndLeft,
			gridType: gridster.GridType.ScrollVertical,
			defaultItemRows: 11,
			defaultItemCols: 14,
			displayGrid: gridster.DisplayGrid.None,
			disableWindowResize: false,
			enableEmptyCellClick: false,
			enableEmptyCellContextMenu: false,
			enableEmptyCellDrop: true,
			enableEmptyCellDrag: false,
			enableOccupiedCellDrop: true,
			emptyCellDragMaxCols: 50,
			emptyCellDragMaxRows: 50,
			margin: 10,
			minCols: 15,
			minRows: 12,
			maxRows: 1000,
			maxCols: 40,
			minItemRows: 9,
			minItemCols: 12,
			maxItemRows: 50,
			draggable: {
				enabled: false,
				ignoreContent: false,
				dragHandleClass: 'gridster-item-content',
			},
			resizable: {
				enabled: false,
			},
			pushItems: true,
			mobileBreakpoint: 640
		};

		if (this.deviceType === DeviceType.MOBILE || this.deviceType === DeviceType.TABLET) {
			this.options.mobileBreakpoint = 9999;
			this.options.keepFixedHeightInMobile = true;
			this.options.fixedRowHeight = 56;
			this.options.outerMarginBottom = 90;
		}
	}

	async openWidgetDetail(widget: any) {

		let existWidget = this.widgetHistorys?.find(s => s.widgetcd == widget.widgetcd);
		if(!existWidget) {
			this.widgetHistorys.unshift(widget);
			this.loadingService.isLoading.emit(true);
			await this.insertOrUpdateTemplateViewLog();
		}
		this.widgetService.setWidgetDetail(widget);
		this.widgetService.setListWidgetTemplateFilter(this.widgets, this.searchList);
		this.routerNavigate.navigate([LocalStorageHelper.getUrl(ROUTE_PATH.CREATE_WIDGET_TEMPLATE)]);

		//Log
		this.saucerLogService.action({
			content: JSON.stringify({
				widgetId: widget.widgetId,
				widgetcd: widget.widgetcd,
				widgetName: widget.widgetName,
				isNew: widget.isNew,
			})
		}, { 
			action: SAUCER_LOG_ACTION.MENU_TEMPLATE.SELECT_TEMPLATE
		});
	}

	clickInputSearch(event: any) {
		this.listOp.toggle(event)
	}

	// lấy danh sách 4 dashboard mới nhất
	async getListDashboardNew() {
		await this.dashboardService.getListDashboardTemplateNew().then(res => {
			if(res.statuscode == 200) {
				// lấy danh sách dashboard order by desc theo upddate
				this.dashboards = res.data || [];
				this.dashboardNews = res.data || [];
				// hiển thị trạng thái new
				this.dashboardNews = this.dashboardNews?.map(db => {
					db.isNew = (isOldTemplate(db.upddate)) ? false : true;
					return db;
				});
				// lấy 4 dashboard để hiển thị lên mục 最新公開
				this.dashboardNews = this.dashboardNews.splice(0, 4) || [];
			}
			this.showSkeletonDashboardNews = false;
		})
	}

	// lấy widget result cho list widget
	async getWidgetResultData(widgetList: any[]) {
		this.filterCondition.isFullData = false;
		let widgetCds = widgetList?.map( x => x.widgetcd) as string[];
		let requestBody = {
			widgetCds: widgetCds,
			selectType: WidgetSelectDataType.SPECIALCASE,
			isTemplate: true,
			filterCondition: this.filterCondition
		} as WidgetResultRequest

		let widgetResultData:  any[] = [];
		await this.widgetResultService.getByWidgetCds(requestBody)
		.then(res => {
			if (res.statuscode == 200) {
				widgetResultData = res.data || [];
			}
		});
		return widgetResultData;
	}

	// map data for widget
	async mapWidgetInfor(widgetList: any[]) {
		if(widgetList?.length == 0) return [];
		let widgetResultData = await this.getWidgetResultData(widgetList);
		//map widget
		widgetList = widgetList?.map((widget: any) => {
			let widgetResult = widgetResultData.filter((x:any) => x.widgetcd == widget.widgetcd).pop();
			const itemFind = widgetList?.find(w => w.widgetcd === widget.widgetcd);
			widget.sortcoltype = itemFind?.sortcoltype;
			widget.type = itemFind?.charttype;
			widget.name = itemFind?.widgetname;
			widget.widgetId = itemFind?.id || "";
			widget.id = itemFind?.id || ""
			widget.widgetId = widget.id;
			widget.widgetcd = widget.widgetcd;
			widget.widgetName = widget.widgetname;
			widget.datasourceCd = widget.widgetdetails?.find((s: any)=>s.datasourcecd != DSTARGET 
				&& s.datasourcecd != DSCUSTOM && s.datasourcecd != FooterName &&  s.datasourcecd != SummaryColumnName )?.datasourcecd;
			widget.widgetConfig = widget.widgetconfigs;
			widget.isLoadWidgetResultComplete = true;
			widget.widgetResult = widgetResult?.result;
			return widget;
		})
		widgetList = widgetList.filter(s => s.widgetResult);
		widgetList = this.sortData(widgetList);
		return widgetList;
	}

	async onKeyupInputData(event: any) {
		if(!this.searchText && !this.searchList.length) return;
		if(event.code == 'Enter') {
			this.listOp.hide();
			this.pageUseds = [];
			this.searchWidgetSkeleton();
			this.allWidgets = cloneDeep(this.currentWidgets);
		}
	}

	// tìm kiếm danh sách widget
	async findListWidgetBySearchText() {
		this.pageUseds = [];
		await this.searchWidgetSkeleton();
	}

	async onSearchWidgets(isHistory: boolean = false, isFrequentlyUsed: boolean = false) {
		//Log

		if(!!this.searchText) {
			this.saucerLogService.action({
				content: CONTENT_LOG.SEARCH_FOR_TEMPLATES + ": " + (this.searchText ?? "")
			}, { 
				action: SAUCER_LOG_ACTION.MENU_TEMPLATE.SEARCH_FOR_TEMPLATES
			});
		}

		if(!this.searchText && this.searchList.length == 0) return;

		if(!this.searchText && this.searchList.length == 0 || this.inputDesParams.readonly) return;
		let widgetCds: string[] = [];
		this.showSkeletonWidget = true;
		this.resetPage();
		if(!isHistory) {
			let findItem = this.searchList.find(text => text == this.searchText);
			if(!findItem && this.searchText != '' && !isFrequentlyUsed) this.searchList.push(this.searchText);
		}
		else this.searchList = [];
		if(this.searchList.length == 0 ) widgetCds = cloneDeep(this.currentWidgets).map(w=>w.widgetcd);
		else {
			widgetCds = cloneDeep(this.currentWidgets).filter(obj =>
				this.searchList.every(keyword => obj.contents?.toLowerCase()?.includes(keyword?.toLowerCase()?.trim()))
			  ).map(s=>s.widgetcd);
		}

		this.inputDesParams.readonly = true;
		this.loadingService.isLoading.emit(true);
		this.widgetCds = widgetCds;
		this.saveArrayHistoryToLocalStorage('listSearchKey', this.searchList);
		this.isSearchList = true;
		this.isDashboard = false;
		let resultData = await this.getListWidgetByPageSize(cloneDeep(widgetCds)?.splice(0, this.pageSize));
		this.widgets = this.mapWidgetIsNew(resultData || []);
		this.widgets = this.sortData(this.widgets);
		this.widgets = cloneDeep(this.widgets);
		// set option for grister
		this.setGristerOptions();
		// set data cho danh sách widget hiển thị trên lưới
		this.setVisibleWidgets();

		if(this.searchText) {
			await this.insertSearchLog(false);
		}
		this.searchText = '';
		this.inputDesParams.readonly = false;
		this.loadingService.isLoading.emit(false);
	}

	async getListWidgetByPageSize(widgetCds: string[] = []) {
		let resultData: any[] = [];
		if(widgetCds.length == 0) return resultData;
		// Chia mảng thành 2 nửa theo page size
		const half1 = widgetCds?.slice(0, (this.pageSize/2));
		const half2 = widgetCds?.slice((this.pageSize/2), this.pageSize);
		await Promise.all([
			this.widgetService.getListWidgetBySearchText(half1, false),
			this.widgetService.getListWidgetBySearchText(half2, false)
		]).then(async res => {
			const [result1, result2] = res;
			resultData = [...result1?.data, ...result2?.data];
			return await this.mapWidgetInfor(resultData);
		});
		return resultData;
	}

	setVisibleWidgets() {
		this.visibleWidgets = [];
		this.pageUseds = [];
		this.pageUseds.push(1);
		this.visibleWidgets = cloneDeep(this.widgets);
	}

	async insertSearchLog(inuse: boolean) {
		const processedSearchList = [this.searchText];

		if(processedSearchList.length) {
			await this.searchLogService.insert(processedSearchList, inuse);
		}
	}

	async removeSearchItem(item: any) {
		if(this.searchList.length > 0) {
			this.searchList = this.searchList.filter(s => s!= item);
			if(this.searchText == item) this.searchText = '';
			if(this.searchList?.length > 0) await this.searchWidgetSkeleton();
		}
		this.saveArrayHistoryToLocalStorage('listSearchKey', this.searchList);

		this.showSkeletonSearchList = false;
	}

	// tìm kiếm danh sách widget thoả điều kiện
	async findListWidget(itemSearchs: any[], isDashboard: boolean = false) {
		await this.widgetService.getListWidgetBySearchText(itemSearchs, isDashboard).then(res => {
			if(res.statuscode == 200)   {
				this.widgets = this.mapWidgetIsNew(res.data || []);
				this.widgets = this.sortData(this.widgets);
			}
		});
		this.widgets = await this.mapWidgetInfor(this.widgets);
	}

	// get widget description
	getWidgetDescription(widgetCd: string = '') {
			let findItem: any = this.widgets.find((w: any) => w.widgetCd === widgetCd);
			return findItem && findItem.description ? findItem.description : "";
	}

	// get class for chart icon
	getChartIcon(widget: any) {
		let chartType = widget?.charttype;
		switch(chartType){
			case GraphType.NUMBER_CHART:
				return  'number-chart-icon';
			case GraphType.LINE_CHART:
				return 'line-chart-icon';
			case GraphType.TABLE_CHART:
				return 'table-chart-icon';
			case GraphType.DOUGHNUT_CHART:
				return 'doughnut-chart-icon';
			case GraphType.LINE_BAR_COMBINE_CHART:
				return 'combo-chart-icon';
			case GraphType.PIE_CHART:
				return 'pie-chart-icon';
			case GraphType.BAR_CHART_VERTICAL:
				return 'bar-chart-vertical-icon'
			case GraphType.BAR_CHART_HORIZONTAL: 
				return 'bar-chart-horizontal-icon'
			case GraphType.STACKED_BAR:
				return 'stacked-bar-chart-icon'
		}
		return 'table-chart-icon';
	}

	// save array widget history to localStorage
	saveArrayHistoryToLocalStorage(key: string, array: any[]) {
		localStorage.setItem(key, JSON.stringify(array));
	}

	// get array widget history from localStorage
	getArrayHistoryFromLocalStorage(key: string): any[] {
		const arrayString = localStorage.getItem(key) || '';
		return arrayString ? JSON.parse(arrayString) : [];
	}

	// show dashboard select dialog
	onShowDialogDashboardSelect() {
		this.isDisplayDashboardDlg = true;

		//Log
		this.saucerLogService.action({}, { 
			action: SAUCER_LOG_ACTION.MENU_TEMPLATE.SHOW_MORE
		});
	}

	async onSubmitDashboardSelect(data: any) {
		this.isDisplayDashboardDlg = false;
		if(!data) {
			return;
		}
		this.dashboardTreeData = data.treedata;
		// call function on change dashboard item để search tất cả các widget trong dashboard đó.
		await this.onChangeDashboardItem(data.nodeSelected, true);
		this.loadingService.isLoading.emit(false);
	}

	setExpandedDashboardTreeData(nodeSelected: string) {
		if(!this.dashboardTreeData) return;
		this.dashboardNodeSelected = nodeSelected;
		// nếu dashboard tree data có dashboard được selected thì expanded = true node cha của nó ngược lại set expanded = false
		this.dashboardTreeData.nodes?.forEach(node => {
			// set default là expanded = false cho tất cả các node
			node.expanded = false;
			if(node.nodes && node.nodes?.length > 0) {
				// tìm node child đang được select
			  	let findNodeSelect = node.nodes?.find(n => n.id == nodeSelected);
				// nếu có node child đang được select thì expaned nút cha 
				if(findNodeSelect) node.expanded = true;
				// tìm đúng node con set selected cho nó
				node.nodes.forEach(n => {
					// nếu là node selected thì set singleSelected cho nó
					if(n.id == nodeSelected) n.singleSelected = true;
					// set singleSelected cho các node còn lại = false
					else n.singleSelected = false;
				})
			}
			return node;
		});
	}

	//#region template view log
	async insertOrUpdateTemplateViewLog() {
		let widgetCds = this.widgetHistorys?.slice(0,6)?.map(item => item.widgetcd).join(",");
		if (widgetCds) {
			await this.templateViewLogService.insertOrUpdate(widgetCds);
		}
	}

	templateViewLogGetByStaff() {
		this.templateViewLogService.getByStaff().then(res => { 
			this.isLoadWidgetHistory = true;
			if (res && res.statuscode == 200) {
				let widgetCds: string[] = (res.data?.recentwidgetcds ?? "").split(",");
				this.widgetHistorys = this.allWidgets.filter(widget => widgetCds.includes(widget.widgetcd))
				.sort((a, b) => widgetCds.indexOf(a.widgetcd) - widgetCds.indexOf(b.widgetcd));
			} else {
				this.widgetHistorys = [];
			}
		});
	}

}
type bycd = { cd: string, labelcd: string, showIcon?: boolean, checkbox?: boolean }