<pivot-modal-template class="range-date-modal form-modal" [modalTemplate]="modalTemplate"
  (closeModalHandler)="closeFormModal()">
  <div class="range-date-modal-body" modal-body [ngClass]="isDisplayEdate ? 'two-col' : 'one-col'">
    <div class="controls">
      <div class="row" [ngClass]="{ 'column': isAllowNullEndDate && !isDisplayEdate }">
        <strong>開始日<span class="input-required">{{COMMON_TEXT.ASTERISK}}</span></strong>
        <div class="end-date-setting" *ngIf="isAllowNullEndDate && !isDisplayEdate" (click)="setEndDate()">終了日選択</div>
      </div>
      <div class="row">
        <p-calendar id="rangestartdate" class="range-date" [selectOtherMonths]="true" [inline]="true" (onShow)="monthChange()" 
        (onMonthChange)="monthChange()" (ngModelChange)="onChangeRangeDate($event,'startDate')"  [minDate]="minDate" [maxDate]="maxDate"
        [(ngModel)]="startDate" [readonlyInput]="true">
        </p-calendar>
      </div>
    </div>
    <div class="controls" *ngIf="isDisplayEdate">
      <div class="row" [ngClass]="{ 'column': isAllowNullEndDate && isDisplayEdate }">
        <strong>終了日<span *ngIf="!isAllowNullEndDate" class="input-required">{{COMMON_TEXT.ASTERISK}}</span></strong>
        <div class="end-date-setting" *ngIf="isAllowNullEndDate && isDisplayEdate" (click)="unsetEndDate()">終了日未定</div>
      </div>
      <div class="row">
        <p-calendar id="rangeenddate" class="range-date" [selectOtherMonths]="true" [inline]="true" (onShow)="monthChange()"  
        (onMonthChange)="monthChange()" (ngModelChange)="onChangeRangeDate($event, 'endDate')" [minDate]="minDate" [maxDate]="maxDate"
        [(ngModel)]="endDate" [readonlyInput]="true">
        </p-calendar>
      </div>
    </div>
  </div>
  <div class="folder-modal-bottom" modal-bottom>
    <div [ngClass]="{'visibility': !isValidDate}" class="error-message">{{errorMessage}}</div>
    <div class="right-pannel">
      <pivot-text-button (btnClick)="cancel()" [buttonType]="buttonType.SECONDARY" [buttonText]="BUTTON.CANCEL">
      </pivot-text-button>
      <pivot-text-button (btnClick)="save()" [disabled]="!isValidDate" [buttonType]="buttonType.SECONDARY" buttonText="確定">
      </pivot-text-button>
    </div>
  </div>
</pivot-modal-template>
