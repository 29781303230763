import { AfterViewInit, Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import bb, { bar, line } from "billboard.js";
import { GraphType } from 'src/app/enum/common-enum';
import { ChartBase } from '../chart.base';
import * as d3 from 'd3';
import Utils from "../../../../util/utils";


@Component({
  selector: 'pivot-line-bar-combine-chart',
  templateUrl: './line-bar-combine-chart.component.html',
  styleUrls: ['./line-bar-combine-chart.component.scss']
})
export class LineBarCombineChartComponent extends ChartBase {
  @Input() graphConfig: any;
  nodeId = "js-chart-linebar"

  colGraphType =  { Col : 0, Row: 1 }

  override ngOnInit() {
    super.ngOnInit();
    this.data = Utils.replaceYenCharacterOnChart(this.data);
  }

  generateChart(): void {

    const onrendered = ()=>{
      this.onRenderComplete.emit();
    }
    const onresize = ()=>{
      this.onStartRender.emit();
    }
    const renderChart = () =>{
      this.chart.flush();
    }
    // prepare fake type data for demo
    let first = this.data.columns[0][0]
    let a = this.data.columns.map(row => row[0])
    let graphConfig = this.graphConfig;
    this.data.columns = this.data.columns.map((row: any) =>   { 
      return  row.map((col: any) => (typeof col === "object" && col != null) ? col.value : col)
    })

    let types: any = {}
    if(graphConfig && graphConfig.length > 0){
      graphConfig.map((col: any) => {
        types[col.targetColumn] =  typeof col.graphType === "object" ? col.graphType.value == 0 ? bar() : line() 
                                  : col.graphType == 0 ? bar() : line();
      })

      let secondAxises :any= {};
      graphConfig.map((col: any)=> {
        if(col.isSecondAxis) {
          secondAxises[col.targetColumn] = "y2";
        }
      })


      this.chart = bb.generate({
        boost: {
          useWorker: false,
        },
        zoom: {
          enabled: this.zoomEnabled
        },
        legend: {
          show: this.data.legendShow
        },
        data: {
          columns: this.data.columns,
          types,
          axes: {...secondAxises}
        },
        axis: {
          rotated: false,
          x: {
            show: this.data.axisShow,
            type: "category",
            categories: this.data.categories,
            tick: {
              width: 80,
              multiline: false,
              culling: false,
              rotate : 40,
              autorotate: true
            }
          },
          y2: {
            show: Boolean(Object.keys(secondAxises).length),
            tick: {
              format: function(y: any) {
                return d3.format(",")(y);
              }
            }
          },
          y: {
            tick: {
              format: function(y: any) {
                return d3.format(",")(y);
              }
            }
          },
        },
        render: {
          lazy: true,
          observe: true
        },
        onrendered: onrendered,
        onresize: onresize,
        size: this.size,
        bindto: this.bindTo
      });
      this.onStartRender.emit();

      //delay render chart to have time for memory cleanup
      let timedelay = 100;
      if(this.data.columns.length>0){
        let dataCount= this.data.columns[0].length * this.data.columns.length;
        if(timedelay < dataCount*this.TIME_DELAY) timedelay= dataCount*this.TIME_DELAY;
        if(timedelay > 10000) timedelay= 10000;
      }
      setTimeout(renderChart, timedelay);

    }
  }

  getgraphTypeConfig(key: string, config: any[]) {
    config.forEach(element => {
      (element.v.v == key)
    });
  }
}
