import { AfterViewInit, Directive, Input,Output, OnChanges, OnDestroy, OnInit, SimpleChanges, EventEmitter } from '@angular/core';
import { PivotTableData } from '../../../../app/_helper/helper';
import { ChartData } from '../../../../app/models/common-model';
import { GraphType } from 'src/app/enum/common-enum';
import { isEqual } from 'lodash';


@Directive()
export abstract class ChartBase implements OnInit, AfterViewInit, OnChanges,OnDestroy {
  ngOnDestroy(): void {
    if (this.chart) {
      if (this.chart.destroy) {
        try{
          this.chart.destroy();
        }catch(e:any){
        
        }
              
      }

    }
    this.data = new ChartData();
  }

  @Input() data: ChartData;
  @Input() isStackedChartSetting: boolean;
  @Input() tableData: PivotTableData
  @Input() width: number | undefined;
  @Input() height: number | undefined;
  @Input() chartId: string | undefined;
  @Input() FirstName: string | undefined;
  @Output() onStartRender: any = new EventEmitter();
  @Output() onRenderComplete: any = new EventEmitter();
  bindTo: string = "";
  size: any = {};
  chart: any;
  viewInit = false;
  zoomEnabled: boolean = true;
  graphType = GraphType;
  isShowChartConfig: boolean = false;

  TIME_DELAY = 0.3;
  HEIGHT_PERIOD = 21;
  ngOnInit(): void {
    if (this.chartId) {
      this.nodeId = this.chartId
    } else {
      this.nodeId = this.nodeId + Number(new Date()).toString()
    }

    this.bindTo = "#" + this.nodeId;
    let width = this.width;
    let height = this.height == undefined? 0:  (this.height - this.HEIGHT_PERIOD);
    this.size = { width, height };
  }

  ngAfterViewInit(): void {
    this.viewInit = true;
    if (!this.data.columns) return;
    this.setConfig();
    if(this.data.columns.length <1000){
      
      this.generateChart();
    }
   
  }

  ngOnChanges(changes: SimpleChanges): void {
    for (let propName in changes) {
      if ((propName === 'height' && changes[propName].firstChange === false) || (propName === 'width' && changes[propName].firstChange === false)) {
        let width = this.width != undefined ? { width: this.width } : {};
        let height = this.height != undefined ? { height: (this.height - this.HEIGHT_PERIOD) } : {};
        this.size = { ...width, ...height };
        if (this.size) {
          if (this.chart) this.chart.resize(this.size);
        }
      }
    }
    if (this.viewInit) {
      if (!this.data.columns) return;
      this.setConfig()
      let data = changes['data'];
      if((data && !isEqual(data?.currentValue,data?.previousValue) && data.firstChange === false) || changes['isStackedChartSetting']) {
        if(this.data.columns.length <1000){
          this.generateChart();
        }
      }
    }
  }

  initData() {
    
  }

  setConfig() {
    const lengthCols = this.data.columns.length;
    if (!this.size?.width) {
      const height = lengthCols <= 50? 400:
                     (50 < lengthCols && lengthCols <= 100) ? 1000 :
                     (100 < lengthCols && lengthCols <= 200) ? 1200 :
                     (200 < lengthCols && lengthCols <= 300) ? 1400 :
                     (300 < lengthCols && lengthCols <= 400) ? 1900 :
                     (400 < lengthCols && lengthCols <= 600) ? 2400 :
                     (600 < lengthCols && lengthCols <= 800) ? 2900 :
                     (800 < lengthCols && lengthCols <= 1000) ? 3400 :
                     0;
      this.size = { height };
      if (lengthCols > 1000) {
      }
    }
  }

  abstract nodeId: string;
  abstract generateChart() : void
}
