import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { DateFormat } from 'src/app/enum/common-enum';
import { ZaitakuDetailDTO } from 'src/app/models/request/zaitaku-detail.dto';
import { ZaitakuUserInfoDTO, ZaitakuDTO } from 'src/app/models/request/zaitaku.dto';
import { API_APP, PIVOT_CLIENT_API_URL } from '../../config/app.config';
import { ApiService } from '../api.service';
import {ErrorHandleService} from '../error-handle.service';
import { ZaitakuSettingDTO } from 'src/app/models/request/zaitaku-setting.dto';
import { configColummModel, zaitakuSettingModel } from 'src/app/models/zaitaku-model';
import { groupBy, map, mapValues } from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import { MAXIMUM_MONTH } from 'src/app/const/const';
import { SAUCER_LOG_ACTION, SaucerLogService } from '../saucer-logs/saucer-log.service';
import { CONTENT_LOG, SCREEN_NAME } from 'src/app/config/saucer-log.config';
import { CK_REPORT_TEXT, COMMON_TEXT, SYNCDATA_STATUS } from 'src/app/const/text-common';
import { LIST_COMPARISON } from 'src/app/const/table-col-define';

@Injectable({ providedIn: 'root' })
export class ZaitakuService extends ApiService {
  constructor(http: HttpClient, router: Router, errorHandle: ErrorHandleService, private saucerLogService: SaucerLogService) {
    super(http, router, errorHandle);
  }

  async getAll(isFromZaitakuScreen: boolean = false): Promise<any> {
    let apiUrl = API_APP.ZAITAKU.CONTROLLER + API_APP.ZAITAKU.ALL;
    if(isFromZaitakuScreen === true) {
      apiUrl += "/true";
    } 
    else
      apiUrl += "/false";
    return await this.get(apiUrl);
  }

  async getDetailInfoByCondition(jigno: string, siteino: string) {
    let apiUrl = API_APP.ZAITAKU.CONTROLLER + API_APP.ZAITAKU.GET_DETAIL_INFO_BY_CONDITION;
    apiUrl = apiUrl.replace('{jigno}', jigno).replace('{siteino}', siteino);
    return await this.get(apiUrl);
  }

  async insert(bodyData: ZaitakuDTO, isDirectInput: boolean) {
    let apiUrl = API_APP.ZAITAKU.CONTROLLER + API_APP.ZAITAKU.INSERT;
    let resData = await this.post(apiUrl, [], bodyData);

    const apiPath = apiUrl.replace(PIVOT_CLIENT_API_URL, "");
    //Log
    const actionLog = isDirectInput ? SAUCER_LOG_ACTION.RETURN_HOME.SAVE_DIRECT_INPUT : SAUCER_LOG_ACTION.RETURN_HOME.CREATE_IMPORT_DATA;
    if (resData?.statuscode === 200) {
      this.saucerLogService.system({
        apiPath,
        body: JSON.stringify(bodyData),
        statusCode: resData.statuscode,
        content: JSON.stringify({
          'officeName': bodyData.officeNm,
          'sdate': bodyData.sDate? moment(bodyData.sDate).format(DateFormat.JP_FULL_LONG_DATE) : '',
          'edate': bodyData.eDate? moment(bodyData.eDate).format(DateFormat.JP_FULL_LONG_DATE) : ''
        })
        }, { action: actionLog });
    } else {
      this.saucerLogService.error({
        apiPath,
        statusCode: resData.statuscode,
        body: JSON.stringify(bodyData),
        content: SYNCDATA_STATUS.ERROR
      }, { action: actionLog });
    }

    return resData;
  }

  async insertDataTableUserInfo(bodyData: ZaitakuUserInfoDTO) {
    let apiUrl = API_APP.ZAITAKU.CONTROLLER + API_APP.ZAITAKU.INSERT_DETAIL_INFO;
    let resData = await this.post(apiUrl, [], bodyData);
    return resData;
  }

  async syncZaitaku(jigNo: string, siteiNo: string): Promise<any> {
    let bodyRequest = {
      jigNo,
      siteiNo
    };
    let apiUrl = API_APP.ZAITAKU.CONTROLLER + API_APP.ZAITAKU.SYNC_DATA;
    return await this.post(apiUrl, [], bodyRequest);
  }
  
  async syncZaitakuBySetting(bodyData: any): Promise<any> {
    let apiUrl = API_APP.ZAITAKU.CONTROLLER + API_APP.ZAITAKU.SYNC_DATA_BY_SETTING;
    return await this.post(apiUrl, [], bodyData);
  }
  

  async getZaitakuDetail(jigNo: string, siteiNo: string, sDate: string, eDate: string): Promise<any> {
    let bodyRequest = {
      jigNo,
      siteiNo,
      sDate,
      eDate
    };
    let apiUrl = API_APP.ZAITAKU.CONTROLLER + API_APP.ZAITAKU.DETAIL;
    let resData = await this.post(apiUrl, [], bodyRequest);
    return this.mapZaitakuDetail(resData);
  }

  async getZaitakuDetailOffset(jigNo: string, siteiNo: string, sDate: string, offset: number): Promise<any> {
    let bodyRequest = {
      jigNo,
      siteiNo,
      sDate,
      offset
    };
    let apiUrl = API_APP.ZAITAKU.CONTROLLER + API_APP.ZAITAKU.DETAIL_OFFSET;
    let resData = await this.post(apiUrl, [], bodyRequest);
    return this.mapZaitakuDetail(resData);
  }

  mapZaitakuDetail(data: any) {
    if (data.statuscode === 200 && data.data) {
      let listData: any[];
      listData = data.data;
      const newListData = listData.map(obj => {
        const { month, ...rest } = obj;
        return { month: moment(month).format(DateFormat.YEAR_MONTH), monthRaw: new Date(month) , ...rest };
      });
      return newListData;
    }
    return [];
  }

  async insertDetail(bodyData: ZaitakuDetailDTO[], changedProperties: any) {
    let apiUrl = API_APP.ZAITAKU.CONTROLLER + API_APP.ZAITAKU.INSERT_DETAIL;
    const apiPath = apiUrl.replace(PIVOT_CLIENT_API_URL, "");
    let resData = await this.post(apiUrl, [], bodyData);

    //Log
    const actionLog = SAUCER_LOG_ACTION.RETURN_HOME.SAVE_DIRECT_INPUT;
    if (resData.statuscode === 200) {
    this.saucerLogService.system({
      apiPath,
      statusCode: resData.statuscode,
      body: JSON.stringify(bodyData),
      content: JSON.stringify(changedProperties)
      }, { action: actionLog });
    } else {
      this.saucerLogService.error({
        apiPath,
        statusCode: resData.statuscode,
        body: JSON.stringify(bodyData),
        content: API_APP.ZAITAKU.INSERT_DETAIL + " " + SYNCDATA_STATUS.ERROR
      }, { 
        action: actionLog
      });
    }

    return resData;
  }

  getDetailContentLog(bodyData: any) {
    if(bodyData == null) return null;
    
    let conditions: any[] = JSON.parse(bodyData?.conditions) || [];
      
    return {
      'conditionName': bodyData?.conditionname,
      'sdate': bodyData?.sdate ? moment(bodyData?.sdate).format(DateFormat.JP_LONG_DATE) : '',
      'edate': bodyData?.edate ? moment(bodyData?.edate).format(DateFormat.JP_LONG_DATE) : '',
      'conditions': conditions?.map(s => ({
        'itemName': s?.itemname,
        'comparisonName': LIST_COMPARISON?.find(c=> c?.value == s?.comparison)?.name,
        'value': s?.val
        })
      )
    }
  }

  //#region setting
  async insertOrUpdateSetting(bodyData: ZaitakuSettingDTO, configColummModel: ZaitakuSettingDTO, isSaveAndClose: boolean) {
    let apiUrl = API_APP.ZAITAKU.CONTROLLER + API_APP.ZAITAKU.INSERT_OR_UPDATE_SETTING;
    const apiPath = apiUrl.replace(PIVOT_CLIENT_API_URL, "");
    let resData = await this.post(apiUrl, [], bodyData);
    let actionLog;
    let oldLog = null;
    if(bodyData.isupdate) {
      actionLog = isSaveAndClose ? SAUCER_LOG_ACTION.RETURN_HOME.SAVE_AND_CLOSE_CONDITION_UPDATE : SAUCER_LOG_ACTION.RETURN_HOME.SAVE_CONDITION_UPDATE;
      oldLog = this.getDetailContentLog(configColummModel);
    } else {
      actionLog = isSaveAndClose ? SAUCER_LOG_ACTION.RETURN_HOME.SAVE_AND_CLOSE_CONDITION_CREATE : SAUCER_LOG_ACTION.RETURN_HOME.SAVE_CONDITION_CREATE;
    }

    let newLog = this.getDetailContentLog(bodyData);

    if (resData.statuscode !== 200) {
      this.saucerLogService.error({
          apiPath,
          statusCode: resData.statuscode,
          body: JSON.stringify(bodyData),
          content: SYNCDATA_STATUS.ERROR
        }, { action: actionLog });
    } else {
      this.saucerLogService.system({
          apiPath,
          statusCode: resData.statuscode,
          body: JSON.stringify(bodyData),
          content: JSON.stringify({
            old: oldLog,
            new: newLog
          })
      }, { action: actionLog });
  }


    return resData;
  }
  

  async getSettingByOffice(jigNo: string, siteiNo: string): Promise<zaitakuSettingModel[]> {
    let bodyRequest = {
      jigNo,
      siteiNo
    };
    let apiUrl = API_APP.ZAITAKU.CONTROLLER + API_APP.ZAITAKU.GET_SETTING_BY_OFFICE;
    let resData = await this.post(apiUrl, [], bodyRequest);
    return this.mapZaitakuSettingByOffice(resData);
  }

  async getSettingByCondition(jigNo: string, siteiNo: string, sDate: string, eDate: string) {
    let bodyRequest = {
      jigNo,
      siteiNo,
      sDate,
      eDate
    };
    let apiUrl = API_APP.ZAITAKU.CONTROLLER + API_APP.ZAITAKU.GET_SETTING_BY_CONDITION;
    let resData = await this.post(apiUrl, [], bodyRequest);
    return resData;
  }


  mapZaitakuSettingByOffice(data: any) {
    if (data.statuscode === 200 && data.data) {
      let listData: zaitakuSettingModel[];

      // Group data by multiple properties
      const groupedItems = groupBy(data.data, item => `${item.jigno}_${item.siteino}`);


      // Transform the grouped items array
      listData = map(groupedItems, (value, key) => (
        {
          id: uuidv4(),
          jigno: value[0].jigno,
          siteino: value[0].siteino,
          officecd: value[0].officecd,
          configs: value.map(item => {
            return { ...item, 
                      edate: item.edate != MAXIMUM_MONTH ? item.edate : "", 
                      conditions: JSON.parse(item.conditions) 
                    }
          })
        } as zaitakuSettingModel
      ));
      return listData;
    }
    return [];
  }

  async deleteSetting(request: configColummModel) {
    let apiUrl = API_APP.ZAITAKU.CONTROLLER + API_APP.ZAITAKU.DELETE_SETTING;
    const apiPath = apiUrl.replace(PIVOT_CLIENT_API_URL, "");
    apiUrl = apiUrl.replace('{id}', request.id);
    let resData =  await this.httpdelete(apiUrl);
    let actionLog = SAUCER_LOG_ACTION.RETURN_HOME.DELETE_CONDITION;
    if (resData.statuscode === 200) {
      const oldLog = {
        'conditionName': request.conditionname,
        'sdate': request.sdate? moment(request.sdate).format(DateFormat.JP_LONG_DATE) : '',
        'edate': request.edate? moment(request.edate).format(DateFormat.JP_LONG_DATE) : ''
      }
      const newLog = null;
      this.saucerLogService.system({
        apiPath,
        body: "ID: "+ request.id,
        statusCode: resData.statuscode,
        content:  JSON.stringify({
          old: oldLog,
          new: newLog
        })
        }, {
          action: actionLog
      });
    } else {
      this.saucerLogService.error({
        apiPath,
        body: "ID: "+ request.id,
        statusCode: resData.statuscode,
        content: SYNCDATA_STATUS.ERROR
      }, { 
        action: actionLog
      });
    }

    return resData;
  }

  async deletePendingInvitation(id: string) {
    let apiUrl = API_APP.NOTIFY.CONTROLLER + API_APP.NOTIFY.DELETE_PENDING_INVITATION;
    apiUrl = apiUrl.replace('{id}', id);
    let resData =  await this.httpdelete(apiUrl);
    return resData;
  }

  async getItemValuesByCondition(officecd: string, ttlkbn: string, ttlcd: string, itemid: string): Promise<any[]> {
    let bodyRequest = {
      officecd,
      ttlkbn,
      ttlcd,
      itemid
    };
    let apiUrl = API_APP.ZAITAKU.CONTROLLER + API_APP.ZAITAKU.GET_ITEMVALUES_BY_CONDITION;
    let resData = await this.post(apiUrl, [], bodyRequest);
    if (resData.statuscode === 200 && resData.data) { 
      return resData.data
    } 
    return [];
  }



  //#endregion



}
