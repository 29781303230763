<div class="container" *ngFor="let child of childView">
  <ng-container *ngIf="!child.hidden">
    <div class="tree-view-container" *ngIf="expanded">
      <div class="tree-view-node">
        <div class="tree-view" *ngIf="!firstNode && !hasChild(child)" [ngClass]="{'invisible': !firstNode && !hasChild(child) && !child.isLastNode}">
          <img [src]="treeNodeStepSrc" />
        </div>
        <div class="expand-container" [ngClass]="{'invisible' : expandIconInvisible(child) }">
          <div (click)="expandTree(child)">
            <img [ngClass]="{'invisible' : !child.isShowIcon }" class="expand-icon" [src]="child.expanded ? collapseIcon : expandIcon" />
          </div>
        </div>
        <div class="checkbox-container" [ngClass]="{ 'checkbox-no-expand': hideExpandIcon(child)}"
          (dragstart)="handleDragStart($event);">
          <pivot-checkbox (onClick)="handleCheckboxChange(child)" [ngClass]="firstNode ? 'pivot-checkbox' : ''"
            [value]="child.selected || false" *ngIf="showCheckbox(child)">
          </pivot-checkbox>
          <img [src]="child?.icon" *ngIf="child?.icon" />
          <div
            *ngIf="!child.isSpin"
            [ngClass]="{ 'node-label': !this.enableCheckbox, 'label-container':true, 'none-checkbox-label': !this.enableCheckbox && firstNode && !hasChild(child), 'single-selected': child.singleSelected, 'last-node': !hasChild(child), 'last-node-hover': !hasChild(child) && hoverable }">
            <label *ngIf="firstNode || !isDisplayTooltip" [ngClass]="{ 'draggable': child.draggable , 'hilighted': child.highlighted && isMultiDragItem}"
              [pDraggable]="dragScope" [pDraggableDisabled]="!child.draggable" (onDragStart)="dragStart($event, child)"
              (onDragEnd)="dragEnd($event, child)" [id]="child.labelId ? child.labelId : child.id" (click)="onSelect(child)"
              (dblclick)="handleDoubleClick(child)">
              {{ child.label ? child.label : COMMON_TEXT.EMPTY_NODE }}
            </label>

            <label *ngIf="!firstNode && isDisplayTooltip" [ngClass]="{ 'draggable': child.draggable, 'hilighted': child.highlighted && isMultiDragItem }" [pTooltip]="child.label!" tooltipPosition="bottom" 
              [pDraggable]="dragScope" [pDraggableDisabled]="!child.draggable" (onDragStart)="dragStart($event, child)"
              (onDragEnd)="dragEnd($event, child)" [id]="child.labelId ? child.labelId : child.id" (click)="onSelect(child)"
              (dblclick)="handleDoubleClick(child)">
              {{ child.label ? child.label : COMMON_TEXT.EMPTY_NODE }}
            </label>
          </div>
          <div *ngIf="child.isSpin" class="spinner-wrapper">
            <div class="loading-spinner"></div>
          </div>
        </div>
      </div>
    </div>
    <div class="children-nodes" (scroll)="scrollHandler($event)" 
    [ngClass]="{'tree-view-node-children':true, 'hidden':!child.expanded ,' active-container' :firstNode}" [style]="treeNodeChildrenMarginStyle(child)">
      <pivot-tree-node
        [isTemplate]="isTemplate"
        [item]="child" 
        [expanded]="child.expanded" 
        [expandAll]="expandAll"
        [enableCheckbox]="enableCheckbox" 
        [isFirstNodeChecked]="isFirstNodeChecked" 
        (nodeMouseClick)="childItemHandler($event)" 
        (isOnFetchData)="isOnFetch($event)"
        (nodeMouseDoubleClick)="childItemDoubleClickHandler($event)"
        [dragScope]="dragScope" 
        (nodeDragStart)="childDragStart($event)" 
        (nodeDragEnd)="childDragEnd($event)"
        [expandIcon]="expandIcon"
        [isMultiDragItem]="isMultiDragItem"
        [collapseIcon]="collapseIcon"
        [selectable]="selectable"
        [hoverable]="hoverable"
        [dragImage]="dragImage"
        [nodeSelecteds]="nodeSelecteds"
        [isDisplayTooltip]="isDisplayTooltip"
        (nodeCheckboxChange)="childCheckboxChange($event)"
        (onDeleteNode)="deleteNode($event)">
      </pivot-tree-node>
    </div>
  </ng-container>
</div>