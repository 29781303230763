import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Title } from "@angular/platform-browser";
import { Router } from '@angular/router';
import { ROUTE_PATH } from '../../../app/const/route-path';
import { BUTTON, RESET_PASSWORD } from '../../../app/const/text-common';
import { LocalStorageHelper } from '../../../app/_helper/local-storage.helper';

@Component({
  selector: 'pivot-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ResetPasswordComponent implements OnInit {
  BUTTON = BUTTON;
  RESET_PASSWORD = RESET_PASSWORD;
  resetPasswordForm: FormGroup;
  isSubmitForm: boolean = false;
  constructor(
    private router: Router,
    private titleService: Title,
    private formBuilder: FormBuilder,
  ) { }

  ngOnInit(): void {
    this.isSubmitForm = false;
    this.titleService.setTitle('CAREKARTE Pivot');
    this.resetPasswordForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$")]],
    });
  }

  get f(): any { return this.resetPasswordForm.controls; }

  onSubmit(): void {
    this.isSubmitForm = true;
  }

  backToLogin(): void {
    this.router.navigate([LocalStorageHelper.getUrl(ROUTE_PATH.AUTH)]);
  }
}
