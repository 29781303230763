<div [ngClass]="{
    'circle-loading': condition === ProcessBarCondition.NOT_START,
    'process-bar': (condition === ProcessBarCondition.START || condition === ProcessBarCondition.DONE) && !showTick,
    'tick': showTick,
    'error': condition === ProcessBarCondition.ERROR
  }">
    <div class="percent" *ngIf="(condition === ProcessBarCondition.START || condition === ProcessBarCondition.DONE) && !showTick">
      <div class = "status-row">
        <img class = "amination" src ="../../../../assets/icons/circle.svg" >
        <img class = "check" src ="../../../../assets/icons/check.svg"  >
      </div></div>
    <div class="percent" *ngIf="condition === ProcessBarCondition.DONE && progress === 100 && !showTick">
      <div class = "status-row">
        <img class = "amination" src ="../../../../assets/icons/circle.svg">
        <img class = "check" src ="../../../../assets/icons/check.svg"  >
      </div>
      </div>
</div>

<div [ngStyle]="{'width': 'calc(' + progress +'% - 2px)'}" class="process-bar-syncing" *ngIf="(condition === ProcessBarCondition.START || condition === ProcessBarCondition.DONE) && !showTick">
    <div class="percent" *ngIf="(condition === ProcessBarCondition.START || condition === ProcessBarCondition.DONE) && !showTick">
      <div class = "status-row">
        <img class = "amination" src ="../../../../assets/icons/circle.svg">
        <img class = "check" src ="../../../../assets/icons/check.svg"  >
      </div>
    </div>
    <div class="percent" *ngIf="condition === ProcessBarCondition.DONE && progress === 100 && !showTick">
      <div class = "status-row">
        <img class = "amination" src ="../../../../assets/icons/circle.svg">
        <img class = "check" src ="../../../../assets/icons/check.svg"  >
      </div>
      </div>
</div>