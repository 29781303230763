import { AfterContentChecked, Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { cloneDeep, orderBy } from 'lodash';
import { OFFICE } from '../../../const/table-col-define';
import { BUTTON, COMMON_TEXT, DATASOURCE_LIST } from '../../../../app/const/text-common';
import { ButtonType, DataType, SearchType } from '../../../../app/enum/common-enum';
import { FilterItem, ModalTemplate, SearchParams } from '../../../../app/models/common-model';
import { Office, OfficeService } from '../../../../app/models/response/office.ro';
import { TableData } from '../../../../app/models/table-model';
import { OfficeAPIService } from './../../../services/modules/office.service';
import Utils from '../../../../app/util/utils';
import { LocalStorageKey } from 'src/app/_helper/local-storage.helper';
import { Router } from '@angular/router';
import { SaucerLogService } from 'src/app/services/saucer-logs/saucer-log.service';
import { CONTENT_LOG } from 'src/app/config/saucer-log.config';

@Component({
  selector: 'pivot-office-service',
  templateUrl: './office-service.component.html',
  styleUrls: ['./office-service.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class OfficeServiceComponent implements OnInit, AfterContentChecked {
  @Input() selectedOfficeServices: Array<Office> = [];
  @Input() showModal: boolean = false;
  @Input() isHome: boolean = false;
  @Input() actionLog: any;
  @Output() closeModalHandler = new EventEmitter();
  @Output() selectedOfficeHandler = new EventEmitter();
  @Output() selectAllOfficeHandler = new EventEmitter();
  BUTTON = BUTTON;
  COMMON_TEXT = COMMON_TEXT;
  buttonType = ButtonType;
  modalTemplate: ModalTemplate = new ModalTemplate();
  searchParams: SearchParams = {
    type: SearchType.input,
    placeholder: COMMON_TEXT.SEARCH,
    cssStyle: { width: '100%', height: '40px' },
    readonly: false,
    disabled: false
  }
  tableData: TableData = {
    config: {
      id: 'office-service',
      caption: '',
      emptyMessage: COMMON_TEXT.NO_DATA,
      showIndex: true,
      showCheckbox: true,
      hoverShowCheckbox: false,
      showPagination: false,
      isResponsive: false,
      tableStyle: {}

    },
    header: [
      {
        field: 'officeCd',
        title: OFFICE.OFFICECD,
        sortable: false,
        filterable: false,
        attribute: {
          header: { width: '20%' },
          row: {}
        },
        dataType: DataType.TEXT,
        visible: false
      },
      {
        field: 'officeNm',
        title: OFFICE.OFFICENM,
        sortable: false,
        filterable: false,
        attribute: {
          header: { width: '80%' },
          row: {}
        },
        dataType: DataType.TEXT,
        visible: true
      },
    ],
    body: []
  };
  isFilterAll: boolean = true;
  checkOfficeSeletedLocalStorage: string | null;
  isShowModal: boolean = false;
  filterServices: any[] = [];
  listOffices: Array<any> = [];
  filterOffice: Array<any> = [];
  listServices: any[] = [];
  loadingTable: boolean = true;
  officeData: any[] = [];
  selectedOffices: Array<Office> = [];
  dispalyFilterServices: any[] = [];
  remaningNumber: number = 0;
  filterList: Array<FilterItem> = [];
  filterModalStyle = { width: '400px', height: '300px' };
  isDisplayNumber: boolean = false;
  searchText: string = "";
  isSelectAll: boolean = false;

  constructor(
    private officeAPI: OfficeAPIService,
    private router: Router,
    private saucerLogService : SaucerLogService
  ) {
  }
  ngAfterContentChecked(): void {
    let item = document.querySelectorAll('.has-items .filter');
    if (item.length > 0) {
      let totalWidth = 0;
      item.forEach(element => {
        let item = element as HTMLElement;
        totalWidth = totalWidth + item.offsetWidth;
      });
      this.isDisplayNumber = totalWidth > 440 ? true : false;
    }
  }

  async ngOnInit(): Promise<void> {
    this.modalTemplate.header = DATASOURCE_LIST.OFFICE_DLG_TITLE;
    this.modalTemplate.style = { 'width': '550px', 'max-height': 'calc(100vh - 200px)', 'min-height': 'calc(100vh - 200px)', 'height': 'calc(100vh - 200px)' };
    window.addEventListener('click', (event: any) => {
      let modal = document.getElementById('filter-modal');
      let element = event.target.id;
      if (element === 'checkbox-check') {
        if (this.isShowModal) {
          this.isShowModal = true;
        }
      }
      else {
        if (!modal?.contains(event.target)) {
          let icon = document.getElementById('filter-icon');
          if (!icon?.contains(event.target)) {
            this.isShowModal = false;
          }
        }
      }
    });

    // icon-checked
    this.listOffices = await this.officeAPI.getAllOfficeService();
    this.filterOffice = cloneDeep(this.listOffices);
    //this.checkOfficeSeletedLocalStorage = localStorage.getItem(LocalStorageKey.OFFICE_SELECTED || "[]");
    this.checkOfficeSeletedLocalStorage = this.officeAPI.getOfficeSelected() || '[]';
    
    if((this.checkOfficeSeletedLocalStorage != null && this.isHome)){
      this.filterOffice.map(o => {
        delete o.copr
        let checkOfficeSeletedLocalStorageString = [];
        checkOfficeSeletedLocalStorageString = JSON.parse(this.checkOfficeSeletedLocalStorage ?? "[]" );
        o.checked = checkOfficeSeletedLocalStorageString.filter((i:any) => i == o.officeCd).length > 0;
        o.services?.map((x: any) => {
          if (!this.listServices.filter(i => i.id === x.id && i.name === x.srvkbnnm).length) {
            let filter = new FilterItem();
            filter.id = x.id;
            filter.level = x.serviceType + "_" + x.fixflg;
            filter.name = x.srvkbnnm;
            filter.checked = false;
            this.listServices.push(filter);
          }
        })
      })
    }else  {
      this.filterOffice.map(o => {
        delete o.copr
        o.checked = this.selectedOfficeServices.filter(i => i.officeCd === o.officeCd).length > 0;
        o.services?.map((x: any) => {
          if (!this.listServices.filter(i => i.id === x.id && i.name === x.srvkbnnm).length) {
            let filter = new FilterItem();
            filter.id = x.id;
            filter.level = x.serviceType + "_" + x.fixflg;
            filter.name = x.srvkbnnm;
            filter.checked = false;
            this.listServices.push(filter);
          }
        })
      })
    }
    let selectedRows = cloneDeep(this.filterOffice).filter(item => item.checked);
    this.selectedOffices = selectedRows.map(item => {
      delete item.checked;
      return item;
    })
    this.filterOffice = orderBy(this.filterOffice, ["officeCd"])
    this.tableData.body = this.filterOffice;
    this.tableData = cloneDeep(this.tableData);
    this.loadingTable = false;
    this.officeData = cloneDeep(this.tableData.body);
  }

  showModalFilter() {
    this.isShowModal = !this.isShowModal;
  }

  closeFormModal() {
    this.closeModalHandler.emit();
  }

  onFilterOutData(officeName: any) {
    if (this.actionLog) {
      this.saucerLogService.action({
        content: CONTENT_LOG.SEARCH + ": " + officeName
      }, {action: this.actionLog.SEARCH_OFFICE});
    }

    let table = cloneDeep(this.tableData);
    officeName = officeName.trim();
    this.searchText = officeName;
    let offices = [];
    offices = this.officeData.filter(o => {
      let nameCondition = Utils.isNullOrEmpty(officeName) ? true : o.officeNm?.includes(officeName) ;
      let serviceCondition =  this.filterServices.length > 0 ? o.services?.filter((s: any) => this.filterServices.filter((i: any) => i.id === s.id && i.name === s.srvkbnnm).length > 0).length > 0: true;
      return nameCondition && serviceCondition;
    });
    table.body = offices;
    this.tableData = table;
  }

  mapdataFilter(dataChecked: any) {
    if (dataChecked && Object.keys(dataChecked).length > 0) {
      if (dataChecked?.data?.length > 0) {
        this.officeData?.map(off => {
          let officeIndex = dataChecked?.data?.findIndex((s: any) =>  s.officeCd == off.officeCd);
          if (officeIndex != -1) {
            off.checked = true;
          }
          else {
            let selectedIndex = this.selectedOffices?.findIndex((s: any) => s.officeCd == off.officeCd);
            if (selectedIndex != -1) {
              off.checked = true;
            }
            else {
              off.checked = false;
            }
          }
        })
      }
      else {
        this.officeData?.map(off => {
          let selectedIndex = this.selectedOffices?.findIndex((s: any) =>  s.officeCd == off.officeCd);
          if (selectedIndex != -1) {
            off.checked = true;
          }
          else {
            off.checked = false;
          }
        })
      }
    }
  }

  checkRowsTable(rows: any) {
    if (rows && Object.keys(rows).length > 0) {
      this.selectedOffices = rows.data;
    }
    this.mapdataFilter(rows);
    if(rows.data?.length == this.officeData?.length) this.isSelectAll = true;
    else this.isSelectAll = false;
  }

  filterData(selectedService: any) {
    if (this.actionLog) {
      this.saucerLogService.action({
        content: JSON.stringify(selectedService)
      }, {action: this.actionLog.CHANGE_FILTER_OFFICE});
    }

    if (selectedService.length > 0) {
      let offices = this.officeData.filter(o => {
        return  o.services?.filter((s: any) => selectedService.filter((i: any) => i.id === s.id && i.name === s.srvkbnnm).length > 0).length > 0;
      }).filter((office)=> {
        return Utils.isNullOrEmpty(this.searchText) ? true : office.officeNm?.includes(this.searchText);
      })

      this.filterServices = selectedService;
      this.listServices.map(item => {
        if (this.filterServices.filter((filter: any) => filter.id === item.id  && filter.name === item.name ).length > 0) item.checked = true;
        else item.checked = false;
      })
      let table = cloneDeep(this.tableData);
      table.body = offices;
      this.tableData = table;
    }
    else {
      this.listServices.map(item => {
        item.checked = false;
      })
      let table = cloneDeep(this.tableData);
      this.officeData = orderBy(this.officeData, ["officeCd"]);
      let offices = this.officeData.filter(o => {
        return Utils.isNullOrEmpty(this.searchText) ? true : o.officeNm?.includes(this.searchText);
      });
      table.body = offices;
      this.tableData = table;
      this.filterServices = [];
    }
    this.generateFilterService();
  }

  generateFilterService() {
    this.dispalyFilterServices = this.filterServices.map(item => (
      {
        id: item.id,
        name: item.name
      }));
    this.remaningNumber = this.filterServices.length;
  }

  removeServiceFilter(service: any) {
    this.filterServices = this.filterServices.filter(i => i.id !== service.id && i.name !== service.srvkbnnm);

    if (this.actionLog) {
      this.saucerLogService.action({
        content: JSON.stringify(this.filterServices)
      }, {action: this.actionLog.CHANGE_FILTER_OFFICE});
    }

    if (this.filterServices.length > 0) {
      let tmpData = cloneDeep(this.officeData);
      let filterData = tmpData.filter( (office) =>  {
        return this.filterServices.filter( (service) => {
          let arrayItem: any[] = [];
          office.services.forEach((s: any) => {
            let item = {id: s.id, name: s.srvkbnnm}
            arrayItem.push(item)
          });
          return arrayItem.filter((i:any) => i.id === service.id && i.name === service.name).length > 0;
        }).length > 0
      }).filter((office) => {
        return Utils.isNullOrEmpty(this.searchText) ? true : office.officeNm?.includes(this.searchText) 
      })

      this.tableData.body = filterData;
      this.tableData = cloneDeep(this.tableData);
      this.listServices.map(item => {
        if (this.filterServices.filter((filter: any) => filter.id === item.id  && filter.name === item.name).length > 0) item.checked = true;
        else item.checked = false;
      })
    }
    else {
      this.filterData(service);
    }
    this.generateFilterService();
  }

  save() {
    if (this.actionLog) {
      let contentLog = this.selectedOffices.map((x:any)=>({corpCd: x.corpcd, officeCd: x.officeCd, officeNm: x.officeNm})) || [];
      this.saucerLogService.action({
        content: JSON.stringify(contentLog)
      }, {action: this.actionLog.SELECT_OFFICE});
    }

    this.selectAllOfficeHandler.emit(this.isSelectAll);
    this.selectedOfficeHandler.emit(this.selectedOffices);
    this.closeModalHandler.emit();
  }

  cancel() {
    this.closeModalHandler.emit();
  }
}
