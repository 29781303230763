import { ChangeDetectorRef, Component, Input } from '@angular/core';
import { WidgetService } from '../../../../../app/services/modules/widget.service';
import { PivotTableConfig } from '../../../../../app/models/response/widget.ro';
import { ChartBase } from '../chart.base';
import { cloneDeep, last } from 'lodash';
import { FooterOption } from '../../../../enum/common-enum'
import { Router } from '@angular/router';

@Component({
  selector: 'pivot-number-chart',
  templateUrl: './number-chart.component.html',
  styleUrls: ['./number-chart.component.scss']
})
export class NumberChartComponent extends ChartBase {
  @Input() footerType: string | undefined;
  @Input() override height: number | undefined;
  @Input() override width: number | undefined;
  nodeId: string
  value: string
  digitCount: number;
  config: PivotTableConfig;
  viewBoxOfSmallNumber: boolean;


  constructor(private cdr: ChangeDetectorRef, private widgetService: WidgetService, private router: Router) {
    super()
  }

  override ngOnInit() {
        // subscribe for change footer type in widget setting
        this.widgetService.footertype$.subscribe((type: string|undefined) => {
          this.footerType = type
          this.generateChart()
        })
    super.ngOnInit()

    
  }

  generateChart(): void {
    let valueTmp = "0";
    if (this.footerType) {
      let tableData = cloneDeep(this.tableData?.table);
      let config = this.tableData?.config;
      if(tableData) {
        let lastHeader = (config?.columns?.length || 0) + (config?.rows?.length || 0) + (config?.values?.length || 0);
        let footerValue = (tableData?.footers? tableData?.footers[this.footerType] : []) || [];
        if(lastHeader < footerValue?.length) footerValue = footerValue?.splice(0,lastHeader);
        valueTmp = footerValue?.reduce((a: any, b:any) => (parseFloat(a) || 0.0) + (parseFloat(b) || 0.0), 0.0);
      } 
      if(this.footerType == FooterOption.Average) valueTmp = Number(valueTmp).toFixed(2)
    }
    valueTmp = this.toJapaneseNumberFormat(Number(valueTmp), this.footerType || '');
    this.value = valueTmp
    this.digitCount = this.countDigits(valueTmp);
    if (this.digitCount > 4 ){
      this.viewBoxOfSmallNumber = false;
    }else {
      this.viewBoxOfSmallNumber = true;
    }
    this.cdr.detectChanges()

  }
  countDigits(value: string): number {
    const digits = value.replace(/\D/g, '');
    return digits.length;
  }

  toJapaneseNumberFormat(number: number, type: string): string {
    let result = "0";
    let fractionOption = undefined;
    if (number === null) {
      return result;
    }

    if (typeof number === "string") {
      number = parseFloat(number);
    }
  

    if(type == FooterOption.Average) fractionOption = 2;

    result = number.toLocaleString("ja-JP", {
      style: "decimal",
      useGrouping: true,
      minimumFractionDigits: fractionOption,
      maximumFractionDigits: fractionOption,
    });

    return result;
  }
}