import { Component, OnInit } from '@angular/core';
import { DynamicDialogConfig } from 'primeng/dynamicdialog';
import { DynamicDialogRef } from 'primeng/dynamicdialog';
import { MESSAGE_TEXT } from '../../../../app/const/message';
import { DialogType } from '../../../../app/enum/common-enum';


@Component({
  selector: 'pivot-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.scss']
})
export class ConfirmDialogComponent implements OnInit{

  dialogType: DialogType = DialogType.save;
  urlImage: string = '../../../../assets/images/save.svg';
  message: string = '';
  constructor(public ref: DynamicDialogRef,
    public config: DynamicDialogConfig) {
      this.config.showHeader = false;
      this.config.style = { width: '140px', height: '150px'};
      this.config.contentStyle = {'border-radius': '4px'};
  }

  ngOnInit(): void {
    // set message from dialog config
    if(this.config?.data?.message != undefined) {
      this.message = this.config?.data?.message;
    }
    // set dialog type from dialog config
    if(this.config?.data?.dialogType != undefined){
      this.dialogType = this.config?.data?.dialogType;
    }

    //削除処理後に表示される
    if (this.dialogType === DialogType.delete) {
      this.urlImage = '../../../../assets/images/delete.svg';
      this.message = this.message || MESSAGE_TEXT.DELETE_COMPLETE;
    }
    //新規、編集、削除処理時に失敗した場合に表示される
    else if (this.dialogType === DialogType.error) {
      this.urlImage = '../../../../assets/images/error.svg';
      this.message = this.message || MESSAGE_TEXT.ERR;
    }
    //新規、編集処理後に成功した場合に表示される
    else if (this.dialogType === DialogType.save) {
      this.urlImage = '../../../../assets/images/save.svg';
      this.message = this.message || MESSAGE_TEXT.SAVE_COMPLETE;
    }
    //編集処理後に表示される
    else {
      this.urlImage = '../../../../assets/images/edit.svg';
      this.message = this.message || MESSAGE_TEXT.UPDATE_COMPLETE;
    }
    setTimeout(() => this.ref.close('reject'), 1200);
  }
}
