import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Title } from "@angular/platform-browser";
import { Router } from '@angular/router';
import { Message } from 'primeng/api';
import { BUTTON, LOGIN } from '../../../app/const/text-common';
import { ROUTE_PATH } from '../../const/route-path';
import { AuthenticationService } from '../../services/authentication.service';
import { ProcessLoadingService } from '../../services/loading.service';
import { LocalStorageHelper, LocalStorageKey } from '../../_helper/local-storage.helper';
import { environment } from '../../../environments/environment';
import Utils from '../../util/utils';
import { DeviceType, OperatingSystemName } from '../../enum/common-enum';
import StringUtils from '../../util/stringUtils';
import { DetectDeviceService, IDeviceType } from 'src/app/services/detect-device.service';
import { LoginInfoModel } from 'src/app/models/login-info.model';
import { HelpListService } from '../../services/modules/help-list.service';
import {ErrorHandleService} from '../../services/error-handle.service';
import { SAUCER_LOG_ACTION } from 'src/app/config/saucer-log.config';
import { SaucerLogService } from 'src/app/services/saucer-logs/saucer-log.service';
import { API_APP } from 'src/app/config/app.config';

@Component({
  selector: 'pivot-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class LoginComponent implements OnInit {
  BUTTON = BUTTON;
  LOGIN = LOGIN;
  DeviceType = DeviceType;
  loginForm: FormGroup;
  errorMessage: string;
  deviceType!: string;
  currentOS: string | null = '';
  OS = OperatingSystemName;
  inError = false;
  emailMaxLength = 256;
  email: string = '';
  isLoginConsole: boolean;
  firstTime: boolean = true;
  isUserAction: boolean = true;

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private authenticationService: AuthenticationService,
    private processLoadingService: ProcessLoadingService,
    private titleService: Title,
    private detectDeviceService: DetectDeviceService,
    private helpListService:  HelpListService,
    private errorHandleService: ErrorHandleService,
    private saucerLogService : SaucerLogService
  ) {
    this.isLoginConsole = this.router.url.includes("console") ? true : false;
    if (this.authenticationService.isLoggedIn()) {
      if (this.authenticationService.isSelectedCorp()) {
        if (this.isLoginConsole) {
          this.router.navigate([LocalStorageHelper.getUrl(ROUTE_PATH.DATASOURCE_TEMPLATE)])
        }else {
          let checkLoginIsConsole = this.authenticationService.currentUserSubject.value.loginstage;
          if (checkLoginIsConsole == "console"){
            this.router.navigate([LocalStorageHelper.getUrl(ROUTE_PATH.DATASOURCE_TEMPLATE)])
          }else {
            this.helpListService.setCheckPopUp(true);
            this.helpListService.setCheckOffice(true);
            this.router.navigate([LocalStorageHelper.getUrl(ROUTE_PATH.HOME)]);
          }
        }
      } else if (!this.router.url.includes(ROUTE_PATH.AUTH)) {
        this.router.navigate([LocalStorageHelper.getUrl(ROUTE_PATH.CHOOSE_CORP)]);
      }
    }

    this.detectDeviceService.currentDevice.subscribe((device: IDeviceType) => {
			if (device) this.deviceType = device.type;
		});
  }

  ngOnInit(): void {
    this.titleService.setTitle(LOGIN.LOGIN_APP);
    this.loginForm = this.formBuilder.group({
      username: ['', Validators.compose([Validators.required])],
      password: ['', Validators.compose([Validators.required])],
      IsRemember: [sessionStorage.getItem('token') ? true : false]
    });

    this.currentOS = Utils.getOS();
    localStorage.removeItem(LocalStorageKey.OFFICE_SELECTED);
  }

  get f(): any { return this.loginForm.controls; }

  onSubmit(): void {
    LocalStorageHelper.removeCurrentUser();
    // Start write saucer log
    this.saucerLogService.action(
      {
        apiPath: API_APP.AUTH.LOGIN,
        content: JSON.stringify({
          username: this.f.username.value
        })
      },
      {
        action: SAUCER_LOG_ACTION.LOGIN.ACTION_LOGIN
      }
    );
    this.processLoadingService.isLoading.emit(true);
    this.errorHandleService.setIsCloseErrorDialogOrErrorScreen(true);
    if (this.isLoginConsole && !this.f.username.value.endsWith('@carekarte.jp')) {
      this.errorMessage = LOGIN.LOGIN_CONSOLE_ERR_MSG;
      this.inError = true;
      this.processLoadingService.isLoading.emit(false);
      this.loginFailSaucerLog(this.f.username.value, this.errorMessage);
      return;
    }

    this.authenticationService.login(this.f.username.value, this.f.password.value, {isLoginConsole: this.isLoginConsole})
      .subscribe(
        async (data: any) => {
          if (data) {

            if (data == "Unauthorized") {
              this.errorMessage = LOGIN.LOGIN_ERR_500_MSG;
              this.inError = true;
              this.loginFailSaucerLog(this.f.username.value, this.errorMessage);
              this.processLoadingService.isLoading.emit(false);
            } else {
              if(data?.data) {
                data.data.loginstage = this.isLoginConsole ? "console" : "auth";
              }
              if (data?.data?.authcorplist && data?.data?.authcorplist.length > 1 || data.data?.templatesettingcorp) {
                this.router.navigate([LocalStorageHelper.getUrl(ROUTE_PATH.CHOOSE_CORP)]);
                this.processLoadingService.isLoading.emit(false);
                return;
              }

              const processRes = await this.authenticationService.processAfterCallLoginApi(data);
              switch (processRes.statuscode) {
                case 200:
                  let loginInfo = new LoginInfoModel();
                  loginInfo.organizationid = data.data.authcorplist[0]?.organizationid;
                  loginInfo.organizationname = data.data.authcorplist[0]?.organizationname ?? "";
                  loginInfo.companyid = data.data.authcorplist[0]?.companyid;
                  loginInfo.custid = data.data.companylist ? data.data.companylist[0]?.custid : "";
                  LocalStorageHelper.setLoginInfo(loginInfo);
                  this.saucerLogService.system(
                    {
                      apiPath: API_APP.AUTH.LOGIN,
                      content: JSON.stringify({
                        username: this.f.username.value,
                        loginInfo
                      })
                    },
                    {
                      action: SAUCER_LOG_ACTION.LOGIN.SUCCESS
                    }
                  );
                  this.inError = false;
                  const account = data.data;
                  this.authenticationService.setCurrentUser(account);
                  if (this.isLoginConsole) {
                    this.router.navigate([LocalStorageHelper.getUrl(ROUTE_PATH.DATASOURCE_TEMPLATE)]);
                  } else {
                    this.router.navigate([LocalStorageHelper.getUrl(ROUTE_PATH.HOME)]);
                  }
                  break;

                case 401:
                default:
                  if(data?.responemessage.includes(LOGIN.ERR_USER_LOCKED)) {
                    this.loginFailSaucerLog(this.f.username.value, LOGIN.ERR_USER_LOCKED, processRes.statuscode);
                    this.router.navigate([LocalStorageHelper.getUrl(ROUTE_PATH.LOCK_ACCOUNT)]);
                  }
                  else {
                    this.errorMessage = LOGIN.LOGIN_ERR_MSG;
                    this.inError = true;
                    this.loginFailSaucerLog(this.f.username.value, this.errorMessage, processRes.statuscode);
                  }
                  break;
              }
            }

          } else {
            this.errorMessage = LOGIN.LOGIN_ERR_MSG;
            this.inError = true;
            this.loginFailSaucerLog(this.f.username.value, this.errorMessage);
            this.processLoadingService.isLoading.emit(false);
          }
          this.processLoadingService.isLoading.emit(false);
        },
        (error: any) => {
          this.errorMessage = LOGIN.LOGIN_ERR_500_MSG;
          this.inError = true;
          this.loginFailSaucerLog(this.f.username.value, JSON.stringify(error));
          this.processLoadingService.isLoading.emit(false);
        });
  }

  loginFailSaucerLog(userName: string = "", msgErr: string = "", statusCode: number = 500) {
    this.saucerLogService.error(                  
      {
        apiPath: API_APP.AUTH.LOGIN,
        statusCode,
        errorMessage: msgErr,
        content: JSON.stringify({
          userName,
          msgErr 
        })
      },
      {
        action: SAUCER_LOG_ACTION.LOGIN.FAILED
      }
    );
  }

  resetPassword() {
    window.location.href = environment.CKPASS_FORGOT_PW;
  }

  inputOnChanges() {
    this.inError = false;
  }

  onEmailInput(event: any) {
    const trimmed = event.target.value.trim();

    if (StringUtils.checkValidateInput(this.emailMaxLength, trimmed) && StringUtils.checkValidateInput((trimmed) ? trimmed.length : 1, trimmed)) {
      this.email = trimmed;
    } else {
      event.target.value = this.email;
    }
  }

  onFocusPassword(event: any) {
    if(event.target.value == "" && this.firstTime == true && this.isUserAction == false) {
      setTimeout(() => {
        event.target.value = "";
      }, 0);
      this.firstTime = false;
    }
    else if(event.data != "" && this.firstTime == false && this.isUserAction == true)
    {
      setTimeout(() => {
        event.target.value =  event.target.value;
      }, 0);
    }

    this.isUserAction = true;
  }

  onFocusEmailInput() {
    this.firstTime = true;
    this.isUserAction = false;
  }
  onPress() {
    this.isUserAction = true;
  }
  onDown(event: any){
    var keyCode = event.keyCode || event.which;

    if (keyCode === 8 ||        // Backspace
        keyCode === 9 ||        // Tab
        keyCode === 13 ||       // Enter
        keyCode === 27 ||       // Escape
        keyCode === 16 ||       // Shift
        keyCode === 17 ||       // Control
        keyCode === 18 ||       // Alt
        keyCode === 20 ||       // Caps Lock
        keyCode === 91 ||       // Left Command (Mac)
        keyCode === 92 ||       // Right Command (Mac)
        keyCode === 93) {       // Right Command (Windows)
          this.isUserAction = true;
        }
  }
}
