import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { createXNode, formulaToOperator } from 'src/app/_helper/helper';
import { setCaretPosition } from 'src/app/_helper/mention-helper';
import { copyOperatorHandle, createOperator, generateHtml, generateHtmlCusor, generateHtmlOperator, getOperatorFormClipboardData, getOperatorFromString, getOperatorString, insertCusor, insertValuePaste, removeAllCusor } from 'src/app/_helper/operator-custom-helper';
import { OPERATOR_CUSTOM, OPERATOR_TYPE } from 'src/app/const/operator-custom';
import { operator } from 'src/app/models/common-model';
import { OperatorCustom } from 'src/app/models/operator-custom';
import { WidgetDetail } from 'src/app/models/request/widget.dto';

@Component({
  selector: 'pivot-input-formula-inline',
  templateUrl: './input-formula-inline.component.html',
  styleUrls: ['./input-formula-inline.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class InputFormulaInlineComponent implements OnInit {

  @ViewChild('operatorEditor') operatorEditorEmelent: ElementRef;
  @Input() selected: any;
  @Input() formula: string;
  @Input() rounding: string;
  @Input() displayCols: Array<WidgetDetail>;
  @Output() changeValue = new EventEmitter<any>();

  operators: Array<operator> = []
  operatorHtml: SafeHtml;
  itemsMention: any = {};
  _sanitizer: DomSanitizer;
  option: number = 0;
  item: number = 0;
  ctrPress = false; // check ctr+V
  constructor(sanitizer: DomSanitizer,
  ) {
    this._sanitizer = sanitizer;
    this.itemsMention.itemsFunction = [];
    OPERATOR_CUSTOM.map((x:OperatorCustom) => {
      this.itemsMention.itemsFunction.push (
        { label: x.name , type: OPERATOR_TYPE.OPERATOR_CUSTOM }
      )
    });
    this.itemsMention.itemsColumn = [];
  }

  ngOnInit(): void {

  }
  ngOnChanges(): void{
    this.operators = formulaToOperator(this.selected, 'targetValue', this.displayCols);
    this.onGenerateHtml();
  }
  setDataType() {

  }
  getData(): any{
    return {
      operators : this.operators,
      rounding: this.rounding//this.item + ','+ this.option
    };
  }

  onFocusinEditor(event: any) {
    removeAllCusor(this.operatorEditorEmelent)
  }

  onFocusoutEditor(event: any) {
    let target = event.target as HTMLElement

    this.operators = []
    target.childNodes.forEach((node, i) => {
      let item = {} as operator
      let nodeLE =  node as HTMLElement;
      if (node.nodeName === '#text') {
        var listOperator = getOperatorFromString(node.textContent);
        this.operators = [...this.operators, ...listOperator]
      } else if(nodeLE.id == OPERATOR_TYPE.OPERATOR_CUSOR || nodeLE.nodeValue == OPERATOR_TYPE.OPERATOR_CUSOR ){
        item.label = "";
        item.value = OPERATOR_TYPE.OPERATOR_CUSOR;
        item.type = OPERATOR_TYPE.OPERATOR_CUSOR;
        this.operators.push(item);
      }
      else {
        item = JSON.parse(node.childNodes[1].textContent as string)
        this.operators.push(item)
      }
    })
    this.onGenerateHtml();
  }
 
  onKeydownEditor(event: any) {
    var key = event.keyCode || event.charCode;
    if (key == 8 || key == 46){ // Backspace or Delete
      event.preventDefault();
    }
    if(event.ctrlKey || event.metaKey ){
      this.ctrPress = true;
    }else{
      if (this.ctrPress && (event.key === 'v' || event.key === 'c') ) {
      }else{
        event.preventDefault();
      }
    }
    
  }
  onKeyupEditor(event: any) {
    if(event.ctrlKey || event.metaKey){
      this.ctrPress = false;
    }
  }
  onPasteEditor(event: any) {
    event.preventDefault();
    let data  = getOperatorFormClipboardData(event);
    if(data) {
      this.selected.targetValue = getOperatorString(data.operators);
      this.selected.rounding = data.rounding;
      this.formula = getOperatorString(data.operators);
      this.operators = (data.operators);
      this.rounding = data.rounding;
      this.onGenerateHtml();
    }
  }
  getOpertor(htmlData: any){
    var tempDiv = document.createElement('div');
    tempDiv.style.display = 'none';
    tempDiv.innerHTML = htmlData;
    var elements = tempDiv.childNodes;
    for(var  i = 0 ; i < elements.length ; i++) {
      if(elements[i].nodeName == "SPAN" || elements[i].nodeName == "#text"){
        let item = {} as operator
        let nodeLE =  elements[i] as HTMLElement;
        if (elements[i].nodeName === '#text') {
          var listOperator = getOperatorFromString(elements[i].textContent);
          this.operators = [...this.operators, ...listOperator]
        } else if(nodeLE.id == OPERATOR_TYPE.OPERATOR_CUSOR || nodeLE.nodeValue == OPERATOR_TYPE.OPERATOR_CUSOR ){
          item.label = "";
          item.value = OPERATOR_TYPE.OPERATOR_CUSOR;
          item.type = OPERATOR_TYPE.OPERATOR_CUSOR;
          this.operators.push(item);
        }
        else {

          item = this.getDataColumn(elements[i]);//JSON.parse(elements[i].childNodes[1].textContent as string)
          this.operators.push(item)
        }
      }
     
    }
    this.onGenerateHtml();

  }
  getDataColumn(elements: any): any{
    elements.childNodes.forEach((element: any) => {
      if(element.nodeName === '#text' ){
        return JSON.parse(element.textContent as string)
      }
    });
  }
  itemSelectedMetion(event:any){
    let selObj =  window.getSelection();
    if (selObj && selObj.rangeCount > 0) {
      var anchorNode = selObj.anchorNode;
      let item:any = {};
      item.label = event.label;
      item.type = event.type;
      item.value = event.label;
      var span = createOperator(item, true);
      var empty = document.createTextNode('\u00A0'); 
      var spanEpmty = document.createTextNode(' '); 

      if( event.type == OPERATOR_TYPE.OPERATOR_CUSTOM){
        spanEpmty = document.createTextNode('('); 
      }
      var nexNode =  anchorNode?.nextSibling;
      if(anchorNode?.nodeName == "#text"){
        let searchStringLength = event.pos - event.startPos;
        let nodeTextValue = anchorNode.nodeValue ?? "";
        nodeTextValue = nodeTextValue?.substring(0, nodeTextValue?.length - searchStringLength);
        anchorNode.nodeValue = nodeTextValue;
      }
      if(nexNode) {
        nexNode?.parentNode?.insertBefore(span, nexNode);
        nexNode?.parentNode?.insertBefore(empty, nexNode);
        nexNode?.parentNode?.insertBefore(spanEpmty, nexNode);

      }else{
        anchorNode?.parentNode?.appendChild(span);
        anchorNode?.parentNode?.appendChild(empty);
        anchorNode?.parentNode?.appendChild(spanEpmty);
     
      } 
      let spanEpmtyHTML = (<HTMLInputElement><unknown>spanEpmty);
      if( item.type == OPERATOR_TYPE.OPERATOR_CUSTOM){
        setCaretPosition(spanEpmtyHTML,1,null);
      }else{
        setCaretPosition(spanEpmtyHTML,0,null);
      }
    }
  }
  onCopyEditor(event: any) {
    copyOperatorHandle(event, this.rounding);
  }
  onGenerateHtml() {
    let html = createXNode(null,null,null,true);
    this.operators.forEach((item: any, i: number) => {
      if(item.type == OPERATOR_TYPE.OPERATOR_CUSOR) {
        generateHtmlCusor(html, i);

      } else if(item.type == OPERATOR_TYPE.OPERATOR) {
        let nextItem;
        if((i + 1) < this.operators.length){ nextItem = this.operators[i+1];}
        generateHtmlOperator(html,item , i, nextItem);

      }
      else {
        if(i == 0){
          generateHtml(html, item, i, true);
        } else {
          generateHtml(html, item, i);
        }
      }
    });
    this.operatorHtml = this._sanitizer.bypassSecurityTrustHtml(html.innerHTML);
  }
}

