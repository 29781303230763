<div class="calendar-box">
    <p-calendar [dateFormat]="dateFormat" [(ngModel)]="value" [selectOtherMonths]="true" (ngModelChange)="handleValueChange($event)"
        [inputId]="inputId" [maxDate]="maxDate || DATE_MAX" [minDate]="minDate || DATE_MIN" [style]="style" [inputStyle]="inputStyle"
        [showTime]="isShowTime" [timeOnly]="isTimeOnly"
        [view]="view" [appendTo]="appendTo"  [disabled]="disabled" [disabledDates]="disabledDates" [disabledDays]="disabledDays"
        [showButtonBar]="showButtonBar">
    </p-calendar>
    <span *ngIf="canClear && value && !disabled" class="item-delete" (click)="clearText()">
        <i class="pi pi-times"></i>
    </span>
</div>