<pivot-single-pannel-template [titlePage]="titlePage" id="dataSource">
    <div pannel-left-action>
        <div class="add-folder">
            <pivot-text-button class="btn-add-folder" [buttonType]="buttonType.SECONDARY" [buttonText]="BUTTON.FOLDER_CREATE"
                (btnClick)="openDialogAddFolder()">
            </pivot-text-button>
        </div>
    </div>

    <div class="datasource-pannel-left-content" pannel-left-content>
        <pivot-search-bar class="search-folder" [searchParams]="searchFolder"
            (onFilterData)="onFilterFolderData($event)">
        </pivot-search-bar>
        <div class="data-source-btn-grp">
            <pivot-text-button [iconType]="iconType.EDIT" (btnClick)="openDialogEditFolder()" *ngIf="dspBtnEditFolder"
                [buttonType]="buttonType.INFO" [buttonText]="BUTTON.EDIT" [disabled]="dsbBtnEditFolder">
            </pivot-text-button>
            <pivot-text-button class="btn-delete" *ngIf="dspBtnDeleteFolder" [iconType]="iconType.DELETE"
                (btnClick)="onDeleteFolder()" [buttonType]="buttonType.DANGER" [buttonText]="BUTTON.DELETE"
                [disabled]="dsbBtnDelFolder">
            </pivot-text-button>
        </div>
        <div class="table-folder-format">
            <pivot-table [saucerLogPagingAction] = "dataSourceListActionLog.CHANGE_PAGE" [data]="folderData" [selectedRow]="folderSelectedRow" 
            (clickRow)="filterListDataSource($event)" [selectedRows]="folderSelecteds"
                (checkRows)="checkRowsFolderTable($event)"></pivot-table>
        </div>
    </div>
    <div class="pannel-right-content" pannel-right-content>
        <div class="datasource-list-content">
            <div class="datasource-list-filter">
                <pivot-search-bar [filterLog]="dataSourceListActionLog.SEARCH_DATASOURCE" [searchParams]="searchDataSource" (onFilterData)="onFilterDatasource($event)">
                </pivot-search-bar>
                <div class="btn-datasource-grp">
                    <pivot-text-button class="btn-add-datasource" [buttonType]="buttonType.SECONDARY"
                        [disabled]="dsbBtnAddDts  || selectedFolderCd == ''" (btnClick)="openDialogOffice()" [buttonText]="addDataSourceStr">
                    </pivot-text-button>
                </div>
            </div>
            <div class="table-right">
                <pivot-table [saucerLogPagingAction] = "dataSourceListActionLog.CHANGE_PAGE" class="table" [isLoading]="isloadingDataSource" [data]="dataSourceData" (clickRow)="clickDataSourceRow($event)"
                    (checkRows)="checkRowsDataSourceTable($event)" [selectedRows]="dataSourceSelecteds"
                    [checkAllMode]="checkAllMode.ALL_PAGE">
                    <div action-buttons class="table-button-wrapper">
                        <pivot-text-button class="btn-delete" *ngIf="dspBtnDeleteDataSource" [disabled]="dsbBtnDelDts"
                            [iconType]="iconType.DELETE" (btnClick)="onDeleteDataSource()"
                            [buttonType]="buttonType.DANGER" [buttonText]="BUTTON.DELETE">
                        </pivot-text-button>
                    </div>
                </pivot-table>
            </div>
        </div>
    </div>
</pivot-single-pannel-template>

<pivot-dialog-folder-editor [isTemplate]="isDataSourceTemplate" [data]="folderEditData" [isDisplayModal]="isDspFolderEditorModal" [modalData]="modalFolder" 
[isShowAssign]="!isDataSourceTemplate" [assignPermission]="permsAssign" (onSubmitData)="onChangeDataFolder($event)">
</pivot-dialog-folder-editor>

<pivot-office-service *ngIf="isDspOfficeModal" [actionLog] = "dataSourceListActionLog" [selectedOfficeServices]="officeSelecteds"
    (closeModalHandler)="closeOfficeModal()" (selectedOfficeHandler)="selectedOffice($event)">
</pivot-office-service>

<pivot-confirm-del-selected-dialog [inputData]="delTxt" [isDisplayModal]="isDisplayModalDelete"
  [deleteItems]="deleteContent" [modalData]="modalDelete" (onSubmitData)="onShowConfirmDeleteModel($event)" [isCustomDialogForDataSourceList]="isDeleteDS" [renderArray]="renderTable">
</pivot-confirm-del-selected-dialog>