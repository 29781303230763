export class ZaitakuDTO {
    jigNo?: string;
    siteiNo?: string;
    officeNm?: string;
    sDate?: string;
    eDate?: string;
    dataTbl?: string;
    hasHospitalNearby: boolean = false;
    autoSyncColumns: string = "";
}

export class ZaitakuUserInfoDTO {
    jigNo?: string;
    siteiNo?: string;
    dataTblUserInfo?: string;
}