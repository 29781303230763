import { regex } from "uuidv4";

export const UnicodeCusor = '&#Cusor,';
//let code = text.codePointAt(0);
export const UnicodeCharacterOperator: any[] = [
  { name: '+', code: '&#43,', regex: /\+/g, regexDecode: /&#43,/g },
  { name: ' ', code: '&#32,', regex: / /g, regexDecode: /&#32,/g },
  { name: '-', code: '&#45,', regex: /-/g, regexDecode: /&#45,/g },
  { name: '*', code: '&#42,', regex: /\*/g, regexDecode: /&#42,/g },
  { name: '/', code: '&#47,', regex: /\//g, regexDecode: /&#47,/g },
  { name: '(', code: '&#40,', regex: /\(/g, regexDecode: /&#40,/g },
  { name: ')', code: '&#41,', regex: /\)/g, regexDecode: /&#41,/g },
  { name: 'x', code: '&#120,', regex: /x/g, regexDecode: /&#120,/g },
  { name: 'X', code: '&#88,', regex: /X/g, regexDecode: /&#88,/g },
  { name: ';', code: '&#59,', regex: /;/g, regexDecode: /&#59,/g },
  { name: '−', code: '&#8722,', regex: /−/g, regexDecode: /&#8722,/g },
  { name: 'ー', code: '&#12540,', regex: /ー/g, regexDecode: /&#12540,/g },
  { name: '＋', code: '&#65291,', regex: /＋/g, regexDecode: /&#65291,/g },
  { name: '＊', code: '&#65290,', regex: /＊/g, regexDecode: /&#65290,/g },
  { name: '（', code: '&#65288,', regex: /（/g, regexDecode: /&#65288,/g },
  { name: '）', code: '&#65289,', regex: /）/g, regexDecode: /&#65289,/g },
  { name: '＝', code: '&#65309,', regex: /＝/g, regexDecode: /&#65309,/g },
  { name: '＜', code: '&#65308,', regex: /＜/g, regexDecode: /&#65308,/g },
  { name: '＞', code: '&#65310,', regex: /＞/g, regexDecode: /&#65310,/g },
  { name: '！', code: '&#65281,', regex: /！/g, regexDecode: /&#65281,/g },
  { name: '％', code: '&#65285,', regex: /％/g, regexDecode: /&#65285,/g },
  { name: '０', code: '&#65296,', regex: /０/g, regexDecode: /&#65296,/g },
  { name: '１', code: '&#65297,', regex: /１/g, regexDecode: /&#65297,/g },
  { name: '２', code: '&#65298,', regex: /２/g, regexDecode: /&#65298,/g },
  { name: '３', code: '&#65299,', regex: /３/g, regexDecode: /&#65299,/g },
  { name: '４', code: '&#65300,', regex: /４/g, regexDecode: /&#65300,/g },
  { name: '５', code: '&#65301,', regex: /５/g, regexDecode: /&#65301,/g },
  { name: '６', code: '&#65302,', regex: /６/g, regexDecode: /&#65302,/g },
  { name: '７', code: '&#65303,', regex: /７/g, regexDecode: /&#65303,/g },
  { name: '８', code: '&#65304,', regex: /８/g, regexDecode: /&#65304,/g },
  { name: '９', code: '&#65305,', regex: /９/g, regexDecode: /&#65305,/g },
  { name: '{', code: '&#125,', regex: /{/g, regexDecode: /&#125,/g },
  { name: '}', code: '&#123,', regex: /}/g, regexDecode: /&#123,/g },
  { name: '\'', code: '&#39,', regex: /\'/g, regexDecode: /&#39,/g },
  { name: '\\', code: '&#92,', regex: /\\/g, regexDecode: /&#92,/g },
  { name: '!', code: '&#33,', regex: /!/g, regexDecode: /&#33,/g },
  { name: '$', code: '&#36,', regex: /\$/g, regexDecode: /&#36,/g },
  { name: '|', code: '&#124,', regex: /\|/g, regexDecode: /&#124,/g },
  { name: '`', code: '&#96,', regex: /`/g, regexDecode: /&#96,/g },
  { name: '_', code: '&#95,', regex: /_/g, regexDecode: /&#95,/g },
  { name: '?', code: '&#63,', regex: /\?/g, regexDecode: /&#63,/g },
  { name: '>', code: '&#62,', regex: />/g, regexDecode: /&#62,/g },
  { name: '<', code: '&#60,', regex: /</g, regexDecode: /&#60,/g },
  { name: '^', code: '&#94,', regex: /\^/g, regexDecode: /&#94,/g },
  { name: '@', code: '&#64,', regex: /@/g, regexDecode: /&#64,/g },
  { name: ']', code: '&#93,', regex: /]/g, regexDecode: /&#93,/g },
  { name: '[', code: '&#91,', regex: /\[/g, regexDecode: /&#91,/g },
  { name: ':', code: '&#58,', regex: /:/g, regexDecode: /&#58,/g },
  { name: '.', code: '&#46,', regex: /\./g, regexDecode: /&#46,/g },
  { name: '=', code: '&#61,', regex: /=/g, regexDecode: /&#61,/g },
  { name: '　', code: '&#12288,', regex: /　/g, regexDecode: /&#12288,/g },
]



