<div class="filter-modal" id="filter-modal" [ngStyle]="styleModal">
  <div class="filter-wrapper">
    <div class="filter-header" *ngIf="checkedAll">
      <div class="checkbox">
        <span >{{COMMON_TEXT.ALL_SELECTED}}</span>
      </div>
    </div>
    <div class="filter-body {{checkedAll ? 'scale-height' : ''}}">
      <div class="filter-search">
        <pivot-checkbox class="check-all" [value]="hasCheckedItems || this.checkedAll" (onClick)="checkAll()">
        </pivot-checkbox>
        <pivot-search-bar class="search-field" [searchParams]="searchParams" (onFilterData)="searchData($event)">
        </pivot-search-bar>
      </div>
      <div class="filter-modal-items">
        <ul id="ulfiler" class="item">
          <li class="li-filter" *ngFor="let item of lstItemRoot">
            <div *ngIf="item.level && item.level === '1'" (click)="checkItem(item)" class="level-1">
              <pivot-checkbox *ngIf="!item?.isLabel" class="check-all" [value]="item.checked">
              </pivot-checkbox>
              <label [ngClass]="item?.isLabel? 'margin-label' : 'margin-text'"> {{item.name}}</label>
            </div>
            <ul *ngIf="getListChildItem(item?.id).length > 0" class="child-item">
              <li class="li-filter-lv2" *ngFor="let child of getListChildItem(item?.id); let index = index">
                <div [ngClass]="index === 0? 'lv2-margin': ''"  (click)="checkItem(child)">
                  <pivot-checkbox *ngIf="!child?.isLabel" class="check-all" [value]="child.checked">
                  </pivot-checkbox>
                  <label [ngClass]="child?.isLabel? 'margin-label' : 'margin-text'"> {{child.name}}</label>
                </div>
              </li>
            </ul>
          </li>
        </ul>
        <div *ngIf="localData?.length == 0" class="emty-data">{{COMMON_TEXT.NO_DATA}}</div>
      </div>
    </div>
  </div>
</div>