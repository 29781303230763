<div class="row parent m-0">
    <div class="col-md-12 child" *ngIf="screenDetails.title == DISPLAY_NOT_FOUND.TITLE">
        <div class="flex-centered col-xl-4 col-lg-6 col-md-8 col-sm-12 text-center">
        <h4 class="title">{{ screenDetails.title }}</h4>
        <h2 class="title">{{ screenDetails.subtitle }}</h2>
        <small class="sub-title">
            {{ screenDetails.content }}
        </small>
        <div class="text-center">
            <button (click)="goToHome()" type="button" class="home-button">
            {{ DISPLAY_NOT_FOUND.BACK_BUTTON }}
            </button>
        </div>
        </div>
    </div>

    <div class="col-md-12 child" *ngIf="screenDetails.title != DISPLAY_NOT_FOUND.TITLE">
        <div class="flex-centered col-xl-4 col-lg-6 col-md-8 col-sm-12 text-center">
        <h4 class="title">{{ screenDetails.title }}</h4>
        <h2 class="title">{{ screenDetails.subtitle }}</h2>
        <h3 class="sub-title">
            {{ screenDetails.content }}
        </h3>
        <div class="text-center">
            <button (click)="goToHome()" type="button" class="home-button">
            {{ DISPLAY_NOT_FOUND.BACK_BUTTON }}
            </button>
        </div>
        </div>
    </div>
</div>
  