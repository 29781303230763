import { ChangeDetectorRef, Component, ElementRef, Input, OnChanges, OnInit, SimpleChanges, ViewChild, ViewEncapsulation } from '@angular/core';
import { cloneDeep, isEqual, orderBy, sortBy } from 'lodash';
import { DialogService } from 'primeng/dynamicdialog';
import { ConfirmDialogComponent } from '../../../../app/component/common/confirm-dialog/confirm-dialog.component';
import { DialogModalComponent } from '../../../../app/component/common/dialog-modal/dialog-modal.component';
import { MESSAGE_TEXT } from '../../../../app/const/message';
import { STAFF, TEAM } from '../../../const/table-col-define';
import { BUTTON, COMMON_TEXT, SYSTEM_SETTING } from '../../../../app/const/text-common';
import { ButtonIconType, ButtonType, CheckAllMode, ConductorStatus, DataType, DialogType, GuardsMode, SaveType, SearchType } from '../../../../app/enum/common-enum';
import { ButtonItem, IconModal, InputParams, ModalParam, ModalTemplate, RowOfTableStaff, SearchParams } from '../../../../app/models/common-model';
import { Role, RoleDetail } from '../../../../app/models/response/role.ro';
import { Staff } from '../../../../app/models/response/staff.ro';
import { Team } from '../../../../app/models/response/team.ro';
import { TableData } from '../../../../app/models/table-model';
import { AuthenticationService } from '../../../../app/services/authentication.service';
import { ProcessLoadingService } from '../../../../app/services/loading.service';
import { RoleService } from '../../../../app/services/modules/role.service';
import { StaffService } from '../../../../app/services/modules/staff.service';
import { TeamService } from '../../../../app/services/modules/team.service';
import Utils from '../../../../app/util/utils';
import { LocalStorageKey } from '../../../../app/_helper/local-storage.helper';
import {ErrorHandleService} from 'src/app/services/error-handle.service';
import {FUNCTION_TITLE_TEXT} from 'src/app/const/error-text';
import { NotifyService } from '../../../../app/services/modules/notify.service';
import {ConfirmUnsavedDataDialogComponent} from '../../../component/common/confirm-unsaved-data-dialog/confirm-unsaved-data-dialog.component';
import {Subscription} from 'rxjs';
import { trigger, state, style, animate, transition } from '@angular/animations';
import {DialogQuestionRoleNewValueEnteredComponent} from '../dialog-question-role-new-value-entered/dialog-question-role-new-value-entered.component';
import { DialogAddNewStaffToTeamByDragComponent } from './dialog-add-new-staff-to-team-by-drag/dialog-add-new-staff-to-team-by-drag.component';
import { SaucerLogService } from 'src/app/services/saucer-logs/saucer-log.service';
import { CONTENT_LOG, SAUCER_LOG_ACTION } from 'src/app/config/saucer-log.config';
import { SaucerLogDTO } from 'src/app/models/request/log.dto';
import { API_APP } from 'src/app/config/app.config';
import { ProcessLoadingStackService } from 'src/app/services/loading-stack.service';

@Component({
  selector: 'pivot-role-setting-staff-info',
  templateUrl: './role-setting-staff-info.component.html',
  styleUrls: ['./role-setting-staff-info.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: [
    trigger('fadeIn', [
      state('void', style({
        opacity: 0,
        transform: 'translateY(-5px)' 
      })),
      state('*', style({
        opacity: 1,
        transform: 'translateY(0)'
      })),
      transition('void => *', animate('0.4s ease-in-out'))
    ]),
    trigger('fadeOut', [
      state('*', style({
        opacity: 1,
        transform: 'translateY(0)'
      })),
      state('void', style({
        opacity: 0,
        transform: 'translateY(5px)' 
      })),
      transition('* => void', animate('0.4s ease-in-out'))
    ])
  ]
})
export class RoleSettingStaffInfoComponent implements OnInit, OnChanges {
  @Input() roles: Role[] = [];
  allRoles: Role[] = [];
  BUTTON = BUTTON;
  buttonType = ButtonType;
  iconType = ButtonIconType;
  titlePage: string = SYSTEM_SETTING.STAFF_INFO_TITLE;
  isDspConfirmInviteModal: boolean = false;
  isDspStaffEditModal: boolean = false;
  dspBtnEditTeam: boolean = false;
  dspBtnDeleteTeam: boolean = false;
  dspBtnEditStaff: boolean = false;
  dspBtnDeleteStaff: boolean = false;
  isLoadingTableStaff: boolean = false;
  staffFullNameSearch: string = '';
  selectedTeamCd: string | undefined;
  teamData: TableData;
  staffData: TableData;
  listTeam: Team[] = [];
  teamDspField: string;
  staffByTeam: Staff[] = [];
  staffsOfNewTeam: Staff[] = [];
  staffByNotTeam: Staff[] = [];
  selectedTeams: Team[] = [];
  selectedTeamsBefore: Team[] = [];
  selectedTeamsBefore_Log: Team[] = [];
  selectedTeamsAfter: Team[] = [];
  selectedTeamsAfter_Log: Team[] = [];
  selectedRoleBefore: Role | undefined;
  selectedRoleBefore_Log: Role | undefined;
  selectedRoleAfter: Role | undefined;
  selectedRoleAfter_Log: Role | undefined;
  staffEditData: Staff;
  teams: Team[] = [];
  staffs: Staff[] = [];
  allStaffs: Staff[] = [];
  teamSelecteds: Team[] = [];
  staffListSelecteds: Staff[] = [];
  rowSelected: string [] = [];
  dragScopeView: 'group-area';
  draggedStaff: RowOfTableStaff| undefined | null;
  draggedStaffList: any[] = []
  availableStaffs: RowOfTableStaff[] | undefined;
  isAddStaffToGroup: number;
  isShowDropAreaOfListTeam: boolean = false;
  teamSelected: Team;
  modalData: ModalTemplate = new ModalTemplate();
  searchTeam: SearchParams = {
    type: SearchType.input,
    placeholder: COMMON_TEXT.SEARCH,
    cssStyle: { height: '40px', margin: '10px 5px 10px 0px' },
    readonly: false,
    disabled: false,
    maxLength: 50
  };
  searchStaff: SearchParams = {
    type: SearchType.input,
    placeholder: COMMON_TEXT.SEARCH,
    cssStyle: { height: '40px', margin: '11px 5px 11px 11px', width: '550px' },
    readonly: false,
    disabled: false,
    maxLength: 50
  };
  checkAllMode = CheckAllMode;
  currentUser: any;
  sysTemText: any = SYSTEM_SETTING;
  ref: any;

  lstPendingInvitation: any[] = [];
  selectedRow: Team;
  previouslySelectedRow: Team;
  undecidedGroupLabel: string = "notteam"
  commonText = COMMON_TEXT;
  deleteStaff: any = null;
  guards: GuardsMode = GuardsMode.IDLE;
  processConductor: ConductorStatus = ConductorStatus.ENDSHOW;
  onEditRow: any;
  targetRow: any;
  dialogSub: Subscription;
  teamNameOnProcessing: string = "";
  isSameEventWithEditBtn: boolean = false;
  guardMode = GuardsMode;
  inputParams: InputParams = new InputParams();
  validateNotificationDialog: any;
  newRowData: Team = new Team();
  successCount: number = 0;
  isDragStaffs: boolean = false;
  saucerLogPagingAction: any = SAUCER_LOG_ACTION.STAFF_INFO.CHANGE_PAGE;
  
  constructor(
    private roleService: RoleService,
    private teamService: TeamService,
    private staffService: StaffService,
    private modalService: DialogService,
    private loadingService: ProcessLoadingService,
    private processLoadingStackService: ProcessLoadingStackService,
    private authenticationService: AuthenticationService,
    private errorHandleService: ErrorHandleService,
    private notifyService: NotifyService,
    private cdr: ChangeDetectorRef,
    private saucerLogService : SaucerLogService
  ) { }

  async ngOnChanges(changes: SimpleChanges): Promise<void> {
    this.allRoles = cloneDeep(this.roles);
    for (let propName in changes) {
      if (propName === 'roles' && changes[propName].firstChange === false) {
        await this.getAllStaff();
        this.authenticationService.setData(this.allStaffs, LocalStorageKey.ALL_STAFF);
        if (this.selectedTeamCd && this.selectedTeamCd != "notteam") {
          let response = await this.staffService.getByTeam(this.selectedTeamCd || '', this.currentUser.issupport);
          if (response.statuscode == 200) {
            this.staffs = response.data || [];
            this.configTableStaff();
            this.bindingStaffTable(this.staffs);
          }
        } else if (this.selectedTeamCd == "notteam") {
          await this.setStaffNotTeam();
        }
      }
    }
  }

  async ngOnInit(): Promise<void> {
    this.processLoadingStackService.loadingSomething('setting-staff', true);
    this.availableStaffs = [];
    this.getCurrentUser();
    this.configTableTeam();
    this.configTableStaff();    
    this.inputParams.placeholder = "入力してください。";
    
    let resTeam = await this.teamService.getAll();
    if (resTeam && resTeam.statuscode === 200) {
      let orderData = orderBy(resTeam.data, ['teamName'], ['asc']);
      orderData = orderData.map((e: any) => {
        return {...e, checked: false, onEdit: false};
      }) as Team[];
      const notTeam = this.createGeneralTeam();
      orderData.unshift(notTeam);
      this.teams = orderData || [];
      this.bindingTeamTable(this.teams);
    }
    await this.getAllStaff();
    await this.onClickTeamRow(this.teams[0]);
    this.selectedRow = this.teamData.body[0];
    this.processLoadingStackService.loadingSomething('setting-staff', false);
    this.staffService.getStaffsSmall(this.staffByNotTeam).then((staffs) => (this.availableStaffs = staffs));
  }

  getCurrentUser() {
    this.currentUser = this.authenticationService.currentUserValue;
  }

  async getAllStaff() {
    let resStaff = await this.staffService.getAll(this.currentUser.issupport);
    if (resStaff && resStaff.statuscode === 200) {
      this.allStaffs = resStaff.data || []
    }
  }

  bindingTeamTable(data: Team[] = []) {
    let teamTable = cloneDeep(this.teamData);
    teamTable.body = data.map((e: any) => {
      return {...e, checked: false};
    });
    this.teamData = cloneDeep(teamTable);
  }

  onShowDropAreaChange(isShowDropArea: boolean){
    this.isShowDropAreaOfListTeam = isShowDropArea;
  }

  bindingStaffTable(data: Staff[] = []) {
    const staffTable = cloneDeep(this.staffData);
    staffTable.body = data;
    this.staffData = cloneDeep(staffTable);
  }

  configTableStaff() {
    this.staffData = {
      config: {
        id: 'dat-source-1',
        caption: '',
        emptyMessage: COMMON_TEXT.NO_DATA,
        showIndex: false,
        showCheckbox: false,
        showPagination: true,
        hoverShowCheckbox: false,
        isResponsive: true,
        tableStyle: { 'margin-top': '4px', 'font-weight': 'unset !important' },
        inlineTableStyle: { 'table-layout': 'fixed', 'width': '100%' },
        disableDeleteBtnCondition: ["RL00000099", "RL00000001"]
      },
      header: [ 
        {
          field: 'dragToAddStaffToTheGroup',
          title: "",
          sortable: false,
          filterable: false,
          attribute: {
            header: { width: '2.25%' },
            row: {},
            rowClass: 'ck-row-center',
          },
          dataType: DataType.DRAG_ADD_STAFF,
          visible: true
        },
        {
          field: 'staffCd',
          title: STAFF.ID,
          sortable: false,
          filterable: false,
          attribute: {
            header: { width: '5%' },
            row: {}
          },
          dataType: DataType.TEXT,
          visible: true
        },
        {
          field: 'fullName',
          title: STAFF.FULLNAME,
          sortable: false,
          filterable: false,
          attribute: {
            header: { width: '20%' },
            row: {},
            rowClass: 'table-staff-row-text-overflow'
          },
          dataType: DataType.TEXT,
          visible: true
        },
        {
          field: 'email',
          title: STAFF.MAIL,
          sortable: false,
          filterable: false,
          attribute: {
            header: { width: '20%' },
            row: {}
          },
          dataType: DataType.TEXT,
          visible: true
        },
        {
          field: 'phone',
          title: STAFF.PHONE,
          sortable: false,
          filterable: false,
          attribute: {
            header: { width: '10%' },
            row: {}
          },
          dataType: DataType.TEXT,
          visible: true
        },
        {
          field: 'teamName',
          title: STAFF.TEAM,
          sortable: false,
          filterable: false,
          attribute: {
            header: { width: '15%' },
            row: {}
          },
          dataType: DataType.TEXT,
          visible: true
        },
        {
          field: 'roleName',
          title: STAFF.ROLE,
          sortable: false,
          filterable: false,
          attribute: {
            header: { width: '15%' },
            row: {}
          },
          dataType: DataType.TEXT,
          visible: true
        },
        {
          field: 'functionGroup',
          title: "",
          sortable: false,
          filterable: false,
          attribute: {
            header: { width: '15%' },
            row: {}
          },
          dataType: DataType.EDIT_DELETE_FUNCTION_BTN,
          visible: true
        }
      ],
      body: []
    };
  }

  configTableTeam() {
    this.teamData = {
      config: {
        id: '1',
        caption: '',
        emptyMessage: COMMON_TEXT.NO_DATA,
        showIndex: false,
        showCheckbox: false,
        showPagination: false,
        hoverShowCheckbox: true,
        isResponsive: true,
        isSelectedRow: true,
      },
      header: [
        {
          field: 'teamName',
          title: TEAM.NAME,
          sortable: false,
          filterable: false,
          attribute: {
            header: { width: '100%' },
            row: {}
          },
          dataType: DataType.TEXT,
          visible: true,
          isHasSubText: true
        },
      ],
      body: []
    };
  }

  async onClickTeamRow(data: Team) {
    this.rowSelected = [];
    this.draggedStaffList = [];
    this.dspBtnDeleteStaff = false;
    this.dspBtnEditStaff = false;
    this.staffListSelecteds = [];
    if(!this.isSameEventWithEditBtn && (( this.targetRow && this.targetRow.teamCd !== data.teamCd) || !this.targetRow)) {
      this.processConductor = ConductorStatus.ON_ROW_PLAYING;
      this.mapSelectedRowToTeam(data);
      await this.updateSelectedTeamData(data);
    }
    else {
      this.mapSelectedRowToTeam(data);
    }
    this.isSameEventWithEditBtn = false;
    this.previouslySelectedRow = data;
  }

  updateSelectedTeam(data: any) {
    const selectedRow = data;
    if(selectedRow) {
      this.teamData.body = cloneDeep(this.teamData.body.map((e: any) => {
        if(e.teamCd === selectedRow.teamCd) {
          this.selectedRow = e;
          return {...e, checked: true}
        } 
        else {
          return {...e, checked: false};
        }
      }))
    }
  }
  
  onStaffsDragged(event: any) {
    this.draggedStaff = event;
    const existingIndex = this.draggedStaffList.findIndex((staff: any) => staff.id === event.id);
    if (existingIndex !== -1) {
        this.draggedStaffList.splice(existingIndex, 1);
        this.draggedStaffList.push(this.draggedStaff);
    } else {
        this.draggedStaffList.push(this.draggedStaff);
    }
  }
  
  async drop(event: any) {
    if (!event && this.draggedStaffList.length == 0 ){
      return;
    }
    var notTeam = "notteam";
    var teamNew = "new";
    var hasEmptyTeamCd =  this.draggedStaffList.some(staff => staff.teamCd == "");
    var hasSameTeamCd = this.draggedStaffList.some(staff => staff.teamCd == event.teamCd)
    var hasDifferentTeamCd = this.draggedStaffList.some(staff => staff.teamCd != "" && staff.teamCd !== event.teamCd);
    if (event.teamCd == notTeam){
      //case nếu kéo 1 staff chưa thuộc team nào vào trường  グループ未定 thì nó sẽ hiện thông báo 
      if(hasEmptyTeamCd) return;
      //Trường hợp staff đã có team và kéo staff đó vào trường グループ未定
      else {
        await this.handleAddStaffToGroupNotTeam(event);
      }
    }else if(event.teamCd !== notTeam){
       //Trường hợp staff đã có team và kéo staff đó vào chính team nó đang thuộc
      if (hasSameTeamCd) return;
      //Trường hợp kéo 1 staff chưa có team vào team 
      else if (!event.onEdit && hasEmptyTeamCd){
        await this.handleAddStaffToTeamByDrag(event);
      }
      //Trường hợp staff đã có team và kéo staff đó vào team khác mà không phải là trường tạo mới 
      else if (event.teamCd != teamNew && hasDifferentTeamCd){
        //Nếu trường đó đang ở trạng thái mode edit
        if (event.onEdit) return;
        else {
          this.isDragStaffs = true;
          await this.getStaffbyTeamCd(event);
          const staffCdsOfNewTeam = this.staffsOfNewTeam.map(staff => staff.staffCd);
          const staffCdsOfdraggedStaffList = this.draggedStaffList.map(staff => staff.staffCd);
          const commonStaffCds = staffCdsOfdraggedStaffList.filter(staffCd => !staffCdsOfNewTeam.includes(staffCd));
          if (commonStaffCds.length == 0) {
            this.staffsOfNewTeam = [];
            this.isDragStaffs = false;
            return;
          }else {
            this.draggedStaffList = this.draggedStaffList.filter(staff => commonStaffCds.includes(staff.staffCd));
            await this.handleAddStaffToTeamByDrag(event);
          }
        }
      }
    }
  }

  async handleAddStaffToTeamByDrag(event: any) {
    let modalParam = new ModalParam();
    modalParam.description = event.teamName;
    this.ref = this.modalService.open(DialogAddNewStaffToTeamByDragComponent, {
        header: SYSTEM_SETTING.STAFF_INFO_UPDATE,
        width: '35%',
        data: {modalParam}
    });
    this.dialogSub = this.ref.onClose.subscribe(async (result: any) => {
        const eventList = [];
        eventList.push(event);
        if (result == SaveType.CANCEL || !result){
          this.isDragStaffs = false;
          return;
        }
        if (result == SaveType.SAVE) {
            let returnData = {
                role: null,
                teams: eventList
            };
            this.isDragStaffs = true;
            this.selectedTeamsBefore = [];
            await this.handleStaffEditSubmit(returnData);
            this.draggedStaffList = [];
            this.staffsOfNewTeam = [];
            this.successCount = 0;
            this.openDialogSuccess(DialogType.update);
        }
    });
  }

  async handleAddStaffToGroupNotTeam(event: any) {
    let modalParam = new ModalParam();
    modalParam.description = event.teamName;
    this.ref = this.modalService.open(DialogAddNewStaffToTeamByDragComponent, {
        header: SYSTEM_SETTING.STAFF_INFO_UPDATE,
        width: '35%',
        data: {modalParam}
    });
    this.dialogSub = this.ref.onClose.subscribe(async (result: any) => {
        if (result == SaveType.CANCEL || !result) {
          this.isDragStaffs = false;
          return;
        }
        if (result == SaveType.SAVE) {
            this.loadingService.isLoading.emit(true);
            let returnData = {
              role: null,
              teams: []
            };
            for (let i = 0; i < this.draggedStaffList.length; i++) {
              this.isDragStaffs = true;
              this.onEditStaff(this.draggedStaffList[i]);
            }
            await this.handleStaffEditSubmit(returnData);
            this.draggedStaffList = [];
            this.successCount = 0;
            this.openDialogSuccess(DialogType.update);
        }
    });
  }
  
  async handleQuestionRoleNewValueEntered(event: any) {
    this.ref = this.modalService.open(DialogQuestionRoleNewValueEnteredComponent, {
        header: SYSTEM_SETTING.STAFF_INFO_UPDATE,
        width: '35%'
    });
    this.dialogSub = this.ref.onClose.subscribe(async (result: any) => {
        if (result == SaveType.SAVE) {
            return;
        }
    });
  }
  
  findIndex(staff: RowOfTableStaff) {
    let index = -1;
    for (let i = 0; i < (this.availableStaffs as RowOfTableStaff[]).length; i++) {
        if (staff.id === (this.availableStaffs as RowOfTableStaff[])[i].id) {
            index = i;
            break;
        }
    }
    return index;
  } 
  

  async setStaff(team: Team) {
    this.teamSelected = team;
    await this.getStaffbyTeamCd(team);
    this.configTableStaff();
    this.bindingStaffTable(this.staffs);
  }

  async getStaffbyTeamCd(team: Team) {
    this.isLoadingTableStaff = true;
    let response = await this.staffService.getByTeam(team.teamCd || '', this.currentUser.issupport);
    if (response.statuscode == 200) {
      this.staffs = response.data || [];
      this.staffByTeam = this.staffs ;
      if(this.isDragStaffs){
        this.staffsOfNewTeam = this.staffs;
        this.isLoadingTableStaff = false;
      }
    }
    this.isLoadingTableStaff = false;
  }

  async setStaffNotTeam() {
    await this.getAllStaffNotTeam();
    this.configTableStaff();
    this.bindingStaffTable(this.staffs);
  }

  async getAllStaffNotTeam() {
    this.isLoadingTableStaff = true;
    let response = await this.staffService.getAllNotTeam(this.currentUser.issupport);
    if (response.statuscode == 200) {
      this.staffs = response.data || [];
      this.staffByNotTeam = this.staffs;
    }
    this.isLoadingTableStaff = false;
  }

  async onChangeTeamName(data: Team, mode: string = "update") {
    if (Utils.isNullOrEmpty(data)) {
      return;
    }

    // Update
    if (!Utils.isNullOrEmpty(this.teamNameOnProcessing) && mode === "update") {
      this.loadingService.isLoading.emit(true);
      let teamArr = [];
      let insTeam = cloneDeep(this.targetRow);
      insTeam.teamName = this.teamNameOnProcessing;
      teamArr.push(insTeam);
      let response = await this.teamService.insertOrUpdate(teamArr, true);
      if (response && response.statuscode == 200) {
        let res = await this.teamService.getAll();
        if (res && res.statuscode === 200) {
          let orderData = orderBy(res.data, ['teamName'], ['asc']);
          this.teams = cloneDeep(orderData) as Team[] || [];
          let targetTeamrow = res.data!.find(e => e.teamCd === insTeam.teamCd);
          if(targetTeamrow) {
            this.teamData.body.forEach((e: any) => {
              if(e.teamCd === targetTeamrow!.teamCd) {
                e.teamName = targetTeamrow!.teamName;
              }
            })
          }
        }
        this.teamData.body = sortBy(this.teamData.body, 'teamName');
        const notTeamIndex = this.teamData.body.findIndex(team => team.teamCd === "notteam");
        if (notTeamIndex !== -1) {
            const notTeam = this.teamData.body.splice(notTeamIndex, 1)[0];
            this.teamData.body.unshift(notTeam);
        }
        this.dspBtnDeleteTeam = false;
        this.dspBtnEditTeam = false;
        this.loadingService.isLoading.emit(false);
        this.openDialogSuccess(DialogType.update);
      } else {
        this.dspBtnDeleteTeam = false;
        this.dspBtnEditTeam = false;
        this.loadingService.isLoading.emit(false);
      }
      this.teamSelecteds = [];
    }

    // Insert
    else if(mode == "add") {
      this.loadingService.isLoading.emit(true);
      let teamArr = [];
      let insTeam = new Team();
      insTeam.teamName = this.teamNameOnProcessing;
      teamArr.push(insTeam);
      let response = await this.teamService.insertOrUpdate(teamArr, false);
      if (response && response.statuscode == 200) {
        let res = await this.teamService.getAll();
        if (res && res.statuscode === 200) {
          let orderData = orderBy(res.data, ['teamName'], ['asc']);
          this.teams = cloneDeep(orderData) as Team[] || [];
          const newTeamCd = response.data.teamcd;
          const newTeam = res.data?.find(e => e.teamCd === newTeamCd);

          if(newTeam) {
            this.newRowData = newTeam;
            this.teamData.body.push(newTeam);
            this.teamData.body = sortBy(this.teamData.body, 'teamName');
            const notTeamIndex = this.teamData.body.findIndex(team => team.teamCd === "notteam");
            if (notTeamIndex !== -1) {
                const notTeam = this.teamData.body.splice(notTeamIndex, 1)[0];
                this.teamData.body.unshift(notTeam);
            }
          }
        }
        this.dspBtnDeleteTeam = false;
        this.dspBtnEditTeam = false;
        this.loadingService.isLoading.emit(false);
        this.openDialogSuccess(DialogType.save);
      } else {
        this.dspBtnDeleteTeam = false;
        this.dspBtnEditTeam = false;
        this.loadingService.isLoading.emit(false);
      }
      this.teamSelecteds = [];
    }
  }

  checkRowsTeamTable(data: any) {
    this.selectedRow = data.value;
    this.teamData.body.forEach((e: any) => {
      e.checked = e.teamCd === data.value.teamCd;
    });
  }

  onShowEdit(event: Event, data: any, eventFrom: string) {
        this.isSameEventWithEditBtn = true;
    (this.processConductor === ConductorStatus.ON_ROW_PLAYING && this.guards === GuardsMode.IDLE) 
    ? this.teamNameOnProcessing = data.teamName : this.teamNameOnProcessing = this.teamNameOnProcessing;

    if(this.guards !== GuardsMode.IDLE) {
      this.ref = this.modalService.open(ConfirmUnsavedDataDialogComponent, {
        header: COMMON_TEXT.CONFIRM_NOT_SAVE_DATA,
        width: '35%'
      });

      this.dialogSub = this.ref.onClose.subscribe(async (result: any) => {

        if(result == SaveType.CANCEL || !result) return;

        if (result == SaveType.SAVE) {
          let mode = "update";
          this.targetRow.teamCd === "new" ? mode = "add" : "update";
          if(this.teamNameOnProcessing.trim().length == 0) {
            this.showValidateNotifyDialog();
            return;
          }
          await this.onChangeTeamName(this.targetRow, mode);
          this.mapSelectedRowToTeam(data);
          this.cdr.detectChanges();
        }
        this.targetRow = data;
        this.teamNameOnProcessing = data.teamName;
        this.reMapTeamEditStatus(data.teamCd);
        this.updateSelectedTeamData(data);
        this.clearNewRowData();
        this.guards === GuardsMode.ADD ? this.guards = GuardsMode.EDIT : this.guards;
        return true;
      })
    }
    else {
        this.guards = GuardsMode.EDIT;
        this.targetRow = data;
        this.processConductor = ConductorStatus.ON_EDIT_PLAYING;
        this.teamNameOnProcessing = data.teamName;
        this.reMapTeamEditStatus(data.teamCd);
        this.updateSelectedTeamData(data);
    }
  }

  onDestroyConfirmDialog() {
    this.ref.destroy();
  }

  reMapTeamEditStatus(teamCd: string) {
    this.teamData.body.forEach((d: any) => {
      if(d.teamCd === teamCd) {
        d.onEdit = true;
      }
      else
        d.onEdit = false;
    });
  }

  mapSelectedRowToTeam(data: any) {
      this.teamData.body.forEach((d: any) => {
        if(d.teamCd === data.teamCd) {
          d.checked = true;
        }
        else
          d.checked = false;
      });
  }

  checkRowsStaffTable(data: any) {
    if (data.data?.length >= 1) {
      this.dspBtnDeleteStaff = true;
      this.dspBtnEditStaff = data.data?.length === 1;
    }
    else {
      this.dspBtnDeleteStaff = false;
      this.dspBtnEditStaff = false;
    }
    
    this.mapSelectedRowToStaff(data);
  }

  mapSelectedRowToStaff(data: any) {
    if (data.data?.length >= 1) {
      this.staffs.forEach((d: any) => {
        const currentRow = this.staffData.body.find(b => b.id === d.id);
        if (currentRow) {
          d.checked = currentRow.checked;
        }
      });
      this.staffListSelecteds = data?.data;
    }
    else {
      const outsideSelecteds = this.staffs.filter(d => this.staffListSelecteds.every(data => data.id !== d.id));
      this.staffs.forEach((d: any) => { 
        if (outsideSelecteds.every(o => o.id !== d.id)) {
          d.checked = false;
        }
      });
      this.staffListSelecteds = outsideSelecteds;
    }
  }

  onDeleteTeam(data: any) {
    this.errorHandleService.setFunctionTitle(FUNCTION_TITLE_TEXT.DELETE_DATA_FAIL);
    this.onShowConfirmDeleteModel(data, 'team');
    this.targetRow = null;
  }

  onDeleteStaff(data: any) {
    this.deleteStaff = data;
    this.onShowConfirmDeleteModel(data, 'staff');
  }

  onShowConfirmDeleteModel(data: any, type: string) {
    let modalParam = new ModalParam();
    modalParam.description = type == 'team' ? MESSAGE_TEXT.CONFIRM_DELETE_TEAM : MESSAGE_TEXT.CONFIRM_DELETE_STAFF;
    let icon = new IconModal();
    icon.iconName = "pi-question";
    icon.iconColor = "#0073BA";
    icon.isSmallSize = false;
    modalParam.icon = icon;
    let btnOk = new ButtonItem();
    btnOk.buttonText = COMMON_TEXT.YES;
    btnOk.buttonType = ButtonType.SECONDARY;
    btnOk.clickFunction = async () => {
      if (type == 'team') {
        if (data && data.teamCd) {
          this.loadingService.isLoading.emit(true);
          let teamArr: Team[] = [];
          teamArr.push(data);
          let response = await this.teamService.delete(teamArr);
          if (response && response.statuscode == 200) {
            this.dspBtnDeleteTeam = false;
            this.dspBtnEditTeam = false;

            let res = await this.teamService.getAll();
            if (res && res.statuscode === 200) {
              const orderData = orderBy(res.data, ['teamName'], ['asc']);
              const notTeam = this.createGeneralTeam();
              orderData.unshift(notTeam);
              this.teams = orderData || [];
              this.bindingTeamTable(this.teams);
              const team = { teamName: SYSTEM_SETTING.NOT_TEAM, rowCheckboxStyle: { 'visibility': 'hidden' }, teamCd: "notteam"};
              this.selectedRow = this.teamData.body[0];
              this.mapSelectedRowToTeam(team);
              await this.updateSelectedTeamData(team);
              this.cdr.detectChanges();
            }
            this.openDialogSuccess(DialogType.delete);
          }
          this.loadingService.isLoading.emit(false);
        }
        this.teamSelecteds = [];
        this.guards = GuardsMode.IDLE;
        this.processConductor = ConductorStatus.ENDSHOW;
      }
      if (type == 'staff') {
        const deleteStaffInf = this.getStaffAllowDeleteInf();
        if (deleteStaffInf) {
          this.loadingService.isLoading.emit(true);
          let response = await this.staffService.delete(deleteStaffInf?.id);
          if (response && response.statuscode == 200) {
            await this.getAllStaff();
            this.authenticationService.setData(this.allStaffs, LocalStorageKey.ALL_STAFF);
            await this.getStaffbyTeam();
            this.openDialogSuccess(DialogType.delete);
          }
          this.loadingService.isLoading.emit(false);
        }
        this.staffListSelecteds = [];
      }
    }
    let btnCancel = new ButtonItem();
    btnCancel.buttonText = COMMON_TEXT.NO;
    btnCancel.buttonType = ButtonType.SECONDARY;
    modalParam.buttonArray = [btnOk, btnCancel];
    const dialogLogout = this.modalService.open(DialogModalComponent, {
      header: COMMON_TEXT.CONFIRM,
      width: '30em',
      data: { modalParam },
    });
  }

  onFilterTeamData(teamName: string) {
    // write log
    const log: SaucerLogDTO = {
      content: SYSTEM_SETTING.STAFF_LIST_LABEL + ": " + teamName
    };
    this.saucerLogService.action(log, {action: SAUCER_LOG_ACTION.STAFF_INFO.SEARCH});

    let tempTeamName = teamName;
    tempTeamName = tempTeamName.trim();

    if (tempTeamName == '') {
      this.bindingTeamTable(this.teams);
    } else {
      const filteredTeam = this.teams.filter((s: any) => s.teamName?.toUpperCase().includes(tempTeamName?.toUpperCase()));
      if (!filteredTeam.includes(this.teams[0])) {
        filteredTeam.unshift(this.teams[0]);
      }

      this.bindingTeamTable(filteredTeam);
    }
  }

  onFilterStaff(fullName: string) {
    // write log
    const log: SaucerLogDTO = {
      content: CONTENT_LOG.SEARCH + " " + SAUCER_LOG_ACTION.STAFF_INFO.SEARCH.screenName + ": " + fullName
    };
    this.saucerLogService.action(log, {action: SAUCER_LOG_ACTION.STAFF_INFO.SEARCH});

    this.rowSelected = [];
    this.staffFullNameSearch = fullName;
    this.clearAllSelectedStatus();
    this.staffs = this.allStaffs.filter(e => (e.fullName?.includes(fullName) || e.staffCd?.includes(fullName) || e.email?.includes(fullName) || e.phone?.includes(fullName) || e.roleName?.includes(fullName) || e.teamName?.includes(fullName)))
    this.staffListSelecteds = this.staffData.body.filter(s => s.checked);
    if (fullName == '') {
      this.selectedRow = this.previouslySelectedRow;
      this.selectedRow.checked = true;
      if (this.selectedTeamCd == "notteam") {
        this.bindingStaffTable(this.staffByNotTeam);
      } else {
        this.bindingStaffTable(this.staffByTeam);
      }
    } else {
      this.bindingStaffTable(this.staffs);
      this.selectedRow = new Team();
    }
    if (this.staffListSelecteds.length >= 1) {
      this.dspBtnDeleteStaff = true;
      this.dspBtnEditStaff = this.staffListSelecteds.length === 1;
    }
    else {
      this.dspBtnDeleteStaff = false;
      this.dspBtnEditStaff = false;
    }
  }

  openDialogSuccess(dialogType: DialogType) {
    this.modalService.open(ConfirmDialogComponent, {
      data: {
        dialogType: dialogType,
      }
    });
  }

  onEditStaff(data: any) {
    this.listTeam = [];
    this.teamDspField = "";
    this.selectedTeams = [];
    this.selectedTeamsBefore = [];
    this.selectedTeamsAfter = [];
    this.errorHandleService.setFunctionTitle(FUNCTION_TITLE_TEXT. UPDATE_DATA_FAIL);
    if(this.isDragStaffs){
      this.listTeam = cloneDeep(this.teams);
    }else {
      let indexToRemove = this.teams.findIndex(team => team.teamCd == "notteam");
      if (indexToRemove !== -1) {
        this.listTeam = cloneDeep(this.teams.slice(1));
      }else {
        this.listTeam = cloneDeep(this.teams);
      }
    }
    this.teamDspField = 'teamName';
    if (data) {
      this.staffEditData = data as Staff;
    }
    if(!this.staffEditData?.email?.includes('carekarte.jp')) {
      this.roles = this.allRoles.filter((s: any) => s.roleCd != 'RL00000099');
    }
    else {
      this.roles = cloneDeep(this.allRoles);
    }
    let selectedRole = this.roles.find(r => r.roleCd == this.staffEditData.roleCd);
    let currentSelectedStaffCd = this.staffEditData.staffCd;
    let filterAllStaff = this.allStaffs.filter(s => s.staffCd === currentSelectedStaffCd);

    filterAllStaff.forEach(staff => {
      let filterTeam = this.listTeam.find(t => t.teamCd === staff.teamCd);
      if (filterTeam) {
        this.selectedTeams.push(filterTeam);
      }
    });
    this.selectedTeamsBefore = cloneDeep(this.selectedTeams);
    this.selectedTeamsBefore_Log = cloneDeep(this.selectedTeams);
    this.selectedRoleBefore_Log = cloneDeep(selectedRole);
    this.selectedRoleBefore = cloneDeep(selectedRole);

    if (!this.isDragStaffs){
      this.isDspStaffEditModal = true;
    }
  }

  closeStaffEditModal() {
    this.isDspStaffEditModal = false;
  }

  getSelectedRoleCd(): string {
    if (this.staffEditData) {
      return this.staffEditData.roleCd || '';
    }
    return '';
  }

  async handleStaffEditSubmit(data: any) {
    if (data != null && (data.role != null || data.teams != undefined)) {
      this.loadingService.isLoading.emit(true);
      const isErrorUpdateRD = await this.updateRoleDetail(data);
      const isErrorUpdateTD = await this.updateTeamDetail(data);
      let currentUser = {
        insstfcd: this.staffEditData?.insstfcd,
        email: this.staffEditData?.email,
        fullName: this.staffEditData?.fullName
      }
      this.selectedTeamsAfter_Log = this.selectedTeamsAfter;
      this.selectedRoleAfter_Log = this.selectedRoleAfter;
      const objRoleLog = {
        old:{
          currentUser,
          role: this.selectedRoleBefore_Log
        },
        new:{
          currentUser,
          role: this.selectedRoleAfter_Log
        }
      }
      
      const objTeamLog = {
        old:{
          currentUser,
          teams: this.selectedTeamsBefore_Log
        },
        new:{
          currentUser,
          teams: this.selectedTeamsAfter_Log
        }
      }

      if (isErrorUpdateRD.apiPath) {
        this.saucerLogService.system(
          {
            apiPath: isErrorUpdateRD.apiPath,
            statusCode: isErrorUpdateRD.statusCode,
            content: JSON.stringify(objRoleLog)
          },
          {
            action: SAUCER_LOG_ACTION.STAFF_INFO.UPDATE_STAFF
          }
        );
      }

      if (isErrorUpdateTD.apiPath) {
        this.saucerLogService.system(
          {
            apiPath: isErrorUpdateTD.apiPath,
            statusCode: isErrorUpdateTD.statusCode,
            content: JSON.stringify(objTeamLog)
          },
          {
            action: SAUCER_LOG_ACTION.STAFF_INFO.UPDATE_STAFF
          }
        );
      }

      const selectedTeam = new Team();
      selectedTeam.teamCd = this.selectedTeamCd;
      this.bindingStaffTable([]);
      // await this.onClickTeamRow(selectedTeam);
      await this.getAllStaff();
      this.mapSelectedRowToTeam(selectedTeam);
      await this.updateSelectedTeamData(selectedTeam)

      this.dspBtnDeleteStaff = false;
      this.dspBtnEditStaff = false;
      this.authenticationService.setData(this.allStaffs, LocalStorageKey.ALL_STAFF);
      this.loadingService.isLoading.emit(false);
      if (!this.isDragStaffs){
        if (!isErrorUpdateRD.isError && !isErrorUpdateTD.isError) {
          this.openDialogSuccess(DialogType.update);
        }
      }
    }
    this.isDspStaffEditModal = false;
    this.isDragStaffs = false ;
    if (this.staffFullNameSearch){
      this.onFilterStaff(this.staffFullNameSearch);
    }
  }

  async updateRoleDetail(data: any): Promise<any> {
    let apiStatus = {
      isError: false,
      statusCode: '',
      apiPath: ''
    };
    
    if (data && data.role) {
      this.selectedRoleAfter = data.role;
      if (!isEqual(this.selectedRoleBefore, this.selectedRoleAfter)) {
        let rs = await this.roleService.getRoleDetailByStaffCd(this.staffEditData.staffCd || "");
        if (rs && rs.statuscode === 200) {
          if (rs.data.length > 0) {
            if (this.selectedRoleAfter && this.selectedRoleAfter.roleCd) {
              let updateData = new RoleDetail();
              updateData.id = rs.data[0].id;
              updateData.roleCd = this.selectedRoleAfter?.roleCd;
              updateData.staffCd = rs.data[0].staffcd;
              updateData.insstfcd = rs.data[0].insstfcd;
              updateData.insdate = rs.data[0].insdate;
              let arrayRoleDetail = [];
              arrayRoleDetail.push(updateData);
              let rsUpdate = await this.roleService.insertOrUpdateRoleDetail(arrayRoleDetail, true);
              apiStatus.apiPath=  API_APP.ROLE.INSERTORUPDATERD + "?isUpdate=" + true;
              if (rsUpdate && rsUpdate.statuscode === 200) {
                await this.getStaffbyTeam();
                apiStatus.statusCode = rsUpdate.statuscode;
              } else {
                apiStatus.isError = true;
                apiStatus.statusCode = rsUpdate.statuscode;
              }
            }
          } else {
            if (this.selectedRoleAfter && this.selectedRoleAfter.roleCd && this.staffEditData.staffCd) {
              let insertData = new RoleDetail();
              insertData.roleCd = this.selectedRoleAfter.roleCd;
              insertData.staffCd = this.staffEditData.staffCd;
              let arrayRoleDetail = [];
              arrayRoleDetail.push(insertData);
              let rsInsert = await this.roleService.insertOrUpdateRoleDetail(arrayRoleDetail, false);
              apiStatus.apiPath =  API_APP.ROLE.INSERTORUPDATERD + "?isUpdate=" + false;
              if (rsInsert && rsInsert.statuscode === 200) {
                await this.getStaffbyTeam();
                apiStatus.statusCode = rsInsert.statuscode;
              } else {
                apiStatus.isError = true;
                apiStatus.statusCode = rsInsert.statuscode;
              }
            }
          }
        }
        else
        apiStatus.isError = true;
        apiStatus.apiPath =  API_APP.ROLE.ROLEDETAILBYSTAFFID + "?staffcd=" + (this.staffEditData.staffCd || "");
      }
    }
    return apiStatus;
  }

  async updateTeamDetail(data: any): Promise<any> {
    let apiStatus = {
      isError: false,
      statusCode: '',
      apiPath: ''
    };
    if (data && data.teams) {
      this.selectedTeamsAfter = data.teams;
      const arrayTeamCdAfter = this.selectedTeamsAfter.map((item) => {
        return item.teamCd;
      })
      const arrayTeamCdBefore = this.selectedTeamsBefore.map((item) => {
        return item.teamCd;
      })

      const newTeam = this.selectedTeamsAfter.filter((item: Team) => {
        if (!arrayTeamCdBefore.includes(item.teamCd)) {
          return item;
        }
        return null;
      });

      const deleteTeam = this.selectedTeamsBefore.filter((item: Team) => {
        if (!arrayTeamCdAfter.includes(item.teamCd)) {
          return item;
        }
        return null;
      });

      if (deleteTeam.length > 0){
        let rsDeleteTeamDetail;
        if (this.isDragStaffs && this.draggedStaffList) {
          rsDeleteTeamDetail = await this.teamService.removeListStaffOfTeamDetail(deleteTeam, this.draggedStaffList);
          apiStatus.apiPath = API_APP.TEAM.CONTROLLER + API_APP.TEAM.REMOVELISTTEAMDETAIL
        }else if (this.staffEditData.staffCd){
          rsDeleteTeamDetail = await this.teamService.deleteTeamDetail(deleteTeam, this.staffEditData.staffCd);
          apiStatus.apiPath =  API_APP.TEAM.DELETETEAMDETAIL + "?staffDelCd=" + this.staffEditData.staffCd
        }
        if (rsDeleteTeamDetail && rsDeleteTeamDetail.statuscode === 200) {
          apiStatus.statusCode = rsDeleteTeamDetail.statuscode;
          await this.getStaffbyTeam();
        } else {
          apiStatus.isError = true;
          apiStatus.statusCode = rsDeleteTeamDetail.statuscode;
        }
      }

      if (newTeam.length > 0) {
        let rsInsertTeamDetail;
        if (this.isDragStaffs && this.draggedStaffList) {
            const insertNewTeam = newTeam[0];
            rsInsertTeamDetail = await this.teamService.insertListStaffToTeamDetail(insertNewTeam, this.draggedStaffList);
            apiStatus.apiPath =  API_APP.TEAM.CONTROLLER + API_APP.TEAM.INSERTLISTTEAMDETAIL
        } else if (this.staffEditData.staffCd) {
            rsInsertTeamDetail = await this.teamService.insertTeamDetail(newTeam, this.staffEditData.staffCd);
            apiStatus.apiPath =  API_APP.TEAM.INSERTTEAMDETAIL + "?staffInsCd=" + this.staffEditData.staffCd
        }
        if (rsInsertTeamDetail && rsInsertTeamDetail.statuscode === 200) {
            await this.getStaffbyTeam();
            apiStatus.statusCode = rsInsertTeamDetail.statuscode;
        } else {
          apiStatus.isError = true;
          apiStatus.statusCode = rsInsertTeamDetail.statuscode;
        }
      }
    }
    return apiStatus;
  }

  async getStaffbyTeam() {
    if (this.selectedTeamCd && this.selectedTeamCd != "notteam") {
      let response = await this.staffService.getByTeam(this.selectedTeamCd || '', this.currentUser.issupport);
      if (response.statuscode == 200) {
        this.staffs = response.data || [];
        this.staffByTeam = this.staffs ;
      }
    } else if (this.selectedTeamCd == "notteam") {
      let response = await this.staffService.getAllNotTeam(this.currentUser.issupport);
      if (response.statuscode == 200) {
        this.staffs = response.data || [];
        this.staffByNotTeam = this.staffs; 
      }
    }
    this.configTableStaff();
    this.bindingStaffTable(this.staffs);
  }

  async onShowDlg() {
    this.modalData.header = SYSTEM_SETTING.INVITE_DLG_TITLE;
    this.modalData.style = { 'width': '50%' };
    this.modalData.breakpoints = { '960px': '75vw', '640px': '100vw' };
    this.isDspConfirmInviteModal = true;

    this.loadingService.isLoading.emit(true);
    this.lstPendingInvitation = await this.notifyService.getPendingInvitation();
    this.loadingService.isLoading.emit(false);
  }

  onSubmitDlg(data: any) {
    this.isDspConfirmInviteModal = false;
  }

  createGeneralTeam(): Team {
    const team = { teamName: SYSTEM_SETTING.NOT_TEAM, rowCheckboxStyle: { 'visibility': 'hidden' }, teamCd: "notteam"};
    return team;
  }

  getStaffAllowDeleteInf() {
    const selectedStaffs = this.deleteStaff;
    if (selectedStaffs) {
      // check isAdmin
      // if admin then not show delete button
      if (selectedStaffs.roleCd === 'RL00000001' || selectedStaffs.roleCd === 'RL00000099') {
        return null;
      }
      // Users cannot delete themselves
      if (String(selectedStaffs.staffCd) === String(this.currentUser.staffid)) {
        return null;
      }
      return selectedStaffs;
    }
    return null;
  }

  isShowBtnDeleteStaff() {
    if (this.getStaffAllowDeleteInf()) return true;
    return false;
  }

  async rowSaveChange(data: any) {
    let currentEdit: Team = new Team();
    if(this.teamNameOnProcessing.trim().length == 0) {
      this.showValidateNotifyDialog();
      return;
    }
    if(data.teamCd !== "new") {
      currentEdit =  data;
      await this.onChangeTeamName(data);
    }
    else {
      await this.onChangeTeamName(data, "add");
      currentEdit = this.newRowData;
    }
    this.clearAllEditStatus();
    this.clearAllSelectedStatus();
    this.clearNewRowData();
    this.selectedRow = currentEdit;
    this.updateSelectedTeamData(currentEdit);
    this.mapSelectedRowToTeam(currentEdit);
    this.guards = GuardsMode.IDLE;
    this.processConductor = ConductorStatus.ENDSHOW;
    this.targetRow = null;
  }

  rowCancel(data: any) { 
    if(data.teamCd === "new") {
      this.teamData.body.pop();
    }
    this.deleteStaff = null;
    this.guards = GuardsMode.IDLE;
    this.processConductor = ConductorStatus.ENDSHOW;
    this.clearAllEditStatus();
    this.clearAllSelectedStatus();
    this.mapSelectedRowToTeam(data);
    const checkStaffOfTeam = this.staffData.body.some(item => item.teamCd === data.teamCd);
    if (this.staffFullNameSearch != "" && !checkStaffOfTeam ){
      this.clearAllSelectedStatus();
      this.selectedRow = new Team();
      this.selectedTeamCd = "";
      this.teamData = cloneDeep(this.teamData);
    }
    this.targetRow = null;
  }
  rowDelete(data: any) {}

  clearAllSelectedStatus() {
    this.teamData.body.forEach((e: any) => {
      e.checked = false;
    })
  }

  clearAllEditStatus() {
    this.teamData.body.forEach((e: any) => {
      e.onEdit = false;
    })
  }

  async updateSelectedTeamData(data: any) {
    if (data && data.teamCd != "notteam") {
      this.selectedTeamCd = data.teamCd;
      await this.setStaff(data);
    } else if (data && data.teamCd == "notteam") {
      this.selectedTeamCd = "notteam";
      await this.setStaffNotTeam();
    }
  }

  async addNewTeam() {
    let newTeam =  {
      teamName: "",
      checked: false,
      onEdit: true,
      teamCd: "new"
    };
    if(this.guards !== GuardsMode.IDLE) {
      this.dialogSub ? this.dialogSub.unsubscribe() : true;
      this.ref = this.modalService.open(ConfirmUnsavedDataDialogComponent, {
        header: COMMON_TEXT.CONFIRM_NOT_SAVE_DATA,
        width: '35%'
      });

      this.dialogSub = this.ref.onClose.subscribe(async (result: any) => {
        if(result == SaveType.CANCEL || !result) return;

        if (result == SaveType.SAVE) {
          if(this.teamNameOnProcessing.trim().length == 0) {
            this.showValidateNotifyDialog();
            return;
          }
          let mode = "update";
          this.targetRow.teamCd === "new" ? mode = "add" : "update";
          await this.onChangeTeamName(this.targetRow, mode);
        }
        this.clearAllSelectedStatus();
        this.clearAllEditStatus();
        this.teamNameOnProcessing = newTeam.teamName;
        this.clearNewRowData();
        this.targetRow = newTeam;
        let tempTable = cloneDeep(this.teamData);
        tempTable.body.push(newTeam);
        this.teamData = tempTable;
        this.guards = GuardsMode.ADD;
        this.processConductor = ConductorStatus.ON_EDIT_PLAYING;
        !Utils.isNullOrEmpty(this.teamNameOnProcessing) ? this.teamNameOnProcessing = "" : true;
        setTimeout(()=> this.scrollToLastestItem(), 300);
        return true;
      })
    }
    else {
      this.guards = GuardsMode.ADD;
      this.processConductor = ConductorStatus.ON_EDIT_PLAYING;
      this.targetRow = newTeam;
      let tempTable = cloneDeep(this.teamData);
      tempTable.body.push(newTeam);
      this.teamData = tempTable;
      this.clearAllSelectedStatus();
      !Utils.isNullOrEmpty(this.teamNameOnProcessing) ? this.teamNameOnProcessing = "" : true;
    }
    setTimeout(()=> this.scrollToLastestItem(), 300);
  }

  clearNewRowData() {
    this.teamData.body.forEach((e: any, index) => {
      if(e.teamCd === "new") {
        this.teamData.body.splice(index, 1);
      };
    })
  }

  showValidateNotifyDialog(){
    this.validateNotificationDialog = this.modalService.open(DialogQuestionRoleNewValueEnteredComponent, {
      header: COMMON_TEXT.CONFIRM_NOT_SAVE_DATA,
      width: '35%'
    });
  }
  scrollToLastestItem() {
    const element = document.getElementById('lastestToScroll');
    if(!!element && !!(element.offsetWidth || element.offsetHeight)) {
      element.scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'nearest' });
    }
  }
}
