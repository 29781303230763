<div class="pivot-textbox input-style" [ngStyle]="inputParams.inputStyle">
    <div class="pivot-textbox-content" *ngIf="!inputParams.hidden">
        <input class="pivot-input" type={{inputType}} placeholder="{{inputParams.placeholder}}" id={{inputId}}
            [disabled]="inputParams.disabled" [readonly]="inputParams.readonly" [ngModel]="inputValue"
            [maxlength]="inputParams.maxLength ? inputParams.maxLength : null"
            [minlength]="inputParams.minLength ? inputParams.minLength : null"
            [ngClass]="{
                'border-fill-textbox': !inputParams.borderFill, 
                'input-readonly': inputParams.readonly && inputParams.borderFill,
                'pivot-input-err': !(inputParams.readonly && inputParams.borderFill) && inputParams.validate && (showValidateErr || inputParams.isValidate),
                'pen-disabled': !(inputParams.readonly && inputParams.borderFill) && !(inputParams.validate && (showValidateErr || inputParams.isValidate)) && (!inputEdit && inputParams.pencil),
                'input-padding': !(inputParams.readonly && inputParams.borderFill) && !(inputParams.validate && (showValidateErr || inputParams.isValidate)) && inputEdit && inputParams.pencil,
                'input-text-center': textCenter,
                'border-red-err': inputParams.borderRed
            }"
            (keydown)="onKeyDowns($event)" (click)="onClicks($event)" (focus)="onFocusIn($event)"
            (focusout)="onFocusOutInput($event)" (input)="onInputData($event)" (keyup)="onKeyUps($event)" />
        <button *ngIf="inputParams.pencil" [ngClass]="inputParams.disabled || inputParams.readonly ? 'pen-disabled' : ''"
            class="pivot-pen" (click)="onClickPencil()"></button>
        <span *ngIf="canClear && !empty(inputValue)" class="item-delete" (click)="clearText()">
            <i class="pi pi-times"></i>
        </span>
    </div>
    <label *ngIf="(inputParams.validate && showValidateErr) || (inputParams.validate && inputParams.isValidate)"
        class="pivot-validate-err">{{inputParams.validateError}}</label>
</div>