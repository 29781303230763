<div class="page-template">
  <div class="pannel-left"[ngClass]="{'expanded': isExpanded}" >
    <div class="pannel-left-wrapper" >
      <div class="pannel-content pannel-left-content">
        <div class="header action">
          <ng-content select="[pannel-left-action]"></ng-content>
        </div>
        <div class="body content">
          <ng-content select="[pannel-left-content]"></ng-content>
        </div>
      </div>
    </div>
  </div>
  <div class="pannel-content pannel-right">
    <div class="header title-page"> {{titlePage}}</div>
    <div class="body content">
      <ng-content select="[pannel-right-content]"></ng-content>
    </div>
  </div>
</div>