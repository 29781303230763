import { AfterViewInit, Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import bb, { donut } from "billboard.js";
import { ChartBase } from '../chart.base';
import Utils from "../../../../util/utils";

@Component({
  selector: 'pivot-doughnut-chart',
  templateUrl: './doughnut-chart.component.html',
  styleUrls: ['./doughnut-chart.component.scss']
})
export class DoughnutChartComponent extends ChartBase {
  
  nodeId = "js-chart-doughnut"

  override ngOnInit() {
    super.ngOnInit();
    this.data = Utils.replaceYenCharacterOnChart(this.data);
  }
  
  generateChart(): void {
    const onrendered = ()=>{
      this.onRenderComplete.emit();
    }
    const onresize = ()=>{
      this.onStartRender.emit();
    }
    const renderChart = () =>{
      this.chart.flush();
    }
    this.chart = bb.generate({
      boost: {
        useWorker: false,
      },
      zoom: {
        enabled: this.zoomEnabled
      },
      legend: {
        show: this.data.legendShow
      },
      data: {
        columns: this.data.columns,
        type: donut(),
      }, 
      render: {
        lazy: true,
        observe: true
      },
      onrendered: onrendered,
      onresize: onresize,
      size: this.size,
      bindto: this.bindTo
    }); 
    this.onStartRender.emit();

    //delay render chart to have time for memory cleanup
    let timedelay = 100;
    if(this.data.columns.length>0){
      let dataCount = this.data.columns[0].length * this.data.columns.length;
      if(timedelay < dataCount*this.TIME_DELAY) timedelay= dataCount*this.TIME_DELAY;
      if(timedelay > 10000) timedelay= 10000;
    }
    setTimeout(renderChart, timedelay);
  }
}
