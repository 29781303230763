<pivot-modal-template *ngIf="isDisplayModal" [modalTemplate]="modalData" (closeModalHandler)="onClose()">
    <div modal-body >
        <div class="add-folder-content">
            <div class="folder-name-grp">
                <label>{{FOLDER_DIALOG.FOLDER_DLG_NAME}} <span class="input-required">{{COMMON_TEXT.ASTERISK}}</span></label>
                <pivot-textbox [inputParams]="inputFolderNameParams" (onInput)="onInputFolderName($event)"
                    (onFocusOut)="inputFolderFocusOut()"
                    [inputValue]="newFolder.name"></pivot-textbox>
            </div>
            <div class="margin-grp">
                <label>{{COMMON_TEXT.DESCRIPTION}}</label>
                <pivot-textbox [inputParams]="inputDesParams" (onInput)="onInputDescription($event)"
                    [inputValue]="newFolder.description"></pivot-textbox>
            </div>
            <ng-container *ngIf="isShowAssign" >
                <div class="margin-grp rgp-access-name"  >
                    <div class="grp-name">
                      <div class="grp-name-lable">
                        <label>{{FOLDER_DIALOG.FOLDER_DLG_SHARE}}
                          <span class="input-required">{{COMMON_TEXT.ASTERISK}}</span>
                        </label>
                        <div class="helpcontent" *ngIf="isDisplaySystemSetting">
                          <div class="help" (mouseover)="onTargetMouseover()"  (mouseleave)="onTargetMouseout()">?</div>
                          <div class="help-panel" *ngIf="showPanel"  (mouseenter)="onPanleMousenter()"  (mouseleave)="onPanleMouseout()">
                            <span class="ds-suggest-header"> グループ作成は <a (click)="gotoStaffInfo()">こちら</a></span> 
                          </div>
                        </div>
                      </div>
                        <pivot-search-bar [searchParams]="assignTypeParams" [_disable]="disableAssignPermission"
                            (onFilterData)="onFilterAssignType($event)">
                        </pivot-search-bar>
                    </div>
                    <div class="grp-access">
                        <label>{{FOLDER_DIALOG.FOLDER_DLG_ACCESS}} <span *ngIf="!(disableAccessPermission || disableAssignPermission)" class="input-required">{{COMMON_TEXT.ASTERISK}}</span></label>
                        <pivot-search-bar [filterLog] = "folderActionLog" [searchParams]="actionParamsDisable" *ngIf="(assignType == ONLY_ME)" [_disable]="true">
                        </pivot-search-bar>
                        <pivot-search-bar [filterLog] = "folderActionLog" [searchParams]="actionParams" *ngIf="!(assignType == ONLY_ME)" [_disable]="(disableAccessPermission || disableAssignPermission)"  (onFilterData)="onAccessFilterData($event)">
                        </pivot-search-bar>
                    </div>
                </div>
                <div class="margin-grp">
                    <label>{{COMMON_TEXT.NAME}} <span *ngIf="!(disableInputName || disableAssignPermission)" class="input-required">{{COMMON_TEXT.ASTERISK}}</span></label>
                    <pivot-auto-complete [selectedData]="assignModel" [data]="listActionName"
                        [field]="listAccessFieldDisplay" [disabled]="disableInputName || disableAssignPermission"
                        (onUnSelectData)="onRemoveAssignData($event)" (onChangeData)="onChangeAssignList($event)"
                        [inputParams]="autoCompleteParams"
                        [excludeSearchData]="excludeSearchData">
                    </pivot-auto-complete>
                </div>
            </ng-container>

        </div>
    </div>
    <div modal-bottom class="add-folder-content">
        <div class="btn-grp">
            <pivot-text-button class="btn-add-folder" (btnClick)="onClose()" [buttonType]="buttonType.SECONDARY"
                [buttonText]="BUTTON.CANCEL">
            </pivot-text-button>
            <pivot-text-button class="btn-add-folder" (btnClick)="onSubmitFolder()"
                [buttonType]="buttonType.SECONDARY" [buttonText]="BUTTON.CREATE">
            </pivot-text-button>
        </div>
    </div>
</pivot-modal-template>