export const environment = {
  production: true,
  PIVOT_CLIENT_API_URL: 'https://r70ucm0qff.execute-api.ap-northeast-1.amazonaws.com/stage/',
  IMAGE_FOLDER: 'user-img',
  USER_DATA_URL: 'https://d3b0cjrics0dj9.cloudfront.net/',
  CKPASS_FORGOT_PW: 'https://stage-pass.carekarte.jp/forgot-password?module=pivot',
  CKPASS_WEBAPP_URL: 'https://stage-pass.carekarte.jp/',
  SAUCER_LOG_API_URL:'https://2tkbp5fip4.execute-api.ap-northeast-1.amazonaws.com/Prod/',
  SAUCER_LOG_TOKEN:'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJtb2R1bGUiOiJQSVZPVCIsImlhdCI6MTcwODkwNTYwMH0.ZoKoqIT35J9JU6dh2xQVh0GJb8z-eS9OhXj7mhmXwsg'
};
