import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { API_APP } from '../../../app/config/app.config';
import { ApiService } from '../api.service';
import {ErrorHandleService} from '../error-handle.service';

@Injectable({ providedIn: 'root' })
export class SyncLogDataService extends ApiService {

  constructor(http: HttpClient, router: Router, errorHandle: ErrorHandleService) {
    super(http, router, errorHandle);
  }

  async getByPackCd(packCd: string) {
    let parameter = [
      packCd
    ];
    let apiUrl = API_APP.SYNCLOGDATA.CONTROLLER + API_APP.SYNCLOGDATA.GETBYPACK;
    let resData =  await this.get(apiUrl, parameter);
    return resData;
  }

  writeLogs(bodyRequest: any) {
    let uri = API_APP.SYNCLOGDATA.CONTROLLER + API_APP.SYNCLOGDATA.WRITELOGS;
    return this.post(uri, [], bodyRequest);
  }


}
