import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
@Injectable()
export class DashboardFilterService {
  private officeList: BehaviorSubject<string[]> = new BehaviorSubject<string[]>([]);
  officesObservable$: Observable<any> = this.officeList.asObservable();
  constructor() { }
  setDataObservable(newOfficeName: string[]) {
    this.officeList.next(newOfficeName);
  }
}