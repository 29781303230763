import { ActivityLog } from "../api-model";
import { Office } from "./office.ro";
import { Role } from "./role.ro";

export class Staff extends ActivityLog {
    id?: string;
    staffCd?: string;
    fullName: string;
    kanaName: string; // フリガナ
    occupations?: Array<string>; // 職種 (Array of occupationName)
    positions?: Array<string>; // 役職  (Array of positionName)
    userId?: string; // ユーザーID
    email?: string; // メール
    phone?: string;
    roles?: Array<Role>; // ロール (Array of Role)
    belongs?: Array<Office>; // 所属 (Array of Office)
    phoneNunber?: string;
    firstName?: string = '';
    lastName?: string = '';
    teamCd?: string;
    teamName?: string;
    roleCd?: string;
    roleName?: string;
    isSupport?: boolean;
}

export class StaffList extends ActivityLog {
    id?: string;
    staffCd?: string;
    fullName: string;
    kanaName: string; // フリガナ
    occupations?: Array<string>; // 職種 (Array of occupationName)
    positions?: Array<string>; // 役職  (Array of positionName)
    userId?: string; // ユーザーID
    email?: string; // メール
    roles?: Array<Role>; // ロール (Array of Role)
    belongs?: Array<Office>; // 所属 (Array of Office)
    phoneNunber?: string;
    teamName?: string;
}