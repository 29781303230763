import { ActivityLog } from "../api-model";
import { Assign } from "./assign.ro";

export class Dashboard extends ActivityLog {
  id?: string;
  dashboardCd?: string;
  name: string; // 名前
  description: string; // 説明
  folderId: string; // フォルダ
  folderCd?: string; // フォルダ
  folderName?: string;
  setting: Array<DashboardSetting>;
  assign: Assign;
  delflg?: boolean;
  publicCd?: string;
  publicSDate?: string;
  publicEDate?: string;
  publictype?: number;
  sortcoltype?: string;
  jigno?: string;
  siteino?: string;
}

export class DashboardList extends ActivityLog {
  id?: string;
  dashboardCd?: string;
  name: string; // 名前
  description: string; // 説明
  folderId: string; // フォルダ
  folderCd?: string; // フォルダ
  folderName?: string;
  assign?: Assign;
  delflg?: boolean;
  publicCd?: string;
  publictype?: number;
  dashboardName?: string;
}

export class DashboardSetting {
  widgetId: string;
  widgetCd: string;
  widgetName?:string;
  widgetPosition: string; // example: '{x: 0, y: 0, rows: 7, cols: 14, width: null, height: null}'
  x: number; // position x of grid
  y: number; // position y of grid
  cols: number; // number of rows in grid
  rows: number; // number of columns in grid
  width?: number;
  height?: number;
  type?: number;
  isHaveSize?: boolean;
  startdate?: Date;
  enddate?: Date;
  datasourceCd?: string;
  period? : string;
  isNew? : boolean;
  datasourceName? : string;
  publictype?: number;
  sortArr?: any[];
  widgetConfig?: any;
  widgetdetails?: any[];
  isLoadWidgetResultComplete?: boolean = false;
  widgetResult?: any;
  syncStatus?: number;
  isLoadDataSource? : boolean;
  charttype?: string;
  detailid?: string;
  tableSize?: any;
  loading?: boolean = false;;
}

export class DashboardModel {
  id?: string;
  dashboardcd?: string;
  foldercd?: string;
  dashboardname?: string;
  publiccd?: string;
  dashboarddesc?: string;
  publictype?: number;
  delflg?: boolean;
  insstfcd?: string;
  updstfcd?: string;
  insdate?: Date;
  upddate?: Date;
}

export class DashboardDetailModel {
  id: string;
  dashboardcd: string;
  widgetcd: string;
  widgetposition?: any;
  isupdate?: boolean;
  widgetnew?: boolean;
}

