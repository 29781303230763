import { AfterViewChecked, Component, OnInit, Renderer2, ViewEncapsulation, ViewChild, ElementRef} from '@angular/core';
import { cloneDeep, remove } from 'lodash';
import { DialogService } from 'primeng/dynamicdialog';
import { LocalStorageHelper, LocalStorageKey } from 'src/app/_helper/local-storage.helper';
import { ConfirmDialogComponent } from '../../../app/component/common/confirm-dialog/confirm-dialog.component';
import { BUTTON, COMMON_TEXT, MENU_NAME, SYSTEM_SETTING, SYSTEM_SETTING_CODE } from '../../../app/const/text-common';
import { ButtonType, DialogType, InputType, MenuCode, ScreenMode, SearchType, TypeOfNotification } from '../../../app/enum/common-enum';
import { DropdownOption, ModalTemplate, NotifyParams, SearchParams } from '../../../app/models/common-model';
import { CorpMstModel } from '../../../app/models/response/corpMst.ro';
import { Role, RoleModel } from '../../../app/models/response/role.ro';
import { ProcessLoadingService } from '../../../app/services/loading.service';
import { CorpMstService } from '../../../app/services/modules/corpmst.service';
import { RoleService } from '../../../app/services/modules/role.service';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { ROUTE_PATH } from 'src/app/const/route-path';
import { SystemSettingPage } from 'src/app/const/const';
import {ErrorHandleService} from 'src/app/services/error-handle.service';
import {FUNCTION_TITLE_TEXT} from 'src/app/const/error-text';
import { DialogRoleEdiorComponent } from '../dialog-role-editor/dialog-role-editor.component';
import { MESSAGE_TEXT } from 'src/app/const/message';
import { DialogQuestionRoleNewValueEnteredComponent } from './dialog-question-role-new-value-entered/dialog-question-role-new-value-entered.component';
import { ConfirmUnsavedDataDialogComponent } from 'src/app/component/common/confirm-unsaved-data-dialog/confirm-unsaved-data-dialog.component';
import { DynamicDialogRef } from 'primeng/dynamicdialog';
import { ProcessNotificationService } from '../../services/process-notification.service';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { SaucerLogService, SAUCER_LOG_ACTION } from '../../services/saucer-logs/saucer-log.service';
import { CONTENT_LOG } from 'src/app/config/saucer-log.config';
import { ProcessLoadingStackService } from 'src/app/services/loading-stack.service';

@Component({
  selector: 'pivot-role-setting',
  templateUrl: './role-setting.component.html',
  styleUrls: ['./role-setting.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class RoleSettingComponent implements OnInit, AfterViewChecked {
  @ViewChild(DialogRoleEdiorComponent) dialogComponent: DialogRoleEdiorComponent;
  @ViewChild('insertItem') insertItemInput: ElementRef;
  @ViewChild('elementInput') elementInput: ElementRef<HTMLInputElement>;
  @ViewChild('itemOfListBox') pannelEle: ElementRef<HTMLInputElement>;
  @ViewChild('hollow') pivotDrawerHidden: ElementRef<HTMLInputElement>;
  _roleService: RoleService;
  _processLoadingService: ProcessLoadingService;
  _corpMstService: CorpMstService;

  BUTTON = BUTTON;
  MENU_NAME = MENU_NAME;
  COMMON_TEXT = COMMON_TEXT;
  SYSTEM_SETTING = SYSTEM_SETTING;
  buttonType = ButtonType;
  systemSettingPage = SystemSettingPage;

  selectedRole: Role;
  selectedRoleInEdit: Role;
  roleNewHaveNullValue: Role;
  mode: ScreenMode = ScreenMode.PREVIEW;

  monthSelected: DropdownOption;
  preselectedRole: any;
  countRoleNew: any;
  countIsNull:any;
  listPermissions: any[] = [];
  monthlys: DropdownOption[] = [];

  currentPage: string = SystemSettingPage.ROLE_SETTING;

  delTxt = SYSTEM_SETTING.ROLE;

  batchcheckDisable = false;
  checkAllPMS: boolean = false;
  displayDialog: boolean;
  isDisableEditBtn: boolean = true;
  displayInsertDialog: boolean = false;
  isInputContainerVisible: boolean = false;
  showButtonAddRole: boolean = true;
  showDialogQues: boolean = false;
  changeRow: boolean = false ;
  cancelBtn: boolean;
  saveBtn: boolean;
  isPressButton:boolean ;
  isDisplayModalDelete: boolean = false;
  isDisableAllCheckboxBtn: boolean = false;
  isDisabledAllPermission: boolean = false;
  isChangeRoleList: boolean = false;
  showBottomInput: boolean = false;
  temRoleCd: string;
  temRoleId: string;
  idOfRolesHaveShowEditIsTrue: string | undefined;
  newRole: Role = new Role();
  nameOfRoleNew: string = '';
  roleEditModalData: Role = new Role();
  roleInsertModalData: Role = new Role();
  monthSetting: CorpMstModel = new CorpMstModel();
  modalDelete: ModalTemplate = new ModalTemplate();
  roleEditModal: ModalTemplate = new ModalTemplate();
  roleInsertModal: ModalTemplate = new ModalTemplate();

  inputRoleParams: any = {
    type: InputType.text,
    validate: true,
    validateError: MESSAGE_TEXT.REQUIRE_INPUT,
    borderFill: true,
    maxLength: 100,
  };

  searchParams: SearchParams = {
    type: SearchType.input,
    placeholder: COMMON_TEXT.SEARCH,
    cssStyle: { width: '223px', height: '40px' },
    readonly: false,
    disabled: false,
    maxLength: 100,
  }

  listRoles: Role[] = [];
  permissions = [
    {
      permissioncd: "1",
      permissionname: MENU_NAME.DATASOURCE,
      checked: true,
      isDisableCheckbox: false,
      menuCode: MenuCode.DATASOURCE,
      parent: false
    },
    {
      permissioncd: "2",
      permissionname: MENU_NAME.DASHBOARD_LIST,
      checked: true,
      isDisableCheckbox: false,
      menuCode: MenuCode.DASHBOARD_LIST,
      parent: false
    },
    {
      permissioncd: "5",
      permissionname: MENU_NAME.AGGREGATION_LIBRARY,
      checked: true,
      isDisableCheckbox: false,
      menuCode: MenuCode.LIST_ITEM_TEMPLATE,
      parent: false
    }
  ];
  permissionRight = [{
    permissioncd: "3",
    permissionname: MENU_NAME.SYSTEM_SETTING,
    checked: true,
    isDisableCheckbox: false,
    menuCode: MenuCode.SYSTEM_SETTING,
    parent: false
  },
  {
    permissioncd: "4",
    permissionname: MENU_NAME.TAB_SEIKUY,
    checked: true,
    isDisableCheckbox: false,
    menuCode: MenuCode.TAB_SEIKUY,
    parent: false
  }
];

  rolesOrigin: Role[];
  isShowIcon: boolean;
  rolesHaveShoweditroleIsTrue: Role[];
  roleDefault: string[] = ["RL00000099",  "RL00000001",  "RL00000002"];
  tabActive = "tab-btn-1"
  currentEven: any;
  isDialogOpen: boolean;
  typeMode: number = 2; // 0 is role is formal format, 1 is when role is in insert format, 2 is when role is edit format
  
  // Delete warning dialog's declarations
  isDisplayWarningDialog: boolean = false;
  modalDataWarning: ModalTemplate = new ModalTemplate();
  warningMessage: string = '';
  isCurrentRoleCanDel: boolean = true;

  //#region Variables for "Include Edate Discharge"
  includeEdateDischarge: boolean = false;
  edateDischargeSetting: CorpMstModel = new CorpMstModel();
  systemSetting = {
    changeStartMonth: false,
    changeIncludeEdateDischarge: false
  }
  hasZaitaku: boolean = false;

  timeoutSearchId: any = null;
  roleSettingLog : any = { log: {}, action: SAUCER_LOG_ACTION.ROLE_SETTING.VIEW };
  corpSettingLog : any = { log: {}, action: SAUCER_LOG_ACTION.CORP_SETTING.VIEW };
  staffInfoLog : any = { log: {}, action: SAUCER_LOG_ACTION.STAFF_INFO.VIEW };
  syncCkMstLog : any = { log: {}, action: SAUCER_LOG_ACTION.SYNC_CK_MASTER.VIEW };
  defaultSettingLog : any = { log: {}, action: SAUCER_LOG_ACTION.DEFAULT_SETTING.VIEW };
  //#endregion

  constructor(
    public refBackPage: DynamicDialogRef,
    private router: ActivatedRoute,
    private location: Location,
    private modalService: DialogService,
    private roleService: RoleService,
    private processLoadingService: ProcessLoadingService,
    private processLoadingStackService: ProcessLoadingStackService,
    private activateRoute: ActivatedRoute,
    private corpMstService: CorpMstService,
    private errorHandleService: ErrorHandleService,
    private renderer: Renderer2,
    private processNotificationService: ProcessNotificationService,
    private authenticationService: AuthenticationService,
    private saucerLogService: SaucerLogService,
  ) {
    this._roleService = roleService;
    this._processLoadingService = processLoadingService;
    this._corpMstService = corpMstService;
    this.activateRoute.params.subscribe(params => {
      let activeTag = params['active'];
      if(activeTag){
        this.tabActive = activeTag;
      }
    });

  }

  async ngOnInit() {
    this.saucerLogService.action({}, 
      {
        action: SAUCER_LOG_ACTION.ROLE_SETTING.VIEW
      }
    );
    const isShowIcon = false;
    this.modalDataWarning.header = COMMON_TEXT.CONFIRM;

    this.processLoadingStackService.loadingSomething('role-setting', true);
    this.errorHandleService.setFunctionTitle(FUNCTION_TITLE_TEXT.SCREEN_INIT_FAIL);
    this.router.paramMap.subscribe(paramMap => {
      this.currentPage = paramMap.get('page') as string;
    });
    for (let i = 1; i < 13; i++) {
      this.monthlys.push({ name: i + '月', value: i })
    }

    this.checkHasZaitaku();
    
    let res = await this._corpMstService.getAll();
    if (res.statuscode == 200) {
      this.monthSetting = res.data?.find(x => x.contentcd == SYSTEM_SETTING_CODE.MONTH_SETTING) || new CorpMstModel();
      this.monthSelected = this.monthlys[Number(this.monthSetting.value) - 1];

      this.edateDischargeSetting = res.data?.find(x => x.contentcd == SYSTEM_SETTING_CODE.INCLUDE_EDATE_DISCHARGE) || new CorpMstModel();
      this.includeEdateDischarge = (this.edateDischargeSetting.value == '1');
    }

    await this.getListRoles();
    this.roleEditModal.header = SYSTEM_SETTING.ROLE_EDIT;
    this.roleEditModal.style = { 'width': '393px' };

    this.roleInsertModal.header = SYSTEM_SETTING.ROLE_CREATE;
    this.roleInsertModal.style = { 'width': '393px' };
    this.modalDataWarning.style = { width: '25%', height: '20%'}
    this.setDisableCheckBox();
    this.disableAllCheckbox(true);

    this.processLoadingStackService.loadingSomething('role-setting', false);
  }

  checkHasZaitaku() {
    this.hasZaitaku = this.authenticationService.getData(LocalStorageKey.HAS_ZAITAKU) == "1" ? true : false;
  }

  ngAfterViewChecked(): void {
    var findlistbox = document.getElementsByClassName('p-listbox-list-wrapper');
    if (findlistbox[0] !== undefined) {
      findlistbox[0].classList.add('set-width-listbox');
    }
  }

  async openDialogInsert() {
    this.typeMode = 1;
    let countRoleEditting = this.listRoles.filter((role) => role.showEdit).length;
    if (countRoleEditting >= 1){
      await this.showDialogQuestionEdit(this.currentEven);
    }
    this.displayInsertDialog = true;
    this.showBottomInput = true;
    this.createValueRoleInsert();
    await this.scrollToBottom();
    this.countIsNull = this.listRoles.filter((role) => role.name == '').length;
    if(this.countIsNull >= 1){
      this.showButtonAddRole = false;
    }
  }

  createValueRoleInsert(){
    this.roleNewHaveNullValue = new Role();
    this.roleNewHaveNullValue.name = "";
    this.roleNewHaveNullValue.id = "null";
    this.roleNewHaveNullValue.style = {'min-height': '63px'};
    this.listRoles.push(this.roleNewHaveNullValue);
    this.listRoles = cloneDeep(this.listRoles);
  }

  scrollToBottom() {
    setTimeout(()=>{
      if (this.pivotDrawerHidden && this.pivotDrawerHidden.nativeElement) {
        this.pivotDrawerHidden.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'nearest' });
      }
    },450)
      if((this.selectedRole || this.showButtonAddRole) &&  this.insertItemInput){
        setTimeout(()=>{ 
          this.insertItemInput.nativeElement.focus();
        },1000)
      }
  }

  async onRemoveRole(roleCd: string) {
    this.errorHandleService.setFunctionTitle(FUNCTION_TITLE_TEXT.DELETE_DATA_FAIL);
    this._processLoadingService.isLoading.emit(true);
    let checkRes: any = await this.roleService.checkBeforeDelete(roleCd);
    if(checkRes && checkRes.statuscode && checkRes.statuscode === 200 ) {
      if(checkRes.data && checkRes.data == true) {
        this.isDisplayModalDelete = true;
      }
      else {
        this.warningMessage = SYSTEM_SETTING.ROLE_DELETE_DECLINE;
        this.isDisplayWarningDialog = true;
        this.isCurrentRoleCanDel = false;
      }
    }
    this._processLoadingService.isLoading.emit(false);
  }

  onFilterTable(value: any) {
    if (!value) {
      this.listRoles = cloneDeep(this.rolesOrigin);
      return;
    }
    this.listRoles = this.rolesOrigin.filter(r => r.name.toLowerCase().includes(value.trim().toLowerCase()));
    if (this.timeoutSearchId) clearTimeout(this.timeoutSearchId);
    this.timeoutSearchId = setTimeout(() => {
      this.saucerLogService.action({content: CONTENT_LOG.SEARCH + ": " + value}, {action: SAUCER_LOG_ACTION.ROLE_SETTING.SEARCH});
    }, 3000);
  }
  
  async showDialogQuestionEdit(currentEvent: any): Promise<number> {
    try {
    this.isDialogOpen = true;
    this.refBackPage = this.modalService.open(ConfirmUnsavedDataDialogComponent, {
      header: COMMON_TEXT.CONFIRM_NOT_SAVE_DATA,
      width: '35%',
      data: { currentEvent }
    });
    const x = await new Promise<number>(resolve => {
      this.refBackPage.onClose.subscribe(async result => {
        const x = await result;
        // x == 1: when user press はい button in dialog
        // x == 2: when user press いいえ button in dialog
        // x == 3: when user press キャンセル button in dialog
        switch (x) {
          case 1:
            this.callOnSubmitRoleData();
            break;
          case 2:
            this.cancel();
            break;
          case 3:
            return;
          default:
            this.listRoles = cloneDeep(this.listRoles);
            break;
        }
      });
    }
    );
    return x;
    }
      finally {
        this.isDialogOpen = false; 
      }
  }

  async selectRole(event: any) {
    this.temRoleId = event.option.id;
    this.selectedRole = event.value;
    if (this.selectedRoleInEdit !== undefined) {
      this.updateEditRoles();
      this.processButtonActions();
    }
    await this.businessProcessingWhenChooseRole(event);
    this.isPressButton = false;
    this.showDialogQues = false;
  }

  processButtonActions() {
    if (this.cancelBtn) {
        this.updateListRoles((role: Role) => {
            role.showEdit = false;
        });
        this.cancelBtn = false;
    }

    if (this.saveBtn) {
        this.updateListRoles((role: Role) => {
            role.showEdit = false;
        });
        this.saveBtn = false;
    }
  }
  
  updateListRoles = (callback: (role : Role) => void ) =>  this.listRoles.forEach(callback);

  updateEditRoles() {
    let countRoleEditting = this.listRoles.filter((role) => role.showEdit).length;
    this.handleRoleEditMode(countRoleEditting);
  }

  handleRoleEditMode(countRoleEditting: Number) {
    if (this.selectedRoleInEdit.id !== this.temRoleId) return;
    this.listRoles.forEach(role => {
      switch (countRoleEditting) {
        case 0:
          if (this.typeMode != 0 ){
            if (role.id === this.temRoleId) role.showEdit = true;
          }
          break;
        case 1:
          if (role.id === this.selectedRoleInEdit.id) role.showEdit = true;
          let countRoleEditting = this.listRoles.filter((role) => role.showEdit).length;
          if (countRoleEditting > 1){
            if (role.id === this.selectedRoleInEdit.id) role.showEdit = false;
          }
          break;
        default:
          if (role.id === this.selectedRoleInEdit.id) role.showEdit = false;
          break;
      }
    })
  }

  onDoubleClick(event: any): void {
    this.countRoleNew = this.listRoles.filter((role) => role.id == 'null').length;
    this.listRoles.forEach((role) => {
      if (role.id === this.selectedRole.id && role.showEdit == true) {
        role.showEdit = true;
      }
    });
  }

  businessProcessingWhenChooseRole(event: any){
    const style: any = {};
    if(event.value.id !== "null"){
      this.setDisableCheckBox();
      this.selectedRole = cloneDeep(event.value);
      this.preselectedRole = cloneDeep(event.value);
      this.listPermissions = this.listPermissions.map((permissionEl: any) => {
        if (!permissionEl.parent &&
          (event.value.lstpermission.some((e: any) => e.permissioncd === permissionEl.permissioncd))) {
          permissionEl.checked = true;
        } else if (permissionEl.parent) {
          this.listPermissions.forEach((item) => {
            if (item.permissioncd === permissionEl.parent) {
              const subcount = this.listPermissions.filter(item => item.parent === permissionEl.parent).length;
              const subcountChecked = this.listPermissions.filter(item => item.parent === permissionEl.parent && item.checked === true).length;
              if (subcountChecked === subcount) {
                item.checked = true;
              } else {
                item.checked = false;
              }
            }
          });
          if (permissionEl.isDefault == true) {
            permissionEl.checked = true;
          } else {
            const parent = event.value.lstpermission.find((perEl: any) => permissionEl.parent === perEl.permissioncd);
            if (parent &&
              (parent.childpermissions.length > 0) &&
              parent.childpermissions.some((e: any) => e.childcd === permissionEl.permissioncd.substr(3, 3))) {
              permissionEl.checked = true;
            } else {
              permissionEl.checked = null;
            }
          }
                  } else {
          permissionEl.checked = null;
        }
        return permissionEl;
      });
            if (this.listPermissions.filter(item => item.checked === true).length === this.listPermissions.length) {
        this.checkAllPMS = true;
      } else {
        this.checkAllPMS = false;
      }
      this.setMenuLst(this.selectedRole);
      if (this.roleDefault.includes(this.selectedRole.roleCd || "")) {
        this.disableAllCheckbox(true);
      } else {
        this.disableAllCheckbox(false);
      }
      this.checkAllCheckbox();
    }else {
        this.showBottomInput = true;
        if (this.pannelEle) {
          this.renderer.setStyle(this.pannelEle, 'background', 'white');
        }
    }
  }

  cancel(isEditable = true) {
    const inputElement = document.getElementById('element-input');
    const inputElementAfter = document.getElementById('element-input-after');
    const btnCancel = document.getElementById('button-cancel');
    if (inputElement) {
      this.listRoles.pop();
      this.listRoles = cloneDeep(this.listRoles);
      inputElement.classList.add('hidden');
    }
    if (inputElementAfter) {
      this.listRoles = cloneDeep(this.listRoles);
    }
    this.isInputContainerVisible = false;
    this.listRoles.forEach((role) => {
        if (role.showEdit === true ) {
          role.showEdit = false;
        }
    });

    this.displayInsertDialog = false;
    this.showButtonAddRole = true;
    this.cancelBtn = false;
    this.listRoles.map((role:any)=>{
      role.isDisable = false;
    })
    let countRoleEditting = this.listRoles.filter((role) => role.showEdit).length;
    this.countIsNull = this.listRoles.filter((role) => role.name == '').length;

    if (this.selectedRoleInEdit !== undefined ){
      this.selectedRoleInEdit = new Role();
    }

    if (isEditable){
      if(this.selectedRole && this.typeMode == 2 && this.changeRow){
        this.listRoles.forEach((role) => {
          if (role.id === this.selectedRole.id) {
            role.showEdit = true;
          }
        });
        if (this.insertItemInput) {
          setTimeout(() => {
            this.insertItemInput.nativeElement.focus();
          }, 0);
        }
      }
    }

    if(this.selectedRoleInEdit){
      if (countRoleEditting == 0 && (this.selectedRoleInEdit.showEdit == false || !this.selectedRoleInEdit) 
      && this.countIsNull == 0 && ((inputElementAfter && btnCancel)) && this.typeMode == 1){
        this.openDialogInsert();
      }
    }else {
      if (countRoleEditting == 0 && (!this.selectedRoleInEdit) 
      && this.countIsNull == 0 && ((inputElementAfter && btnCancel)) && this.typeMode == 1){
        this.openDialogInsert();
      }
    }
    this.typeMode = 0;
      }

  async showDialogNoNewValueHasBeenEnterdYet(){
    try {
    this.isDialogOpen = true;
    this.refBackPage = this.modalService.open(DialogQuestionRoleNewValueEnteredComponent, {
      header: COMMON_TEXT.CONFIRM,
      width: '25%'
    });
    const x = await new Promise<number>(resolve => {
      this.refBackPage.onClose.subscribe(async result => {
        const x = await result;
        // x == 2: close dialog
        if (x == 2){
          if (this.elementInput) {
            const indexToRemove = this.listRoles.findIndex(role => role.id === 'null');
            if (indexToRemove !== -1) {
              this.listRoles.splice(indexToRemove, 1);
              await this.getListRoles();
              this.showButtonAddRole = true;
            }
            this.renderer.addClass(this.elementInput.nativeElement, 'hidden');
          }
        }
      });
    });
    }
      finally {
        this.isDialogOpen = false;
      }
  }

  callOnSubmitRoleData(isEditable = true){
    this.listRoles.map((role:any)=>{
      role.isDisable = false;
    })
    const input = document.getElementById("insert-item") as HTMLInputElement;
    if(this.insertItemInput){
      this.nameOfRoleNew = input.value;
    }
    if (this.nameOfRoleNew == "" || /^\s*$/.test(this.nameOfRoleNew)){
      this.showDialogNoNewValueHasBeenEnterdYet();
    }else {
      this.nameOfRoleNew = this.nameOfRoleNew.replace(/^\s+|\s+$/g, '');
      this.SubmitRoleData(isEditable);
    }
    if (this.typeMode == 0 &&  !isEditable  && (this.saveBtn || this.saveBtn === undefined) ){
      this.listRoles.forEach((role) => {
        if (role.id === this.selectedRole.id) {
          role.showEdit = false;
        }
      });
    }
    this.listRoles = cloneDeep(this.listRoles)
  }
  
  async SubmitRoleData(isEditable = true){
    if (!this.dialogComponent.name && this.dialogComponent.name !== "") {
      this.inputRoleParams.isValidate = true;
      this.inputRoleParams.validate = true;
      return;
    }
    const roleNew = this.listRoles.find((role) => role.name === '');

    if (this.nameOfRoleNew !== this.dialogComponent.name && this.selectedRole.name !== "" && !roleNew ){
      this.newRole.name = this.nameOfRoleNew;
      await this.handleRoleEditDialogClose(this.newRole);
    }
    else if (this.nameOfRoleNew == this.dialogComponent.name){
      this.newRole.name = this.dialogComponent.name;
      await this.handleRoleEditDialogClose(this.newRole);
    }
    else if (this.nameOfRoleNew !== " " && this.nameOfRoleNew !== this.selectedRole.name && roleNew ){
      this.newRole.name = this.nameOfRoleNew;
      this.handleRoleInsertDialogClose(this.newRole);
    }
    else {
      this.newRole.name = this.nameOfRoleNew;
      this.handleRoleInsertDialogClose(this.newRole);
    }
    this.saveBtn = false;
    this.listRoles.map((role:any)=>{
      role.showEdit = false;
    })
    
    if (isEditable){
      if(this.selectedRole && this.typeMode == 2 && this.changeRow ){
        this.changeRow = false;
        this.listRoles.forEach((role) => {
          if (role.id === this.selectedRole.id) {
            role.showEdit = true;
          }
        });
        if(this.insertItemInput){
          setTimeout(() => {
            this.insertItemInput.nativeElement.focus();
          }, 0);
        }
      }
      this.typeMode = 0;
    }else {
      this.typeMode = 0;
    }
  }

  setDisableCheckBox() {
    this.isDisabledAllPermission = false;
    this.listPermissions.forEach(ele => {
      if (ele.isDefault) {
        ele.isDisableCheckbox = true;
      } else {
        ele.isDisableCheckbox = false;
      }
    });
  }

  async showEditFormatRole(rolecd: any ) {
    this.typeMode = 2;
    this.listRoles.forEach(async (role) => {
      if (role.roleCd == "RL00000002" || role.roleCd == "RL00000001" || role.roleCd == "RL00000099"){
        role.showEdit = false;
      }
    })
    this.typeMode == 2 ? this.changeRow = true : this.changeRow = false;
    await this.checkEditNewRole();
    this.selectedRoleInEdit = this.selectedRole;
    if (this.isDisableEditBtn) return;
    this.isInputContainerVisible = !this.isInputContainerVisible;
    if(this.selectedRoleInEdit !== undefined){
      this.rolesHaveShoweditroleIsTrue = this.listRoles.filter((role) => role.showEdit == true);
      if (this.rolesHaveShoweditroleIsTrue){
        this.rolesHaveShoweditroleIsTrue.forEach((role) => {
          this.idOfRolesHaveShowEditIsTrue = role.id;
        });
      }
      if(typeof rolecd === 'string'){ //click to button pen
        this.listRoles.forEach(async (role) => {
          if ((role.showEdit && ( (rolecd != this.idOfRolesHaveShowEditIsTrue) ) ) || 
              (role.id == 'null' && ((rolecd != this.idOfRolesHaveShowEditIsTrue))) ) { 
            this.showDialogQues = true ;
            await this.showDialogQuestionEdit(this.selectedRoleInEdit);
          }else {
            return;
          }
        });
      }
      else if(typeof rolecd === 'object'){ // double click to button pen
        this.listRoles.forEach(async (role) => {
          if ((role.showEdit && ( (rolecd.value.id != this.idOfRolesHaveShowEditIsTrue) ) ) || 
              (role.id == 'null' && ((rolecd.value.id != this.idOfRolesHaveShowEditIsTrue)))  ) { 
            await this.showDialogQuestionEdit(this.selectedRoleInEdit);
          }else {
            return;
          }
        });
      }
      this.isPressButton = true ;
    }

    this.setModalData(this.selectedRole);
    const elementFinal = this.listRoles[this.listRoles.length - 1];
    if (this.selectedRole.id == elementFinal.id){
      this.scrollToBottom();
    }else {
      if(this.insertItemInput){
          this.insertItemInput.nativeElement.focus();
      }
    }
    this.idOfRolesHaveShowEditIsTrue = "";
  }
  
  async checkEditNewRole(){
    const checkRoleidNullHave = this.listRoles.findIndex(role => role.id === 'null');
    const roleWithIdNull = this.listRoles.find(role => role.id === 'null');
    if(this.showButtonAddRole === false && checkRoleidNullHave && this.selectedRole.id !== roleWithIdNull?.id ){
      await this.showDialogQuestionEdit(this.currentEven);
    }
  }

  handleCheckAll(value: boolean) {
    this.checkAllPMS = value;

    this.permissions.forEach(p => {
      if (!p.isDisableCheckbox || !this.isDisabledAllPermission) {
        p.checked = value;
      }
    });
    this.permissionRight.forEach(p => {
      if (!p.isDisableCheckbox || !this.isDisabledAllPermission) {
        p.checked = value;
      }
    });
  }

  handlePermissionCheck(value: boolean, permission: any) {
    permission.checked = value;
    setTimeout(() => {
      this.checkAllCheckbox();
    }, 300);
  }

  async fetchAllData() {
    let resData = await this._roleService.getAll();
    if (resData.statuscode == 200) {
      this.listRoles = resData?.data || [];
      if(this.selectedRole && this.showButtonAddRole == false) {
        this.listRoles = cloneDeep(resData.data?.map((e: any) => {
          if(e.id === this.selectedRole.id) {
            return {...e, showEdit: true};
          }
          else
            return {...e};
        })) as Role[];
      }
      const user = JSON.parse(LocalStorageHelper.getCurrentUser());
      const email = user.email;
      const pattern = /^[a-zA-Z0-9._%+-]+@carekarte\.jp$/;
      if (!pattern.test(email)) remove(this.listRoles, (x) => { return x.roleCd === "RL00000099"})

      this.rolesOrigin = cloneDeep(this.listRoles);
    }
  }

  async getListRoles() {
    await this.fetchAllData();
    this.selectedRole = this.listRoles[0];
    this.setModalData(this.selectedRole);
    this.setMenuLst(this.selectedRole);
    this.disableAllCheckbox(true);
    this.isDisableAllCheckboxBtn = true;
    this.isDisableEditBtn = true;
    this.checkAllPMS = true;
  }

  async handleRoleEditDialogClose(event: Role) {
    this.errorHandleService.setFunctionTitle(FUNCTION_TITLE_TEXT.SAVE_DATA_FAIL);
    this.isChangeRoleList = false;
    this.displayDialog = false;

    if (event && event.name) {
      this._processLoadingService.isLoading.emit(true);
      let reqData = [];
      let dashData = this.mapModelRole(event.name, true, false);
      reqData.push(dashData);

      let res = await this._roleService.insertOrUpdate(reqData, true);
      if (res.statuscode == 200 && res.data) {
        await this.fetchAllData();
        let res = await this._roleService.getDetail(this.selectedRole.roleCd || "");
        if (res.statuscode == 200) {
          this.selectedRole = res.data || new Role();
        }
        this.setMenuLst(this.selectedRole);
        this.isChangeRoleList = true;
        this.openDialogSuccess(DialogType.update);
      }
      this._processLoadingService.isLoading.emit(false);
      this.roleInsertModalData = new Role();
    }
    this.isInputContainerVisible = false;
    let countRoleEditting = this.listRoles.filter((role) => role.showEdit).length;
    if (countRoleEditting == 0 && this.typeMode == 1){
      this.openDialogInsert();
    }
    let countRoleNew = this.listRoles.filter((role) => role.id == 'null').length;
    this.selectedRole = this.listRoles.find(role => role.id === this.selectedRole.id) ?? new Role();
    if (countRoleNew == 1 ){
      this.showButtonAddRole = false;
    }else {
      this.showButtonAddRole = true;
    }
  }

  setModalData(selectedRole: Role) {
    this.roleEditModalData = { ...selectedRole };
  }

  async handleRoleInsertDialogClose(event: Role) {
    this.isChangeRoleList = false;
    this.displayInsertDialog = false;

    if (event && event.name) {
      this._processLoadingService.isLoading.emit(true);
      let reqData = [];
      let dashData = this.mapModelRole(event.name, false, false);
      reqData.push(dashData);

      let res = await this._roleService.insertOrUpdate(reqData);
      if (res.statuscode == 200 && res.data) {
        await this.getListRoles();
        this.isChangeRoleList = true;
        this.openDialogSuccess(DialogType.save);
      }
      this._processLoadingService.isLoading.emit(false);
      this.roleInsertModalData = new Role();
    }
  }

  setMenuLst(data: Role) {
    let lstMenu = data.permissions;
    this.permissions = [
      {
        permissioncd: "1",
        permissionname: MENU_NAME.DATASOURCE,
        checked: lstMenu.includes(MenuCode.DATASOURCE),
        isDisableCheckbox: false,
        menuCode: MenuCode.DATASOURCE,
        parent: false
      },
      {
        permissioncd: "2",
        permissionname: MENU_NAME.DASHBOARD_LIST,
        checked: lstMenu.includes(MenuCode.DASHBOARD_LIST),
        isDisableCheckbox: false,
        menuCode: MenuCode.DASHBOARD_LIST,
        parent: false
      },
      {
        permissioncd: "5",
        permissionname: MENU_NAME.AGGREGATION_LIBRARY,
        checked: lstMenu.includes(MenuCode.LIST_ITEM_TEMPLATE),
        isDisableCheckbox: false,
        menuCode: MenuCode.LIST_ITEM_TEMPLATE,
        parent: false
      }
    ];
    this.permissionRight = [{
      permissioncd: "3",
      permissionname: MENU_NAME.SYSTEM_SETTING,
      checked: lstMenu.includes(MenuCode.SYSTEM_SETTING),
      isDisableCheckbox: false,
      menuCode: MenuCode.SYSTEM_SETTING,
      parent: false
    },
    {
      permissioncd: "4",
      permissionname: MENU_NAME.TAB_SEIKUY,
      checked: lstMenu.includes(MenuCode.TAB_SEIKUY),
      isDisableCheckbox: false,
      menuCode: MenuCode.TAB_SEIKUY,
      parent: false
    },
  ];
  }

  checkAllCheckbox() {
    let res = true;
    this.permissions.forEach(element => {
      if (!element.checked) res = false;
    });
    this.permissionRight.forEach(element => {
      if (!element.checked) res = false;
    });
    this.checkAllPMS = res;
  }

  getMenuLst() {
    let lstMenu = '';
    let ischeckAll = true;
    this.permissions.forEach(element => {
      if (element.checked) {
        lstMenu += (element.menuCode + ",");
      } else {
        ischeckAll = false;
      }
    });
    this.permissionRight.forEach(element => {
      if (element.checked) {
        lstMenu += (element.menuCode + ",");
      } else {
        ischeckAll = false;
      }
    });
    if (lstMenu) lstMenu = lstMenu.slice(0, -1);
    if (ischeckAll) lstMenu = "#-1";
    return lstMenu;
  }

  async onSaveLstMenu() {
    this.errorHandleService.setFunctionTitle(FUNCTION_TITLE_TEXT.SAVE_DATA_FAIL);
    this._processLoadingService.isLoading.emit(true);
    let lstMenu = this.getMenuLst();
    let reqData = [];
    let dashData = this.mapModelRole(lstMenu, true, true);
    dashData.id = this.selectedRole?.id || '';
    dashData.rolecd = this.selectedRole?.roleCd || '';
    reqData.push(dashData);

    let res = await this._roleService.insertOrUpdate(reqData, true, true);
    if (res.statuscode == 200 && res.data) {
      await this.fetchAllData();
      this.selectedRole = cloneDeep(this.listRoles.find(x => x.roleCd == this.selectedRole.roleCd) || new Role());
      this.openDialogSuccess(DialogType.save);
    }
    this._processLoadingService.isLoading.emit(false);
  }

  mapModelRole(data: any, isUpdate: boolean, isSetMenuLst: boolean) {
    let req = new RoleModel();
    if (isSetMenuLst) {
      req.menulst = data;
    } else {
      req.rolename = data;
    }
    if (isUpdate) {
      const role = this.listRoles.find((role) => role.showEdit === true);
      if(role)
      {
        req.id = role?.id || "";
        req.rolecd = role?.roleCd || "";
        req.insdate = role.insdate;
        req.insstfcd = role.insstfcd || "";
      }
      else{
        req.id = this.selectedRole?.id || "";
        req.rolecd = this.selectedRole?.roleCd || "";
        req.insdate = this.selectedRole.insdate;
        req.insstfcd = this.selectedRole.insstfcd || "";
      }
    }
    req.delflg = false;

    return req;
  }

  async onSubmitDeleteModel(type: any) {
    if (!type) {
      this.isDisplayModalDelete = false;
      return;
    } else {
      this._processLoadingService.isLoading.emit(true);
      let ids = [];
      ids.push(this.selectedRole.roleCd);
      let res = await this._roleService.delete(ids);
      if (res.data) {
        await this.getListRoles();
        this.openDialogSuccess(DialogType.delete);
      }
      else if(!this.isCurrentRoleCanDel) {
        let notifyParams = new NotifyParams();
        notifyParams.isDisplay = true;
        notifyParams.status = false
        notifyParams.value = SYSTEM_SETTING.ROLE_DELETE_DECLINE;
        notifyParams.typeOfNoti = TypeOfNotification.OTHER;
        this.processNotificationService.dataNotify.emit(notifyParams);
        this.isCurrentRoleCanDel = true;
      }
      this._processLoadingService.isLoading.emit(false);
      this.isDisplayModalDelete = false;
    }
  }

  openDialogSuccess(dialogType: DialogType) {
    this.modalService.open(ConfirmDialogComponent, {
      data: {
        dialogType: dialogType,
      }
    });
    this.isInputContainerVisible = false;
    this.showButtonAddRole = true;
  }

  async onSaveMonth() {
    this._processLoadingService.isLoading.emit(true);
    this.errorHandleService.setFunctionTitle(FUNCTION_TITLE_TEXT.SAVE_DATA_FAIL);

    let reqData = this.mapModelMonthSetting();
    let res = await this._corpMstService.insertOrUpdate(reqData, true);
    if (res.statuscode == 200 && res.data) {

      this.systemSetting.changeIncludeEdateDischarge = false;
      this.systemSetting.changeStartMonth = false;
      this._processLoadingService.isLoading.emit(false);
      this.openDialogSuccess(DialogType.save);
    } else {
      this._processLoadingService.isLoading.emit(false);
    }
  }

  mapModelMonthSetting() {
    let reqData = [];
    let noUpdate = !this.systemSetting.changeStartMonth && !this.systemSetting.changeIncludeEdateDischarge;
    if (noUpdate || this.systemSetting.changeStartMonth) {
      let corpMst = new CorpMstModel();
      corpMst.id = this.monthSetting.id;
      corpMst.corpcd = this.monthSetting.corpcd;
      corpMst.contentcd = this.monthSetting.contentcd;
      corpMst.content = this.monthSetting.content;
      corpMst.insdate = this.monthSetting.insdate;
      corpMst.insstfcd = this.monthSetting.insstfcd;
      corpMst.upddate = this.monthSetting.upddate;
      corpMst.updstfcd = this.monthSetting.updstfcd;
      corpMst.delflg = this.monthSetting.delflg;
      corpMst.value = this.monthSelected.value.toString();
      reqData.push(corpMst);
    }

    if (this.hasZaitaku && (noUpdate || this.systemSetting.changeIncludeEdateDischarge)) {
      let corpMst = new CorpMstModel();
      corpMst.id = this.edateDischargeSetting.id;
      corpMst.corpcd = this.edateDischargeSetting.corpcd;
      corpMst.contentcd = this.edateDischargeSetting.contentcd;
      corpMst.content = this.edateDischargeSetting.content;
      corpMst.insdate = this.edateDischargeSetting.insdate;
      corpMst.insstfcd = this.edateDischargeSetting.insstfcd;
      corpMst.upddate = this.edateDischargeSetting.upddate;
      corpMst.updstfcd = this.edateDischargeSetting.updstfcd;
      corpMst.delflg = this.edateDischargeSetting.delflg;
      corpMst.value = this.includeEdateDischarge ? '1' : '0'
      reqData.push(corpMst);
    }

    return reqData;
  }

  disableAllCheckbox(isDisable: boolean) {
    this.isDisableAllCheckboxBtn = false;
    this.isDisableEditBtn = false;
    if (this.selectedRole.roleCd == "RL00000001" || this.selectedRole.roleCd == "RL00000099") {
      this.isDisableAllCheckboxBtn = true;
      this.permissions.forEach(element => {
        element.isDisableCheckbox = isDisable;
      });
      this.permissionRight.forEach(element => {
        element.isDisableCheckbox = isDisable;
      });
    } else if (this.selectedRole.roleCd == "RL00000002") {
      this.isDisableAllCheckboxBtn = false;
    }

    if (this.roleDefault.includes(this.selectedRole.roleCd || "")) {
      this.isDisableEditBtn = true;
    }
  }

  changeTab(page: string) {
    this.currentPage = page;
    this.location.go(LocalStorageHelper.getUrl(ROUTE_PATH.SYSTEM_SETTING) + '/' + this.currentPage);
  }

  closeWarningDialog() {
    this.isDisplayWarningDialog = false;
    this.warningMessage = '';
    this.isCurrentRoleCanDel = true;
  }

  onCheckEdateDischarge() {
    this.includeEdateDischarge = !this.includeEdateDischarge;
    this.systemSetting.changeIncludeEdateDischarge = true;
  }
  onChangeStartMonth(event: any) {
    this.systemSetting.changeStartMonth = true;
  }
}
