import { Component, EventEmitter, HostListener, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { ButtonIconType, ButtonType } from '../../../app/enum/common-enum';
import { InputParams } from '../../../app/models/common-model';
import { Location } from '@angular/common';
import { cloneDeep } from 'lodash';
import { BUTTON } from '../../../app/const/text-common';

@Component({
  selector: 'pivot-splitter-panel-template',
  templateUrl: './splitter-panel-template.component.html',
  styleUrls: ['./splitter-panel-template.component.scss']
})
export class SplitterPanelTemplateComponent implements OnInit, OnChanges {
  @Input() titlePage: string = "";
  @Input() name: any = null;
  @Input() nameParams: InputParams = new InputParams();
  @Input() isDisplayControlIcon: boolean = true;
  @Input() isDisplayToolTip: boolean = false;
  @Input() isDisplayPannel: boolean = true;
  @Input() isDisplayLeftPannel: boolean = true;
  @Input() isDisplayRightPannel: boolean = true;
  @Input() panelLeftSize: any[] = [];
  @Input() panelRightSize: any[] = [];
  @Input() isDisplayDSSetting: boolean = false;
  // pannel left default size
  @Input()   defaultSizeLPanel: any[] = [20, 80];
  // default size right panel
  @Input() defaultSizeRPanel: any[] = [18.5, 81.5];
  @Output() nameFieldHandler = new EventEmitter<any>();
  @Output() backPageHandler = new EventEmitter<any>();
  @Output() dockerListHandler = new EventEmitter<any>();
  isBackUsed = false;
  _dockerList: any[] = [];
  get dockerList(): any[] {
    return this._dockerList;
  }
  @Input() set dockerList(list: any[]) {
    this._dockerList = list;
  }

  showControlPopup: boolean = false;
  iconType = ButtonIconType;
  buttonType = ButtonType;
  BUTTON = BUTTON;
  isExpanded: boolean = false;
  backIcon: string = '';
  optionIcon: string = '';
  minSizeLefPanel: any[] = [0,0];
  minSizeRightPanel: any[] = [0, 0];
  // min width panel left
  widthLeft: number = 0;
  // min width panel right
  widthRight: number = 0;
  // min width panel
  minWidth: number = 17;
  // max width panel
  maxWidth: number = 86;
  // width start
  widthStart: any = 0;
  // wid end
  widthEnd: any = 0;
  // Limited expansion control panel
  elastiCities: any = 60;

  constructor(private _location: Location) {
  }


  @HostListener('dblclick', ['$event'])
  onDoubleClick(event: Event) {
    const target = event.target as HTMLElement;
    if (target.classList.contains('p-splitter-gutter')) {
      this.clearDockerList();
      this.resetPanelSize();
    }
  }

  ngOnInit(): void {
    this.isBackUsed = this.backPageHandler.observers.length > 0;
    this.nameParams.maxLength = 100;
    this.nameParams = cloneDeep(this.nameParams);
    this.isDisplayLeftPannel = true;
    this.isDisplayRightPannel = true;
    if(this.panelLeftSize.length == 0) this.panelLeftSize = this.defaultSizeLPanel;
    if(this.panelRightSize.length == 0) this.panelRightSize = this.defaultSizeRPanel;
    window.addEventListener('click', (event: any) => {
      let controlPopup = document.getElementById('control-popup');
      if (!controlPopup?.contains(event.target)) {
        let controlIcon = document.getElementById('control-icon');
        if (!controlIcon?.contains(event.target)) {
          this.showControlPopup = false;
        }
      }
      else {
        this.showControlPopup = false;
      }
    });
  }

  // back page event
  backPage() {
    this.backPageHandler.emit();
    if (!this.isBackUsed) {
      this._location.back();
    }
  }

  // show control popup
  openControlPopup() {
    this.showControlPopup = true;
  }
  
  showDocker(pannel: string) {
    let dockerList: any[] = [];
    if (pannel === 'left') {
      this.panelLeftSize = cloneDeep(this.defaultSizeLPanel);
      this.isDisplayLeftPannel = true;
    }
    else {
      this.panelRightSize = cloneDeep(this.defaultSizeRPanel);
      this.isDisplayRightPannel = true;
    }
    dockerList = this.dockerList.filter(i => i.pannel != pannel);

    this.dockerListHandler.emit({dockerList: dockerList, clickedPannel:pannel });
  }

  // on input name event
  onInputName(event: any) {
    this.nameFieldHandler.emit(event.target.value);
  }

  // reset default panel size
  resetPanelSize() {
    this.panelLeftSize = cloneDeep(this.defaultSizeLPanel);
    this.panelRightSize = cloneDeep(this.defaultSizeRPanel);
  }

  // resize panel left
  onResizePanelLeft(event: any) {
    let sizes: any[] = event.sizes || [];
    if(sizes.length > 0) {
      this.widthLeft = sizes[0];
      this.widthRight = sizes[1];
      if(this.widthLeft <= this.minWidth || this.widthLeft >= this.elastiCities) this.panelLeftSize[0] = this.minWidth;
      else this.panelLeftSize[0] = this.widthLeft;
      if(this.widthRight >= this.maxWidth || this.widthRight <= this.minWidth) this.panelLeftSize[1] = this.maxWidth;
      else this.panelLeftSize[1] = this.widthRight;
      this.panelLeftSize = cloneDeep(this.panelLeftSize)
    }
  }

  // resize panel right
  onResizePanelRight(event: any) {
    let sizes: any[] = event.sizes || [];
    if(sizes.length > 0) {
      this.widthLeft = sizes[0];
      this.widthRight = sizes[1];
      if(this.widthLeft <= this.minWidth || this.widthLeft >= this.elastiCities) this.panelRightSize[0] = this.minWidth;
      else this.panelRightSize[0] = this.widthLeft;
      if(this.widthRight >= this.maxWidth || this.widthRight <= this.minWidth + 5) this.panelRightSize[1] = this.maxWidth;
      else this.panelRightSize[1] = this.widthRight;
      this.panelRightSize = cloneDeep(this.panelRightSize)
    }
  }

  // clear docker list
  clearDockerList() {
    this.dockerList = [];
    this.dockerList = cloneDeep(this.dockerList);
    this.dockerListHandler.emit({dockerList: [], clickedPannel:'right' });
  }

  // start resize panel
  onResizePanelStart(event: any) {
    this.widthStart = event.sizes[0] || 0;
    this.widthEnd = event.sizes[1] || 0;
  }

  ngOnChanges(changes: SimpleChanges): void {
    // set margin for main panel
    let mainPanels = document.getElementsByClassName('margin-main-panel') as HTMLCollectionOf<HTMLElement>;
    if(mainPanels && mainPanels.length > 0) {
      for (let i = 0; i < mainPanels.length; i++) {
        mainPanels[i].style.marginLeft = this.dockerList.length > 0?  '40px' : '0px';
      }
    }
  }
}
