<pivot-modal-template *ngIf="isDisplayModal" [modalTemplate]="modalData" (closeModalHandler)="onClose()">
  <div modal-body>
    <div class="content body">
      <div>
        <div class="img-loading" >
          <div class="status-of-all-syns"  *ngIf="statusOfAllSyns == statusSynsMasterData.LOADING">
              <img class="sys-state-loading" >
              <div class = "sys-state-text">{{COMMON_TEXT.SYNC_LOADING}}
              </div>
          </div>
        </div>
        <div class="img-success" >
          <div class="status-of-all-syns"  *ngIf="statusOfAllSyns == statusSynsMasterData.SUCCESS">
              <img class="sys-status">
              <div class = "sys-state-text">{{COMMON_TEXT.SYNC_SUCCESS}}
              </div>
          </div>
        </div>
        <div class="img-error" >
          <div class="status-of-all-syns" *ngIf="statusOfAllSyns == statusSynsMasterData.FAILED">
              <img class="sys-status">
          </div>
        </div>
      </div>
      <div class="line-edit-sync-data"></div>
      <div class="dialog-sync-info-detail">
        <ng-container *ngFor="let item of data">
          <div>{{ item.name }}</div>
          <pivot-process-bar [condition]="item.status"></pivot-process-bar>
        </ng-container>
      </div>
    </div>
  </div>
</pivot-modal-template>