import { EventEmitter, Injectable } from '@angular/core';
import { ProcessLoadingService } from './loading.service';

@Injectable()
export class ProcessLoadingStackService {
  
  constructor(private loadingService: ProcessLoadingService) { }

  private loadingStack: any [] = [];

  loadingSomething(key: string, state = true) {
    if (state) {
      let process = this.loadingStack.filter(item => item == key);
      if (process.length == 0) this.loadingStack.push(key);
      this.loadingService.isLoading.emit(true)
    } else {
      this.loadingStack = this.loadingStack.filter(item => item !== key);
      if (this.loadingStack.length == 0) this.loadingService.isLoading.emit(false)
    }
 
  }
}
