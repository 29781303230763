import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { cloneDeep, sortBy } from 'lodash';
import { DialogService, DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { DeviceType } from '../../../app/enum/common-enum';
import { MENU_NAME, SYSTEM_SETTING } from '../../../app/const/text-common';
import { setValidateMaxLength } from '../../../app/_helper/helper';
import { OrderBy, SortType } from '../../../app/const/const';
import { MESSAGE_TEXT } from '../../../app/const/message';

@Component({
  selector: 'pivot-role-detail-inf',
  templateUrl: './role-detail-inf.component.html',
  styleUrls: ['./role-detail-inf.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class RoleDetailInfComponent implements OnInit {

  @Input() roleName = '';
  @Input() deviceType = '';

  mstPermission: any[] = [];
  tmpPermissonList: any[] = [];
  @Input() permissionList: any[] = [];
  @Input() dashboardList: any[] = [];

  isDialog = false;
  DeviceType = DeviceType;
  MENU_NAME = MENU_NAME;
  SYSTEM_SETTING = SYSTEM_SETTING;
  MESSAGE_TEXT = MESSAGE_TEXT;

  constructor(
    public dialogService: DialogService,
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig) {

    // this.mstPermission = [
    //   { permissioncd: '001', permissionname: MENU_NAME.ROLE_SETTING, childpermissions: null },
    //   { permissioncd: '002', permissionname: MENU_NAME.ACCOUT_MANAGEMENT, childpermissions: null },
    //   { permissioncd: '003', permissionname: MENU_NAME.DASHBOARD_LIST, childpermissions: null },
    //   { permissioncd: '004', permissionname: MENU_NAME.WIDGET_LIST, childpermissions: null },
    //   { permissioncd: '005', permissionname: MENU_NAME.BUDGET_INPUT, childpermissions: null },
    //   { permissioncd: '006', permissionname: MENU_NAME.TARGET_SETTING, childpermissions: null },
    //   {
    //     isDefault: true, permissioncd: '007', permissionname: MENU_NAME.SYSTEM_SETTING,
    //     childpermissions: [
    //       { childcd: '001', childname: '開始月設定' },
    //       { childcd: '002', childname: 'メール配信/通知設定', isDefault: true }
    //     ]
    //   },
    //   { permissioncd: '008', permissionname: MENU_NAME.REPORT }
    // ];

    if (this.config && this.config.data) {
      this.isDialog = this.config.data.isDialog;
      this.permissionList = this.config.data.permissionList;
      this.dashboardList = this.config.data.dashboardList
    }

    this.getTmpListPermission();
  }

  ngOnInit(): void {
    const countLengthName = this.roleName.length;
    if (countLengthName > 10) {
      this.roleName = setValidateMaxLength(this.roleName, 10) + '...';
    }
    this.displayRoleDetail();
    if (this.dashboardList) {
      sortBy(this.dashboardList, SortType.INSDATE, OrderBy.DESC);
    }
  }

  ngAfterViewInit(): void {
    if (this.isDialog) {
      const findheader = document.getElementsByClassName('p-dialog-content');
      if (findheader !== undefined) {
        if ((findheader[0] as HTMLElement).classList !== undefined) {
          (findheader[0] as HTMLElement).classList.remove('p-dialog-content');
        }
      }
      const findDialog = document.getElementById('dialogcheck');
      if (findDialog) {
        const height = findDialog.clientHeight;
        if (height > 200 && findDialog) {
          findDialog.style.overflow = 'scroll !impotant';
        }
      }
    }
  }

  getlistChild(nodeName: any) {
    return this.tmpPermissonList.filter(s => s.parent === nodeName);
  }

  slicePermissionLeft(isDialog = false) {
    if (isDialog) {
      return this.permissionList;
    }
    let lengthSlice = parseInt((this.permissionList.length / 2).toString());
    return this.permissionList.slice(0, lengthSlice);
  }

  slicePermissionRight() {
    let lengthSlice = parseInt((this.permissionList.length / 2).toString());
    return this.permissionList.slice(lengthSlice, this.permissionList.length);
  }

  getTmpListPermission() {
    const data = cloneDeep(this.mstPermission);
    let tmpPermissions: any[] = [];
    data.forEach((elm: any) => {
      const hasDefaultChild = (elm.childpermissions && elm.childpermissions.length) &&
        cloneDeep(elm.childpermissions).find((childEl: any) => childEl.isDefault == true);
      tmpPermissions.push({
        permissioncd: elm.permissioncd,
        permissionname: elm.permissionname,
        pk: 'permission',
        sk: elm.permissioncd,
        checked: hasDefaultChild ? false : null,
        parent: null
      });
      if (elm.childpermissions && elm.childpermissions.length > 0) {
        cloneDeep(elm.childpermissions).forEach((childEl: any) => {
          tmpPermissions.push({
            permissioncd: elm.permissioncd + childEl.childcd,
            permissionname: childEl.childname,
            pk: 'permission',
            sk: elm.permissioncd + childEl.childcd,
            checked: childEl.isDefault ? true : null,
            parent: elm.permissioncd,
            isDefault: childEl.isDefault
          });
        });
      }
    });
    this.tmpPermissonList = tmpPermissions;
  }

  displayRoleDetail() {
    const result: any[] = this.tmpPermissonList.map((permissionEl: any) => {
      if (!permissionEl.parent &&
        (this.permissionList.some((e: any) => e.permissioncd === permissionEl.permissioncd))) {
        permissionEl.checked = true;
      } else if (permissionEl.parent) {
        this.tmpPermissonList.forEach((item: any) => {
          if (item.permissioncd === permissionEl.parent) {
            const subcount = this.tmpPermissonList.filter((item: any) => item.parent === permissionEl.parent).length;
            const subcountChecked = this.tmpPermissonList.filter((item: any) => item.parent === permissionEl.parent && item.checked === true).length;
            if (subcountChecked === subcount) {
              item.checked = true;
            } else {
              item.checked = false;
            }
          }
        });
        if (permissionEl.isDefault == true) {
          permissionEl.checked = true;
        } else {
          const parent: any = this.permissionList.find((perEl: any) => permissionEl.parent === perEl.permissioncd);
          if (parent && parent.childpermissions &&
            (parent.childpermissions.length > 0) &&
            parent.childpermissions.some((e: any) => e.childcd === permissionEl.permissioncd.substr(3, 3))) {
            permissionEl.checked = true;
          } else {
            permissionEl.checked = null;
          }
        }
      } else {
        permissionEl.checked = null;
      }
      return permissionEl;
    }).filter((el: any) => {
      return el.checked !== null
    });

    this.permissionList = result;
  }

  openDialogRoleDetail() {
    const ref = this.dialogService.open(RoleDetailInfComponent, {
      width: '80%',
      showHeader: false,
      data: {
        isDialog: true,
        permissionList: this.permissionList,
        dashboardList: this.dashboardList
      }
    });
  }

  closeDialog() {
    this.ref.close('reject');
  }
}
