import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from '../api.service';
import { ErrorHandleService } from '../error-handle.service';
import { map, of, EMPTY, catchError } from 'rxjs';
import { SaucerLogDTO, SaucerLogQuickConfigDTO } from '../../models/request/log.dto';
import { LocalStorageHelper } from '../../_helper/local-storage.helper';
import { ACTION_LOG, CONFIG_LOG, LOG_TYPE, SAUCER_LOG_ACTION } from '../../config/saucer-log.config';
import { API_APP } from '../../config/app.config';
import { environment } from 'src/environments/environment';

export { SAUCER_LOG_ACTION };

@Injectable({ providedIn: 'root' })
export class SaucerLogService extends ApiService {
  private timeoutId: any = null;
  private logs: any[] = [];

  private clientIp: string | any = null;

  constructor(
    http: HttpClient,
    router: Router,
    errorHandle: ErrorHandleService
  ) {
    super(http, router, errorHandle);
    window.addEventListener('beforeunload', this.saveLogsToLocalStorage.bind(this));
  }

  async writeLog(params: SaucerLogDTO, config?: SaucerLogQuickConfigDTO | null) {
    (Object.keys(config?.action || []) as (keyof SaucerLogDTO)[]).forEach(key => {
      if (!params[key] || params[key] === '') {
        params[key] = config?.action[key];
      }
    });
    params = await this.formatSaucerLogParams(params);
    this.logs.push(params);
    if (CONFIG_LOG.IS_DEBUG) {
      console.log("===== SAUCER_LOG_RAM =====");
      console.log({LOG_RAM_DATA: this.logs});
      console.log("==========================");
    }
    this.setTimeoutToSendLogs();
  }

  checkClientIpToSendLog(params: SaucerLogDTO, config?: SaucerLogQuickConfigDTO | null): void {
    if (this.clientIp) {
      this.writeLog(params, config);
    } else {
      this.getClientIp().subscribe({
        next: (clientIp) => {
          this.setClientIp(clientIp);
        },
        complete: () => {
          this.writeLog(params, config);
        }
      });
    }
  }

  async action(params: SaucerLogDTO, config?: SaucerLogQuickConfigDTO | null) : Promise<void> {
    params.logType = LOG_TYPE.LOG_ACTION;
    await this.checkClientIpToSendLog(params, config);
  }

  error(params: SaucerLogDTO, config?: SaucerLogQuickConfigDTO | null): void {
    params.logType = LOG_TYPE.LOG_ERROR;
    this.checkClientIpToSendLog(params, config);
  }

  system(params: SaucerLogDTO, config?: SaucerLogQuickConfigDTO | null): void {
    params.logType = LOG_TYPE.LOG_SYSTEM;
    this.checkClientIpToSendLog(params, config);
  }

  async formatSaucerLogParams(params: SaucerLogDTO) {
    const loginInfo = await LocalStorageHelper.getLoginInfo();
    const currentUserStr = LocalStorageHelper.getCurrentUser();
    let currentUser = {
      authcorplist: [],
      staffid: 0,
      token: '',
      companyid: 0,
      pivotpermission: [], 
      email: '',
      phonenumber: '',
      fullname: '',
    };
    if (currentUserStr) {
      currentUser = JSON.parse(currentUserStr);
    }

    function getUserRole() {
      if (!currentUser) return null;
      let roleNameLst = currentUser?.pivotpermission?.map((role:any) => role.rolename);
      let rolename = roleNameLst?.toString() || "";
      return rolename;
    }
    function getOrganizationName() {
      if (!currentUser) return null;
      let organizationnameLst = currentUser?.authcorplist?.map((org:any) => org.organizationname);
      let organizationname = organizationnameLst?.toString() || "";
      return organizationname;
    }

    const organizationName = await getOrganizationName();
    const userRole = await getUserRole();

    params.module = 'PIVOT';
    params.ip = this.clientIp;
    params.userAgent = window.navigator.userAgent;
    params.userId = currentUser.staffid;
    params.token = currentUser.token;
    params.email = params.email || currentUser.email || '';
    params.phoneNumber = params.phoneNumber || currentUser.phonenumber || '';
    params.userKana = params.userKana || '',
    params.userName = params.userName || currentUser.fullname || '';
    params.accessDatetime = new Date().toISOString(),
    params.action = params.action || ACTION_LOG.ACTION_ERROR,
    params.screenName = params.screenName || '';
    params.logType = params.logType || LOG_TYPE.LOG_ERROR;
    params.apiPath = params.apiPath || '';
    params.content = this.parseToString(params.content);
    params.body = this.parseToString(params.body);
    params.userType = params.userType || userRole,
    params.statusCode = params.statusCode || 200,
    params.errorMessage = this.parseToString(params.errorMessage) || '',
    params.organizationId = params.companyId || currentUser.companyid;
    params.organizationName = organizationName || loginInfo.organizationname || '';
    params.companyId = 0;
    params.companyName = '',
    params.method = params.method || '';

    return params;
  }

  saveLogsToLocalStorage() {
    LocalStorageHelper.setSaucerLogs(this.logs);
  }

  runSaucerLog() {
    this.getClientIp().subscribe({
      next: (clientIp) => {
        this.setClientIp(clientIp);
      },
      complete: () => {
        const tempSaucerLogs = LocalStorageHelper.getSaucerLogs();
        if (tempSaucerLogs.length > 0) {
          let listBulk: any[] = [];
          const chunks = this.chunkArray(tempSaucerLogs, CONFIG_LOG.MAX_COUNT_TO_CALL_SAUCER_LOG);
          for (const chunk of chunks) {
            listBulk.push(this.sendLogs(chunk).subscribe());
          }
          Promise.all(listBulk).then(() => {
            LocalStorageHelper.clearSaucerLogs();
          });
        }
      }
    });
  }

  getClientIp() {
    if (this.clientIp) return of({ip:this.clientIp});
    return this.http.get<any>(CONFIG_LOG.API_GET_CLIENT_IP)
      .pipe(map(data => {
        return data;
      }),
      catchError(() => {
        return of({ip: 'IP取得失敗'});
      }))
  }

  async setClientIp(data: any) {
    if (!this.clientIp || this.clientIp === 'IP取得失敗') {
      this.clientIp = data?.ip || 'IP取得失敗';
    }
  }

  resetTimeout() {
    if (this.timeoutId) {
      clearTimeout(this.timeoutId);
    }
    this.timeoutId = setTimeout(() => {
      this.sendLogs(this.logs).subscribe();
      this.logs = [];
    }, CONFIG_LOG.TIMEOUT_CALL_SAUCER_LOG);
  }

  setTimeoutToSendLogs() {
    if (this.logs.length >= CONFIG_LOG.MAX_COUNT_TO_CALL_SAUCER_LOG) {
      this.sendLogs(this.logs).subscribe();
      this.logs = [];
    }
    this.resetTimeout();
  }

  sendLogImmediately() {
    if(this.logs.length > 0) {
      this.sendLogs(this.logs).subscribe();
      this.logs = [];
    }
    if (this.timeoutId) {
      clearTimeout(this.timeoutId);
    }
  }

  sendLogs(lstBulk: any[]) {
    if (!lstBulk || lstBulk.length === 0) {
      return EMPTY.pipe();
    }
    if (!CONFIG_LOG.IS_DEBUG) {
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${environment.SAUCER_LOG_TOKEN}` });
      return this.http.post<any>(API_APP.SAUCER_LOG.LOG_BULK, {logs: lstBulk}, {
        headers: headers,
        observe: 'response'
        })
        .pipe(map(data => {
          return data;
        }));
    } else {
      console.log('==== POST_TO_API ===')
      console.log({POSTED_API: this.logs});
      console.log('====================');
      return EMPTY.pipe();
    }
  }

  // Utility function to split the array into chunks of max size
  chunkArray(array: [], chunkSize: number) {
    const chunks = [];
    for (let i = 0; i < array.length; i += chunkSize) {
        chunks.push(array.slice(i, i + chunkSize));
    }
    return chunks;
  }


  parseToString(inp: any) {
    // Check if 'a' is null or undefined and return an empty string
    if (inp === null || inp === undefined) {
      return '';
    }

    if (typeof inp === 'object') {
      try {
        return JSON.stringify(inp);
      } catch (error) {
        console.error('Error stringifying object:', error);
        return '';
      }
    }

    return String(inp);
  }

}