import { Injectable } from "@angular/core";
import { Subject } from "rxjs";

@Injectable()
export class BasicInfoCommunicatorService {
    private userAvatarSubject = new Subject<string>();

    sendUserAvatar(avatarUrl: string) {
        this.userAvatarSubject.next(avatarUrl);
    }

    get userAvatarEvents$() {
        return this.userAvatarSubject.asObservable();
    }
}