
<pivot-modal-template class="chart-setting-wrapper"  [modalTemplate]="modalData" (closeModalHandler)="onClose()">
  <div modal-body>
    <div class="combo-chart-content body">
      <div class="choosing-combo-chart">
        <div class="combo-chart-content">
          <pivot-table class="table" [data]="tableData"></pivot-table>
        </div>
      </div>
    </div>
  </div>
  <div modal-bottom class="combo-chart-footer">
    <pivot-text-button 
      (btnClick)="onClose()" [buttonType]="buttonType.SECONDARY" [buttonText]="BUTTON.CANCEL">
    </pivot-text-button>
    <pivot-text-button (btnClick)="saveConfig()"
    [buttonType]="buttonType.SECONDARY" [buttonText]="BUTTON.NEXT">
</pivot-text-button>
  </div>
</pivot-modal-template>
