<ng-template #defaultItemTemplate let-item="item">
  {{item[labelKey]}}
</ng-template>

<ul #list class="dropdown-menu scrollable-menu" [hidden]="hidden" 
  [class.mention-menu]="!styleOff" [class.mention-dropdown]="!styleOff && dropUp">
  <li *ngFor="let item of itemsColunms; let i = index"
    [class.active]="activeIndex==i" [class.mention-active]="!styleOff && activeIndex==i">
    <a class="dropdown-item  item-mention" [class.mention-item]="!styleOff"
      (mousedown)="activeIndex=i;itemClick.emit();$event.preventDefault()">
      <ng-template  [ngTemplateOutlet]="defaultItemTemplate" [ngTemplateOutletContext]="{'item':item}"></ng-template>
    </a>
  </li>
</ul>