<pivot-modal-template *ngIf="apiResponse ? apiResponse.isDisplayErrorDialog : false" (closeModalHandler)="closeDialog()" [modalTemplate]="modalData"
style="
position: absolute;
z-index: 12000;">
    <div modal-body class="error-dialog-body">
       <div class="modal-body">
        <div class=""><img class="dlg-icon" src="../../../assets/icons/exclamation-mark-blue.svg"></div>
       <div class="label">
        <pre *ngIf="apiResponse.functionMessage != ''" for="">{{apiResponse.functionMessage}}</pre>
        <pre>{{apiResponse.title}}</pre>
        <pre>{{apiResponse.subtitle}}</pre>
       </div>
       </div>
        <pivot-text-button class="confirm-btn" [buttonText]="'OK'" (btnClick)="closeDialog()" [buttonType]="buttonType.SECONDARY"></pivot-text-button>   
    </div>
</pivot-modal-template>