<pivot-modal-template class="confirm-invite-dialog" *ngIf="isDisplayModal" [modalTemplate]="modalData"
    (closeModalHandler)="onClose()">
    <div modal-body>
        <div class="content header">
            <div class="title">{{SYSTEM_SETTING.INVITE_DLG_SUB_TITLE}}</div>
        </div>

        <div class="content body">
            <div class="left-content">
                <div class="title">{{SYSTEM_SETTING.INVITE_DLG_CONTENT}}</div>
                <div class="type">
                    <div class="type-title">{{SYSTEM_SETTING.INVITE_DLG_TYPE}}</div>
                    <div class="type-radio">
                        <pivot-radio-button [styleClass]="'choose-type-radio-btn'" [isChecked]="isMail" (onClick)="onCheckbox('email')"></pivot-radio-button>
                        <label (click)="onCheckbox('email')">{{SYSTEM_SETTING.INVITE_DLG_MAIL}}</label>
                        <pivot-radio-button [styleClass]="'choose-type-radio-btn'" [isChecked]="isPhone" (onClick)="onCheckbox('phone')"></pivot-radio-button>
                        <label (click)="onCheckbox('phone')">{{SYSTEM_SETTING.INVITE_DLG_PHONE}}</label>
                    </div>
                </div>
                <div class="email">
                    <label>{{SYSTEM_SETTING.INVITE_DLG_MAIL}}</label>
                    <div>
                        <pivot-textbox [inputParams]="inputNameParams" [inputValue]="textVal"
                            (onInput)="onInput($event, 'email')" (onFocus)="onFocusMail()">
                        </pivot-textbox>
                    </div>
                </div>
                <div class="phone">
                    <label>{{SYSTEM_SETTING.INVITE_DLG_PHONE}}</label>
                    <div class="phone-input">
                        <div class="phone-input--item">
                            <pivot-textbox [inputParams]="inputPhoneParams[0]" [inputValue]="telArea"
                                inputId="inpPhoneNumber1"
                                [textCenter]="true"
                                (onInput)="onInput($event, 'area')" (onFocus)="onFocusTel()"
                                (onKeyDown)="onKeydownInput($event, 'area')"
                                (onKeyUp)="onChangePhoneNumber1()"></pivot-textbox>
                        </div>
                        <div class="phone-input--hyphen">ー</div>
                        <div class="phone-input--item">
                            <pivot-textbox [inputParams]="inputPhoneParams[1]" [inputValue]="telCity"
                                inputId="inpPhoneNumber2"
                                [textCenter]="true"
                                (onInput)="onInput($event, 'city')" (onFocus)="onFocusTel()"
                                (onKeyDown)="onKeydownInput($event, 'city')"
                                (onKeyUp)="onChangePhoneNumber2()"></pivot-textbox>
                        </div>
                        <div class="phone-input--hyphen">ー</div>
                        <div class="phone-input--item">
                            <pivot-textbox [inputParams]="inputPhoneParams[2]" [inputValue]="telNum"
                                inputId="inpPhoneNumber3"
                                [textCenter]="true"
                                (onInput)="onInput($event, 'num')" (onFocus)="onFocusTel()"
                                (onKeyDown)="onKeydownInput($event, 'num')"></pivot-textbox>
                        </div>
                    </div>
                    <div *ngIf="isInvalidTelNum" class="phone-validate">{{errorTelMsg}}</div>
                </div>
            </div>
            <div class="right-content">
                <div>
                    <div class="title">{{SYSTEM_SETTING.PENDING_INVITATION_TITLE}}</div>
                </div>
                <div class="lst-pending-invitation">
                    <div *ngFor="let pendingInvitation of lstPendingInvitation">
                        <div class="invitation-row"
                        (mouseenter)="mouseEnterRemovePendingBtn(pendingInvitation.id)"
                        (mouseleave)="mouseLeaveRemovePendingBtn(pendingInvitation.id)">
                            <div class="pending-name">{{pendingInvitation.user}}</div>
                            <div class="remove-pending-btn" >
                                <pivot-text-button (btnClick)="showDlgConfigmRemovePending(pendingInvitation.id)" [buttonText]="SYSTEM_SETTING.CANCEL_INVITED_STAFF"></pivot-text-button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div modal-bottom class="content footer">
        <div>
            <pivot-text-button class="cancel-invite-btn" [iconType]="iconType.CLOSE_DIALOG" (btnClick)="onClose()" [buttonType]="buttonType.SECONDARY" [buttonText]="BUTTON.CANCEL">
            </pivot-text-button>
            <pivot-icon-button [styleClass]="'staff-save-dialog-btn'" icon="assets/icons/email_invite.svg" [label]="BUTTON.INVITE" [buttonType]="buttonType.SECONDARY" (onClick)="onSubmit()"></pivot-icon-button>
        </div>
    </div>
</pivot-modal-template>