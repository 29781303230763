import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { API_APP, PIVOT_CLIENT_API_URL } from "../../config/app.config";
import { ApiService } from "../api.service";
import {ErrorHandleService} from "../error-handle.service";
import { SAUCER_LOG_ACTION, SaucerLogService } from "../saucer-logs/saucer-log.service";
import { SYNCDATA_STATUS } from "src/app/const/text-common";

@Injectable({ providedIn: 'root' })
export class SyncDataOracleService extends ApiService {

    constructor(
        http: HttpClient, 
        router: Router, 
        errorHandle: ErrorHandleService,
        private saucerLogService : SaucerLogService
    ) {
        super(http, router, errorHandle);
      }

    syncUnit() {
        const apiUrl = API_APP.SYNCDATAORALE.CONTROLLER + API_APP.SYNCDATAORALE.SYNC_UNIT;
        const apiPath = apiUrl.replace(PIVOT_CLIENT_API_URL, "");
        const resData = this.post(apiUrl, undefined, undefined).then(res => {
            if (res.statuscode !== 200) {
                this.saucerLogService.error({
                    apiPath,
                    body: res,
                    statusCode: res.statuscode,
                    content: API_APP.SYNCDATAORALE.SYNC_UNIT + " " + SYNCDATA_STATUS.ERROR
                }, {action: SAUCER_LOG_ACTION.SYNC_CK_MASTER.SYNC});
                throw res;
            } else {
                this.saucerLogService.system({
                    apiPath,
                    body: res,
                    statusCode: res.statuscode,
                    content: API_APP.SYNCDATAORALE.SYNC_UNIT + " " + SYNCDATA_STATUS.UPDATE_COMPLETE
                }, {action: SAUCER_LOG_ACTION.SYNC_CK_MASTER.SYNC});
            }
        });

        return resData;
    }

    syncBlock() {
        const apiUrl = API_APP.SYNCDATAORALE.CONTROLLER + API_APP.SYNCDATAORALE.SYNC_BLOCK;
        const apiPath = apiUrl.replace(PIVOT_CLIENT_API_URL, "");
        const resData = this.post(apiUrl, undefined, undefined).then(res => {
            if (res.statuscode !== 200) {
                this.saucerLogService.error({
                    apiPath,
                    body: res,
                    statusCode: res.statuscode,
                    content: API_APP.SYNCDATAORALE.SYNC_BLOCK + " " + SYNCDATA_STATUS.ERROR
                }, {action: SAUCER_LOG_ACTION.SYNC_CK_MASTER.SYNC});
                throw res;
            } else {
                this.saucerLogService.system({
                    apiPath,
                    body: res,
                    statusCode: res.statuscode,
                    content: API_APP.SYNCDATAORALE.SYNC_BLOCK + " " + SYNCDATA_STATUS.UPDATE_COMPLETE
                }, {action: SAUCER_LOG_ACTION.SYNC_CK_MASTER.SYNC});
            }
        });

        return resData;
    }

    syncOffice() {
        const apiUrl = API_APP.SYNCDATAORALE.CONTROLLER + API_APP.SYNCDATAORALE.SYNC_OFFICE;
        const apiPath = apiUrl.replace(PIVOT_CLIENT_API_URL, "");
        const resData = this.post(apiUrl, undefined, undefined).then(res => {
            if (res.statuscode !== 200) {
                this.saucerLogService.error({
                    apiPath,
                    body: res,
                    statusCode: res.statuscode,
                    content: API_APP.SYNCDATAORALE.SYNC_OFFICE + " " + SYNCDATA_STATUS.ERROR
                }, {action: SAUCER_LOG_ACTION.SYNC_CK_MASTER.SYNC});
                throw res;
            } else {
                this.saucerLogService.system({
                    apiPath,
                    body: res,
                    statusCode: res.statuscode,
                    content: API_APP.SYNCDATAORALE.SYNC_OFFICE + " " + SYNCDATA_STATUS.UPDATE_COMPLETE
                }, {action: SAUCER_LOG_ACTION.SYNC_CK_MASTER.SYNC});
            }
        });

        return resData;
    }

    syncCorp() {
        const apiUrl = API_APP.SYNCDATAORALE.CONTROLLER + API_APP.SYNCDATAORALE.SYNC_CORP;
        const apiPath = apiUrl.replace(PIVOT_CLIENT_API_URL, "");
        const resData = this.post(apiUrl, undefined, undefined).then(res => {
            if (res.statuscode !== 200) {
                this.saucerLogService.error({
                    apiPath,
                    body: res,
                    statusCode: res.statuscode,
                    content: API_APP.SYNCDATAORALE.SYNC_CORP + " " + SYNCDATA_STATUS.ERROR
                }, {action: SAUCER_LOG_ACTION.SYNC_CK_MASTER.SYNC});
                throw res;
            } else {
                this.saucerLogService.system({
                    apiPath,
                    body: res,
                    statusCode: res.statuscode,
                    content: API_APP.SYNCDATAORALE.SYNC_CORP + " " + SYNCDATA_STATUS.UPDATE_COMPLETE
                }, {action: SAUCER_LOG_ACTION.SYNC_CK_MASTER.SYNC});
            }
        });

        return resData;
    }

    syncOfficeService() {
        const apiUrl = API_APP.SYNCDATAORALE.CONTROLLER + API_APP.SYNCDATAORALE.SYNC_OFFICE_SERVICE;
        const apiPath = apiUrl.replace(PIVOT_CLIENT_API_URL, "");
        const resData = this.post(apiUrl, undefined, undefined).then(res => {
            if (res.statuscode !== 200) {
                this.saucerLogService.error({
                    apiPath,
                    body: res,
                    statusCode: res.statuscode,
                    content: API_APP.SYNCDATAORALE.SYNC_OFFICE_SERVICE + " " + SYNCDATA_STATUS.ERROR
                }, {action: SAUCER_LOG_ACTION.SYNC_CK_MASTER.SYNC});
                throw res;
            } else {
                this.saucerLogService.system({
                    apiPath,
                    body: res,
                    statusCode: res.statuscode,
                    content: API_APP.SYNCDATAORALE.SYNC_OFFICE_SERVICE + " " + SYNCDATA_STATUS.UPDATE_COMPLETE
                }, {action: SAUCER_LOG_ACTION.SYNC_CK_MASTER.SYNC});
            }
        });

        return resData;
    }
    
    syncRoom(){
        const apiUrl = API_APP.SYNCDATAORALE.CONTROLLER + API_APP.SYNCDATAORALE.SYNC_ROOM;
        const apiPath = apiUrl.replace(PIVOT_CLIENT_API_URL, "");
        const resData = this.post(apiUrl, undefined, undefined).then(res => {
            if (res.statuscode !== 200) {
                this.saucerLogService.error({
                    apiPath,
                    body: res,
                    statusCode: res.statuscode,
                    content: API_APP.SYNCDATAORALE.SYNC_ROOM + " " + SYNCDATA_STATUS.ERROR
                }, {action: SAUCER_LOG_ACTION.SYNC_CK_MASTER.SYNC});
                throw res;
            } else {
                this.saucerLogService.system({
                    apiPath,
                    body: res,
                    statusCode: res.statuscode,
                    content: API_APP.SYNCDATAORALE.SYNC_ROOM + " " + SYNCDATA_STATUS.UPDATE_COMPLETE
                }, {action: SAUCER_LOG_ACTION.SYNC_CK_MASTER.SYNC});
            }
        });
        return resData;
    }

    syncTitleMapping(office: any) {
        const apiUrl = API_APP.SYNCDATAORALE.CONTROLLER + API_APP.SYNCDATAORALE.SYNC_TITLE_MAPPING;
        const apiPath = apiUrl.replace(PIVOT_CLIENT_API_URL, "");
        const body = [{ officecd: office.officecd }];
        const resData = this.post(apiUrl, undefined, body).then(res => {
            if (res.statuscode !== 200) {
                res.office = office;
                this.saucerLogService.error({
                    apiPath,
                    body: res,
                    statusCode: res.statuscode,
                    content: API_APP.SYNCDATAORALE.SYNC_TITLE_MAPPING + " " + SYNCDATA_STATUS.ERROR + JSON.stringify(office)
                }, {action: SAUCER_LOG_ACTION.SYNC_CK_MASTER.SYNC});
                throw res;
            } else {
                this.saucerLogService.system({
                    apiPath,
                    body: res,
                    statusCode: res.statuscode,
                    content: API_APP.SYNCDATAORALE.SYNC_TITLE_MAPPING + " " + SYNCDATA_STATUS.UPDATE_COMPLETE +  JSON.stringify(office)
                }, {action: SAUCER_LOG_ACTION.SYNC_CK_MASTER.SYNC});
            }

            return res;
        });

        return resData;
    }

    deleteSyncTitleMapping(office: any) {
        const apiUrl = API_APP.SYNCDATAORALE.CONTROLLER + API_APP.SYNCDATAORALE.DELETE_SYNC_TITLE_MAPPING;
        const apiPath = apiUrl.replace(PIVOT_CLIENT_API_URL, "");
        const resData = this.post(apiUrl, [office.officecd], undefined).then(res => {
            if (res.statuscode !== 200) {
                this.saucerLogService.error({
                    apiPath,
                    body: res,
                    statusCode: res.statuscode,
                    content: API_APP.SYNCDATAORALE.DELETE_SYNC_TITLE_MAPPING + " " + SYNCDATA_STATUS.ERROR
                }, {action: SAUCER_LOG_ACTION.SYNC_CK_MASTER.DELETE_SYNC});
                throw res;
            } else {
                this.saucerLogService.system({
                    apiPath,
                    body: res,
                    statusCode: res.statuscode,
                    content: API_APP.SYNCDATAORALE.DELETE_SYNC_TITLE_MAPPING + " " + SYNCDATA_STATUS.UPDATE_COMPLETE
                }, {action: SAUCER_LOG_ACTION.SYNC_CK_MASTER.DELETE_SYNC});
            }

            return res;
        });

        return resData;
    }
}