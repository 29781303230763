<pivot-modal-template class="office-service-modal form-modal" [modalTemplate]="modalTemplate"
  (closeModalHandler)="closeFormModal()">
  <div class="office-modal-body" modal-body>
    <div class="controls">
      <label>{{COMMON_TEXT.OFFICE}}</label>
      <pivot-search-bar [searchParams]="searchParams" (onFilterData)="onFilterOutData($event)"></pivot-search-bar>
      <div class="row filter-service">
        <div [ngClass]="dispalyFilterServices.length > 0 ? 'filter-items has-items' : 'filter-items'">
          <div *ngFor="let service of dispalyFilterServices" class="filter">
            <div class="filter-label">{{service.name}}</div>
            <div class="icon-wrapper">
              <i class="pi pi-times" (click)="removeServiceFilter(service)"></i>
            </div>
          </div>
          <div *ngIf="isDisplayNumber && remaningNumber > 0" class="more-filter">
            ...
            <div class="icon-wrapper-number">
              {{remaningNumber}}
            </div>
          </div>
        </div>
        <div class="filter-icon">
          <i [ngClass]="filterServices.length > 0 ? 'pi pi-filter-fill': 'pi pi-filter-slash'" id="filter-icon"
            (click)="showModalFilter()"></i>
          <pivot-filter-modal (closeModalHandler)="isShowModal = false" (checkRows)="filterData($event)" [selectedServices]="filterServices"
            [styleModal]="filterModalStyle" [itemList]="listServices" *ngIf="isShowModal"></pivot-filter-modal>
        </div>
      </div>
    </div>
    <pivot-table class="table" [data]="tableData" [isLoading]="loadingTable" 
    [selectedRows]="selectedOffices" (checkRows)="checkRowsTable($event)"></pivot-table>

  </div>
  <div class="office-modal-bottom" modal-bottom>
    <pivot-text-button (btnClick)="cancel()" [buttonType]="buttonType.SECONDARY" [buttonText]="BUTTON.CANCEL">
    </pivot-text-button>
    <pivot-text-button (btnClick)="save()"  [disabled]="!isHome && selectedOffices.length == 0"  [buttonType]="buttonType.SECONDARY"
      [buttonText]="BUTTON.SELECT"></pivot-text-button>
  </div>
</pivot-modal-template>