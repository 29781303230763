<pivot-splitter-panel-template [titlePage]="titlePage " [isDisplayDSSetting] = "true" [nameParams]="nameParams" [name]="nameDataSource"
  [isDisplayControlIcon]="mode === screenMode.EDIT" [isDisplayLeftPannel]="isShowLeftPannel" [isDisplayRightPannel]="isShowRightPannel"
  [panelLeftSize]="panelLeftSize"
  [panelRightSize]="panelRightSize"
  [dockerList]="dockerList"
  (dockerListHandler)="updateDockerList($event)" 
  (nameFieldHandler)="setNameDataSource($event)" (backPageHandler)="backPage()">
  <div top-buttons class="button-wrapper">
    <div class="ds-group-buttons">
      <pivot-text-button [buttonType]="buttonType.SECONDARY" [buttonText]="BUTTON.PREVIEW" [disabled]="isDisabledPreviewButton()"
      (btnClick)="previewOrSaveDataSource(true)"> </pivot-text-button>
      <pivot-text-button [buttonType]="buttonType.PRIMARY" [disabled]="mode === screenMode.PREVIEW || isDisabledButton()"  [buttonText]="BUTTON.SAVE" 
      (btnClick)="showWidgetList()"></pivot-text-button>
    </div>
    <div *ngIf="isOver100Records" [ngClass]="mode === screenMode.EDIT ? 'edit-total-record' : 'create-total-record'"> ※ 表示可能件数を超えました。100件まで表示しています。</div>
  </div>
  <div class="datasource-pannel-content" left-pannel>
    <div (click)="closeDocker(0)" class="expanded-pannel">
      <i class="pi pi-chevron-left ng-star-inserted docker-icon bold-text "></i>
      <span class="bold-text">{{DATASOURCE_SETTING.COLUMN_OFFICE_ITEM}}</span> 
    </div>
    <div class="left-pannel-content">
      <div class="office-wrapper">
        <div class="folder-change">
          <span class="bold-text">{{DATASOURCE_SETTING.OFFICE_SELECT}}</span> 
          <pivot-text-button class="button" (btnClick)="showOfficeModal()"  [disabled]="mode === screenMode.PREVIEW" [buttonType]="buttonType.SECONDARY" 
          [buttonText]="BUTTON.OFFICE_SELECT"></pivot-text-button>
        </div>
        <ul class="offices-list">
          <li *ngFor="let office of officeList" class="office-item" [pTooltip]="office.officeNm!" tooltipPosition="bottom" >{{office.officeNm}}</li>
        </ul>
      </div>
      <div class="data-source-left-pannel-wrapper">
        <pivot-search-bar [searchParams]="searchParams" (onFilterData)="searchData($event)"></pivot-search-bar>
        <div class="tree-tab-wrapper">
          <div class="data-source-tab-pannel">
            <ul class="tabs-2">
              <li id="tab-master" [ngClass]="{'selected-tab': treeViewTab.MASTER === selectedTabTree }" (click)="changeTabTreeView(treeViewTab.MASTER)"
              (mouseenter)="mouseEnterContainer($event)" 
              (mouseleave)="mouseLeaveContainer($event)" class = "tab-ele">
                <div class="ds-tab master"></div>{{DATASOURCE_SETTING.DS_MASTERDATA}}
                <div class= "ds-tab icon-show-icon-dialog" (click)="showDialogDatasourceSuggest(treeViewTab.MASTER)">
                  <i class="pi pi-exclamation-circle"></i>
                </div>
              </li>
              <li id="tab-seikuy" *ngIf="permissionSeikuy" [ngClass]="{'selected-tab' : treeViewTab.SEIKUY === selectedTabTree, 'disabled': isDisabledSeikuyTab }" (click)="!isDisabledSeikuyTab ? changeTabTreeView(treeViewTab.SEIKUY): false"
              (mouseenter)="mouseEnterContainer($event)"
              (mouseleave)="mouseLeaveContainer($event)">
                <div class="ds-tab seikuy"></div>{{DATASOURCE_SETTING.DS_SEIKUY}}
              </li>
            </ul>
            <ul class="tabs-3">
              <li id="tab-record" [ngClass]="{'selected-tab': treeViewTab.RECORD === selectedTabTree }" (click)="changeTabTreeView(treeViewTab.RECORD)"
              (mouseenter)="mouseEnterContainer($event)" 
              (mouseleave)="mouseLeaveContainer($event)" class = "tab-ele">
                <div class="ds-tab record"></div>{{DATASOURCE_SETTING.RECORD}}
                <div  class= "ds-tab icon-show-icon-dialog" (click)="showDialogDatasourceSuggest(treeViewTab.RECORD)">
                  <i class="pi pi-exclamation-circle"></i>
                </div>
              </li>
              <li id="tab-report" [ngClass]="{'selected-tab': treeViewTab.REPORT === selectedTabTree }" (click)="changeTabTreeView(treeViewTab.REPORT)"
              (mouseenter)="mouseEnterContainer($event)" 
              (mouseleave)="mouseLeaveContainer($event)" class = "tab-ele">
                <div class="ds-tab report"></div>{{DATASOURCE_SETTING.REPORT}}
                <div  class= "ds-tab icon-show-icon-dialog"  (click)="showDialogDatasourceSuggest(treeViewTab.REPORT)">
                  <i class="pi pi-exclamation-circle"></i>
                </div>
              </li>
              <li id="tab-schedule" [ngClass]="{'selected-tab': treeViewTab.SCHEDULE === selectedTabTree }" (click)="changeTabTreeView(treeViewTab.SCHEDULE)"
              (mouseenter)="mouseEnterContainer($event)" 
              (mouseleave)="mouseLeaveContainer($event)" class = "tab-ele">
                <div class="ds-tab schedule"></div>{{DATASOURCE_SETTING.SCHEDULE}}
                <div  class= " ds-tab icon-show-icon-dialog" (click)="showDialogDatasourceSuggest(treeViewTab.SCHEDULE)">
                  <i class="pi pi-exclamation-circle"></i>
                </div>
              </li>
            </ul>
          </div>
          <div id="data-source-tab-content">
            <div class="loading-tree" *ngIf="isLoadingTree">
              <p-progressSpinner [style]="{width: '40px', height: '40px'}"></p-progressSpinner>
            </div>
          <pivot-lazy-tree-view *ngIf="!isLoadingTree" [data]="treeViewList"  [nodeClicked] = "nodeClicked" [dragScope]="dragScopeTreeView"
          [scrollHeight]=" scrollHeight" [rowHeight]="treeNodeHeight" (nodeClick)="handleItemClick($event)">
         </pivot-lazy-tree-view>
           
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="datasource-pannel-content" right-pannel>
    <div class="pannel-setting">
      <div (click)="closeDocker(1)" class="expanded-pannel">
        <i class="pi pi-chevron-left ng-star-inserted docker-icon" ></i>
        <span class="bold-text">{{DATASOURCE_SETTING.COLUMN_LAYOUT}}</span> 
      </div>
      <div>
        <span class="clear-column" (click)="clearAllColumns()">{{DATASOURCE_SETTING.CLEAR_ALL}}</span>
      </div>
    </div>
    <div class="right-pannel-content">
      <div class="column-wrapper">
        <div class="column-header">
          <span class="bold-text">{{DATASOURCE_SETTING.DS_COLUMN}}</span>
        </div>
        <div class="columns-content" #treeDropArea pDroppable="column-area" (onDrop)="handleTreeDrop($event)">
          <pivot-list-item [params]="columnListParams" [isDsSetting] = "true" (onChangeOption)="changeDataType($event)" [isBorderButton]="true"
          (onClick)="onClickColumnSelected($event)"
          (onDelete)="removeColum($event)" (onSortData)="sortingData($event)" (onRemoveData) = "onRemoveColumn($event)">
          </pivot-list-item>
        </div>
      </div>
        <div class="action-wrapper-datasource">
          <div class="container-ele">
            <div class="pivot-drawer">
               <input id="pivot__period__setting__drawer" class="pivot-drawer__trigger__element__viewed" type="checkbox">
               <div (click)="showElement('pivot__period__setting__drawer')" class="pivot-drawer__title" data-for="pivot__period__setting__drawer" >{{DATASOURCE_SETTING.DS_PERIOD}}</div>
                <div class="pivot-drawer__content-wrapper"> 
                  <div class="pivot-drawer__content">
                    <div class="field-wrapper">
                      <p-dropdown appendTo="body" class="period-wrapper" [options]="groupedPeriod" [(ngModel)]="selectedPeriod" [group]="true">
                        <ng-template let-group pTemplate="group">
                          <div class="p-d-flex p-ai-center">
                            <span>{{group.label}}</span>
                          </div>
                        </ng-template>
                        <ng-template let-option pTemplate="item">
                          <div (click)="openRangeDateModal(option)" class="option-item period-item-data-source">{{option.label}}</div>
                        </ng-template>
                      </p-dropdown>
                      <div class="field-name read-only">{{formatRangeDate(selectedRangeDate)}}</div>
                    </div>
                  </div>
               </div>
            </div>
            <div class="pivot-drawer">
               <input id="pivot__destination__drawer" class="pivot-drawer__trigger" type="checkbox">
               <div (click)="showElement('pivot__destination__drawer')" class="pivot-drawer__title" data-for="pivot__destination__drawer">{{DATASOURCE_SETTING.DS_DESTINATION}}</div>
               <div class="pivot-drawer__content-wrapper">
                  <div class="pivot-drawer__content">
                    <div class="field-wrapper">
                      <div class = "title-datasoucre-folder">{{DATASOURCE_SETTING.DS_FOLDER}}</div> 
                      <div class="field-name">{{selectedFolder.name}}</div>
                    </div>
                    <pivot-text-button class="new-folder" (btnClick)="showFolderModal()" [disabled]="mode === screenMode.PREVIEW" [buttonType]="buttonType.SECONDARY" [buttonText]="BUTTON.SELECT_FOLDER"></pivot-text-button>
                  </div>
               </div>
            </div>
            <div class="pivot-drawer">
              <input id="pivot__others__drawer" class="pivot-drawer__trigger" type="checkbox">
                <div (click)="showElement('pivot__others__drawer')" class="pivot-drawer__title" data-for="pivot__others__drawer">{{DATASOURCE_SETTING.DS_ORDERS}}
                  <div id="input" type="text" class="input-frame"></div>
                  <span class="count-check">{{checkboxInf?.count}}</span>
                </div>
              <div class="pivot-drawer__content-wrapper">
                <div class="pivot-drawer__content">
                  <div class="field-inline">
                    <pivot-checkbox [disabled]="isDisabledCheckBoxByType('lastestrecord')" [value]="isOnlyLastestData" (onClick)="changeOptionGetLastestData()"></pivot-checkbox>
                    <label [style]="{'opacity': isDisabledCheckBoxByType('lastestrecord') ? '0.5' : '1' }" class="text-of-other-item">{{DATASOURCE_SETTING.DS_ONLY_LASTEST_DATA}}</label>
                  </div>
                  <div  class="field-inline">
                    <pivot-checkbox [disabled]="isDisabledCheckBoxByType('dspdate')" [value]="isDisplayDate" (onClick)="changeOptionDisplayDate()"></pivot-checkbox>
                    <label [style]="{'opacity': isDisabledCheckBoxByType('dspdate') ? '0.5' : '1' }" class="text-of-other-item">{{DATASOURCE_SETTING.DS_DISPLAY_DATE}}</label>
                  </div>
                  <div  class="field-inline">
                    <pivot-checkbox [disabled]="isDisabledCheckBoxByType('dspBlockUnit')" [value]="isDspBlockUnit" (onClick)="changeOptionDspBlockUnit()"></pivot-checkbox>
                    <label [style]="{'opacity': isDisabledCheckBoxByType('dspBlockUnit') ? '0.5' : '1' }" class="text-of-other-item">{{DATASOURCE_SETTING.DISPLAY_BLOCK_UNIT}}</label>
                  </div>
                  <div class="field-inline">
                      <pivot-checkbox [disabled]="isDisabledCheckBoxByType('isDspAge')" [value]="isDspAge" (onClick)="changeOptionGetAge()"></pivot-checkbox>
                      <label class="age-text" [style]="{'opacity': isDisabledCheckBoxByType('isDspAge') ? '0.5' : '1' }" class="text-of-other-item">{{DATASOURCE_SETTING.DS_AGE}}</label>
                  </div>
                  <div class="field-inline-cal">
                      <label class="cal-date" [style]="{'opacity': isDisabledCheckBoxByType('isDspAge') || !isDspAge ? '0.5' : '1' }" class="text-of-other-item">{{DATASOURCE_SETTING.DS_CALCULATION_DATE}}</label>
                      <pivot-calendar [disabled]="!isDspAge"   
                                      [dateFormat]="calendarFormat" 
                                      [appendTo]="appendToElement" 
                                      class="disable dsp-cal-date" 
                                      [(value)]="calcAge"
                                      (valueChange)="changeCalcAte($event)">
                      </pivot-calendar>
                  </div>
                  <div class="field-inline-ref-date">
                    <pivot-checkbox [disabled]="isDisabledCheckBoxByType('isDspLapsedDay')" [value]="isDspLapsedDay" (onClick)="changeOptionGetLapsedDay()"></pivot-checkbox>
                    <label class="age-text cal-date" [style]="{'opacity': isDisabledCheckBoxByType('isDspLapsedDay') ? '0.5' : '1' }" class="text-of-other-item">{{DATASOURCE_SETTING.DS_REFERENCE_DATE}}</label>
                    <div class="calendar_of_reference_date">
                      <pivot-calendar [disabled]="!isDspLapsedDay"   
                      [dateFormat]="calendarFormat" 
                      [appendTo]="appendToElement" 
                      class="disable dsp-cal-date" 
                      [(value)]="lapsedDay"
                      (valueChange)="changeLapsedDay($event)">
                    </pivot-calendar>
                    </div>
                  </div>
                  <div class = "title-aggregation">{{DATASOURCE_SETTING.AGGREGATION_TYPE}}</div>
                  <div class="aggreration-item">
                    <pivot-checkbox [disabled]="isDisabledCheckBoxByType('aggrerationtype')" class="aggreration-checkbox" [value]="includingstartdate" (onClick)="chooseAggrerationType(aggregationType.INCLUDINGSTARTDATE)">
                    </pivot-checkbox>
                    <label [style]="{'opacity': isDisabledCheckBoxByType('aggrerationtype') ? '0.5' : '1' }"class = "text-of-other-item" >{{DATASOURCE_SETTING.INCLUDES_START_DATE}}</label>
                  </div>
                  <div class="aggreration-item">
                    <pivot-checkbox [disabled]="isDisabledCheckBoxByType('aggrerationtype')" class="aggreration-checkbox" [value]="dischargedate" (onClick)="chooseAggrerationType(aggregationType.DISCHARGEDATE)">
                    </pivot-checkbox>
                    <label [style]="{'opacity': isDisabledCheckBoxByType('aggrerationtype') ? '0.5' : '1' }" class = "text-of-other-item">{{DATASOURCE_SETTING.INCLUDES_DATE_DISCHARGE}}</label>
                  </div>
                  <div class="aggreration-item">
                    <pivot-checkbox [disabled]="isDisabledCheckBoxByType('isBedDisplay')" class="aggreration-checkbox" [value]="isBedDisplay" (onClick)="changeOptionBedDislay()">
                    </pivot-checkbox>
                    <label [style]="{'opacity': isDisabledCheckBoxByType('isBedDisplay') ? '0.5' : '1' }" class = "text-of-other-item">{{DATASOURCE_SETTING. VACANT_BED_DISPLAY}}</label>
                  </div>
                </div>
              </div>
            </div>
            <div class="pivot-drawer">
              <input id="pivot__user_defined__drawer" class="pivot-drawer__trigger" type="checkbox">
                <div (click)="showElement('pivot__user_defined__drawer')" class="pivot-drawer__title" data-for="pivot__user_defined__drawer">{{DATASOURCE_SETTING.DS_USER_DEFINED}}</div>
              <div class="pivot-drawer__content-wrapper">
                <div class="pivot-drawer__content">
                  <div class="field-inline">
                    <label>{{DATASOURCE_SETTING.DS_USER_DEFINED_COLUMN}}</label>
                    <pivot-text-button class="new-folder" (btnClick)="onOpenDefinedColumnDialog()" [buttonType]="buttonType.SECONDARY" [buttonText]="BUTTON.MANAGERMENT"></pivot-text-button>
                  </div>
                </div>
              </div>
            </div>
            <div id="pivot-drawer-hidden"></div>
          </div>
        </div>
      </div>
  </div>
  <div class="datasource-controls-popup"  control-popup>
		<label class="datasource-controls-popup--item" (click)="duplicatedRecord()">{{BUTTON.COPY}}</label>
		<label class="datasource-controls-popup--item" (click)="openDeleteDialog()">{{BUTTON.DELETE}} </label>
	</div>
  <div class="data-source-setting-table" body-content>
    <pivot-table [data]="tableData" [isLoading]="isTableLoading">
    </pivot-table>
  </div>

</pivot-splitter-panel-template>
<pivot-office-service [actionLog] = "datasourceSettingLogAction" *ngIf="isShowOffice" [selectedOfficeServices]="officeList" (closeModalHandler)="closeOfficeModal()"
  (selectedOfficeHandler)="changeOffices($event)">
</pivot-office-service>

<pivot-folder-modal [folderActionLog] = "datasourceSettingLogAction"  *ngIf="isShowFolder" [folderType]="folderType.Datasource" [folderList]="folderEdits" (closeModalHandler)="closeFolderModal()"
  (selectedFolderHandler)="changeFolder($event)" [selectedFolder]="selectedFolderRow">
</pivot-folder-modal>

<pivot-confirm-del-selected-dialog [isDisplayModal]="isDisplayConfirmDeleteModal"  [isCustomDialogForDataSourceList]="isDeleteDS" [renderArray]="renderTable" [modalData]="modalDelete"
	(onSubmitData)="onConfirmDeleteDlg($event)" [deleteItems]="nameDataSourceDelete" [inputData]="delTxt" >
</pivot-confirm-del-selected-dialog> 

<pivot-range-date-modal *ngIf="isDisplayRangeDateModal" (closeModalHandler)="closeRangeDateModal()"
  (rangeDateHandler)="setRangeDate($event)">
</pivot-range-date-modal>

<pivot-dialog-select-title *ngIf="isShowTitle" [itag]="itag" [titles]="titles" [officeCds]="officeCds" (closeModalHandler)="closeTitleModal()"
(selectedTitleHandler)="changeTitles($event)">
</pivot-dialog-select-title>

<pivot-modal-template *ngIf="isDisplayWarning" [modalTemplate]="modalDataWarning" (closeModalHandler)="closeWarning()">
  <div modal-body>
      {{warningMessage}}
  </div>
  <div modal-bottom >
      <div class="btn-grp-warning">
          <pivot-text-button  (btnClick)="closeWarning()" [buttonType]="buttonType.SECONDARY"
              [buttonText]="BUTTON.CANCEL">
          </pivot-text-button>
      </div>  
  </div>
</pivot-modal-template>

<pivot-select-widget *ngIf="isShowWidget" [isDataSourceTemplate] = "isDataSourceTemplate" [widgetList]="usedWidgets" [dataSourceCd]="dataSourceCd" (closeModalHandler)="closeWidgetModal()"
  (selectedWidgetHandler)="saveDataSource($event)">
</pivot-select-widget>

<pivot-user-defined-column *ngIf="isShowUserDefined" 
  [selectedDsColumns] = "selectedDsColumns" 
  [userDefinedColumns] = "userDefinedColumns" 
  [selected] = "selectedDefinedColumns" 
  [colUsed] = "colUsed" 
  (onSubmitUserDefinedColumn)="onSubmitUserDefinedColumn($event)" 
  (closeModalHandler)="closeDefinedColumnDialog()"
  (onDeleteUserDefinedColumn)="onDeleteUserDefinedColumn($event)">
</pivot-user-defined-column>