import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ResponseData } from '../../../app/models/api-model';
import { CorpMstModel } from '../../../app/models/response/corpMst.ro';
import { API_APP, PIVOT_CLIENT_API_URL } from '../../config/app.config';
import { ApiService } from '../api.service';
import {ErrorHandleService} from '../error-handle.service';
import { SaucerLogService, SAUCER_LOG_ACTION } from '../saucer-logs/saucer-log.service';



@Injectable({ providedIn: 'root' })
export class CorpMstService extends ApiService {
  public sessionCorpData: any = null;
  
  constructor(
    http: HttpClient,
    router: Router,
    errorHandle: ErrorHandleService,
    private saucerLogService: SaucerLogService
  ) {
    super(http, router, errorHandle);
  }

  async getAll() {
    let apiUrl = API_APP.CORPMST.CONTROLLER + API_APP.CORPMST.ALL;
    const apiPath = apiUrl.replace(PIVOT_CLIENT_API_URL, "");
    let resData = await this.get(apiUrl);
    if (resData.statuscode === 200) {
      this.sessionCorpData = resData?.data || []
      this.saucerLogService.system({
        apiPath,
        body: resData
      }, {action: SAUCER_LOG_ACTION.COMMON_CALL_API.GET});
    } else {
      this.saucerLogService.error({
        apiPath,
        body: resData,
        statusCode: resData?.statuscode
      }, {action: SAUCER_LOG_ACTION.COMMON_CALL_API.GET});
    }
    return await this.mapModel(resData);
  }

  async insertOrUpdate(bodyData: any, isUpdate: boolean = false) {
    let parameter = [
      isUpdate
    ];
    let apiUrl = API_APP.CORPMST.CONTROLLER + API_APP.CORPMST.INSERTORUPDATE;
    const apiPath = apiUrl.replace(PIVOT_CLIENT_API_URL, "");
    let resData =  await this.post(apiUrl, parameter, bodyData);
    if (resData.statuscode === 200 && resData.data) {
      // write log
      if (bodyData.length > 0) {
        // contentcd = 0001 : Start month setting
        let oldMonth = this.sessionCorpData?.find((x:any)=>x?.contentcd === "0001")?.value || "";
        // contentcd = 0002 : Elderly Health Care: Home Return and Home Care Index
        let oldIndicator = this.sessionCorpData?.find((x:any)=>x?.contentcd === "0002")?.value || "";
        for (let i = 0; i < bodyData.length; i++) {
          const elm = bodyData[i];
          const objLog = {
            old:{
              [elm.content] : elm.contentcd === "0001" ? oldMonth : oldIndicator
            },
            new:{
              [elm.content] : elm.value
            }
          }
          this.saucerLogService.system(
            {
              apiPath,
              statusCode: resData.statuscode,
              content: JSON.stringify(objLog)
            },
            {
              action: elm.contentcd === "0001" ? SAUCER_LOG_ACTION.CORP_SETTING.UPDATE_MONTH : SAUCER_LOG_ACTION.CORP_SETTING.UPDATE_INDICATOR
            }
          );
          this.sessionCorpData = this.sessionCorpData.map((item:any)=> {
            if (item.contentcd === elm.contentcd) {
              item = elm;
            }
            return item;
          });
        }
      }
    } else {
      if (bodyData.length > 0) {
        for (let i = 0; i < bodyData.length; i++) {
          const elm = bodyData[i];
          this.saucerLogService.error(
            {
              apiPath,
              statusCode: resData.statuscode,
              content: [elm.content].toString() + ": ERROR"
            },
            {
              action: elm.contentcd === "0001" ? SAUCER_LOG_ACTION.CORP_SETTING.UPDATE_MONTH : SAUCER_LOG_ACTION.CORP_SETTING.UPDATE_INDICATOR
            }
          );
        }
      }
    }
    return resData;
  }

  async mapModel(data: any) {
    let res: ResponseData<CorpMstModel[]> = new ResponseData<CorpMstModel[]>();
    let dataArr = [];
    res.statuscode = data.statuscode;
    res.statusmessage = data.statusmessage;
    if (res.statuscode === 200) {
      for await (const element of data.data) {
        let corpMst = new CorpMstModel();
        corpMst.id = element.id;
        corpMst.corpcd = element.corpcd;
        corpMst.contentcd = element.contentcd;
        corpMst.content = element.content;
        corpMst.value = element.value;
        corpMst.insdate = element.insdate;
        corpMst.insstfcd = element.insstfcd;
        corpMst.upddate = element.upddate;
        corpMst.updstfcd = element.updstfcd;
        corpMst.delflg = element.delflg;
        dataArr.push(corpMst);
      }
    }
    res.data = dataArr;
    return res;
  }
}
