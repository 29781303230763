import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { MESSAGE_TEXT } from 'src/app/const/message';
import { BUTTON, COMMON_TEXT } from 'src/app/const/text-common';
import { ButtonType, SaveType } from 'src/app/enum/common-enum';
import { ModalTemplate } from 'src/app/models/common-model';

@Component({
  selector: 'pivot-dialog-question-role-new-value-entered',
  templateUrl: './dialog-question-role-new-value-entered.component.html',
  styleUrls: ['./dialog-question-role-new-value-entered.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class DialogQuestionRoleNewValueEnteredComponent implements OnInit {
  buttonType = ButtonType;
  saveType = SaveType;
  BUTTON = BUTTON;
  MESSAGE_TEXT = MESSAGE_TEXT;
  modalData: ModalTemplate = new ModalTemplate();
  constructor(public ref: DynamicDialogRef,
    public config: DynamicDialogConfig) { 
      this.config.showHeader = true;
      this.config.style = { 
        width: '25%',
        header: COMMON_TEXT.CONFIRM
      };}
  
  ngOnChanges(){
  }
  
  ngOnInit(){
  }
  
  onSubmit(data: number) {
    this.ref.close(data);
  }
}
