import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { API_APP } from '../../config/app.config';
import { ApiService } from '../api.service';
import { sortBy } from '../../../app/_helper/helper';
import { values } from 'lodash';
import Utils from '../../../app/util/utils';
import { OrderBy, SortType } from '../../../app/const/const';
import {ErrorHandleService} from '../error-handle.service';

@Injectable({ providedIn: 'root' })
export class OfficeAPIService extends ApiService {
  myArrOffice: any[] = [];
  constructor(http: HttpClient, router: Router, errorHandle: ErrorHandleService) {
    super(http, router, errorHandle);
  }

  async getAll(): Promise<any>  {
    let apiUrl = API_APP.OFFICE.CONTROLLER + API_APP.OFFICE.ALL;
    return await this.get(apiUrl);
  }

  async getAllOfficeService(): Promise<any>  {
    let apiUrl = API_APP.OFFICE.CONTROLLER + API_APP.OFFICE.SERVICES;
    let resData = await this.get(apiUrl);
    return await this.mapOfficeService(resData);
  }

  async getAllOfficeServicesAllowDelFlg(): Promise<any>  {
    let apiUrl = API_APP.OFFICE.CONTROLLER + API_APP.OFFICE.ZAITAKU_STATUS;
    let resData = await this.get(apiUrl);
    return await this.mapOfficeService(resData);
  }

  async getAllOfficeTemplateService(): Promise<any>  {
    let apiUrl = API_APP.OFFICE.CONTROLLER + API_APP.OFFICE.TEMPLATE;
    let resData = await this.get(apiUrl);
    return await this.mapOfficeService(resData);
  }

  getDetail(code: string) {
    let parameter = [
      code
    ];
    let apiUrl = API_APP.TEAM.CONTROLLER + API_APP.TEAM.DETAIL;
    return this.get(apiUrl, parameter);
  }

  mapOfficeService(data: any) {
    if(data.statuscode === 200 && data.data) {
      let realData: any [];
      realData = !data?.data?.result?  data.data : data?.data?.result;
      let officeServices = realData.reduce((r, {
        corpcd,
        officecd,
        officenm,
        sortno,
        ...service
      }) => {
        const key = `${officecd}-${officenm}`;
        r[key] = r[key] || { corpcd: corpcd, officeCd: officecd, officeNm: officenm, sortno: sortno, services: [] };
        if(!Utils.isNullOrEmpty(service.srvkbncd)) {
          r[key]["services"].push({
            id: service.srvkbncd + "_" + service.servicetype,
            srvkbnnm: service.srvkbnnm, 
            serviceType: service.servicetype, 
            fixflg: service.fixflg
          });
        }
        return r;
      }, {});
      let result = values(officeServices);
      sortBy(result, SortType.SORT_NO, OrderBy.ASC);
      return result;
    }
    return [];
  }
  
  getData(key: string): any {
    const data = localStorage.getItem(key);
    if (!data) {
        return null;
    }
    return JSON.parse(data);
  }

  getOfficeSelected():any {
    return JSON.stringify(this.myArrOffice);
  }

  setOfficeSelected(data: any) {
    this.myArrOffice = data ?? [];
  }

  setData(information: any, key: string): void {
    localStorage.setItem(key, JSON.stringify(information));
  }
  
  mapOfficeServiceZaitaku(data: any) {
    if (data.statuscode === 200 && data.data) {
      let listData: any[];
      listData = data.data;
      const newListData = listData.map(obj => {
        const { jignm, officenm, ...rest } = obj;
        return { name: jignm, officenm: jignm, ...rest };
      });
      return newListData;
    }
    return [];
  }

  async getOfficeServiceZaitaku(): Promise<any> {
    let apiUrl = API_APP.OFFICE.CONTROLLER + API_APP.OFFICE.SERVICES_ZAITAKU;
    let resData = await this.get(apiUrl);
    return this.mapOfficeServiceZaitaku(resData);
  }
}
