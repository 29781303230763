import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { cloneDeep, isEqual } from 'lodash';
import * as moment from 'moment';
import { DialogService } from 'primeng/dynamicdialog';
import { DialogModalComponent } from 'src/app/component/common/dialog-modal/dialog-modal.component';
import { MESSAGE_TEXT } from 'src/app/const/message';
import { ZaitakuDetailDTO } from 'src/app/models/request/zaitaku-detail.dto';
import { ZaitakuUserInfoDTO, ZaitakuDTO } from 'src/app/models/request/zaitaku.dto';
import { ZaitakuDatatableDetailItem, ZaitakuSyncItem } from 'src/app/models/response/zaitaku.ro';
import { OfficeAPIService } from 'src/app/services/modules/office.service';
import { ZaitakuService } from 'src/app/services/modules/zaitaku.service';
import { ProcessLoadingService } from '../../../app/services/loading.service';
import { CK_REPORT_COLUMNCDS, CK_REPORT_COLUMNS } from '../../const/table-col-define';
import { BUTTON, CK_REPORT_TEXT, COMMON_TEXT, SYSTEM_SETTING_CODE } from '../../const/text-common';
import { ButtonType, DataType, DateFormat, LambdaStatus, SearchType, TreeViewTab } from '../../enum/common-enum';
import { ButtonItem, IconModal, ModalParam, SearchParams } from '../../models/common-model';
import { TableData } from '../../models/table-model';
import {SERVICE_TYPE} from '../../const/zaitaku-table-define'
import { convertJapaneseNumber, findChangedProperties } from 'src/app/_helper/helper';
import {ErrorHandleService} from 'src/app/services/error-handle.service';
import {FUNCTION_TITLE_TEXT} from 'src/app/const/error-text';
import { ZaitakuSettingDialogComponent } from './zaitaku-setting-dialog/zaitaku-setting-dialog.component';
import { DatePipe } from '@angular/common';
import { itemZaitakuAutoSyncModel, requestSyncDataModel } from 'src/app/models/zaitaku-model';
import { StepFunctionService } from 'src/app/services/modules/step-function.service';
import { ClearProcessService } from 'src/app/services/clear-process.service';
import { CorpMstService } from 'src/app/services/modules/corpmst.service';
import { CorpMstModel } from 'src/app/models/response/corpMst.ro';
import { DEFAULT_AUTO_SYNC_COLS } from 'src/app/const/const';
import { SAUCER_LOG_ACTION, SaucerLogService } from 'src/app/services/saucer-logs/saucer-log.service';
import { CONTENT_LOG } from 'src/app/config/saucer-log.config';
@Component({
  selector: 'pivot-ck-report',
  templateUrl: './ck-report.component.html',
  styleUrls: ['./ck-report.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class CkReportComponent implements OnInit {

  CK_REPORT = CK_REPORT_TEXT;
  listOffices: Array<any> = [];
  buttonType = ButtonType;
  searchParams: SearchParams = {
    type: SearchType.combo,
    cssStyle: { width: '488px', height: '40px', 'font-size': '0.875rem' },
    readonly: false,
    disabled: false,
    maxLength: 100,
    defaultValue: '',
    comboDisplayText: 'name'
  };
  now: Date = new Date();
  startDate: Date = new Date(this.now.getFullYear(), this.now.getMonth() - 12, this.now.getDate());
  endDate: Date = new Date(this.now.getFullYear(), this.now.getMonth() - 1, this.now.getDate());
  startDateInitial: Date = new Date();
  endDateInitial: Date = new Date();
  dateFormat: string = DateFormat.SHORT_DATE.toLocaleLowerCase();
  isValidDate: boolean = true;
  isValidMapDateTable: boolean = true;
  isEdit: boolean = false;
  configTableData: TableData;
  currentSelectedOffice: any = {};
  currentSelectedOfficeInitial: any = {};
  dataTable: ZaitakuSyncItem[] = [];
  dataTblUserInfo: ZaitakuDatatableDetailItem[] = [];
  endDateAt1stDay: Date = new Date(this.now.getFullYear(), this.now.getMonth() - 1, this.now.getDate());
  zaitakuData: any[] = [];
  isChangeDate: boolean = false;
  inputText: string = CK_REPORT_TEXT.DIRECT_INPUT;
  autoSyncCells: itemZaitakuAutoSyncModel[] = [];
  firstTimeCalling: boolean = true;
  intervalIdList: any[] = [];
  includeEdateDischarge: boolean = true;
  hasHospitalNearby: boolean = false;
  autoSyncColumns: string = "";

  logAction: any = SAUCER_LOG_ACTION.RETURN_HOME;
  SAUCER_LOG_ACTION = SAUCER_LOG_ACTION;
  showAutomaticAcquisitionSettingsLog : any = { 
    log: {}, 
    action: this.logAction.SHOW_DIALOG 
  };
  originalbodyDataDetail: ZaitakuDetailDTO[] = [];

  constructor(
    private loadingService: ProcessLoadingService,
    private officeAPI: OfficeAPIService,
    private zaitakuService: ZaitakuService,
    private corpMstService: CorpMstService,
    private modalService: DialogService,
    private errorHandleService: ErrorHandleService,
    private stepFunctionService: StepFunctionService,
    private clearProcessService: ClearProcessService,
    private datePipe: DatePipe,
    private saucerLogService: SaucerLogService

  ) {

    //Log
    this.saucerLogService.action({}, { 
      action: SAUCER_LOG_ACTION.RETURN_HOME.VIEW 
    });
  }

  async ngOnInit(): Promise<void> {
    this.errorHandleService.setFunctionTitle(FUNCTION_TITLE_TEXT.SCREEN_INIT_FAIL);
    this.loadingService.isLoading.emit(true);
    this.inputText = this.isEdit? BUTTON.SAVE : CK_REPORT_TEXT.DIRECT_INPUT;
    await this.officeAPI.getOfficeServiceZaitaku().then((data: any) => {
      this.listOffices =  data || [];
    });
    this.searchParams.comboDisplayText = 'name';
    this.searchParams.dataSource = this.listOffices;
    this.searchParams = cloneDeep(this.searchParams);
    const syncedRs = await this.zaitakuService.getAll(true);
    if (syncedRs.statuscode === 200) {
      const syncedData = syncedRs.data;
      this.zaitakuData = syncedRs.data;
      if (syncedData.length > 0) {
        this.mapData(syncedData);
      } else {
        this.hasHospitalNearby = false;
        this.currentSelectedOffice = this.listOffices.length > 0 ? this.listOffices[0] : {};
        this.searchParams.defaultValue = this.currentSelectedOffice?.name;
        this.searchParams = cloneDeep(this.searchParams);
        this.startDate =  new Date(new Date(this.startDate).setDate(1));
        this.endDateAt1stDay =  new Date(new Date(this.endDate).setDate(1));
      }
    }

    await this.getIncludeEdateDischarge();
    await this.getAutoSyncCells();
    this.configTable();
    await this.initZaitakuUserInfo();

    this.loadingService.isLoading.emit(false);
  }

  async getAutoSyncCells() {
    const settingRes = await this.zaitakuService.getSettingByCondition(this.currentSelectedOffice?.jigno, this.currentSelectedOffice?.siteino,this.datePipe.transform(this.startDate, 'yyyy-MM') || "",this.datePipe.transform(this.endDate, 'yyyy-MM') || "")
    if (settingRes && settingRes.statuscode == 200) {
      this.autoSyncCells = settingRes.data as itemZaitakuAutoSyncModel[] || [];
    } else {
      this.autoSyncCells = [];
    }
  }

  async getIncludeEdateDischarge() {
    let mstRes = await this.corpMstService.getAll();
    if (mstRes.statuscode == 200) {
      let edateDischargeSetting = mstRes.data?.find(x => x.contentcd == SYSTEM_SETTING_CODE.INCLUDE_EDATE_DISCHARGE) || new CorpMstModel();
      this.includeEdateDischarge = (edateDischargeSetting.value == '0');
    }
  }

  mapData(data: any) {
    this.currentSelectedOffice = this.listOffices[0];
    if(this.currentSelectedOffice) {
      let dataSelected = data.find((s: any) => s.jigno == this.currentSelectedOffice.jigno && s.siteino == this.currentSelectedOffice.siteino)
      if(dataSelected) {
        this.currentSelectedOfficeInitial = this.listOffices.find(o => o.jigno == dataSelected.jigno && o.siteino == dataSelected.siteino);
        this.searchParams.defaultValue = this.currentSelectedOffice?.name;
        this.searchParams = cloneDeep(this.searchParams);
        this.startDate = new Date(dataSelected.sdate);
        this.endDateAt1stDay = new Date(new Date(dataSelected.edate).setDate(1));
        this.endDate = new Date(new Date(new Date(this.endDateAt1stDay).setMonth(new Date(this.endDateAt1stDay).getMonth() + 1, 0)).setHours(0, 0, 0, 0));
        this.startDateInitial = new Date(dataSelected.sdate);
        this.endDateInitial = new Date(dataSelected.edate);
        let tblData = JSON.parse(dataSelected.datatbl) || [];
        tblData?.map((row: any) => {
          new Date(row.date) < this.startDate ? row.hide = true : row.hide = false;
        })
        this.dataTable = tblData;
        this.dataTable.forEach(item => {
          item.countflg = item.countflg ?? true;
          item.hasSocialLicense = item.hasSocialLicense ?? false;
          item.hasAllRehabProfessionals = item.hasAllRehabProfessionals ?? false;
          item.isRehabQualified = item.isRehabQualified ?? false;
          item.isRehab20Min = item.isRehab20Min ?? false;
          item.isRehab3PerWeek = item.isRehab3PerWeek ?? false;
        });
      
        this.hasHospitalNearby = dataSelected.hashospitalnearby ?? false;
        this.autoSyncColumns = dataSelected.autosynccolumns;
      } else {
        this.hasHospitalNearby = false;
      }
    }
  }

  async syncTableData(jigNo: string, siteiNo: string) {
    this.errorHandleService.setFunctionTitle(FUNCTION_TITLE_TEXT.IMPORT_DATA_FAIL);
    let admissionsData: any[] = [];
    let dischargesToHomeData: any[] = [];
    var offset = 6 ;
    let resData = await this.zaitakuService.syncZaitaku(jigNo, siteiNo);
    let startDate = new Date(this.startDate);
    let resDataDetailOffset = await this.zaitakuService.getZaitakuDetailOffset(jigNo, siteiNo, moment(startDate).format(DateFormat.FULL_SHORT_DATE), offset);
    let countOffset = resDataDetailOffset.length; 
    if (countOffset == 0) {
      startDate.setMonth(startDate.getMonth() - 6);
    }
    else if (countOffset < offset) {
      var lastMonth = resDataDetailOffset[countOffset - 1].monthRaw;
      lastMonth.setMonth(lastMonth.getMonth() - offset + countOffset);
      startDate = lastMonth;
    } else {
      startDate = resDataDetailOffset[countOffset - 1].monthRaw;
    }

    // Statistics for hisio 6 months before sdate_hisio to handle the case of corona statistics at Home
    let autoSyncItems: itemZaitakuAutoSyncModel[] = [...this.autoSyncCells];

    let oneMonthAgo = new Date(this.startDate);
    oneMonthAgo.setMonth(oneMonthAgo.getMonth() - 1);

    const settingRes = await this.zaitakuService.getSettingByCondition(this.currentSelectedOffice?.jigno, this.currentSelectedOffice?.siteino,this.datePipe.transform(startDate, 'yyyy-MM') || "",this.datePipe.transform(oneMonthAgo, 'yyyy-MM') || "")
    if (settingRes && settingRes.statuscode == 200) autoSyncItems = [...autoSyncItems, ...settingRes.data]
    
    let resDataDetail = await this.zaitakuService.getZaitakuDetail(jigNo, siteiNo, moment(startDate).format(DateFormat.FULL_SHORT_DATE), moment(this.endDate).format(DateFormat.FULL_SHORT_DATE));
    if (resData.statuscode === 200) {
      let processData = resData.data;
      let processDataDetail = resDataDetail;
      const filterGklvData = cloneDeep(processData.filter((d: any) => d.gklv === '24' || d.gklv === '25'));
      const rmHisninData = this.removeDuplicates(processData.map((d: any) => {
        const { gklv, gsdt, gedt, ...rest } = d;
        return rest;
      }));

        this.dataTable = [];
        this.dataTblUserInfo = [];

        // Statistics for hisio 1 month before sdate_hisio to handle the case (column: 退所前後訪問指導を行った者の延数 & checkbox: 延べ人数)
        let oneMonthsBeforeSDate = new Date(startDate);
        oneMonthsBeforeSDate.setMonth(oneMonthsBeforeSDate.getMonth() - 1);
        let isFirstIteration = true;

        let currentDate = moment(oneMonthsBeforeSDate).startOf('month');
        while (currentDate.isSameOrBefore(moment(this.endDate))) {
          let dataTableItem = new ZaitakuSyncItem();
          let detailItem = new ZaitakuDatatableDetailItem();
          detailItem.date = currentDate.format(DateFormat.YEAR_MONTH);

          dataTableItem.date = currentDate.format(DateFormat.YEAR_MONTH);
          let dataZaitakuDetail = processDataDetail.find((p: any) => p.month === dataTableItem.date);
          dataTableItem.dayInMonth = currentDate.daysInMonth();
          dataTableItem.cumulativeDays = this.calculateDaysCareLevel(filterGklvData, dataTableItem.date);
          dataTableItem.inmates = this.calculateDaysNotCareLevel(rmHisninData, dataTableItem.date);
          let countOtherRs = this.calculateRecords(rmHisninData, dataTableItem.date, detailItem);

          dataTableItem.cumulativeNew = countOtherRs.countIn;
          dataTableItem.newLeavers = countOtherRs.countOut;
          dataTableItem.peopleLeaving = countOtherRs.countOutLive;
          dataTableItem.death = countOtherRs.countOutDead;
          admissionsData = [...admissionsData, ...countOtherRs.inData]
          dischargesToHomeData = [...dischargesToHomeData, ...countOtherRs.outData]

          if (dataZaitakuDetail) {
            dataTableItem.countflg = dataZaitakuDetail.countflg;
            dataTableItem.hasSocialLicense = dataZaitakuDetail.hassociallicense;
            dataTableItem.hasAllRehabProfessionals = dataZaitakuDetail.hasallrehabprofessionals ?? false;
            dataTableItem.isRehabQualified = dataZaitakuDetail.isrehabqualified;
            dataTableItem.isRehab20Min = dataZaitakuDetail.isrehab20min;
            dataTableItem.isRehab3PerWeek = dataZaitakuDetail.isrehab3perweek;
            dataTableItem.admission = dataZaitakuDetail.admission;
            dataTableItem.discharge = dataZaitakuDetail.discharge;
            dataTableItem.serviceType = dataZaitakuDetail.servicetype;
            dataTableItem.healthFacility = dataZaitakuDetail.healthfacility;
            dataTableItem.physicalTherapists = dataZaitakuDetail.physicaltherapists;
            dataTableItem.careHealthFacility = dataZaitakuDetail.carehealthfacility;
            dataTableItem.coumselors = dataZaitakuDetail.coumselors;
            dataTableItem.aspiration = dataZaitakuDetail.aspiration;
            dataTableItem.feeding = dataZaitakuDetail.feeding;
            dataTableItem.sameDayDischarge = dataZaitakuDetail.samedaydischarge ?? 0;
            dataTableItem.sameDayAdmissionDischarge = dataZaitakuDetail.samedayadmissiondischarge ?? 0;
          }
          if (!isFirstIteration) {
            this.dataTable.push(dataTableItem);
          } else {
            isFirstIteration = false;
          }
          if(new Date(detailItem.date) >= this.startDate) this.dataTblUserInfo.push(detailItem);
          currentDate.add(1, 'month');
        }
        this.dataTable?.map((row: any) => {
          new Date(row.date) < this.startDate ? row.hide = true : row.hide = false;
        })
    }
    this.autoSyncColumns = DEFAULT_AUTO_SYNC_COLS.join(',');

    if (!autoSyncItems || autoSyncItems.length == 0) {
      let bodyData = this.getDataInsert();
      await this.zaitakuService.insert(bodyData, false)
      let dataTblUserInfo = this.mapUserInfoToInsert();
      await this.zaitakuService.insertDataTableUserInfo(dataTblUserInfo)
      this.resetInitialValue()
      this.configTable();
      this.isValidMapDateTable = true;
      this.loadingService.isLoading.emit(false);
    } else {
      let reqData = new requestSyncDataModel();
      reqData.syncItems = autoSyncItems;
      this.autoSyncColumns = this.autoSyncColumns + ',' + Array.from(new Set(autoSyncItems.map(item => item.columncd))).join(',');

      reqData.admissions = this.filterDataForSyncZaitaku("admissions", admissionsData, autoSyncItems, startDate);
      reqData.dischargesToHome = this.filterDataForSyncZaitaku("dischargesToHome", dischargesToHomeData, autoSyncItems, startDate);
      const dataSyncBySetting = await this.zaitakuService.syncZaitakuBySetting(reqData);
      if(dataSyncBySetting.statuscode === 200) {
        const screenCode = 1 + Math.random();
        let intervalId = setInterval(()=> {
          if(this.checkIfStopInterval(screenCode, intervalId)) {
            return;
          }
          this.stepFunctionService.checkStatus(dataSyncBySetting.data.executionarn).then(async resLamda => {
            if(resLamda.statuscode && resLamda.statuscode == 500) {
              clearInterval(intervalId);
              this.removeIntervalIdToList(intervalId);
            } else {
              if(resLamda.data.status === LambdaStatus.SUCCEEDED)
              {
                clearInterval(intervalId);
                this.removeIntervalIdToList(intervalId);
                
                let data: any[] = JSON.parse(resLamda.data.output);
                if (data && data.length) {
                  this.dataTable.forEach((item) => {
                    let tmp = data.filter(x => x.month == item.date.replace('/','-'));
                    if (tmp.length) {
                      tmp.forEach(element => {
                        item[element.columncd] = element.count;
                      });
                    }
                  });

                  this.dataTblUserInfo.forEach((item) => {
                    const formattedDate = item.date.replace('/', '-');
                    const filteredData = data.filter(x => x.month === formattedDate);
                  
                    if (filteredData.length) {
                      const keys: (keyof ZaitakuDatatableDetailItem)[] = ['admission', 'discharge', 'aspiration', 'feeding'];
                      const getDetailInfo = (columnCd: string) => 
                        filteredData.find(entry => entry.columncd === columnCd)?.detailInfo ?? [];
                        keys.forEach((key) => {
                          item[key] = getDetailInfo(key as string);
                        });
                    }
                  });
                  
                }
  
                let bodyData = this.getDataInsert();
                await this.zaitakuService.insert(bodyData, false)
                let dataTblUserInfo = this.mapUserInfoToInsert();
                await this.zaitakuService.insertDataTableUserInfo(dataTblUserInfo);
                this.resetInitialValue()
                this.configTable();
                this.isValidMapDateTable = true;
                this.loadingService.isLoading.emit(false);
  
              }
              else if(resLamda.data.status != LambdaStatus.RUNNING) {
                clearInterval(intervalId);
                this.removeIntervalIdToList(intervalId);
                this.loadingService.isLoading.emit(false);
              }
            }
          })
        }, 3000);
        this.addIntervalIdToList(intervalId);
      }
      else {
        this.loadingService.isLoading.emit(false);
      }  
    }


  }

  filterDataForSyncZaitaku(type: string, data: any[], autoSyncItems: any[], sixMonthsBeforeSDate: Date) {
    data = data.map(item => ({ ...item, month: item.month.replace("/", "-"), sdate: this.formatDate(item.sdate), edate: this.formatDate(item.edate)}));
    if (type == "admissions") {
      if (autoSyncItems.some(item => item.columncd == "admission")) {
        let minDate = moment(sixMonthsBeforeSDate).subtract(7, "days").format('YYYY-MM-DD') + " 00:00";
        let res = data.map(item => ({...item, sdate: item.sdate.slice(0, -5) + "00:00", edate: item.edate.slice(0, -5) + "23:59", }));
        return res.filter(item => item.sdate >= minDate && this.isMoreThan30Days(item.sdate, item.edate));
      }
    } else if (type == "dischargesToHome") { 
      if (autoSyncItems.some(item => item.columncd == "discharge")) {
        let minDate = moment(sixMonthsBeforeSDate).format('YYYY-MM-DD') + " 00:00";
        let res = data.map(item => ({...item, sdate: item.sdate.slice(0, -5) + "00:00", edate: item.edate.slice(0, -5) + "23:59", }));
        return res.filter(item => item.edate >= minDate);
      }
    }
    return [];
  }

  // isMoreThan30Days includes both the start date and the end date of hisio
  isMoreThan30Days = (startDate: string, endDate: string): boolean => (new Date(endDate).getTime() - new Date(startDate).getTime()) >= 29 * 24 * 60 * 60 * 1000;

  checkIfStopInterval(screenCode: any, intervalId: any): boolean {
    let result: boolean = false;
    if(this.firstTimeCalling) {
      this.firstTimeCalling = false;
      this.clearProcessService.setScreenCode(screenCode);
    }
    const holderCode = this.clearProcessService.getScreenCode();
    if(holderCode != screenCode) {
      clearInterval(intervalId);
      this.removeIntervalIdToList(intervalId);
      result = true;
    }

    return result;
  }
  removeIntervalIdToList(id: any) {
    this.intervalIdList = this.intervalIdList.filter(e => e != id);
    this.clearProcessService.removeIntervalIdList(id);
    this.firstTimeCalling = true;
  }
  addIntervalIdToList(id: any) {
    if(this.intervalIdList.includes(id)) return;
    this.intervalIdList.push(id);
    this.clearProcessService.setIntervalId(id);
  }

  calculateDaysCareLevel(reportData: any[], reportMonth: string) {
    // reportMonth format YYYY-MM
    reportMonth = reportMonth.replace('/', '-');

    let totalDays = 0;
    let [year, month] = reportMonth.split('-').map(Number);
    let lastDay = new Date(year, month, 0).getDate().toString().padStart(2, '0');

    let firstDayOfReportMonth = `${reportMonth}-01T00:00:00`;
    let lastDayOfReportMonth = `${reportMonth}-${lastDay}T23:59:59`;

    // loop reportData
    reportData.forEach((item) => {

      // Skip the current item if it is out of range or kbn != 0
      if (lastDayOfReportMonth < item.sdate || firstDayOfReportMonth > item.edate || item.kbn != "0") return;
      // Skip the current item if gsdt (start date) or gedt (end date) is null
      if (item.gsdt == null || item.gedt == null) return;
      // Skip the current item if it is out of range of gsdt (start date) or gedt (end date)
      if (lastDayOfReportMonth < item.gsdt || firstDayOfReportMonth > item.gedt) return;
      // Skip the current item if it is out of range of gsdt (start date) or gedt (end date)
      if (item.edate < item.gsdt || item.sdate > item.gedt) return;

      let sdate = (item.sdate > item.gsdt) ? item.sdate : item.gsdt;
      sdate = (sdate > firstDayOfReportMonth) ? sdate : firstDayOfReportMonth;

      let edate = (item.gedt < item.edate) ? item.gedt : item.edate;
      edate = (edate < lastDayOfReportMonth) ? edate : lastDayOfReportMonth;

      let  gedt = item.gedt.replace(/T\d{2}:\d{2}:\d{2}$/, 'T23:59:59');

      // Check if the 'edate' of 'hisio' is before or on the 'lastDayOfReportMonth' and also before or on the 'gedt' of 'hisnin'.
      if (item.edate <= lastDayOfReportMonth && item.edate <= gedt) {
        totalDays += this.countDaysBetweenDates(sdate, edate, this.includeEdateDischarge)
      } else {
        totalDays += this.countDaysBetweenDates(sdate, edate)
      }

    });

    return totalDays;
  }

  calculateDaysNotCareLevel(reportData: any[], reportMonth: string) {
    // reportMonth format YYYY-MM
    reportMonth = reportMonth.replace('/', '-');

    let totalDays = 0;
    let [year, month] = reportMonth.split('-').map(Number);
    let lastDay = new Date(year, month, 0).getDate().toString().padStart(2, '0');

    let firstDayOfReportMonth = `${reportMonth}-01T00:00:00`;
    let lastDayOfReportMonth = `${reportMonth}-${lastDay}T23:59:59`;

    // loop reportData
    reportData.forEach((item) => {

      // Skip the current item if it is out of range or kbn != 0
      if (lastDayOfReportMonth < item.sdate || firstDayOfReportMonth > item.edate || item.kbn != "0") return;

      let sdate = (item.sdate > firstDayOfReportMonth) ? item.sdate : firstDayOfReportMonth;
      let edate = (item.edate < lastDayOfReportMonth) ? item.edate : lastDayOfReportMonth;

      // case 1: The last day of the report month is before the end date.
      if (lastDayOfReportMonth < item.edate) totalDays += this.countDaysBetweenDates(sdate, edate)

      // case 2: Report month is the same as the end date month.
      if (lastDayOfReportMonth >= item.edate) totalDays += this.countDaysBetweenDates(sdate, edate, this.includeEdateDischarge)

    });

    return totalDays;
  }

  countDaysBetweenDates(startDate: string, endDate: string, includeEndDate: boolean = true): number {
    // Adjust the endDate to the end of the day (23:59:59) and the startDate to the beginning of the day (00:00:00)
    endDate = endDate.replace(/T\d{2}:\d{2}:\d{2}$/, 'T23:59:59');
    startDate = startDate.replace(/T\d{2}:\d{2}:\d{2}$/, 'T00:00:00');

    const start = new Date(startDate);
    let end = new Date(endDate);
    // Adjust end date to include the day if specified
    if (!includeEndDate) end.setDate(end.getDate() - 1); // Subtract one day
    // Convert both dates to milliseconds
    const startMs = start.getTime();
    const endMs = end.getTime();
    // Calculate the difference in milliseconds
    const differenceMs = endMs - startMs;
    // Total milliseconds in a day
    // Subtract 1 second to get the span from T00:00:00 to T23:59:59
    const totalMillisecondsInDay = 24 * 60 * 60 * 1000 - 1000;
    // Convert back to days
    return Math.round(differenceMs / totalMillisecondsInDay);
  }


  /**
 * [Calc automatic column]
 *
 * @param {any} groupData - Array hisio record from CK.
 * @param {string} month - Target month for calc.
 * @returns {
 *  countIn: number;
    countOut: number;
    countOutLive: number;
    countOutDead: number;} - New in, new out, return to home and who is not alive.
 */
  calcDetailsValueInMonth(groupData: any, month: any, detailItem: ZaitakuDatatableDetailItem) {
      let countIn: number = 0;
      let countOut: number = 0;
      let inData: any[] = [];
      let outData: any[] = [];
      let countOutLive: number = 0;
      let countOutDead: number = 0;
      Object.keys(groupData).forEach((k: any) => {
        let database = groupData[k];
        let j = 0;
        let x = 0;
        for(let i = 0; i < database.length; i++) {
          if(i > 0) {
            j = i - 1;
          }
          if(i < database.length - 1) {
            x = i + 1;
          }

          //get 3 pointer for calc
          //If index = 0, last index, 3 pointer can be the same
          let currentPointer = database[i];
          let prevPointer = database[j];
          let nextPointer = database[x];

          const currentSDate = moment(currentPointer.sdate).toDate();
          const nextSDate = moment(nextPointer.sdate).toDate();
          const currentEDate = moment(currentPointer.edate).toDate();
          const prevEDate = moment(prevPointer.edate).toDate();
          
          const prevEDateFormat = moment(prevEDate).add(1, 'days').startOf('day').toDate();
          const currentSDateFormat = moment(currentSDate).endOf('day').toDate();
          const timeDifferenceInMilliseconds = currentSDateFormat.getTime() - prevEDateFormat.getTime() + 1;

          //total day from prev record's end date to current record's start date
          const totalDayFromCurrentToPrev = timeDifferenceInMilliseconds / (1000 * 60 * 60 * 24);

          //estat Wiki
          //https://fds-ssc.backlog.jp/alias/wiki/636235
          /*
            0:未入力
            1:居宅
            2:介護保険施設
            3:医療機関入院
            4:死亡
            5:その他
            6:介護老人福祉施設入所（H18/04から）
            7:介護老人保健施設入所（H18/04から）
            8:介護療養型医療施設入院（H18/04から）
            9:介護医療院入所（H30/04から）
          */

          //calc total new come in
          if(currentSDate.getFullYear() == month.split('/')[0] && currentSDate.getMonth() == month.split('/')[1] - 1){
            //prev record is not same month with current record
            if((prevEDate.getFullYear() != month.split('/')[0]) 
              || (prevEDate.getFullYear() == month.split('/')[0] && prevEDate.getMonth() != month.split('/')[1] - 1)){
                countIn++;
                inData.push({ month: month, daicd: k, sdate: database[i].sdate, edate: database[i].edate, fullname: database[i].fullname })
                detailItem.cumulativeNew.push({ fullname: database[i].fullname})
              }
            //if prev record is has estat != ["1", "3"], auto calc
            else if(prevPointer.estat != "1" && prevPointer.estat != "3") {
              countIn++;
              inData.push({ month: month, daicd: k, sdate: database[i].sdate, edate: database[i].edate, fullname: database[i].fullname })
              detailItem.cumulativeNew.push({ fullname: database[i].fullname})
            }
            else if (totalDayFromCurrentToPrev > 7 || totalDayFromCurrentToPrev < 0){
              countIn++;
              inData.push({ month: month, daicd: k, sdate: database[i].sdate, edate: database[i].edate, fullname: database[i].fullname })
              detailItem.cumulativeNew.push({ fullname: database[i].fullname})
            }
          }

         //calc total new come out and leaving home
         if(currentEDate.getFullYear() == month.split('/')[0] && currentEDate.getMonth() == month.split('/')[1] - 1) {

          //User !alive
          if(currentPointer.estat == "4") {
            countOut++
            countOutDead++;
            detailItem.newLeavers.push({ fullname: database[i].fullname})
            detailItem.death.push({ fullname: database[i].fullname})
            continue;
          }

          const currentSdateStartDay = moment(currentSDate).startOf('day').toDate();
          const currentEdateEndDay = moment(currentEDate).endOf('day').toDate();
          const timeDifferenceInMillisecondsForcountOutLive = currentEdateEndDay.getTime() - currentSdateStartDay.getTime() + 1;
          const totalDayOfRecords = timeDifferenceInMillisecondsForcountOutLive / (1000 * 60 * 60 * 24);

          const currentEDateFormat = moment(currentEDate).add(1, 'days').startOf('day').toDate();
          const nextSDateFormat = moment(nextSDate).endOf('day').toDate();
          const timeDifferenceInMilliseconds = nextSDateFormat.getTime() - currentEDateFormat.getTime() + 1;

          //total day from current record's end date to next record's start date
          const totelDayFromCurrentToNext = timeDifferenceInMilliseconds / (1000 * 60 * 60 * 24);

          //next record in next month
          if(nextSDate.getFullYear() != month.split('/')[0] || (nextSDate.getFullYear() == month.split('/')[0] && nextSDate.getMonth() != month.split('/')[1] - 1 && totelDayFromCurrentToNext > 0)) {
            if(currentPointer.estat == "1") {
              if(totalDayOfRecords >= 30) {
                if((prevEDate.getFullYear() != month.split('/')[0]) || (prevEDate.getFullYear() == month.split('/')[0] && prevEDate.getMonth() != month.split('/')[1] - 1)) {
                  countOutLive++;
                  outData.push({ month: month, daicd: k, sdate: database[i].sdate, edate: database[i].edate, fullname: database[i].fullname });
                  detailItem.peopleLeaving.push({ fullname: database[i].fullname})
                }
                else if(totalDayFromCurrentToPrev > 7 || totalDayFromCurrentToPrev < 0) {
                  countOutLive++;
                  outData.push({ month: month, daicd: k, sdate: database[i].sdate, edate: database[i].edate, fullname: database[i].fullname });
                  detailItem.peopleLeaving.push({ fullname: database[i].fullname})
                }
              }
              countOut++;
              detailItem.newLeavers.push({ fullname: database[i].fullname})
            }
            else if(currentPointer.estat == "3") {
              if((prevEDate.getFullYear() != month.split('/')[0]) || (prevEDate.getFullYear() == month.split('/')[0] && prevEDate.getMonth() != month.split('/')[1] - 1)) {
                countOut++;
                detailItem.newLeavers.push({ fullname: database[i].fullname})
              }
              else {
                //totalDayFromCurrentToPrev < 0: index is 0(first record)
                if(totalDayFromCurrentToPrev > 7 || totalDayFromCurrentToPrev < 0) {
                  countOut++;
                  detailItem.newLeavers.push({ fullname: database[i].fullname})
                }
              }
            }
            else {
              countOut++;
              detailItem.newLeavers.push({ fullname: database[i].fullname})
            }
          }
          //next record is same month
          else if(nextSDate.getFullYear() == month.split('/')[0] && nextSDate.getMonth() == month.split('/')[1] - 1 && totelDayFromCurrentToNext > 0){
            if(currentPointer.estat == "1" ) {
              if(totelDayFromCurrentToNext > 7){
                countOut++;
                detailItem.newLeavers.push({ fullname: database[i].fullname})
                if(totalDayOfRecords >= 30) {
                  let prevRecordsEndSameMonth = 
                  (prevEDate.getFullYear() == month.split('/')[0] 
                  && prevEDate.getMonth() == month.split('/')[1] - 1) ? true : false;

                  if(prevRecordsEndSameMonth) {
                    //if totalDayFromCurrentToPrev < 0, this is a first 
                    if(totalDayFromCurrentToPrev > 7 || totalDayFromCurrentToPrev < 0) {
                      countOutLive++;
                      outData.push({ month: month, daicd: k, sdate: database[i].sdate, edate: database[i].edate, fullname: database[i].fullname });
                      detailItem.peopleLeaving.push({ fullname: database[i].fullname})
                    }
                  }
                  else {
                    countOutLive++;
                    outData.push({ month: month, daicd: k, sdate: database[i].sdate, edate: database[i].edate, fullname: database[i].fullname });
                    detailItem.peopleLeaving.push({ fullname: database[i].fullname})
                  }
              }
              }
            }
            else if(currentPointer.estat == "3") {
              let prevRecordsEndSameMonth = 
                (prevEDate.getFullYear() == month.split('/')[0] 
                && prevEDate.getMonth() == month.split('/')[1] - 1) ? true : false;
              
              
              if(!prevRecordsEndSameMonth) {
                if(totelDayFromCurrentToNext > 7) {
                  countOut++;
                  detailItem.newLeavers.push({ fullname: database[i].fullname})
                }
              }
              //head and tail record is different 7 day/first record and diffirent to tail 7 days
              else if(( prevRecordsEndSameMonth && totelDayFromCurrentToNext > 7 && totalDayFromCurrentToPrev > 7) 
              || (totalDayFromCurrentToPrev < 0 && totelDayFromCurrentToNext > 7)) {
                countOut++;
                detailItem.newLeavers.push({ fullname: database[i].fullname})
              }
            }
            else {
              countOut++;
              detailItem.newLeavers.push({ fullname: database[i].fullname})
            }
          }
          //first or last records
          else if(nextSDate.getFullYear() == currentSDate.getFullYear() && nextSDate.getMonth() == currentSDate.getMonth()) {
            //only has 1 record
            if(x === 0) {
              let nextRecord: any = null;
              //get next record
              try {
                let nextIndex = x + 1;
                nextRecord = database[nextIndex];
              }
              catch(e: any) {

              }

              if(nextRecord) {
                const nextSDateForCheck = moment(nextRecord.sdate).toDate();

                const currentEDateFormat = moment(currentEDate).add(1, 'days').startOf('day').toDate();
                const nextSDateFormat = moment(nextSDateForCheck).endOf('day').toDate();
                const timeDifferenceInMilliseconds = nextSDateFormat.getTime() - currentEDateFormat.getTime() + 1;
                const totelDayFromCurrentToNextCheck = timeDifferenceInMilliseconds / (1000 * 60 * 60 * 24);

                if(currentPointer.estat != "1" && currentPointer.estat != "3") {
                  countOut++;
                  detailItem.newLeavers.push({ fullname: database[i].fullname})
                }
                else if(totelDayFromCurrentToNextCheck > 7) {
                  if(currentPointer.estat == "1" && totalDayOfRecords >= 30) {
                    countOutLive++;
                    outData.push({ month: month, daicd: k, sdate: database[i].sdate, edate: database[i].edate, fullname: database[i].fullname });
                    detailItem.peopleLeaving.push({ fullname: database[i].fullname})
                  }
                  else {
                    countOut++;
                    detailItem.newLeavers.push({ fullname: database[i].fullname})
                  }
                }
              }
              else {
                if(currentPointer.estat == "1" && (totalDayOfRecords >= 30)) {
                  countOutLive++;
                  outData.push({ month: month, daicd: k, sdate: database[i].sdate, edate: database[i].edate, fullname: database[i].fullname });
                  detailItem.peopleLeaving.push({ fullname: database[i].fullname})
                }
                countOut++;
                detailItem.newLeavers.push({ fullname: database[i].fullname})
              }

            }
            //last records
            else {
              //Auto calc if this is other estat
              if(currentPointer.estat != "1" && currentPointer.estat != "3") {
                countOut++;
                detailItem.newLeavers.push({ fullname: database[i].fullname})
              }
              //apply for column new return to home if estat = 3
              else if(currentPointer.estat == "3") {
                countOut++;
                detailItem.newLeavers.push({ fullname: database[i].fullname})
              }
              // estat == 1
              else {
                if(totalDayOfRecords >= 30) {
                  let prevRecord: any = null;
                  //get prev record
                  try {
                    let prexIndex = x - 1;
                    prevRecord = database[prexIndex];
                  }catch(e: any) {}

                  if(prevRecord) {
                    const prevSDateForCheck = moment(prevRecord.sdate).toDate();
                    let prevRecordsEndSameMonth = 
                    (prevSDateForCheck.getFullYear() == month.split('/')[0] 
                    && prevSDateForCheck.getMonth() == month.split('/')[1] - 1) ? true : false;
  
                    if(prevRecordsEndSameMonth) {
                      const prevEDateForCheck = moment(prevRecord.edate).toDate();

                      const prevEDateFormat = moment(prevEDateForCheck).add(1, 'days').startOf('day').toDate();
                      const currentSDateFormat = moment(currentSDate).endOf('day').toDate();
                      const timeDifferenceInMilliseconds = currentSDateFormat.getTime() - prevEDateFormat.getTime() + 1;
                      const totalDayFromCurrentToPrevCheck = timeDifferenceInMilliseconds / (1000 * 60 * 60 * 24);
  
                      if(totalDayFromCurrentToPrevCheck > 7) {
                        countOutLive++;
                        countOut++;
                        outData.push({ month: month, daicd: k, sdate: database[i].sdate, edate: database[i].edate, fullname: database[i].fullname });
                        detailItem.peopleLeaving.push({ fullname: database[i].fullname})
                        detailItem.newLeavers.push({ fullname: database[i].fullname})
                      }
                    }
                    else {
                      countOutLive++;
                      countOut++;
                      outData.push({ month: month, daicd: k, sdate: database[i].sdate, edate: database[i].edate, fullname: database[i].fullname });
                      detailItem.peopleLeaving.push({ fullname: database[i].fullname})
                      detailItem.newLeavers.push({ fullname: database[i].fullname})
                    }
                  }
                  else {
                    countOutLive++;
                    countOut++;
                    outData.push({ month: month, daicd: k, sdate: database[i].sdate, edate: database[i].edate, fullname: database[i].fullname });
                    detailItem.peopleLeaving.push({ fullname: database[i].fullname})
                    detailItem.newLeavers.push({ fullname: database[i].fullname})
                  }
                } else {
                  countOut++;
                  detailItem.newLeavers.push({ fullname: database[i].fullname})
                }
              }
            }
          }
        }
      }
      });
    return { countIn, countOut, countOutLive, countOutDead, inData, outData };
  }


  calculateRecords(data: any[], month: any, detailItem: ZaitakuDatatableDetailItem) {
    let countIn = 0;
    let countOut = 0;
    let countOutLive = 0;
    let countOutDead = 0;
    let groupDaiCData = data.reduce((ele, item) => {
      if (!ele[item.daicd]) {
          ele[item.daicd] = [];
      }
      ele[item.daicd].push(item);
      return ele;
    }, {});
    for (const key in groupDaiCData) {
      if (groupDaiCData.hasOwnProperty(key)) {
          const array = groupDaiCData[key];
          groupDaiCData[key] = array.sort(this.compareDates);
      }
    }
    let countValues = this.calcDetailsValueInMonth(groupDaiCData, month, detailItem);
    countIn = countValues.countIn
    countOut = countValues.countOut
    countOutLive = countValues.countOutLive;
    countOutDead = countValues.countOutDead;
    return { countIn, countOut, countOutLive, countOutDead, inData: countValues.inData, outData: countValues.outData };
  }

  compareDates = (a: any, b: any) => {
    const dateA = new Date(a.sdate);
    const dateB = new Date(b.sdate);
    return dateA.getTime() - dateB.getTime();
  };

  removeDuplicates(arr: any[]) {
    const result: any[] = [];
    const duplicates = new Set();

    arr.forEach((obj, index) => {
      const objStr = JSON.stringify(obj);
      if (duplicates.has(objStr)) {
        return;
      } else {
        duplicates.add(objStr);
        result.push(arr[index]);
      }
    });

    return result;
  }

  getDataCK() {
    if (this.isValidDate) {
      let modalParam = new ModalParam();
      modalParam.description = MESSAGE_TEXT.CONFRIM_OVERRIDE;
      let icon = new IconModal();
      icon.iconName = "pi-question";
      icon.iconColor = "#0073BA";
      icon.isSmallSize = false;
      modalParam.icon = icon;
      let btnOk = new ButtonItem();
      btnOk.buttonText = COMMON_TEXT.YES;
      btnOk.buttonType = ButtonType.SECONDARY;
      btnOk.clickFunction = async () => {
        this.loadingService.isLoading.emit(true);
        await this.syncTableData(this.currentSelectedOffice?.jigno, this.currentSelectedOffice?.siteino);
       
      }
      let btnCancel = new ButtonItem();
      btnCancel.buttonText = COMMON_TEXT.NO;
      btnCancel.buttonType = ButtonType.SECONDARY;
      modalParam.buttonArray = [btnOk, btnCancel];
      this.modalService.open(DialogModalComponent, {
        header: COMMON_TEXT.CONFIRM,
        width: '33em',
        data: { modalParam },
      });
      this.isChangeDate = false;
    }
  }

  async enableEditMode() {
    if (this.isEdit) {
      this.errorHandleService.setFunctionTitle(FUNCTION_TITLE_TEXT.SAVE_DATA_FAIL);
      this.loadingService.isLoading.emit(true);
      this.isEdit = false;   
      let bodyData = this.getDataInsert();
      let bodyDataDetail = this.getDataDetailInsert();
      const changedProperties = findChangedProperties(this.originalbodyDataDetail,bodyDataDetail)
      await this.zaitakuService.insert(bodyData, true);
      await this.zaitakuService.insertDetail(bodyDataDetail, changedProperties);

      let dataTblUserInfo = this.mapUserInfoToInsert();
      await this.zaitakuService.insertDataTableUserInfo(dataTblUserInfo);
      this.loadingService.isLoading.emit(false);
    } else {
      this.isEdit = true;

      //Log
      this.saucerLogService.action({}, { action: this.logAction.VIEW_DIRECT_INPUT });
      const bodyDataDetail = this.getDataDetailInsert();
      this.originalbodyDataDetail = cloneDeep(bodyDataDetail);
    }
    this.searchParams.defaultValue = this.currentSelectedOffice?.name;
    this.searchParams = cloneDeep(this.searchParams);
    this.inputText = this.isEdit? BUTTON.SAVE : CK_REPORT_TEXT.DIRECT_INPUT;
    this.configTable();
  }

  resetInitialValue() {
    this.startDateInitial = cloneDeep(this.startDate);
    this.endDateInitial = cloneDeep(this.endDate);
    this.currentSelectedOfficeInitial = cloneDeep(this.currentSelectedOffice);
  }

  getDataInsert(): ZaitakuDTO {
    let bodyRequest = new ZaitakuDTO();
    bodyRequest.jigNo = this.currentSelectedOffice?.jigno;
    bodyRequest.siteiNo = this.currentSelectedOffice?.siteino;
    bodyRequest.officeNm = this.currentSelectedOffice?.officenm;
    bodyRequest.sDate = moment(this.startDate).format(DateFormat.DS_FULL_SHORT_DATE);
    bodyRequest.eDate = moment(this.endDate).format(DateFormat.DS_FULL_SHORT_DATE);
    bodyRequest.hasHospitalNearby = this.hasHospitalNearby;
    bodyRequest.autoSyncColumns = this.autoSyncColumns || DEFAULT_AUTO_SYNC_COLS.join(',');
    
    let dataInsert = cloneDeep(this.dataTable?.map((obj: any) => {
      const { hide, ...rest } = obj; 
      return rest;
    }));
    bodyRequest.dataTbl = JSON.stringify(dataInsert);
    return bodyRequest;
  }

  mapUserInfoToInsert() : ZaitakuUserInfoDTO {
    let bodyRequest = new ZaitakuUserInfoDTO();
    bodyRequest.jigNo = this.currentSelectedOffice?.jigno;
    bodyRequest.siteiNo = this.currentSelectedOffice?.siteino;
    bodyRequest.dataTblUserInfo = JSON.stringify(this.dataTblUserInfo);
    return bodyRequest;
  }

  getDataDetailInsert(): ZaitakuDetailDTO[] {
    let bodyRequestList: ZaitakuDetailDTO[] = [];
    this.dataTable.forEach((item: ZaitakuSyncItem) => {
      let bodyRequest = new ZaitakuDetailDTO();
      bodyRequest.jigNo = this.currentSelectedOffice?.jigno;
      bodyRequest.siteiNo = this.currentSelectedOffice?.siteino;
      bodyRequest.month = item.date.replace("/", "-") + "-01";
      bodyRequest.countflg = item.countflg != undefined? item.countflg : true;
      bodyRequest.hasSocialLicense = item.hasSocialLicense != undefined? item.hasSocialLicense : false;
      bodyRequest.hasAllRehabProfessionals = item.hasAllRehabProfessionals != undefined? item.hasAllRehabProfessionals : false;
      bodyRequest.isRehabQualified = item.isRehabQualified != undefined? item.isRehabQualified : false;
      bodyRequest.isRehab20Min = item.isRehab20Min != undefined? item.isRehab20Min : false;
      bodyRequest.isRehab3PerWeek = item.isRehab3PerWeek != undefined? item.isRehab3PerWeek : false;
      bodyRequest.admission = item.admission;
      bodyRequest.discharge = item.discharge;
      bodyRequest.serviceType = item.serviceType;
      bodyRequest.healthFacility = item.healthFacility?.toString();
      bodyRequest.physicalTherapists = item.physicalTherapists?.toString();
      bodyRequest.careHealthFacility = item.careHealthFacility?.toString();
      bodyRequest.coumselors = item.coumselors?.toString();
      bodyRequest.aspiration = item.aspiration;
      bodyRequest.feeding = item.feeding;
      bodyRequest.sameDayDischarge = item.sameDayDischarge;
      bodyRequest.sameDayAdmissionDischarge = item.sameDayAdmissionDischarge;
      bodyRequestList.push(bodyRequest);
    });
    return bodyRequestList;
  }

  async onFilterOutData(event: any) {
    this.errorHandleService.setFunctionTitle(FUNCTION_TITLE_TEXT.SCREEN_INIT_FAIL);
    let oldOffice: any = cloneDeep(this.currentSelectedOffice);
    this.currentSelectedOffice = event.length > 0 ? event[0] : {};
    await this.mapOfficeData(this.currentSelectedOffice)
    this.isValidMapDateTable = this.checkMapDateTable();
    this.isValidDate = this.isDateRangeWithin12Months();
    this.isChangeDate = false;
    if (this.isValidDate) {
      await this.getAutoSyncCells();
      this.configTable();
    }

    //Log
    const oldLog = {
      'officeCd': oldOffice?.officecd,
      'officeName': oldOffice?.name
    }
    const newLog = {
      'officeCd': this.currentSelectedOffice?.officecd,
      'officeName': this.currentSelectedOffice?.name
    }
		this.saucerLogService.action({
        content: JSON.stringify({
          old: oldLog,
          new: newLog
        })
      },
      { 
        action: this.logAction.SWITCHING_BUSINESS
      }
    );
  }

  async mapOfficeData(office: any) {
    this.loadingService.isLoading.emit(true)
    const syncedRs = await this.zaitakuService.getAll(true);
    if (syncedRs.statuscode === 200) { 
      this.zaitakuData = syncedRs.data;
      let zaitakuSelected =  this.zaitakuData.find(s => s.jigno == office.jigno && s.siteino == office.siteino);
      if(zaitakuSelected) {
        this.startDate = new Date( zaitakuSelected.sdate);
        this.endDateAt1stDay = new Date( zaitakuSelected.edate);
        this.endDate = new Date(new Date(new Date(this.endDateAt1stDay).setMonth(new Date(this.endDateAt1stDay).getMonth() + 1, 0)).setHours(0, 0, 0, 0));
        let tblData = JSON.parse(zaitakuSelected.datatbl) || [];
        tblData?.map((row: any) => {
          new Date(row.date) < this.startDate ? row.hide = true : row.hide = false;
        })
        this.dataTable = tblData;
        this.dataTable.forEach(item => {
          item.countflg = item.countflg ?? true;
          item.hasSocialLicense = item.hasSocialLicense ?? false;
          item.hasAllRehabProfessionals = item.hasAllRehabProfessionals ?? false;
          item.isRehabQualified = item.isRehabQualified ?? false;
          item.isRehab20Min = item.isRehab20Min ?? false;
          item.isRehab3PerWeek = item.isRehab3PerWeek ?? false;
        }); 
        
        this.hasHospitalNearby = zaitakuSelected.hashospitalnearby ?? false;
        this.bindingTable(this.dataTable.filter((s: any) => !s.hide));
      }
      else {
        this.dataTable = []
        this.hasHospitalNearby = false;
        this.bindingTable(this.dataTable);
      }
    }
    this.loadingService.isLoading.emit(false)
  }

  async changeStartDate(value: any) {
    if (!value) return;
    new Date(value) != this.startDate ? this.isChangeDate = true : this.isChangeDate = false
    this.startDate = value;
    this.isValidDate = this.isDateRangeWithin12Months();
    this.isValidMapDateTable = this.checkMapDateTable();

    if (this.isValidDate) {
      this.loadingService.isLoading.emit(true);
      await this.getAutoSyncCells();
      this.configTable();

      this.saucerLogService.action({
        content: CONTENT_LOG.START_MONTH + ": " + moment(this.startDate).format(DateFormat.YEAR_MONTH)
      }, { action: this.logAction.SHOW_CALENDAR_START });

      this.loadingService.isLoading.emit(false);
    }

  }

  async changeEndDate(value: any) {
    if (!value) return;
    this.endDateAt1stDay != new Date(value) ? this.isChangeDate = true : this.isChangeDate = false;
    this.endDateAt1stDay = value;
    this.endDate = new Date(new Date(new Date(value).setMonth(new Date(value).getMonth() + 1, 0)).setHours(0, 0, 0, 0));
    this.isValidDate = this.isDateRangeWithin12Months();
    this.isValidMapDateTable = this.checkMapDateTable();

    if (this.isValidDate) {
      this.loadingService.isLoading.emit(true);
      await this.getAutoSyncCells();
      this.configTable();

      this.saucerLogService.action({
        content: CONTENT_LOG.END_MONTH + ": " + moment(this.endDate).format(DateFormat.YEAR_MONTH)
      }, { action: this.logAction.SHOW_CALENDAR_END });

      this.loadingService.isLoading.emit(false);
    }
  }

  configTable() {
    this.configTableData = {
      config: {
        id: 'dashboard-1',
        caption: '',
        emptyMessage: COMMON_TEXT.NO_DATA,
        showIndex: false,
        showCheckbox: false,
        showPagination: false,
        hoverShowCheckbox: false,
        isResponsive: true,
        isSelectedRow: false,
        tableStyle: { 'margin-top': '4px', 'font-weight': 'unset !important' },
        inlineTableStyle: { 'table-layout': 'fixed', 'width': '100%' }
      },
      header: [
        {
          field: CK_REPORT_COLUMNCDS.COUNTFLG,
          title: CK_REPORT_COLUMNS.COUNTFLG,
          sortable: false,
          filterable: false,
          attribute: {
            header: { 'text-align': 'center', 'width': '90px' },
            row: {},
            rowClass: this.isEdit ? 'ck-row-center' : 'ck-row-center ck-disable-cell'
          },
          dataType: DataType.CHECKBOX,
          visible: true
        },
        {
          field: CK_REPORT_COLUMNCDS.DATE,
          title: CK_REPORT_COLUMNS.DATE,
          sortable: false,
          filterable: false,
          attribute: {
            header: { 'text-align': 'center', 'width': '90px' },
            row: {},
            rowClass: 'ck-row-center'
          },
          dataType: DataType.TEXT,
          visible: true
        },
        {
          field: CK_REPORT_COLUMNCDS.DAYINMONTH,
          title: CK_REPORT_COLUMNS.DAYINMONTH,
          sortable: false,
          filterable: false,
          attribute: {
            header: { 'width': '80px'},
            row: {},
            rowClass: 'ck-row-right'
          },
          dataType: DataType.TEXT,
          visible: true
        },
        {
          field: CK_REPORT_COLUMNCDS.CUMULATIVE_DAYS,
          title: CK_REPORT_COLUMNS.CUMULATIVE_DAYS,
          sortable: false,
          filterable: false,
          attribute: {
            header: { 'width': '90px'},
            row: {},
            rowClass: 'ck-row-right ck-row-text-overflow'
          },
          dataType: DataType.TEXT,
          visible: true
        },
        {
          field: CK_REPORT_COLUMNCDS.INMATES,
          title: CK_REPORT_COLUMNS.INMATES,
          sortable: false,
          filterable: false,
          attribute: {
            header: { 'width': '80px', 'background-color': '#ffff00', color: 'black' },
            row: {},
            rowClass: 'ck-row-right ck-row-text-overflow'
          },
          dataType: DataType.TEXT,
          visible: true
        },
        {
          field: CK_REPORT_COLUMNCDS.CUMULATIVE_NEW,
          title: CK_REPORT_COLUMNS.CUMULATIVE_NEW,
          sortable: false,
          filterable: false,
          attribute: {
            header: { 'width': '80px', 'background-color': '#ffff00', color: 'black' },
            row: {},
            rowClass: 'ck-row-right ck-row-text-overflow'
          },
          dataType: DataType.TEXT,
          visible: true
        },
        {
          field: CK_REPORT_COLUMNCDS.NEW_LEAVERS,
          title: CK_REPORT_COLUMNS.NEW_LEAVERS,
          sortable: false,
          filterable: false,
          attribute: {
            header: { 'width': '85px', 'background-color': '#ffff00', color: 'black' },
            row: {},
            rowClass: 'ck-row-right ck-row-text-overflow'
          },
          dataType: DataType.TEXT,
          visible: true
        },
        {
          field: CK_REPORT_COLUMNCDS.PEOPLE_LEAVING,
          title: CK_REPORT_COLUMNS.PEOPLE_LEAVING,
          sortable: false,
          filterable: false,
          attribute: {
            header: { 'width': '85px', 'background-color': '#ffff00', color: 'black' },
            row: {},
            rowClass: 'ck-row-right ck-row-text-overflow'
          },
          dataType: DataType.TEXT,
          visible: true
        },
        {
          field: CK_REPORT_COLUMNCDS.DEATH,
          title: CK_REPORT_COLUMNS.DEATH,
          sortable: false,
          filterable: false,
          attribute: {
            header: { 'width': '90px', 'background-color': '#ffff00', color: 'black' },
            row: {},
            rowClass: 'ck-row-right ck-row-text-overflow'
          },
          dataType: DataType.TEXT,
          visible: true
        },
        {
          field: CK_REPORT_COLUMNCDS.ISREHABQUALIFIED,
          title: CK_REPORT_COLUMNS.ISREHABQUALIFIED,
          sortable: false,
          filterable: false,
          attribute: {
            header: { 'width': '100px', 'background-color': '#ceeece', color: 'black' },
            row: {},
            rowClass: this.isEdit ? 'ck-row-center' : 'ck-row-center ck-disable-cell'
          },
          dataType: DataType.CHECKBOX,
          visible: true
        },
        {
          field: CK_REPORT_COLUMNCDS.ISREHAB20MIN,
          title: CK_REPORT_COLUMNS.ISREHAB20MIN,
          sortable: false,
          filterable: false,
          attribute: {
            header: { 'width': '100px', 'background-color': '#ceeece', color: 'black' },
            row: {},
            rowClass: this.isEdit ? 'ck-row-center' : 'ck-row-center ck-disable-cell'
          },
          dataType: DataType.CHECKBOX,
          visible: true
        },
        {
          field: CK_REPORT_COLUMNCDS.ISREHAB3PERWEEK,
          title: CK_REPORT_COLUMNS.ISREHAB3PERWEEK,
          sortable: false,
          filterable: false,
          attribute: {
            header: { 'width': '100px', 'background-color': '#ceeece', color: 'black' },
            row: {},
            rowClass: this.isEdit ? 'ck-row-center' : 'ck-row-center ck-disable-cell'
          },
          dataType: DataType.CHECKBOX,
          visible: true
        },
        {
          field: CK_REPORT_COLUMNCDS.ADMISSION,
          title: CK_REPORT_COLUMNS.ADMISSION,
          sortable: false,
          filterable: false,
          attribute: {
            header: { 'width': '100px', 'background-color': '#ceeece', color: 'black' },
            row: {},
            rowClass: this.isEdit ? 'ck-row-right ck-row-text-overflow' : 'ck-disable-cell ck-row-right ck-row-text-overflow'
          },
          dataType: DataType.INPUT,
          visible: true
        },
        {
          field: CK_REPORT_COLUMNCDS.DISCHARGE,
          title: CK_REPORT_COLUMNS.DISCHARGE,
          sortable: false,
          filterable: false,
          attribute: {
            header: { 'width': '100px', 'background-color': '#ceeece', color: 'black' },
            row: {},
            rowClass: this.isEdit ? 'ck-row-right ck-row-text-overflow' : 'ck-disable-cell ck-row-right ck-row-text-overflow'
          },
          dataType: DataType.INPUT,
          visible: true
        },
        {
          field: CK_REPORT_COLUMNCDS.SERVICE_TYPE,
          title: CK_REPORT_COLUMNS.SERVICE_TYPE,
          sortable: false,
          filterable: false,
          attribute: {
            header: { 'width': '90px', 'background-color': '#ceeece', color: 'black' },
            row: {},
            rowClass: this.isEdit ? 'ck-row-right ck-row-text-overflow' : 'ck-disable-cell ck-row-right ck-row-text-overflow',
            dataCombobox: SERVICE_TYPE
          },
          dataType: DataType.COMBOBOX,
          visible: true
        },
        {
          field: CK_REPORT_COLUMNCDS.HEALTH_FACILITY,
          title: CK_REPORT_COLUMNS.HEALTH_FACILITY,
          sortable: false,
          filterable: false,
          attribute: {
            header: { 'width': '90px', 'background-color': '#ceeece', color: 'black' },
            row: {},
            rowClass: this.isEdit ? 'ck-row-right ck-row-text-overflow' : 'ck-disable-cell ck-row-right ck-row-text-overflow'
          },
          dataType: DataType.INPUT,
          visible: true
        },
        {
          field: CK_REPORT_COLUMNCDS.PHYSICAL_THERAPISTS,
          title: CK_REPORT_COLUMNS.PHYSICAL_THERAPISTS,
          sortable: false,
          filterable: false,
          attribute: {
            header: { 'width': '90px', 'background-color': '#ceeece', color: 'black' },
            row: {},
            rowClass: this.isEdit ? 'ck-row-right ck-row-text-overflow' : 'ck-disable-cell ck-row-right ck-row-text-overflow'
          },
          dataType: DataType.INPUT,
          visible: true
        },
        {
          field: CK_REPORT_COLUMNCDS.HAS_ALL_REHAB_PROFESSIONALS,
          title: CK_REPORT_COLUMNS.HAS_ALL_REHAB_PROFESSIONALS,
          sortable: false,
          filterable: false,
          attribute: {
            header: { 'width': '100px', 'background-color': '#ceeece', color: 'black' },
            row: {},
            rowClass: this.isEdit ? 'ck-row-center' : 'ck-row-center ck-disable-cell'
          },
          dataType: DataType.CHECKBOX,
          visible: true
        },
        {
          field: CK_REPORT_COLUMNCDS.CARE_HEALTH_FACILITY,
          title: CK_REPORT_COLUMNS.CARE_HEALTH_FACILITY,
          sortable: false,
          filterable: false,
          attribute: {
            header: { 'width': '90px', 'background-color': '#ceeece', color: 'black' },
            row: {},
            rowClass: this.isEdit ? 'ck-row-right ck-row-text-overflow' : 'ck-disable-cell ck-row-right ck-row-text-overflow'
          },
          dataType: DataType.INPUT,
          visible: true
        },
        {
          field: CK_REPORT_COLUMNCDS.COUMSELORS,
          title: CK_REPORT_COLUMNS.COUMSELORS,
          sortable: false,
          filterable: false,
          attribute: {
            header: { 'width': '90px', 'background-color': '#ceeece', color: 'black' },
            row: {},
            rowClass: this.isEdit ? 'ck-row-right ck-row-text-overflow' : 'ck-disable-cell ck-row-right ck-row-text-overflow'
          },
          dataType: DataType.INPUT,
          visible: true
        },
        {
          field: CK_REPORT_COLUMNCDS.HASSOCIALLICENSE,
          title: CK_REPORT_COLUMNS.HASSOCIALLICENSE,
          sortable: false,
          filterable: false,
          attribute: {
            header: { 'width': '90px', 'background-color': '#ceeece', color: 'black' },
            row: {},
            rowClass: this.isEdit ? 'ck-row-center' : 'ck-row-center ck-disable-cell'
          },
          dataType: DataType.CHECKBOX,
          visible: true
        },
        {
          field: CK_REPORT_COLUMNCDS.ASPIRATION,
          title: CK_REPORT_COLUMNS.ASPIRATION,
          sortable: false,
          filterable: false,
          attribute: {
            header: { 'width': '90px', 'background-color': '#ceeece', color: 'black' },
            row: {},
            rowClass: this.isEdit ? 'ck-row-right ck-row-text-overflow' : 'ck-disable-cell ck-row-right ck-row-text-overflow'
          },
          dataType: DataType.INPUT,
          visible: true
        },
        {
          field: CK_REPORT_COLUMNCDS.FEEDING,
          title: CK_REPORT_COLUMNS.FEEDING,
          sortable: false,
          filterable: false,
          attribute: {
            header: { 'width': '90px', 'background-color': '#ceeece', color: 'black' },
            row: {},
            rowClass: this.isEdit ? 'ck-row-right ck-row-text-overflow' : 'ck-disable-cell ck-row-right ck-row-text-overflow'
          },
          dataType: DataType.INPUT,
          visible: true
        }
      ],
      body: this.dataTable?.filter((s: any)=> !s.hide)
    };

    this.handleCaseSameDayDischarge();
    this.mapAutoSyncCols()
  }
  
  //併設病院から退院日同日入所 & 併設病院への入所日同日退所
  handleCaseSameDayDischarge() {
    if (this.hasHospitalNearby) {
      let newObject = {
        field: CK_REPORT_COLUMNCDS.SAME_DAY_DISCHARGE,
        title: CK_REPORT_COLUMNS.SAME_DAY_DISCHARGE,
        sortable: false,
        filterable: false,
        attribute: {
          header: { 'width': '100px', 'background-color': '#ceeece', color: 'black' },
          row: {},
          rowClass: this.isEdit ? 'ck-row-right ck-row-text-overflow' : 'ck-disable-cell ck-row-right ck-row-text-overflow'
        },
        dataType: DataType.INPUT,
        visible: true
      };

      let newObject2 = {
        field: CK_REPORT_COLUMNCDS.SAME_DAY_ADMISSION_DISCHARGE,
        title: CK_REPORT_COLUMNS.SAME_DAY_ADMISSION_DISCHARGE,
        sortable: false,
        filterable: false,
        attribute: {
          header: { 'width': '100px', 'background-color': '#ceeece', color: 'black' },
          row: {},
          rowClass: this.isEdit ? 'ck-row-right ck-row-text-overflow' : 'ck-disable-cell ck-row-right ck-row-text-overflow'
        },
        dataType: DataType.INPUT,
        visible: true
      };

      let headers = this.configTableData.header;
      if (!headers.some(item => item.field == CK_REPORT_COLUMNCDS.SAME_DAY_DISCHARGE)) {
        this.configTableData.header = [...headers.slice(0, 6), newObject, ...headers.slice(6)];
      }
      if (!headers.some(item => item.field == CK_REPORT_COLUMNCDS.SAME_DAY_ADMISSION_DISCHARGE)) {
        this.configTableData.header = [...this.configTableData.header.slice(0, 8), newObject2, ...this.configTableData.header.slice(8)];
      }
      
      this.dataTable = this.dataTable.map(obj => {
        if (!(CK_REPORT_COLUMNCDS.SAME_DAY_DISCHARGE in obj)) {
          obj = { ...obj, [CK_REPORT_COLUMNCDS.SAME_DAY_DISCHARGE]: 0 };
        }
        if (!(CK_REPORT_COLUMNCDS.SAME_DAY_ADMISSION_DISCHARGE in obj)) {
          obj = { ...obj, [CK_REPORT_COLUMNCDS.SAME_DAY_ADMISSION_DISCHARGE]: 0 };
        }
        return obj;
      });

      this.configTableData.body = this.dataTable?.filter((s: any) => !s.hide).map(obj => {
        const newObj = { ...obj };
        newObj.inmates = newObj.inmates - newObj.sameDayDischarge - newObj.sameDayAdmissionDischarge;
        return newObj;
      });

    } else {

      this.dataTable = this.dataTable.map(obj => {
        const newObj = { ...obj };
        delete newObj[CK_REPORT_COLUMNCDS.SAME_DAY_DISCHARGE];
        delete newObj[CK_REPORT_COLUMNCDS.SAME_DAY_ADMISSION_DISCHARGE];
        return newObj;
      });
      
      this.configTableData.header = this.configTableData.header.filter(item => item.field != CK_REPORT_COLUMNCDS.SAME_DAY_DISCHARGE && item.field != CK_REPORT_COLUMNCDS.SAME_DAY_ADMISSION_DISCHARGE);
      this.configTableData.body = this.dataTable?.filter((s: any) => !s.hide);
    }
  }

  mapAutoSyncCols() {
    this.configTableData.body.map(item => {
      item.disableInputCols = this.autoSyncCells.filter(x => x.month.replace('-','/') == item.date).map(x => x.columncd);
      return item;
    })
    this.configTableData.header.map(item => {
      let indexCell = this.autoSyncCells.findIndex(x => x.columncd == item.field)
      if (item.dataType == DataType.INPUT && indexCell != -1) {
        item.attribute.header = {
          ...item.attribute.header,
          'background-color': '#ffff00',
          'color': 'black'
        };
      }
      return item;
    })
  }



  cellFocusOut(data: any) {
    let value = convertJapaneseNumber(data?.event?.value);
    value = this.cellValueChange(value, data.row)
    if (data.toString() == '') return;
    this.dataTable?.forEach((item: any) => {
      new Date(item.date) < this.startDate ? item.hide = true : item.hide = false;
      if (item.date == data.value.date) {
        value = this.formatInput(value);
        if(data.row === "physicalTherapists") {
          if(item[data.row] != parseFloat(value)) {
            item["coumselors"] = parseFloat(value) || 0;
          }
          item[data.row] = parseFloat(value) || 0;
        }
        else 
          item[data.row] = parseFloat(value) || 0;
      }
    });
    this.bindingTable(this.dataTable.filter((s: any) => !s.hide));
  }
  cellDropdownChange(data:any){
    this.dataTable?.forEach((item: any) => {
      new Date(item.date) < this.startDate ? item.hide = true : item.hide = false;
      if (item.date == data.value.date) {
        item[data.row] = parseFloat(data.value[data.row]) || 0;
      }
    });
  }

  formatInput(input: string): string {
    //for japanese keyboard, format not working correctly
    const digitsOnly = input.replace(/\D/g, '');
    if (digitsOnly.length > 4) {
      const formatted = `${digitsOnly.slice(0, 4)}.${digitsOnly.charAt(4)}`;
      return formatted;
    }
    else {
      //input is not more than 5 characters => return default
      return input;
    }
  
    return digitsOnly;
  }

  cellValueChange(data: any, row: any) {
    let format = this.setFormatByColumn(row)
    let formatArr = format.split('.') || []
    if(format) {
      if(data?.toString()?.length >= format.length - 1 && formatArr.length > 1)
        data = this.formatDataValue(format,data)
      else {
        if(!formatArr[1])
        {
          data = data?.replace(/\D/g, '');
          data = data?.slice(0, format.length)
        }
        else {
          data = data?.replace(/[^0-9.]/g, '');
          data = this.formatDataValue(format,data)
        }
      } 
    }
    return data;
  }

  formatDataValue(format: string, value: number) {
    if(!value) return value
    let numericString = value.toString()?.replace(/[^\d.]/g, '');
    let hasDecimal = numericString.includes('.');
    let formatArr = format?.split('.') || []
    if (numericString.length >= format.length - 1 && !hasDecimal) {
      let integerPart = numericString.slice(0, formatArr[0]?.length || 0);
      let decimalPart = numericString.slice(formatArr[0]?.length, (formatArr[0]?.length + formatArr[1]?.length) || 0 );
      return integerPart + '.' + decimalPart;
    } else if (hasDecimal) {
      let parts = numericString.split('.');
      if (parts[1].length > 1) {
        return parts[0] + '.' + parts[1].slice(0, 1);
      } else {
        return parts[0] + '.' + parts[1];
      }
    }
    return numericString;
  };

  cellFocusIn(data: any) {
    data.event.maxLength = this.setFormatByColumn(data.row)?.length
  }

  checkFieldEvent(event: any) {
    if (event && event?.data?.length > 0) {
      this.dataTable = this.mapItemsByDateOptimized(this.dataTable, event.data);
    }
  }

  mapItemsByDateOptimized(array1: ZaitakuSyncItem[], array2: ZaitakuSyncItem[]): ZaitakuSyncItem[] {
    // Chuyển array2 thành một Map để tối ưu hóa việc tìm kiếm theo `date`
    const mapByDate = new Map<string, ZaitakuSyncItem>();
    array2.forEach(item => mapByDate.set(item.date, item));

    // Áp dụng map cho array1 và tìm kiếm nhanh trong mapByDate
    return array1.map(item1 => {
        const matchingItem = mapByDate.get(item1.date);
        if (matchingItem) {
          const { inmates, ...otherProps } = matchingItem;
          return { ...item1, ...otherProps };
        }
        return item1;
    });
  }

  setFormatByColumn(row: any) {
    let format: any = '';
    switch (row) {
      case 'admission':
      case 'discharge':
      case 'sameDayDischarge':
      case 'sameDayAdmissionDischarge':
          format ='##'
        break;
      case 'serviceType':
          format = '#'
        break;
      case 'healthFacility':
      case 'physicalTherapists':
      case 'careHealthFacility':
      case 'coumselors':
          format = '####.#'
        break;
      case 'aspiration':
      case 'feeding':
          format = '###'
        break;
    }
    return format
  }

  checkMapDateTable(): boolean {
    if (this.dataTable.length > 0) {
      let startDateTbl = new Date(this.dataTable[0].date);
      let endDateTbl = new Date(this.dataTable[this.dataTable.length - 1].date);

      return (
        startDateTbl.getFullYear() === this.startDate.getFullYear() &&
        startDateTbl.getMonth() === this.startDate.getMonth() &&
        endDateTbl.getFullYear() === this.endDate.getFullYear() &&
        endDateTbl.getMonth() === this.endDate.getMonth() &&
        isEqual(this.currentSelectedOffice, this.currentSelectedOfficeInitial)
      );
    }
    return true;
  }

  isDateRangeWithin12Months() {
    const startDate = new Date(this.startDate);
    const endDate = new Date(this.endDate);

    if (startDate > endDate) return false;
    const diffMonths = (endDate.getFullYear() - startDate.getFullYear()) * 12 + (endDate.getMonth() - startDate.getMonth());

    if (diffMonths > 11) {
      return false;
    } else {
      return true;
    }
  }

  bindingTable = (data: any[] = []) => (
    this.configTableData.body = cloneDeep(data), 
    this.handleCaseSameDayDischarge(),
    this.configTableData = cloneDeep(this.configTableData)
  );

  setStartTimeToMidnight = (date: Date) => (date.setHours(0, 0, 0, 0), date);

  setEndTimeToJustBeforeMidnight = (date: Date) => (date.setHours(23, 59, 59, 999), date);

  getDaysBetweenIncludeTime = (start: Date, end: Date): number => (end.getTime() - start.getTime()) / (1000 * 3600 * 24) + 1;

  getDaysBetween(start: Date, end: Date) {
    start = this.setStartTimeToMidnight(start);
    end = this.setEndTimeToJustBeforeMidnight(end);
    return Math.ceil((end.getTime() - start.getTime()) / 86400000);
  }

  //#region 
  async openDlgSetting() {
    this.modalService.open(ZaitakuSettingDialogComponent, {
      data: {
        dialogType: 1,
        listOffices: this.listOffices,
        initialStartDate: this.datePipe.transform(this.startDate, 'yyyy-MM') || "",
        initialOffice: this.currentSelectedOffice
      }
    }).onClose.subscribe(async () => {
        this.loadingService.isLoading.emit(true);
        await this.getAutoSyncCells();
        this.configTable();
        this.loadingService.isLoading.emit(false);
      
    })
  }
  //#endregion


  //#region 同一敷地内又は隣接する敷地内に病院又は診療所あり
  onCheckboxHospitalNearby() {
    this.hasHospitalNearby = !this.hasHospitalNearby;
    this.configTable();
  }

  formatDate(input: string): string {
    return input.replace('T', ' ').substring(0, 16);
  }
  //#endregion

  async initZaitakuUserInfo() {
    if (!this.currentSelectedOffice?.jigno || !this.currentSelectedOffice?.siteino) return;
    let res = await this.zaitakuService.getDetailInfoByCondition(this.currentSelectedOffice.jigno, this.currentSelectedOffice.siteino);
    if (res && res.statuscode == 200 && res.data && res.data[0]?.detailinfo) {
      this.dataTblUserInfo = res.data[0].detailinfo ? JSON.parse(res.data[0].detailinfo): [];
    }
  }
}
