import { HttpRequest } from '@angular/common/http';
import { InjectionToken } from '@angular/core';
import { environment } from '../../environments/environment';
import { API_APP } from './app.config';

export const interceptorExceptURL: string[] = [
    environment.PIVOT_CLIENT_API_URL + API_APP.AUTH.LOGIN,
    '/assets/i18n/'
];

export const AUTH_TOKEN_INTERCEPTOR_FILTER =
    new InjectionToken<(req: HttpRequest<any>) => boolean>('Interceptor Filter');
