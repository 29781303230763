<div class="filter-table-modal"
  (closeModalHandler)="closeFormModal()">
  <div class="filter-table-body" modal-body>
    <div class="controls">
      <pivot-search-bar [searchParams]="searchParams" (onFilterData)="onFilterOutData($event)"></pivot-search-bar>
    </div>
    <pivot-table class="table" [data]="tableData" [isLoading]="loadingTable" 
    [selectedRows]="selectedDatas" (checkRows)="checkRowsTable($event)"></pivot-table>

  </div>
  <div class="filter-modal-bottom" modal-bottom>
    <pivot-text-button (btnClick)="cancel()" [buttonType]="buttonType.SECONDARY" [buttonText]="BUTTON.CANCEL">
    </pivot-text-button>
    <pivot-text-button (btnClick)="save()" [buttonType]="buttonType.SECONDARY"
      [buttonText]="BUTTON.SELECT"></pivot-text-button>
  </div>
</div>