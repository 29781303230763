import { EventEmitter, Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable, throwError, lastValueFrom } from 'rxjs';
import { map } from 'rxjs/operators';
import { Router } from '@angular/router';
import { LocalStorageKey, LocalStorageHelper } from '../_helper/local-storage.helper';
import { environment } from '../../environments/environment';
import { JwtHelperService } from '@auth0/angular-jwt';
import { cloneDeep, isEmpty } from 'lodash';
import { API_APP } from '../config/app.config';
import { RoleService } from './modules/role.service';
import { StaffService } from './modules/staff.service';
import { ProcessLoadingService } from '../services/loading.service';
import {checkIfUserIsSupporterOrAdmin} from '../_helper/helper';
import {SharedDataService} from './share-data.service';
import { ClearProcessService } from './clear-process.service';
import { LoginInfoModel } from '../models/login-info.model';

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
    constructor(
        private http: HttpClient,
        private router: Router,
        private staffService: StaffService,
        private roleService: RoleService,
        private processLoadingService: ProcessLoadingService,
        private sharedDataService: SharedDataService,
        private clearProcessService: ClearProcessService
    ) {
        const datalocal = localStorage.getItem(LocalStorageKey.CURRENT_USER) || sessionStorage.getItem(LocalStorageKey.CURRENT_USER);
        this.currentUserSubject = new BehaviorSubject<any>(JSON.parse(datalocal || '{}'));
        this.currentUser = this.currentUserSubject.asObservable();

        this.userIdSubject = new BehaviorSubject<any>(null);
        this.userId = this.userIdSubject.asObservable();

        const authCorpListLocal = localStorage.getItem(LocalStorageKey.AUTH_CORP_LIST) || sessionStorage.getItem(LocalStorageKey.AUTH_CORP_LIST);
        this.authCorpListSubject = new BehaviorSubject<any>(JSON.parse(authCorpListLocal || '[]'));
        this.authCorpList = this.authCorpListSubject.asObservable();

        const companyIdLocal = localStorage.getItem(LocalStorageKey.COMPANYID) || sessionStorage.getItem(LocalStorageKey.COMPANYID);
        this.companyIdSubject = new BehaviorSubject<any>(companyIdLocal);
        this.companyId = this.companyIdSubject.asObservable();

        const corpTemplateSettingLocal = localStorage.getItem(LocalStorageKey.CORP_TEMPLATE_SETTING) || sessionStorage.getItem(LocalStorageKey.CORP_TEMPLATE_SETTING);
        this.corpTemplateSettingSubject = new BehaviorSubject<any>((corpTemplateSettingLocal != null && corpTemplateSettingLocal != undefined && corpTemplateSettingLocal != "undefined")? JSON.parse(corpTemplateSettingLocal || '{}') : null);
        this.corpTemplateSetting = this.corpTemplateSettingSubject.asObservable();
    


    }

    public get currentUserValue(): any {
        if (!isEmpty(this.currentUserSubject.value)) {
            return this.currentUserSubject.value;
        }

        return this.getData(LocalStorageKey.CURRENT_USER);
    }

    public get currentUserRole(): any {
        return this.getData(LocalStorageKey.CURRENT_USER_ROLE);
    }

    public get userIdValue(): any {
        return this.userIdSubject.value;
    }
    
    public currentUserSubject: BehaviorSubject<any>;
    public currentUser: Observable<any>;

    public userIdSubject: BehaviorSubject<any>;
    public userId: Observable<any>;

    public authCorpListSubject: BehaviorSubject<any>;
    public authCorpList: Observable<any>;

    public companyIdSubject: BehaviorSubject<any>;
    public companyId: Observable<any>;

    public corpTemplateSettingSubject: BehaviorSubject<any>;
    public corpTemplateSetting: Observable<any>;


    public isPageNotFound = new EventEmitter<any>();

    public isAllowAllAfterAPICall: boolean = true;

    login(email: string, password: string, customParams? : any | {isLoginConsole: boolean}): any {
        this.isAllowAllAfterAPICall = true;
        let param = {
            email,
            password
        };
        if (customParams && customParams.isLoginConsole) {
            param = Object.assign(param, {'type' : 'CONSOLE'});
        }
        return this.http.post<any>(environment.PIVOT_CLIENT_API_URL + API_APP.AUTH.LOGIN
            , param
            , {
                headers: new HttpHeaders().set('content-Type', 'application/json')
            }
        )
            .pipe(map(data => {
                const user = data.data;
                if (user && user.token && user.token !== '') {
                    localStorage.setItem(LocalStorageKey.CURRENT_USER, JSON.stringify(user));
                    localStorage.setItem(LocalStorageKey.USERID, user?.staffid);
                    this.addLoginSession(user);
                    this.setCompanyId(user?.companyid);
                    this.setCorpTemplateSetting(user?.templatesettingcorp);
                    if (user?.authcorplist) {
                        this.setAuthCorpList(user?.authcorplist);
                    }
                    return data;
                } else {
                    return data.data || null;
                }
            }));
    }

    addLoginSession(user:any){
        this.currentUserSubject.next(user);
        sessionStorage.setItem(LocalStorageKey.CURRENT_USER, JSON.stringify(user));
    }

    removeLoginSession(){
        this.currentUserSubject.next(null);
        sessionStorage.removeItem(LocalStorageKey.CURRENT_USER);
    }

    logout(): void {
        this.removeAccessToken().subscribe();
        this.isAllowAllAfterAPICall = false;

        let theme = cloneDeep(localStorage.getItem(LocalStorageKey.THEME));
        LocalStorageHelper.clearAll();
        this.removeLoginSession();
        this.sharedDataService.clearStoreData();
        if (theme) localStorage.setItem(LocalStorageKey.THEME, theme);
        this.currentUserSubject.next(null);
        this.userIdSubject.next(null);
        this.clearProcessService.clearIntervalIdList();
        // Clear all current interval process
        this.clearProcessService.clearTargetInterval([], true);

        this.router.navigate(['/auth']);

        this.processLoadingService.isLoading.emit(false);
    }

    isLoggedIn(): boolean {
        // check if token is expired
        const currentUser = JSON.parse(localStorage.getItem(LocalStorageKey.CURRENT_USER) || '{}');
        const currentUserSessionStorage = sessionStorage.getItem(LocalStorageKey.CURRENT_USER) ? true : false;
        if (!currentUser || !currentUser.token || !currentUserSessionStorage) { return false; }

        const token = currentUser.token;
        if (token) {
            const helper = new JwtHelperService();
            const expired = helper.isTokenExpired(token);
            if (expired) {
                let theme = cloneDeep(localStorage.getItem(LocalStorageKey.THEME));
                LocalStorageHelper.clearAll();
                this.removeLoginSession();
                if (theme) localStorage.setItem(LocalStorageKey.THEME, theme);
                this.currentUserSubject.next(null);
                this.userIdSubject.next(null);
            }
            return true;
        }
        return false;
    }

    isSelectedCorp(): boolean {
        const currentUser = JSON.parse(localStorage.getItem(LocalStorageKey.CURRENT_USER) || '{}');
        const currentUserSessionStorage = sessionStorage.getItem(LocalStorageKey.CURRENT_USER) ? true : false;
        if (!currentUser || !currentUser.token || !currentUserSessionStorage || currentUser.companyid === -1 || !currentUser.companyid) { return false; }
        return true;
    }

    getCurrentUser(): any {
        if (!this.isLoggedIn()) {
            this.currentUserSubject.next(null);
        } else {
            const currentUser = JSON.parse(localStorage.getItem(LocalStorageKey.CURRENT_USER) || '{}');
            this.currentUserSubject.next(currentUser);
        }
    }

    setCurrentUser(information: any): any {
        // this.currentUserSubject.value.information = information
        this.currentUserSubject.next(information);
        localStorage.setItem(LocalStorageKey.CURRENT_USER, JSON.stringify(information));
    }

    setUserId(userId: number): void {
        this.userIdSubject.next(userId);
    }

    public get authCorpListValue(): any {
        if (!isEmpty(this.authCorpListSubject.value)) {
            return this.authCorpListSubject.value;
        }

        return this.getData(LocalStorageKey.AUTH_CORP_LIST);
    }

    setAuthCorpList(orgLst: any[]): void {
        this.authCorpListSubject.next(orgLst);
        localStorage.setItem(LocalStorageKey.AUTH_CORP_LIST, JSON.stringify(orgLst));
    }

    setCorpTemplateSetting(corp: any): void {
        this.corpTemplateSettingSubject.next(corp);
        localStorage.setItem(LocalStorageKey.CORP_TEMPLATE_SETTING, JSON.stringify(corp));
    }

    public get companyIdValue(): any {
        if (!isEmpty(this.companyIdSubject.value)) {
            return this.companyIdSubject.value;
        }

        return this.getData(LocalStorageKey.COMPANYID);
    }

    setCompanyId(companyId: number): void {
        this.companyIdSubject.next(companyId);
        localStorage.setItem(LocalStorageKey.COMPANYID, String(companyId));
    }

    getData(key: string): any {
        const data = localStorage.getItem(key);
        if (!data) {
            return null;
        }

        return JSON.parse(data);
    }

    setData(information: any, key: string): void {
        localStorage.setItem(key, JSON.stringify(information));
    }

    requestAccessToken(inpRefreshToken?: string, headerAuthorizationToken?: string): Observable<any> {
        const user = this.currentUserValue;
        const refreshToken = inpRefreshToken || user.refreshtoken;

        if (refreshToken) {
            if (headerAuthorizationToken) {
                return this.http.post<any>(environment.PIVOT_CLIENT_API_URL + API_APP.AUTH.REFRESH_TOKEN,
                    { refreshToken },
                    {
                        headers: new HttpHeaders()
                        .set('content-Type', 'application/json')
                        .set('Authorization', headerAuthorizationToken)
                    }
                );
            } else {
                return this.http.post<any>(environment.PIVOT_CLIENT_API_URL + API_APP.AUTH.REFRESH_TOKEN, {
                    refreshToken,
                });
            }
        } else {
            return throwError(() => new Error('Refresh token not found'));
        }
    }

    removeAccessToken(): Observable<any> {
        return this.http.post<any>(environment.PIVOT_CLIENT_API_URL + API_APP.AUTH.LOGOUT, {})
            .pipe(map(data => {
                return data;
            }));
    }

    async loginViaToken(accessToken: string, refreshToken: string,
            customParams? : any | {
                module: string,
                companyId: any,
                istemplateSetting: boolean,
            }): Promise<any> {
        let customHeader = new HttpHeaders()
            .set('content-Type', 'application/json')
            .set('Authorization', accessToken)
            .set('refreshtoken', refreshToken)
            .set('module', customParams?.module || 'PASS');

        if (customParams?.companyid) {
            customHeader = customHeader.set('companyId', `${customParams.companyid}`);
        }
        if(customParams?.istemplateSetting){
          customHeader = customHeader.set('isconsole', `${customParams.istemplateSetting}`);
        }
        return await lastValueFrom(this.http.post<any>(environment.PIVOT_CLIENT_API_URL + API_APP.AUTH.LOGIN_BY_TOKEN
            , {}
            , {
                headers: customHeader
            }
        )
            .pipe(map(data => {
                const user = data.data;
                if (user && user.token !== '') {
                    localStorage.setItem(LocalStorageKey.CURRENT_USER, JSON.stringify(user));
                    localStorage.setItem(LocalStorageKey.USERID, user?.staffid);
                    this.addLoginSession(user);
                    this.setCompanyId(user?.companyid);
                    this.setCorpTemplateSetting(user?.templatesettingcorp);
                    if (user?.authcorplist) {
                        this.setAuthCorpList(user?.authcorplist);
                        let loginInfo = new LoginInfoModel();
                        loginInfo.organizationid = user.authcorplist[0]?.organizationid;
                        loginInfo.organizationname = user.authcorplist[0]?.organizationname ?? "";
                        loginInfo.companyid = user.authcorplist[0]?.companyid;
                        loginInfo.custid = user.companylist ? user.companylist[0]?.custid : "";
                        LocalStorageHelper.setLoginInfo(loginInfo);
                    }
                    return data;
                } else {
                    return null;
                }
            }))
        );
    }

    isCurrentUser(token: string, currentUserId: any): boolean {
        const jwtHelper = new JwtHelperService();
        const decodeToken = jwtHelper.decodeToken(token);
        if (!decodeToken || !decodeToken.userId) {
            throw new Error('Invalid token!');
        }
        return Number(currentUserId) === Number(decodeToken.userId);
    }

    async processAfterCallLoginApi(data: any) {
        let res = {statuscode: 0, message: ""};

        switch (data.statuscode) {
            case 200:
                let account = data.data;
                if (!account || account === "Unauthorized") {
                    res.statuscode = 401;
                    res.message = "Unauthorized";
                    return res;
                }

                const allStaffTask = await this.staffService.getAll(this.currentUserValue.issupport);
                const userRolesTask = await this.roleService.getByStaff();
                const [allStaff, userRoles] = await Promise.all([allStaffTask, userRolesTask]);

                if (allStaff.statuscode === 200) {
                    const staffs = allStaff.data || [];
                    this.setData(staffs, LocalStorageKey.ALL_STAFF);
                }

                if (userRoles.statuscode === 200) {
                    const roles = userRoles.data || [];
                    this.setData(roles, LocalStorageKey.CURRENT_USER_ROLE);
                }

                this.setCurrentUser(account);
                this.setUserId(account?.staffid);
                res.statuscode = 200;
                res.message = "SUCCESSED";
                return res;
            
            case 401:
            default:
                res.statuscode = 401;
                res.message = "Unauthorized";
                return res;
        }
    }

    async isAdminOrSupporter() {
         return checkIfUserIsSupporterOrAdmin(this.currentUserValue);
    }
}
