<div class="role-setting" id="pccheck">
    <div class="role-setting-title">{{MENU_NAME.SYSTEM_SETTING}}</div>
    <div class="role-setting-container">
        <div class="tabs">
            <input type="radio" name="tab-btn" id="tab-btn-1" [checked]="currentPage === systemSettingPage.ROLE_SETTING" (change)="changeTab(systemSettingPage.ROLE_SETTING)">
            <label id="content1" saucerLogger [logMessage]="roleSettingLog" for="tab-btn-1" class="tabs-border">{{SYSTEM_SETTING.ROLE_SETTING_TAB}}</label>
            <input type="radio" name="tab-btn" id="tab-btn-2" [checked]="currentPage === systemSettingPage.CORP_SETTING" (change)="changeTab(systemSettingPage.CORP_SETTING)">
            <label id="content2" saucerLogger [logMessage]="corpSettingLog" for="tab-btn-2" class="tabs-border">{{SYSTEM_SETTING.CORP_SETTING_TAB}}</label>
            <input type="radio" name="tab-btn" id="tab-btn-3" [checked]="currentPage === systemSettingPage.STAFF_INFO" (change)="changeTab(systemSettingPage.STAFF_INFO)">
            <label id="content3" saucerLogger [logMessage]="staffInfoLog" for="tab-btn-3" class="tabs-border">{{SYSTEM_SETTING.STAFF_INFO_TAB}}</label>
            <input type="radio" name="tab-btn" id="tab-btn-4" [checked]="currentPage === systemSettingPage.MASTER_SYNC" (change)="changeTab(systemSettingPage.MASTER_SYNC)">
            <label id="content4" saucerLogger [logMessage]="syncCkMstLog" for="tab-btn-4" class="tabs-border master-sync">{{SYSTEM_SETTING.MASTER_SYNC}}</label>
            <input type="radio" name="tab-btn" id="tab-btn-5" [checked]="currentPage === systemSettingPage.DEFAULT_VALUE" (change)="changeTab(systemSettingPage.DEFAULT_VALUE)">
            <label id="content5" saucerLogger [logMessage]="defaultSettingLog" for="tab-btn-5" class="tabs-border master-sync">{{SYSTEM_SETTING.DEFAULT_VALUE}}</label>
            
            <div id="content-1" class="tab-role-setting">
                <div class="role-panel">
                    <div>
                        <div class="search-bar">
                            <pivot-search-bar [searchParams]="searchParams" (onFilterData)="onFilterTable($event)">
                            </pivot-search-bar>
                        </div>
                        <div class="cotainer-of-listbox-and-btnAddRole">
                            <div class="container-listRoles" >
                                <p-listbox optionLabel="rolename" [options]="listRoles" [(ngModel)]="selectedRole" (onClick)="selectRole($event)"
                                    emptyMessage="{{COMMON_TEXT.NO_DATA}}" id="listboxRole"
                                    [styleClass]="'roles-listbox'" [listStyle]="{'max-height':'calc(100vh - 305px)'}" [listStyleClass]="'roles-style'">
                                    <ng-template #itemOfListBox id="item-of-listbox" let-role pTemplate="item">
                                        <div class="role-item" id="role-item" *ngIf="role.name">
                                            <span class="role-item--name">{{role.name}}</span>
                                            <ng-template [ngIf]="!isDisableEditBtn">
                                                <pivot-icon-button *ngIf="selectedRole && (selectedRole.roleCd === role.roleCd)" 
                                                    (click)="showEditFormatRole(role.roleCd)" icon="assets/icons/pen.svg" 
                                                    [styleClass]="'btn-edit-role'">
                                                </pivot-icon-button>
                                            </ng-template> 
                                            <ng-template [ngIf]=" !role.checked && role.roleCd != 'RL00000001' && role.roleCd != 'RL00000002' && role.roleCd != 'RL00000099'">
                                                <pivot-icon-button class="hover-action" (click)="showEditFormatRole(role.roleCd)"  icon="assets/icons/pen.svg" 
                                                    [styleClass]="'btn-edit-role'">
                                                </pivot-icon-button>
                                            </ng-template> 
                                        </div>
                                        <div class="input-container" id="element-input" *ngIf="role.id == 'null' && showBottomInput "  style="opacity: 1; transform: translateY(0px);">
                                            <div class="input-with-border" (dblclick)="onDoubleClick($event)">
                                                <input #insertItem id="insert-item" class= "input-name-role" placeholder="入力してください。">
                                            </div>
                                            <div class="button-container">
                                                <pivot-icon-button id= "btnSummit" (onClick)="callOnSubmitRoleData()" [styleClass]="'row-function-btn confirm'" [buttonType]="buttonType.SECONDARY" icon="assets/icons/row_confirm.svg"></pivot-icon-button>
                                                <pivot-icon-button id="button-cancel" (onClick)="cancel()" [styleClass]="'row-function-btn cancel'" [buttonType]="buttonType.DANGER" icon="assets/icons/row_cancel.svg"></pivot-icon-button>
                                                <div #hollow id="hollow"></div>
                                            </div>
                                        </div>
                                        <div #elementInput class="input-container" id="element-input-after" 
                                            *ngIf="role.showEdit !== false && role.roleCd != 'RL00000001' && role.roleCd != 'RL00000002' && role.roleCd != 'RL00000099'" >
                                            <div class="input-with-border" (dblclick)="onDoubleClick($event)" >
                                                <input #insertItem id="insert-item" class= "input-name-role" [ngModel]="role.name">
                                            </div>
                                            <div class="button-container">
                                                <pivot-icon-button (onClick)="callOnSubmitRoleData(false)" [styleClass]="'row-function-btn confirm'" [buttonType]="buttonType.SECONDARY" icon="assets/icons/row_confirm.svg"></pivot-icon-button>
                                                <pivot-icon-button id="btnDelete" (onClick)="onRemoveRole(role.roleCd)" [styleClass]="'row-function-btn danger'" [buttonType]="buttonType.DANGER" icon="assets/icons/row_delete.svg"></pivot-icon-button>
                                                <pivot-icon-button id="button-cancel" (onClick)="cancel(false)" [styleClass]="'row-function-btn cancel'" [buttonType]="buttonType.DANGER" icon="assets/icons/row_cancel.svg"></pivot-icon-button>
                                                <div id="hollow"></div>
                                            </div>
                                        </div>
                                    </ng-template>
                                </p-listbox>
                            </div>
                            <div class="top-buttons" >
                                <div id="btnInsert" [ngClass]="{'plus-button': showButtonAddRole , 'plus-button-disabled': !showButtonAddRole}" (click)="openDialogInsert()">
                                    <div class="container-button-add">
                                        <i class="pi pi-plus"></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="permission-panel">
                    <div class="header">
                        <span>{{SYSTEM_SETTING.RESTRICTION_SETTING}}</span>
                        <pivot-text-button *ngIf="selectedRole" [buttonType]="buttonType.PRIMARY"
                            [disabled]="isDisableAllCheckboxBtn" [buttonText]="BUTTON.SAVE"
                            (btnClick)="onSaveLstMenu()">
                        </pivot-text-button>
                    </div>

                    <div class="checkbox-all">
                        <pivot-checkbox [value]="checkAllPMS" (onClick)="handleCheckAll($event)"
                            [disabled]="isDisableAllCheckboxBtn"></pivot-checkbox>
                        <span>{{SYSTEM_SETTING.ALL_CHECK_UNCHECK}}</span>
                    </div>

                    <div class="treeview">
                        <div class="treeview-left">
                            <ng-container *ngFor="let item1 of this.permissions">
                                <ul *ngIf="!item1.parent">
                                    <li>
                                        <div class="permission-item">
                                            <pivot-checkbox *ngIf="!item1.parent" [value]="item1.checked"
                                                (onClick)="handlePermissionCheck($event, item1)"
                                                [disabled]="item1.isDisableCheckbox">
                                            </pivot-checkbox>
                                            <label *ngIf="!item1.parent">{{item1.permissionname}}</label>
                                        </div>
                                    </li>
                                </ul>
                            </ng-container>
                        </div>
                        <div class="treeview-right">
                            <ng-container *ngFor="let item2 of this.permissionRight">
                                <ul *ngIf="!item2.parent">
                                    <li>
                                        <div class="permission-item">
                                            <pivot-checkbox *ngIf="!item2.parent" [value]="item2.checked"
                                                (onClick)="handlePermissionCheck($event, item2)"
                                                [disabled]="item2.isDisableCheckbox">
                                            </pivot-checkbox>
                                            <label *ngIf="!item2.parent">{{item2.permissionname}}</label>
                                        </div>
                                    </li>
                                </ul>
                            </ng-container>
                        </div>
                    </div>
                </div>
            </div>
            <div id="content-2" class="tab-month-setting">
                <div class="month-setting-header">
                    <span>{{SYSTEM_SETTING.MONTH_SETTING}}</span>
                    <pivot-text-button [buttonType]="buttonType.PRIMARY" [buttonText]="BUTTON.SAVE"
                        (btnClick)="onSaveMonth()">
                    </pivot-text-button>
                </div>

                <div class="month-setting-body">
                    <div>
                        <span class="content-2-title">{{SYSTEM_SETTING.START_MONTH}}</span>
                        <p-dropdown [options]="monthlys" [(ngModel)]="monthSelected" optionLabel="name" (onChange)="onChangeStartMonth($event)"
                            [disabled]="batchcheckDisable">
                        </p-dropdown>
                    </div>
                </div>
                <div class="nursing-home-header" *ngIf="hasZaitaku">
                    <span>{{SYSTEM_SETTING.NURSING_HOME_CARE_INDEX}}</span>
                </div>
                <div class="nursing-home-body" *ngIf="hasZaitaku">
                    <div>
                        <pivot-checkbox [value]="includeEdateDischarge" (onClick)="onCheckEdateDischarge()">
                        </pivot-checkbox>
                        <span>{{SYSTEM_SETTING.INCLUDE_EDATE_DISCHARGE}}</span>
                    </div>
                </div>
            </div>
            <div id="content-3" class="tab-staff-info">
                <pivot-role-setting-staff-info [roles]="listRoles"></pivot-role-setting-staff-info>
            </div>
            <div id="content-4" class="tab-sync-data">
                <pivot-sync-master-data></pivot-sync-master-data>
            </div>
            <div id="content-5" class="tab-default-value">
                <pivot-default-value-setting></pivot-default-value-setting>
            </div>
        </div>
    </div>
</div>

<pivot-dialog-role-editor [isDisplayModal]="displayDialog" [modalData]="roleEditModal" [data]="roleEditModalData"
    (onSubmitData)="handleRoleEditDialogClose($event)">
</pivot-dialog-role-editor>

<pivot-dialog-role-editor  [modalData]="roleInsertModal"
    [data]="roleInsertModalData" (onSubmitData)="handleRoleInsertDialogClose($event)">
</pivot-dialog-role-editor>

<pivot-confirm-del-selected-dialog [inputData]="delTxt" [isDisplayModal]="isDisplayModalDelete"
    [modalData]="modalDelete" (onSubmitData)="onSubmitDeleteModel($event)">
</pivot-confirm-del-selected-dialog>

<pivot-modal-template class="warning-dialog-del-role-dialog" *ngIf="isDisplayWarningDialog" [modalTemplate]="modalDataWarning" (closeModalHandler)="closeWarningDialog()">
    <div modal-body>
        {{warningMessage}}
    </div>
    <div modal-bottom >
        <div class="btn-grp-warning">
            <pivot-text-button  (btnClick)="closeWarningDialog()" [buttonType]="buttonType.SECONDARY"
                [buttonText]="BUTTON.YES">
            </pivot-text-button>
        </div>
    </div>
  </pivot-modal-template>