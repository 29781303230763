<pivot-modal-template class="dashboard-service form-modal" [modalTemplate]="modalTemplate" (closeModalHandler)="cancel()">
    <div class="dialog-select-datasoure" modal-body>
        <div class="datasource">
            <div class="datasource__new">
                <div *ngIf="isLoading" class="loading">
                    <div class="loader"></div>
                </div>
                <div class="datasource__new__radio d-flex">
                    <pivot-radio-button [isChecked]="isOptionCreate" (onClick)="onCheckboxDts(1)"></pivot-radio-button>
                    <label class="datasource__new__radio--label" (click)="onCheckboxDts(1)">{{COMMON_TEXT.CREATE_DATASOURCE}}</label>
                </div>
                <div class="datasource__new__folder">
                    <label class="datasource__new__folder--label">{{COMMON_TEXT.FOLDER_DATASOURCE}}</label>
                    <span class="input-required">*</span>
                <div class="datasource__new__folder__select d-flex gap-10">
                    <pivot-search-bar  [_disable]="!isOptionCreate"
                        class="search-field" 
                        [searchParams]="searchFolderParams"
                        [placeHolderFilter]="CREATE_DASHBOARD_TEMPLATE.SEARCH_FOLDER"
                        (onFilterData)="selectedFolder($event)"
                    >
                    </pivot-search-bar>
                    <button pButton type="button" icon="pi pi-plus" [disabled]="!this.isOptionCreate" class="btn-add-folder"
                    (click)="openDialogAddFolder()"></button>
                </div>
                <span *ngIf="isValidateDtsFolder" class="lbl-error">{{ CREATE_DASHBOARD_TEMPLATE.SELECT_FOLDER_DATASOURCE }}</span>
                </div>
                <div class="datasource__new__name">
                    <label class="datasource__new__name--label">データソース名</label>
                    <pivot-textbox [inputParams]="inputDesParams" (onInput)="onInputDescription($event)" [inputValue]="datasourceNm"></pivot-textbox>
                </div>
            </div>
            <div class="datasource__exist">
                <div class="datasource__exist__radio d-flex">
                    <pivot-radio-button [isChecked]="!isOptionCreate" [disabled]="!existDatasources.length" (onClick)="onCheckboxDts(2)"></pivot-radio-button>
                    <label class="datasource__exist__radio--label" (click)="onCheckboxDts(2)">{{COMMON_TEXT.USE_DATASOURCE_EXIST}}</label>
                </div>
                <div class="datasource__exist__table">
                    <p-orderList class="primeng-list" [value]="existDatasources" [selection]="selectedExistDs" [disabled]="isOptionCreate" (onSelectionChange)="onSelectionDatasourceChange($event)">
                        <ng-template let-ds pTemplate="item">
                            <div>{{ds.dsname}}</div>
                        </ng-template>
                    </p-orderList>
                </div>
            </div>
        </div>

        <div class="right-pannel">
            <pivot-text-button (btnClick)="save()" [buttonType]="buttonType.SECONDARY" [disabled]="disableButtonSave()" [buttonText]="BUTTON.SELECT">
            </pivot-text-button>
            <pivot-text-button (btnClick)="cancel()" [buttonType]="buttonType.SECONDARY" [buttonText]="BUTTON.CANCEL">
            </pivot-text-button>
        </div>
    </div>
</pivot-modal-template>
<pivot-dialog-folder-editor  [folderActionLog]="SAUCER_LOG_ACTION.MENU_TEMPLATE.DATASOURCE_CREATE_FOLDER_DIALOG"
    [folderType]="folderType"
    [isDisplayModal]="isDisplayFolderModal" 
    [modalData]="modalFolder" 
    (onSubmitData)="onChangeDataFolder($event)">
</pivot-dialog-folder-editor>