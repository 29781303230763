<div class="sync-data-header">
    <span>{{SYSTEM_SETTING.PIVOT_MASTER_SYNC}}</span>
</div>
<div class="sync-data-body">
    <div>
        <p class="content-2-title pre-line">{{SYSTEM_SETTING.SYNC_INFO}}</p>
    </div>
</div>

<pivot-icon-button class="btn-sync-master" 
            (click)="onSyncMasterData()" 
            [ngStyle]="{'background-color': iconType == false? '$white-color' : '$background-gray-color'}"
            [ngClass]="isDarkMode ? 'dark-mode-sync' : ''"
            [label]="BUTTON.START_SYNC" icon="assets/icons/icon_update_newdata.svg" >
</pivot-icon-button>

<pivot-dialog-sync-info-detail class="sync-info-detail"
    [isSyncing]="isSyncing" 
    [isDisplayModal]="showSyncInfoDetailDialog"
    [modalData]="syncInfoDetailModal" 
    [data]="syncDetailData"
    [statusOfAllSyns]="statusOfAllSyns"
    (onSubmitData)="handleModalDetailClose()">
</pivot-dialog-sync-info-detail>

