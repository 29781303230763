import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { GraphType } from '../../../../app/enum/common-enum';

@Component({
  selector: 'pivot-display-graph',
  templateUrl: './display-graph.component.html',
  styleUrls: ['./display-graph.component.scss']
})

export class DisplayGraphComponent implements OnInit, OnChanges {

  // style css  for panel
  @Input() cssStyle: any = {};
  @Input() selected: GraphType | null
  // event submit data
  @Output() onClickChart = new EventEmitter<any>();
  itemCharts: any[];
  currentSelectItem: any = {}
  isDarkMode: boolean;

  constructor() {
    const bodyElement = document.getElementsByTagName("body")[0];
    this.isDarkMode = bodyElement.classList.contains("navi") || bodyElement.classList.contains("dark") ? true : false;
    this.itemCharts = [
      { value: GraphType.BAR_CHART_HORIZONTAL, image: 'horizontal-bar-chart.svg'},
      { value: GraphType.BAR_CHART_VERTICAL, image: 'vertical-bar-chart.svg'},
      { value: GraphType.LINE_BAR_COMBINE_CHART, image: 'combo-chart.svg'},
      { value: GraphType.LINE_CHART, image: 'line-chart.svg'},
      { value: GraphType.DOUGHNUT_CHART, image: 'doughnut-chart.svg'},
      { value: GraphType.NUMBER_CHART, image: 'number-chart.svg'},
      // { value: GraphType.GAUGE_CHART, image: 'gauge-chart.svg'},
      { value: GraphType.PIE_CHART, image: 'pie-chart.svg'},
      { value: GraphType.STACKED_BAR, image: 'stacked-bar_chart.svg'},
      { value: GraphType.TABLE_CHART, image: 'table.svg'},
    ];
    
  }

  ngOnInit(): void { }
  ngOnChanges(changes: SimpleChanges): void {
    if (this.selected != null) {
      this.currentSelectItem = this.itemCharts.filter(x => x.value == this.selected).pop()
      if (this.currentSelectItem) {
        this.currentSelectItem.selected = true;
        this.itemCharts.map((x: any) => {
          if(x.value != this.selected){
            x.selected = false
          }
          return x;
        })
      } else {
        this.currentSelectItem = {}
      }
    } else {
      this.currentSelectItem.selected = false
      this.currentSelectItem = {}
    }
  }

  // select chart
  onSelectedChange(item: any) {
    if (this.currentSelectItem == item) {
      this.currentSelectItem.selected = false;
      this.currentSelectItem = {}
      this.onClickChart.emit(null);
    } else {
      this.currentSelectItem.selected = false
      this.currentSelectItem = item
      item.selected = true
      this.onClickChart.emit(item.value);
    }

  }

  // event focusin for chart
  onHoverChart(item: any) {
    item.mousein = true
  }

  // on mouse leave chart
  onLeaveChart(item: any) {
    item.mousein = false
  }
}
