<div>
  <div *ngIf="isDataSource" class="pivot-calculator " >
    <ng-container  *ngFor="let item of itemsCustom; let r = index " >
      <div *ngIf="item.hover" class="calculator-item" 
        [pTooltip]="item.hover"  tooltipPosition = "top"
        (click)="buttonClick($event, item.label)">{{item.label}}
      </div>
      <div *ngIf="!item.hover" class="calculator-item" 
        (click)="buttonClick($event, item.label)">{{item.label}}
      </div>
    </ng-container>
  </div>
  <div class="pivot-calculator itemOperator">
    <ng-container  *ngFor="let item of items; let r = index " >
      <div *ngIf="item.hover" class="calculator-item" 
        [pTooltip]="item.hover"  tooltipPosition = "top"
        (click)="buttonClick($event, item.label)">{{item.label}}
      </div>
      <div *ngIf="!item.hover" class="calculator-item" 
        (click)="buttonClick($event, item.label)">{{item.label}}
      </div>
    </ng-container>
  </div>
</div>


<p-overlayPanel #default [styleClass]="'list-option-default'">
  <pivot-search-bar [searchParams]="searchParams" (onFilterData)="filterConstantVal($event)">
  </pivot-search-bar>
  <ng-template pTemplate>
    <div class="tree-view-default">
      <pivot-lazy-tree-view [data]="treeViewList" [nodeClicked] = "nodeClicked" [isReLoadData] = "true"
      [scrollHeight]="scrollHeight" [rowHeight]="27" (nodeClick)="handleItemClick($event)" (leafNodeClick)="onClickItag($event)">
     </pivot-lazy-tree-view>
    </div>

     
  </ng-template>
</p-overlayPanel>