<pivot-modal-template class="select-widget-modal form-modal" [modalTemplate]="modalTemplate"
  (closeModalHandler)="closeFormModal()">
  <div class="widget-modal-body" modal-body>
    <div class="controls">
      <div>以下のウィジェットが関連しています。<br>
        一緒に更新したいウィジェットを選択して下さい。
      </div>
      <div>【ウィジェット】</div>
      <pivot-search-bar [searchParams]="searchParams" (onFilterData)="onFilterOutData($event)"></pivot-search-bar>
    </div>
    <pivot-table class="table" [data]="tableData" [isLoading]="loadingTable" 
    [selectedRows]="selectedWidgets" (checkRows)="checkRowsTable($event)"></pivot-table>

  </div>
  <div class="widget-modal-bottom" modal-bottom>
    <pivot-text-button (btnClick)="cancel()" [buttonType]="buttonType.SECONDARY" [buttonText]="BUTTON.CANCEL">
    </pivot-text-button>
    <pivot-text-button (btnClick)="save()" [buttonType]="buttonType.SECONDARY"
      [buttonText]="BUTTON.SELECT"></pivot-text-button>
  </div>
</pivot-modal-template>