import { Component, EventEmitter, Input, OnChanges, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { MESSAGE_TEXT } from '../../../../app/const/message';
import { BUTTON, COMMON_TEXT } from '../../../../app/const/text-common';
import { ButtonType } from '../../../../app/enum/common-enum';
import { ModalTemplate } from '../../../../app/models/common-model';

@Component({
  selector: 'pivot-confirm-delete-dialog',
  templateUrl: './confirm-delete-dialog.component.html',
  styleUrls: ['./confirm-delete-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None,
})


export class ConfirmDeleteDialogComponent implements OnInit, OnChanges {

  // display modal 
  @Input() isDisplayModal: boolean = false;
  // modal data
  @Input() modalData: ModalTemplate = new ModalTemplate();
  // submit data event
  @Output() onSubmitData: any = new EventEmitter<any>();
  // edit  data
  @Input() inputData: string = "";
  // button type
  buttonType = ButtonType;
  MESSAGE_TEXT = MESSAGE_TEXT;
  BUTTON = BUTTON;

  constructor() {}

  // on change data
  ngOnChanges(): void {
  }

  ngOnInit(): void {
    this.modalData.header = COMMON_TEXT.CONFIRM_DELETE;
    this.modalData.style = { 'width': '20%' };
    this.modalData.breakpoints = { '960px': '75vw', '640px': '100vw' };
  }

  // on submit data event
  onSubmit(data: boolean) {
    this.onSubmitData.emit(data);
    this.isDisplayModal = false;
  }
}
