import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { ModalTemplate } from '../../../../app/models/common-model';

@Component({
  selector: 'pivot-modal-template',
  templateUrl: './modal-template.component.html',
  styleUrls: ['./modal-template.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ModalTemplateComponent implements OnInit {
  @Input() modalTemplate: ModalTemplate = new ModalTemplate();
  @Output() closeModalHandler = new EventEmitter();
  isDisplay: boolean = false;
  constructor() { }

  ngOnInit(): void {
    this.isDisplay = true;
  }
  closeModal(){
    this.isDisplay = false;
    this.closeModalHandler.emit();
  }

}
