import { Directive, HostListener, Input } from '@angular/core';
import { SaucerLogService } from '../../../services/saucer-logs/saucer-log.service';


@Directive({
  selector: '[saucerLogger]'
})
export class LogActionDirective {
  @Input() logMessage: any;

  constructor(
    private saucerLogService : SaucerLogService
  ) { }

  @HostListener('click', ['$event'])
  onClick(): void {
    this.saucerLogService.action(this.logMessage.log, { action:this.logMessage.action });
  }
}
