import { ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnChanges, OnDestroy, Output, SimpleChanges, ViewChild } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { cloneDeep } from 'lodash';
import { COMMON_TEXT } from '../../../../../app/const/text-common';
import { TreeNode } from '../../../../models/common-model';
import Utils from '../../../../util/utils';
import { NodeTransferringService } from '../../../../services/node-transferring.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'pivot-tree-node',
  templateUrl: './tree-node.component.html',
  styleUrls: ['./tree-node.component.scss']
})
export class TreeNodeComponent implements OnChanges, OnDestroy {
  @Input() isTemplate: boolean = false;
  @Input() currentPage: number = 0;
  @Input() item: TreeNode = new TreeNode();
  @Input() firstNode: boolean = false;
  @Input() expanded: boolean | undefined = false;
  @Input() expandAll: boolean = true;
  @Input() enableCheckbox: boolean = false;
  @Input() isShowCheckBox: boolean = true;
  @Input() isFirstNodeChecked: boolean = false;
  @Input() dragScope: string = '';
  @Input() expandIcon: string = '';
  @Input() collapseIcon: string = '';
  @Input() selectable: boolean = false;
  @Input() hoverable: boolean = false;
  @Input() isDisplayTooltip: boolean = false;
  @Input() isMultiDragItem: boolean = false;
  @Input() nodeSelecteds: any[] = []
  @Input() dragImage: any = null;
  @Output() nodeMouseClick = new EventEmitter<any>();
  @Output() nodeMouseDoubleClick = new EventEmitter<any>();
  @Output() nodeDragStart = new EventEmitter<any>();
  @Output() nodeDragEnd = new EventEmitter<any>();
  @Output() nodeCheckboxChange = new EventEmitter<TreeNode>();
  @Output() scrollToBottom = new EventEmitter<number>();
  @Output() onDeleteNode = new EventEmitter<TreeNode>();
  @Output() isOnFetchData = new EventEmitter<boolean>();
  childView: TreeNode[] = [];
  COMMON_TEXT = COMMON_TEXT;
  treeNodeStepSrc: string = "";
  checkSub : Subscription;
  constructor(
    private cd: ChangeDetectorRef,
    private _nodeController: NodeTransferringService) { 
    const bodyElement = document.getElementsByTagName("body")[0];
    const isDarkMode = bodyElement.classList.contains("navi") || bodyElement.classList.contains("dark") ? true : false;
    this.treeNodeStepSrc = isDarkMode ? '../../../../../assets/icons/tree-node-step-white.svg' : '../../../../../assets/icons/tree-node-step.svg';
    this.checkSub = this._nodeController.check.subscribe((nodeId: any) => {
      if(nodeId && nodeId === this.item.id) {
        // Fake on changes event
        this.childView = this.item.nodes ?? [];
      }
    })
  }
  ngOnDestroy(): void {
    if(this.checkSub) this.checkSub.unsubscribe();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['item']) {
      this.childView = this.item?.nodes || [];
    }
    if (this.item && !Utils.isNullOrEmpty(changes['expandAll'])) {
      this.item.expanded = this.expandAll || this.item?.expanded;
    }
    if(this.item && changes['singleSelected']) {
      this.item.singleSelected = changes['singleSelected'].currentValue;
    }
    this.cd.detectChanges();
  }

  /**
   * Add left space to a node so it can be recognize as child of upper node
   * @param node 
   * @returns 
   */
  treeNodeChildrenMarginStyle(node: TreeNode) {
    const firstNodeMargin = 20;
    if (this.hideExpandIcon(node)) {
      return {};
    }

    return { marginLeft: firstNodeMargin + 'px' };
  }

  /**
   * check if checkbox is show
   * @param flag item isShow flag
   * @returns return flag data
   */
  checkIsShow(flag: any) {
    if (!Utils.isNullOrEmpty(flag)) return flag;
    return true;
  }

  createImageDrag() {
    let lstHilight = this.nodeSelecteds.filter(s=> s.highlighted) || []
    if(lstHilight.length > 0) {
      const fontSize = 14;
      const fontFamily = '"Roboto", Arial, sans-serif !important';
      const marginLeft = 10;
      const paddingTop = 10;
      const canvas = document.createElement('canvas');
      const ctx: any = canvas.getContext('2d');
      const lineHeight = fontSize + 10;
      const canvasHeight = lineHeight * lstHilight.length + 8;
      canvas.width = 161;
      canvas.height = canvasHeight;
      ctx.font = `300 ${fontSize}px Roboto`;
      ctx.textBaseline = 'top';
      ctx.fillStyle = 'gray';
      ctx.fillRect(0, 0, canvas.width, canvas.height);
      ctx.fillStyle = 'white';
      ctx.textAlign = 'left';
      ctx.imageSmoothingEnabled = true;
      lstHilight.forEach((s, index) => {
        const textX = marginLeft;
        const textY = index * lineHeight + paddingTop;
        ctx.fillText(s.label, textX, textY);
      });
      const dragImage = new Image();
      dragImage.src = canvas.toDataURL();
      this.dragImage = dragImage;
    }
  }

  handleDragStart(e: any) {
    if(this.dragImage)
      e.dataTransfer?.setDragImage(this.dragImage, 0, 0);
  }
  
  /**
   * recursive function
   * set selected property value from this node and all its childs
   * use in check and uncheck all cases
   * @param parent 
   * @param value 
   */
  changeBranchSelection(parent: TreeNode, value: boolean) {
    if (!Utils.isNullOrEmpty(parent)) {
      parent.selected = value;
      if (!Utils.isNullOrEmpty(parent.nodes)) {
        for (let node of parent.nodes || []) {
          this.changeBranchSelection(node, value);
        }
      }
    }
  }

  /**
   * recursive function
   * unexpand from this node to all its child
   * use in case unexpand a node must unexpand all its childs
   * @param parent 
   */
  unexpandNode(parent: TreeNode) {
    if (!Utils.isNullOrEmpty(parent)) {
      parent.expanded = false;
      if (!Utils.isNullOrEmpty(parent.nodes)) {
        for (let node of parent.nodes || []) {
          this.unexpandNode(node);
        }
      }
    }
  }

  /**
   * Load node
   */
  async loadNode(node: TreeNode) {
    this.isOnFetchData.emit(true);
    let nodeChild = await this._nodeController.getNodesByLevel(node, 2, this.isTemplate);
    if(nodeChild.length > 0) {
      // Update current data
      let updateNode = this.childView.find(s=> s.id === node.id);
      if(updateNode) {
        updateNode.nodes = [...nodeChild[0].nodes ?? []];
        updateNode.isLoaded = true;
        const sendNote = cloneDeep(updateNode)
        // Update origin TreeNode
        this._nodeController.loadedNodeDataSub.next(sendNote);

        // Re-map current node
        updateNode.nodes = updateNode.nodes.map(node => ({
          ...node,
          nodes: node.isLastNode ? [] : node.nodes
        }));
      }
    }
    node.isLoaded = true;
    // Trigger node on changes + clear trigger key
    this._nodeController.loadedNodeDataSub.next(null);
    this._nodeController.checkSub.next(node.id!);
    this._nodeController.checkSub.next(null);
    this.isOnFetchData.emit(false);
  }

  /**
   * handle label click event
   * @param node 
   * @returns 
   */
  async onSelect(node: TreeNode) {
    if (this.hasChild(node) && !this.showCheckbox(node)) {
      this.checkExpandTree(node);
    }

    if(!node.isLoaded) await this.loadNode(node);
    if (this.selectable && !this.hasChild(node)) {
      node.singleSelected = true;
    }
    this.nodeMouseClick.emit(node);
  }

  isOnFetch(status: boolean) {
    this.isOnFetchData.emit(status);
  }

  /**
   * expand current node only
   * in case unexpand then must unexpand all child nodes
   * @param node 
   */
  async expandTree(node: TreeNode) {
    this.checkExpandTree(node);
    if(!node.isLoaded) await this.loadNode(node);

    this.nodeMouseClick.emit(node);
  }

  checkExpandTree(node: TreeNode) {
    if (node.expanded) {
      this.unexpandNode(node);
    } else {
      node.expanded = !node.expanded;
    }

    this.refeshData();
  }

  /**
   * re-render tree
   */
  refeshData() {
    const temp = this.childView;
    this.childView = [];
    this.childView = temp;
  }

  childItemHandler(child: any) {
    if(this.isMultiDragItem) {
      child.highlighted = !child.highlighted
      if(child.highlighted) {
        if(this.nodeSelecteds?.findIndex(s=>s.id == child.id) == -1)
        this.nodeSelecteds.push(child)
      } 
      else this.nodeSelecteds = cloneDeep(this.nodeSelecteds?.filter(item => item.id != child.id))
      this.nodeMouseClick.emit(this.nodeSelecteds);
    }
    else {
      this.nodeMouseClick.emit(child);
    }
    this.createImageDrag()
  }

  handleDoubleClick(node: TreeNode) {
    this.nodeMouseDoubleClick.emit(node);
  }

  childItemDoubleClickHandler(value: any) {
    this.nodeMouseDoubleClick.emit(value);
  }

  /**
   * check a node has child or not
   * @param node 
   * @returns 
   */
  hasChild(node: TreeNode): boolean | undefined {

    return node.nodes && node.nodes.length > 0;
  }

  /**
   * check to show checkbox or not
   * @param node 
   * @returns 
   */
  showCheckbox(node: TreeNode): boolean {
    return this.enableCheckbox &&
      this.checkIsShow(node.isShowCheckBox) &&
      ((this.hasChild(node) && this.isFirstNodeChecked) || !this.hasChild(node));
  }

  /**
   * handle drag start event hook
   * set data with name "item" into native data transfer object
   * @param e 
   * @param node 
   */
  dragStart(e: any, node: TreeNode) {
    if(this.isMultiDragItem) {
      node.highlighted = true
      let nodeSelect = this.childView.filter(s=> s.highlighted) || []
      this.nodeSelecteds = nodeSelect
      this.createImageDrag()
      e.dataTransfer.setData("items", JSON.stringify(nodeSelect));
    }
    this.nodeDragStart.emit({ event: e, node });
  }

  dragEnd(e: any, node: TreeNode) {
    if(this.isMultiDragItem) this.childView.map(s=> s.highlighted = false)
    this.nodeDragEnd.emit({ event: e, node });
  }

  childDragStart(e: any) {
    this.nodeDragStart.emit(e);
  }

  childDragEnd(e: any) {
    this.nodeDragEnd.emit(e);
  }

  hideExpandIcon(node: TreeNode) {
    return !this.hasChild(node) || !this.expandIcon || !this.collapseIcon;
  }

  handleCheckboxChange(node: TreeNode) {
    if (this.enableCheckbox) {
      node.selected = !node.selected;
      // if current node has any child then set checkbox state to be the same for its childs
      if (this.hasChild(node)) {
        this.changeBranchSelection(node, node.selected);
      }

      this.nodeCheckboxChange.emit(node);
    }
  }

  childCheckboxChange(e: any) {
    this.nodeCheckboxChange.emit(e);
  }

  expandIconInvisible(node: TreeNode) {
    if (node.isShowIcon) {
      return false;
    }

    if (this.hideExpandIcon(node)) {
      return true;
    }

    return true;
  }
  scrollHandler(event:any) {
    let scrollElement = event.target;
    if (scrollElement.scrollHeight - scrollElement.scrollTop === scrollElement.clientHeight)
    {
      this.currentPage ++;
      this.scrollToBottom.emit(this.currentPage );
    }
  }

  deleteNode(node: TreeNode) {
    this.onDeleteNode.emit(node) 
  }
}
