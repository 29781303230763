import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { ProcessLoadingService } from '../../../../app/services/loading.service';
import { NOT_FOUND } from '../../../const/text-common';
import { AuthenticationService } from '../../../services/authentication.service';
import { Location } from '@angular/common';
import {Subscription} from 'rxjs';
import {ErrorHandleService} from '../../../services/error-handle.service';
import {Router} from '@angular/router';
import Utils from '../../../util/utils';
import {HTTP_STATUS} from '../../../config/app.config';
import {ROUTE_PATH} from 'src/app/const/route-path';
import {LocalStorageKey} from 'src/app/_helper/local-storage.helper';
import {CanActivatePivotListsService} from 'src/app/services/can-deactivate/can-activate-pivot-lists.service';

@Component({
  selector: 'pivot-not-found',
  templateUrl: './not-found.component.html',
  styleUrls: ['./not-found.component.scss']
})
export class NotFoundComponent implements OnInit, OnDestroy {

  DISPLAY_NOT_FOUND = NOT_FOUND;
  screenDetails: any = {
    title: this.DISPLAY_NOT_FOUND.TITLE,
    subtitle: this.DISPLAY_NOT_FOUND.SUB_TITLE,
    content: this.DISPLAY_NOT_FOUND.CONTENT
  };
  apiServiceSubscriber: Subscription | undefined;
  backURLSubscriber: Subscription | undefined;
  backURL: string;

  errorCode: any;
  constructor(
    private authenticationService: AuthenticationService,
    private loadingService: ProcessLoadingService,
    private location: Location,
    private errorHandleService: ErrorHandleService,
    private cdr: ChangeDetectorRef,
    private router: Router,
    private canActivatePivotListsService: CanActivatePivotListsService
    ) { }

  ngOnInit(): void {
    this.apiServiceSubscriber = this.errorHandleService.responseValue.subscribe((screenData: any) => {
      if (screenData && screenData.statuscode !== 9999) {
        this.screenDetails.title = screenData.title;
        this.screenDetails.subtitle = screenData.functionMessage;
        this.screenDetails.content =  screenData.subtitle;
        this.errorCode = screenData.statuscode;
      }
    });
    this.backURLSubscriber = this.errorHandleService.backURL.subscribe((url: string) => {
      this.backURL = url;
    })
    this.loadingService.isLoading.emit(false);
    this.authenticationService.isPageNotFound.emit(true);
  }

  goToHome(): void {
    if(Utils.isNullOrEmpty(this.backURL)) {
      if(this.errorCode == HTTP_STATUS.FORBIDDEN) {
        window.history.go(-2);
      }
      else
        this.location.back();
    }
    else {
      if(this.errorCode == HTTP_STATUS.UNAUTHORIZED) {
        this.authenticationService.logout();
        this.errorCode = 9999;
      }
      else if(this.errorCode == HTTP_STATUS.FORBIDDEN) {
        let routeListsNeedToRecheck: string[] = [
          ROUTE_PATH.DATASOURCE, 
          ROUTE_PATH.DASHBOARD_LIST, 
          ROUTE_PATH.WIDGET_LIST, 
          ROUTE_PATH.SYSTEM_SETTING, 
          ROUTE_PATH.DASHBOARD_WIDGET_TEMPLATE]
          if(routeListsNeedToRecheck.includes(this.backURL)) {
            this.backURL = this.reGenerateBackURL(this.backURL);
          }
      }
      this.navigateTo([this.backURL]);
      this.backURL = "";
    }
   
  }

  ngOnDestroy(): void {
    this.errorHandleService.setIsCloseErrorDialogOrErrorScreen(true);
    this.backURL = "";
    this.screenDetails = {
      title: this.DISPLAY_NOT_FOUND.TITLE,
      subtitle: this.DISPLAY_NOT_FOUND.SUB_TITLE,
      content: this.DISPLAY_NOT_FOUND.CONTENT
    };
    this.apiServiceSubscriber?.unsubscribe();
    this.backURLSubscriber?.unsubscribe();
    this.authenticationService.isPageNotFound.emit(false);
  }

  protected navigateTo(commands: any[] = []) {
    this.router.navigate(commands);
  }

  reGenerateBackURL(URLString: string): string {
    let result = ROUTE_PATH.HOME;
    let currentRole = this.authenticationService.getData(LocalStorageKey.CURRENT_USER_ROLE);
    if (currentRole && currentRole.length > 0 && currentRole[0].permissions) {
      switch(URLString) {
        case ROUTE_PATH.SYSTEM_SETTING:
          if(!currentRole[0].permissions.includes("#systemsetting")) 
            break;
          else
            result = URLString;
          break;
        case ROUTE_PATH.DATASOURCE:
        case ROUTE_PATH.DASHBOARD_LIST:
        case ROUTE_PATH.WIDGET_LIST:
        case ROUTE_PATH.DASHBOARD_WIDGET_TEMPLATE:
          let realURLForChecking = this.canActivatePivotListsService.getRequiredPermissionToAccessToStateRoute("/" + URLString);
          if(!currentRole[0].permissions.includes(realURLForChecking)) 
            break;
          else
            result = URLString
          break;
      }
    }
    return result;
  }
}
