import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ButtonIconType, ButtonType } from '../../../../../app/enum/common-enum';
import { InputParams } from '../../../../../app/models/common-model';
import { Location } from '@angular/common';
import { cloneDeep } from 'lodash';
import { BUTTON } from '../../../../../app/const/text-common';

@Component({
  selector: 'pivot-multiple-pannel-template',
  templateUrl: './multiple-pannel-template.component.html',
  styleUrls: ['./multiple-pannel-template.component.scss']
})
export class MultiplePannelTemplateComponent implements OnInit {
  @Input() titlePage: string = "";
  @Input() name: any = null;
  @Input() nameParams: InputParams = new InputParams();
  @Input() isDisplayControlIcon: boolean = true;
  @Input() isDisplayToolTip: boolean = false;
  @Input() isDisplayPannel: boolean = true;
  @Input() isDisplayLeftPannel: boolean = true;
  @Input() isDisplayRightPannel: boolean = true;
  @Output() dockerListHandler = new EventEmitter<any>();
  @Output() nameFieldHandler = new EventEmitter<any>();
  @Output() backPageHandler = new EventEmitter<any>();
  @Input() isDisplayDSSetting: boolean = false;
  isBackUsed = false;
  _dockerList: any[] = [];
  get dockerList(): any[] {
    return this._dockerList;
  }
  @Input() set dockerList(list: any[]) {
    this._dockerList = list;
    this.adjustWidthOfBodyContent();
  }

  showControlPopup: boolean = false;
  iconType = ButtonIconType;
  buttonType = ButtonType;
  BUTTON = BUTTON;
  isExpanded: boolean = false;

  constructor(private _location: Location) { }

  ngOnInit(): void {
    this.isBackUsed = this.backPageHandler.observers.length > 0;
    this.nameParams.maxLength = 100;
    this.nameParams = cloneDeep(this.nameParams);
    this.isDisplayLeftPannel = true;
    this.isDisplayRightPannel = true;
    window.addEventListener('click', (event: any) => {
      let controlPopup = document.getElementById('control-popup');
      if (!controlPopup?.contains(event.target)) {
        let controlIcon = document.getElementById('control-icon');
        if (!controlIcon?.contains(event.target)) {
          this.showControlPopup = false;
        }
      }
      else {
        this.showControlPopup = false;
      }
    });
  }

  toogle() {

  }

  backPage() {
    this.backPageHandler.emit();
    if (!this.isBackUsed) {
      this._location.back();
    }
  }
  openControlPopup() {
    this.showControlPopup = true;
  }
  showDocker(pannel: string) {
    let dockerList: any[] = [];
    if (pannel === 'left') {
      dockerList = this.dockerList.filter(i => i.pannel !== pannel);
      this.isDisplayLeftPannel = true;
    }
    else {
      dockerList = this.dockerList.filter(i => i.pannel !== pannel);
      this.isDisplayRightPannel = true;
    }
    let pannelContents = document.getElementsByClassName('pannel-content') as HTMLCollectionOf<HTMLElement>;
    for (let i = 0; i < pannelContents.length; i++) {
      if (length === 0) { //Show all dockers
        pannelContents[i].style.width = "calc(50% - 5px)";
      }
    }
    this.dockerListHandler.emit({dockerList: dockerList, clickedPannel:pannel });

  }
  adjustWidthOfBodyContent() {
    let length = this._dockerList.length;
    let contentEle = document.getElementById('content-wrapper');
    let pannelEle = document.getElementById('pannel-wrapper');
    if (length > 0 && contentEle && pannelEle) {
      let hasRightPannel = !(this.dockerList.filter(item => item.pannel === "right").length > 0);
      let hasLeftPannel = !(this.dockerList.filter(item => item.pannel === "left").length > 0);

      let bodyWidth = length * 15 + 70 - (length > 0 ? 3 : 0);
      let pannelWidth = length === 1 ? 30 - (length * 15) + 3 : 30 - (length * 15);

      contentEle.style.width = bodyWidth + '%';
      pannelEle.style.width = length === 2 ? '3%' : pannelWidth + '%';
      let pannelContents = document.getElementsByClassName('pannel-content') as HTMLCollectionOf<HTMLElement>;
      for (let i = 0; i < pannelContents.length; i++) {
        pannelContents[i].style.width = "calc(100% - 37px)";
      }
      this.isDisplayRightPannel = hasRightPannel;
      this.isDisplayLeftPannel = hasLeftPannel;
    }
    //No pannel docker, open default mode
    if (length === 0 && pannelEle && contentEle) {
      pannelEle.style.width = '30%';
      contentEle.style.width = '70%';
      this.isDisplayRightPannel = true;
      this.isDisplayLeftPannel = true;
    }
  }


  onInputName(event: any) {
    this.nameFieldHandler.emit(event.target.value);
  }
}
