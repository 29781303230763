<p-orderList [id]="params ? params.id : ''" [value]="params ? params.items: []" [dragdrop]="dragdrop" [styleClass]="'pivot-list-default'"
    [style]="params ? params.cssStyle : ''" (onReorder)="sortListData()" (onSelectionChange)="selectionChange($event)"
    [(selection)]="selection">
    <ng-template let-data pTemplate="item">
        <div class="list-item" style="height: 30px;
            display: block;">
            <div style="float: left;
                height: 30px;
                display: flex;
                justify-content: space-between;
                z-index: -1;
                gap: 10px;
                align-items: center;" [ngClass]="{'filter-item':  data.isFilter, 'not-filter-item':  !(data.isFilter), 'ds-setting': isDsSetting}">
                <span *ngIf="params.isBorderLeftColor" class="ls-item-color"   
                [ngClass]="{'master': data.data['tabtype'] == treeViewTab.MASTER, 
                            'schedule' :  data.data['tabtype'] == treeViewTab.SCHEDULE, 
                            'report' :  data.data['tabtype'] == treeViewTab.REPORT, 
                            'record' :  data.data['tabtype'] == treeViewTab.RECORD,
                            'seikuy' :  data.data['tabtype'] == treeViewTab.SEIKUY,
                            'user-defined' : data.data.columntype == DataSourceColumnType.USER_DEFINED
                            }"></span>
                <label [ngStyle]="labelStyle" style="cursor: pointer !important;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    /* width: 85%; */
                    font-size: 14px;
                    overflow: hidden;" 
                    (click)= "onClickOption($event)"
                    [style.color]="data.hilightColor || ''"
                    *ngIf="!params.isDisplayTooltip && !forFooter">{{data[params.itemDisplayText]}}
                </label>

                <label [ngStyle]="labelStyle" style="cursor: pointer !important;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    width: 85%;
                    font-size: 14px;
                    overflow: hidden;"
                    [style.color]="data.hilightColor || ''"
                    (click)= "onClickOption($event)"
                    *ngIf="params.isDisplayTooltip && !forFooter "
                    [pTooltip]="data[params.itemDisplayTooltip!]"
                    [ngClass]="{'user-defined-label': (data.data && data.data.columntype == DataSourceColumnType.USER_DEFINED)}"
                    tooltipPosition="bottom">{{data[params.itemDisplayText!]}} </label>
                <label style="cursor: pointer !important;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    width: 35%;
                    font-size: 14px;
                    overflow: hidden;" *ngIf="forFooter">
                </label>
                <label *ngIf="data.defaultValue?.name && isBorderButton" style="cursor: pointer !important;  
                    border-radius: 4px;
                    border: 1px dotted #9E9E9E;
                    padding: 2px;
                    z-index: 1000;
                    min-width: 50px;
                    width: fit-content;
                    font-size: 11px;
                    text-align: center;"
                    (click)="onShowListOption($event, data.defaultValue)">
                    {{data.defaultValue?.name}}
                </label>
                <label *ngIf="data.defaultValue?.name && !isBorderButton && !forFooter" style="cursor: pointer !important;  
                    border-radius: 4px;
                    padding: 2px;
                    z-index: 1000;
                    font-size: 14px;
                    width: fit-content;
                    min-width: 60px;
                    white-space: nowrap;
                    text-align: right;"
                    (click)="onShowListOption($event, data.defaultValue)">
                    {{data.defaultValue?.name}}
                </label>
                <label *ngIf="data.defaultValue?.name && !isBorderButton && forFooter" style="cursor: pointer !important;  
                    border-radius: 4px;
                    padding: 2px;
                    z-index: 1000;
                    font-size: 14px;
                    width: 90%;
                    text-align: right;"
                    (click)="onShowListOption($event, data.defaultValue)">
                    {{data.defaultValue?.name}}
                </label>
            </div>
           
            <div  *ngIf="!(forFooter || (data.data && data.data.columntype == DataSourceColumnType.USER_DEFINED))" class="columns-option">
                <div class="item-filter" [ngClass]="[data.onFilter ? 'allow-filter': 'not-filter']" *ngIf="data.isFilter" (click)="filterData(data)"></div>
                <div class="item-default-right" [ngClass]="{'ds-setting': isDsSetting}">
                    <div class="list-item-default item-default-delete" (click)="deleteItem(data)">
                        <i class="pi pi-times"></i>
                    </div>
                </div>
            </div>
        </div>
    </ng-template>
</p-orderList>

<p-overlayPanel #lb [styleClass]="'list-option'" *ngIf="showOption">
    <ng-template pTemplate>
        <p-listbox [options]="options" [(ngModel)]="selectOption" optionLabel="name" (onClick)="onShowDialogFilter($event)"
            (onChange)="onSelectedOption($event)">
            <ng-template let-option pTemplate="item">
                <div [ngClass]="{'label-option-datasource': isDsSetting}">{{option.name}}</div>
            </ng-template>
        </p-listbox>
    </ng-template>
</p-overlayPanel>