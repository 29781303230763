import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewEncapsulation } from "@angular/core";
import { cloneDeep, isEqual } from "lodash";
import * as moment from 'moment'; 
import { Subject } from "rxjs";
import StringUtils from "src/app/util/stringUtils";
import { beautify, isPubishWidget } from "src/app/_helper/helper";
import { COMMON_TEXT, BUTTON } from "../../../../app/const/text-common";
import { ButtonIconType, ButtonType, CheckAllMode, DataType, DateFormat, SearchType, SortMode, SyncDataStatus } from "../../../enum/common-enum";
import { RowOfTableStaff, SearchParams } from '../../../models/common-model';
import { TableData } from "../../../models/table-model";
import { SaucerLogService } from "src/app/services/saucer-logs/saucer-log.service";

@Component({
  selector: "pivot-table",
  templateUrl: "./table.component.html",
  styleUrls: ["./table.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class TableComponent implements OnInit, OnChanges, OnDestroy {
  @Input() isDefaultCheck: boolean = false;
  @Input() loadicon :boolean;
  @Input() proccess :any[] ;
  @Input() checked :boolean;
  @Input() disableCheckbox: boolean = false;
  @Input() disableHeaderCheckbox: boolean = false;
  @Input() selectedRow: any = {};
  @Input() dragScope: String = 'group-area'
  @Input() selectedRows: any[] =[];
  @Input() selectedRowsToDrag : string[] = [];
  @Input() checkAllMode: CheckAllMode = CheckAllMode.SINGLE_PAGE;
  @Input() paginateParams: any = { currentPage: 1, pageSize: 20 };
  @Input() saucerLogPagingAction: any;
  @Input() staffInfor:  boolean = false;
  @Output() clickRow = new EventEmitter<any>();
  @Output() cellFocusOut = new EventEmitter<any>();
  @Output() cellFocusIn = new EventEmitter<any>();
  @Output() cellDropdownChange = new EventEmitter<any>();
  @Output() checkRows = new EventEmitter<any>();
  @Output() unCheckRows = new EventEmitter<any>();
  @Output() pageChange= new EventEmitter<any>();
  @Output() cellCheckChange = new EventEmitter<any>();
  @Output() onEditDataRow = new EventEmitter<any>();
  @Output() onDeleteDataRow = new EventEmitter<any>();
  @Output() checkFieldEvent = new EventEmitter<any>();
  
  @Output() staffsDragged = new EventEmitter<any>();
  @Output() showDropAreaChange = new EventEmitter<boolean>();

  private readonly destroy$ = new Subject<boolean>();

  _isLoading: boolean = false;
  draggedStaff: RowOfTableStaff | undefined | null;;
  dragScopeView: 'teams';
  listDataDrag: string[] = []
  get isLoading(): boolean {
    return this._isLoading;
  }
  @Input() set isLoading(value: boolean) {
    this._isLoading = value;
    if (this._isLoading) {
      this.initData();
    }
  }

  _data: TableData = new TableData();
  get data(): TableData {
    return this._data;
  }
  @Input() set data(value: TableData) {
    this._data = value;
    if (this._data) {
      this.initData();
    }
  }
  tableId: string = '';
  iconType = ButtonIconType;
  buttonType = ButtonType;
  buttonText = BUTTON;
  isDragging:boolean= false;
  draggedDiv: HTMLElement;
  draggedLabel: HTMLElement;
  rowSeletedObject : any [] = [] ;
  headerData: any[] = [];
  bodyData: any[] = [];
  config: any = {};
  checkedAll: boolean = false;
  cols: number = 0;
  isShowSortPopup = false;
  isShowFilterPopup = false;
  sortColumnList: SearchParams = {
    type: SearchType.combo,
    placeholder: COMMON_TEXT.SEARCH,
    cssStyle: { width: '15rem', height: '40px' },
    readonly: false,
    disabled: false,
    dataSource: [],
    comboDisplayText: 'name'
  }
  sortField: any = {};
  sortMode: SortMode = SortMode.ASCENDING;
  filterColumnList: SearchParams = {
    type: SearchType.combo,
    placeholder: COMMON_TEXT.SEARCH,
    cssStyle: { width: '15rem', height: '40px' },
    readonly: false,
    disabled: false,
    dataSource: [],
    comboDisplayText: 'name'
  }
  filterField: string = "";
  filterFields: any[] = [];
  selectedFilterField: any[] = [];
  isAscending: boolean = false;
  isDescending: boolean = false;
  isShowDropArea: boolean;
  totalRecord: number = 0;
  dataRecords: any[] = [];
  filterRecords: any[] = [];
  localDataRecords: any[] = [];
  sortIcon: string = "";
  sortIconCount: number = 0;
  isFilterAll: boolean = false;
  dataType = DataType;
  COMMON_TEXT = COMMON_TEXT;
  processType = SyncDataStatus;
  isHasMergeCell: boolean = false;
  showDraggableDiv: boolean = false;
  dragInProgress: boolean = false;
  totalDayDetailValues: any  = {
    key: "totaldays",
    data: []
  };
  key: string = '';

  constructor( private saucerLogService : SaucerLogService ) { }

  ngOnInit(): void {
    if (this.staffInfor){
      this.createImgButton();
    }
    if (this.data) {
      this.initData();
    }
    if (this.isDefaultCheck) {
      this.checkAll();
    }
  }

  initData() {
    this.totalDayDetailValues = {
      key: "totaldays",
      data: []
    };
    this.checkedAll = false;
    this.headerData = this.data?.header;
    this.dataRecords = this.data?.body;
    this.localDataRecords = this.dataRecords;
    this.config = this.data?.config;
    this.tableId = this.config?.id;
    if(this.config?.disableHeaderCheckbox) this.disableHeaderCheckbox = this.config.disableHeaderCheckbox;
    this.cols = this.headerData.filter(h => h.visible)?.length;
    this.totalRecord = this.dataRecords?.length;
    this.sortColumnList.dataSource = this.headerData.filter(item => item.sortable).map(item => { return { 'name': item.title, 'value': item.field } });
    this.filterColumnList.dataSource = this.headerData.filter(item => item.filterable).map(item => { return { 'name': item.title, 'value': item.field } });
    if (this.config.showCheckbox) this.cols = this.cols + 1;
    if (this.config.hoverShowCheckbox) this.cols = this.cols + 1;
    if (this.config.showIndex) this.cols = this.cols + 1;
    if (this.config.showIndex) this.cols = this.cols + 1;
    if (this.config.showCheckboxTitle) this.cols = this.cols + 1;
    this.localDataRecords.forEach(item => {
      if (!item.checked) item.checked = false;
    });
    this.headerData.forEach(item => {
      if(item.dataType == this.dataType.CHECKBOX)
        item.checkedAll = this.localDataRecords.every(data => data[item.field]);
    });
    if (this.config.showPagination) {
      this.paginateData(this.paginateParams);
    }
    else {
      this.bodyData = this.localDataRecords;
    }

    this.sortTTDCells();
    this.setCheckedAll();
  }

  getStyle(field: string = '', type: string = '') {
    let res = '';
    let header = this.headerData.find(item => item.field === field);
    if (header) {
      let attribute;
      if (type === 'header') attribute = header.attribute.header;
      if (type === 'row') attribute = header.attribute.row;
      for (let property in attribute) {
        if (attribute[property]) {
          res += (property + ':' + attribute[property] + '!important;');
        }
      }
    }
    return res;
  }

  getClass(col: any, type: string = '', rowData: any = null) {
    let field: string = col.field;
    const header = this.headerData.find(item => item.field === field);
    if (!header) return '';

    if (type == 'row' && rowData?.disableInputCols?.includes(field)) return 'ck-disable-cell ck-row-right ck-row-text-overflow';
    
    if (type === 'row') return header.attribute.rowClass;

    return '';
  }

  checkAll() {
    this.checkedAll = !this.checkedAll;
    if (this.checkedAll) {
      this.setCheckboxValue(true);
      this.setSelectedRows();
      this.checkRows.emit({ "data": this.selectedRows });
    } else {
      this.setCheckboxValue(false);
      this.setSelectedRows();
      this.checkRows.emit({ "data": this.selectedRows }); //No select any records
    }
  }

  dragStart(event:DragEvent | any, staff: RowOfTableStaff) {
    this.draggedDiv = document.createElement('div');
    this.dragInProgress = true;
    this.isShowDropArea = true;
    if (this.selectedRowsToDrag.length < 0 ){
      return;
    }else {
      if (staff) {
        this.draggedStaff = staff;
        if ( this.draggedStaff.id) {
          this.selectedRowsToDrag.push(this.draggedStaff.id);
          this.drawTheDragPreview(this.selectedRowsToDrag);
        }else {
          this.drawTheDragPreview(this.selectedRowsToDrag);
        }
      }else {
        this.drawTheDragPreview(this.selectedRowsToDrag);
      }
    }
    //draw drag Preview
    this.draggedDiv.id = "drag-ghost";
    this.draggedDiv.style.position = "absolute";
    this.draggedDiv.style.top = "-1000px";
    document.body.appendChild(this.draggedDiv);
    event.dataTransfer?.setDragImage(this.draggedDiv, 0, 0);
    this.staffsDragged.emit(this.draggedStaff);
    this.showDropAreaChange.emit(this.isShowDropArea);
  }

  dragEnd( staff: RowOfTableStaff) {
    if (staff && staff.id) {
      //Remove item hover when the drag event ends 
      this.draggedStaff = staff;
      if (this.draggedStaff.id) {
        const indexToRemove = this.selectedRowsToDrag.indexOf(this.draggedStaff.id);
        if (indexToRemove !== -1) {
          this.selectedRowsToDrag.splice(indexToRemove, 1);
        }
      }
    }
    this.draggedStaff = null;
    this.isShowDropArea = false ; 
    this.dragInProgress = false;
    this.listDataDrag = [];
    this.showDropAreaChange.emit(this.isShowDropArea);
    if (this.draggedDiv && this.draggedDiv.parentNode) {
      this.draggedDiv.parentNode.removeChild(this.draggedDiv);
    }
  }

  drawTheDragPreview(listData: string[] =[]){
    let data = this.dataRecords.filter(r => listData.some(x=>x === r.id)); 
    data.forEach((row, index) => {
        let container = this.createContainer();
        //draw the pi-ellipsis-v icon 
        this.drawDragIcon(container);
        //draw cell ID  
        this.drawCellID(container, row);
        //draw cell name
        this.drawCellName(container, row);
        //draw cell email 
        this.drawCellEmail(container, row);
        //draw cell phone number 
        this.drawCellPhoneNumber(container, row);
        //draw cell the group 
        this.drawCellGroup(container, row);
        //draw cell the rollName
        this.drawCellRole(container, row);
        //Draw the container of icons
        this.drawButtonGroup(container);
        this.draggedDiv.appendChild(container);
        this.staffsDragged.emit(row);
        this.showDropAreaChange.emit(this.isShowDropArea);
    })
  }
  
  createContainer() {
    let container = document.createElement('div');
    container.style.display = "flex";
    container.style.alignItems = "left";
    container.style.height = "48px";
    container.style.backgroundColor = "rgba(103, 102, 102, 0.90)";
    return container;
  }

  createImgButton() {
    let iconPan = document.createElement('img');
    iconPan.src = "../../assets/icons/edit_pan_white.svg";
    document.body.appendChild(iconPan);
    let iconTrash = document.createElement('img');
    iconTrash.src = "../../assets/icons/trash_white.svg"; 
    document.body.appendChild(iconTrash);
  }

  drawDragIcon(container : any) {
    let draggDiv_icon_staff = document.createElement('div');
    draggDiv_icon_staff.style.display = "flex";
    draggDiv_icon_staff.style.justifyContent = "space-around";
    draggDiv_icon_staff.style.alignItems = "center";
    draggDiv_icon_staff.style.width = "1.75vw";
    draggDiv_icon_staff.style.border = "1px solid #AFAFAF";
    
    let draggIcon =  document.createElement('i');
    draggIcon.style.margin = "10px 0 10px 0";
    draggIcon.classList.add('pi', 'pi-ellipsis-v', 'drag-icon');
    draggIcon.style.fontSize = '1rem';
    draggIcon.style.width = '1rem';
    draggIcon.style.height = '1rem';
    draggIcon.style.color = '#DADADA';
    draggDiv_icon_staff.appendChild(draggIcon);
    container.appendChild(draggDiv_icon_staff);
  }
  
  drawCellID(container: any, row: any) {
    let draggDiv_id = document.createElement('div');
    draggDiv_id.classList.add('sub-text');
    draggDiv_id.style.width = "4vw";
    draggDiv_id.style.display = "flex";
    draggDiv_id.style.alignItems = "center";
    draggDiv_id.style.justifyContent = "flex-start";
    draggDiv_id.style.border = "1px solid #AFAFAF";
    let draggDiv_id_text = document.createElement('span');
    draggDiv_id_text.style.margin = "10px 0 10px 0";
    draggDiv_id_text.textContent = row.insstfcd ?? " ";
    draggDiv_id_text.style.width = '1rem';
    draggDiv_id_text.style.height = '1rem';
    draggDiv_id_text.style.color = '#DADADA';
    draggDiv_id.appendChild(draggDiv_id_text);
    container.appendChild(draggDiv_id);
  }
  
  drawCellName(container: any , row : any){
    let draffDiv_name = document.createElement('div');
    draffDiv_name.classList.add('sub-text');
    draffDiv_name.style.width = "14.7vw";
    draffDiv_name.style.display = "flex";
    draffDiv_name.style.alignItems = "center";
    draffDiv_name.style.border = "1px solid #AFAFAF";

    let draggedLabel_name = document.createElement('span');
    draggedLabel_name.style.color = "#DADADA"
    draggedLabel_name.style.margin = "10px 0 10px 0";
    draggedLabel_name.textContent = row.fullName ?? " ";
    draggedLabel_name.style.display = "block";
    draggedLabel_name.style.textAlign = "left";
    draggedLabel_name.style.padding = " 0 0 0 20px ";
    draggedLabel_name.style.fontWeight = "500px";
    draffDiv_name.appendChild(draggedLabel_name);
    container.appendChild(draffDiv_name);
  }
  
  drawCellEmail(container: any , row : any){
    let draffDiv_email = document.createElement('div');
    draffDiv_email.classList.add('sub-text');
    draffDiv_email.style.width = "15vw";
    draffDiv_email.style.display = "flex";
    draffDiv_email.style.alignItems = "center";
    draffDiv_email.style.border = "1px solid #AFAFAF";
    
    let draggedLabel_email = document.createElement('span');
    draggedLabel_email.style.color = "#DADADA";
    draggedLabel_email.style.margin = "10px 0 10px 0";
    draggedLabel_email.textContent = row.email ?? " ";
    draggedLabel_email.style.display = "block";
    draggedLabel_email.style.textAlign = "left";
    draggedLabel_email.style.padding = " 0 0 0 20px ";
    draggedLabel_email.style.fontWeight = "500px";
    draffDiv_email.appendChild(draggedLabel_email);
    container.appendChild(draffDiv_email);
  }
  
  drawCellPhoneNumber(container: any , row : any){
    let draffDiv_phone= document.createElement('div');
    draffDiv_phone.classList.add('sub-text');
    draffDiv_phone.style.width = "8vw";
    draffDiv_phone.style.display = "flex";
    draffDiv_phone.style.alignItems = "center";
    draffDiv_phone.style.border = "1px solid #AFAFAF";
    
    let draggedLabel_phone = document.createElement('span');
    draggedLabel_phone.textContent = row.phone ?? " ";
    draggedLabel_phone.style.margin = "10px 0 10px 0";
    draggedLabel_phone.style.color = "#DADADA";
    draggedLabel_phone.style.display = "block";
    draggedLabel_phone.style.textAlign = "left";
    draggedLabel_phone.style.padding = " 0 0 0 20px ";
    draggedLabel_phone.style.fontWeight = "500px";
    draffDiv_phone.appendChild(draggedLabel_phone);
    container.appendChild(draffDiv_phone);
  }
  
  drawCellGroup(container: any , row : any){
    let draffDiv_group= document.createElement('div');
    draffDiv_group.classList.add('sub-text');
    draffDiv_group.style.width = "11vw"
    draffDiv_group.style.color = "#DADADA";
    draffDiv_group.style.display = "flex";
    draffDiv_group.style.alignItems = "center";
    draffDiv_group.style.justifyContent = "flex-start";
    draffDiv_group.style.border = "1px solid #AFAFAF";
    
    let draggedLabel_group = document.createElement('span');
    draggedLabel_group.style.margin = "10px 0 10px 0";
    draggedLabel_group.textContent = row.teamName ?? " ";
    draggedLabel_group.style.display = "block";
    draggedLabel_group.style.textAlign = "left";
    draggedLabel_group.style.padding = " 0 0 0 20px ";
    draggedLabel_group.style.fontWeight = "500px";
    draffDiv_group.appendChild(draggedLabel_group);
    container.appendChild(draffDiv_group);
  }
  
  drawCellRole(container: any , row : any){
    let draffDiv_roll= document.createElement('div');
    draffDiv_roll.classList.add('sub-text');
    draffDiv_roll.style.width = "11vw"
    draffDiv_roll.style.color = "#DADADA";
    draffDiv_roll.style.display = "flex";
    draffDiv_roll.style.alignItems = "center";
    draffDiv_roll.style.border = "1px solid #AFAFAF";

    let draggedLabel_roll = document.createElement('span');
    draggedLabel_roll.style.margin = "10px 0 10px 0";
    draggedLabel_roll.textContent = row.roleName ?? " ";
    draggedLabel_roll.style.display = "block";
    draggedLabel_roll.style.textAlign = "left";
    draggedLabel_roll.style.padding = " 0 0 0 20px ";
    draggedLabel_roll.style.fontWeight = "500px";
    draffDiv_roll.appendChild(draggedLabel_roll);
    container.appendChild(draffDiv_roll);
  }
  
  drawButtonGroup(container: any) {
    let buttonGroupDiv = this.createButtonGroupDiv();
    let editButton = this.createEditButton();
    let lineDiv = this.createLineDiv();
    let deleteButton = this.createDeleteButton();
    
    buttonGroupDiv.appendChild(editButton);
    buttonGroupDiv.appendChild(lineDiv);
    buttonGroupDiv.appendChild(deleteButton);
    
    container.appendChild(buttonGroupDiv);
  
  }
  createButtonGroupDiv() {
    let buttonGroupDiv = document.createElement('div');
    buttonGroupDiv.className = 'button-group';
    buttonGroupDiv.style.color = "#DADADA";
    buttonGroupDiv.style.display = "flex";
    buttonGroupDiv.style.width = "12vw"
    buttonGroupDiv.style.border = "1px solid #AFAFAF";
    buttonGroupDiv.style.justifyContent = "center";
    buttonGroupDiv.style.padding = "10px";
    return buttonGroupDiv;
  }

  createEditButton() {
    let editButton = document.createElement('div');
    editButton.style.display = "flex";
    editButton.style.justifyContent = "flex-start"; 
    let iconImg_edit = document.createElement('img');
    iconImg_edit.setAttribute('src', '../../assets/icons/edit_pan_white.svg');
    iconImg_edit.style.width = '20px'; 
    iconImg_edit.style.height = '20px';
    let edit_text_button = document.createElement('span');
    edit_text_button.style.margin = "px 0 10px 0";
    edit_text_button.style.justifyContent = "flex-start"; 
    edit_text_button.style.color = "#DADADA";
    edit_text_button.textContent = this.buttonText.EDIT;
    edit_text_button.style.marginLeft = "-10px"
    editButton.appendChild(iconImg_edit);
    editButton.appendChild(edit_text_button);
    return editButton;
  }

  createLineDiv() {
    let lineDiv = document.createElement('div');
    lineDiv.classList.add('line');
    lineDiv.style.width = "2px";
    lineDiv.style.backgroundColor = "#fff";
    lineDiv.style.margin = "0px 10px 0px 10px";
    return lineDiv;
  }

  createDeleteButton() {
    let deleteButton = document.createElement('div');
    deleteButton.style.display = "flex";
    let iconImg_delete = document.createElement('img');
    iconImg_delete.setAttribute('src', '../../assets/icons/trash_white.svg');
    iconImg_delete.style.width = '20px'; 
    iconImg_delete.style.height = '20px';
    let delete_text_button = document.createElement('span');
    delete_text_button.style.color = "#DADADA";
    delete_text_button.textContent = this.buttonText.DELETE;
    delete_text_button.style.marginLeft = "-10px"
    deleteButton.appendChild(iconImg_delete);
    deleteButton.appendChild(delete_text_button);
    return deleteButton;
  }

  checkRowSeleted(rowData: any) {
    // -1 = no found
    // >= 0 - index/postion
    if(!rowData) return;
    const index = this.selectedRowsToDrag.findIndex((selectedRowId: string) => selectedRowId === rowData.id);
    if (index >= 0) {
      this.selectedRowsToDrag.splice(index, 1);
      return;
    } 
    this.selectedRowsToDrag.push(rowData.id);
    this.rowSeletedObject.push(rowData)
  }

  selectedRowCheck(row: any){
    return this.selectedRowsToDrag.some(x=>x === row.id);
  }

  setCheckedAll() {
    switch (this.checkAllMode) {
      case CheckAllMode.SINGLE_PAGE:
        this.checkedAll = this.bodyData.every(item => item.checked);
        break;

      case CheckAllMode.ALL_PAGE:
        this.checkedAll = this.localDataRecords.every(item => item.checked);
        break;
      default:
        break;
    }
  }

  getCheckedRows() {
    const checkedRows = this.localDataRecords.filter(r => r.checked === true);
    let selectedRecords = cloneDeep(checkedRows);
    selectedRecords.forEach(item => { delete item.checked; });
    return selectedRecords;
  }

  setCheckboxValue(value: boolean) {
    switch (this.checkAllMode) {
      case CheckAllMode.SINGLE_PAGE:
        this.bodyData.forEach(item => item.checked = value);
        break;

      case CheckAllMode.ALL_PAGE:
        this.selectedRows = [];
        this.localDataRecords.forEach(item => item.checked = value);
        break;

      default:
        break;
    }
  }

  checkRow(rowData: any) {
    rowData.checked = !rowData.checked;
    this.setCheckedAll();
    this.checkedAll = this.localDataRecords.every(item => item.checked);
    if(!rowData.checked) { 
      delete rowData.checked;
      let selectedIndex = this.selectedRows?.findIndex((s: any) => isEqual(rowData, s));
      if (selectedIndex != -1) {
        this.selectedRows?.splice(selectedIndex, 1);
      }
    }
    else {
      if (this.checkAllMode == CheckAllMode.ALL_PAGE) this.selectedRows = [];
      this.setSelectedRows();
    }
    this.checkRows.emit({ "data": this.selectedRows });
  }

  setSelectedRows() {
    this.localDataRecords.forEach(record => {
      if(record.checked) {
        let selectedRow = cloneDeep(record)
        delete selectedRow.checked;
        this.selectedRows.push(selectedRow);
      }
      else {
        delete record.checked;
        let selectedIndex = this.selectedRows?.findIndex((s: any) => isEqual(record, s));
        if (selectedIndex != -1) {
          this.selectedRows?.splice(selectedIndex, 1);
        }
      }
    })

    let uniqueSelectedRows= [];
    uniqueSelectedRows = this.selectedRows.filter((value: any, index, self) =>
      index === self.findIndex((t: any) => (
        isEqual(value, t)
      ))
    );
    this.selectedRows = uniqueSelectedRows;
  }

  onClickRow(rowData: any, columnIndex: number) {
    this.isShowFilterPopup = false;
    this.isShowSortPopup = false;
    let selectedRow = cloneDeep(rowData);
    this.selectedRow = cloneDeep(rowData);
    delete selectedRow.checked;
    this.clickRow.emit(selectedRow);
  }
  
  setSelectedRowStyle(rowData: any) {
    let sourceCompare = cloneDeep(rowData);
    let destinationCompare = cloneDeep(this.selectedRow);

    if(sourceCompare && sourceCompare.hasOwnProperty("checked")){
      delete sourceCompare.checked;
    }
    if(destinationCompare && destinationCompare.hasOwnProperty("checked")){
      delete destinationCompare.checked;
    }
    return this.config.isSelectedRow && isEqual(sourceCompare, destinationCompare)
  }

  showSortPopup() {
    this.isShowFilterPopup = false;
    this.isShowSortPopup = true;
  }

  showFilterPopup() {
    this.isShowSortPopup = false;
    this.isShowFilterPopup = true;
  }

  paginateData(event: any) {
    if (this.saucerLogPagingAction) {
      let oldPage = this.paginateParams, newPage = event;
      if (oldPage.currentPage !== newPage.currentPage || oldPage.pageSize !== newPage.pageSize ) {
        const content = {
          old: { currentPage: oldPage?.currentPage, pageSize: oldPage?.pageSize },
          new: { currentPage: newPage?.currentPage, pageSize: newPage?.pageSize }
        }    
        this.saucerLogService.action(
          {
            content: JSON.stringify(content)
          }, 
          {
            action: this.saucerLogPagingAction
          }
        );
      }
    }

    this.paginateParams = event;
    this.totalRecord = this.localDataRecords.length;
    this.bodyData = this.localDataRecords.slice((this.paginateParams.currentPage - 1) * this.paginateParams.pageSize,
      (this.paginateParams.currentPage * this.paginateParams.pageSize));

      this.setCheckedAll();
    this.pageChange.emit(event);
  }

  setSortField(event: any) {
    this.sortField.field = event[0].value;
    this.sortField.dataType = this.headerData.filter(h => h.field === this.sortField.field)[0]?.dataType;
  }

  sortModeChange(mode: SortMode) {
    if (!mode) { //Ascending mode
      this.isAscending = true;
      this.isDescending = false;
    } else { //Descending mode
      this.isAscending = false;
      this.isDescending = true;
    }
    this.sortMode = mode;
    this.sortData();
  }

  sortData() {
    this.localDataRecords.sort((item1, item2) => {
      switch (this.sortField.dataType) {
        case DataType.DATETIME:
          return this.sortMode ? Date.parse(item2[this.sortField.field]) - Date.parse(item1[this.sortField.field]) :
            Date.parse(item1[this.sortField.field]) - Date.parse(item2[this.sortField.field]);
        case DataType.NUMBER:
          return this.sortMode ? item2[this.sortField.field] - item1[this.sortField.field] :
            item1[this.sortField.field] - item2[this.sortField.field];
        default: //Sort method for string
          return this.sortMode ? item1[this.sortField.field].localeCompare(item2[this.sortField.field]) :
            -(item1[this.sortField.field].localeCompare(item2[this.sortField.field]));
      }
    });
    //Reset pagination
    this.paginateData(this.paginateParams);
  }

  clickSortIcon(field: string) {
    this.isAscending = !this.isAscending;
    this.sortField.field = field;
    this.sortField.dataType = this.headerData.filter(h => h.field === this.sortField.field)[0]?.dataType;
    this.sortIconCount++;
    if (this.isAscending) {
      this.sortIcon = "pi-sort-amount-up-alt";
      this.sortMode = SortMode.ASCENDING;
    }
    else {
      this.sortIcon = "pi-sort-amount-down";
      this.sortMode = SortMode.DECENDING;
    }
    this.sortData();
  }

  setFilterField(event: any) {
    this.filterField = event[0].value;
    this.filterFields = [...new Set(this.dataRecords.map(item => {
      return {
        'code': item[this.filterField],
        'name': this.headerData.filter(header => header.field === this.filterField && header.dataType === this.dataType.DATETIME).length ?
          this.formatDate(item[this.filterField], item, this.filterField) : item[this.filterField]
      }
    })
    )];

    let checkedFields = [...new Set(this.localDataRecords.map(item => {
      return {
        'code': item[this.filterField],
        'name': this.headerData.filter(header => header.field === this.filterField && header.dataType === this.dataType.DATETIME).length ?
          this.formatDate(item[this.filterField], item, this.filterField) : item[this.filterField]
      }
    })
    )];
    this.selectedFilterField = checkedFields;
  }

  filterData(isObject: boolean) {
    if (!this.selectedFilterField)
      this.localDataRecords = this.dataRecords;
    else
      this.localDataRecords = this.dataRecords.filter(item => !isObject ? this.selectedFilterField.includes(item[this.filterField]) :
        this.selectedFilterField.filter(filter => filter.code === item[this.filterField]).length > 0);
    if (this.localDataRecords.length == this.dataRecords.length)
      this.isFilterAll = true;
    else this.isFilterAll = false;
    this.paginateData(this.paginateParams);
  }

  clickFilterIcon(field: string, event: any) {
    this.isShowFilterPopup = true;
    this.filterField = field;
    let windowWidth = window.innerWidth;
    let clickPos = event.clientX;
    let tableElement = document.getElementById(this.tableId);
    let filterPopup = tableElement?.getElementsByClassName(`filter-popup-${field}`)[0] as HTMLElement;
    if (filterPopup) {
      if (clickPos + 260 > windowWidth) { //260 is width of filter-control-popup
        filterPopup.style.right = '0px';
      }
    }
    this.filterFields = [...new Set(this.dataRecords.map(item => {
      return {
        'code': item[this.filterField],
        'name': this.headerData.filter(header => header.field === this.filterField && header.dataType === this.dataType.DATETIME).length ?
          this.formatDate(item[this.filterField], item, this.filterField) : item[this.filterField]
      }
    })
    )];

    let checkedFields = [...new Set(this.localDataRecords.map(item => {
      return {
        'code': item[this.filterField],
        'name': this.headerData.filter(header => header.field === this.filterField && header.dataType === this.dataType.DATETIME).length ?
          this.formatDate(item[this.filterField],item, this.filterField) : item[this.filterField]
      }
    })
    )];
    this.selectedFilterField = checkedFields;
    if (this.filterFields.length == checkedFields.length) this.isFilterAll = true;
  }

  resetAll() {
    this.isShowFilterPopup = false;
    this.isShowSortPopup = false;
    this.localDataRecords = this.dataRecords;
    this.isAscending = false;
    this.isDescending = false;
    this.sortField = {};
    this.selectedFilterField = [];
    this.filterField = "";
    this.paginateData(this.paginateParams);
  }

  formatDate(value: string,row :any, field: string) {
    if (!value) return '';
    if(row.formattype){
     return beautify(value, row.formattype);
    }
    if(field == "info_zenkai") {
      //Column "info_zenkai" formatDate YYYY/MM
      return moment.utc(value).format(DateFormat.YEAR_MONTH);
    }
    return moment.utc(value).format(DateFormat.FULL_SHORT_DATE);
  }

  formatNumber(value: string) {
    if (value === null || value === undefined) return '';
    if (value.toString().trim() === '') return '';
    return Number.parseFloat(value).toLocaleString();
  }

  formatDateForWidgetType(value: string) {
    if (!value) return '';
    let subtextDate = value?.split('~');
    if(subtextDate.length < 2) return value;
    let endDate = subtextDate[1].length > 5 ? (moment(new Date(subtextDate[1]))).format(DateFormat.FULL_SHORT_DATE) : subtextDate[1];
    return (moment(new Date(subtextDate[0]))).format(DateFormat.FULL_SHORT_DATE).toString() +' ~ '+ endDate
  }

  getTitlePublishWidget(value: string): string {
    let dateArray = value?.split(' ~ ');
    if (dateArray && dateArray.length < 2) return "非公開";
    let publicSDate = dateArray[0];
    let publicEDate = dateArray[1] == '未定' ? '' : dateArray[1];
    return isPubishWidget(publicSDate, publicEDate) ? "公開" : "非公開";
  }

  setRowIndex(rowIndex: number) {
    if (this.config.showIndex) {
      if (Object.keys(this.paginateParams).length)
        return ((this.paginateParams.currentPage - 1) * this.paginateParams.pageSize) + (rowIndex + 1)

      return rowIndex + 1;
    }
    return;
  }

  findRowIndex(element: any) {
    return (this.bodyData?.indexOf(element) || 0) * 100;
  }
  

  ngOnChanges(changes: SimpleChanges) {
    if (this.checkAllMode == CheckAllMode.ALL_PAGE) {
      if (changes['data'] && changes['data'].currentValue) {
        this._data = changes['data'].currentValue;
        this.selectedRows =  changes['data'].currentValue.body.filter((x : any) => x.checked);
        this.initData();
      }
    }
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  inputFocusOut(event: any, field: any, rowData: any) {
    const tabindex = event.tabIndex;
    this.cellFocusOut.emit({ value: rowData, row: field , event: event });
    if(this.key == 'Tab') {
      if (!isNaN(tabindex)) {
        setTimeout(() => {
          const nextElement: any = document.querySelector(`[tabindex="${tabindex + 1}"]`);
          if (nextElement) {
            if(nextElement.tagName.toUpperCase()==='P-DROPDOWN'){
              nextElement.childNodes[0].click();
              return;
            }
            nextElement.focus();
          }
          else {
            const nextElement: any = document.querySelector(`[tabindex="${tabindex + 100 - 8}"]`);
            if (nextElement) {
              if(nextElement.tagName.toUpperCase()==='P-DROPDOWN'){
                nextElement.childNodes[0].click();
                return;
              }  
              nextElement.focus();
            }
          }
        }, 100);
      }
      this.key = '';
    }
  }

  inputChange(event: any, field: any, rowData: any) {
    let trimmed = event.value;
    if (StringUtils.checkValidateInput(event.maxLength, trimmed) && StringUtils.checkValidateInput((trimmed) ? trimmed.length : 1, trimmed)) {
      event.value = trimmed;
    } else {
      event.value = event.value;
    }
  }

  inputFocus(event: any, field: any, rowData: any) {
    event.select()
    this.cellFocusIn.emit({event: event, value: rowData, row: field })
  }
  onBlurDropDown(event: any, field: any, rowData: any){
    event.closest('p-dropdown').focus();
  }
  onChangeDropDown(field: any, rowData: any){
    this.cellDropdownChange.emit({ value: rowData, row: field});
  }


  inputKeyUp(event: any, field: any, rowData: any) {
    if(event.key === "Enter" || event.code === "Enter") {
      const tabindex = event.target?.tabIndex;
      this.cellFocusOut.emit({ value: rowData, row: field , event: event.target });
      if (!isNaN(tabindex)) {
        setTimeout(() => {
          const nextElement: any = document.querySelector(`[tabindex="${tabindex + 1}"]`);
          if (nextElement) {
            if(nextElement.tagName.toUpperCase()==='P-DROPDOWN'){
              nextElement.childNodes[0].click();
              return;
            }
            nextElement.focus();
          }
          else {
            const nextElement: any = document.querySelector(`[tabindex="${tabindex + 100 - 8}"]`);
            if (nextElement) {
              if(nextElement.tagName.toUpperCase()==='P-DROPDOWN'){
                nextElement.childNodes[0].click();
                return;
              }
              nextElement.focus();
            }
          }
        }, 100);
      }
    }
  }

  inputKeyDown(event: any) {
    this.key = event.key
  }

  getCellsSpan = () => {
    let totalDayValue = this.headerData.filter(ele => ele.field.toUpperCase() === "TOTALDAYS");
    if(totalDayValue.length > 0 ) {
      this.totalDayDetailValues =  {
        key: this.totalDayDetailValues.key,
        data: totalDayValue[0].attribute["childrenCols"] ? 
        totalDayValue[0].attribute["childrenCols"].sort((a: any, b: any) => {
        const numberA = parseInt(a.field.split("_")[1]);
        const numberB = parseInt(b.field.split("_")[1]);
        return numberA - numberB;
      }) : []
      }
      this.isHasMergeCell = true
    }
  }
  
  sortTTDCells() {
    const ttdCellsList = this.headerData.filter(obj => obj.field.toUpperCase().startsWith('TTD_'));
    ttdCellsList.sort((a, b) => {
      const numberA = parseInt(a.field.split('_')[1]);
      const numberB = parseInt(b.field.split('_')[1]);
      return numberA - numberB;
    });

    this.fillTDDToHeader(ttdCellsList);
  }

  fillTDDToHeader(arr: any) {
    return this.headerData =  this.headerData.map(obj => {
      if (obj.field.toUpperCase().startsWith('TTD_')) {
        return arr.shift();
      }
      return obj;
    });
  }
  checkField(field: any, rowData: any, rowIndex: number) {
    rowData[field] = !rowData[field];
    this.data.body[rowIndex][field] = rowData[field];
    this.bodyData[rowIndex][field] = rowData[field];
    this.checkFieldEvent.emit({ "data": this.bodyData });
    this.setCheckedAllField(field);
  }
  setCheckedAllField(field: any) {
    var fieldHeader = this.headerData.find(item => item.field == field)
    if(fieldHeader){
      fieldHeader.checkedAll = this.bodyData.every(item => item[field] === true);
    }
  }
  checkAllField(field: any){
    var fieldHeader = this.headerData.find(item => item.field == field);
    if(fieldHeader){
      fieldHeader.checkedAll = !fieldHeader.checkedAll;
      this.bodyData.map(item => item[field] = fieldHeader.checkedAll);
      this.checkFieldEvent.emit({ "data": this.bodyData });
    }
    
  }

  onEditDataRowByBtn(event: any) {
    this.onEditDataRow.emit(event);
  }

  onEditDeleteRowByBtn(event: any) {
    this.onDeleteDataRow.emit(event);
  }
}
