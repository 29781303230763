<div class="item">
  <!-- check box parent -->
  <pivot-tree-node
    [isTemplate]="isTemplate"
    [item]="params"
    [expanded]="true"
    [enableCheckbox]="enableCheckbox"
    [firstNode]="true"
    [isFirstNodeChecked]="isFirstNodeChecked"
    [expandAll]="params?.expanded || false"
    (isOnFetchData)="onFetchData($event)"
    (nodeMouseClick)="childItemHandler($event)"
    (nodeMouseDoubleClick)="childItemDoubleClickHandler($event)"
    (nodeDragStart)="childNodeDragStart($event)"
    (nodeDragEnd)="childNodeDragEnd($event)"
    [dragScope]="params?.dragScope || ''"
    [expandIcon]="params?.expandIcon || ''"
    [collapseIcon]="params?.collapseIcon || ''"
    [selectable]="params?.selectable || false"
    [hoverable]="params?.hoverable || false"
    [isMultiDragItem]="params?.isMultiDragItem || false"
    [isDisplayTooltip]="params?.isDisplayTooltip || false"
    (nodeCheckboxChange)="childNodeCheckboxChange($event)"
    (scrollToBottom)="infiniteScroll($event)"
    (onDeleteNode)="deleteNode($event)">
  </pivot-tree-node>
</div>
