import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ProcessLoadingService } from '../../../app/services/loading.service';
import { IDeviceType, DetectDeviceService } from '../../../app/services/detect-device.service';
import { DeviceType } from '../../enum/common-enum';
import { LOCK_ACCOUNT } from '../../../app/const/text-common';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'pivot-lock-account',
  templateUrl: './lock-account.component.html',
  styleUrls: ['./lock-account.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class LockAccountComponent implements OnInit {

  deviceType!: string;
  DeviceType = DeviceType;
  LOCK_ACCOUNT = LOCK_ACCOUNT

  constructor(  
    private processLoadingService: ProcessLoadingService,
    private detectDeviceService: DetectDeviceService) { 
    this.detectDeviceService.currentDevice.subscribe((device: IDeviceType) => {
			if (device) this.deviceType = device.type;
		});
  }

  ngOnInit(): void {
    this.processLoadingService.isLoading.emit(false);
  }

  resetPassword() {
    window.location.href = environment.CKPASS_FORGOT_PW;
  }

}
