import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { InputParams } from 'src/app/models/common-model';

@Component({
  selector: 'pivot-auto-complete',
  templateUrl: './auto-complete.component.html',
  styleUrls: ['./auto-complete.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AutoCompleteComponent implements OnInit, OnChanges {
  @Input() data: any[];
  @Input() field: string;
  @Input() disabled: boolean = false;
  @Input() isError: boolean = false;
  @Input() required: boolean = false;
  @Input() maxLength: number | undefined;
  @Output() onChangeData: any = new EventEmitter<any>();
  @Output() onSelectData: any = new EventEmitter<any>();
  @Output() onUnSelectData: any = new EventEmitter<any>();
  @Input() excludeSearchData: any[] = [];
  @Input() selectedData: any[] = [];
  @Input() inputParams: InputParams = new InputParams();
  filteredData: any[];
  maxLengthValue: number;
  constructor() {
    this.maxLengthValue = this.maxLength ? this.maxLength : 100;
  }
  ngOnChanges(changes: SimpleChanges): void {
  }

  ngOnInit(): void {
    this.field = this.field ? this.field : "name";
    this.data = this.data && this.data?.length > 0 ? this.data : [];
  }

  filterData(event: any) {
    let filtered: any[] = [];
    let query = event.query;
    for (let i = 0; i < this.data?.length; i++) {
      let dataFilter = this.data[i];
      if (this.excludeSearchData.every(d => d[this.field]?.toLowerCase()?.trim() !== dataFilter[this.field]?.toLowerCase()?.trim()) && 
          dataFilter[this.field]?.toLowerCase()?.trim().includes(query?.toLowerCase()?.trim())) {
        filtered.push(dataFilter);
      }
    }

    this.filteredData = filtered;
  }

  dataChange(event: any) {
    this.onChangeData.emit(event);
  }

  unselectData(event: any) {
    this.onUnSelectData.emit(event);
  }

  selectData(event: any) {
    this.onSelectData.emit(event);
  }
}
