import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ResponseData } from '../../../app/models/api-model';
import { Folder, FOLDER_TYPE } from '../../../app/models/response/folder.ro';
import { Assign } from '../../../app/models/response/assign.ro';
import { API_APP, PIVOT_CLIENT_API_URL } from '../../config/app.config';
import { ApiService } from '../api.service';
import { FolderAddDTO } from '../../../app/models/request/folder.dto';
import {ErrorHandleService} from '../error-handle.service';
import { SAUCER_LOG_ACTION, SaucerLogService } from '../saucer-logs/saucer-log.service';



@Injectable({ providedIn: 'root' })
export class FolderService extends ApiService {
  private folderCd: any = null;
  public sessionFolderData: any = null;
  public sessionFolderAction: any = null;

  constructor(
    http: HttpClient, 
    router: Router, 
    errorHandle: ErrorHandleService,
    private saucerLogService : SaucerLogService,
  ) {
    super(http, router, errorHandle);
  }


  getAll() {
    let apiUrl = API_APP.FOLDER.CONTROLLER + API_APP.FOLDER.ALL;
    let resData = this.get(apiUrl);
    return this.mapModel(resData);
  }

  setFolderCd(folderCd: any) {
    this.folderCd = folderCd;
  }

  getFolderCd() {
    return this.folderCd;
  }

  getActionLogByCode(code: string, isTemPlate: boolean){
    let actionLog;
    switch (Number(code)) {
      case FOLDER_TYPE.Datasource:
        actionLog = isTemPlate ? SAUCER_LOG_ACTION.DATASOURCE_LIST_TEMPLATE : SAUCER_LOG_ACTION.DATASOURCE_LIST;
        break;
      case FOLDER_TYPE.Dashboard:
        actionLog = isTemPlate? SAUCER_LOG_ACTION.DASHBOARD_TEMPLATE_LIST : SAUCER_LOG_ACTION.DASHBOARD_LIST;
        break;
      case FOLDER_TYPE.Widget:
        actionLog = isTemPlate ? SAUCER_LOG_ACTION.WIDGET_LIST_TEMPLATE : SAUCER_LOG_ACTION.WIDGET_LIST;
        break;
      default:
        actionLog = isTemPlate ? SAUCER_LOG_ACTION.DATASOURCE_LIST_TEMPLATE : SAUCER_LOG_ACTION.DATASOURCE_LIST;
        break;
    }
    return actionLog
  }

  async getByType(code: string, isSupporterOrAdmin?: boolean) {
    let parameter = [
      code,
      (isSupporterOrAdmin) ? isSupporterOrAdmin : false
    ];
    let apiUrl = API_APP.FOLDER.CONTROLLER + API_APP.FOLDER.GETBYTYPE;
    let resData = await this.get(apiUrl, parameter);
    if (resData?.statuscode === 200) {
      this.sessionFolderAction = this.getActionLogByCode(code, false);
      this.sessionFolderData = resData?.data || [];
    } 
    return await this.mapModel(resData);
  }

  async getFolderTemplateByType(code: string) {
    let parameter = [
      code
    ];
    let apiUrl = API_APP.FOLDER.CONTROLLER + API_APP.FOLDER.GETBYTYPE + "/" + code + API_APP.FOLDER.TEMPLATE;
    let resData = await this.get(apiUrl);
    if (resData?.statuscode === 200) {
      this.sessionFolderAction = this.getActionLogByCode(code, true);
      this.sessionFolderData = resData?.data || [];
    }
    return await this.mapModel(resData);
  }

  async getDatasourceFolderStructByLevel(datasourceCds: string[], level: number, isSupporterAdmin: boolean, isTemplate: boolean)
  {
    let parameter = {
      datasourceCds: datasourceCds.join("-"),
      level,
      isSupporterAdmin,
      isTemplate,
    };
    let apiUrl = API_APP.FOLDER.CONTROLLER + API_APP.FOLDER.GETBYLEVEL;
    let resData = await this.getByParameter(apiUrl, parameter);

    return resData.statuscode !== 200 ? [] : resData.data ?? [];
  }
  async getStructByKeywork(keywork: string, isSupporterAdmin: boolean, isTemplate: boolean)
  {
    let parameter = {
      keywork,
      isSupporterAdmin,
      isTemplate
    };
    let apiUrl = API_APP.FOLDER.CONTROLLER + API_APP.FOLDER.GETSTRUCTBYKEYWORK;
    let resData = await this.getByParameter(apiUrl, parameter);

    return resData.statuscode !== 200 ? [] : resData.data ?? [];
  }

  async mapModel(data: any) {
    let res: ResponseData<Folder[]> = new ResponseData<Folder[]>();
    let dataArr = [];
    res.statuscode = data.statuscode;
    res.statusmessage = data.statusmessage;
    if (res.statuscode === 200) {
      for await (const element of data.data) {
        let folder = new Folder();
        let assign = new Assign()
        folder.id = element.id;
        folder.folderCd = element.foldercd;
        folder.description = element.folderdesc;
        folder.folderType = element.foldertype;
        folder.name = element.foldername;
        folder.folderName = element.foldername;
        folder.insdate = element.insdate;
        folder.insstfcd = element.insstfcd;
        folder.upddate = element.upddate;
        folder.updstfcd = element.updstfcd;
        assign.AssignId = element.assigncd;
        assign.assignCd = element.assigncd;
        assign.act = element.act;
        assign.teamCd = element.teamcd;
        assign.staffCd = element.staffcd;
        folder.assign = assign;
        folder.istemplate = element.istemplate;
        folder.assigntype = element.assigntype
        folder.act = element.act;
        folder['name-subText'] = element.folderdesc;
        dataArr.push(folder);
      }
    }
    res.data = dataArr;
    return res;
  }

  async insertOrUpdate(request: any, actionOutSideLog: any = null, assignSaucerLog: any = null) {
    let uri = API_APP.FOLDER.CONTROLLER + API_APP.FOLDER.INSERTORUPDATE;
    const apiPath = uri.replace(PIVOT_CLIENT_API_URL, "");
    let resData = await this.post(uri, [], request);
    let actionLog = actionOutSideLog ? actionOutSideLog : this.sessionFolderAction;
    const ids = [...request.foldes].map(item => item.foldercd);
    const oldUpdateDatas = [...this.sessionFolderData || []].filter((item: any) => ids.includes(item?.foldercd)) || [];
    const logCreate = {
      old: null,
      new: {
        foldercd: request?.foldes[0]?.foldercd,
        foldername: request?.foldes[0]?.foldername,
        folderdesc: request?.foldes[0]?.folderdesc,
        assigncd: request?.foldes[0]?.assigncd,
        assigntype: request?.foldes[0]?.assigntype,
        access: assignSaucerLog?.newAssign?.accessData,
        assignList: assignSaucerLog?.newAssign?.assignList
      }
    };
    const logUpdate = {
      old: (oldUpdateDatas.length > 0) && {
        foldercd: oldUpdateDatas[0]?.foldercd,
        foldername: oldUpdateDatas[0]?.foldername,
        folderdesc: oldUpdateDatas[0]?.folderdesc,
        assigncd: oldUpdateDatas[0]?.assigncd,
        assigntype: oldUpdateDatas[0]?.assigntype,
        access: assignSaucerLog?.oldAssign?.accessData,
        assignList: assignSaucerLog?.oldAssign?.assignList
      },
      new: {
        foldercd: request?.foldes[0]?.foldercd,
        foldername: request?.foldes[0]?.foldername,
        folderdesc: request?.foldes[0]?.folderdesc,
        assigncd: request?.foldes[0]?.assigncd,
        assigntype: request?.foldes[0]?.assigntype,
        access: assignSaucerLog?.newAssign?.accessData,
        assignList: assignSaucerLog?.newAssign?.assignList
      }
    };

    if (resData?.statuscode === 200) {
      this.saucerLogService.system(
        {
          apiPath,
          statusCode: resData.statuscode,
          body: JSON.stringify(request),
          content: !request.isUpdate ? JSON.stringify(logCreate) : JSON.stringify(logUpdate)
        }, 
        { 
          action: !request.isUpdate ? actionLog?.CREATE_FOLDER : actionLog?.UPDATE_FOLDER 
        }
      );
    } else {
      this.saucerLogService.error(
        {
          apiPath,
          statusCode: resData.statuscode,
          body: JSON.stringify(request),
          errorMessage: resData.statusmessage,
          content: !request.isUpdate ? JSON.stringify(logCreate) : JSON.stringify(logUpdate)
        }, 
        { 
          action: !request.isUpdate ? actionLog?.CREATE_FOLDER : actionLog?.UPDATE_FOLDER 
        }
      );
    }

    return resData;
  }

  async delete(folders: FolderAddDTO[]) {
    let uri = API_APP.FOLDER.CONTROLLER + API_APP.FOLDER.DELETE;
    const apiPath = uri.replace(PIVOT_CLIENT_API_URL, "");
    let resData = await this.post(uri, [], folders);
    const contentLog = folders?.map((x:any)=>({
      folderCd: x.folderCd, 
      folderType: x.folderType,
      folderName: x.folderName || x.name,
      assigntype: x.assigntype,
      assign: x.assign
    }));
    if (resData?.statuscode === 200) {
      this.saucerLogService.system({
        apiPath,
        body: resData,
        statusCode: resData.statuscode,
        content: JSON.stringify({
          old: contentLog,
          new: null
        })
      }, { action: this.sessionFolderAction?.DELETE_FOLDER });
    } else {
      this.saucerLogService.error({
        apiPath,
        body: resData,
        statusCode: resData?.statuscode,
        content: JSON.stringify({
          old: contentLog,
          new: null
        })
      }, { action: this.sessionFolderAction?.DELETE_FOLDER });
    }

    return resData;
  }

  async getDashboardFolder(isSupporterOrAdmin?: boolean) {
    let parameter = [
      (isSupporterOrAdmin) ? isSupporterOrAdmin : false
    ];
    let apiUrl = API_APP.FOLDER.CONTROLLER + API_APP.FOLDER.GET_DASHBOARD_FOLDER;
    let resData = await this.get(apiUrl, parameter);
    return await this.mapModel(resData);
  }

  async getListFolderHavePermissionEdit(code: string, isSupporterOrAdmin?: boolean) {
    let parameter = [
      code,
      (isSupporterOrAdmin) ? isSupporterOrAdmin : false
    ];
    let apiUrl = API_APP.FOLDER.CONTROLLER + API_APP.FOLDER.GET_LIST_FOLDER_EDIT
    let resData = await this.get(apiUrl, parameter);
    return await this.mapModel(resData);
  }
}

