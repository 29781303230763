<div class="container-login">
    <div class="box" [ngClass]="{ 'box-mobile':  deviceType == DeviceType.MOBILE || deviceType == DeviceType.TABLET }">
        <div class="title">
            <img src="assets/images/pivotlogo_login.svg">
        </div>
        <div class="content">
            <form [formGroup]="loginForm" (ngSubmit)="onSubmit()">
                <div class="login-title" [ngClass]="{'macos-font': currentOS === OS.Mac, 'windows-font': currentOS === OS.Windows}">{{LOGIN.LOGIN_TITLE}}</div>
                <div>
                    <label class="error-msg"
                        *ngIf="(!loginForm.controls['username'].valid && loginForm.controls['username'].dirty) && (!loginForm.controls['password'].valid && loginForm.controls['password'].dirty) || inError">{{errorMessage}}</label><br>
                    <label class="id-label">{{LOGIN.ID_LBL}}</label>
                    <span class="p-float-label">
                        <input type="email" class="text" formControlName="username" size="30" pInputText [maxlength]="emailMaxLength" (change)="inputOnChanges()"
                            aria-describedby="username-help"
                            (input)="onEmailInput($event)"
                            (focus)="onFocusEmailInput()"/>
                    </span>
                </div>
                <div>
                    <label class="password-label">{{LOGIN.PASSWORD_LBL}}</label>
                    <span class="p-float-label">
                        <input class="text" type="password" formControlName="password" size="30" maxlength="30"
                            pInputText aria-describedby="password-help" (beforeinput)="onFocusPassword($event)" (keypress)="onPress()" (keydown)="onDown($event)"/>
                    </span>
                </div>
                <div class="content-footer">
                    <pivot-text-button [disabled]="loginForm.invalid" type="submit" [buttonText]="BUTTON.LOGIN">
                    </pivot-text-button>
                    <div class="reset-pw" (click)="resetPassword()">{{LOGIN.RESET_PASSWORD_LBL}}</div>
                </div>
            </form>
        </div>
    </div>
</div>