import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { InputType } from '../../../../app/enum/common-enum';
import { InputParams } from '../../../../app/models/common-model';
import { v4 } from 'uuid';
import Utils from 'src/app/util/utils';
import StringUtils from 'src/app/util/stringUtils';
@Component({
  selector: 'pivot-textbox',
  templateUrl: './textbox.component.html',
  styleUrls: ['./textbox.component.scss']
})
export class TextboxComponent implements OnInit, OnChanges {

  @Input() inputParams: InputParams = new InputParams();
  // input value variables
  @Input() inputValue: any = null;
  @Input() canClear = false;
  @Output() onKeyUp = new EventEmitter<any>();
  @Output() onInput = new EventEmitter<any>();
  @Output() onKeyDown = new EventEmitter<any>();
  @Output() onClick = new EventEmitter<any>();
  @Output() onFocus = new EventEmitter<any>();
  @Output() onFocusOut = new EventEmitter<any>();
  @Output() inputValueChange = new EventEmitter();
  showValidateErr: boolean = false;
  inputType: string = 'text';
  inputEdit: boolean = false;
  // char code enter key
  enterKey: number = 13;
  telNum: string = '';
  @Input() inputId: string = '';
  @Input() textCenter: boolean = false;
  constructor() { }

  ngOnInit(): void {
    if (this.inputId === '') {
      this.inputId = v4();
    }
    this.setInputType();
  }

  ngOnChanges(changes: SimpleChanges): void {
    for (let propName in changes) {
      if (propName === 'inputParams') {
        if (this.inputParams.isError === true) {
          this.showValidateErr = true;
        } else {
          this.showValidateErr = false;
        }
      }
    }
  }

  onInputData(event: any) {
    this.onInput.emit(event)
    if (this.inputParams.type == InputType.number) {
      if (StringUtils.checkValidateInput(1, event.data) && StringUtils.checkValidateInput(this.inputParams.maxLength || 50, event.target.value)) {
        this.telNum = event.target.value;
      } else {
        event.target.value = this.telNum;
      }
      this.inputValue = this.telNum;
    }
    else {
      this.inputValue = event.currentTarget?.value;
    }
    this.inputValueChange.emit(this.inputValue);
  }

  setInputFocus() {
    let inputElement = document.getElementById(this.inputId);
    if (inputElement !== undefined) {
      inputElement?.focus();
    }
  }

  onKeyUps(event: any) {
    this.onKeyUp.emit(event);
    if (event.keyCode === this.enterKey && event.currentTarget?.value === '') {
      this.showValidateErr = true;
    }
    else {
      this.showValidateErr = false;
    }
    this.inputValue = event.currentTarget?.value;
  }

  // set type for input
  setInputType() {
    if (this.inputParams?.type === InputType.number) {
      this.inputType = 'number';
    }
    else if (this.inputParams?.type === InputType.tel) {
      this.inputType = 'tel';
    }
    else {
      this.inputType = 'text';
    }
    if (this.inputParams?.disabled || this.inputParams?.readonly) {
      this.inputParams.placeholder = '';
    }
  }

  onKeyDowns(event: any) {
    this.onKeyDown.emit(event);
    this.inputValue = event.currentTarget?.value;
  }

  onClicks(event: any) {
    this.onClick.emit(event);
  }

  onFocusIn(event: any) {
    this.onFocus.emit(event);
    this.showValidateErr = false;
    this.inputParams.isValidate = this.showValidateErr;
    this.inputValue = event.currentTarget?.value;
  }

  onFocusOutInput(event: any) {
    this.onFocusOut.emit(event);
    this.inputValue = event.currentTarget?.value.trim();
    if (!Utils.isNullOrEmpty(this.inputParams?.maxLength) && this.inputParams?.type == InputType.number) {
      this.inputValue = event.currentTarget?.value?.toString().substring(0, this.inputParams.maxLength).trim()
    }
    if (this.inputValue === '') {
      this.showValidateErr = true;
    }
    else if (this.inputParams.isError === true) {
      this.showValidateErr = true;
    }
    else {
      this.showValidateErr = false;
    }
    if (this.inputParams.pencil) {
      this.inputEdit = false;
    }
    this.inputParams.isValidate = this.showValidateErr;
  }

  // click pencil and submit data
  onClickPencil() {
    this.inputEdit = true;
    this.setInputFocus();
    if (this.empty(this.inputValue)) {
      this.showValidateErr = true;
    }
    else {
      this.showValidateErr = false;
    }
  }

  clearText() {
    this.inputValue = null
    this.inputValueChange.emit(null)
  }

  empty(value: any) {
    return value === null || value === undefined || value === ''
  }
}
