import { Component, OnInit } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { SearchParams } from '../../../app/models/common-model';
import { BUTTON, COMMON_TEXT } from '../../../app/const/text-common';
import { ButtonType, DataType, FormatType, SearchType } from '../../../app/enum/common-enum';
import { TableData } from '../../../app/models/table-model';
import { cloneDeep, orderBy } from 'lodash';

@Component({
  selector: 'pivot-dialog-filter-table',
  templateUrl: './dialog-filter-table.component.html',
  styleUrls: ['./dialog-filter-table.component.scss'],
})
export class DialogFilterTableComponent implements OnInit {

  searchParams: SearchParams = {
    type: SearchType.input,
    placeholder: COMMON_TEXT.SEARCH,
    cssStyle: { width: '100%', height: '40px' },
    readonly: false,
    disabled: false
  }
  submitData: any[] = [];
  BUTTON = BUTTON
  COMMON_TEXT = COMMON_TEXT
  data: any[] = []
  colnm: string = ''
  rowIndex: number = 0
  currentData: any[] = []
  filterModalStyle = { width: '400px', height: '300px' }
  dispalyFilterServices: any[] = []
  filterServices: any[] = []
  buttonType = ButtonType
  listServices: any[] = []
  isShowModal: boolean = false
  selectedDatas: any[] = []
  loadingTable: boolean = false
  isDisplayNumber: boolean = false
  remaningNumber: number = 0
  tableData: TableData = {
    config: {
      id: 'filter-condition',
      caption: '',
      emptyMessage: COMMON_TEXT.NO_DATA,
      showIndex: true,
      showCheckbox: true,
      hoverShowCheckbox: false,
      showPagination: false,
      isResponsive: false,
      tableStyle: {}

    },
    header: [
      {
        field: 'value',
        title: 'value',
        sortable: false,
        filterable: false,
        attribute: {
          header: { width: '80%' },
          row: {}
        },
        dataType: DataType.TEXT,
        visible: true
      },
    ],
    body: []
  }
  
  constructor(public ref: DynamicDialogRef,
    public config: DynamicDialogConfig) {
      this.config.showHeader = true;
      this.config.style = { width: '500px', height: '600px'};
      this.config.contentStyle = {'border-bottom-radius': '4px'}
    }

  ngOnInit(): void {
    this.data = this.config.data?.filterData || [];
    let datatype = "";
    let formatType = "";
    this.rowIndex = this.config.data?.rowIndex || 0;
    if(this.data[0] && this.data[0][this.rowIndex]){
      datatype = this.data[0][this.rowIndex]["datatype"]
      formatType = this.data[0][this.rowIndex]["formattype"];
    }
  
    this.colnm = this.config.data?.colnm || ''
    this.tableData.header = [
      {
        field: 'value',
        title: this.colnm,
        sortable: false,
        filterable: false,
        attribute: {
          header: { width: '80%' },
          row: {}
        },
        dataType:  this.getDataType(formatType, datatype),
        visible: true
      }
    ]
    let body: any[] = cloneDeep(this.data);
    body?.forEach((item) => {
      let selectedRows = item?.filter((i:any) => i.checked);
      selectedRows.map((s: any) => {
        let findIndex = this.selectedDatas?.findIndex(item => item.value == s.value && item.colnm == s.colnm)
        if(findIndex == -1)
          this.selectedDatas.push(s)
      })
    })
  
    this.selectedDatas.map(item => {
      delete item.checked;
      return item;
    })
    this.tableData = cloneDeep(this.tableData)
    this.currentData = cloneDeep(body)
    this.setTableData()
  }

  getDataType(formatType: any, colDatatype: any) {
    if(formatType == FormatType.Group) {
      return colDatatype === "DATETIME" ? DataType.DATETIME : DataType.TEXT;
    }
    else {
      if(colDatatype === "FLOAT" || colDatatype === "INT") return  DataType.NUMBER;
      else  return colDatatype === "DATETIME" ? DataType.DATETIME : DataType.TEXT;
    }
  }

  closeFormModal() {

  }

  onFilterOutData(filter: any) {
     if(filter) {
        this.data = this.currentData?.filter(ft => ft[this.rowIndex].value?.toUpperCase()?.trim().includes(filter.toUpperCase().trim()))
     } else {
        this.data = cloneDeep(this.currentData)
     }
     this.setTableData()
  }

  setTableData() {
    let bodyData: any[] = [];
    this.data?.forEach(d=> {
      bodyData.push(d[this.rowIndex])
    })
    
    bodyData = [...new Set(bodyData.filter((item, index, self) =>
      index === self.findIndex(t => (
        t.value === item.value
      ))
    ))];
    this.tableData.body = bodyData  
    this.tableData = cloneDeep(this.tableData)
  }

  cancel() {
    this.ref.close();
  }

  save() {
    this.ref.close(this.submitData);
  }

  checkRowsTable(rows: any) {
    this.selectedDatas = rows.data
    this.submitData = []
    this.submitData = [{ data: rows.data, index: this.rowIndex }]
    this.mapdataFilter(rows)
  }

  mapdataFilter(dataChecked: any) {
    if (dataChecked && Object.keys(dataChecked).length > 0) {
      if (dataChecked?.data?.length > 0) {
        this.data?.map(dt => {
          let findIndex = dataChecked?.data?.findIndex((s: any) =>  s.value == dt[this.rowIndex].value);
          if (findIndex != -1) {
            dt[this.rowIndex].checked = true;
          }
          else {
            let selectedIndex = this.selectedDatas?.findIndex((s: any) => s.value == dt[this.rowIndex].value);
            if (selectedIndex != -1) {
              dt[this.rowIndex].checked = true;
            }
            else {
              dt[this.rowIndex].checked = false;
            }
          }
        })
      }
      else {
        this.data?.map(dt => {
          let selectedIndex = this.selectedDatas?.findIndex((s: any) =>  s.value == dt[this.rowIndex].value);
          if (selectedIndex != -1) {
            dt[this.rowIndex].checked = true;
          }
          else {
            dt[this.rowIndex].checked = false;
          }
        })
      }
    }
  }
}
