import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { RadioItem, RadioOutputItem } from '../../../../app/models/common-model';

// ラジオボタン
@Component({
  selector: 'pivot-radio-button',
  templateUrl: './radio-button.component.html',
  styleUrls: ['./radio-button.component.scss']
})
export class RadioButtonComponent implements OnInit {

  @Input() dataItem: RadioItem = new RadioItem();
  @Input() style = {};
  @Input() isChecked: boolean = false;
  @Input() disabled: boolean = false;
  @Input() styleClass: string = "";
  @Output() onClick = new EventEmitter<RadioOutputItem>();

  constructor() { }

  ngOnInit(): void {
  }

  handleClick(event: any) {
    if (this.disabled) return
    this.isChecked = true;

    this.onClick.emit({ data: this.dataItem, value: this.isChecked });
  }
}
