import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot } from '@angular/router';
import { AuthenticationService } from '../services/authentication.service';
import { ROUTE_PATH } from '../const/route-path';
import { LocalStorageHelper } from '../_helper/local-storage.helper';
import { ProcessLoadingService } from '../services/loading.service';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
    constructor(
        private router: Router,
        private authenticationService: AuthenticationService,
        private processLoadingService: ProcessLoadingService,
    ) { }

    async canActivate(route: ActivatedRouteSnapshot): Promise<boolean> {
        const queryAccessToken = route?.queryParams['accessToken'] || null;
        const queryRefreshToken = route?.queryParams['refreshToken'] || null;
        const consoleToken = route?.queryParams['consoleToken'] || null;

        if (queryAccessToken && queryRefreshToken) {
            if (this.authenticationService.isLoggedIn()) {
                this.authenticationService.getCurrentUser();
                const currentUser = this.authenticationService.currentUserValue;
                const userId = currentUser.staffid;
                const isCurrentUser = this.authenticationService.isCurrentUser(queryAccessToken, userId);
                if (!isCurrentUser) {
                    LocalStorageHelper.clearAll()
                    return await this.loginViaToken(queryAccessToken, queryRefreshToken);

                } else {
                    this.router.navigate([LocalStorageHelper.getUrl('/')]);
                    return true;
                }
            }
            LocalStorageHelper.clearAll();
            return await this.loginViaToken(queryAccessToken, queryRefreshToken);

        } else if (this.authenticationService.isLoggedIn()) {
          if(document.referrer!=''){
            if(location.href.split('/')[2] != document.referrer.split('/')[2] 
              && location.href.indexOf(ROUTE_PATH.AUTH) < 0 
              && location.href.indexOf(ROUTE_PATH.CHOOSE_CORP) < 0 ){
              this.authenticationService.removeLoginSession();
              location.reload();
              return false;
            }
          }

          return true;
        }
        this.router.navigate([LocalStorageHelper.getUrl(ROUTE_PATH.AUTH)]);
        return false;
    }

    async loginViaToken(queryAccessToken: string, queryRefreshToken: string): Promise<boolean> {
        let res = false;

        this.processLoadingService.isLoading.emit(true);

        await this.authenticationService.loginViaToken(queryAccessToken, queryRefreshToken)
        .then(
            async (data: any) => {
                if (data) {
                    if (data?.data?.authcorplist && data?.data?.authcorplist.length > 1 || data.data.templatesettingcorp) {
                        this.router.navigate([LocalStorageHelper.getUrl(ROUTE_PATH.CHOOSE_CORP)]);
                        res = true;
                        return;
                    }
                    const processRes = await this.authenticationService.processAfterCallLoginApi(data);
                    switch (processRes.statuscode) {
                        case 200:
                            const account = data.data;
                            this.authenticationService.setCurrentUser(account);
                            this.router.navigate([LocalStorageHelper.getUrl(ROUTE_PATH.HOME)]);
                            res = true;
                            break;
                    
                        case 401:
                        default:
                            res = false;
                            break;
                    }
                } else {
                    res = false;
                }
                res = false;
            },
        (error: any) => {
            console.log(error);
            res = false;
        });

        this.processLoadingService.isLoading.emit(false);

        if (!res) {
            this.router.navigate([LocalStorageHelper.getUrl(ROUTE_PATH.AUTH)]);
        }

        return res;
    }
}
