<div class="pivot-search-bar" [ngStyle]="searchParams.cssStyle">
    <div class="pivot-input-search" *ngIf="searchParams.type === 0" [ngClass]="{'no-border': textInputFocus}">
        <input class="pivot-input" type="text" placeholder="{{searchParams.placeholder}}" id={{inputSearchId}}
            [disabled]="_disable" [readonly]="searchParams.readonly" [ngModel]="searchValue"
            [ngClass]="{
                'input-readonly' :  searchParams.readonly,
                'border-red-err': searchParams.borderRed
            }" 
            (keydown)="onKeyDowns($event)"
            (focus)="onFocusIn($event)" (focusout)="onFocusOutInput($event)" (input)="onInputData($event)"
            (keyup)="onKeyUps($event)" [maxlength]="searchParams.maxLength || null" />
        <div (click)="onClickIconSearch()">
            <span class="pivot-icon search-icon"></span>
        </div>
    </div>

    <div class="pivot-input-search" *ngIf="searchParams.type === 1" [ngClass]="{'no-border': comboInputFocus, 'required': _required}">
        <input class="pivot-input" type="text" placeholder="{{searchParams.placeholder}}" id={{inputSearchId}}
            [ngClass]="{
                'border-red-err': searchParams.borderRed
            }" 
            [disabled]="_disable" readonly [ngModel]="searchValue" (keydown)="onKeyDowns($event)"
            (click)="onClickDropdown($event)" [maxlength]="searchParams.maxLength || null" 
            (focus)="handleComboInputFocus($event)"
            (focusout)="handleComboInputFocusOut($event)"
            />
        <div class="pivot-icon dropdown-icon" [ngClass]="dspSearchPanel? 'icon-rotate': ''"
            (click)="onClickDropdown($event)"></div>
    </div>
    <div class="pivot-input-insert " *ngIf="searchParams.type === SearchType.insert" [ngClass]="{'no-border': textInputFocus}">
      <input class="pivot-input" type="text" id={{inputSearchId}} (keydown)="onKeyDownsInputInsert($event)"
          #inputSearch [(ngModel)]="columnName" (ngModelChange)="columnNameChange.emit($event)" 
          [maxlength]="searchParams.maxLength || null" [ngClass]="validateInput ? 'err-input' : ''" />
      <div class="btn-dropdown" (click)="onClickDropdown($event, inputSearch)" [ngClass]="dspSearchPanel? 'icon-rotate': ''">
          <span class="pivot-icon dropdown-icon" ></span>
      </div>                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                      
  </div>
    <pivot-filter-dialog [filterLog]="filterLog" [_displayFilter]="_displayFilter" [_targetFilter]="_targetFilter" (_onHide)= "onHidePanelSearch()" [_tempDataFilters]="searchParams.dataSource"
        [isFilter]="isFilter" [defaultValue]="searchValue" [displayText]="listFilterDspText" [cssStyle]="popupStyle"
        (_onFilterOutData)="filterOutData($event)" [_selectedData]="selectedData" [placeHolderFilter]="placeHolderFilter">
    </pivot-filter-dialog>
</div>