import { Component, EventEmitter, Input, OnChanges, OnInit, AfterViewInit, AfterViewChecked, Output, SimpleChanges, ChangeDetectorRef } from '@angular/core';
import { cloneDeep } from 'lodash';
import { COMMON_TEXT } from '../../../../app/const/text-common';
import { SearchType } from '../../../../app/enum/common-enum';
import { SearchParams } from '../../../../app/models/common-model';
import Utils from '../../../../app/util/utils';
import { conditionItemModel } from 'src/app/models/zaitaku-model';

@Component({
  selector: 'pivot-zaitaku-treenode-item',
  templateUrl: './treenode-item.component.html',
  styleUrls: ['./treenode-item.component.scss']
})
export class ZaitakuTreenodeItemComponent implements OnInit, OnChanges {

  @Input() filterLog: {screenName: string, action: string, body: string, content: string} | null = null;
  @Input() searchParams: SearchParams = new SearchParams();
  @Output() eventUpdateData = new EventEmitter<conditionItemModel>();

  @Input() placeHolderFilter: string;
  @Input() _displayFilter: any = null;
  @Input() isTreeNode: boolean = true;
  @Input() _disable: boolean = false;
  @Input() _required: boolean = false;
  @Input() columnName: string;
  @Input() validateInput: boolean = false;
  @Input() notValidIds: any[] = [];
  @Output() columnNameChange = new EventEmitter<string>();
  _targetFilter: any = null;
  dspSearchPanel: boolean = false;
  searchValue: string = '';
  inputSearchId: string = '';
  popupStyle: any = {};
  dataFilters = [];
  selectedData: any[] = [];
  isFilter: boolean = true;
  outputData: any = null;
  listFilterDspText: string = '';
  comboInputFocus: boolean = false;
  textInputFocus: boolean = false;
  SearchType = SearchType;

  selectedNode: conditionItemModel = new conditionItemModel();


  constructor(private cdr: ChangeDetectorRef) {
  }
  ngOnChanges(changes: SimpleChanges): void {
    this.cdr.detectChanges();

    this.selectedNode = this.searchParams.objectData.selectedNode;
    this.searchValue = this.selectedNode.itemname;

    if (changes && changes["objectData"]) {
      let objectData = changes["objectData"].currentValue;
      this.searchParams.objectData = objectData;
    }
  }



  ngOnInit(): void {
    this.setDspTextForDlgFilter();
    this.searchValue = this.searchParams?.defaultValue == undefined ? '' : this.searchParams?.defaultValue;
    let findItemSelect = this.searchParams.dataSource?.find((d: any) => d.value == this.searchParams.selected || d.name == this.searchValue);
    this.searchValue = this.searchValue || findItemSelect?.name
    // set placeholder for textbox
    if (this.searchParams?.placeholder === undefined) {
      this.searchParams.placeholder = COMMON_TEXT.SEARCH;
    }
    this.inputSearchId = this.getRandomIdSearch();
    if (!Utils.isNullOrEmpty(this.searchParams?.isFilter)) {
      this.isFilter = (this.searchParams.isFilter || false);
    }
  }

  // random id textbox
  getRandomIdSearch() {
    return Math.floor(Math.random() * 10000).toString();
  }

  // click input show dialog filter
  onClickDropdown(event: any) {
    let inputSearch = document.getElementById(this.inputSearchId);
    if (!Utils.isNullOrEmpty(inputSearch)) {
      this.popupStyle = { 'width': inputSearch?.offsetWidth + 'px' };
    }
    this._displayFilter = event;
    this.dspSearchPanel = true;
  }

  setInputValue(data: any) {
    if (this.searchParams.comboDisplayText != undefined) {
      this.searchValue = data[this.searchParams.comboDisplayText];
    }
    else {
      let column = Object.keys(data);
      this.searchValue = data[column[0]];
    }
  }
  // set display text for dialog filter
  setDspTextForDlgFilter() {
    let params = this.searchParams;
    if (params?.type === SearchType.combo) {
      if (params?.dataSource === undefined) {
        this.searchParams.dataSource = [];
      }
      if (params.comboDisplayText !== undefined) {
        this.listFilterDspText = params.comboDisplayText;
      }
      else {
        let row: any = {};
        let dataSource = this.searchParams?.dataSource;
        if (dataSource != undefined && dataSource.length > 0) {
          row = dataSource[0];
        }
        let column = Object.keys(row);
        if (column[0] != undefined) {
          this.listFilterDspText = column[0];
        }
      }
    }
  }

  onHidePanelSearch() {
    this.dspSearchPanel = false;
  }

  handleComboInputFocus(event: any) {
    this.comboInputFocus = true;
  }

  handleComboInputFocusOut(event: any) {
    this.comboInputFocus = false;
    this._displayFilter = null;
  }

  onClickLeafNode(event: any) {
    let item =  event.node.data[0];
    if (!item) return;
    this.searchValue = item.tooltip;
    this.selectedNode.itemname = this.searchValue;
    this.selectedNode.itemid = item.itemid;
    this.selectedNode.ttlcd = item.ttlcd;
    this.selectedNode.ttlkbn = item.ttlkbn;
    this.selectedNode.wtype = item.wtype;
    this.selectedNode.notekbn = item.notekbn;
    this.selectedNode.dockbn = item.dockbn;
    this.selectedNode.im = item.im;
    this.eventUpdateData.emit(this.selectedNode);
  }

}
