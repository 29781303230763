import { AfterContentChecked, Component, EventEmitter, Input, OnInit, OnChanges, Output, ViewEncapsulation, SimpleChanges, Inject } from '@angular/core';
import { cloneDeep, join, orderBy } from 'lodash';
import { OFFICE } from '../../../const/table-col-define';
import { BUTTON, COMMON_TEXT, DATASOURCE_LIST, DATASOURCE_SETTING } from '../../../../app/const/text-common';
import { ButtonType, DataSourceType, DataType, DateFormat, InputType, SearchType, UserAction } from '../../../../app/enum/common-enum';
import { FilterItem, SearchParams, TemplatedDialogItem } from '../../../../app/models/common-model';
import { Office, OfficeService } from '../../../../app/models/response/office.ro';
import { TableData } from '../../../../app/models/table-model';
import { OfficeAPIService } from './../../../services/modules/office.service';
import Utils from '../../../../app/util/utils';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { CorpMstService } from '../../../../app/services/modules/corpmst.service';
import { MstCommonService } from '../../../../app/services/modules/mstcommon.service';
import * as moment from 'moment';
import { FOLDER_TYPE } from '../../../../app/models/response/folder.ro';
import { FolderService } from '../../../../app/services/modules/folder.service';
import { DataSourceService } from 'src/app/services/modules/data-source.service';
import { ProcessLoadingService } from 'src/app/services/loading.service';
import {AuthenticationService} from 'src/app/services/authentication.service';
import {ErrorHandleService} from 'src/app/services/error-handle.service';
import {FUNCTION_TITLE_TEXT} from 'src/app/const/error-text';
import { MstFilterDataTimeItem } from 'src/app/const/const';
import { SAUCER_LOG_ACTION, SaucerLogService } from 'src/app/services/saucer-logs/saucer-log.service';
import { CONTENT_LOG } from 'src/app/config/saucer-log.config';

@Component({
  selector: 'pivot-template-datasource-dialog',
  templateUrl: './template-datasource-dialog.component.html',
  styleUrls: ['./template-datasource-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class TemplateDatasourceDialogComponent implements OnInit, AfterContentChecked, OnChanges {
  @Input() selectedOfficeServices: Array<Office> = [];
  @Input() datasourceCdTemplate: any = "";
  @Input() inputParams: TemplatedDialogItem = new TemplatedDialogItem();
  @Output() selectedOfficeHandler = new EventEmitter();

  BUTTON = BUTTON;
  COMMON_TEXT = COMMON_TEXT;
  buttonType = ButtonType;
  searchParams: SearchParams = {
    type: SearchType.input,
    placeholder: COMMON_TEXT.SEARCH,
    cssStyle: { width: '100%', height: '40px' },
    readonly: false,
    disabled: false
  }
  tableData: TableData = {
    config: {
      id: 'office-service',
      caption: '',
      emptyMessage: COMMON_TEXT.NO_DATA,
      showIndex: true,
      showCheckbox: true,
      hoverShowCheckbox: false,
      showPagination: false,
      isResponsive: false,
      tableStyle: {}

    },
    header: [
      {
        field: 'officeCd',
        title: OFFICE.OFFICECD,
        sortable: false,
        filterable: false,
        attribute: {
          header: { width: '20%' },
          row: {},
          childrenCols: []
        },
        dataType: DataType.TEXT,
        visible: false
      },
      {
        field: 'officeNm',
        title: OFFICE.OFFICENM,
        sortable: false,
        filterable: false,
        attribute: {
          header: { width: '80%' },
          row: {},
          childrenCols: []
        },
        dataType: DataType.TEXT,
        visible: true
      },
    ],
    body: []
  };
  isFilterAll: boolean = true;
  isShowModal: boolean = false;
  filterServices: any[] = [];
  listOffices: Array<any> = [];
  filterOffice: Array<any> = [];
  listServices: any[] = [];
  loadingTable: boolean = true;
  officeData: any[] = [];
  selectedOffices: Array<Office> = [];
  dispalyFilterServices: any[] = [];
  remaningNumber: number = 0;
  filterList: Array<FilterItem> = [];
  filterModalStyle = { width: '400px', height: '300px' };
  isDisplayNumber: boolean = false;
  searchText: string = "";
  tabType: number = 1;
  DATASOURCE_SETTING = DATASOURCE_SETTING;
  selectedPeriod: any = {};
  selectedRangeDate: any = {};
  groupedPeriod: any = [{value: "-1", label: "ユーザー定義期間", items: [{value: "-1", label: "カスタマイズ"}]}]
  _groupedPeriod: any = [];
  isDisplayRangeDateModal: boolean = false;
  isOptionCreate: boolean = true;
  isShowMsgExistDs: boolean = false;

  // input textbox description params
  inputDesParams: any = {
    type: InputType.text,
    validate: false,
    borderFill: true,
    maxLength: 200,
    disabled: false
  };

  isShowFolder: boolean = false;
  selectedFolder: any = {};
  selectedFolderRow: any = {};
  folderType = FOLDER_TYPE;
  folderEdits: any[] = [];
  folderList: any[] = [];
  existDatasources: any[] = [];
  selectedExistDs: any[] = [];
  disableNextBtn: boolean = true;
  dsCdTemplate: any;
  period: string = '';
  datasourceNm: string = '';
  existDatasourceCd: string = '';
  officeCds: any[] = [];
  dsType: number;
  isSupporterAdmin: boolean = false;
  
  SAUCER_LOG_ACTION = SAUCER_LOG_ACTION;

  constructor(
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    private officeAPI: OfficeAPIService,
    private corpMstService: CorpMstService,
    private mstService: MstCommonService,
    private folderService: FolderService,
    private dataSourceService: DataSourceService,
    private loadingService: ProcessLoadingService,
    private authenticationService: AuthenticationService,
    private errorHandleService: ErrorHandleService,
    private saucerLogService: SaucerLogService
  ) {
    this.config.showHeader = true;
    this.config.style = { 
      width: '38%',
      header: COMMON_TEXT.CONFIRM_NOT_SAVE_DATA
    };
    if (this.config && this.config.data) {
     this.datasourceCdTemplate = this.config.data?.datasourceCdTemplate
     this.datasourceNm = this.config.data?.datasourceNameTemplate
     this.period = this.config.data?.datasourcePeriod
     this.tabType = this.config.data?.tabType || 1;
    }

    //Log
    this.saucerLogService.action({}, { 
      action: SAUCER_LOG_ACTION.TEMPLATE_SELECTION.BUSINESS_LOCATION_SELECTION_DIALOG.VIEW
    });
  }
  ngAfterContentChecked(): void {
    let item = document.querySelectorAll('.tabtem1__filter__services__items__item');
    if (item.length > 0) {
      let totalWidth = 0;
      item.forEach(element => {
        let item = element as HTMLElement;
        totalWidth = totalWidth + item.offsetWidth;
      });
      this.isDisplayNumber = totalWidth > 440 ? true : false;
    }
  }

  async ngOnInit(): Promise<void> {
    this.loadingService.isLoading.emit(true);
    this.errorHandleService.setFunctionTitle(FUNCTION_TITLE_TEXT.SCREEN_INIT_FAIL);
    await this.dataSourceService.getDataSourceType({dsstructcd:this.datasourceCdTemplate}).then(result => {
      this.dsType = result.data;
    });
    this.isSupporterAdmin = await this.authenticationService.isAdminOrSupporter();
    //#region tab 1
    window.addEventListener('click', (event: any) => {
      let modal = document.getElementById('filter-modal');
      let element = event.target.id;
      if (element === 'checkbox-check') {
        if (this.isShowModal) {
          this.isShowModal = true;
        }
      }
      else {
        if (!modal?.contains(event.target)) {
          let icon = document.getElementById('filter-icon');
          if (!icon?.contains(event.target)) {
            this.isShowModal = false;
          }
        }
      }
    });
    this.listOffices = await this.officeAPI.getAllOfficeService();
    this.filterOffice = cloneDeep(this.listOffices);
    this.filterOffice.map(o => {
      delete o.copr
      o.checked = this.selectedOfficeServices.filter(i => i.officeCd === o.officeCd).length > 0;
      o.services?.map((x: any) => {
        if (!this.listServices.filter(i => i.id === x.id && i.name === x.srvkbnnm).length) {
          let filter = new FilterItem();
          filter.id = x.id;
          filter.level = x.serviceType + "_" + x.fixflg;
          filter.name = x.srvkbnnm;
          filter.checked = false;
          this.listServices.push(filter);
        }
      })
    })
    let selectedRows = cloneDeep(this.filterOffice).filter(item => item.checked);
    this.selectedOffices = selectedRows.map(item => {
      delete item.checked;
      return item;
    })
    this.filterOffice = orderBy(this.filterOffice, ["officeCd"])
    this.tableData.body = this.filterOffice;
    this.tableData = cloneDeep(this.tableData);
    this.loadingTable = false;
    this.officeData = cloneDeep(this.tableData.body);
    //#endregion tab 1
    
    //#region tab 2
    const corpMstService = await this.corpMstService.getAll();
    let startMonth = 1;
    if (corpMstService.statuscode == 200) {
      let monthSetting = corpMstService.data?.find((x:any) => x.contentcd == "0001");
      startMonth = +(monthSetting?.value || 1);
    }

    let filterBodyRequest = {
      startmonth: startMonth
    }

    let groupedPeriod = await this.mstService.getMstFilter(filterBodyRequest) as any[];
    let localGroupedPeriod = [{value: "-1", label: "ユーザー定義期間", items: [{value: "-1", label: "カスタマイズ"}]}];
    this.groupedPeriod.push(...groupedPeriod);
    localGroupedPeriod.push(...groupedPeriod);
    this._groupedPeriod.push(...localGroupedPeriod);
    if(this.dsType === DataSourceType.SEIKUY){
      let seikuyGroupedPeriod  = this._groupedPeriod.filter((x: any) => x.value != "-1" && x.value != MstFilterDataTimeItem.ThisYear && x.value != MstFilterDataTimeItem.Today && x.value != MstFilterDataTimeItem.ThisWeek);
      this.groupedPeriod = seikuyGroupedPeriod;
    }
    else {
      this.groupedPeriod = cloneDeep(this._groupedPeriod);
    }
    // map label for period
    this.groupedPeriod.map((group:any) => group.items.map((item:any) => {
      if(this.dsType === DataSourceType.SEIKUY){
        item.label = item.contentseikuy ??  item.label
      }else{
        item.label = item.content ??  item.label
      }
    }));

    if (this.period.includes('~')){
      this.selectedPeriod = "-1";
      let range = this.period.split('~');
      if (range.length == 2){
        this.selectedRangeDate = {startDate: range[0], endDate: range[1]}
      }else {
        this.selectedPeriod = '003-002'; //Defautl is this month 今月;
        this.setSelectedRangeDate();
      }  
    }else{
      this.selectedPeriod = this.period;
      this.setSelectedRangeDate();
    } 
    
    const folderService = await this.folderService.getByType(FOLDER_TYPE.Datasource.toString(), this.isSupporterAdmin);
    if (folderService.statuscode == 200) {
      this.folderList = folderService?.data || [];
      this.folderEdits = this.folderList?.filter(f => f.act != UserAction.VIEW);
    }
    //#endregion tab 2
    if(this.tabType == 2 && this.config) {
      let params = this.config.data?.inputParams;
      if(params) {
        this.datasourceNm = params.datasourceNm || '';
        this.existDatasourceCd = params.existDatasourceCd || '';
        this.isOptionCreate = params.isOptionCreate || false;
        this.officeCds = params.officeCds || [];
        this.period = params.period || '';
        this.selectedPeriod = this.period;
        this.selectedFolder = params.selectedFolder;
        this.selectedOffices = params.selectedOffices || [];
        this.selectedRangeDate = params.selectedRangeDate;
      } 
    }
    this.loadingService.isLoading.emit(false);
  }

  ngOnChanges(changes: SimpleChanges) {
  }

  //#region tab1

  showModalFilter() {
    this.isShowModal = !this.isShowModal;
  }

  setSelectedRangeDate() {
    this.groupedPeriod.forEach((group: any) => {
      let selectedItem = group.items.filter((item:any) => item.value === this.selectedPeriod).pop();
      if(selectedItem){
        if(this.dsType === DataSourceType.SEIKUY){
          this.selectedRangeDate = {startDate: selectedItem.startdateseikuy, endDate: selectedItem.enddateseikuy}
         }else{
          this.selectedRangeDate = {startDate: selectedItem.startdate, endDate: selectedItem.enddate}
        }
      }
    });
  }

  onFilterOutData(officeName: any) {
    let table = cloneDeep(this.tableData);
    officeName = officeName.trim();
    this.searchText = officeName;
    let offices = [];
    offices = this.officeData.filter(o => {
      let nameCondition = Utils.isNullOrEmpty(officeName) ? true : o.officeNm?.includes(officeName) ;
      let serviceCondition =  this.filterServices.length > 0 ? o.services?.filter((s: any) => this.filterServices.filter((i: any) => i.id === s.id && i.name === s.srvkbnnm).length > 0).length > 0: true;
      return nameCondition && serviceCondition;
    });
    table.body = offices;
    this.tableData = table;

    //Log
    this.saucerLogService.action({
      content: CONTENT_LOG.SEARCH + ": " + officeName
    }, { 
      action: SAUCER_LOG_ACTION.TEMPLATE_SELECTION.BUSINESS_LOCATION_SELECTION_DIALOG.SEARCH
    });
  }

  mapdataFilter(dataChecked: any) {
    if (dataChecked && Object.keys(dataChecked).length > 0) {
      if (dataChecked?.data?.length > 0) {
        this.officeData?.map(off => {
          let officeIndex = dataChecked?.data?.findIndex((s: any) =>  s.officeCd == off.officeCd);
          if (officeIndex != -1) {
            off.checked = true;
          }
          else {
            let selectedIndex = this.selectedOffices?.findIndex((s: any) => s.officeCd == off.officeCd);
            if (selectedIndex != -1) {
              off.checked = true;
            }
            else {
              off.checked = false;
            }
          }
        })
      }
      else {
        this.officeData?.map(off => {
          let selectedIndex = this.selectedOffices?.findIndex((s: any) =>  s.officeCd == off.officeCd);
          if (selectedIndex != -1) {
            off.checked = true;
          }
          else {
            off.checked = false;
          }
        })
      }
    }
  }

  checkRowsTable(data: any) {
    if (data && Object.keys(data).length > 0) {
      this.selectedOffices = data.data;
    }
    this.mapdataFilter(data);
    this.disableNextBtn = (this.tabType == 1 && this.selectedOffices.length == 0)
  }

  filterData(selectedService: any) {
    //Log
    this.saucerLogService.action({
      content: JSON.stringify(selectedService)
    }, { 
      action: SAUCER_LOG_ACTION.TEMPLATE_SELECTION.BUSINESS_LOCATION_SELECTION_DIALOG.CHANGE_FILTER
    });
    
    if (selectedService.length > 0) {
      let offices = this.officeData.filter(o => {
        return  o.services?.filter((s: any) => selectedService.filter((i: any) => i.id === s.id && i.name === s.srvkbnnm).length > 0).length > 0;
      }).filter((office)=> {
        return Utils.isNullOrEmpty(this.searchText) ? true : office.officeNm?.includes(this.searchText);
      })

      this.filterServices = selectedService;
      this.listServices.map(item => {
        if (this.filterServices.filter((filter: any) => filter.id === item.id  && filter.name === item.name ).length > 0) item.checked = true;
        else item.checked = false;
      })
      let table = cloneDeep(this.tableData);
      table.body = offices;
      this.tableData = table;
    }
    else {
      this.listServices.map(item => {
        item.checked = false;
      })
      let table = cloneDeep(this.tableData);
      this.officeData = orderBy(this.officeData, ["officeNm"]);
      let offices = this.officeData.filter(o => {
        return Utils.isNullOrEmpty(this.searchText) ? true : o.officeNm?.includes(this.searchText);
      });
      table.body = offices;
      this.tableData = table;
      this.filterServices = [];
    }
    this.generateFilterService();
  }

  generateFilterService() {
    this.dispalyFilterServices = this.filterServices.map(item => (
      {
        id: item.id,
        name: item.name
      }));
    this.remaningNumber = this.filterServices.length;
  }

  removeServiceFilter(service: any) {
    this.filterServices = this.filterServices.filter(i => i.id !== service.id && i.name !== service.srvkbnnm);
    this.saucerLogService.action({
      content: JSON.stringify(this.filterServices)
    }, { 
      action: SAUCER_LOG_ACTION.TEMPLATE_SELECTION.BUSINESS_LOCATION_SELECTION_DIALOG.CHANGE_FILTER
    });
    if (this.filterServices.length > 0) {
      let tmpData = cloneDeep(this.officeData);
      let filterData = tmpData.filter( (office) =>  {
        return this.filterServices.filter( (service) => {
          let arrayItem: any[] = [];
          office.services.forEach((s: any) => {
            let item = {id: s.id, name: s.srvkbnnm}
            arrayItem.push(item)
          });
          return arrayItem.filter((i:any) => i.id === service.id && i.name === service.name).length > 0;
        }).length > 0
      }).filter((office) => {
        return Utils.isNullOrEmpty(this.searchText) ? true : office.officeNm?.includes(this.searchText) 
      })

      this.tableData.body = filterData;
      this.tableData = cloneDeep(this.tableData);
      this.listServices.map(item => {
        if (this.filterServices.filter((filter: any) => filter.id === item.id  && filter.name === item.name).length > 0) item.checked = true;
        else item.checked = false;
      })
    }
    else {
      this.filterData(service);
    }
    this.generateFilterService();
  }

  //#endregion tab1

  //#region tab2
  openRangeDateModal( group: any) {
    if(group.value === "-1")  {
      this.isDisplayRangeDateModal = true;
    }
    else {
      if(this.dsType === DataSourceType.SEIKUY){
        this.selectedRangeDate = {startDate: group.startdateseikuy, endDate: group.enddateseikuy}
       }else{
        this.selectedRangeDate = {startDate: group.startdate, endDate: group.enddate}
      }
      this.isDisplayRangeDateModal = false;
      this.selectedPeriod = group.value;
      this.getListExistDatasource()
    } 
    //Reset table if changing period option

  }

  closeRangeDateModal() {
    this.isDisplayRangeDateModal = false;
  }

  setRangeDate(event: any){
    this.selectedRangeDate = event;
    this.isDisplayRangeDateModal = false;
    this.getListExistDatasource()
  }

  formatRangeDate(rangeDate: any){
    if(Object.keys(rangeDate).length && rangeDate.startDate && rangeDate.endDate ) {
      if(moment(rangeDate.startDate).format("L") == moment(rangeDate.endDate).format("L"))
        return moment(rangeDate.startDate).format(DateFormat.FULL_SHORT_DATE);
      return `${moment(rangeDate.startDate).format(DateFormat.FULL_SHORT_DATE)} ~ ${moment(rangeDate.endDate).format(DateFormat.FULL_SHORT_DATE)}`;
    }
    return  "";
  }

  onCheckbox(option: number) {
    if (option == 1) {
      this.isOptionCreate = true
      this.inputDesParams.disabled = false;
    } else if (option == 2) {
      this.inputDesParams.disabled = true;
      if (!this.isShowMsgExistDs) return
      this.isOptionCreate = false
    }
  }

  onInputDescription(event: any) {
    this.datasourceNm = event.currentTarget?.value;
  }

  showFolderModal() {
    this.isShowFolder = true;
    if (this.selectedFolder && this.selectedFolder.id) {
      this.selectedFolderRow = this.folderList.find(f => f.folderCd === this.selectedFolder.id);
    }

    this.saucerLogService.action({}, { 
      action: SAUCER_LOG_ACTION.TEMPLATE_SELECTION.DATA_SOURCE_INFORMATION_REGISTRATION_DIALOG.FOLDER_SHOW_DIALOG
    });
  }

  closeFolderModal() {
    this.isShowFolder = false;
  }

  changeFolder(folder: any) {
    if (!Utils.isNullOrEmpty(folder)) {
      this.selectedFolder.id = folder.folderCd;
      this.selectedFolder.name = folder.name;
    } else this.selectedFolder = null;

    //Log
    const contentFolderLog = {
      folderCd: folder.folderCd,
      folderName: folder.folderName || folder.name,
      assigntype: folder.assigntype,
      lstAssign: folder.assign
    }
    this.saucerLogService.action({
      content: JSON.stringify(contentFolderLog)
    }, { 
      action: SAUCER_LOG_ACTION.TEMPLATE_SELECTION.FOLDER_SELECTION_DIALOG.UPDATE_FOLDER
    });
  }
  //#endregion tab2

  //#region bottom
  onNext() {
    this.tabType++
    if (this.tabType == 2) {
      this.getListExistDatasource()
      const content = this.selectedOffices.map((x:any) => ({
        corpcd: x.corpcd,
        officeCd: x.officeCd,
        officeNm: x.officeNm,
      }));
      this.saucerLogService.action({
        content: JSON.stringify(content)
      }, { 
        action: SAUCER_LOG_ACTION.TEMPLATE_SELECTION.BUSINESS_LOCATION_SELECTION_DIALOG.SELECT_BUSINESS_LOCATION
      });
      this.saucerLogService.action({}, 
        { 
          action: SAUCER_LOG_ACTION.TEMPLATE_SELECTION.DATA_SOURCE_INFORMATION_REGISTRATION_DIALOG.VIEW
        }
      );
    } else if (this.tabType == 3) {
      this.saucerLogService.action(
        {
          content: JSON.stringify({
            selectedRangeDate: this.formatRangeDate(this.selectedRangeDate),
            folderName:  this.selectedFolder.name || this.selectedExistDs[0]?.foldername,
            datasourceName: this.isOptionCreate ? this.datasourceNm : this.selectedExistDs[0]?.dsname || "",
            existDataSourceName: this.isOptionCreate ? undefined : this.getExistDatasourceNm()
          })
        }, 
        { 
          action: SAUCER_LOG_ACTION.TEMPLATE_SELECTION.CONFIRMATION_DIALOG.VIEW
        }
      );
    }
  }

  onBack() {
    this.tabType--
    this.officeData?.map(off => {
      let selectedIndex = this.selectedOffices?.findIndex((s: any) => s.officeCd == off.officeCd);
      if (selectedIndex != -1) {
        off.checked = true;
      }
      else {
        off.checked = false;
      }
    })
    this.tableData.body = cloneDeep(this.officeData);
    this.tableData = cloneDeep(this.tableData);

    //Log
    if(this.tabType == 1)
    {
      this.saucerLogService.action({}, { 
        action: SAUCER_LOG_ACTION.TEMPLATE_SELECTION.BUSINESS_LOCATION_SELECTION_DIALOG.VIEW
      });
    } else 
    if(this.tabType == 2) {
      this.saucerLogService.action({}, { 
        action: SAUCER_LOG_ACTION.TEMPLATE_SELECTION.DATA_SOURCE_INFORMATION_REGISTRATION_DIALOG.VIEW
      });
    }
  }

  onCancel() {
    this.ref.close('cancel')
  }

  onSave() {
    this.errorHandleService.setFunctionTitle(FUNCTION_TITLE_TEXT.SAVE_DATA_FAIL);
    let data = {
      selectedFolder: this.selectedFolder,
      selectedRangeDate: this.selectedRangeDate,
      period: this.period,
      isOptionCreate: this.isOptionCreate,
      datasourceNm: this.datasourceNm,
      existDatasourceCd: this.existDatasourceCd,
      officeCds: this.officeCds,
      selectedOffices: this.selectedOffices
    }
    this.ref.close(data)

    //LOG
    const newRecord = {
      selectedFolder: data.selectedFolder,
      selectedRangeDate: data.selectedRangeDate,
      period: data.period,
      datasourceNm: data.datasourceNm,
      existDatasourceCd: data.existDatasourceCd,
      officeCds: data.officeCds,
    };
    this.saucerLogService.action({
      content: JSON.stringify({
        old: null,
        new: newRecord
      })
    }, { 
      action: SAUCER_LOG_ACTION.TEMPLATE_SELECTION.CONFIRMATION_DIALOG.SAVE
    });

  }

  isDisableNextBtn() {
    if (this.tabType == 1) {
      if (this.selectedOffices.length) return false
    } else if (this.tabType == 2) {
      if (this.selectedRangeDate && this.selectedRangeDate.startDate && this.selectedRangeDate.endDate) {
        if (this.isOptionCreate) {
          if (this.selectedFolder && this.selectedFolder?.id && this.datasourceNm.trim()) {
            return false
          }
        } else {
          if (this.existDatasourceCd) {
            return false
          }
        }
      }
    }
    return true
  }
  //#endregion bottom

  //#region handle exsit datasources
  async getListExistDatasource() {
    this.loadingService.isLoading.emit(true);
    this.officeCds = this.selectedOffices.map(o => o.officeCd).sort((a,b) => {
      return Number(a) - Number(b)
    })
    let officeIdLst = join(this.officeCds, "-");
    let startDate = this.selectedRangeDate.startDate;
    let endDate = this.selectedRangeDate.endDate;
    if(this.selectedPeriod === '-1'){
      this.period = `${startDate} ~ ${endDate}`
    }
    else {
      this.period = this.selectedPeriod;
    }
    let data: any = {
      period: this.period, 
      officeIdLst: officeIdLst, 
      datasourceCdTemplate: this.datasourceCdTemplate, 
      startDate: startDate, 
      endDate: endDate
    }
    const res =  await this.dataSourceService.getExistDatasources(data);
    if (res.statuscode === 200 && res.data?.length) {
      this.isShowMsgExistDs = true;
      this.existDatasources = res.data;
      this.loadingService.isLoading.emit(false);
    } else {
      this.isShowMsgExistDs = false;
      this.existDatasources = [];
      this.existDatasourceCd = "";
      this.isOptionCreate = true;
      this.inputDesParams.disabled = false;
      this.loadingService.isLoading.emit(false);
    }
  }

  onSelectionChange(event: any) {
    if (this.isOptionCreate) return
    this.selectedExistDs = event.value
    this.existDatasourceCd = this.selectedExistDs[0]?.dsstructcd || ""
  }

  getExistDatasourceNm() {
    return this.existDatasources.find(x => x.dsstructcd === this.existDatasourceCd).dsname || ""
  }
  //#endregion

}
