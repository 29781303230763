import { Component, Input, Output, EventEmitter, ViewEncapsulation, OnChanges, SimpleChanges, ChangeDetectorRef } from '@angular/core';
import { COMMON_TEXT } from 'src/app/const/text-common';

@Component({
  selector: 'pivot-filter-table',
  templateUrl: './filter-table.component.html',
  styleUrls: ['./filter-table.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class FilterTableComponent implements OnChanges {
  @Input() options: any[] = [];
  @Input() scrollHeight: string = '300px';
  @Input() placeholderText: string = COMMON_TEXT.SEARCH_TEXT;
  @Input() emptyFilterMessage: string = COMMON_TEXT.NO_DATA;

  @Output() onChange = new EventEmitter<any[]>(); 

  filteredOptions: any[] = [];
  selectAllChecked: boolean = false;

  constructor(private cdr: ChangeDetectorRef) {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['options']) {
      this.filteredOptions = [...changes['options'].currentValue];
      this.selectAllChecked = this.isAllSelected();
      this.cdr.detectChanges();
    }
  }

  onFilter(event: any) {
    const query = event.target.value.toLowerCase();
    this.filteredOptions = this.options.filter(option => option.name.toLowerCase().includes(query));
  }

  isAllSelected(): boolean {
    return (this.filteredOptions.length && this.filteredOptions.every(option => option.checked)) as boolean;
  }

  selectAllOptions(event: any) {
    this.filteredOptions.forEach(option => {
      option.checked = event.checked;
    });
    this.selectAllChecked = this.isAllSelected();
    this.emitChange();
    this.cdr.detectChanges();
  }

  onItemChange(option: any, event: any) {
    option.checked = event.checked;
    this.selectAllChecked = this.isAllSelected();
    this.emitChange();
    this.cdr.detectChanges();
  }

  private emitChange() {
    this.onChange.emit({ value: this.options.filter(option => option.checked)}  as any);
  }
}
