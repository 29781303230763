import { Injectable } from '@angular/core';
import {ActivatedRouteSnapshot, CanDeactivate, RouterStateSnapshot} from '@angular/router';
import {Observable} from 'rxjs';
import {ROUTE_PATH} from 'src/app/const/route-path';
import { CorpSelectionComponent } from '../../module/login/corp-selection/corp-selection.component';

@Injectable({
  providedIn: 'root'
})
export class CanDeactivateCorpSelectionService  implements CanDeactivate<CorpSelectionComponent> {

  isSkipCallAPI: boolean = true;

  constructor(
  ) { }

  canDeactivate(component: CorpSelectionComponent,
    currentRoute: ActivatedRouteSnapshot,
    currentState: RouterStateSnapshot,
    nextState?: RouterStateSnapshot):  Observable<boolean> | Promise<boolean> | boolean {
    if (nextState?.url !== ("/" + ROUTE_PATH.AUTH))
      return true;
    else
      return component.canDeactivate(nextState?.url);
  }
}
