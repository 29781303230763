import { Component, EventEmitter, Input, OnChanges, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { ButtonType } from '../../../enum/common-enum';
import { HelpListHelper } from './../../../_helper/help-list'
import { HELP_LIST } from '../../../const/text-common';
import { ModalTemplate } from '../../../models/common-model';



@Component({
  selector: 'pivot-help-detail-dialog',
  templateUrl: './help-detail-dialog.component.html',
  styleUrls: ['./help-detail-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class HelpDetailDialogComponent implements OnInit,OnChanges  {
  buttonType = ButtonType;
  displayContent = "";
  isShowChecbox = false;
  isCheck = false;
  modalTemplate: ModalTemplate= new ModalTemplate()
  @Input() data: any = {};
  @Input() showDialog: boolean = false;
  @Input() showCheckbox: boolean = false;
  @Input() dataCheckbox: boolean = false;
  @Output() closeDialog = new EventEmitter<any>();
  @Output() changeCheckbox = new EventEmitter<boolean>();
  constructor(
  ) {}

  HELP_LIST = HELP_LIST
  ngOnChanges(): void {
    this.initData();
  }

  async ngOnInit(): Promise<void> {
    this.modalTemplate.closeOnEscape = false;
    this.initData();
  }
  onClickClose(){
    this.closeDialog.emit(this.data);
  }
  initData(){
    this.isCheck = this.dataCheckbox;
    this.displayContent = HelpListHelper.getDisplayMarkdown(this.data);
    this.isShowChecbox = this.showCheckbox;
  }
  checkChange(){
    this.isCheck = !this.isCheck;
    this.changeCheckbox.emit(this.isCheck);
  }
}
