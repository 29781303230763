<div modal-body>
    <div class="dlgu-content dlgu-flex-start">
        <div class="dlgu-block-message">
            <div class="dlgu-txt"><label>{{MESSAGE_TEXT.REQUIRE_INPUT}}</label></div>
        </div>
    </div>
</div>
<div modal-bottom class="dlgu-content">
    <div class="dlgu-div-btn">
        <pivot-text-button class="dlgu-btn" (btnClick)="onSubmit(saveType.SAVE)" [buttonType]="buttonType.SECONDARY"
            [buttonText]="BUTTON.YES">
        </pivot-text-button>
    </div>
</div>