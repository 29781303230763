import { AfterViewChecked, Directive, ElementRef, EventEmitter, Input, OnChanges, Output, Renderer2, SimpleChanges, ViewContainerRef } from "@angular/core";
import { MentionConfig } from "src/app/models/mention-config";
import { MentionListComponent } from "../mention-list/mention-list.component";
import { getCaretPosition, getValue, insertValue } from "src/app/_helper/mention-helper";



@Directive({
  selector: '[mention]',
  host: {
    '(keydown)': 'onKeydownEditor($event)',
    '(input)': 'onInputEditor($event)',
    '(blur)': 'onBlurEditor($event)',
    'autocomplete': 'off'
  }
})
export class MentionDirective implements OnChanges {
  KEY_BACKSPACE = 8;
  KEY_TAB = 9;
  KEY_ENTER = 13;
  KEY_SHIFT = 16;
  KEY_ESCAPE = 27;
  KEY_SPACE = 32;
  KEY_LEFT = 37;
  KEY_UP = 38;
  KEY_RIGHT = 39;
  KEY_DOWN = 40;
  KEY_BUFFERED = 229;
  iframe: any; // optional
  startNode:any;
  private lastKeyCode: number;
  startPos: number = 0;
  activeConfig: (MentionConfig | null);
  trigger : (string | undefined);
  triggerChars = [' ']//, '(' , ';'];////
  searching = false;
  searchString = '';

  @Input('mention')itemsimput:any;

  @Input() targetSettings: any [] = [];

  itemsFunction: any[]= [];
  itemsColumn: any[]= [];

  @Output() opened = new EventEmitter();
  @Output() closed = new EventEmitter();
  @Output() itemSelected = new EventEmitter<any>();
  @Output() searchTerm = new EventEmitter<string>();
  searchList: MentionListComponent;

  constructor(
    private elementRef: ElementRef, 
    private renderer: Renderer2,
    private _viewContainerRef: ViewContainerRef) {

  }
  ngOnChanges(changes: SimpleChanges): void {
    if(this.activeConfig) {
      this.activeConfig.itemsFunction = this.itemsimput.itemsFunction;
      this.activeConfig.itemsColumn  = this.itemsimput.itemsColumn;
    }
  }


  ngOnInit(): void {
    this.activeConfig = new  MentionConfig();
    this.activeConfig.itemsFunction = this.itemsimput.itemsFunction;
    this.activeConfig.itemsColumn  = this.itemsimput.itemsColumn;
    this.activeConfig.triggerChar = ' ';
    this.activeConfig.labelKey = 'label'
    this.activeConfig.maxItems =  -1;
    this.activeConfig.allowSpace = false
    this.activeConfig.mentionSelect = (item: any, triggerChar?: string) => {
      return  item[this.activeConfig?.labelKey?? '']
    },
    this.activeConfig.mentionFilter = (searchString: string, items: any[]) => {
      const searchStringLowerCase = searchString.toLowerCase();
      return items.filter(e => e[this.activeConfig?.labelKey ?? ''].toLowerCase().startsWith(searchStringLowerCase));
    }
  }

  onInputEditor(event: any) {
    if (this.lastKeyCode === this.KEY_BUFFERED && event.data) {
      let keyCode = event.data.charCodeAt(0);
      this.handleKey({ keyCode, inputEvent: true });
    }
  }
  onBlurEditor(event: any) {
    this.stopEvent(event);
    this.stopSearch();
  }
  onFocusinEditor(event: any) {

  }
  onFocusoutEditor(event: any) {
    
  }
  onKeydownEditor(event: any) {
    if (!this.itemsimput.isRenderDropdown) {
      return;
    }
    this.lastKeyCode = event.keyCode;
    if (event.isComposing || event.keyCode === this.KEY_BUFFERED) {
      return true;
    }
    return this.handleKey(event);
  }
  handleKey(event: any,nativeElement: HTMLInputElement = this.elementRef.nativeElement): boolean {
    let val = getValue(nativeElement);
    let pos = getCaretPosition(nativeElement, this.iframe);
    let charPressed = event.key;
    if (!charPressed) {
      let charCode = event.which || event.keyCode;
      if (!event.shiftKey && (charCode >= 65 && charCode <= 90)) {
        charPressed = String.fromCharCode(charCode + 32);
      }
      else {
        charPressed = String.fromCharCode(event.which || event.keyCode);
      }
    }
    if(pos >1){
      let  tri = val?.substring(pos-1, pos);
      
    }

    let config =  this.triggerChars.find(x => x == charPressed);//[charPressed];
    if (config) {
      this.trigger = config;
      // this.activeConfig = config;
      this.startPos = event.inputEvent ? pos - 1 : pos;
      this.startNode = (this.iframe ? this.iframe.contentWindow.getSelection() : window.getSelection()).anchorNode;
      this.searching = true;
      this.searchString = '';
      this.showSearchList(nativeElement);
      this.updateSearchList();
    }
    else if (this.startPos >= 0 && this.searching){
      if (pos <= this.startPos) {
        this.searchList.hidden = true;
      } 
      else if (event.keyCode !== this.KEY_SHIFT && !event.metaKey && !event.altKey && !event.ctrlKey && pos > this.startPos){
        if (event.keyCode === this.KEY_BACKSPACE && pos > 0) {
          pos--;
          if (pos == this.startPos) {
            this.stopSearch();
          }
        }
        else if (this.searchList.hidden) {
          if (event.keyCode === this.KEY_TAB || event.keyCode === this.KEY_ENTER) {
            this.stopSearch();
            return true;
          }
        } else if (!this.searchList.hidden) {
          if (event.keyCode === this.KEY_TAB || event.keyCode === this.KEY_ENTER) {
            this.stopEvent(event);
          
            this.searchList.activeItem.startPos = this.startPos + 1;
            this.searchList.activeItem.pos = pos;
            this.itemSelected.emit(this.searchList.activeItem);

            let text = this.activeConfig?.mentionSelect(this.searchList.activeItem, this.activeConfig?.triggerChar)?? "";
            //insertValue(nativeElement, this.startPos + 1, pos, text, this.iframe);
   
            if ("createEvent" in document) {
              let evt = document.createEvent("HTMLEvents");
              if (this.iframe) {
                // a 'change' event is required to trigger tinymce updates
                evt.initEvent("change", true, false);
              }
              else {
                evt.initEvent("input", true, false);
              }
              nativeElement.dispatchEvent(evt);
            }
            this.startPos = -1;
            this.stopSearch();
            return false;
          }
          else if (event.keyCode === this.KEY_ESCAPE) {
            this.stopEvent(event);
            this.stopSearch();
            return false;
          }
          else if (event.keyCode === this.KEY_DOWN) {
            this.stopEvent(event);
            this.searchList.activateNextItem();
            return false;
          }
          else if (event.keyCode === this.KEY_UP) {
            this.stopEvent(event);
            this.searchList.activatePreviousItem();
            return false;
          }
        }
        if (charPressed.length!=1 && event.keyCode!=this.KEY_BACKSPACE && !this.searchList.hidden) {
          this.stopEvent(event);
          return false;
        } else  {
          let mention = val?.substring(this.startPos + 1, pos);
          if (event.keyCode !== this.KEY_BACKSPACE && !event.inputEvent) {
            mention += charPressed;
          }
          this.searchString = mention ?? "";
          if (this.activeConfig?.returnTrigger) {
            const triggerChar = (this.searchString || event.keyCode === this.KEY_BACKSPACE) ? val?.substring(this.startPos, this.startPos + 1) : '';
            this.searchTerm.emit(triggerChar + this.searchString);
          } 
          else {
            this.searchTerm.emit(this.searchString);
          }
          this.updateSearchList();
        }


      }
    }

    if (event.key === 'Backspace' || event.key === 'Delete') {
      const selection = window.getSelection();
      const range = selection?.getRangeAt(0);
      const isBeforeSpan = range?.startContainer.nodeType === Node.TEXT_NODE &&
        range?.startContainer.previousSibling instanceof HTMLSpanElement &&
        (range?.startOffset === 0);
      if (isBeforeSpan) {
        event.preventDefault(); 
        this.removeSpan();
      }
    }

    let control = ['Enter'];
    if (control.includes(event.key)) return false;
    return true;
  }
  removeSpan() {
    const selection = window.getSelection();
    const range = selection?.getRangeAt(0);

    if (range?.startContainer.previousSibling instanceof HTMLSpanElement) {
      range.startContainer.previousSibling.remove();
    } else if (range?.startContainer.nextSibling instanceof HTMLSpanElement) {
      range.startContainer.nextSibling.remove();
    }
  }
  stopSearch() {
    if (this.searchList && !this.searchList.hidden) {
      this.searchList.hidden = true;
      this.closed.emit();
    }
    this.searching = false;
  }
  stopEvent(event: any) {
    //if (event instanceof KeyboardEvent) { // does not work for iframe
    if (!event.wasClick) {
      event.preventDefault();
      event.stopPropagation();
      event.stopImmediatePropagation();
    }
  }
  
  updateSearchList() {
    let matchesFunction: any[] = [];
    let matchesColumn: any[] = [];
    let objectsFunction = this.activeConfig?.itemsFunction ?? [];
    let objectsColumn = this.activeConfig?.itemsColumn ?? [];
    // disabling the search relies on the async operation to do the filtering
    if (this.searchString) {
      objectsFunction = this.activeConfig?.mentionFilter(this.searchString, objectsFunction) ?? [];
      objectsColumn = this.activeConfig?.mentionFilter(this.searchString, objectsColumn) ?? [];
    }
    matchesFunction = objectsFunction;
    matchesColumn = objectsColumn;
    
    // update the search list
    if (this.searchList) {
      this.searchList.itemsFutions = matchesFunction;
      this.searchList.itemsColunms = matchesColumn;
      this.searchList.hidden = (matchesFunction.length == 0 && matchesColumn.length == 0 );
    }
  }
  showSearchList(nativeElement: HTMLInputElement) {
    if (!this.itemsimput.isRenderDropdown) {
      return;
    }
    this.opened.emit(); 
    if (this.searchList == null) {
      let componentRef = this._viewContainerRef.createComponent(MentionListComponent);
      this.searchList = componentRef.instance;
      componentRef.instance['itemClick'].subscribe(() => {
        nativeElement.focus();
        let fakeKeydown = { key: 'Enter', keyCode: this.KEY_ENTER, wasClick: true };
        this.handleKey(fakeKeydown);
      });
    } 
    this.searchList.activeIndex = 0;
    window.requestAnimationFrame(() => {
      this.searchList.reset();
      if (this.searchList.itemsColunms.length <= 0) {
        this.searchList.hideList();
      }
    });  
    this.searchList.position(nativeElement, this.iframe);
  }
}

