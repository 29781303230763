import { Component, Input, OnChanges, ViewEncapsulation } from '@angular/core';
import { ProcessBarCondition } from 'src/app/const/const';

@Component({
  selector: 'pivot-process-bar',
  templateUrl: './process-bar.component.html',
  styleUrls: ['./process-bar.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ProcessBarComponent implements OnChanges {
  @Input() condition: string;
  progress: number = 0;
  showTick: boolean;
  ProcessBarCondition = ProcessBarCondition;

  constructor() { }

  ngOnChanges() {
    this.showTick = false;
    if (this.condition === ProcessBarCondition.DONE) {
      this.showTick = true;
      this.progress = 0;
    }
  }
}