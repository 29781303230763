import { Observable } from 'rxjs';
import { CanDeactivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { WidgetSettingComponent } from '../../module/widget-setting/widget-setting.component';
import { ROUTE_PATH } from '../../../app/const/route-path';
export class CanDeactivateWidget implements CanDeactivate<WidgetSettingComponent> {
    constructor() {

    }
    canDeactivate(component: WidgetSettingComponent,
        currentRoute: ActivatedRouteSnapshot,
        currentState: RouterStateSnapshot,
        nextState?: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
        if (nextState?.url === ("/" + ROUTE_PATH.AUTH)) return true;
        return component.canDeactivate();
    }
}