import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { lastValueFrom } from 'rxjs';
import { ResponseData } from '../../../app/models/api-model';
import { Staff } from '../../../app/models/response/staff.ro';
import { API_APP, PIVOT_CLIENT_API_URL } from '../../config/app.config';
import { ApiService } from '../api.service';
import { LocalStorageKey } from './../../_helper/local-storage.helper';
import {ErrorHandleService} from '../error-handle.service';
import { SAUCER_LOG_ACTION, SaucerLogService } from '../saucer-logs/saucer-log.service';



@Injectable({ providedIn: 'root' })
export class StaffService extends ApiService {

  public sessionStaffData: any = null;

  constructor(
    http: HttpClient, 
    router: Router, 
    errorHandle: ErrorHandleService,
    private saucerLogService : SaucerLogService
  ) {
    super(http, router, errorHandle);
  }

  async getAll(isSupport: boolean) {
    let apiUrl = API_APP.STAFF.CONTROLLER + API_APP.STAFF.ALL;
    let apiPath = apiUrl.replace(PIVOT_CLIENT_API_URL, "");
    if(isSupport) {
      apiUrl = apiUrl + "/1"
    } else {
      apiUrl = apiUrl + "/0"
    }
    let result = await this.get(apiUrl);

    if (result.statuscode === 200) {
      this.saucerLogService.system({
        apiPath,
        body: result,
        statusCode: result.statuscode
      }, {action: SAUCER_LOG_ACTION.COMMON_CALL_API.GET});
  
      this.sessionStaffData = result?.data || [];
    } else {
      this.saucerLogService.error({
        apiPath,
        body: result,
        statusCode: result.statuscode
      }, {action: SAUCER_LOG_ACTION.COMMON_CALL_API.GET});
    }

    return this.mapModel(result);
  }

  async getAllNotTeam(isSupport: boolean) {
    let apiUrl = API_APP.STAFF.CONTROLLER + API_APP.STAFF.NOTTEAM;
    if(isSupport) {
      apiUrl = apiUrl + "/1"
    } else {
      apiUrl = apiUrl + "/0"
    }
    let result = await this.get(apiUrl);
    return this.mapModel(result);
  }

  async getDetail(): Promise<ResponseData> {
    let apiUrl = API_APP.STAFF.CONTROLLER + API_APP.STAFF.DETAIL;
    const res = await this.get(apiUrl);

    return this.mapModel(res);
  }

  async getByTeam(teamCd: string, isSupport: boolean) {
    let parameter = [
      teamCd,
      (isSupport) ? "1" : "0"
    ];
    let apiUrl = API_APP.STAFF.CONTROLLER + API_APP.STAFF.TEAM;
    let result = await this.get(apiUrl, parameter);
    return this.mapModel(result);
  }
  getStaffsSmall(listStaffNotTeam:  Staff[] = []) {
    return Promise.resolve(listStaffNotTeam.slice(0, listStaffNotTeam.length));
  }
  
  async delete(staffid: string) {
    let uri = API_APP.STAFF.CONTROLLER + API_APP.STAFF.DELETE;
    uri = uri.replace('{id}', staffid);
    let apiPath = uri.replace(PIVOT_CLIENT_API_URL, "");
    let resData = await this.httpdelete(uri);
    const oldDeleteDatas = [...this.sessionStaffData || []].filter((item: any) => item?.id === staffid) || [];
    if (resData.statuscode === 200) {
      this.saucerLogService.system({
        apiPath,
        body: resData,
        statusCode: resData.statuscode,
        content: JSON.stringify({
          old: oldDeleteDatas,
          new: null
        })
      }, {action: SAUCER_LOG_ACTION.STAFF_INFO.DELETE_STAFF});
    } else {
      this.saucerLogService.error({
        apiPath,
        body: resData,
        content: JSON.stringify({
          old: oldDeleteDatas,
          new: null
        })
      }, {action: SAUCER_LOG_ACTION.STAFF_INFO.DELETE_STAFF});
    }

    return resData;
  }

  mapModel(data: any) {
    let staffs = [];
    const dataAllStaff = localStorage.getItem(LocalStorageKey.ALL_STAFF);
    if (dataAllStaff) {
      staffs = JSON.parse(dataAllStaff);
    }

    let res: ResponseData<Staff[]> = new ResponseData<Staff[]>();
    let dataArr = [];
    res.statuscode = data.statuscode;
    res.statusmessage = data.statusmessage;
    if (res.statuscode == 200) {
      for (const element of data.data || []) {
        const staff = new Staff();
        staff.id = element.id;
        staff.fullName = staffs?.find((stf: any) => stf.staffCd == element.staffcd)?.fullName || element.staffnm;
        staff.phone = element.phone;
        staff.email = element.email;
        staff.staffCd = element.staffcd;
        staff.teamName = element.teamname;
        staff.userId = staffs?.find((stf: any) => stf.staffCd == element.staffcd)?.fullName || element.staffnm;
        staff.kanaName = element.kananm;
        staff.occupations = element.occupations;
        staff.positions = element.positions;
        staff.roleCd = element.rolecd;
        staff.roleName = element.rolename;
        staff.teamCd = element.teamcd;
        staff.insdate = element.insdate || new Date(element.insdate);
        staff.upddate = element.insdate || new Date(element.upddate);
        staff.insstfcd = element.insstfcd;
        staff.updstfcd = element.updstfcd;
        staff.insstfnm = staffs?.find((stf: any) => stf.staffCd == element.insstfcd)?.fullName || element.insstfnm;
        staff.updstfnm = staffs?.find((stf: any) => stf.staffCd == element.updstfcd)?.fullName || element.updstfnm;
        staff.isSupport = element.issupport;

        dataArr.push(staff);
      }
    }
    res.data = dataArr;
    return res;
  }

  async uploadAvatar(file: File): Promise<any> {
    if (!file) {
      return;
    }

    try {
      const formData = new FormData();
      formData.append('file', file, file.name);
      const apiUrl = API_APP.STAFF.CONTROLLER + API_APP.STAFF.UPLOAD_AVATAR;

      const res: any = await lastValueFrom(this.http.post(apiUrl, formData, { reportProgress: true, observe: 'events' }));

      return res.body;
    } catch (e) {
      return this.defaultValue();
    }
  }
}
