import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { API_APP } from '../../config/app.config';
import { ApiService } from '../api.service';
import { ErrorHandleService } from '../error-handle.service';



@Injectable({ providedIn: 'root' })
export class SearchLogService extends ApiService {
  constructor(http: HttpClient, router: Router, errorHandle: ErrorHandleService) {
    super(http, router, errorHandle);
  }

  async insert(contents: string[], inuse: boolean) {
    let apiUrl = API_APP.SEARCHLOG.CONTROLLER + API_APP.SEARCHLOG.INSERT;
    await this.post(apiUrl, undefined, {
      contents,
      inuse
    });
  }
  async getFrequentlySearches() {
    let apiUrl = API_APP.SEARCHLOG.CONTROLLER + API_APP.SEARCHLOG.GET_FREQUENTLY_SEARCH;
    let resData = await this.get(apiUrl);
    return resData;
  }
}
