export class ZaitakuDetailDTO {
    jigNo?: string;
    siteiNo?: string;
    countflg: boolean;
    hasSocialLicense: boolean;
    hasAllRehabProfessionals: boolean;
    isRehabQualified: boolean;
    isRehab20Min: boolean;
    isRehab3PerWeek: boolean;
    month?: string;
    admission?: number;            // 識別9
    discharge?: number;            // 識別10
    serviceType?: number;          // 識別11
    healthFacility?: string;       // 識別12
    physicalTherapists?: string;   // 識別13
    careHealthFacility?: string;   // 識別14
    coumselors?: string;           // 識別15
    aspiration?: number;           // 識別16
    feeding?: number;              // 識別17
    sameDayDischarge?: number;  
    sameDayAdmissionDischarge?: number;  
}