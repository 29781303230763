<pivot-modal-template *ngIf="isDisplayModal" [modalTemplate]="modalData" (closeModalHandler)="onSubmit(false)">
    <div modal-body>
        <div class="dlgd-content dlgd-flex-justify-cen dlgd-fex-center">
            <div class="dlgd-txt"><label>{{MESSAGE_TEXT.CONFIRM_DELETE}}</label></div>
        </div>
    </div>
    <div modal-bottom class="dlgd-content">
        <div class="dlgd-div-btn">
            <pivot-text-button class="dlgd-btn" (btnClick)="onSubmit(false)" [buttonType]="buttonType.SECONDARY"
                [buttonText]="BUTTON.NO">
            </pivot-text-button>
            <pivot-text-button class="dlgd-btn" (btnClick)="onSubmit(true)" [buttonType]="buttonType.SECONDARY"
                [buttonText]="BUTTON.YES">
            </pivot-text-button>
        </div>
    </div>
</pivot-modal-template>