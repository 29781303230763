import { lyl, StyleRenderer, ThemeRef, ThemeVariables, WithStyles } from '@alyle/ui';
import { LyDialogRef, LY_DIALOG_DATA } from '@alyle/ui/dialog';
import {
  ImgCropperConfig, ImgCropperErrorEvent, ImgCropperEvent, LyImageCropper, STYLES as CROPPER_STYLES
} from '@alyle/ui/image-cropper';
import { STYLES as SLIDER_STYLES } from '@alyle/ui/slider';
import { ChangeDetectionStrategy, Component, Inject, ViewChild } from '@angular/core';
import { BUTTON } from '../../../../app/const/text-common';
import { ButtonType } from '../../../../app/enum/common-enum';

const STYLES = (_theme: ThemeVariables, ref: ThemeRef) => {
  ref.renderStyleSheet(SLIDER_STYLES);
  ref.renderStyleSheet(CROPPER_STYLES);
  const slider = ref.selectorsOf(SLIDER_STYLES);
  const cropper = ref.selectorsOf(CROPPER_STYLES);

  return {
    root: lyl`{
      ${cropper.root} {
        max-width: 600px
        height: 320px
      }
    }`,
    sliderContainer: lyl`{
      position: relative
      ${slider.root} {
        width: 80%
        position: absolute
        left: 0
        right: 0
        margin: auto
        top: -32px
      }
    }`,
    slider: lyl`{
      padding: 1em
    }`
  };
};

@Component({
  selector: 'pivot-image-cropper',
  templateUrl: './image-cropper.component.html',
  styleUrls: ['./image-cropper.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    StyleRenderer
  ]
})
export class ImageCropperComponent implements WithStyles {

  classes = this.sRenderer.renderSheet(STYLES, 'root');
  ready: boolean;
  scale: number;
  minScale: number;
  BUTTON = BUTTON;
  buttonType = ButtonType;

  @ViewChild(LyImageCropper, { static: true }) cropper: LyImageCropper;
  myConfig: ImgCropperConfig = {
    width: 150,
    height: 150,
    // type: 'image/png',
    keepAspectRatio: true,
    responsiveArea: true,
    resizableArea: true,
    round: true,
  };

  url: string;

  constructor(
    @Inject(LY_DIALOG_DATA) private event: Event,
    readonly sRenderer: StyleRenderer,
    public dialogRef: LyDialogRef,
  ) {

  }

  ngOnInit(): void {
    this.dialogRef.afterOpened.subscribe(() => {
      this.cropper.selectInputEvent(this.event);
    });
  }

  onCropped(e: ImgCropperEvent) {
  }

  onLoaded(e: ImgCropperEvent) {
  }

  onError(e: ImgCropperErrorEvent) {
    this.dialogRef.close();
  }

  onUpload() {
    // this.ref.close(this.croppedImage);
  }

  onSelectFile(event: any) {
    if (event.target.files && event.target.files[0]) {
      this.cropper.selectInputEvent(event);
    }
  }
}
