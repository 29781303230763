

export class Mentions {

  items?:any[];
  itemsFunction?:any[];
  itemsColumn?:any[];
  triggerChar?:string;

  labelKey?:string;

  maxItems?:number;

  disableSort?:boolean;

  disableSearch?:boolean;

  dropUp?:boolean;

  allowSpace?:boolean;

  returnTrigger?:boolean;

  mentionSelect:(item:any, triggerChar?:string) => (string);

  mentionFilter:(searchString:string, items?:any) => (any[]);
}


export class MentionConfig extends Mentions {
  mentions?:Mentions[];
  
  disableStyle?:boolean;  
}