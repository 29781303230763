<pivot-single-pannel-template [titlePage]="titlePage" id="dashboardlist">
    <div pannel-left-action>
        <div class="add-folder">
            <pivot-text-button class="btn-add-folder" [disabled]="disableBtnAddFolder"
                [buttonType]="buttonType.SECONDARY" [buttonText]="BUTTON.FOLDER_CREATE" (btnClick)="openDialogAddFolder()">
            </pivot-text-button>
        </div>
    </div>

    <div class="dashboard-pannel-left-content" pannel-left-content>
        <pivot-search-bar class="search-folder" [searchParams]="searchFolder"
            (onFilterData)="onFilterFolderData($event)">
        </pivot-search-bar>
        <div class="dashboard-btn-grp">
            <pivot-text-button [iconType]="iconType.EDIT" (btnClick)="openDialogEditFolder()" *ngIf="dspBtnEditFolder"
                [disabled]="disableBtnEditFolder" [buttonType]="buttonType.INFO" [buttonText]="BUTTON.EDIT">
            </pivot-text-button>
            <pivot-text-button class="btn-delete" *ngIf="dspBtnDeleteFolder" [disabled]="disableBtnDeleteFolder"
                [iconType]="iconType.DELETE" (btnClick)="onDeleteFolder()" [buttonType]="buttonType.DANGER"
                [buttonText]="BUTTON.DELETE">
            </pivot-text-button>
        </div>
        <div class="table-folder-format">
            <pivot-table [data]="folderData" [selectedRow]="folderSelecteRow" (clickRow)="onFilterDashboard($event)"
                [selectedRows]="folderSelecteds" (checkRows)="checkRowsFolderTable($event)"></pivot-table>
        </div>
    </div>
    <div class="pannel-right-content" pannel-right-content>
        <div class="dashboard-list-content">
            <div class="dashboard-list-filter">
                <pivot-search-bar [searchParams]="searchDashboard" (onFilterData)="onFilterDashboardData($event)">
                </pivot-search-bar>
                <div class="btn-dashboard-grp">
                    <pivot-text-button class="btn-add-dashboard" [buttonType]="buttonType.SECONDARY"
                        [disabled]="disableBtnAddDashboard" (btnClick)="openDialogAddDashboard()"
                        [buttonText]="addDashboardStr"></pivot-text-button>
                </div>
            </div>
            <div class="table-right">
                <pivot-table [saucerLogPagingAction] = "logAction.CHANGE_PAGE" class="table" [isLoading]="isTableDashLoading" [selectedRows]="dashboardSelecteds"
                    [data]="dashboardTableData" (checkRows)="checkRowsDashboardTable($event)"
                    (clickRow)="movetoDashboardView($event)"
                    [checkAllMode]="checkAllMode.ALL_PAGE">
                    <div action-buttons class="table-button-wrapper">
                        <pivot-text-button [iconType]="iconType.EDIT" *ngIf="dspBtnEditDashboard"
                            [disabled]="disableBtnEditDashboard" (btnClick)="onEditDashboard()"
                            [buttonType]="buttonType.INFO" [buttonText]="BUTTON.EDIT">
                        </pivot-text-button>
                        <pivot-text-button class="btn-delete" *ngIf="dspBtnDeleteDashboard"
                            [disabled]="disableBtnDeleteDashboard" [iconType]="iconType.DELETE" [buttonText]="BUTTON.DELETE"
                            [buttonType]="buttonType.DANGER" (btnClick)="onDeleteDashboard()">
                        </pivot-text-button>
                    </div>
                </pivot-table>
            </div>
        </div>
    </div>
</pivot-single-pannel-template>

<pivot-dialog-folder-editor [isTemplate]="isDashboardTemplate" [data]="folderEditData" [folderType]="folderType" [isShowAssign]="!isDashboardTemplate" [assignPermission]="assignPermission"
    [isDisplayModal]="isDisplayFolderModal" [modalData]="modalFolder" (onSubmitData)="onChangeDataFolder($event)">
</pivot-dialog-folder-editor>

<pivot-dialog-dashboard-editor [data]="dashboardEditData" [isDisplayModal]="isDisplayDashboardModal" [folders]="folderEdits"
    [isUpdate]="isUpdate" [folderSelected]="folderSelected" [modalData]="modalAddDashboard" [isShowAssign]="!isDashboardTemplate" [logAction]="logAction"
    (onSubmitData)="onChangeDataDashboard($event)">
</pivot-dialog-dashboard-editor>

<pivot-confirm-del-selected-dialog [inputData]="delTxt" [isDisplayModal]="isDisplayModalDelete"
    [modalData]="modalDelete" [deleteItems]="deleteArray" (onSubmitData)="onSubmitDeleteModel($event)">
</pivot-confirm-del-selected-dialog>