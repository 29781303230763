import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { API_APP, PIVOT_CLIENT_API_URL } from '../../config/app.config';
import { ApiService } from '../api.service';
import * as _ from 'lodash';
import {ErrorHandleService} from '../error-handle.service';
import { SAUCER_LOG_ACTION, SaucerLogService } from '../saucer-logs/saucer-log.service';



@Injectable({ providedIn: 'root' })
export class NotifyService extends ApiService {
  constructor(
    http: HttpClient, 
    router: Router, 
    errorHandle: ErrorHandleService,
    private saucerLogService : SaucerLogService
  ) {
    super(http, router, errorHandle);
  }

  async inviteStaffsToPivot(bodyData: any) {
    let apiUrl = API_APP.NOTIFY.CONTROLLER + API_APP.NOTIFY.INVITESTAFFSTOPIVOT;
    const apiPath = apiUrl.replace(PIVOT_CLIENT_API_URL, "");
    let resData =  await this.post(apiUrl, [], bodyData);
    if (resData.statuscode === 200) {
      this.saucerLogService.system({
        apiPath,
        body: resData,
        statusCode: resData.statuscode,
        content: JSON.stringify({
          old: null,
          new: {
            user : bodyData?.mail || bodyData?.phone || ""
          }
        })
      }, {action: SAUCER_LOG_ACTION.STAFF_INFO.INVITE_STAFF});
    } else {
      this.saucerLogService.error({
        apiPath,
        body: resData,
        statusCode: resData.statuscode,
        content: JSON.stringify({
          old: null,
          new: {
            user : bodyData?.mail || bodyData?.phone || ""
          }
        })
      }, {action: SAUCER_LOG_ACTION.STAFF_INFO.INVITE_STAFF});
    }

    return resData;
  }

  async getPendingInvitation() {
    let apiUrl = API_APP.NOTIFY.CONTROLLER + API_APP.NOTIFY.LIST_PENDING_INVITATION;
    let resData =  await this.get(apiUrl);

    let lstPendingInvitation: any[] = [];
    if (resData && resData.data && resData.data.length > 0) {
      lstPendingInvitation = [...resData.data].map((el: any) => {
        return {
          id: el.id,
          user: el?.email || el?.phone || "",
          isHoverRemovePendingBtn: false
        }
      });
    }
    return lstPendingInvitation;
  }

  async deletePendingInvitation(id: string, user : string) {
    let apiUrl = API_APP.NOTIFY.CONTROLLER + API_APP.NOTIFY.DELETE_PENDING_INVITATION;
    apiUrl = apiUrl.replace('{id}', id);
    const apiPath = apiUrl.replace(PIVOT_CLIENT_API_URL, "");
    let resData =  await this.httpdelete(apiUrl);
    if (resData.statuscode === 200) {
      this.saucerLogService.system({
        apiPath,
        body: resData,
        statusCode: resData.statuscode,
        content: JSON.stringify({
          old: { idInvitation: id, userInvitation: user },
          new: null
        })
      }, {action: SAUCER_LOG_ACTION.STAFF_INFO.CANCEL_INVITE});
    } else {
      this.saucerLogService.error({
        apiPath,
        body: resData,
        statusCode: resData.statuscode,
        content: JSON.stringify({
          old: { idInvitation: id, userInvitation: user },
          new: null
        })
      }, {action: SAUCER_LOG_ACTION.STAFF_INFO.CANCEL_INVITE});
    }

    return resData;
  }


}
