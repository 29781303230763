
import { environment } from '../../environments/environment';
export class HelpListHelper {
  public static replaceImage(file: File | { name: string; attachmentUrl: string }, content: string) {
    let urlImg: string;
    if (file instanceof File) {
      urlImg = window.URL.createObjectURL(file);
    } else {
      urlImg = file.attachmentUrl;
    }
    const regexFileName = file.name.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
    const FILE_UPLOAD_REGEX = new RegExp(
      `\!\\[([^\\[\\]]+)\\]\\\((${regexFileName})(\\s+\\".*\\")?\\)`,
      'g',
    );
    return content.replace(FILE_UPLOAD_REGEX, (
      match: string,
      p1: string,
      p2: string,
      p3: string,
    ) =>
      p2 === file.name ? `![${p1 || ''}](${urlImg}${p3 || ''})` : match,
    );
  }

  public static createAnchorsToText(text: string) {
    const textWithoutHTML = text.replace(/&/g, '&amp;')
      .replace(/</g, '&lt;')
      .replace(/>/g, '&gt;')
      .replace(/"/g, '&quot;');
    const regexp = /((?:http(s)?:\/\/)[\w.-]+(?:\.[\w.-]+)+[\w\-._~:/?#[\]@!$&'()*+%,;=]+)/igm;
    const map: any = {};
    return textWithoutHTML.replace(regexp, (_, match, __, pos) => {
      // This map helps us to keep a count of the duplicate matches.
      if (map[match] !== undefined) {
        map[match]++;
      } else {
        map[match] = 0;
      }
      // These next few lines handle duplicates. We want to handle cases like this:
      // <a href="myurl.com">foo</a> bar baz myurl.com.
      let testString = textWithoutHTML.replace(new RegExp(this.escapeRegExp(match), 'ig'), '$');
      const newPos = pos - map[match] * (match.length - 1);
      testString = this.insertIntoString(testString, newPos, match);

      // Detect if the url was found here -> <a href="#here#">
      const hrefRegex = new RegExp(`<a[^>]*href="${this.escapeRegExp(match)}"[^>]*>[^<]*<\/a>`, 'ig');
      const isHref = hrefRegex.test(testString);

      // Detect if the url was found here -> <a href="...">#here#</a>
      const anchoredRegex = new RegExp(`<a[^>]*>[^<]*${this.escapeRegExp(match)}[^<]*<\/a>`, 'ig');
      const isAnchored = anchoredRegex.test(testString);

      // If the url is found in href or anchor, then do nothing with it, it needs no further treatment.
      if (isAnchored || isHref) {
        return match;
      }
      const hasProtocol = /^http[s]?:\/\/.*$/igm.test(match);
      return `<a href="${hasProtocol ? '' : 'http://'}${match}" target="_blank" rel="noopener noreferrer">${match}</a>`;
    });
  }

  private static insertIntoString(str: string, pos: number, fragment: string) {
    return str.slice(0, pos) + fragment + str.slice(pos + 1, str.length);
  }

  private static escapeRegExp(text: string) {
    return text.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
  }

  public static insertInString(str: string, code: string) {
    return code + str.slice(code.length, str.length);
  }

  public static getS3Url = (url: string) => {
    const newImageUrl = environment.USER_DATA_URL + url;
    return newImageUrl + '?' + Date.now().toString();
  }

  public static removeDomainFromUrl = (url: string): string => {
    const urlObj = new URL(url);
    return urlObj.pathname + urlObj.search + urlObj.hash;
  }

  public static getDisplayMarkdown(item: any): string {
    let str = item?.content || '';
    str = str.replace(/\n/g, '<br>');
    if (item?.filenm) {
      str = this.replaceImage(
        {
          name: item.filenm,
          attachmentUrl: this.getS3Url(item.attachmenturl),
        },
        str,
      );
    }
    return str;
  }

  public static getDisplayContent(item: any): any[] {
    let str = item?.content || '';
    if (item?.filenm) {
      str = this.replaceImage(
        {
          name: item.filenm,
          attachmentUrl: this.getS3Url(item.attachmenturl),
        },
        str,
      );
    }
    return this.getDisplayItem(str);
  }

  public static getDisplayItem(item: string): any[] {
    let displayContent: any[] = [];
    let pattern = /!\[Image\]\((.*?)\)/g;
    let matches = item.match(pattern);
    if (matches) {
      matches.map((x: any) => {
        let divArray = (item.split(x)[0]).trim();
        if (divArray) {
          displayContent.push({
            type: 'div',
            content: divArray
          });
        }
        displayContent.push({
          type: 'img',
          content: x.substring(9, x.length - 1)
        })
        item = item.replace(divArray, "").replace(x, "");

      });
      item = item.trim();
      if (item) {
        displayContent.push({
          type: 'div',
          content: item
        });
      }
    } else {
      displayContent.push({
        type: 'div',
        content: item
      });
    }
    return displayContent;
  }
}

