import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import Utils from 'src/app/util/utils';
import { API_APP } from '../../../app/config/app.config';
import { ResponseData } from '../../../app/models/api-model';
import { FeaturePublicDTO } from '../../../app/models/request/featurepublic.dto';
import { FeaturePublic } from '../../../app/models/response/featurepublic.ro';
import { ApiService } from '../api.service';
import {ErrorHandleService} from '../error-handle.service';

@Injectable({ providedIn: 'root' })
export class FeatureService extends ApiService {

  constructor(http: HttpClient, router: Router, errorHandle: ErrorHandleService) {
    super(http, router, errorHandle);
  }

  async getAll() {
    let apiUrl = API_APP.FOLDER.CONTROLLER + API_APP.FOLDER.ALL;
    let resData = await this.get(apiUrl);
    return await this.mapModel(resData);
  }

  async getByType(publicCd: string = '') {
    let parameter = [
      publicCd
    ];
    let apiUrl = API_APP.PEATUREPUBLIC.CONTROLLER + API_APP.PEATUREPUBLIC.GETBYTYPE;
    let resData = await this.get(apiUrl, parameter);
    return await this.mapModel(resData);
  }

  async mapModel(data: any) {
    let res: ResponseData<FeaturePublic[]> = new ResponseData<FeaturePublic[]>();
    let dataArr = [];
    res.statuscode = data.statuscode;
    res.statusmessage = data.statusmessage;
    if (res.statuscode === 200) {
      for await (const element of data.data) {
        let feature = new FeaturePublic();
        feature.id = element.id;
        feature.publicCd = element.publiccd;
        feature.featureType = element.featuretype;
        feature.teamcd = element.teamcd;
        feature.staffcd = element.staffcd;
        feature.delflg = element.delflg;
        feature.insdate = element.insdate;
        feature.insstfcd = element.insstfcd;
        feature.upddate = element.upddate;
        feature.updstfcd = element.updstfcd;
        dataArr.push(feature);
      }
    }
    res.data = dataArr;
    return res;
  }

  insertOrUpdate(request: any) {
    let uri = API_APP.PEATUREPUBLIC.CONTROLLER + API_APP.PEATUREPUBLIC.INSERTORUPDATE;
    return this.post(uri, [], request);
  }

  delete(folders: FeaturePublicDTO[]) {
    let uri = API_APP.PEATUREPUBLIC.CONTROLLER + API_APP.PEATUREPUBLIC.DELETE;
    return this.post(uri, [], folders);
  }
}
