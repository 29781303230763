import { ChangeDetectorRef, Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ProcessBarCondition } from 'src/app/const/const';
import { BUTTON, COMMON_TEXT, SYSTEM_SETTING } from '../../../const/text-common';
import { ButtonType, StatusSynsMasterData } from '../../../enum/common-enum';
import { ModalTemplate } from '../../../models/common-model';
import { OfficeAPIService } from '../../../services/modules/office.service';
import { SyncDataOracleService } from '../../../services/modules/sync-data-oracle.service';
import {ErrorHandleService} from 'src/app/services/error-handle.service';
import {FUNCTION_TITLE_TEXT} from 'src/app/const/error-text';
@Component({
  selector: 'pivot-sync-master-data',
  templateUrl: './sync-master-data.component.html',
  styleUrls: ['./sync-master-data.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SyncMasterDataComponent implements OnInit {
 
  BUTTON = BUTTON;
  SYSTEM_SETTING = SYSTEM_SETTING;
  COMMON_TEXT = COMMON_TEXT;
  buttonType = ButtonType;
  syncInfoDetailModal = new ModalTemplate();
  showSyncInfoDetailDialog = false;
  syncDetailData: any[] = [];
  isSyncing: boolean = false;
  isTitleMappingSyncSuccess: boolean = true;

  isDarkMode: boolean = true;
  iconType: boolean = false;
  syncStatusIconSrc: string = "";
  statusOfAllSyns: StatusSynsMasterData ; // 0 : loading, 1 : sucessfull, 2: fail

  constructor(
    private syncDataOracle: SyncDataOracleService,
    private officeService: OfficeAPIService,
    private errorHandleService: ErrorHandleService,
    private cdr: ChangeDetectorRef
  ) { 
    const bodyElement = document.getElementsByTagName("body")[0];
    this.isDarkMode = bodyElement.classList.contains("navi") || bodyElement.classList.contains("dark") ? true : false;
    this.syncStatusIconSrc = this.isDarkMode ? 'dark': 'normal' ;
  }

  ngOnInit(): void {
  }

  async onSyncMasterData() {
    this.statusOfAllSyns = StatusSynsMasterData.LOADING;
    this.errorHandleService.setFunctionTitle(FUNCTION_TITLE_TEXT.SYNC_DATA_FAIL);
    this.errorHandleService.setIsNotShowFunctionTitle(true);
    if (this.isSyncing === false) {
      this.isSyncing = true;
      const promises = [
        this.syncDataOracle.syncCorp(),
        this.syncDataOracle.syncOffice(),
        this.syncDataOracle.syncBlock(),
        this.syncDataOracle.syncUnit(),
        this.syncDataOracle.syncOfficeService(),

      ];

      this.syncDetailData = [];
      this.syncDetailData.push({ name: '組織データの同期を実装しています。', status: ProcessBarCondition.NOT_START });
      this.syncDetailData.push({ name: '事業所データの同期を実装しています。', status: ProcessBarCondition.NOT_START });
      this.syncDetailData.push({ name: 'ブロックデータの同期を実装しています。', status: ProcessBarCondition.NOT_START });
      this.syncDetailData.push({ name: 'ユニットデータの同期を実装しています。', status: ProcessBarCondition.NOT_START });
      this.syncDetailData.push({ name: '事業所サービスデータの同期を実装しています。', status: ProcessBarCondition.NOT_START });
      this.syncDetailData.push({ name: '部屋マスタデータの同期を実装しています。', status: ProcessBarCondition.NOT_START });
      this.syncDetailData.push({ name: 'タイトルマスタデータの同期を実装しています。', status: ProcessBarCondition.NOT_START });

      this.openSyncInfoDetailDialog();

      this.syncDetailData[0].status = ProcessBarCondition.START;
      this.syncDetailData[1].status = ProcessBarCondition.START;
      this.syncDetailData[2].status = ProcessBarCondition.START;
      this.syncDetailData[3].status = ProcessBarCondition.START;
      this.syncDetailData[4].status = ProcessBarCondition.START;
      this.syncDetailData[5].status = ProcessBarCondition.START;
      this.syncDetailData[6].status = ProcessBarCondition.START;

      const results = await Promise.allSettled(promises);
      results.forEach((result, index) => {
        if (result.status === 'rejected') {
          this.syncDetailData[index].status = ProcessBarCondition.ERROR;
          this.sendErrorIfConditionFalse(false);
        } else {
          this.syncDetailData[index].status = ProcessBarCondition.DONE;
        }
      });
      await this.syncRoom();
      await this.syncTitleData();
    } else {
      this.openSyncInfoDetailDialog();
    }
    
    const allDone = this.syncDetailData.every(detail => detail.status == ProcessBarCondition.DONE);
    this.statusOfAllSyns = allDone ? StatusSynsMasterData.SUCCESS : StatusSynsMasterData.FAILED;
  }

  async syncRoom(){
    try {
      await this.syncDataOracle.syncRoom();
      this.syncDetailData[5].status =  ProcessBarCondition.DONE;
    }
    catch (error) {
      this.syncDetailData[5].status =  ProcessBarCondition.ERROR;
      this.sendErrorIfConditionFalse(false);
    }
  }
  
  async syncTitleData(){
    const syncTitleTasks: any[] = [];
      try {
        await this.syncDataOracle.deleteSyncTitleMapping({ officecd: '00' });
        await this.syncDataOracle.syncTitleMapping({ officecd: '00' });
      } catch (error) {
        this.isTitleMappingSyncSuccess = false;
      }

      const offices = await this.officeService.getAll();
      if(offices && offices.data && offices.data.length > 0) {
        offices.data.forEach((office: any) => {
          syncTitleTasks.push(() => this.syncDataWithRetry(office));
        });
      }

      await this.concurrentPromisePool<void>(syncTitleTasks, 6);
      this.syncDetailData[6].status = this.isTitleMappingSyncSuccess ? ProcessBarCondition.DONE : ProcessBarCondition.ERROR;
      this.sendErrorIfConditionFalse(this.isTitleMappingSyncSuccess);
      this.isSyncing = false;
      this.syncInfoDetailModal.closable = true;
  }

  sendErrorIfConditionFalse(condition: boolean) {
    if(condition == false) {
      let errorStatus = this.errorHandleService.isHasErrorDialogOrErrorScreenDisplay;
      if(errorStatus == false) {
        this.errorHandleService.openUnknowErrorDialog();
      }
    }
  }

  async syncDataWithRetry(office: any): Promise<void> {
    try {
      await this.syncDataOracle.deleteSyncTitleMapping(office);
      await this.syncDataOracle.syncTitleMapping(office);
    } catch (error: any) {
      if (error.statuscode === 502) {
        await new Promise((resolve) => setTimeout(resolve, 1000));
        await this.syncDataWithRetry(office);
      } else {
        this.isTitleMappingSyncSuccess = false;
      }
    }
  }

  async concurrentPromisePool<T>(tasks: (() => Promise<T>)[], batchSize: number): Promise<T[]> {
    const results: T[] = [];

    for (let i = 0; i < tasks.length; i += batchSize) {
      const batchTasks = tasks.slice(i, i + batchSize);
      const batchPromises = batchTasks.map(task => task());
      const batchResults = await Promise.all(batchPromises);
      results.push(...batchResults);
    }

    return results;
  }

  openSyncInfoDetailDialog() {
    this.syncInfoDetailModal.header = this.COMMON_TEXT.SYNC_MASTER_LIST;
    this.syncInfoDetailModal.style = { 'width': 'auto','background': '#000' };
    this.syncInfoDetailModal.breakpoints = { '960px': '75vw', '640px': '100vw' };
    this.syncInfoDetailModal.closable = false;
    this.syncInfoDetailModal = { ...this.syncInfoDetailModal };
    this.showSyncInfoDetailDialog = true;
    this.statusOfAllSyns = StatusSynsMasterData.LOADING;
  }

  handleModalDetailClose = () => this.showSyncInfoDetailDialog = false;
}
