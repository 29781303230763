import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { API_APP } from '../../config/app.config';
import { ApiService } from '../api.service';
import { BehaviorSubject, Observable } from 'rxjs';
import {ErrorHandleService} from '../error-handle.service';

@Injectable({ providedIn: 'root' })
export class HelpListService extends ApiService {

  public countUnReadHelpSubject: BehaviorSubject<number>;
  public countUnReadHelp: Observable<number>;
  isCheckPopup = false;
  isCheckOffice = false;

  constructor(http: HttpClient, router: Router, errorHandle: ErrorHandleService) {
    super(http, router, errorHandle);
    this.countUnReadHelpSubject = new BehaviorSubject<any>(0);
    this.countUnReadHelp = this.countUnReadHelpSubject.asObservable();
  }

  setCheckOffice(ischeck: boolean) {
    this.isCheckOffice = ischeck;
  }

  getCheckOffice(): boolean {
    return this.isCheckOffice;
  }

  setCheckPopUp(ischeck: boolean){
    this.isCheckPopup = ischeck;
  }
  getCheckPopUp():boolean{
   return this.isCheckPopup;
  }

  setCountUnRead(count:number){
    this.countUnReadHelpSubject.next(count);
  }
  getCountUnRead():number{
   return this.countUnReadHelpSubject.value;
  }

  async getAll(heplistRequest:any): Promise<any> {
    let apiUrl = API_APP.HELPLIST.CONTROLLER + API_APP.HELPLIST.ALL;
    let resData = await this.post(apiUrl, [], heplistRequest);
    return resData;
  }
  async getHelpListPost(heplistRequest:any): Promise<any> {
    let apiUrl = API_APP.HELPLIST.CONTROLLER + API_APP.HELPLIST.HELP_LIST;
    let resData = await this.post(apiUrl, [], heplistRequest);
    return resData;
  }
  async updateUnread(heplistRequest:any): Promise<any> {
    let apiUrl = API_APP.HELPLIST.CONTROLLER + API_APP.HELPLIST.UPDATE_UNREAD;
    let resData = await this.post(apiUrl, [], heplistRequest);
    return resData;
  }

  async getHelpPopup(heplistRequest:any):Promise<any>{
    let apiUrl = API_APP.HELPLIST.CONTROLLER + API_APP.HELPLIST.HELP_POPUP;
    let resData = await this.post(apiUrl, [], heplistRequest);
    return resData;
  }

  async updateHidePopup(heplistRequest:any): Promise<any> {
    let apiUrl = API_APP.HELPLIST.CONTROLLER + API_APP.HELPLIST.UPDATE_HIDE_POPUP;
    let resData = await this.post(apiUrl, [], heplistRequest);
    return resData;
  }
}
