<pivot-modal-template class="dashboard-service form-modal" [modalTemplate]="modalTemplate"
(closeModalHandler)="closeFormModal()" >
<div class="dashboard-service-body" modal-body>
  <div class="controls">
    <div *ngIf="isLoading" class="loading">
      <div class="loader"></div>
    </div>
    <div class="row">
      <label class="lbl-text">{{COMMON_TEXT.FOLDER}}</label>
      <div class="item-grp">
          <pivot-search-bar class="search-bar" [searchParams]="searchFolderParams" (onFilterData)="onFilterFolder($event)"
            [placeHolderFilter]="CREATE_DASHBOARD_TEMPLATE.SEARCH_FOLDER">
          </pivot-search-bar>
          <p-button icon="pi pi-plus" [ngClass]="'button-add'" (onClick)="showDialogAddFolder()"> </p-button>
      </div>
    
    </div>

    <div class="row">
      <label class="lbl-text">{{COMMON_TEXT.DASHBOARD}}</label>
      <div class="item-grp">
        <pivot-search-bar class="search-bar" [searchParams]="searchDashboardParams" (onFilterData)="onFilterDashboard($event)"
          [placeHolderFilter]="CREATE_DASHBOARD_TEMPLATE.SEARCH_DASHBOARD">
        </pivot-search-bar>
        <p-button icon="pi pi-plus" [ngClass]="'button-add'" (onClick)="showDialogAddDashboard()"></p-button>
      </div>
    
    </div>
  </div>
</div>
<div class="dashboard-service-bottom" modal-bottom>
  <pivot-text-button (btnClick)="cancel()" [buttonType]="buttonType.SECONDARY" [buttonText]="BUTTON.CANCEL"></pivot-text-button>
  <pivot-text-button (btnClick)="save()" [disabled]="!selectedDashboard" [buttonType]="buttonType.SECONDARY" [buttonText]="BUTTON.SELECT"></pivot-text-button>
</div>
</pivot-modal-template>

<pivot-dialog-folder-editor [folderActionLog]="SAUCER_LOG_ACTION.MENU_TEMPLATE.DASHBOARD_CREATION_DIALOG"
  [isDisplayModal]="isDspFolderDashboard" [folderType]="folderType" [modalData]="modalFolder" 
  (onSubmitData)="onChangeDataFolder($event)">
</pivot-dialog-folder-editor>

<pivot-dialog-dashboard-editor [logAction]="SAUCER_LOG_ACTION.MENU_TEMPLATE.DASHBOARD_CREATION_DIALOG"
    [isDisplayModal]="isDspDashboardDlg" [folders]="folderSelects" [isNotNavigate]="true"
    [folderSelected]="folderSelects[0]" [modalData]="modalAddDashboard" [isNotChangeFolder]="true"
    (onSubmitData)="onChangeDataDashboard($event)">
</pivot-dialog-dashboard-editor>