import { DataSourceType } from "../../../app/enum/common-enum";
import { ActivityLog } from "../api-model";
import { DataSourceConfig, DataSource } from "../response/datasource.ro";
import { ConditionDTO } from "./condition.dto";

export class DataSourceGetDTO {
    id?: String;
    ids?: Array<string>;
    folderId?: string;
    folderCd?: string;
    userId?: string;
    condition?: ConditionDTO;
}

export class DataSearch {
    datasourceCd? : String;
    widgetCd? : String;
    dashboardName? : String
}

export class DataSourceAddDTO extends ActivityLog {
    name: string; // データソース名
    folderId?: string; // フォルダ
    dsstructCd?: string;
    folderCd?: string; // フォルダ
    folderName?: string;
    description?: string;
    config: DataSourceConfig; // 事業所選択 & 列
    lastSync?: string; // 最終日更新
    delflg?: boolean;
}

export class DataSourceUpdateDTO extends DataSource {

}

export class DataSourceDeleteDTO extends DataSourceGetDTO {

}

export class DistinctedColumnOfDataRawModel {
    columnName: string; 
    data: string[];
    isFull: boolean;
}

export class MinMaxOfColumnName {
    columnName: string;
    min: number | Date | null;
    max: number | Date | null;
}

export class DataSourceInFor {
    periods: any[] = [];
    periodSelected: any | undefined;
    dstype: number = DataSourceType.TITLE;
}
