<pivot-modal-template [modalTemplate]="modalData"
    (closeModalHandler)="onSubmiDataFilter(false)">
    <div modal-body id="dlgfitergroup">
        <div class="filter-content">
            <pivot-checkbox [value]="isMinValue" (onClick)="getFirstValue()"></pivot-checkbox>
            <label [ngClass]="isDayType()? 'label-value': ''">{{COMMON_TEXT.FIRST_VALUE}}</label>
            <div class="date-group">
                <pivot-textbox [canClear]="!isMinValue" [inputParams]="minParams" 
                [id]="isDate ? 'inputUnit' : ''"
                *ngIf="!isDate || isDayType()" 
                [ngClass]="isDayType() ?'input-day': 'input-number'"
                (onFocus)="inputMinFocusIn()"
                (onFocusOut)="minMaxValidate(false, true)"
                (inputValueChange)="onInputMinValue($event)"
                [inputValue]="minValue">
                </pivot-textbox>
                <label *ngIf="isDayType()" [ngClass]="{
                   'label-day option-label' : isDayType(),
                   'label-disable' : isMinValue,
                   'input-validate' : isMinValidate && !isMinFocus,
                   'label-focus' : isMinFocus
                }">{{condition? condition.optionName : '月'}}</label>
            </div> 
            <pivot-calendar *ngIf="isDate && condition?.value != FilterGroup.DAY" 
                [view]="condition?.value == FilterGroup.YEAR_MONTH 
                || condition.value == FilterGroup.MONTH? 'month' : 'date'"
                [disabled]="isMinValue"
                [isShowTime]="isShowTime"
                [ngClass]="{ 'valid-date': isMinValidate }"
                [dateFormat]="dateFormat"
                [isTimeOnly]="isTimeOnly"
                (valueChange)="changeDateValue($event, 'min')"
                [(value)]="minValue">
            </pivot-calendar>
        </div>
        <div class="filter-content">
            <pivot-checkbox [value]="isMaxValue" (onClick)="getLastValue()"></pivot-checkbox>
            <label [ngClass]="isDayType()? 'label-value': ''">{{COMMON_TEXT.LAST_VALUE}}</label>
            <div class="date-group">
                <pivot-textbox [canClear]="!isMaxValue" [inputParams]="maxParams" 
                    *ngIf="!isDate || isDayType()" 
                    [id]="isDate ? 'inputUnit' : ''"
                    [ngClass]="isDayType() ?'input-day': 'input-number'"
                    (onFocusOut)="minMaxValidate(true, true)"
                    (onFocus)="inputMaxFocusIn()"
                    (inputValueChange)="onInputMaxValue($event)"
                    [inputValue]="maxValue">
                </pivot-textbox>
                <label *ngIf="isDayType()" [ngClass]="{
                    'label-day option-label' : isDayType(),
                    'label-disable' : isMaxValue,
                    'input-validate' : isMaxValidate && !isMaxFocus,
                    'label-focus' : isMaxFocus
                 }">{{condition? condition.optionName : '月'}}</label>
            </div>
            <pivot-calendar  *ngIf="isDate && condition?.value != FilterGroup.DAY"  
                [view]="condition?.value == FilterGroup.YEAR_MONTH || condition?.value == FilterGroup.MONTH? 'month' : 'date'"
                [isTimeOnly]="isTimeOnly"
                [disabled]="isMaxValue"
                [isShowTime]="isShowTime"
                [ngClass]="{ 'valid-date': isMaxValidate }"
                [dateFormat]="dateFormat"
                (valueChange)="changeDateValue($event, 'max')"
                [(value)]="maxValue">
            </pivot-calendar>
        </div>
        <div class="filter-content">
            <label>単位</label>
            <div class="option-group">
                <pivot-textbox [canClear]="false"
                    [id]="isDate ? 'inputUnit' : ''"
                    [inputValue]="unitValue"
                    [inputParams]="unitParams" 
                    (onFocusOut)="setUnitValidate()"
                    (onFocus)="onUnitFocus()"
                    (inputValueChange)="onChangeUnit($event)"
                    [ngClass]="isDate?'input-unit': 'input-number'">
                </pivot-textbox>
                <label *ngIf="isDate" [ngClass]="{
                   'option-label input-validate' : isUnitValidate,
                   'option-label' : !isUnitValidate || !isUnitFocus,
                   'label-focus' : isUnitFocus
                }">{{condition? condition.optionName : '月'}}</label>
            </div>
            <p-dropdown id="filteroption" [disabled]="isDate? false: true"
                appendTo="body" 
                [options]="filterOption"
                [(ngModel)]="optionSelected"
                (onChange)="onChangeDropDown($event)"
                optionLabel="name">
                <ng-template let-option pTemplate="item">
                    <div class="option-item">{{option.name}} </div>
                </ng-template>
          </p-dropdown>    
        </div>
        <div modal-bottom class="filter-content">
            <div class="btn-grp">
                <pivot-text-button class="btn-condition" (btnClick)="onSubmiDataFilter(false)"
                    [buttonType]="buttonType.SECONDARY" [buttonText]="BUTTON.CANCEL">
                </pivot-text-button>
                <pivot-text-button class="btn-add-dashboard" [disabled]="isUnitValidate || isMinValidate || isMaxValidate" (btnClick)="onSubmiDataFilter(true)"
                    [buttonType]="buttonType.SECONDARY" [buttonText]="BUTTON.SET">
                </pivot-text-button>
            </div>
        </div>
    </div>

</pivot-modal-template>