<div class="grid basic-setting-pc" *ngIf="deviceType === DeviceType.DESKTOP || deviceType === DeviceType.TABLET">
    <ng-container *ngTemplateOutlet="pc"></ng-container>
</div>

<ng-template #pc>
    <div class="col-12 md:col-5 lg:col-5 column-left">
        <label class="name custom-label-name">{{ currentStaff.fullName }}</label>

        <img class="avatar" [src]="userAvatarUrl" [ngClass]="avatarValid ? 'block' : 'hidden'"
            (error)="handleAvatarError($event)">
        <img class="avatar" [ngClass]="avatarValid ? 'hidden' : 'block'" src="../../../assets/images/user-avatar.svg">

        <i class="edit" (click)="fileInput.click()"></i>
        <!-- Hidden input -->
        <input hidden (change)="openCropperDialog($event)" accept=".jpg, .png, .jpeg, .svg" type='file' #fileInput>
        <span>背景色設定</span>
        <div class="menu-icon">
            <!-- Black -->
            <div class="icon-child" (click)="changeBackgroundStyle(1)">
                <span class="icon-bkr-black icon-custom-size selected" >                
                    <i class="pi pi-check" *ngIf="selectedTheme==1"></i>
                </span>

            </div>
            <!-- Navy -->
            <div class="icon-child" (click)="changeBackgroundStyle(2)">
                <span class="icon-bkr-navy icon-custom-size selected">
                    <i class="pi pi-check" *ngIf="selectedTheme==2"></i>
                </span>
            </div>
            <!-- white -->
            <div class="icon-child" (click)="changeBackgroundStyle(-1)">
                <span class="icon-bkr-white selected">
                    <i class="pi pi-check" *ngIf="selectedTheme==-1"></i>
                </span>

            </div>
        </div>
    </div>
    <div class="grid col-12 md:col-7 lg:col-7 column-right">
        <div class="grid row col-12">
            <div class="col-4 sm:col-4 md:col-4 ld:col-4 xl:col-2 flex justify-content-end">
                <label>{{BASIC_INFO.ID}}</label>
            </div>
            <div class="col-8 sm:col-8 md:col-8 ld:col-8 xl:col-10">
                <label>{{ currentStaff.staffCd }}</label>
            </div>
        </div>
        <div class="grid row col-12">
            <div class="col-4 sm:col-4 md:col-4 ld:col-4 xl:col-2 flex justify-content-end">
                <label>{{BASIC_INFO.USER_NAME}}</label>
            </div>
            <div class="col-8 sm:col-8 md:col-8 ld:col-8 xl:col-10">
                <label>{{ currentStaff.userId}}</label>
            </div>
        </div>
        <div class="grid row col-12">
            <div class="col-4 sm:col-4 md:col-4 ld:col-4 xl:col-2 flex justify-content-end">
                <label>{{BASIC_INFO.EMAIL}}</label>
            </div>
            <div class="col-8 sm:col-8 md:col-8 ld:col-8 xl:col-10 txtPivotemail">
                <label>{{ currentStaff.email }}</label>
                <span class="update-basic-inf-btn" (click)="onClickChangeUser()">
                    {{BASIC_INFO.UPDATE_INF_BTN}}
                </span>
            </div>
        </div>
        <div class="grid row col-12">
            <div class="col-2 flex justify-content-end">
                <label  >{{BASIC_INFO.PHONE_NUMBER}}</label>
            </div>
            <div class="col-10">
                <label>{{ currentStaff.phoneNunber}}</label>
            </div>
        </div>
        <div class="grid row col-12 basic-info-role-container">
            <div class="col-4 sm:col-4 md:col-4 ld:col-4 xl:col-2 flex justify-content-end">
                <label style = "margin-top: 8px">{{BASIC_INFO.ROLE}}</label>
            </div>
            <div class="col-8 sm:col-8 md:col-8 ld:col-8 xl:col-10 abc">
                <ng-container *ngIf="isInit">
                </ng-container>
                <ng-container *ngIf="!roleArr && !isInit">
                    <label class="roles--null">
                        {{MESSAGE_TEXT.NO_PIVOT_PERMISSION}}
                    </label>
                    <br>
                    <label class="roles--null">
                        {{MESSAGE_TEXT.CONTACT_ADMIN}}
                    </label>
                </ng-container>
                <ng-container *ngIf="currentStaff.roles">
                    <p-tag styleClass="mr-2 roles" severity="success" *ngFor="let role of currentStaff.roles">
                        <pivot-role-detail-inf [deviceType]="deviceType" [roleName]="role.name">
                        </pivot-role-detail-inf>
                    </p-tag>
                </ng-container>
            </div>
        </div>
        <div class="grid row col-12">
            <div class="col-2 flex justify-content-end">
                <label>{{BASIC_INFO.LOGIN_INFO}}</label>
            </div>
            <div class="col-10">
               
            </div>
        </div>
        <div class="grid row col-12">
            <div class="col-2 flex justify-content-end">
                <label>{{BASIC_INFO.CORP}}</label>
            </div>
            <div class="col-10">
                <label>{{ loginInfo.organizationname || ""}}</label>
            </div>
        </div>
        <div class="grid row col-12">
            <div class="col-2 flex justify-content-end">
                <label>{{BASIC_INFO.CUSTID}}</label>
            </div>
            <div class="col-10">
                <label>{{ loginInfo.custid || ""}}</label>
            </div>
        </div>
    </div>
</ng-template>

<pivot-dialog-avatar-upload-information [isDisplayModal]="showAvatarInfoDialog" [modalData]="modalAvatar"
    (onSubmitData)="handleCloseAvatarRuleDialog($event)">
</pivot-dialog-avatar-upload-information>