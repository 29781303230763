
import * as moment from 'moment';
import { DashboardService } from '../services/modules/dashboard.service';
import { DataSourceService } from '../services/modules/data-source.service';
import { WidgetService } from '../services/modules/widget.service';
import Utils from '../util/utils';
import { DataSourceType, DateFormat } from '../enum/common-enum';
import { DASHBOARD_CD_NEW } from '../const/const';

export class DashboardHelper {

  public static async getSettingWidgetDetail(dashboardService: DashboardService, dashboardCd: string, isTemplate:boolean) {
		let dashboards: any[] = [];
		let widgets: any[] = []
		if (!Utils.isNullOrEmpty(dashboardCd)) {
			await dashboardService.getSettingWidgetDetail(dashboardCd,isTemplate).then((data: any) => {
				dashboards = data || [];
				dashboards.map((d: any) => {
					d.isHaveSize = true;
				});
				widgets = data.settings  || [];
			});
		}
		return { dashboards: dashboards, widgets: widgets }
	}

	public static async getWidget(widgets: any[], widgetService: WidgetService, dashboadCd: string, dashboardSelected: any,isTemplate:boolean) {
		let periods: any[] = [];
		if (widgets.length == 0 && dashboadCd != DASHBOARD_CD_NEW) {
			const widgetRes = await widgetService.getByDashboard(dashboadCd);
			widgets = widgetRes.data || [];
		}

		await widgetService.getPeriodInfor(DashboardHelper.getListWidgetStr(dashboardSelected),isTemplate).then(data => {
			if (data.statuscode == 200) {
				periods = data.data || [];
			}
		});


		dashboardSelected?.setting?.map((widget: any) => {
			let findPeriod = periods?.find(s => s.widgetcd == widget.widgetCd);
			if (!Utils.isNullOrEmpty(findPeriod)) {
				widget.startdate = findPeriod.dstype == DataSourceType.SEIKUY? (moment(findPeriod.startdateseikuy)).format(DateFormat.JP_FULL_SHORT_DATE): (moment(findPeriod.startdate)).format(DateFormat.JP_FULL_SHORT_DATE);
				widget.enddate = findPeriod.dstype == DataSourceType.SEIKUY? (moment(findPeriod.enddateseikuy)).format(DateFormat.JP_FULL_SHORT_DATE) : (moment(findPeriod.enddate)).format(DateFormat.JP_FULL_SHORT_DATE);
				widget.datasourceCd = findPeriod.dsstructcd;
			}
			const itemFind = widgets?.find(w => w.widgetCd === widget.widgetCd);
			widget.sortcoltype = itemFind?.sortcoltype;
			if(itemFind?.chartType) widget.type = itemFind?.chartType;
			widget.widgetId = itemFind?.id || "";

			let findDataSourceCD = periods?.find(s => s.widgetcd == widget.widgetCd);
			if (!Utils.isNullOrEmpty(findDataSourceCD)) {
				widget.datasourceCd = findDataSourceCD.dsstructcd;
				widget.sortArr = !Utils.isNullOrEmpty(itemFind?.sortcoltype) ? JSON.parse(itemFind?.sortcoltype || '') : []
			}

		})

		return { periods: periods, widgets: widgets }
	}


	public static getListWidgetStr(dashboardSelected: any) {
		let widgetStrs: any[] = [];
		dashboardSelected?.setting?.forEach((widget: any) => {
			widgetStrs.push(widget.widgetCd);
		});
		return widgetStrs;
	}


  public static  checkWidgetNotDetele(dash: any, allWidgets:any[]): any[] {
    let lisWidget: any[] = [];
    dash?.setting.map(((widget: any) => {
      let findDashboard = allWidgets?.find(d => d.widgetCd === widget.widgetCd);
      if (findDashboard != undefined) {
        lisWidget.push(widget);
      }
    }))
    return lisWidget;
  }
}

