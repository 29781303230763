import { CommonModule } from '@angular/common';
import { Component, OnInit,
  Input, Output, EventEmitter,
  TemplateRef, 
  ChangeDetectionStrategy,
  ViewEncapsulation,
  NgModule} from '@angular/core';
import { AngularSvgIconModule } from 'angular-svg-icon';
import {ButtonType} from '../../../enum/common-enum';


type ButtonIconPosition = 'left' | 'right' | 'top' | 'bottom';

@Component({
  selector: 'pivot-icon-button',
  templateUrl: './icon-button.component.html',
  styleUrls: ['./icon-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class IconButtonComponent implements OnInit {

  @Input() type: string = 'button';
  @Input() iconPos: ButtonIconPosition = 'left';
  @Input() icon: string | undefined;
  @Input() badge: string | undefined;
  @Input() label: string | undefined;
  @Input() disabled: boolean | undefined;
  @Input() loading: boolean = false;
  @Input() loadingIcon: string | undefined;
  @Input() style: { [klass: string]: any } | null | undefined;
  @Input() styleClass: string = '';
  @Input() badgeClass: string | undefined;
  @Input() ariaLabel: string | undefined;
  @Input() styles: string
  @Input() buttonType: ButtonType = ButtonType.PRIMARY

  @Output() onClick: EventEmitter<MouseEvent> = new EventEmitter();
  @Output() onFocus: EventEmitter<FocusEvent> = new EventEmitter<FocusEvent>();
  @Output() onBlur: EventEmitter<FocusEvent> = new EventEmitter<FocusEvent>();

  contentTemplate: TemplateRef<any> | undefined;
  loadingIconTemplate: TemplateRef<any> | undefined;
  iconTemplate: TemplateRef<any> | undefined;

  ngOnInit(): void {
    
  }

  iconClass() {
    return {
      'pivot-button-icon': true,
      'pivot-button-icon-left': this.iconPos === 'left' && this.label,
      'pivot-button-icon-right': this.iconPos === 'right' && this.label,
      'pivot-button-icon-top': this.iconPos === 'top' && this.label,
      'pivot-button-icon-bottom': this.iconPos === 'bottom' && this.label
    };
  }

  buttonClass() {
    let buttonType = this.buttonType
    return {
      'pivot-button pivot-component': true,
      'pivot-button-icon-only': this.icon && !this.label,
      'pivot-button-vertical': (this.iconPos === 'top' || this.iconPos === 'bottom') && this.label,
      'pivot-disabled': this.disabled || this.loading,
      'pivot-button-loading': this.loading,
      'pivot-button-loading-label-only': this.loading && !this.icon && this.label,
      [buttonType]: true
    };
  }

}
