import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';

import { RequestService } from '../services/request.service';

@Injectable()
export class CompleteRequestsInterceptor implements HttpInterceptor {
    constructor(private requestService: RequestService) { }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        this.requestService.sendRequest();
        return next.handle(req).pipe(finalize(() => this.requestService.completedRequest()));
    }
}
