import { Component, Input, OnInit, OnChanges, ViewEncapsulation, SimpleChanges, ChangeDetectorRef, Output, EventEmitter } from '@angular/core';
import { cloneDeep } from 'lodash';
import { LIST_COMPARISON } from 'src/app/const/table-col-define';
import { CK_REPORT_TEXT } from 'src/app/const/text-common';
import { InputType, SearchType } from 'src/app/enum/common-enum';
import { LazyTreeNode, SearchParams, TreeNode, item } from 'src/app/models/common-model';
import { conditionItemModel } from 'src/app/models/zaitaku-model';

@Component({
  selector: 'pivot-condition-item',
  templateUrl: './condition-item.component.html',
  styleUrls: ['./condition-item.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ZaitakuConditionItemComponent implements OnInit {

  @Input() filterLog: {screenName: string, action: string, body: string, content: string} | null = null;
  @Input() treeViewNode: TreeNode[] = [];
  @Input() treeViewList: LazyTreeNode[] = [];
  @Input() notValidIds: any[] = [];
  @Input() officecd: string = "";
  @Input() conditionItem: conditionItemModel = new conditionItemModel();

  @Output() removeItem = new EventEmitter<any>();
  @Output() eventUpdateData = new EventEmitter<conditionItemModel>();

  CK_REPORT = CK_REPORT_TEXT;
  LIST_COMPARISON = LIST_COMPARISON;
  beforeIM: number = 0;
  operatorsNotForString: any[] = ['greater_than', 'less_than'];
  defaultComparison = 'like';

  paramsForTreeNode: SearchParams = {
    type: SearchType.combo,
    cssStyle: { width: '100%', height: '40px', 'font-size': '0.875rem' },
    readonly: false,
    disabled: false,
    maxLength: 100,
    defaultValue: '',
    comboDisplayText: 'name',
    objectData: {
      "treeViewNode": this.treeViewNode,
      "treeViewList": this.treeViewList,
      "selectedNode": this.conditionItem
    }
  };

  paramsForComparison: SearchParams = {
    type: SearchType.combo,
    placeholder: '',
    isFilter: false,
    cssStyle: { height: '40px', width: '100%', 'font-size': '14px' },
    readonly: false,
    disabled: false,
    comboDisplayText: 'name',
    defaultValue: '',
    dataSource: LIST_COMPARISON
  };


  paramsForItems: any = {
    type: InputType.text,
    borderFill: true,
    maxLength: 200
  };

  itemValues: string[] = [];
  keyChangeItem: string = "";

  constructor(
    private cdr: ChangeDetectorRef
  ) {

  }

  //#region event angular 
  ngOnInit(): void {
  }
  ngOnChanges(changes: SimpleChanges): void {
    this.cdr.detectChanges(); 
    this.keyChangeItem = this.conditionItem.ttlcd + this.conditionItem.ttlkbn + this.conditionItem.itemid;
    this.paramsForComparison.defaultValue = this.LIST_COMPARISON.find(x => x.value == this.conditionItem.comparison)?.name || "";
    this.paramsForComparison.selected = this.LIST_COMPARISON.find(x => x.value == this.conditionItem.comparison)?.name || "";

    if (this.conditionItem.im == 2) {
      // im = 2: case number
      this.paramsForComparison.dataSource = LIST_COMPARISON;
    } else {
      // im != 2: case string
      this.paramsForComparison.dataSource = LIST_COMPARISON.filter(item => !this.operatorsNotForString.includes(item.value));
    }
    this.beforeIM = this.conditionItem.im ?? 0;
    this.paramsForComparison = cloneDeep(this.paramsForComparison);

    this.paramsForTreeNode.objectData.treeViewNode = this.treeViewNode;
    this.paramsForTreeNode.objectData.treeViewList = this.treeViewList;
    this.paramsForTreeNode.objectData.selectedNode = this.conditionItem;  
    this.paramsForTreeNode = cloneDeep(this.paramsForTreeNode);
  }
  //#endregion event angular

  //#region treenode

  async onUpdateDataCondition(event: any) {
    this.conditionItem.dockbn = event.dockbn;
    this.conditionItem.ttlkbn = event.ttlkbn;
    this.conditionItem.ttlcd = event.ttlcd;
    this.conditionItem.itemid = event.itemid;
    this.conditionItem.notekbn = event.notekbn;
    this.conditionItem.dockbn = event.dockbn;
    this.conditionItem.wtype = event.wtype;
    this.conditionItem.im = event.im;
    this.conditionItem.itemname = event.itemname;
    this.keyChangeItem = this.conditionItem.ttlcd + this.conditionItem.ttlkbn + this.conditionItem.itemid;
    this.eventUpdateData.emit(this.conditionItem)

    if (event.im == 2) {
      // im = 2: case number
      this.paramsForComparison.dataSource = LIST_COMPARISON;
    } else {
      // im != 2: case string
      this.paramsForComparison.dataSource = LIST_COMPARISON.filter(item => !this.operatorsNotForString.includes(item.value));

      // Trường hợp trước đó mode IM  = 2 (number) và đang chọn so sánh 'greater_than', 'less_than',
      // sau đó chuyển sang IM != 2 (string) thì auto chuyển option comparison sang default 'like'
      if (this.operatorsNotForString.includes(this.conditionItem.comparison) && this.beforeIM == 2) {
        this.conditionItem.comparison = this.defaultComparison;
        this.paramsForComparison.defaultValue = this.LIST_COMPARISON.find(x => x.value == this.defaultComparison)?.name || "";
        this.paramsForComparison.selected = this.LIST_COMPARISON.find(x => x.value == this.defaultComparison)?.name || "";
        this.paramsForComparison = cloneDeep(this.paramsForComparison);
      }

    }
    this.beforeIM = event.im;
    this.paramsForComparison = cloneDeep(this.paramsForComparison);

  }


  //#endregion treenode

  //#region conditon
  onFilterCondition(data: any[]) {
    if (data && data.length == 1) {
      this.conditionItem.comparison = data[0].value;
      this.paramsForComparison.defaultValue = this.LIST_COMPARISON.find(x => x.value == this.conditionItem.comparison)?.name || "";
      this.paramsForComparison.selected = this.LIST_COMPARISON.find(x => x.value == this.conditionItem.comparison)?.name || "";
      this.paramsForComparison = cloneDeep(this.paramsForComparison);
    }
    this.eventUpdateData.emit(this.conditionItem)
  }
  //#endregion condition

  //#region item

  onChangeItemValue(event: any) {
    this.conditionItem.val = event;
    this.eventUpdateData.emit(this.conditionItem)
  }
  //#endregion item

  //#region button
  onRemove(id: any) {
    this.removeItem.emit(id);
  }

  //#endregion button

}
