import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { BUTTON, COMMON_TEXT } from 'src/app/const/text-common';
import { ButtonIconType, ButtonType, StatusSynsMasterData } from 'src/app/enum/common-enum';
import { ModalTemplate } from '../../../../models/common-model';
import Utils from '../../../../util/utils';

@Component({
  selector: 'pivot-dialog-sync-info-detail',
  templateUrl: './dialog-sync-info-detail.component.html',
  styleUrls: ['./dialog-sync-info-detail.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class DialogSyncInfoDetailComponent implements OnInit, OnChanges  {
  @Input() isDisplayModal: boolean = false;
  @Input() modalData: ModalTemplate = new ModalTemplate();
  @Input() data: any[] = [];
  @Input() isSyncing: boolean;
  @Output() onSubmitData: any = new EventEmitter<any>();
  @Input() statusOfAllSyns: StatusSynsMasterData ;

  iconType = ButtonIconType;
  buttonType = ButtonType;
  statusSynsMasterData = StatusSynsMasterData;
  BUTTON = BUTTON;
  COMMON_TEXT = COMMON_TEXT;

  constructor() { }

  ngOnChanges(change: SimpleChanges) {
    this.setDataforDialog();
      }

  ngOnInit(): void {
    this.setDataforDialog();
  }

  onClose() {
    this.onSubmitData.emit(null);
    this.isDisplayModal = false;
  }

  setDataforDialog() {
    if (Utils.isNullOrEmpty(this.modalData.style?.width)) {
      this.modalData.style.width = '400px';
    }
  }
}