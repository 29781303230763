import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ClearProcessService {

  private intervalIdList: any[] = [];
  public screenCode: number = 0;
  constructor() { }

  setScreenCode(code: number): void {
    if(this.screenCode === code) {
      return;
    }
    this.screenCode = code;
  }

  getScreenCode(): number {
    return this.screenCode;
  }

  setIntervalId(id: any) {
    if(this.intervalIdList.includes(id)) return;
    this.intervalIdList.push(id);
  }

  removeIntervalIdList(id: any) {
    this.intervalIdList = this.intervalIdList.filter(item => item !== id);
  }
  
  clearIntervalIdList() {
    this.intervalIdList = [];
  }

  /**
   * Clear all interval or list selected interval
   * 
   * @param intervalIdArr - List intervalId for clear
   * @param isClearAll - If true, clear all interval from interval list
   * @returns Void
   */
  clearTargetInterval(intervalIdArr: any[] = [], isClearAll: boolean = false) {
    if(isClearAll) {
      this.intervalIdList.forEach(clearInterval);
      this.intervalIdList = [];
      return; 
    }

    intervalIdArr.forEach(clearInterval);
    this.intervalIdList = this.intervalIdList.filter(e => !intervalIdArr.includes(e));
  }
}
