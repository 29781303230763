import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { BUTTON, COMMON_TEXT, CREATE_DASHBOARD_TEMPLATE, DIALOG_DASHBOARD_SERVICE } from '../../../../app/const/text-common';
import { ButtonType, SearchType } from '../../../../app/enum/common-enum';
import { ModalTemplate, SearchParams } from '../../../../app/models/common-model';
import { FOLDER_TYPE } from '../../../../app/models/response/folder.ro';
import { FolderService } from '../../../../app/services/modules/folder.service';
import { cloneDeep } from 'lodash';
import { SAUCER_LOG_ACTION } from 'src/app/config/saucer-log.config';

@Component({
  selector: 'pivot-dialog-folder-widget',
  templateUrl: './dialog-folder-widget.component.html',
  styleUrls: ['./dialog-folder-widget.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class DialogFolderWidgetComponent implements OnInit {

  @Input() selectedWidgetFolder: any = null;
  @Input() folderOwners: any[] = [];
  @Output() closeModalHandler = new EventEmitter();
  @Output() selectedFolderHandler = new EventEmitter();
  
  BUTTON = BUTTON;
  COMMON_TEXT = COMMON_TEXT;
  buttonType = ButtonType;
  DIALOG_DASHBOARD_SERVICE = DIALOG_DASHBOARD_SERVICE;
  CREATE_DASHBOARD_TEMPLATE = CREATE_DASHBOARD_TEMPLATE;
  modalTemplate: ModalTemplate = new ModalTemplate();

  searchFolderParams: SearchParams = {
		type: SearchType.combo,
		cssStyle: {},
		readonly: false,
		disabled: false,
		maxLength: 100,
		defaultValue: '',
		comboDisplayText: 'name',
    placeholder: COMMON_TEXT.SELECTED
	};


  isLoading: boolean = false;
  isDspFolder: boolean = false;
  folderType: number = FOLDER_TYPE.Widget;
  modalFolder: ModalTemplate = new ModalTemplate();

  SAUCER_LOG_ACTION = SAUCER_LOG_ACTION;

  constructor(private folderService: FolderService) {

  }

  ngOnInit(): void {
    this.modalFolder.style = { 'width' : '400px' };
    this.modalTemplate.style = this.modalFolder.style;
    this.modalTemplate.header = CREATE_DASHBOARD_TEMPLATE.WIDGET_SAVE_DESTINATION;
    this.searchFolderParams.dataSource = this.folderOwners?.map(x => {x.value = x.folderCd; return x;}) || [];
    this.searchFolderParams = cloneDeep(this.searchFolderParams);
    this.searchFolderParams.selected = !this.selectedWidgetFolder ? null : this.selectedWidgetFolder.folderCd;
    this.searchFolderParams = cloneDeep(this.searchFolderParams);
  }


  onFilterFolder(folder: any) {
    if(folder) {
      this.selectedWidgetFolder = folder[0];
    }
  }

  showDialogAddFolder() {
    this.isDspFolder = true;
  }

  getSubmitData() {
    return {
      folders: this.folderOwners,
      selectedFolder: this.selectedWidgetFolder
    };
  }

  cancel() {
    this.closeModalHandler.emit(this.getSubmitData());
  }

  save() {
    this.selectedFolderHandler.emit(this.getSubmitData());
  }

  async getListFolder(isNew: boolean = false) {
    await this.folderService.getListFolderHavePermissionEdit(FOLDER_TYPE.Widget.toString(), false).then(async data => {
      if (data.statuscode == 200) {
        this.folderOwners = (data.data || []).filter(s => s.act != 1);
        if(!isNew) {
          this.setFolderData();
        }
      }
    });
  }

  setFolderData() {
    this.searchFolderParams.dataSource = this.folderOwners?.map(x => {
      x.value = x.folderCd
      return x;
    });
    if(this.selectedWidgetFolder?.folderCd) this.searchFolderParams.selected = this.selectedWidgetFolder?.folderCd;
    this.searchFolderParams = cloneDeep(this.searchFolderParams);
  }

  mapFolderData() {
    this.searchFolderParams.dataSource = this.folderOwners?.map(x => {
      x.value = x.folderCd
      return x;
    });
  }

  async onChangeDataFolder(folder: any) {
    this.isDspFolder = false;
    if(!folder) return;
    this.isLoading = true;
    await this.getListFolder(true)
    let findFolder = this.folderOwners?.find(s=>s.name == folder?.name);
    if(findFolder) {
      this.mapFolderData();
      findFolder.value = findFolder.folderCd
      this.selectedWidgetFolder = findFolder;
      this.searchFolderParams.dataSource = this.folderOwners;
      this.searchFolderParams = cloneDeep(this.searchFolderParams);
      if(findFolder?.folderCd) this.searchFolderParams.selected = findFolder.folderCd;
      this.searchFolderParams = cloneDeep(this.searchFolderParams);
    }
    this.isLoading = false;
  }
}
