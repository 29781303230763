<div class="container-reset-password">
    <div class="box">
        <div *ngIf="!isSubmitForm" class="content">
            <div class="title">{{RESET_PASSWORD.RP_TITLE}}</div>
            <p>{{RESET_PASSWORD.RP_INFO_LINE_1}}</p>
            <div class="desc-back-page">
                <p>{{RESET_PASSWORD.RP_INFO_LINE_2}}</p>
                <p class="link" (click)="backToLogin()">{{RESET_PASSWORD.BACK_TO_LOGIN}}</p>
            </div>
            <form [formGroup]="resetPasswordForm" (ngSubmit)="onSubmit()">
                <div>
                    <span class="p-float-label">
                        <input type="email" class="text" formControlName="email" size="30" pInputText maxlength="200"
                            aria-describedby="email-help" />
                    </span>
                    <small id="email-help" class="p-input-error"
                        *ngIf="!resetPasswordForm.controls['email'].valid && resetPasswordForm.controls['email'].dirty">
                        {{ resetPasswordForm.controls['email'].errors?.['required'] ? RESET_PASSWORD.RP_ERR_MSG :
                        '' }}
                    </small>
                </div>
                <div class="content-footer">
                    <pivot-text-button [disabled]="resetPasswordForm.invalid" type="submit"
                        [buttonText]="BUTTON.SEND">
                    </pivot-text-button>
                </div>
            </form>
        </div>
        <div *ngIf="isSubmitForm" class="content">
            <div class="title">{{RESET_PASSWORD.SENT_MSG_TITLE}}</div>
            <p>{{RESET_PASSWORD.SENT_MSG_CONTENT_1}}</p>
            <p>{{RESET_PASSWORD.SENT_MSG_CONTENT_2}}</p>
            <div class="content-footer">
                <p class="back-link" (click)="backToLogin()">{{RESET_PASSWORD.BACK_TO_LOGIN}}</p>
            </div>
        </div>
    </div>
</div>