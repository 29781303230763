import { Component, EventEmitter, Input, NgModule, OnInit, Output, ViewEncapsulation } from '@angular/core';


@Component({
  selector: 'pivot-switchbutton',
  templateUrl: './switchbutton.component.html',
  styleUrls: ['./switchbutton.component.scss'],
  encapsulation: ViewEncapsulation.None,
  
})
export class SwitchButtonComponent implements OnInit {
  @Output() onClick = new EventEmitter();
  @Input() checked: boolean;
  @Input() width: number = 0;
  @Input() height: number = 0;
  constructor() { }

  ngOnInit(): void {
  }
  
  handleClick(event: any) {
    this.onClick.emit(event);
  }
}
