import { Component, OnInit,  ChangeDetectorRef, ChangeDetectionStrategy } from '@angular/core';
import { ProcessLoadingService } from '../../services/loading.service';

@Component({
  selector: 'pivot-loading',
  templateUrl: './loading.component.html',
  styleUrls: ['./loading.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LoadingComponent implements OnInit {

  isLoading = false;
  constructor(private pageLoadingService: ProcessLoadingService,
              private cdr: ChangeDetectorRef) {
    this.checkPageLoadingHandler();
  }

  ngOnInit(): void {

  }

  checkPageLoadingHandler(): void {
    this.pageLoadingService.isLoading
        .subscribe(
          (isLoading: boolean) => {
            if (this.isLoading !== isLoading) {
              this.isLoading = isLoading;
              this.cdr.detectChanges();
            }
          }, (error) => {
          }
        );
  }
}
