<div *ngIf="deviceType === DeviceType.MOBILE">
    <label> {{ roleName }} </label>
</div>
<div class="p-dialog-width" *ngIf="isDialog">
    <pivot-dialog-header
        header="{{SYSTEM_SETTING.RESTRICTION_SETTING}}"
        (closeEventHandler)="closeDialog()"></pivot-dialog-header>
    <div class="p-dialog-content p-dialog-content-message" id="dialogcheck">
        <ng-container *ngTemplateOutlet="roleDetailBlock"></ng-container>
    </div>
</div>

<div class="box-role-detail" *ngIf="deviceType === DeviceType.TABLET || deviceType === DeviceType.DESKTOP">
    <label class="custom-label-role"> {{ roleName }} </label>
    <div class="role-detail-inf {{deviceType === DeviceType.TABLET ? 'position_top custom-panel-tablet' : 'position_bottom'}}">
        <labeL *ngIf="!permissionList && !dashboardList; else roleDetailBlock">
            {{MESSAGE_TEXT.NOT_AUTHORIZED}}
        </labeL>
    </div>
</div>

<ng-template #roleDetailBlock>
    <div class="role-detail-inf__body" *ngIf="permissionList">
        <label *ngIf="!isDialog">{{SYSTEM_SETTING.RESTRICTION_SETTING}}</label>
        <div class="box grid" *ngIf="permissionList.length > 0">
            <div class="display-treeview-left padding-treeview custom-size-role">
                <ul *ngFor="let permission of slicePermissionLeft(isDialog)">
                    <li *ngIf="!permission.parent">
                        <div class="flex align-items-center">
                            <label class="padding-label break-span-tree">{{permission.permissionname}}</label>
                        </div>
                        <ul *ngFor="let permissionChild of getlistChild(permission.permissioncd)">
                            <li *ngIf="permissionChild.checked === true">
                                <div class="flex align-items-center">
                                    <label class="padding-label break-span-tree">{{permissionChild.permissionname}}</label>
                                </div>
                            </li>
                        </ul>
                    </li>
                </ul>   
            </div>
            <div class="display-treeview-right padding-treeview custom-size-role" *ngIf="!isDialog">
                <ul *ngFor="let permission of slicePermissionRight()">
                    <li *ngIf="!permission.parent">
                        <div class="flex align-items-center">
                            <label class="padding-label break-span-tree">{{permission.permissionname}}</label>
                        </div>
                        <ul *ngFor="let permissionChild of getlistChild(permission.permissioncd)">
                            <li *ngIf="permissionChild.checked === true">
                                <div class="flex align-items-center">
                                    <label class="padding-label break-span-tree">{{permissionChild.permissionname}}</label>
                                </div>
                            </li>
                        </ul>
                    </li>
                </ul>
            </div>
        </div>
    </div>
    <div class="role-detail-inf__body" *ngIf="dashboardList && (dashboardList.length > 0)">
        <label>{{MENU_NAME.DASHBOARD_LIST}}</label>
        <div *ngIf="dashboardList && dashboardList.length > 0">
            <ul  class="custom-dashboard-dialog" >
              <li *ngFor="let dashboard of dashboardList">
                  <p class="flex align-items-center"> {{dashboard['dashboardname']}}</p>
              </li>
            </ul>
          </div>
    </div>
</ng-template>