<div class="ztk__autocomplete">
    <p-autoComplete 
        appendTo="body"
        [(ngModel)]="selectedItem" 
        [suggestions]="filteredItems"
        (completeMethod)="onSearch($event)" 
        (onSelect)="onSelect($event)"
        (onKeyUp)="onKeyUp($event)"
        (onShow)="addPanelClass('items-container')"
        [dropdown]="true">
        <ng-template let-item pTemplate="item">
            <div class="dropdownitem">
                <div>{{item}}</div>
            </div>
        </ng-template>
    </p-autoComplete>
    <div *ngIf="loading" class="spinner-overlay"><i class="pi pi-spin pi-spinner"></i></div>
</div>

