<div class="pivot-chart-container">
    <table  #table class="pivot-table"  [ngClass]="{ 'pivot-table-mobile':  deviceType == DeviceType.MOBILE || deviceType == DeviceType.TABLET}">
        <thead>
            <ng-container *ngFor="let row of headers; let i = index">
                <tr>
                    <ng-container *ngIf="!config.rows.length && footertype">
                        <th *ngIf="i == 0" [attr.rowspan]="headers.length"
                        class="first"> </th>
                    </ng-container>
                    <ng-container *ngFor="let col of row; let j = index">

                        <ng-container *ngIf="j < config.rows.length">
                            <th *ngIf="i == 0"
                            [attr.rowspan]="headers.length"
                            class="first pointer"
                            >{{replaceNameNashi(col.value ? col.value.toString(): "")}}
                        <span [ngClass]="{'sort-up-down': sortArr[j] === 'none', 'sort-up' : sortArr[j] === 'asc', 'sort-down' : sortArr[j] === 'desc' }" (click)="onSortCol(j)"></span>
                        <span *ngIf="isFilter" [ngClass]="this.filterSelecteds[j]?.value?.data?.length > 0  ?'filter-icon filter-row' : 'filter-icon un-filter'" (click)="showDialogFilter(j)"></span>
                        </th>
                        </ng-container>

                        <ng-container *ngIf="j >= config.rows.length">
                            <th *ngIf="col.colspan" [attr.colspan]="col.colspan" [attr.rowspan]="col.rowspan" [ngClass]="{'pointer': (i == headers.length - 1)}" (click)="(i == headers.length - 1) && onSortCol(j)">
                                {{beautify(replaceNameNashi(col.value ? col.value.toString(): ""), col.formattype, undefined, undefined, true)}}  
                                <span  *ngIf="i == headers.length - 1"  [ngClass]="{'sort-up-down': sortArr[j] === 'none', 'sort-up' : sortArr[j] === 'asc', 'sort-down' : sortArr[j] === 'desc' }"></span>
                            </th>
                        </ng-container>

                    </ng-container>
                </tr>
            </ng-container>
        </thead>
        <tbody>
            <!-- Thay thế những page trước bằng 1 hàng trống dùng để duy trì vị trí cuộn dọc khi currentPage > 1. -->
            <ng-container *ngIf="currentPage > 1">
                <tr [style.height.px]="rowHeight * ((currentPage - 1) * pageSize - buffer)"> <td> </td> </tr>
            </ng-container>

            <!-- Hiển thị các row đã được cắt từ allBodyRows dựa trên currentPage. -->
            <ng-container *ngFor="let row of displayedRows">
                <tr [style.height.px]="rowHeight">
                    <td  *ngIf="!config.rows.length && footertype"> </td>
                    <ng-container *ngFor="let col of row; let i = index">
                        <td [ngStyle]="{'color': col.cssStyle}" *ngIf="col.rowspan > 1" [attr.rowspan]="col.rowspan">{{beautify(col.value, col.formattype, i, col.datatype)}}</td>
                        <td *ngIf="col.rowspan === 1"  [ngStyle]="{'color': col.cssStyle}" [ngClass]="{'text-right': i >= config.rows.length}">{{beautify(col.value, col.formattype, i, col.datatype)}}</td>
                    </ng-container>
                </tr>
            </ng-container>

            <!-- Hàng trống để lấp đầy khoảng trống cho các hàng chưa được render. -->
            <ng-container *ngIf="allBodyRows.length > currentPage * pageSize">
                <tr [style.height.px]="rowHeight * (allBodyRows.length - currentPage * pageSize)"> <td> </td> </tr>
            </ng-container>

            <!-- Hiển thị message trống khi filter by office ở dashboard và không có bodyRows -->
            <ng-container  *ngIf="bodyLength <= 0 && isFilterByOffice">
                <tr class="row-no-data ng-star-inserted">
                    <td class="no-data" [attr.colspan]="colnLength">
                      {{COMMON_TEXT.NO_DATA}}
                    </td>
                </tr>
            </ng-container>

            <!-- Hiển thị message khi số column trong table đạt tới maxColumns -->
            <ng-container  *ngIf="tableSize.Columns > TableLimits.maxColumns">
                <tr class="ng-star-inserted" >
                    <td class="exceeded-limit" [attr.colspan]="colnLength">
                        {{COMMON_TEXT.EXCEEDED_MAX_COLUMNS}}
                    </td>
                </tr>
            </ng-container>

            <!-- Hiển thị footer nếu có setting cho footer -->
            <ng-container  *ngIf="footers?.length">
                <tr>
                    <td  *ngIf="!config.rows.length && footertype">{{getFooterName(footertype)}}</td>
                    <ng-container *ngFor="let cell of footers; let i = index">
                        <td [ngStyle]="{'color': cell.cssStyle}" *ngIf="cell.colspan > 0" [attr.colspan]="cell.colspan" [ngClass]="'text-right'">{{formatFooterValue(cell.value, i)}}</td>
                    </ng-container>
                </tr>
            </ng-container>
        </tbody>
    </table>
</div>