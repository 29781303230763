import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { API_APP } from '../../config/app.config';
import { ApiService } from '../api.service';
import { ErrorHandleService } from '../error-handle.service';
import { transform } from 'lodash';
import { WidgetResultRequest } from 'src/app/models/request/widget.dto';

@Injectable({ providedIn: 'root' })
export class WidgetResultService extends ApiService {

  constructor(http: HttpClient, router: Router, errorHandle: ErrorHandleService) {
    super(http, router, errorHandle);
  }

  async getByWidgetCds(widgetResultRequest: WidgetResultRequest) {
    let apiUrl = API_APP.WIDGETRESULT.CONTROLLER + API_APP.WIDGETRESULT.GET_BY_WIDGETCDS;
    let resData = await this.post(apiUrl, [], widgetResultRequest);
    if (resData.statuscode == 200) {
      if(resData.data) {
        resData.data.forEach((item: any) => {
          if(item.result) {
            if(item.result.table ) {
              if(item.result.table.footers ) {
                item.result.table.footers = this.mapWidgetResult(item.result.table.footers);
              }
            }
          }
          return item
        });
      }
      else {
        resData.data = [];
      }
    }
    return resData;
  }

  mapFormatBody(body: any, formatbody: any) {
    let transformedBody = body.map((row: any) => {
      return row.map((cellValue: any, index: string | number) => {
        if (formatbody[index] !== null) {
          if(typeof cellValue === 'object' && cellValue !== null) {
            return { ...cellValue, ...formatbody[index] };
          }
          else {
            return { value : cellValue, ...formatbody[index] };
          }
        } else {
          return cellValue;
        }
      });
    });
    return transformedBody;
  }

  mapWidgetResult(obj: { [key: string]: any }) {
    return transform(obj, (result: any, val, key) => { result[key.toUpperCase()] = val; });
  }

  async insertOrUpdate(bodyData: any) {
    let apiUrl = API_APP.WIDGETRESULT.CONTROLLER + API_APP.WIDGETRESULT.INSERT_OR_UPDATE;
    let resData = await this.post(apiUrl, [], bodyData);
    return resData;
  }

  createWidgetResultFromWidgetCdAndDatasourceCd(widgetCd: string | undefined, datasourceCd: string | undefined): Promise<any> {
    let apiUrl = `${API_APP.WIDGETRESULT.CONTROLLER}/${widgetCd}/${datasourceCd}/${API_APP.WIDGETRESULT.WIDGET_RESULT}`;
    return this.post(apiUrl, [], null);
  }

  async pivoting(bodyData: any) {
    let apiUrl = API_APP.WIDGETRESULT.CONTROLLER + API_APP.WIDGETRESULT.PIVOTING;
    let resData = await this.post(apiUrl, [], bodyData);
    if(resData?.data?.table?.footers) {
      resData.data.table.footers = this.mapWidgetResult(resData?.data?.table?.footers);
    }
    return resData;
  }
}
