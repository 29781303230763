import { Observable } from 'rxjs';
import { CanDeactivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { DataSourceSettingComponent } from '../..//module/data-source-setting/data-source-setting.component';
import { ROUTE_PATH } from '../../../app/const/route-path';

export class CanDeactivateDataSource implements CanDeactivate<DataSourceSettingComponent> {
    constructor() {

    }
    canDeactivate(component: DataSourceSettingComponent,
        currentRoute: ActivatedRouteSnapshot,
        currentState: RouterStateSnapshot,
        nextState?: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
        if (nextState?.url === ("/" + ROUTE_PATH.AUTH)) return true;
        return component.canDeactivate(nextState?.url);
    }
}