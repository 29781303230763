import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ResponseData } from '../../../app/models/api-model';
import { Folder } from '../../../app/models/response/folder.ro';
import { Assign } from '../../../app/models/response/assign.ro';
import { API_APP } from '../../config/app.config';
import { ApiService } from '../api.service';
import { AssignDTO } from '../../../app/models/request/assign.dto';
import {ErrorHandleService} from '../error-handle.service';

@Injectable({ providedIn: 'root' })
export class AssignService extends ApiService {

  constructor(http: HttpClient, router: Router, errorHandle: ErrorHandleService) {
    super(http, router, errorHandle);
  }
  
  async getAll() {
    let apiUrl = API_APP.ASSIGN.CONTROLLER + API_APP.ASSIGN.ALL;
    let resData = await this.get(apiUrl);
    return await this.mapModel(resData);
  }

  async getByAssignCD(code: string) {
    let parameter = [
      code
    ];
    let apiUrl = API_APP.ASSIGN.CONTROLLER + API_APP.ASSIGN.GETBYASSIGNCD;
    let resData = await this.get(apiUrl, parameter);
    return resData;
  }

  async mapModel(data: any) {
    let res: ResponseData<Folder[]> = new ResponseData<Folder[]>();
    let dataArr = [];
    res.statuscode = data.statuscode;
    res.statusmessage = data.statusmessage;
    if (res.statuscode === 200) {
      for await (const element of data.data) {
        let folder = new Folder();
        let assign = new Assign()
        folder.id = element.id;
        folder.folderCd = element.foldercd;
        folder.description = element.folderdesc;
        folder.folderType = element.foldertype;
        folder.name = element.foldername;
        folder.insdate = element.insdate;
        folder.insstfcd = element.insstfcd;
        folder.upddate = element.upddate;
        folder.updstfcd = element.updstfcd;
        assign.AssignId = element.assigncd;
        assign.assignCd = element.assigncd;
        assign.act = element.act;
        assign.teamCd = element.teamcd;
        assign.staffCd = element.staffcd;
        folder.assign = assign;

        dataArr.push(folder);
      }
    }
    res.data = dataArr;
    return res;
  }

  insertOrUpdate(items: AssignDTO[], isUpdate: boolean = false) {
    let params = [
      isUpdate
    ];
    let uri = API_APP.ASSIGN.CONTROLLER + API_APP.ASSIGN.INSERTORUPDATE;
    return this.post(uri, params, items);
  }

  delete(assigns: AssignDTO[]) {
    let uri = API_APP.ASSIGN.CONTROLLER + API_APP.ASSIGN.DELETE;
    return this.post(uri, [], assigns);
  }
}
