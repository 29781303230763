import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

// 非表示チェックボックス
@Component({
  selector: 'pivot-checkbox',
  templateUrl: './checkbox.component.html',
  styleUrls: ['./checkbox.component.scss']
})
export class CheckboxComponent implements OnInit {

  @Input() style = {};
  @Input() iconStyle = {};
  @Input() value: boolean = false;
  @Input() disabled: boolean = false;
  @Output() onClick = new EventEmitter();

  constructor() { }

  ngOnInit(): void {
  }

  handleClick(event: any) {
    if (this.disabled) {
      return;
    }
    
    this.value = !this.value;

    this.onClick.emit(this.value);
  }
}
