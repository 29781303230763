import { ActivityLog } from "../api-model";
import { Assign } from "./assign.ro";

export class Folder extends ActivityLog {
    id?: string;
    folderCd?: string;
    folderName?: string;
    name: string; // フォルダ名
    description: string; // 説明
    'name-subText'?: string; // 説明
    folderType: FOLDER_TYPE;
    assign: Assign;
    assigntype?:number;
    delflg?: boolean;
    act?: number;
    istemplate?: boolean;
}

export enum FOLDER_TYPE {
    Datasource = 1,
    Dashboard = 2,
    Widget = 3,
    HomeView = 4
}