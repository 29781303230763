import { LocalStorageHelper } from 'src/app/_helper/local-storage.helper';
import { MASTERDATAFORDATASOURCE } from './../../const/master-data';
import { CorpMstService } from './../../services/modules/corpmst.service';
import { ChangeDetectorRef, Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, NavigationStart, Router } from '@angular/router';
import { uniq, map, cloneDeep, isEqual, orderBy, uniqBy } from 'lodash';
import * as moment from 'moment';
import { DialogService } from 'primeng/dynamicdialog';
import { MESSAGE_TEXT } from '../../../app/const/message';
import { BUTTON, COMMON_TEXT, DATASOURCE_SETTING, MENU_NAME } from '../../../app/const/text-common';
import { v4 } from 'uuid';
import { DataTypeOptions, DATA_SOURCE_DEFAULT_HEADERS, DATA_SOURCE_EXTRA_MASTER_HEADERS, OrderBy, PeriodOptions, SortType, DATA_SOURCE_DEFAULT_HEADERS_MASTER, DATATYPE, MstFilterDataTimeItem } from '../../const/const';
import { ROUTE_PATH } from '../../const/route-path';
import { ButtonType, DataSourceColumnType, DataSourceType, DataSourceAggregationType, DataType, DateFormat, DialogType, InputType, LambdaStatus, PrimengDateFormat, SaveType, ScreenMode, SearchType, TreeViewTab, UserAction, MenuCode, DatasourceValueConfig, ColumnType,TypeOfNotification } from '../../enum/common-enum';
import { InputParams, LazyTreeNode, ListItemParams, ModalTemplate, NotifyParams, OutputData, SearchParams, TitleMapping, TreeNode, TreeViewInputParams, ListDatasourceMix } from '../../models/common-model';
import { FOLDER_TYPE } from '../../models/response/folder.ro';
import { Office } from '../../models/response/office.ro';
import { HeaderItem, TableData } from '../../models/table-model';
import { LocalStorageKey } from '../../_helper/local-storage.helper';
import { ConfirmDialogComponent } from './../../component/common/confirm-dialog/confirm-dialog.component';
import { ProcessLoadingService } from './../../services/loading.service';
import { DataSourceService } from './../../services/modules/data-source.service';
import { FolderService } from './../../services/modules/folder.service';
import { MstCommonService } from './../../services/modules/mstcommon.service';
import { OfficeAPIService } from './../../services/modules/office.service';
import Utils from './../../util/utils';
import { isMatchesExpression, sortBy, getDataTypeNumber, getExtraHeader, beautifyPrvHour } from './../../_helper/helper';
import { Observable, mergeMap, firstValueFrom, Subscription } from 'rxjs';
import { ConfirmUnsavedDataDialogComponent } from '../../component/common/confirm-unsaved-data-dialog/confirm-unsaved-data-dialog.component';
import { animate, style, transition, trigger } from '@angular/animations';
import { v4 as uuid } from 'uuid';
import { StepFunctionService } from 'src/app/services/modules/step-function.service';
import { SEIKUYFORDATASOURCE, SEIKYUU_TYPE } from 'src/app/const/seikuy-data';
import { AuthenticationService } from '../../services/authentication.service';
import { ProcessNotificationService } from 'src/app/services/process-notification.service';
import {ErrorHandleService, errorDialogTemplate} from 'src/app/services/error-handle.service';
import {ERROR_TEXT, FUNCTION_TITLE_TEXT} from 'src/app/const/error-text';
import { updateOperatorWithColId, updateOperatorWithColName, getOperatorFromString } from 'src/app/_helper/operator-custom-helper';
import { ClearProcessService } from '../../services/clear-process.service';
import { WidgetService } from 'src/app/services/modules/widget.service';
import { DialogCombineInformationComponent } from '../dialog-combine-information/dialog-combine-information/dialog-combine-information.component';
import { DATASOURCE_COMBINATION } from 'src/app/const/datasource-combine';
import { SAUCER_LOG_ACTION, SaucerLogService } from 'src/app/services/saucer-logs/saucer-log.service';
import { CONTENT_LOG, SCREEN_NAME } from 'src/app/config/saucer-log.config';
import { API_APP } from 'src/app/config/app.config';
@Component({
  selector: 'pivot-data-source-setting',
  templateUrl: './data-source-setting.component.html',
  styleUrls: ['./data-source-setting.component.scss'],
  animations: [
    trigger('onOff', [
      transition(':enter', [
        style({
          opacity: 0,
          transform: 'translateX(-100%)'
        }),animate(400)
      ])
    ])
  ]
})
export class DataSourceSettingComponent implements OnInit, OnDestroy {
  @Input() officeList: Office[] = []
  @Input() nameDataSource: string = "";
  @ViewChild("treeDropArea") treeDropArea: ElementRef = new ElementRef(null);
  PERIOD_CUSTOM_VALUE = "-1";
  BUTTON = BUTTON;
  DATASOURCE_SETTING = DATASOURCE_SETTING;
  ITEMPERPAGE: number = 200;
  buttonType = ButtonType;
  dialogType = DialogType;
  folderType = FOLDER_TYPE;
  searchParams: SearchParams = {
    type: SearchType.input,
    placeholder: COMMON_TEXT.SEARCH,
    cssStyle: { width: '97%', height: '40px' },
    readonly: false,
    disabled: false,
    maxLength: 50
  }
  nameParams: InputParams = {
    inputStyle: { 'width': '300px' },
    placeholder: DATASOURCE_SETTING.DS_NAME,
    type: InputType.text,
    pencil: true,
    validate: false,
    disabled: false,
    readonly: false,
    validateError: MESSAGE_TEXT.REQUIRE_INPUT_TEXT,
    borderFill: false,
  };
  colUsed:any[] = [];
  selectedColumns: any[] = [];
  selectedDsColumns:any[]=[];
  selectedDefinedColumns: any;
  userDefinedColumns: any[] = [];
  isShowOffice: boolean = false;
  isShowTitle: boolean = false;
  isShowFolder: boolean = false;
  isShowUserDefined: boolean = false;
  isDeleteDS: boolean = false;
  delTxt: string = '';
  folderList: any[] = [];
  renderTable: any[] = [];
  isExpanded: boolean = true;
  isShowLeftPannel: boolean = true;
  isShowRightPannel: boolean = true;
  isShowOwnStfnmCol: boolean = false;
  isHasYoteCol: boolean = false;
  dockerList: any[] = [];
  folderEdits: any[] = [];
  treeViewList:LazyTreeNode [] = [];
  treeViewListTreeLazy:LazyTreeNode [] = [];
  treeViewNode: TreeNode[] = [];
  combinedDatasourceInfo: any;
  resultJson: any;
  nodeClicked: LazyTreeNode;
  isShowAggregationType: boolean = false;
  aggregationType = DataSourceAggregationType;
  treeViewTab = TreeViewTab;
  nameDatasourceType : string;
  isDSMaster: boolean = false;
  dialogRef: ElementRef | null = null;
  panelLeftSize: any[] = [20, 80]
  panelRightSize: any[] = [18.5, 81.5]
  isConfirmSaveData: boolean = false;
  tableData: TableData = {
    config: {
      id: 'data-source-table',
      caption: '',
      emptyMessage: COMMON_TEXT.NO_DATA,
      showIndex: false,
      showCheckbox: false,
      hoverShowCheckbox: false,
      showPagination: false,
      isResponsive: false,
      tableStyle: { 'cursor': 'default' }
    },
    header: [],
    body: []
  };
  isTableLoading: boolean = false;
  periodParams: SearchParams = {
    type: SearchType.combo,
    placeholder: COMMON_TEXT.THIS_MONTH,
    cssStyle: { width: '200px', height: '40px' },
    readonly: false,
    disabled: false,
    dataSource: PeriodOptions,
    comboDisplayText: 'name'
  }
  dataTypeOptions: any[] = DataTypeOptions;
  columnListParams: ListItemParams = {
    id: 'column-list',
    items: [],
    options: [],
    itemDisplayText: 'name',
    itemDisplayTooltip: 'tooltip',
    cssStyle: { width: '100%', height: '100%' },
    isDisplayTooltip: true,
    isBorderLeftColor: true
  };
  dragScopeTreeView = 'column-area';
  selectedPeriod: any = {}
  _groupedPeriod: any = []
  groupedPeriod: any = [];
  dataSourceCd: string = "";
  isDisplayConfirmDeleteModal: boolean = false;
  modalDelete: ModalTemplate = new ModalTemplate();
  selectedFolder: any = {};
  screenMode = ScreenMode;
  mode: ScreenMode = ScreenMode.ADD;
  titlePage: string = "";
  folderCd: string = "";
  dsID: string = "";
  isPreview: boolean = false;
  allOffices: any[] = [];
  isDuplicated: boolean = false;
  saveType = SaveType;
  confirmModal: ModalTemplate = new ModalTemplate();
  selectedFolderRow: any = {};
  isOver100Records: boolean = false;
  useCanDeactivate: boolean = true;
  selectedTabTree: any = TreeViewTab.RECORD;
  isLoadingTree: boolean = false;
  searchText: string = "";
  treeViewType: TreeViewTab = TreeViewTab.RECORD;
  rawTitleMapping: any[] = [];
  lazyData: LazyTreeNode[] = [];
  lazyComposedData: LazyTreeNode[] = [];
  lazyTags: LazyTreeNode[] = [];
  lazyTagsComposedData: LazyTreeNode[] = [];
  readonly treeDefaultHeight: number = 26;
  readonly treeNodeHeight: number = 27;
  scrollHeight = 0;
  templateLeftPanel: HTMLElement | null;
  treeWrapperElement: HTMLElement | null;
  isDisplayRangeDateModal: boolean = false;
  selectedRangeDate: any = {};
  editDataSourceData: any = {};
  previewDataSourceData: any = {};
  expandIcon: string = '';
  collapseIcon: string = 'g';
  itag: Array<any> = [];
  officeCds: Array<string> = [];
  isDataSourceTemplate: boolean;
  titles: Array<any> = [];
  isDisplayWarning: boolean = false;
  modalDataWarning: ModalTemplate = new ModalTemplate();
  warningMessage: string = '';
  typeDataSource: string =  '';
  lapsedDay: Date = new Date();
  checkboxInf: any = {
    count : 0
  }
  datasourceSettingLogAction: any;
  _isOnlyLastestData: boolean
  set isOnlyLastestData(value: boolean) {
    this.setCheckboxValue("_isOnlyLastestData", value, "isOnlyLastestData")
  }
  get isOnlyLastestData() {
    return this._isOnlyLastestData
  }

  _isDspLapsedDay: boolean
  set isDspLapsedDay(value: boolean) {
    this.setCheckboxValue("_isDspLapsedDay", value, "isDspLapsedDay")
  }
  get isDspLapsedDay() {
  return this._isDspLapsedDay
  }

  _isDspBlockUnit: boolean
  set isDspBlockUnit(value: boolean) {
    this.setCheckboxValue("_isDspBlockUnit", value, "isDspBlockUnit")
  }
  get isDspBlockUnit() {
    return this._isDspBlockUnit
  }

  _isDisplayDate: boolean;
  set isDisplayDate(value: boolean) {
    this.setCheckboxValue("_isDisplayDate", value, "isDisplayDate")
  }
  get isDisplayDate() {
    return this._isDisplayDate
  }
 
  _isBedDisplay: boolean;
  
  set isBedDisplay(value: boolean) {
    this.setCheckboxValue("_isBedDisplay", value, "isBedDisplay")
  }

  get isBedDisplay() {
    return this._isBedDisplay
  }
  _isDspAge: boolean;
  set isDspAge(value: boolean) {
    this.setCheckboxValue("_isDspAge", value, "isDspAge")
  }
  get isDspAge() {
    return this._isDspAge
  }

  _isDspRefDate: boolean
  set isDspRefDate(value: boolean) {
    this.setCheckboxValue("_isDspRefDate", value, "isDspRefDate")
  }
  get isDspRefDate() {
    return this._isDspRefDate
  }

  _includingstartdate: boolean
  set includingstartdate (value: boolean) {
    if (this._includingstartdate !== value) {
      this._includingstartdate = value;
      this.updateCheckboxCount("includingstartdate", this._includingstartdate)
    }
  }
  get includingstartdate () {
    return this._includingstartdate;
  }

  _dischargedate: boolean 
  set dischargedate (value: boolean) {
    if (this._dischargedate !== value) {
    this._dischargedate = value
    this.updateCheckboxCount("dischargedate", this._dischargedate)
    }
  }
  get dischargedate () {
    return this._dischargedate;
  }
  
  dateFormat: string = PrimengDateFormat.MONTH;
  calendarFormat: string = PrimengDateFormat.FULL_SHORT_DATE;
  dsType: number = DataSourceType.TITLE;
  DSTYPE = DataSourceType;
  now: Date = new Date();
  searchDateSeikuy:  Date = new Date(this.now.getFullYear(), this.now.getMonth(), 1);
  numberDateCol: any[] = [];
  isClickedAggregationTypeOnce: boolean = false;
  isDisabledSeikuyTab: boolean = false;
  totalDaysDetails: number = 0;
  isUserChangedAction: boolean = true;
  DSCombineTooltip: string = '';
  showDSCombination: boolean = false;
  calcAge: Date = new Date();
  listDSSuggestion:string[] = [];
  _processNotificationService: ProcessNotificationService;
  @ViewChild('dssuggest', { static: false }) listOp: any;
  isSupporterAdmin: boolean = false;

  permissionSeikuy: boolean = false;
  LIMIT_TITLEMAPPING = 6000;
  titleMappingLoadList: string[] =  [];
  datasourceRelationalTree: any[] = [];
  nameDataSourceDelete: string[] = [];
  startMonth = 1;
  isByPassConfirmDialog: boolean =  false;

  apiServiceSubscriber: Subscription | undefined;
  appendToElement: any;
  isInitEvent: boolean = false;
  isShowWidget: boolean = false;
  selectedWidgets: [] = [];
  usedWidgets: [] = [];
  isNavigate: boolean = false;
  isYoteiHover: boolean = false;
  backActionFromBrowser: boolean = false;
  routerSub: Subscription;
  //List intervalid on process
  intervalIdList: any[] = [];
  firstTimeCalling: boolean = true;
  officeIcon: string = '';
  saveIcon: string = '';
  previewIcon: string = '';
  isDarkMode: boolean = false;
  SAUCER_LOG_ACTION = SAUCER_LOG_ACTION;
  oldDSLogData: any;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private modalService: DialogService,
    private mstService: MstCommonService,
    private dataSourceService: DataSourceService,
    private loadingService: ProcessLoadingService,
    private folderService: FolderService,
    private officeAPI: OfficeAPIService,
    private corpMstService: CorpMstService,
    private authenticationService: AuthenticationService,
    private stepFunctionService: StepFunctionService,
    private widgetService: WidgetService,
    private processNotificationService: ProcessNotificationService,
    private cdr: ChangeDetectorRef,
    private errorHandleService: ErrorHandleService,
    private clearProcessService: ClearProcessService,
    private saucerLogService : SaucerLogService
  ) { 
    this.isDataSourceTemplate = this.router.url.includes("datasource-template") ? true : false;
    const bodyElement = document.getElementsByTagName("body")[0];
    this.isDarkMode = bodyElement.classList.contains("navi") || bodyElement.classList.contains("dark") ? true : false;
    if (this.isDarkMode) {
      this.expandIcon = '../../../assets/icons/folder-white.svg';
      this.collapseIcon = '../../../assets/icons/folder-opened-white.svg';
    } else {
      this.expandIcon = '../../../assets/icons/folder.svg';
      this.collapseIcon = '../../../assets/icons/folder-opened.svg';
      this._processNotificationService = processNotificationService;
    }

    this.routerSub = this.router.events.subscribe((event) => {
      if (event instanceof NavigationStart && event.navigationTrigger === 'popstate') {
        this.backActionFromBrowser = true;
        this.clearProcessService.clearTargetInterval(this.intervalIdList, false);
        this.clearProcessService.setScreenCode(0);
        this.firstTimeCalling = false;
      }
    });
  }

  ngOnDestroy(): void {
    window.removeEventListener('resize', this.handleResizeLazyTree);
    this.apiServiceSubscriber?.unsubscribe();
    this.routerSub.unsubscribe();
  }

  async ngOnInit() {
    this.appendToElement = document.body;
    this.isInitEvent = true;
    let editMixSchedule =  false;
    //Remove p-highlight class in list-item
    window.addEventListener('click', (event: any) => {
      let columnList = document.getElementById('column-list');
      let itemList = columnList?.querySelectorAll('li'); {
        itemList?.forEach(item => {
          if (!item?.contains(event.target)) {
            item.classList.remove("p-highlight")
          }
        })
      }
    });

    this.apiServiceSubscriber = this.errorHandleService.isByPassConfirmDialog.subscribe((isSkipAllConfirmDialog: boolean) => {
      if(this.isByPassConfirmDialog != isSkipAllConfirmDialog) this.isByPassConfirmDialog = isSkipAllConfirmDialog;
    });

    this.errorHandleService.backURLSub.next(ROUTE_PATH.DATASOURCE);
    this.errorHandleService.setFunctionTitle(FUNCTION_TITLE_TEXT.SCREEN_INIT_FAIL);
    const roles = this.authenticationService.getData(LocalStorageKey.CURRENT_USER_ROLE);
    this.isSupporterAdmin = await this.authenticationService.isAdminOrSupporter();
    const roleTabSeikuy = roles[0].permissions.find((p: any) => p == MenuCode.TAB_SEIKUY);
    roleTabSeikuy? this.permissionSeikuy = true : false;
    window.addEventListener('resize', this.handleResizeLazyTree);

    this.loadingService.isLoading.emit(true);
    this.isPreview = false;
    this.editDataSourceData = {};
    this.allOffices = await this.officeAPI.getAllOfficeService();
    this.route.paramMap.subscribe(async (paramMap) => {
      this.dataSourceCd = paramMap.get('dsstructCd') as string;
      this.folderCd = paramMap.get('folderCd') as string;
      if (!Utils.isNullOrEmpty(this.folderCd)) {
        //Mode add
        this.titlePage = this.isDataSourceTemplate ? MENU_NAME.DATASOURCE_TEMPLATE_CREATE : MENU_NAME.DATASOURCE_CREATE;
        this.mode = ScreenMode.ADD;
      }
      else {
        let mode = paramMap.get('mode') as string;
        this.mode = parseInt(mode);
        if (this.mode === this.screenMode.ADD) {
          this.navigateTo([ROUTE_PATH.PAGE_NOT_FOUND]);
        } else if(this.mode === this.screenMode.EDIT){
          this.titlePage = this.isDataSourceTemplate ? MENU_NAME.DATASOURCE_TEMPLATE_EDIT : MENU_NAME.DATASOURCE_EDIT;
        }
        else {
          this.titlePage = this.isDataSourceTemplate ? MENU_NAME.DATASOURCE_TEMPLATE_PREVIEW : MENU_NAME.DATASOURCE_PREVIEW;
        }
      }
    });

    if (this.mode === ScreenMode.ADD) {
      this.datasourceSettingLogAction = this.isDataSourceTemplate ? SAUCER_LOG_ACTION.CREATE_DATA_SOURCE_TEMPLATE : SAUCER_LOG_ACTION.CREATE_DATA_SOURCE;
      // this.selectedPeriod = '003-002'; //Defautl is this month 今月
      this.isShowOwnStfnmCol = true;
      let selectedOffices = localStorage.getItem(LocalStorageKey.SELECTED_OFFICES) || "";
      if (!Utils.isNullOrEmpty(selectedOffices)) {
        let offices = JSON.parse(selectedOffices);
        offices = orderBy(offices, ["officeNm"]);
        this.officeList = offices;
      } 
    }
    else {
      this.datasourceSettingLogAction = this.isDataSourceTemplate ? SAUCER_LOG_ACTION.EDIT_DATA_SOURCE_TEMPLATE : SAUCER_LOG_ACTION.EDIT_DATA_SOURCE;
      let dataS3API = this.dataSourceService.getDataSourcePreviewFromS3(this.dataSourceCd);
      let infoDSAPI = this.dataSourceService.getDataSourceStructByCode(this.dataSourceCd);
      let usedWidgetAPI = this.dataSourceService.getUsedWidgetByDatasourceCd(this.dataSourceCd)

      let [dataS3Res, infoDSRes, usedWidgetsRes] = await Promise.all([dataS3API, infoDSAPI, usedWidgetAPI ]);
      if(usedWidgetsRes.statuscode == 200) {
        if(usedWidgetsRes.data && usedWidgetsRes.data.length) {
          this.usedWidgets = usedWidgetsRes.data;
        }
      }
      let lowerCaseArray = dataS3Res.data.map((obj:any) =>  Object.fromEntries(Object.entries(obj).map(([k, v]) => [k.toLowerCase(), v])));
      if(infoDSRes.statuscode === 200 && infoDSRes.data) {
        this.dsID = infoDSRes.data.id;
        this.dsType = infoDSRes.data.dstype;
        this.nameDataSource = infoDSRes.data.dsname;
        if (!Utils.isNullOrEmpty(infoDSRes.data.officeidlst)) {
          let officeList = infoDSRes.data.officeidlst.substring(1, infoDSRes.data.officeidlst.length - 1)
          let offices = officeList.split("-");
          if (!Utils.isNullOrEmpty(offices)) {
            let officeList = this.allOffices.filter((o: any) => {
              return offices.filter((code: string) => code === o.officeCd).length > 0;
            }).map((item: any) => (
                {
                  officeCd: item.officeCd,
                  officeNm: item.officeNm
                }
            ));
            this.officeList = orderBy(officeList, ["officeNm"]);
          }
        }
        this.editDataSourceData = infoDSRes.data;
        var activeColumns = infoDSRes.data.columns ? infoDSRes.data.columns.filter((item: any) => !item.delflg) : [];

        if(activeColumns.length == 0){
          this.selectedTabTree = TreeViewTab.RECORD;
        } 
        else {
          let typeOfColumns = activeColumns[0].tabtype || TreeViewTab.SCHEDULE;
          if(infoDSRes.data.dstype == DataSourceType.TITLE) {
            if(activeColumns.length == 1) {
              this.selectedTabTree = typeOfColumns;
              this.treeViewType = typeOfColumns;
            } 
            else {
              let isMoreThanOneType = uniq(map(activeColumns, 'tabtype').sort());
              if(isMoreThanOneType.length > 1){
                this.selectedTabTree = isMoreThanOneType[0];
                this.treeViewType = isMoreThanOneType[0];
                if(isMoreThanOneType.filter(x=> x === TreeViewTab.SCHEDULE).length >0 ) {
                  editMixSchedule = true;
                }
              } 
              else {
                this.selectedTabTree = typeOfColumns;
                this.treeViewType = typeOfColumns;
              }
            }
          }
          else {
            this.selectedTabTree = infoDSRes.data.dstype;
            this.treeViewType = infoDSRes.data.dstype;
          }
        }
      }
      else this.errorHandleService.backURLSub.next(ROUTE_PATH.DATASOURCE);
      this.editDataSourceData.datatabledetail ={};
      this.editDataSourceData.datatabledetail.totalrecords = dataS3Res.data.length;
      if( this.editDataSourceData.datatabledetail.totalrecords >100){
          this.editDataSourceData.datatabledetail.datatable = lowerCaseArray.slice(0, 100);;
      }else{
          this.editDataSourceData.datatabledetail.datatable = lowerCaseArray;
      }
      this.editDataSourceData.datatabledetail.totalrecords = lowerCaseArray.length; 
    }

    this.saucerLogService.action(
      {
        content: JSON.stringify({
          dataSourceCd: this.dataSourceCd,
          nameDataSource: this.nameDataSource
        })
      },
      {
        action: this.datasourceSettingLogAction.VIEW
      }
    );

    await this.initData();
    await this.changeTabTreeView(this.treeViewType, true, editMixSchedule);
    if (this.mode === ScreenMode.ADD) {
      let folders = this.folderList.filter(f => f.folderCd === this.folderCd);
      if (folders.length) {
        this.selectedFolder.id = folders[0].folderCd;
        this.selectedFolder.name = folders[0].name;
      }
      this.nameDataSource = '';
    }
    else {
      if (this.editDataSourceData && !Utils.isNullOrEmpty(this.editDataSourceData.id)) {
        this.getEditData(this.editDataSourceData);
      }
    }
    await this.prepopulatePeriodData();
    this.setModeInputName();
    this.isInitEvent = false;
    this.scrollToBottom();
  }
  
  showDialogDatasourceSuggest(treeViewType: TreeViewTab){
    let isDsMaster = false;
    switch (treeViewType){
      case TreeViewTab.MASTER:
        this.nameDatasourceType = DATASOURCE_SETTING.DS_MASTERDATA;
        isDsMaster = true;
        this.combinedDatasourceInfo = this.getCombinedDatasourceMaterInfo();
        break;
      case TreeViewTab.SEIKUY:
        this.nameDatasourceType = DATASOURCE_SETTING.DS_SEIKUY
        break;
      case TreeViewTab.RECORD:
        this.nameDatasourceType = DATASOURCE_SETTING.RECORD
        this.combinedDatasourceInfo = this.getCombinedDatasourceInfo(false)
        break;
      case TreeViewTab.REPORT:
        this.nameDatasourceType = DATASOURCE_SETTING.REPORT
        this.combinedDatasourceInfo  = this.getCombinedDatasourceInfo(false)
        break;
      case TreeViewTab.SCHEDULE:
        this.nameDatasourceType = DATASOURCE_SETTING.SCHEDULE;
        this.isYoteiHover = true;
        this.combinedDatasourceInfo  = this.getCombinedDatasourceInfo(this.isYoteiHover)
        break;
    }
    // this.nameDatasourceType = datasourcetypeName;
    this.isDSMaster = isDsMaster;
    const showDlgInforDataSourceMix = this.modalService.open(DialogCombineInformationComponent, {
      data: {
        nameDatasourceType: this.nameDatasourceType,
        isYotei: this.isYoteiHover,
        isMater: this.isDSMaster,
        listInfor:  this.combinedDatasourceInfo
      },
      header: COMMON_TEXT.COMBINE_INTELLIGENT,
      width: '30%',
      dismissableMask: true
    });
    showDlgInforDataSourceMix.onClose.subscribe(() => {
      this.isYoteiHover = false ;
      this.isDSMaster = false;
    });
  }

  getCombinedDatasourceInfo(isYoite: boolean) {
    if (isYoite) {
      this.typeDataSource = "SCHEDULE";
    }else {
      if (this.nameDatasourceType === DATASOURCE_SETTING.REPORT) {
        this.typeDataSource = "REPORT";
      }else {
        this.typeDataSource = "RECORD";
      }
    }
    const result = this.generateResult(this.typeDataSource);
    this.resultJson = JSON.stringify({ datasources: result }, null, 2);
    return this.resultJson;
  }
  
  generateResult(type: string): any[] {
    const result: any[] = [];
    const recordParents = DATASOURCE_COMBINATION.filter(parent => parent.type === type);
    recordParents.forEach(parent => {
      parent.combines.forEach(child => {
        result.push({
          parentName: parent.name,
          childName: child.name,
          name: `${parent.name} x ${child.name}`,
          date: child.date,
          iconNew: false
        });
      });
    });
    return result;
  }
  
  getCombinedDatasourceMaterInfo() {
    this.typeDataSource = "MASTER";
    const result = this.generateResult(this.typeDataSource);
    this.resultJson = JSON.stringify({ datasources: result }, null, 2);
    return this.resultJson
  }

  formatDate(value: Date) {
    if (!value) return '';
    return moment(value).format(DateFormat.FULL_DATE_HOUR);
  }

  async prepopulatePeriodData() {
    let filterBodyRequest = {
      startmonth: this.startMonth
    }
    let localGroupedPeriod = [{value: "-1", label: "ユーザー定義期間", items: [{value: "-1", label: "カスタマイズ"}]}];
    let groupedPeriod = await this.mstService.getMstFilter(filterBodyRequest) as any[];
    localGroupedPeriod.push(...groupedPeriod);
    this._groupedPeriod.push(...localGroupedPeriod);
    if(this.mode === ScreenMode.ADD) {
      this.groupedPeriod.push(...localGroupedPeriod);
      this.selectedPeriod = '003-002'; //Defautl is this month 今月;
    }
    else {
      if(this.dsType === DataSourceType.SEIKUY){
        let seikuyGroupedPeriod = this._groupedPeriod.filter((x: any) => x.value != "-1" && x.value != MstFilterDataTimeItem.ThisYear && x.value != MstFilterDataTimeItem.Today && x.value != MstFilterDataTimeItem.ThisWeek);
        this.groupedPeriod = seikuyGroupedPeriod;
      }
      else {
        this.groupedPeriod = cloneDeep(this._groupedPeriod);
      }
      this.groupedPeriod.map((group:any) => group.items.map((item:any) => {
        if(this.dsType === DataSourceType.SEIKUY){
          item.label = item.contentseikuy ??  item.label
        }else{
          item.label = item.content ??  item.label
        }
      }));
    }
    if( this.selectedPeriod != "-1") {
      this.groupedPeriod.forEach((group: any) => {
        let selectedItem = group.items.filter((item:any) => item.value === this.selectedPeriod).pop();
        if(selectedItem) {
          if(this.dsType === DataSourceType.SEIKUY){
            this.selectedRangeDate = {startDate: selectedItem.startdateseikuy, endDate: selectedItem.enddateseikuy}
           }else{
            this.selectedRangeDate = {startDate: selectedItem.startdate, endDate: selectedItem.enddate}
           }
        }
      });
    }
  }

  scrollToBottom() {
      setTimeout(()=>{ 
        let pivotDrawerHidden = document.getElementById('pivot-drawer-hidden')
        if (pivotDrawerHidden) {
          pivotDrawerHidden.scrollIntoView({ behavior: 'smooth', block: "end", inline: "nearest" })
        }},550)
  }

  updateCheckboxCount(key: string, value: boolean): void {
    if (value) {
      this.checkboxInf[key] = 1
      this.checkboxInf["count"]++;
      // this.cdr.detectChanges();
    } else {
      if (this.checkboxInf && this.checkboxInf[key]) {
        this.checkboxInf[key] = 0
        this.checkboxInf["count"]--;
        if ( this.checkboxInf["count"] < 0) {
          this.checkboxInf["count"] = 0;
        }
        // this.cdr.detectChanges();
      }
    }
  }
  
  showElement(id: string ) {
    const checkbox = document.getElementById(id) as HTMLInputElement
    const title = document.querySelector(`.pivot-drawer__title[data-for="${id}"]`)
    const titlePivotOthers = document.querySelector(`.pivot-drawer__title[data-for="pivot__others__drawer"]`)
    if (title && checkbox) {
      const listeners = title?.eventListeners?.("click")
      if (listeners && listeners.length > 0) {
        checkbox.checked = !checkbox.checked;
      } else {
        title?.addEventListener("click", () => {
          checkbox.checked = !checkbox.checked;
        });
      }
    } else {
      console.error("The required element was not found");
    }
    if(titlePivotOthers){
      this.scrollToBottom();
    }
  }

  setCheckboxValue(propertyName: string, value: boolean, key: string): void {
    (this as any)[propertyName] = value
    this.updateCheckboxCount(key, value)
  }

  getEditData(data: any) {
    let period = data.period;
    this.isDisplayDate = data.dspdateflg;
    this.isBedDisplay = data.dspbedflg
    this.isDspAge = data.dspageflg;
    this.calcAge = data.calcage ? new Date(data.calcage) : new Date();
    this.isShowOwnStfnmCol = data?.datatabledetail?.datatable[0]?.ownstfnm ? true : false;
    let aggregation = data.aggregationtype;
    if(aggregation) {
      this.includingstartdate = aggregation.includingstartdate;
      this.dischargedate = aggregation.dischargedate;
    }
    this.isDspBlockUnit = data.dspblockunit ? true : false;
    this.isDspLapsedDay = data.lapsedday ? true: false;
    this.lapsedDay = data.lapsedday ? new Date(data.lapsedday) : new Date();
    if(period.includes('~')) {
      this.selectedPeriod = "-1";
      let range = period.split('~');
      this.selectedRangeDate = {startDate: range[0], endDate: range[1]}
    }
    else {
      this.selectedPeriod = data.period;
      this.isOnlyLastestData = data.lastestflg;
    }
    let folders = this.folderList.filter(f => f.folderCd === data.foldercd);
    if (folders.length) {
      this.selectedFolder.id = folders[0].folderCd;
      this.selectedFolder.name = folders[0].name;
    }
    //set selectedColumns
    if (data.columns?.length > 0) {
      sortBy(data.columns, SortType.SORT_NO, OrderBy.ASC);
      data.columns.forEach((item: any) => {
        let datatype = this.getDataTypeOption(item.datatype);
        if(item.columntype === DataSourceColumnType.MASTER) {
          let columnNameArray = item.columnname.split('_'); 
          let colName = columnNameArray.length < 2 ? columnNameArray[0] : columnNameArray.length > 2? columnNameArray.slice(1).join('_') : columnNameArray[1];
          let tableAlias = columnNameArray.length > 1? columnNameArray[0] : '';
          let filterMaster: any = {};
          MASTERDATAFORDATASOURCE.forEach((master: any) => {
            if(master.alias === columnNameArray[0] ) {
              let fields =  master.fields.filter((field: any)=> field.field === (columnNameArray.length == 2? columnNameArray[1]: columnNameArray.slice(1).join('_')))
              if(fields.length ) {
                filterMaster = { ...master, "combineFields" : fields[0].combineFields,"outputData": new OutputData(fields[0].outputData)  }
              }
            } ;
          })
          if(filterMaster) {
            let fieldData: TitleMapping = {
              id: item.id,
              displayname: item.displayname,
              columnname: colName,
              columntype: DataSourceColumnType.MASTER,
              table: filterMaster.table,
              tablealias: tableAlias,
              datatype: item.datatype,
              startdatecolumn: filterMaster.startDateColumn,
              enddatecolumn: filterMaster.endDateColumn,
              datetimetype: filterMaster.dateTimeType,
              sortno: item.sortno,
              delflg: item.delflg,
              tooltip: `${filterMaster.name} > ${item.displayname || ''}`,
              outputdata: filterMaster.outputData,
              tablecondition: filterMaster.tableCondition || "",
              tabtype: TreeViewTab.MASTER,
              innerjoin: filterMaster.innerJoin,
            }
            //Push master columns
            this.selectedColumns.push({ value: `COL${item.sortno}`, name: item.displayname, defaultValue: datatype, data: fieldData, tooltip: fieldData.tooltip, columnId: (uuid().replace(/-/g,""))})
          }
        }
        else if(item.columntype === DataSourceColumnType.SEIKUY) {
          const regex: RegExp = /^(\d+)_(.+)$/;
          const match = item.columnname.match(regex);
          
          let seikuyType = match[1][0]; 
          let columnName = match[2]; 
          let filterSeikuy: any = {};
          SEIKUYFORDATASOURCE.forEach((col: any) => {
            if(col.table === seikuyType ) {
              let fields =  col.fields.filter((field: any)=> field.field === columnName)
              if(fields.length ) {
                filterSeikuy = { ...col }
              }
            } ;
          })
          if(filterSeikuy) {
            let fieldData: TitleMapping = {
              id: item.id,
              displayname: item.displayname,
              columnname: columnName,
              columntype: DataSourceColumnType.SEIKUY,
              table: match[1],
              datatype: item.datatype,
              sortno: item.sortno,
              delflg: item.delflg,
              tooltip: `${filterSeikuy.name} > ${item.displayname || ''}`,
              tabtype: TreeViewTab.SEIKUY,
            }
            //Push master columns
            this.selectedColumns.push({ value: `COL${item.sortno}`, name: item.displayname, defaultValue: datatype, data: fieldData, tooltip: fieldData.tooltip, columnId: (uuid().replace(/-/g,""))})
          }
        }
        else if(item.columntype === DataSourceColumnType.USER_DEFINED) {
          let datatype = this.getDataTypeOption(item.datatype);
          let data: TitleMapping = {
            id: item.id,
            displayname: item.displayname,
            columnname: item.columnname,
            datatype: item.datatype,
            columntype: DataSourceColumnType.USER_DEFINED,
            sortno: item.sortno,
            delflg: item.delflg,
            tooltip: "",
          }
          //Push USER_DEFINED columns
          this.selectedColumns.push({ value: `COL${item.sortno}`, name: item.displayname, data: data, defaultValue: datatype, operator: item.operator, tooltip: data.displayname, columnId: (uuid().replace(/-/g,""))})
          
        }
        else{
          //Push normal and tag columns
          let column = this.findNoteKbn(item);
          this.selectedColumns.push({ value: `COL${item.sortno}`, name: item.displayname, defaultValue: datatype, data: column, tooltip: column.tooltip, columnId: (uuid().replace(/-/g,""))})
        }
      })
      let columnUserDefined = data.columns.filter((col: any) => col.columntype === DataSourceColumnType.USER_DEFINED);
      if (columnUserDefined && columnUserDefined.length > 0) {

        this.selectedColumns = updateOperatorWithColId(this.selectedColumns, this.dsType);
      }
      this.generateDataForDropColumnsAndTable();
    }
    //end set selectedColumns and display table
    //get preview data for edit case
    if (data.datatabledetail && Object.keys(data.datatabledetail).length) {
      if(this.dsType == DataSourceType.SEIKUY) {
        let selectedColumns = cloneDeep(this.selectedColumns);
        let columns: any[] = []
        selectedColumns.forEach(item => {
          columns.push({ ...item.data })
        })
        let colSeikuy = columns.filter((col: any) => col.columntype == DataSourceColumnType.SEIKUY);
        let colUserDefined = columns.filter((col: any) => col.columntype == DataSourceColumnType.USER_DEFINED);
        this.previewSeikuyDataTable(data.datatabledetail.datatable, colSeikuy, colUserDefined);
      } else {
        this.getPreviewDataForEdit(data);
      }
      this.isOver100Records = data.datatabledetail.totalrecords > 100 ? true : false;
    }
    let columns = cloneDeep(orderBy(this.selectedColumns,["sortno"]))
    this.oldDSLogData = data;
    this.oldDSLogData.columnListParams = columns.filter(item => !item.data?.delflg && item.data?.displayname !='利用日数');
    //end preivew data for edit case
  }
  findNoteKbn(data: any) {
    // Normal field
    if (data.columntype !==  DataSourceColumnType.TAG) {
      let match = this.rawTitleMapping.filter((item: any) => item.officecd == data.officecd  &&
        data.ttlkbn == item.ttlkbn && data.ttlcd == item.ttlcd && data.itemid == item.itemid).pop();
      if (match) {
        data.notekbn = match.notekbn || 0;
        data.tooltip = `${match.ttlkbnnm} > ${match.ttlnm} > ${match.itemnm}`;
      }
    }
    //Tags
    else   data.tooltip = data.displayname;
    return data;
  }

  getPreviewDataForEdit(data: any) {
    let selectedColumns = cloneDeep(this.selectedColumns);
    let columns: any[] = []
    selectedColumns.forEach(item => {
      columns.push({ ...item.data })
    })
    let masterColumns = columns.filter((col) => col.columntype === DataSourceColumnType.MASTER);
    let userDefinedColumns = columns.filter((col) => col.columntype == DataSourceColumnType.USER_DEFINED);
    let titleColumns = columns.filter((col) => col.columntype != DataSourceColumnType.MASTER && col.columntype != DataSourceColumnType.USER_DEFINED);
    if (data.datatabledetail.datatable.length) {
      this.previewDataTable(data.datatabledetail.datatable, titleColumns, masterColumns, userDefinedColumns, true, data.dssetting)
    }
  }

  async initData() {
    this.isDisabledSeikuyTab = false;
    const corpMstService = this.corpMstService.getAll();
    const folderService = !this.isDataSourceTemplate ? this.folderService.getByType(FOLDER_TYPE.Datasource.toString(), this.isSupporterAdmin) 
                                                      : this.folderService.getFolderTemplateByType(FOLDER_TYPE.Datasource.toString());
    const [folderRes, corpMstRes] = await Promise.all([folderService, corpMstService]);
    if (folderRes.statuscode == 200) {
      this.folderList = folderRes?.data || [];
      this.folderEdits = this.folderList?.filter(f => f.act != UserAction.VIEW);
    }

    if (corpMstRes.statuscode == 200) {
      let monthSetting = corpMstRes.data?.find((x:any) => x.contentcd == "0001");
      this.startMonth = +(monthSetting?.value || 1);
    }
    
  }

  async loadTitleMapping( notekbn: number[] , dockbn: number[], selectedTabs?: TreeViewTab[]) {
    let officeCds = this.officeList.map(item => item.officeCd);
    let titleMappingData: any[] = [];
    let loopTotal = 0;
    let countRequest = {
      officeIds: officeCds,
      isOnlyTag: this.officeList.length > 1 ? true : false,
      noteKbn: notekbn.length  ? notekbn: null,
      docKbn: dockbn.length ? dockbn: null,
      searchText: this.searchText
    }
    if(this.isInitEvent)
      this.errorHandleService.setFunctionTitle(FUNCTION_TITLE_TEXT.SCREEN_INIT_FAIL);
    await this.dataSourceService.countTitleMappingByOffices(countRequest).then(async (countTitleRes) => {
      if (countTitleRes.statuscode === 200 && countTitleRes.data) {
        loopTotal = Math.ceil(countTitleRes.data / this.LIMIT_TITLEMAPPING);
        await Promise.all([...Array(loopTotal).keys()].map(index => {
          let titleMappingRequest = {
            officeIds: officeCds,
            offset: index * this.LIMIT_TITLEMAPPING,
            limit: this.LIMIT_TITLEMAPPING,
            isOnlyTag: this.officeList.length > 1 ? true : false,
            noteKbn: notekbn.length  ? notekbn: null,
            docKbn: dockbn.length ? dockbn: null,
            searchText: this.searchText
          }
          return this.dataSourceService.getTitleAndTagFromOffice(titleMappingRequest);
        })).then(  (responses: any) => {
          if(this.isInitEvent) {
            this.errorHandleService.setIsCloseErrorDialogOrErrorScreen(false);
          }
          responses.forEach((resdata: any) => {
            if (resdata.statuscode === 200 && resdata.data) {
              titleMappingData.push(resdata.data);
            }
          })
          if (titleMappingData.length > 0) {
            titleMappingData = titleMappingData.flat();
          }
        }).finally(() => {
          if(this.searchText == "" && selectedTabs  && selectedTabs.length) {
            this.titleMappingLoadList.push(...selectedTabs.map(x=>x.toString()));
          }
          titleMappingData = orderBy(titleMappingData, ['officecd', 'ttlkbn', 'ttlcd', 'sortno']);
          this.rawTitleMapping = this.rawTitleMapping.concat(titleMappingData);
          this.errorHandleService.setIsCloseErrorDialogOrErrorScreen(true);
        });
      }
    }).finally(()=> this.loadingService.isLoading.emit(false));
  }

  groupDateTime(date: any[]) {
    let items = date.reduce((r, {
      sdate,
      edate,
      ...item
    }) => {
      const dateKey = `${moment(sdate).format(DateFormat.FULL_SHORT_DATE)} ~ ${moment(edate).format(DateFormat.FULL_SHORT_DATE)}`;;
      r[dateKey] = r[dateKey] || { items: [] };
      r[dateKey]["items"].push({ ...item, sdate, edate });
      return r;
    }, {});

    return items;
  }

  generateTitleTree(titleList: any): TreeNode {

    let emptyNode = this.generateEmptyNode();

    let folderNode = new TreeNode();
    folderNode.id = "folder";
    folderNode.label = DATASOURCE_SETTING.DS_RECORD_ITEM;
    folderNode.isShowExpandIcon = true;
    folderNode.isShowIcon = true;
    folderNode.nodes = []

    let corpNode = new TreeNode();
    corpNode.id = "folder-corp";
    corpNode.label = DATASOURCE_SETTING.DS_COPR;
    corpNode.style = {'font-weight': 'bold'}
    corpNode.nodes = [];

    let officeNode = new TreeNode();
    officeNode.id = "folder-office";
    officeNode.label = DATASOURCE_SETTING.DS_OFFICE;
    officeNode.style = {'font-weight': 'bold'}
    officeNode.nodes = [];

    let kbnNodes: TreeNode[] = [];
    if(titleList.length) {
      titleList.map((item: any) => {
        //KBN
        let kbnNode = new TreeNode();
        kbnNode.id = `kbn+${item.officecd}+${item.ttlkbn}`;
        kbnNode.label = item.ttlkbnnm;
        kbnNode.labelId = kbnNode.id;
        kbnNode.isShowExpandIcon = false;
        kbnNode.isShowIcon = true;
        if(item.officecd === "00"){
          corpNode.nodes!.push(kbnNode);
        }
        else {
          officeNode.nodes!.push(kbnNode);
        }
        
        //TITLES
        let titles = Object.values(item.titles);
        let titleNodes: TreeNode[] = [];
        if (titles.length > 0) {
          titles.map((title: any, index: number) => {
            if (title) {
              title = Object.values(title);
              title = title[0]//Get first item
              if (!Utils.isNullOrEmpty(title.ttlcd)) {
                let titleNode = new TreeNode();
                titleNode.id = `title+${title.officecd}+${title.ttlkbn}+${title.ttlcd}`;
                titleNode.labelId = titleNode.id;
                titleNode.label = title.ttlnm;
                titleNode.isShowExpandIcon = false;
                titleNode.draggable = true;
                titleNode.isShowIcon = false;
                if (titleNode !== undefined && title.items?.length) {
                  const originalArray = title.items.map((item: any) => item.itemid);
                  const duplicateElements = originalArray.filter((item: string, index: number) => originalArray.indexOf(item) !== index)
                  if (duplicateElements.length > 0) {
                    let items = orderBy(title.items, ["sdate"], ['desc']);
                    let groupDate = this.groupDateTime(items);
                    if (Object.keys(groupDate).length > 0) {
                      let dateTimeNodes: TreeNode[] = [];
                      Object.keys(groupDate).map((key: any) => {
                        let dateTimeNode = new TreeNode();
                        dateTimeNode.id = `titledate+${key}`;
                        dateTimeNode.labelId = dateTimeNode.id;
                        dateTimeNode.label = key;
                        dateTimeNode.isShowExpandIcon = false;
                        dateTimeNode.draggable = true;
                        dateTimeNode.isShowIcon = false;
                        let itemNodes: TreeNode[] = [];
                        groupDate[key].items?.map((item: any) => {
                          //ITEMS
                          let itemData = this.createTitleMappingObject(item);
                          itemData.tooltip = `${item.ttlkbnnm} > ${item.ttlnm} > ${item.itemnm}`
                          let titleId = titleNode.id?.substring(6, titleNode.id.length); //01+001;
                          let itemNode = new TreeNode();
                          itemNode.id = `item+${titleId}+${item.itemid}+${item.datatype}+${item.id}`;
                          itemNode.data = [itemData];
                          itemNode.labelId = itemNode.id;
                          itemNode.label = item.itemnm;
                          itemNode.draggable = true;
                          itemNode.isShowExpandIcon = (item.childinfarray && item.childinfarray.length > 0) ? true : false;
                          itemNode.childinfarray = (item.childinfarray && item.childinfarray.length > 0) ? item.childinfarray : undefined;
                          itemNode.parentInf = item.parentInf;
                          itemNode.isLastNode = true;
                          itemNode.isShowIcon = false;
                          itemNodes.push(itemNode);
                        })
                        dateTimeNode.nodes = itemNodes;
                        dateTimeNodes.push(dateTimeNode);
  
                      })
                      titleNode.nodes = dateTimeNodes;
                      
                      for (var i = 0; i < titleNode.nodes.length; i++){
                        this.buildChildTree(titleNode.nodes[i], item, titleNode.nodes);
                      }
                      titleNode.nodes = titleNode.nodes.filter((node: any) => !(node.data[0]?.wtype == 11 || node.data[0]?.wtype == 10));
                    }
                  }else { 
                    let itemNodes: TreeNode[] = [];
                    //ITEMS
                    title.items.map((item: any) => {
                      let titleId = titleNode.id?.substring(6, titleNode.id.length); //01+001;
                      let itemData = this.createTitleMappingObject(item);
                      itemData.tooltip = `${item.ttlkbnnm} > ${item.ttlnm} > ${item.itemnm}`
                      let itemNode = new TreeNode();
                      itemNode.id = `item+${titleId}+${item.itemid}+${item.datatype}+${item.id}`;
                      itemNode.data = [itemData];
                      itemNode.labelId = itemNode.id;
                      itemNode.label = item.itemnm;
                      itemNode.draggable = true;
                      itemNode.isShowExpandIcon = false;
                      itemNode.isLastNode = true;
                      itemNode.isShowIcon = (item.childinfarray && item.childinfarray.length > 0) ? true : false;
                      itemNode.childinfarray = (item.childinfarray && item.childinfarray.length > 0) ? item.childinfarray : undefined;
                      itemNode.parentInf = item.parentInf;
                      itemNodes.push(itemNode);
                    })
                    titleNode.nodes = itemNodes;

                    //CHILDS
                    for (var i = 0; i < titleNode.nodes.length; i++){
                      this.buildChildTree(titleNode.nodes[i], item, titleNode.nodes);
                    }
                    titleNode.nodes = titleNode.nodes.filter((node: any) => !(node.data[0]?.wtype == 11 || node.data[0]?.wtype == 10));
                  }
                }
                titleNodes.push(titleNode);
              }
            }
          });
          kbnNode.nodes = titleNodes;
          kbnNodes.push(kbnNode);
        }
        else {
          let titleNodes: TreeNode[] = [];
          titles.map((title: any) => {
            if (title) {
              title = Object.values(title);
              title = title[0]//Get first item
              let itemData = this.createTitleMappingObject(title);
              itemData.tooltip = `${item.ttlkbnnm} > ${item.ttlnm} > ${item.itemnm}`
              let titleNode = new TreeNode();
              titleNode.id = `title+${title.officecd}+${title.ttlkbn}+${title.ttlcd}`;
              titleNode.data = [itemData];
              titleNode.labelId = titleNode.id;
              titleNode.label = title.ttlnm;
              titleNode.isShowExpandIcon = false;
              titleNode.draggable = true;
              titleNode.isShowIcon = false;
              titleNodes.push(emptyNode);
            }
          });
          kbnNode.nodes = titleNodes;
          kbnNodes.push(kbnNode);
        }
      })
    }
    else {
      kbnNodes.push(emptyNode);
    }

    if(!corpNode.nodes.length){
      corpNode.nodes.push(emptyNode)
    }
  
    if(!officeNode.nodes.length){
      officeNode.nodes.push(emptyNode)
    }

    folderNode.nodes = [corpNode, officeNode]
    let titleNode: TreeNode = {};
    titleNode.id = "titletree";
    titleNode.label = DATASOURCE_SETTING.DS_RECORD_ITEM;;
    titleNode.nodes = folderNode.nodes;
    return titleNode;
  }
  
  buildChildTree(node: any, item: any, titleNodeChild: any) {
    if (node.childinfarray && node.childinfarray.length > 0) {
      let hasChildren = node.childinfarray.filter((child: any) => child.childinfarray && child.childinfarray.length > 0);
      let noChildren = node.childinfarray.filter((child: any) => !child.childinfarray || child.childinfarray.length == 0);
      node.childinfarray = [...noChildren, ...hasChildren];
      let childNodes: TreeNode[] = [];
      node.childinfarray.map((child: any) => {
        let titleId = node.id?.substring(6, node.id.length);
        let childData = this.createTitleMappingObject(child);
        let childNode = new TreeNode();
        childNode.id = `item+${titleId}+${item.itemid}+${item.datatype}+${item.id}+${childData.ttlcd}+${childData.itemid}`;
        childNode.data = [childData];
        childNode.label = child.itemnm;
        childNode.labelId = child.id;
        childNode.draggable = true;
        childNode.isShowExpandIcon = false;
        childNode.isLastNode = true;
        childNode.isShowIcon = false;
        childNode.childinfarray = child.childinfarray && child.childinfarray.length > 0 ? child.childinfarray : undefined;
        childNode.parentInf = child.parentInf;
        if (childNode.childinfarray && childNode.childinfarray.length > 0){
          this.buildChildTree(childNode, item, titleNodeChild);
        }else {
          titleNodeChild.push(childNode);
        }
      });
      node.nodes = childNodes;
    }
  }

  createTitleMappingObject(item: any): TitleMapping {
    let itemData: TitleMapping = {
      id: "",
      officecd: item.officecd,
      blockcd: item.blockcd,
      unitcd: item.unitcd,
      ttlkbn: item.ttlkbn,
      ttlcd: item.ttlcd,
      itemid: item.itemid,
      displayname: item.itemnm,
      sdate: moment(item.sdate).format(DateFormat.DS_FULL_SHORT_DATE),
      edate: moment(item.edate).format(DateFormat.DS_FULL_SHORT_DATE),
      columntype: DataSourceColumnType.NORMAL,
      datatype: item.datatype,
      sortno: -1,
      notekbn: item.notekbn,
      dockbn: item.dockbn,
      delflg: false,
      tooltip: "",
      tabtype: item.tabtype ?  item.tabtype : this.getTabType(item.notekbn, item.dockbn),
      parentinf: item.parentinf,
      wtype: item.wtype
    }
    return itemData;
  }

  getTabType(notekbn: any, dockbn: any): TreeViewTab {
    let tabtypes: any = {
      '00': TreeViewTab.RECORD,
      '01': TreeViewTab.REPORT,
      '40': TreeViewTab.SCHEDULE
    };
    let key = `${notekbn}${dockbn}`;
    return tabtypes[key] || TreeViewTab.MASTER;
  }

  generateTagTree(itemTagList: any): TreeNode {
    let emptyNode = this.generateEmptyNode();
    let tagRootNode = new TreeNode();
    tagRootNode.id = 'tag';
    tagRootNode.label = DATASOURCE_SETTING.DS_TAG;
    tagRootNode.isShowExpandIcon = true;
    tagRootNode.isShowIcon = true;

    let corpNode = new TreeNode();
    corpNode.id = "tag-corp";
    corpNode.label = DATASOURCE_SETTING.DS_COPR;
    corpNode.style = {'font-weight': 'bold'}
    corpNode.nodes = [];

    let officeNode = new TreeNode();
    officeNode.id = "tag-office";
    officeNode.label = DATASOURCE_SETTING.DS_OFFICE;
    officeNode.style = {'font-weight': 'bold'}
    officeNode.nodes = [];

    let tags = itemTagList;
    let tagNodes: TreeNode[] = [];
    if(tags.length) {
      tags.forEach((tag: any) => {
        let tagData: TitleMapping = {
          id : "",
          displayname: tag.name,
          columntype: DataSourceColumnType.TAG,
          datatype: tag.datatype,
          officecd: tag.officecd, 
          sortno: -1,
          notekbn: 0,
          delflg: false,
          tooltip: tag.name,
          tabtype: tag.tabtype,
          titleuse: '',
        }
        let toolTipDSP = Utils.isNullOrEmpty(tag.tooltip) ?  tag.titles?.toString()?.split(',')?.join('\r\n') : tag.tooltip?.split(',')?.join('\r\n') || '';
        if(toolTipDSP?.length > 400) {
          toolTipDSP = toolTipDSP.substring(0,400) + '...';
        }
        let tagNode = new TreeNode();
        tagNode.id = `tag+${tag.name}+${tag.datatype}`;
        tagNode.labelId = v4().toString();
        tagNode.label = tag.name;
        tagNode.isShowIcon = false;
        tagNode.isShowExpandIcon = false;
        tagNode.draggable = true;
        tagNode.data = [tagData];
        tagNode.tooltip = toolTipDSP;

        if(tag.officecd === "00"){
          corpNode.nodes!.push(tagNode);
        }
        else {
          officeNode.nodes!.push(tagNode);
        }
      });
    } 
    else {
      tagNodes.push(emptyNode);
    }
  
    let treeViewParamsTags: TreeViewInputParams = {
      nodes: [],
      checkbox: false,
      allowParentSelect: true,
      expandIcon: '../../../assets/icons/folder.svg',
      collapseIcon: '../../../assets/icons/folder-opened.svg',
      isDisplayTooltip: true
    }
    if(!corpNode.nodes.length){
      corpNode.nodes.push(emptyNode)
    }
  
    if(!officeNode.nodes.length){
      officeNode.nodes.push(emptyNode)
    }
    tagRootNode.nodes = [corpNode, officeNode]
    treeViewParamsTags.nodes = [tagRootNode]

    let tagNode: TreeNode = {};
    tagNode.id = "tagtree";
    tagNode.label = DATASOURCE_SETTING.DS_TAG;
    tagNode.nodes = tagRootNode.nodes;
    return tagNode;
  }

  generateMasterDataTree(): TreeNode {
    let masterRootNode = new TreeNode();
    masterRootNode.id = 'master';
    masterRootNode.label = DATASOURCE_SETTING.DS_MASTERDATA;
    masterRootNode.isShowExpandIcon = true;
    masterRootNode.isShowIcon = true;
    let subNode = new TreeNode();
    subNode.label = DATASOURCE_SETTING.DS_MASTER_SUBLABEL;
    subNode.isShowExpandIcon = false;
    subNode.isShowIcon = false;
    let tableNodes: TreeNode[] =[];
    let searchMaster: any[] = [];
    let masterData = cloneDeep(MASTERDATAFORDATASOURCE);
    masterData.forEach((table:any) => {
      let fields = table.fields.filter((field:any) => field.displayName.toLowerCase().includes(this.searchText));
      if(fields.length ){
        table.fields = fields;
        searchMaster.push(table);
      }
    })

    if(searchMaster.length) {
      searchMaster.forEach(table => {
        let tableNode = new TreeNode();
        tableNode.id = `table+${table.table}`;
        tableNode.label = table.name;
        tableNode.isShowExpandIcon = false;
        tableNode.draggable = true;
        tableNode.isShowIcon = false;
        let fieldNodes: TreeNode[] = [];
        table.fields.forEach((field: any) => {
          let fieldNode = new TreeNode();
          let fieldData: TitleMapping = {
            id: "",
            displayname: field.displayName,
            columnname: field.field,
            columntype: DataSourceColumnType.MASTER,
            table: table.table,
            tablealias: table.alias,
            datetimetype: table.dateTimeType,
            datatype: field.dataType,
            startdatecolumn: table.startDateColumn,
            enddatecolumn: table.endDateColumn,
            sortno: -1,
            delflg: false,
            tooltip: `${table.name} > ${field.displayName}`,
            tablecondition: table.tableCondition || "",
            tabtype: TreeViewTab.MASTER,
            innerjoin: table.innerJoin,
            outputdata:  new OutputData(field.outputData)
          }
          fieldNode.id = `masterfield+${field.field}+${field.dataType}`;
          fieldNode.label = field.displayName;
          fieldNode.isShowExpandIcon = false;
          fieldNode.draggable = true;
          fieldNode.isShowIcon = false;
          fieldNode.data = [fieldData];
          fieldNode.visible = true;
          if(field.field == 'NUMBEROFDAYS') {
            fieldNode.visible = false;
            this.numberDateCol.push(cloneDeep(fieldNode.data[0]));
          }
          if(fieldNode.visible) {
            fieldNodes.push(fieldNode);
          } 
        })
        tableNode.nodes = fieldNodes;
        tableNodes.push(tableNode)
      })
    }
    else {
      let emptyNode = this.generateEmptyNode();
      tableNodes.push(emptyNode);
    }
    subNode.nodes = tableNodes;
    masterRootNode.nodes = [subNode];
    let masterFolderNode = new TreeNode();
    masterFolderNode.id = "mastertree";
    masterFolderNode.label =  DATASOURCE_SETTING.DS_MASTERDATA;
    masterFolderNode.nodes =[masterRootNode];
    return masterFolderNode;
  }

  generateSeikuyDataTree(): TreeNode  {
    let masterRootNode = new TreeNode();
    masterRootNode.id = 'seikuy';
    masterRootNode.label = DATASOURCE_SETTING.DS_SEIKUY;
    masterRootNode.isShowExpandIcon = true;
    masterRootNode.isShowIcon = true;
    let subNode = new TreeNode();
    subNode.label = DATASOURCE_SETTING.DS_SEIKUY_SUBLABEL;
    subNode.isShowExpandIcon = false;
    subNode.isShowIcon = false;
    let tableNodes: TreeNode[] =[];
    let searchMaster: any[] = [];
    let masterData = cloneDeep(SEIKUYFORDATASOURCE);
    masterData.forEach((node:any) => {
      let fields = node.fields.filter((field:any) => field.displayName.toLowerCase().includes(this.searchText));
      if(fields.length ){
        node.fields = fields;
        searchMaster.push(node);
      }
    })

    if(searchMaster.length) {
      searchMaster.forEach(table => {
        let tableNode = new TreeNode();
        tableNode.id = `seikuytype+${table.table}`;
        tableNode.label = table.name;
        tableNode.isShowExpandIcon = false;
        tableNode.draggable = true;
        tableNode.isShowIcon = false;
        let fieldNodes: TreeNode[] = [];
        table.fields.forEach((field: any) => {
          let fieldNode = new TreeNode();
          let fieldData: TitleMapping = {
            id: "",
            displayname: field.displayName,
            columnname: field.field,
            columntype: DataSourceColumnType.SEIKUY,
            table: table.table,
            datatype: field.dataType,
            sortno: -1,
            delflg: false,
            tooltip: `${table.name} > ${field.displayName}`,
            tabtype: TreeViewTab.SEIKUY
          }
          fieldNode.id = `seikuyfield+${field.field}+${field.dataType}`;
          fieldNode.label = field.displayName;
          fieldNode.isShowExpandIcon = false;
          fieldNode.draggable = true;
          fieldNode.isShowIcon = false;
          fieldNode.data = [fieldData];
          fieldNodes.push(fieldNode);
        })
        tableNode.nodes = fieldNodes;
        tableNodes.push(tableNode)
      })
    }
    else {
      let emptyNode = this.generateEmptyNode();
      tableNodes.push(emptyNode);
    }
    subNode.nodes = tableNodes;
    masterRootNode.nodes = [subNode];

    let seukuyNode: TreeNode = {};
    seukuyNode.id = "seikuytree";
    seukuyNode.label = DATASOURCE_SETTING.DS_SEIKUY;;
    seukuyNode.nodes =  masterRootNode.nodes;
    return seukuyNode;
  }

  generateEmptyNode() {
    let emptyNode = new TreeNode();
    emptyNode.id = `empty-node`;
    emptyNode.label = COMMON_TEXT.EMPTY_NODE;
    emptyNode.isShowExpandIcon = false;
    emptyNode.isShowIcon = false;
    emptyNode.draggable = false;
    return emptyNode;
  }

  searchData(searchText: any) {
    this.saucerLogService.action(
      {
        content: "タイトル、タグ検索: " + searchText,
      }, 
      {
        action: this.datasourceSettingLogAction.SEARCH_TITLE_AND_TAG
      }
    );    
    this.searchText = searchText.trim()?.toLowerCase();
    this.changeTabTreeView(this.selectedTabTree);
  }

  serializeNodes(nodes: TreeNode[], level: number, parent?: LazyTreeNode, showIcon?: boolean) {
    if (nodes && nodes.length) {
        for (const [index,node] of nodes.entries()) {
            const rowNode = new LazyTreeNode();
            rowNode.parent = parent;
            rowNode.id = node.id;
            rowNode.level = level;
            rowNode.isFolder = (level == 0);
            rowNode.text = node.label ?? '';
            rowNode.tooltip = node.tooltip?.toString() || '';
            rowNode.expandable = Boolean(node.nodes && nodes.length);
            rowNode.node = node;
            rowNode.draggable = node.draggable;
            rowNode.style = node.style;
            rowNode.icon = node.icon;
            if (!rowNode.parent) {
              rowNode.isFirstNode = true;
            }

            if (!node.nodes || node.nodes.length === 0) {
              rowNode.isLastNode = true;
              rowNode.showIcon = showIcon
            }
            rowNode.isLastChild  = (index == (nodes.length -1));
            rowNode.isParentLastChild = level <= 1 ? [] : ([...(parent?.isParentLastChild??[]), (parent?.isLastChild ?? true)]);
            this.lazyComposedData.push(rowNode);

            if (node.nodes && node.expanded) {
              rowNode.expanded = node.expanded;
              this.serializeNodes(node.nodes, level + 1, rowNode, showIcon);
            }
        }
    }
  }

  async changeCalcAte(value: Date) {
    if (!value) return;
    this.saucerLogService.action(
      {
        content: "算出日: " + moment(value).format('YYYY/MM/DD')
      }, 
      {
        action: this.datasourceSettingLogAction.CALCULATION_DATE
      }
    );
    this.calcAge = new Date(value.getFullYear(), value.getMonth(), value.getDate(), new Date().getHours());
  }

  async changeLapsedDay(value: Date) {
    if (!value) return;
    this.saucerLogService.action(
      {
        content: "基準日: " + moment(value).format('YYYY/MM/DD')
      }, 
      {
        action: this.datasourceSettingLogAction.BASE_DATE
      }
    );
    this.lapsedDay = value;
  }

  updateLazyTree(nodes?: TreeNode[]) {
    if(nodes && nodes.length) {
      this.lazyComposedData = [];
      this.serializeNodes(nodes!, 0, undefined, true);
      return [...this.lazyComposedData];
    }
    else return [];
  }

  handleTreeRootFolderClick(selectedTree: any) {
    let selectedTreeId = selectedTree.id;
    let selectedTreeData: TreeViewInputParams = selectedTree.treeData;
    let otherTrees = this.treeViewList.filter(tree => tree.id != selectedTreeId);
    if(otherTrees.length) {
      otherTrees.forEach(tree => {
        tree.lazyData = this.updateLazyTree(tree.treeData?.nodes[0].nodes);
      });
    }
    if(selectedTreeData.nodes[0].expanded) {
      selectedTreeData.nodes[0].expanded = false;
      //un-expand all nodes
      this.unexpandChilds(selectedTreeData.nodes);
      selectedTree.lazyData = this.updateLazyTree(selectedTreeId);
      selectedTree.treeHeight = this.treeDefaultHeight;
    }
    else {
      selectedTreeData.nodes[0].expanded = true;
      selectedTree.lazyData = this.updateLazyTree(selectedTreeData.nodes[0].nodes);
      selectedTree.treeHeight = this.setTreeHeight(selectedTree.lazyData);
    }
  }

  findNodeById(id?: string, nodes?: TreeNode[]): TreeNode | undefined {
    if (nodes && nodes.length > 0) {
      for (const node of nodes) {
        if (node.id === id) {
          return node;
        }

        return this.findNodeById(id, node.nodes);
      }
    }

    return undefined;
  }

  unexpandChilds(nodes?: TreeNode[]) {
    if (nodes && nodes.length > 0) {
      for (const node of nodes) {
        if (node.expanded) {
          node.expanded = false;
        }

        this.unexpandChilds(node.nodes);
      }
    }
  }

  setTreeHeight(lazyData?: []) {
    if (!this.templateLeftPanel) {
      this.templateLeftPanel = document.getElementById('data-source-tab-content')
    }
    if (this.templateLeftPanel) {
      this.scrollHeight = this.templateLeftPanel?.offsetHeight - 20;
    }
  }
  
  mouseLeaveContainer(event:any){
    const target = event.target as HTMLElement;
    target.classList.remove('hover');
    target.classList.add('unhover');
   
  }
  mouseEnterContainer(event:any){
    const target = event.target as HTMLElement;
    target.classList.add('hover');
    target.classList.remove('unhover');
  }
  handleItemClick(node: LazyTreeNode){
    this.nodeClicked = node;
    this.treeViewList = this.updateLazyTree(this.treeViewNode);
  }


  handleResizeLazyTree = (event: any) => {
    if (!this.templateLeftPanel) {
      this.templateLeftPanel = document.getElementById('data-source-tab-content')
    }
    if (this.templateLeftPanel) {
      this.scrollHeight = this.templateLeftPanel?.offsetHeight - 40;
    }
  }

  handleTreeDrop(event: any) {
    this.isShowOwnStfnmCol = true;
    const item = JSON.parse(event.dataTransfer.getData("lazy-tree-node")).node;
    if ((item.id.includes('title') || item.id.includes('table') || item.id.includes('seikuytype')) && item.nodes.length > 0) { //Drap in title nodes => add item nodes
      item.nodes.forEach((field: any) => {
        if (field.id.includes('titledate')) { // if node is date
          let fieldItems = field.nodes;
          if (fieldItems.length) {
            fieldItems.forEach((el: any) => this.addDropItemIntoSelectedColumn(el))
          }
        }
        else {
          this.addDropItemIntoSelectedColumn(field);
        }
      })
      if(this.mode == ScreenMode.ADD && this.isClickedAggregationTypeOnce == false) this.isUserChangedAction = false;
    }
    else if (item.id.includes('date')) { // Drap in date node => add item nodes
      let fieldItems = item.nodes;
      if (fieldItems.length) {
        fieldItems.forEach((el: any) => this.addDropItemIntoSelectedColumn(el))
      }
    }
    else {
      this.addDropItemIntoSelectedColumn(item);
      if(this.mode == ScreenMode.ADD && this.isClickedAggregationTypeOnce == false) this.isUserChangedAction = false;
    }
    const selectedColsFiltered = this.selectedColumns.filter(item => !item.data?.delflg && item.name !='利用日数');
    const selectedCols = selectedColsFiltered.map((x:any) => ({field: x?.value, displayname :x?.name, tooltip: x?.tooltip}));

    this.saucerLogService.action(
      {
        content: JSON.stringify(selectedCols),
      }, 
      {
        action: this.datasourceSettingLogAction.SELECT_TITLE
      }
    );

    this.generateDataForDropColumnsAndTable();
    this.isOver100Records = false;
  }

  onSubmitUserDefinedColumn(event: any){
    const newUserDefinedLog = {
      value: event.value,
      name: event.name,
      columnId: event.columnId,
      operator: event.operatorSaucerLog
    }

    if(event.columnId){
      let indexUpdate = this.selectedColumns.findIndex((column: any)=> column.columnId == event.columnId);

      let oldDataSelectedColsLog = this.selectedColumns[indexUpdate];
      const oldUserDefinedLog = {
        value: oldDataSelectedColsLog.value,
        name: oldDataSelectedColsLog.name,
        columnId: oldDataSelectedColsLog.columnId,
        operator: oldDataSelectedColsLog.operatorSaucerLog
      }

      this.saucerLogService.action(
        {
          content: JSON.stringify({
            old: oldUserDefinedLog,
            new: newUserDefinedLog
          }),
        }, 
        {
          action: this.datasourceSettingLogAction.EDIT_USER_DEFINED_COLUMN
        }
      );
      this.selectedColumns[indexUpdate].name = event.name;
      this.selectedColumns[indexUpdate].data.displayname = event.name;
      this.selectedColumns[indexUpdate].operator = event.operator;
      this.selectedColumns[indexUpdate].tooltip = event.tooltip;
    }else {
      event.columnId =  uuid().replace(/-/g,"");
      this.selectedColumns.push(event);
      this.saucerLogService.action(
        {
          content: JSON.stringify({
            old: null,
            new: newUserDefinedLog
          }),
        }, 
        {
          action: this.datasourceSettingLogAction.ADD_USER_DEFINED_COLUMN
        }
      );
    }
    (event.operatorColunm.map((op: any)=>{
      this.colUsed.push(op.value)
    }));
    this.generateDataForDropColumnsAndTable();
    this.isShowUserDefined = false;
  }

  onDeleteUserDefinedColumn(event: any) {
    const oldUserDefinedLog = {
      value: event.value,
      name: event.name,
      columnId: event.columnId,
      operator: event.operatorSaucerLog
    }
    this.saucerLogService.action(
      {
        content: JSON.stringify({
          old: oldUserDefinedLog,
          new: null
        }),
      }, 
      {
        action: this.datasourceSettingLogAction.DELETE_USER_DEFINED_COLUMN
      }
    );
    let selected = cloneDeep(this.selectedColumns);
    let selectedCols: any[]= [];
    for(let i = 0; i< selected.length; i++){
      if (isEqual(selected[i].columnId, event.columnId )) {
        selected[i].data.delflg = true;
        selectedCols = selected.filter((item: any) => !(item.data?.id == "" && item.data?.delflg));
        this.selectedColumns = cloneDeep(selectedCols);
        this.columnListParams.items = selectedCols.filter(item => !item.data?.delflg);
        this.columnListParams = this.columnListParams;
        this.removeHeaderOfTable(selected[i].data);
      }
    }
    this.isShowUserDefined = false;
  }
  addDropItemIntoSelectedColumn(item: any) {
    let existCol = this.selectedColumns.filter(i => {
      if (!i.data.delflg) {
        switch (i.data.columntype)
        {
          case DataSourceColumnType.NORMAL: 
            return i.data.officecd == item.data[0].officecd && i.data.ttlkbn == item.data[0].ttlkbn
              && i.data.ttlcd == item.data[0].ttlcd && i.data.itemid == item.data[0].itemid && i.data.parentinf == item.data[0].parentinf;
          case DataSourceColumnType.TAG:
            return i.data.displayname == item.data[0].displayname && i.data.officecd == item.data[0].officecd;
          case DataSourceColumnType.MASTER: 
          case DataSourceColumnType.SEIKUY:
            // Check that all characters belong to the same group
            const isSameGroup = this.areCharsInSameGroup(i.data.table) 
            && this.areCharsInSameGroup(item.data[0].table);
            // Check that i.data.table includes item.data[0].table
            const isSubstring = i.data.table.length === 1 || i.data.table.includes(item.data[0].table);

            return i.data.columnname == item.data[0].columnname 
              && isSameGroup 
              && isSubstring;
        }
      }
      return false;

    });
    if (existCol.length) {
      const isSeikyuu = item.data[0].columntype == DataSourceColumnType.SEIKUY;
      if(!isSeikyuu) return;
      
      let existSeikyuu = existCol.filter(e => e.data.columntype == DataSourceColumnType.SEIKUY);
      existSeikyuu.forEach(e =>  {
        // Mix table alias
        if(!e.data.table.includes(item.data[0].table)) e.data.table += item.data[0].table;
      });
      return;
    }
    let countActiveCols = this.selectedColumns.filter(i=>!i.data.delflg).length;
    if(countActiveCols >100){
      this.isDisplayWarning = true;
      this.warningMessage = '設定できる列項目の上限は100項目です。100項目以内で列項目を設定してください。';
    }
    this.selectedColumns.push({ value: `COL${item.data[0].sortno}`, name: item.label, defaultValue: this.getDataTypeOption(item.data[0].datatype), data: item.data[0], tooltip: item.data[0].tooltip, columnId: (uuid().replace(/-/g,"")) });
    
    let columns: any[] = []
    this.selectedColumns.forEach(item => {
      columns.push({ ...item.data })
    })
    var activeColumns = columns.filter(item => !item.delflg);
    let uniqueColumnType = uniqBy(activeColumns, "columntype").map(obj => obj.columntype).filter(columnType => columnType != DataSourceColumnType.USER_DEFINED);
    switch(true){
      case  uniqueColumnType.length == 0:
        this.dsType = DataSourceType.TITLE;
        this.groupedPeriod = this._groupedPeriod;
        break;
      case  uniqueColumnType.every(x=>x === DataSourceColumnType.MASTER):
        this.groupedPeriod = this._groupedPeriod;
        this.dsType = DataSourceType.MASTER;
        break;
      case  uniqueColumnType.every(x=>x === DataSourceColumnType.SEIKUY):
        this.dsType = DataSourceType.SEIKUY;
        this.groupedPeriod = this._groupedPeriod.filter((x: any) => x.value != "-1" && x.value != MstFilterDataTimeItem.ThisYear && x.value != MstFilterDataTimeItem.Today && x.value != MstFilterDataTimeItem.ThisWeek);
        break;
      default:
        this.dsType = DataSourceType.TITLE;
        this.groupedPeriod = this._groupedPeriod;
        break;
    }
    this.groupedPeriod.map((group:any) => group.items.map((item:any) => {
      if(this.dsType === DataSourceType.SEIKUY){
        item.label = item.contentseikuy ??  item.label
      }else{
        item.label = item.content ??  item.label
      }
    }));
    if(this.selectedPeriod && this.selectedPeriod != this.PERIOD_CUSTOM_VALUE){
      this.groupedPeriod.forEach((group: any) => {
        let selectedItem = group.items.filter((item:any) => item.value === this.selectedPeriod).pop();
        if(selectedItem){
          if(this.dsType === DataSourceType.SEIKUY){
            this.selectedRangeDate = {startDate: selectedItem.startdateseikuy, endDate: selectedItem.enddateseikuy}
           }else{
            this.selectedRangeDate = {startDate: selectedItem.startdate, endDate: selectedItem.enddate}
           }
        }
      });
    }
  }

  getCharGroup (char: string): number | null {
    for (let i = 0; i < SEIKYUU_TYPE.length; i++) {
      if (SEIKYUU_TYPE[i].includes(char)) {
        return i;
      }
    }
    return null; // Character not in any group
  };

  // Helper function to check if all characters belong to the same group
  areCharsInSameGroup(str: string): boolean {
    const groups = [...str].map(this.getCharGroup);
    return groups.every(group => group !== null && group === groups[0]);
  };

  getDataTypeOption(typeString: string): any {
    let dataType = this.dataTypeOptions;
    if (Utils.isNullOrEmpty(typeString)) return dataType[0]
    dataType = this.dataTypeOptions.filter(type => type.value === typeString);
    if (dataType) return dataType[0];
  }

  generateDataForDropColumnsAndTable() {
    this.selectedColumns?.map((i: any) => {
      if(i.data?.titleuse) {
        i.hilightColor = '#C8424C';
      }
    })
    this.setListParams();
    this.generateTable();
  }

  generateTable() {
    if (this.selectedColumns.filter(item => !item.data?.delflg).length) {
      let temp = cloneDeep(this.tableData);
      let tableHeader: HeaderItem[] = [];
      let hasReportAndRecordTabData = false;
      this.isShowAggregationType = this.selectedColumns.filter(item => !item.data?.delflg && item.data.table && item.data?.table == "HISIO").length > 0 ? true: false;
      this.selectedColumns.filter(item => !item.data?.delflg && item.name !='利用日数').forEach(col => {
        let header = new HeaderItem();
        header.field = col.value;
        header.title = col.name;
        header.data = col.data;
        header.dataType = DataType.TEXT;
        tableHeader.push(header);
        if (col?.data?.tabtype == TreeViewTab.SCHEDULE) {
          this.isHasYoteCol = true
        }
        if (col?.data?.tabtype == TreeViewTab.RECORD || col.data.tabtype == TreeViewTab.REPORT) {
          hasReportAndRecordTabData = true;
        }
      });
      let hasTitleColumns = this.selectedColumns.filter(item => (item.data.columntype === DataSourceColumnType.NORMAL 
              || item.data.columntype === DataSourceColumnType.TAG) && !item.data.delflg).length > 0;
      let defaultHeader = hasTitleColumns ? [...DATA_SOURCE_DEFAULT_HEADERS] : [];
            
      defaultHeader?.map((x:any) => {
        if (x.field == 'ownstfnm' || x.field == 'ownstfcd') {
          if (hasReportAndRecordTabData && this.isShowOwnStfnmCol) {
            x.visible = true;
          } else {
            x.visible = false;
          }
        }
        return x;
      });
  
      let defaultHeaderMater = this.dsType == DataSourceType.MASTER ? [...DATA_SOURCE_DEFAULT_HEADERS_MASTER] : [];
      let headers = [...defaultHeaderMater, ...defaultHeader, ...tableHeader];
      
      temp.header = headers.map(header => ({
        ...header, attribute: {
          rowClass: 'set-cursor-row-table',
          header: { 'width': `${100 / headers.length}%` },
          row: {}
        }
      }))
      if (temp.body.length > 0) {
        temp.body = [];
      }
      this.tableData = temp;
    }
    else {
      let temp = cloneDeep(this.tableData);
      if (temp.body.length > 0) {
        temp.body = [];
      }
      temp.header = []
      this.tableData = temp;
    }
  }

  removeHeaderOfTable(removeColumn: any) {
    if (this.isPreview) this.generateTable();
    else {
      this.isShowAggregationType = this.selectedColumns.filter(item => !item.data?.delflg && item.data.table && item.data?.table == "HISIO").length > 0 ? true: false;
      let temp = cloneDeep(this.tableData);
      temp.body = [];
      delete removeColumn.delflg;

      let hasTitleColumns = this.selectedColumns.filter(item => (item.data.columntype === DataSourceColumnType.NORMAL 
        || item.data.columntype === DataSourceColumnType.TAG) && !item.data.delflg).length > 0;

      if (this.selectedColumns.filter(item => !item.data?.delflg).length) {
        temp.header = temp.header.filter((item: any) => {
          let headerData = item.data;
          if (headerData) {
            delete headerData.delflg;
            return !isEqual(headerData, removeColumn);
          }
          else if(!hasTitleColumns){
            let matchHeader = DATA_SOURCE_DEFAULT_HEADERS.filter(col => col.field === item.field).length > 0;
            if(matchHeader) return false;
          }
          else if (item.field == "resultatr" || item.field == "roomattr" || item.field == "totaldays") return false;
          return true;
        });
        this.tableData = temp;
      }
      else {
        temp.header = [];
        this.dsType = DataSourceType.TITLE;
      }
      this.tableData = temp;
      const selectedColsRemoved_Log = this.selectedColumns.filter(item => !item.data?.delflg && item.name !='利用日数');
      const selectedCols_Log = selectedColsRemoved_Log.map((x:any) => ({field: x?.field, displayname :x?.data?.displayname, tooltip: x?.data?.tooltip}));
      this.saucerLogService.action(
        {
          content: JSON.stringify(selectedCols_Log),
        }, 
        {
          action: this.datasourceSettingLogAction.SELECT_TITLE
        }
      );
    }
  }

  getOfficeNameById(id: string) {
    let office = this.officeList.filter(o => o.officeCd === id);
    if (office.length) return office[0].officeNm;
    return ''
  }

  removeColum(removeItem: any) {
    this.isOver100Records = false;
    let selected = cloneDeep(this.selectedColumns);
    for(let i = 0; i< selected.length; i++){
      if (isEqual(selected[i].data, removeItem.data)) {
        selected[i].data.delflg = true;
        let selectedCols = selected.filter((item: any) => !(item.data?.id == "" && item.data?.delflg));
        this.selectedColumns = cloneDeep(selectedCols);
        this.columnListParams.items = selectedCols.filter(item => !item.data?.delflg && item.data?.displayname !='利用日数');
        this.columnListParams = this.columnListParams;
        this.removeHeaderOfTable(selected[i].data);
        if(this.columnListParams.items.length == 0) this.isUserChangedAction = false;
        return ({ ...selectedCols });
      }
    }
    return ({...selected});
  }

  sortingData(currentSelectedCols: any[]) {
    currentSelectedCols.filter(item => item.data && !item.data?.datatype).map(col => {
      let data = col.data;
      data.datatype = col.defaultValue?.value || "VARCHAR";
      return {...col, data}
    })
    this.isOver100Records = false;
    this.selectedColumns = [...currentSelectedCols];
    this.generateTable();
  }

  changeDataType(item: any) {
    if (!item) return;
    let changedColumn = item.itemSelected[0];
    let datatype = item.optionSelected;

    this.selectedColumns.map(col => {
      let selectedCol = cloneDeep(col);
      if (selectedCol.data.datatype) {
        delete selectedCol.data.datatype
      }
      if (changedColumn && changedColumn.data.datatype) {
        delete changedColumn.data.datatype
      }
      if (isEqual(selectedCol.data, changedColumn.data)) {
        col.data.datatype = datatype.value;
        col.defaultValue = datatype;
      }
      return col;
    })
    let columns = cloneDeep(this.selectedColumns);
    this.columnListParams.items = columns.filter(item => !item.data?.delflg && item.data?.displayname !='利用日数');
    this.columnListParams = cloneDeep(this.columnListParams);
    let table = cloneDeep(this.tableData);
    table.body =[];
    this.isOver100Records = false;
    this.tableData = table;
  }

  showOfficeModal() {
    this.errorHandleService.setFunctionTitle(FUNCTION_TITLE_TEXT.SCREEN_INIT_FAIL);
    this.isShowOffice = true;
  }

  closeOfficeModal() {
    this.isShowOffice = false;
  }

  showFolderModal() {
    this.saucerLogService.action(
      {
        content: CONTENT_LOG.SHOW_DIALOG,
      }, 
      {
        action: this.datasourceSettingLogAction.SELECT_FOLDER
      }
    );
    this.isShowFolder = true;
    if (this.selectedFolder && this.selectedFolder.id) {
      this.selectedFolderRow = this.folderList.find(f => f.folderCd === this.selectedFolder.id);
    }
  }

  closeFolderModal() {
    this.isShowFolder = false;
  }

  closeDocker(index: number) {
    let defaultSize: any = [0.05, 99.95];
    if (index === 0) {
      this.isShowLeftPannel = false;
      this.panelLeftSize = defaultSize
      this.panelLeftSize = cloneDeep(this.panelLeftSize)
      this.dockerList = this.dockerList.filter(item => item.pannel !== "left");
      this.dockerList.push({ name: DATASOURCE_SETTING.COLUMN_OFFICE_ITEM, pannel: 'left' });
    }
    if (index === 1) {
      this.isShowRightPannel = false;
      this.panelRightSize = defaultSize
      this.panelRightSize = cloneDeep(this.panelRightSize)
      this.dockerList = this.dockerList.filter(item => item.pannel !== "right");
      this.dockerList.push({ name: DATASOURCE_SETTING.COLUMN_LAYOUT, pannel: 'right' });
    }
    this.dockerList = cloneDeep(this.dockerList)

  }

  async changeOffices(office: Office[]) {
    this.loadingService.isLoading.emit(true);
    this.searchText = "";
    this.isOver100Records = false;
    //Check change office
    //No change
    if (JSON.stringify(office) === JSON.stringify(this.officeList)) {
      this.loadingService.isLoading.emit(false);
      return
    }
    else {
      let search = cloneDeep(this.searchParams);
      search.defaultValue = undefined;
      this.searchParams = search;
      localStorage.setItem(LocalStorageKey.SELECTED_OFFICES, JSON.stringify(office));
      this.officeList = cloneDeep(office);
      this.titleMappingLoadList = [];
      this.rawTitleMapping = [];
      await this.changeTabTreeView(this.selectedTabTree, false, false )
      if(this.officeList.length > 1 ){
        this.dsType = this.DSTYPE.TITLE;
      }
      // If mode edit, set all column with deflg = false;
      if( this.mode === ScreenMode.EDIT){
        this.selectedColumns.map(item => {
          item.data.delflg = true;
          return {...item}
        });

      }
      else {
        this.selectedColumns = []; 
      }
      this.includingstartdate = true;
      this.dischargedate = true;
      this.columnListParams.items = this.selectedColumns.filter(item => !item.data?.delflg && item.data?.displayname !='利用日数');
      this.columnListParams = cloneDeep(this.columnListParams);
      this.generateTable();
      this.loadingService.isLoading.emit(false);
    }
  }

  changeFolder(folder: any) {
    if (!Utils.isNullOrEmpty(folder)) {
      let actionLog = this.datasourceSettingLogAction.SELECT_FOLDER_SELECTION;
      this.saucerLogService.action(
        {
          content: CONTENT_LOG.FOLDER_SELECTION + ': ' +  folder.name
        }, 
        {
          action: actionLog
        }
      );
      this.selectedFolder.id = folder.folderCd;
      this.selectedFolder.name = folder.name;
    } else this.selectedFolder = null;
  }

  openDialog(dialogType: DialogType, isNavigate: boolean = true) {
    let modal = this.modalService.open(ConfirmDialogComponent, {
      data: {
        dialogType: dialogType,
      }
    });
    modal.onClose.subscribe(() =>{
      if (isNavigate) {
        if (this.isDataSourceTemplate) {
          this.navigateTo([ROUTE_PATH.DATASOURCE_TEMPLATE]);
        } else {
          this.navigateTo([ROUTE_PATH.DATASOURCE]);
        }
      }
    });
  }

  async openDeleteDialog() {
    this.isDeleteDS = true;
    this.renderTable = [];
    this.nameDataSourceDelete = [this.editDataSourceData.dsname];
    await this.generateRelationshipMap(this.editDataSourceData);
    await this.generateWarningDetails();
    this.errorHandleService.setFunctionTitle(FUNCTION_TITLE_TEXT.DELETE_DATA_FAIL);
    this.isDisplayConfirmDeleteModal = true;
    this.delTxt = COMMON_TEXT.DATASOURCE;
  }
  
  generateWarningDetails(){
    let data = this.datasourceRelationalTree[0];
    if(data){
      let widgetNames = {
        widgetNames: data.widgetNames
      }
      let dashboardNames = {
        dashboardNames: data.dashboardNames.filter( (e: any) => e != "")
      }
      this.renderTable.push(widgetNames);
      this.renderTable.push(dashboardNames);
    }
  }

  updateDockerList(event : any) {
    this.dockerList = event.dockerList;
    this.dockerList = cloneDeep(this.dockerList);
  }
  
  async generateRelationshipMap(arr: any){
    this.loadingService.isLoading.emit(true);
    if(arr.dstructcd != "")
    {
      let result = await this.widgetService.getWidgetList(arr.dstructcd);
    if(result.data != null && result.data.length > 0)
      this.datasourceRelationalTree = result.data;
    }
    this.loadingService.isLoading.emit(false);
  }

  async onConfirmDeleteDlg(event: any) {
    if (event) {
      this.loadingService.isLoading.emit(true);
      let datasources: string[] = [];
      datasources.push(this.dataSourceCd);
      let actionLogDelete = this.isDataSourceTemplate ? SAUCER_LOG_ACTION.EDIT_DATA_SOURCE_TEMPLATE : SAUCER_LOG_ACTION.EDIT_DATA_SOURCE
      let deleteResult = await this.dataSourceService.delete(datasources, actionLogDelete);
      if (deleteResult.statuscode == 200) {
        this.loadingService.isLoading.emit(false);
        this.openDialog(DialogType.delete);
      }
    }
    this.isDisplayConfirmDeleteModal = false;
  }

  async previewOrSaveDataSource(isPreview: boolean, isSaveUnSavedData: boolean = false) {
    this.loadingService.isLoading.emit(true);
    let allColumns : any[] = [];
    let startDate = this.selectedRangeDate.startDate.trim();
    let endDate = this.selectedRangeDate.endDate.trim();
    if(!this.isDuplicated) {
      // do not get item that have id ="" and delflg = true => items that added/deleted on UI only
      this.selectedColumns = this.selectedColumns.filter((item :any) => !(item.data && item.data?.id === "" && item.data?.delflg) );
    }
    else {
      this.selectedColumns = this.selectedColumns.filter((item :any) => !item.data?.delflg ); //Duplicate ds not care about delflg false;
    }
    let selectedColumns = cloneDeep(this.selectedColumns);
    let sortno = 16;// sortNo = 16 because we index for default headers
    selectedColumns.forEach((item:any) => {
      let sortNo = 0;
      if(item.data?.delflg){// if item is deleted item => do not update sortno, keep as it is
        sortNo = item.data.sortno;
      }
      else if (item.data?.columnname === "NUMBEROFDAYS") {
        sortNo = DatasourceValueConfig.LASTEST_SORTNO; //set to end of col list
      }
      else { // if item is added item => set sort not increasing
        sortNo = sortno++;
      }

      switch (item.data.columntype) {
        case DataSourceColumnType.MASTER: 
        case DataSourceColumnType.SEIKUY:
          allColumns.push({ ...item.data, sortno: sortNo, columnId: item.columnId});
          break;
        case DataSourceColumnType.USER_DEFINED: 
          allColumns.push({ ...item.data, columnname: `CUS${sortNo}`, sortno: sortNo, columnId: item.columnId, operator: item.operator});
          break;
        default: 
          allColumns.push({ ...item.data, columnname: `COL${sortNo}`, sortno: sortNo , columnId: item.columnId});
        break; 
      }
    })
    let colDefilne = allColumns.filter((col:any)=>col.columntype == DataSourceColumnType.USER_DEFINED);
    if(colDefilne && colDefilne.length > 0){
      allColumns = updateOperatorWithColName(allColumns, this.dsType);
    } 

    var activeColumns = allColumns.filter(item => !item.delflg);
    let uniqueColumnType = uniqBy(activeColumns, "columntype").map(obj => obj.columntype).filter(columnType => columnType != DataSourceColumnType.USER_DEFINED);;
    let dsType = 0;
    
    switch(true){
      case  uniqueColumnType.length == 0:
        dsType = DataSourceType.TITLE;
        break;
      case  uniqueColumnType.every(x=>x === DataSourceColumnType.MASTER):
        dsType = DataSourceType.MASTER;
        break;
      case  uniqueColumnType.every(x=>x === DataSourceColumnType.SEIKUY):
        dsType = DataSourceType.SEIKUY;
        break;
      default:
        dsType = DataSourceType.TITLE;
        break;
    }


    if(this.dsType == DataSourceType.MASTER) {
      let findSdateCol = allColumns.filter(m => m.columnname == 'SDATE' && !m.delflg)
      let findEdateCol = allColumns.filter(m => m.columnname == 'EDATE' && !m.delflg)
      if(findSdateCol?.length > 0 && findEdateCol?.length > 0 && this.numberDateCol?.length > 0) {
        if(allColumns.filter(m => m.columnname == 'NUMBEROFDAYS' && !m.delflg).length == 0) {
          let hisioColumns = allColumns?.filter( mt => !mt.delflg && mt.tablealias?.includes('HS'))
          let numberOfDateCol = this.numberDateCol.find(col => col.tablealias == hisioColumns[0]?.tablealias )
          if(numberOfDateCol) {
            numberOfDateCol.sortno = sortno
            numberOfDateCol.datatype = DATATYPE.Number; 
            allColumns.push(numberOfDateCol)
          }
        }
      }
      let lstColNumberDate = allColumns.filter(m => m.columnname == 'NUMBEROFDAYS' && !m.delflg);
      if(lstColNumberDate?.length > 1) {
        let index = 0
        allColumns.forEach( col => {
          if(!col.delflg && col.columnname == 'NUMBEROFDAYS') {
            if(index > 0) col.delflg = true
            index ++
          }
        })
      }
    }

    allColumns = orderBy(allColumns,["delflg", "sortno"] )
    let period = ''
    if(this.selectedPeriod === '-1'){
      period = `${startDate} ~ ${endDate}`
    }
    else {
      period = this.selectedPeriod;
    }

    if (isPreview) {
      this.loadingService.isLoading.emit(true);
      this.isPreview = true;
      this.errorHandleService.setFunctionTitle(FUNCTION_TITLE_TEXT.PREVIEW_FAIL);
      this.errorHandleService.backURLSub.next("");
      let dsPreview = this.dataSourceCd != "" ? uuid() : this.dataSourceCd ;
      LocalStorageHelper.setKey("DSPREVIEW", dsPreview);
      let bodyRequest = {
        DsType: dsType,
        DsStructCd: dsPreview,
        Startdate: startDate,
        Enddate: endDate,
        Period: period,
        Columns: allColumns.filter(x => x.columntype === DataSourceColumnType.NORMAL || x.columntype  === DataSourceColumnType.TAG),
        MasterColumns: allColumns.filter(x => x.columntype === DataSourceColumnType.MASTER),
        SeikuyColumns: allColumns.filter(x => x.columntype === DataSourceColumnType.SEIKUY),
        UserDefinedColumns: allColumns.filter(x => x.columntype === DataSourceColumnType.USER_DEFINED),
        OfficeCds: this.officeList.map(o => o.officeCd),
        IsLastest: this.isOnlyLastestData,
        AggregationType: { includingstartdate: this.includingstartdate, dischargedate : this.dischargedate },
        Dspdateflg: this.isDisplayDate,
        Dspbedflg: this.isBedDisplay,
        DspAgeflg: this.isDspAge,
        DspBlockUnit: this.isDspBlockUnit,
        CalcAge: moment(this.calcAge).format(DateFormat.DS_FULL_SHORT_DATE),
        LapsedDay: this.isDspLapsedDay ? moment(this.lapsedDay).format(DateFormat.FULL_SHORT_DATE) : null
      }

      this.dataSourceService.previewDataSourceTable(bodyRequest, this.datasourceSettingLogAction).then(res => {
        if(res.statuscode === 200) {
          const screenCode = 1 + Math.random();
          let intervalId = setInterval(()=> {
            if(this.checkIfStopInterval(screenCode, intervalId)) {
              return;
            }
            this.stepFunctionService.checkStatus(res.data.lambdaresponse.executionarn).then(resLamda => {
              if(resLamda.statuscode && resLamda.statuscode == 500) {
                clearInterval(intervalId);
                this.removeIntervalIdToList(intervalId);
                this.saucerLogService.error(
                  {
                    apiPath: 'stepfunction' + API_APP.STEPFUNCTION.CHECK_STATUS + "/" + res.data.lambdaresponse.executionarn,
                    statusCode: resLamda.statuscode,
                    body: resLamda,
                    content: "stepfunction: " + res.data.lambdaresponse.executionarn
                  }, 
                  {
                    action: SAUCER_LOG_ACTION.COMMON_CALL_API.GET
                  }
                );
              } else {
                if(resLamda.data.status === LambdaStatus.SUCCEEDED)
                {
                  this.saucerLogService.system(
                    {
                      apiPath: 'stepfunction' + API_APP.STEPFUNCTION.CHECK_STATUS + "/" + res.data.lambdaresponse.executionarn,
                      statusCode: resLamda.statuscode,
                      body: resLamda,
                      content: "stepfunction: " + res.data.lambdaresponse.executionarn
                    }, 
                    {
                      action: SAUCER_LOG_ACTION.COMMON_CALL_API.GET
                    }
                  );
                  clearInterval(intervalId);
                  this.removeIntervalIdToList(intervalId);
                  this.dataSourceService.getResultOnS3(dsPreview).then(res =>{
                    let resultData = JSON.parse(res.data);
                    if(res.statuscode == 200 && resultData.status === true && resultData.outputdata) {
                      this.previewDataSourceData = resultData.outputdata;
                      if(Object.keys(resultData.outputdata).length > 0  &&  resultData.outputdata.datatable.length > 0) 
                      {
                        let dt = resultData.outputdata.datatable ? resultData.outputdata.datatable : []
                        if(dsType != DataSourceType.SEIKUY) {
                          this.previewDataTable(dt, bodyRequest.Columns, bodyRequest.MasterColumns, bodyRequest.UserDefinedColumns, false, []);
                        }
                        else {
                          this.previewSeikuyDataTable(dt, bodyRequest.SeikuyColumns, bodyRequest.UserDefinedColumns)
                        }
                        
                        this.isOver100Records = resultData.outputdata.isover100records;
                        this.loadingService.isLoading.emit(false)
                      }
                      else {
                        this.clearDataSourceData();
                      }
                    }
                    else {
                      this.clearDataSourceData();
                    }
                  }).finally(() => this.loadingService.isLoading.emit(false))
                }
                else if(resLamda.data.status != LambdaStatus.RUNNING) {
                  clearInterval(intervalId);
                  this.removeIntervalIdToList(intervalId);
                  this.loadingService.isLoading.emit(false);
                  this.clearDataSourceData();
                  if(resLamda.data.output.isoverlimitrecord) {
                    this.isDisplayWarning = true;
                    this.warningMessage = MESSAGE_TEXT.DS_OVER_LIMIT_RECORDS;
                  }
                }
              }
            })
          }, 5000);
          this.addIntervalIdToList(intervalId);
        }
        else {
          this.clearDataSourceData();
        }  
      })
    }
    else {
      this.isPreview = false;
      this.errorHandleService.setFunctionTitle(FUNCTION_TITLE_TEXT.SAVE_DATA_FAIL);
      this.errorHandleService.backURLSub.next("");
      let officeCds = this.officeList.map(o => o.officeCd).sort((a,b) => {
        return Number(a) - Number(b)
      })
      let bodyRequest: any = {
        id: this.dsID,
        dstype: dsType,
        isupdate: this.mode === this.screenMode.ADD ? false : true,
        foldercd: this.selectedFolder.id,
        dsstructcd: this.dataSourceCd || "",
        dsname: this.nameDataSource,
        period: period,
        officecds: officeCds,
        columns: allColumns.filter(x => x.columntype === DataSourceColumnType.NORMAL || x.columntype  === DataSourceColumnType.TAG),
        masterColumns: allColumns.filter(x => x.columntype === DataSourceColumnType.MASTER),
        seikuyColumns: allColumns.filter(x => x.columntype === DataSourceColumnType.SEIKUY),
        userDefinedColumns: allColumns.filter(x => x.columntype === DataSourceColumnType.USER_DEFINED),
        startdate: startDate,
        enddate: endDate,
        lastestflg: this.isOnlyLastestData,
        dspdateflg: this.isDisplayDate,
        dspbedflg: this.isBedDisplay,
        aggregationType: {includingstartdate: this.includingstartdate, dischargedate : this.dischargedate},
        WidgetCds :this.selectedWidgets.map(x=> x["widgetcd"]),
        dspageflg: this.isDspAge,
        dspblockunit: this.isDspBlockUnit,
        calcage: moment(this.calcAge).format(DateFormat.DS_FULL_SHORT_DATE),
        lapsedday: this.isDspLapsedDay? moment(this.lapsedDay).format(DateFormat.FULL_SHORT_DATE) : null
      }

      let columnListLogNew = this.getLisOperatorSaucerLog(this.columnListParams?.items);
      const logContentNew = {
        dstype: bodyRequest.dstype,
        foldercd: bodyRequest.foldercd,
        dsstructcd: bodyRequest.dsstructcd,
        dsname: bodyRequest.dsname,
        period: bodyRequest.period,
        officecds: bodyRequest.officecds,
        columnList: columnListLogNew
      }
     
      let columnListLogOld = this.getLisOperatorSaucerLog(this.oldDSLogData?.columnListParams);
      const logContentOld = {
        dstype: this.oldDSLogData?.dstype,
        foldercd: this.oldDSLogData?.foldercd,
        dsstructcd: this.oldDSLogData?.dstructcd,
        dsname: this.oldDSLogData?.dsname,
        period: this.oldDSLogData?.period,
        officecds: this.oldDSLogData?.officeidlst,
        columnList: columnListLogOld
      }

      if (this.mode === this.screenMode.EDIT) {
        bodyRequest.insstfcd = this.editDataSourceData.insstfcd;
        bodyRequest.insdate = this.editDataSourceData.insdate;
      }

      let actionLog = isSaveUnSavedData ? this.datasourceSettingLogAction.SAVE_UNSAVED_DATA : this.datasourceSettingLogAction.SAVE;
      
      if(isSaveUnSavedData) {
        this.saucerLogService.action({
          content: CONTENT_LOG.SAVE_UNSAVED_DATA
        }, {action: this.datasourceSettingLogAction.SAVE_UNSAVED_DATA});
      }
      
      await this.dataSourceService.createAndUpdate(bodyRequest).then(resCreate => {
        if(resCreate.statuscode === 200) {
          this.saucerLogService.system(
            {
              apiPath: "datasource" + API_APP.DATASOURCE.INSERTORUPDATE,
              body: bodyRequest,
              statusCode: resCreate.statuscode,
              content: JSON.stringify({
                old: this.mode === this.screenMode.ADD ? null : logContentOld,
                new: logContentNew
              }) 
            },
            {
              action: actionLog
            }
          );
          const screenCode = 1 + Math.random();
          let intervalId = setInterval(()=> {
            if(this.checkIfStopInterval(screenCode, intervalId)) {
              return;
            }
            this.stepFunctionService.checkStatus(resCreate.data.lambdaresponse.executionarn).then(resLamda => {
              if(resLamda.statuscode && resLamda.statuscode == 500) {
                clearInterval(intervalId);
                this.removeIntervalIdToList(intervalId);
                this.saucerLogService.error(
                  {
                    apiPath: "stepfunction" + API_APP.STEPFUNCTION.CHECK_STATUS + "/" + resCreate.data.lambdaresponse.executionarn,
                    body: resLamda,
                    statusCode: resCreate.statuscode,
                    content: "stepfunction: " + resCreate.data.lambdaresponse.executionarn
                  },
                  {
                    action: SAUCER_LOG_ACTION.COMMON_CALL_API.GET
                  }
                );
              }
              else {
                if(resLamda.data.status === LambdaStatus.SUCCEEDED)
                {
                  this.saucerLogService.system(
                    {
                      apiPath: "stepfunction" + API_APP.STEPFUNCTION.CHECK_STATUS + "/" + resCreate.data.lambdaresponse.executionarn,
                      body: resLamda,
                      statusCode: resCreate.statuscode,
                      content: "stepfunction: " + resCreate.data.lambdaresponse.executionarn
                    },
                    {
                      action: SAUCER_LOG_ACTION.COMMON_CALL_API.GET
                    }
                  );
                  clearInterval(intervalId);
                  this.removeIntervalIdToList(intervalId);
                  this.dataSourceService.getResultOnS3(resCreate.data.dsstructcd).then(res =>{
                    let resultData = JSON.parse(res.data);
                    if(res.statuscode == 200 && resultData.status) {
                      this.openDialog(this.dialogType.save, this.isNavigate);
                      this.loadingService.isLoading.emit(false)
                    }
                  }).finally(() => this.loadingService.isLoading.emit(false))
                }
                else if(resLamda.data.status != LambdaStatus.RUNNING && resLamda.data.status != "") {
                  clearInterval(intervalId);
                  this.removeIntervalIdToList(intervalId);
                  this.loadingService.isLoading.emit(false);
                  if(resLamda.data.output.isoverlimitrecord) {
                    this.isDisplayWarning = true;
                    this.warningMessage = MESSAGE_TEXT.DS_OVER_LIMIT_RECORDS;
                  }
                }
            }
            })
          }, 5000);
          this.addIntervalIdToList(intervalId);
        } 
        else {
          this.saucerLogService.error(
            {
              apiPath: "datasource" + API_APP.DATASOURCE.INSERTORUPDATE,
              body: bodyRequest,
              content: JSON.stringify({
                old: this.mode === this.screenMode.ADD ? null : logContentOld,
                new: logContentNew
              })
            },
            {
              action: actionLog 
            }
          );
          this.loadingService.isLoading.emit(false);
        }
      })
    }
  }

  setNameDataSource(name: string) {
    this.nameDataSource = name.trim();
  }

  isDisabledPreviewButton(): boolean {
    if (this.columnListParams.items.filter((col: any) => col.data.columntype != DataSourceColumnType.USER_DEFINED).length === 0 || this.columnListParams.items.length > 100) {
      return true;
    }

    return false;
  }

  isDisabledButton(): boolean {
    if (Utils.isNullOrEmpty(this.nameDataSource) || this.nameDataSource === "" || Utils.isNullOrEmpty(this.selectedFolder.id) || this.columnListParams.items.length > 100) return true;
    return false;
  }

  duplicatedRecord() {
    this.saucerLogService.action(
      {
        content: "別名で保存する: " +  this.nameDataSource + COMMON_TEXT.EXTEND_COPY,
      }, 
      {
        action: this.isDataSourceTemplate ? SAUCER_LOG_ACTION.EDIT_DATA_SOURCE_TEMPLATE.SAVE_AS : SAUCER_LOG_ACTION.EDIT_DATA_SOURCE.SAVE_AS
      }
    );

    this.loadingService.isLoading.emit(true);
    this.nameDataSource = this.nameDataSource + COMMON_TEXT.EXTEND_COPY;
    this.titlePage = this.isDataSourceTemplate ? MENU_NAME.DATASOURCE_TEMPLATE_CREATE : MENU_NAME.DATASOURCE_CREATE;
    this.mode = ScreenMode.ADD;
    this.isOver100Records = false;
    this.generateTable();
    var table = cloneDeep(this.tableData);
    table.body = [];
    this.tableData = table;
    this.isDuplicated = true;
    setTimeout(() => {
      this.loadingService.isLoading.emit(false);
    }, 200);
  }

  previewDataTable(data: any, titleColumns: any[], masterColumns: any[], userDefinedColumns: any[], isEdit: boolean, dssetting: any[]) {
  
    let temp = cloneDeep(this.tableData);
    let headers: any[] = [];
    let tableHeader: HeaderItem[] = [];
    let defaultHeaders = DATA_SOURCE_DEFAULT_HEADERS.map(item => {
      if(item.field.toLowerCase() == 'dspdate') {
        item.visible = this.isDisplayDate;
      }
      let header: any = { ...item };
      header.field = item.field;
      header.title = item.title;
      return header;
    })

    let defaultHeadersMaster = DATA_SOURCE_DEFAULT_HEADERS_MASTER.map(item => {
      let header: any = { ...item };
      header.field = item.field;
      header.title = item.title;
      return header;
    })
    
    let extraHeader: HeaderItem[] = [];
    let headerKey:string[] = [];
    if(isEdit) 
      headerKey = dssetting.filter(x => !x.delflg).map((col: any) =>  col.columnname.toLowerCase());
    else if(data.length)
      headerKey = Object.keys(data[0]);
 
      let isHasTotalDays = false;
      let isAddTotalDays = false;
      headerKey.forEach(key => {
        let isDefaultHeader = defaultHeaders.filter(header => header.field.toLowerCase() === key.toLowerCase()).length > 0;
        let isDefaultHeaderMaster = defaultHeadersMaster.filter(header => header.field.toLowerCase() === key.toLowerCase()).length > 0;
        if(key.toLowerCase() == 'blocknm') {
          let block = DATA_SOURCE_DEFAULT_HEADERS.filter(header => header.field === "blocknm").map(item => {
            let header: any = { ...item };
            header.visible = true;
            header.sortno = 5;
            return header;
          }).pop() as HeaderItem;
          extraHeader.push(block);
        }
        if(key.toLowerCase() == 'unitnm') {
          let unit = DATA_SOURCE_DEFAULT_HEADERS.filter(header => header.field === "unitnm").map(item => {
            let header: any = { ...item };
            header.visible = true;
            header.sortno = 6;
            return header;
          }).pop() as HeaderItem;
          extraHeader.push(unit);
        }
        if (key.toLowerCase() == "roomnm") {
          let roomnm = DATA_SOURCE_DEFAULT_HEADERS.filter(header => header.field === "roomnm").map(item => {
            let header: any = { ...item };
            header.visible = true;
            header.sortno = 7;
            return header;
          }).pop() as HeaderItem;
          extraHeader.push(roomnm);
        }
        
        if (key.toLowerCase() == "schedbedcd") {
          let schedbedcd = DATA_SOURCE_DEFAULT_HEADERS.filter(header => header.field === "schedbedcd").map(item => {
            let header: any = { ...item };
            header.visible = true;
            header.sortno = 8;
            return header;
          }).pop() as HeaderItem;
          extraHeader.push(schedbedcd);
        }
        if(key.toLowerCase() == 'ownstfcd' && !this.isHasYoteCol) {
          if (this.mode == ScreenMode.EDIT && !this.isShowOwnStfnmCol) {
            let ownstfcd = DATA_SOURCE_DEFAULT_HEADERS.filter(header => header.field === "ownstfcd").map(item => {
              let header: any = { ...item };
              header.visible = true;
              header.sortno = 10;
              return header;
            }).pop() as HeaderItem;
            extraHeader.push(ownstfcd);
          }
        }
        if(key.toLowerCase() == 'ownstfnm' && !this.isHasYoteCol) {
          if (this.mode == ScreenMode.EDIT && !this.isShowOwnStfnmCol) {
            let ownstfnm = DATA_SOURCE_DEFAULT_HEADERS.filter(header => header.field === "ownstfnm").map(item => {
              let header: any = { ...item };
              header.visible = true;
              header.sortno = 11;
              return header;
            }).pop() as HeaderItem;
            extraHeader.push(ownstfnm);
          }
        }
        if (key.toLowerCase() == "totaldays") {
          let totalDay = DATA_SOURCE_DEFAULT_HEADERS.filter(header => header.field === "totaldays").map(item => {
            let header: any = { ...item };
            header.visible = true;
            header.attribute ={
                header: { },
                row: {},
                childrenCols: [],
                isMergeCells: false
              }
            return header;
          }).pop() as HeaderItem;
          extraHeader.push(totalDay);
          isHasTotalDays = true;
        }

        if (key.toLowerCase() == "totaltime") {
          let totalTime = DATA_SOURCE_DEFAULT_HEADERS.filter(header => header.field === "totaltime").map(item => {
            let header: any = { ...item };
            header.visible = true;
            return header;
          }).pop() as HeaderItem;
          extraHeader.push(totalTime);
        }

        if(isHasTotalDays === false) {
          if (key.toLowerCase().startsWith("ttd")) {

            if(isAddTotalDays === false){
            }
  
            const MonthAndYear: string[] = key.split('_');
            let columnDisplayName: string = '';
            const Month: string = MonthAndYear[1].substring(4, 6);
            if(MonthAndYear[1].length != 8) {
              columnDisplayName = `${Month}月`;
            }
            else {
              const Day: string = MonthAndYear[1].substring(6, 8);
              columnDisplayName = `${Month}/${Day}`;
            }
            let tdDetail =   {
              field: key,
              title: columnDisplayName,
              sortable: false,
              filterable: false,
              visible: true,
              attribute: {
                header: { },
                row: {}
              },
              dataType: DataType.NUMBER,
              sortno: Number.MAX_SAFE_INTEGER + 1
            } as HeaderItem;
            extraHeader.push(tdDetail);
            isAddTotalDays = true;
          }
        }

        if(key.toLowerCase() == "totaltime" || isHasTotalDays || isAddTotalDays) {
          if(extraHeader.filter(h => h.field === "resultatr").length == 0) {
            let resultatr = DATA_SOURCE_DEFAULT_HEADERS.filter(header => header.field === "resultatr" ).map(item => {
              let header: any = { ...item };
              header.visible = true;
              return header;
            }).pop() as HeaderItem;
            extraHeader.push(resultatr);
          }
        }

        if(key.toLowerCase() == "roomattr")
        {
          let roomAttrHeader = DATA_SOURCE_DEFAULT_HEADERS.filter(header => header.field === "roomattr").map(item => {
            let header: any = { ...item };
            header.visible = true;
            return header;
          }).pop() as HeaderItem;
          extraHeader.push(roomAttrHeader);
        }

        if(key.toLowerCase() == "prvtime")
        {
          let prvTime = DATA_SOURCE_DEFAULT_HEADERS.filter(header => header.field === "prvtime").map(item => {
            let header: any = { ...item };
            header.visible = true;
            return header;
          }).pop() as HeaderItem;
          extraHeader.push(prvTime);
        }

        if(key.toLowerCase() == "prvhour")
          {
            let prvTime = DATA_SOURCE_DEFAULT_HEADERS.filter(header => header.field === "prvhour").map(item => {
              let header: any = { ...item };
              header.visible = true;
              return header;
            }).pop() as HeaderItem;
            extraHeader.push(prvTime);
          }

        if(key.toLowerCase() == "odrstfnm")
        {
          let odrstfnm = DATA_SOURCE_DEFAULT_HEADERS.filter(header => header.field === "odrstfnm").map(item => {
            let header: any = { ...item };
            header.visible = true;
            return header;
          }).pop() as HeaderItem;
          extraHeader.push(odrstfnm);
        }

        if(key.toLowerCase() == "sndstfnm")
        {
          let sndstfnm = DATA_SOURCE_DEFAULT_HEADERS.filter(header => header.field === "sndstfnm").map(item => {
            let header: any = { ...item };
            header.visible = true;
            return header;
          }).pop() as HeaderItem;
          extraHeader.push(sndstfnm);
        }

        if(isDefaultHeader || isDefaultHeaderMaster) return;
        if(masterColumns.length > 0){
          var keyField = key.toLocaleLowerCase().split("_");
          if(keyField.length > 1){
            let isDefaultMasterHeader = DATA_SOURCE_DEFAULT_HEADERS_MASTER.filter(header => header.field.toLowerCase() === keyField[1]).length > 0;
            if (isDefaultMasterHeader) return;
          }
        }
        
        if(key.toLowerCase().startsWith("ttd") === false) {
          let keyArray = key.split("_");
          if( keyArray.length > 1){
            let matchColumn = masterColumns.filter((item: any) => item.columnname.toLowerCase() === (keyArray.length === 2 ? keyArray[1].toLowerCase(): keyArray.slice(1).map(key => key.toLowerCase()).join('_'))
                                  && !item.delflg && item.tablealias.toLowerCase() ===  keyArray[0].toLowerCase()).pop();
            if(matchColumn){
              headers.push({ key: key, title: matchColumn?.displayname, sortno: matchColumn?.sortno, dataType: getDataTypeNumber(matchColumn?.datatype) });
            }else{
              let matchUserDefileColumn = userDefinedColumns.filter((item: any) => item.columnname.toLowerCase() === key.toLowerCase() && !item.delflg).pop();
              if(matchUserDefileColumn){
                headers.push({ key: key, title: matchUserDefileColumn?.displayname, sortno: matchUserDefileColumn?.sortno, dataType: getDataTypeNumber(matchColumn?.datatype) });
              }
            }
           
          }
          else
          {
            let matchUserDefileColumn = userDefinedColumns.filter((item: any) => item.columnname.toLowerCase() === key.toLowerCase() && !item.delflg).pop();
            if(matchUserDefileColumn){
              headers.push({ key: key, title: matchUserDefileColumn?.displayname, sortno: matchUserDefileColumn?.sortno ,dataType: getDataTypeNumber(matchUserDefileColumn?.datatype)});
            }else {
              let matchColumn = titleColumns.filter((item: any) => item.columnname.toLowerCase() === key.toLowerCase() && !item.delflg).pop();
              if(matchColumn)
                headers.push({ key: key, title: matchColumn?.displayname, sortno: matchColumn?.sortno, dataType: getDataTypeNumber(matchColumn?.datatype) });
            }
          }
        }
      })
    

    if (headers.length) {
      headers.forEach((header: any) => {
        let headerItem = new HeaderItem();
        headerItem.field = header.key;
        headerItem.title = header.title;
        headerItem.visible = true;
        headerItem.sortno = header.sortno;
        headerItem.dataType = header.dataType ?? DataType.TEXT;

        if (isEdit) { 
          let headerData = {};
          let matchColumn = titleColumns.filter((item: any) => item.columnname.toLowerCase() === header.key.toLowerCase() && !item.delflg).pop();
          if(!matchColumn) {
            let keyArray = header.key.split("_");
            if( keyArray.length > 1){
              let matchMasterColumn = masterColumns.filter((item: any) => item.columnname.toLowerCase() === 
                                (keyArray.length === 2 ? keyArray[1].toLowerCase(): keyArray.slice(1).map((key:any) => key.toLowerCase()).join('_'))
                                    && !item.delflg && item.tablealias.toLowerCase() ===  keyArray[0].toLowerCase()).pop();
              if(matchMasterColumn) {
                headerData = matchMasterColumn;
              }else{
                headerData = matchColumn
              }
            }
          }
          else {
            headerData = matchColumn
          }
          headerItem.data = headerData; // Add property data for case Edit, to check condition for remove selected columns (function removeColum())
        }
        tableHeader.push(headerItem);
      });
    }
    temp.body = this.orderPreviewDataByOfficeCdAndObjCd(data);
    let defaultColumns = DATA_SOURCE_DEFAULT_HEADERS.filter(col => col.visible);
    let hasTitleColumns = titleColumns.filter(col => !col.delflg).length ? true: false;
    let titleHeaders = hasTitleColumns ? [...defaultColumns, ...extraHeader] : [];
    let hasMasterColumns = masterColumns.filter(col => !col.delflg).length ? true: false;
    let defaultcolumnsMaster = hasMasterColumns && !hasTitleColumns ? DATA_SOURCE_DEFAULT_HEADERS_MASTER.filter(col => col.visible) : [];
    let newHeader: HeaderItem[] = [];
    if(this.isDspAge) {
      let age = DATA_SOURCE_DEFAULT_HEADERS.filter(header => header.field === 'age').map(item => {
        let header: any = { ...item };
        header.visible = true;
        return header;
      }).pop() as HeaderItem;
      let calcAge = DATA_SOURCE_DEFAULT_HEADERS.filter(header => header.field === 'calcage').map(item => {
        let header: any = { ...item };
        header.visible = true;
        return header;
      }).pop() as HeaderItem;
      newHeader.push(age);
      newHeader.push(calcAge);
    }
    if(this.isDspLapsedDay) {
      let lapsedDayHeader = DATA_SOURCE_DEFAULT_HEADERS.filter(header => header.field === 'lapsedday').map(item => {
        let header: any = { ...item };
        header.visible = true;
        return header;
      }).pop() as HeaderItem;
      newHeader.push(lapsedDayHeader);
    }
    let headerTable = [...defaultcolumnsMaster, ...tableHeader, ...titleHeaders, ...newHeader ];
    let orderHeaders = orderBy(headerTable, ["sortno"], ["asc"])
    temp.header = orderHeaders.map(header => ({
      ...header, 
      attribute: {
        header: { 'max-width': `${100 / (headerTable.length + this.totalDaysDetails - 1)}%`},
        row: {},
        childrenCols: [],
        isMergeCells: false
      }
    }))

    // format cell value for column has data type DATETIME
    temp.body.forEach(row => {
      for (let key in row) {
        if (Object.prototype.hasOwnProperty.call(row, key)) {
          let rowValue = row[key];
          if (rowValue && !userDefinedColumns.find((col:any) => col.columnname.toLocaleLowerCase() == key)) {
            if(isMatchesExpression(rowValue).length){
              let dateConverted = moment(rowValue,isMatchesExpression(rowValue)[0]).format(DateFormat.FULL_SHORT_DATE); 
              row[key] = dateConverted;
            }
            if (typeof row[key] == 'string') {
              row[key] = row[key].replace(/\\/g, "¥");
            }
          }
          if(key.toLowerCase().includes('hs') && key.toLowerCase().includes('_numberofdays')) {
            row['numberofdays'] = row[key]
          }
          if(key.toLowerCase() == "prvhour") {
            row[key] = beautifyPrvHour(row[key]);
          }
          
        }
      }
    });
    if(this.isDisplayDate)  temp.body = orderBy(temp.body,["objcd", "dspdate"] )
    if(this.isBedDisplay)  temp.body = orderBy(temp.body,["officecd", "roomnm", "schedbedcd", "sdate", "edate"] )
    this.tableData = temp;
  }

  previewSeikuyDataTable(data: any, seikuyColumns: any[], colUserDefined: any[]) {
    let temp = cloneDeep(this.tableData);
    let headers: any[] = [];
    let tableHeader: HeaderItem[] = [];

    if (data.length) {
      Object.keys(data[0]).forEach(key => {
        if (key == "officecd") return;
        let matchColumn = seikuyColumns.filter((item: any) => item.table + "_" + item.columnname.toLowerCase() === key.toLowerCase() && !item.delflg).pop();
        if(matchColumn){
          headers.push({ key: key, title: matchColumn?.displayname, sortno: matchColumn?.sortno, dataType: matchColumn?.datatype });
        }else{
          let matchColumnDefined = colUserDefined.filter((item: any) => item.columnname.toLocaleLowerCase() === key.toLowerCase() && !item.delflg).pop();
          headers.push({ key: key, title: matchColumnDefined?.displayname, sortno: matchColumnDefined?.sortno, dataType: matchColumnDefined?.datatype });
        }
        
      })
      if (headers.length) {
        headers.forEach((header: any) => {
          let headerItem = new HeaderItem();
          headerItem.field = header.key;
          headerItem.title = header.title;
          headerItem.visible = true;
          headerItem.sortno = header.sortno;
          headerItem.dataType = header.dataType == "FLOAT" ? DataType.NUMBER: header.dataType;
          tableHeader.push(headerItem);
        });
      }
      let newArray: any[] = [];
      newArray = data.map((row: any) => {
        let office = this.allOffices.filter((o: any) => o.officeCd === row.officecd);
        return {
          ...row,
          officenm: office.length > 0 ? office[0].officeNm : ''
        }
      });
      temp.body = newArray;
      let orderHeaders = orderBy(tableHeader, ["sortno"], ["asc"])
      temp.header = orderHeaders;
      // format cell value for column has data type DATETIME
      temp.body.forEach(row => {
        for (let key in row) {
          if (Object.prototype.hasOwnProperty.call(row, key)) {
            let rowValue = row[key];
  
            if (rowValue && !colUserDefined.find((col:any) => col.columnname.toLocaleLowerCase() == key)) {
              if(isMatchesExpression(rowValue).length){
                let dateConverted = moment(rowValue,isMatchesExpression(rowValue)[0]).format(DateFormat.FULL_SHORT_DATE); 
                row[key] = dateConverted;
              }
            }
          }
        }
      });
    }

    

    this.tableData = temp;
  }

  orderPreviewDataByOfficeCdAndObjCd(data: any[]) {
    return orderBy(data, ["officecd", "ttlkbn", "ttlcd", "objcd", "sdate", "edate"])
  }

  getLisOperatorSaucerLog(lstMap:any) {
    let columns = cloneDeep(orderBy(lstMap,["sortno"]))
    let selectedColsSaucerLog = columns.filter(item => !item.data?.delflg && item.data?.displayname !='利用日数') || [];
    for (let i = 0; i < selectedColsSaucerLog.length; i++) {
      let item = selectedColsSaucerLog[i];
      if (item?.operator) {
        item.operatorSaucerLog = item.operator;
        for (let j = 0; j < this.selectedColumns.length; j++) {
          let itemFindName = this.selectedColumns[j];
          if (item?.operatorSaucerLog?.includes(itemFindName.columnId)) {
            item.operatorSaucerLog = item?.operatorSaucerLog.replace(itemFindName.columnId, itemFindName.name);
          }
        }
      }
    }
    selectedColsSaucerLog = selectedColsSaucerLog.map((x:any)=>({columnId: x.columnId, name: x.name, value: x.value, operator :x.operatorSaucerLog}))
    return selectedColsSaucerLog;
  }

  clearAllColumns() {
    let selectedColsSaucerLog = this.getLisOperatorSaucerLog(this.selectedColumns);
    let oldUserDefinedLog = selectedColsSaucerLog.filter((column) => column.operator);
    if (oldUserDefinedLog.length > 0) {
      this.saucerLogService.action(
        {
          content: JSON.stringify({
            old: oldUserDefinedLog,
            new: null
          }),
        }, 
        {
          action: this.datasourceSettingLogAction.DELETE_USER_DEFINED_COLUMN
        }
      );
    }
    //Reset period options
    this.isUserChangedAction = false;
    this.groupedPeriod = this._groupedPeriod;
    this.dsType = DataSourceType.TITLE;
    this.selectedPeriod = '003-002'; //Defautl is this month 今月
    this.groupedPeriod.forEach((group: any) => {
      let selectedItem = group.items.filter((item:any) => item.value === this.selectedPeriod).pop();
      if(selectedItem){
        if(this.dsType === DataSourceType.SEIKUY){
          this.selectedRangeDate = {startDate: selectedItem.startdateseikuy, endDate: selectedItem.enddateseikuy}
         }else{
          this.selectedRangeDate = {startDate: selectedItem.startdate, endDate: selectedItem.enddate}
         }
      }
    });
    this.groupedPeriod.map((group:any) => group.items.map((item:any) => {
      if(this.dsType === DataSourceType.SEIKUY){
        item.label = item.contentseikuy ??  item.label
      }else{
        item.label = item.content ??  item.label
      }
    }));
     
    let selectedColumns = this.selectedColumns;
    // map all columns with delflg = true and filter not get item that have delflg = true and id =""
    selectedColumns = this.selectedColumns.map((col: any) => {
      col.data.delflg = true;
      return ({ ...col })
    }).filter(item => !(item.data?.id == "" && item.data?.delflg));
    this.selectedColumns = cloneDeep(selectedColumns);
    this.isShowAggregationType = this.selectedColumns.filter(item => !item.data?.delflg && item.data.table && item.data?.table == "HISIO").length > 0 ? true: false;
    this.includingstartdate =  true;
    this.dischargedate = true;
    this.columnListParams.items = selectedColumns.filter(item => !item.data?.delflg);;
    this.columnListParams = this.columnListParams;
    let temp = cloneDeep(this.tableData);
    temp.body = [];
    temp.header = [];
    this.tableData = temp;
    this.isOver100Records = false;
    this.isDspAge = false;
    this.calcAge =  new Date();
    this.isDspBlockUnit = false;
    this.isDspLapsedDay = false;
    this.lapsedDay = new Date();
    this.checkboxInf.count = 0;
  }

  async onConfirmSaveData(data: any, isNavigate: boolean = true): Promise<boolean> {
    switch (data) {
      case this.saveType.SAVE:
        this.checkValidateDatasourceName();
        if (Utils.isNullOrEmpty(this.nameDataSource)) {
          return false;
        }
        this.isConfirmSaveData = true;
        this.isNavigate = isNavigate;
        this.usedWidgets.length ? this.isShowWidget = true : await this.previewOrSaveDataSource(false, this.isConfirmSaveData);
        break;
      case this.saveType.NO:
        if (isNavigate) {
          this.saucerLogService.action(
            {
              content: CONTENT_LOG.NOT_SAVE_UNSAVED_DATA
            }, 
            {
              action: this.datasourceSettingLogAction.NOT_SAVE_UNSAVED_DATA
            }
          );
          if (this.isDataSourceTemplate) {
            this.navigateTo([ROUTE_PATH.DATASOURCE_TEMPLATE]);
          } else {
            this.navigateTo([ROUTE_PATH.DATASOURCE]);
          }
        }
        break;
      case this.saveType.CANCEL:
        this.saucerLogService.action(
          {
            content: CONTENT_LOG.CLOSE_DIALOG + ": " + SCREEN_NAME.UNSAVED_CONFIRM_DIALOG
          },
          {
            action: this.datasourceSettingLogAction.RETURN_ORIGINAL_SCREEN
          }
        );
        break;
    }
    return true;

  }

  backPage() {
    this.saucerLogService.action({}, { action: this.datasourceSettingLogAction.RETURN_PREVIOUS_SCREEN });
    
    if (this.mode === ScreenMode.PREVIEW) {
      if (this.isDataSourceTemplate) {
        this.navigateTo([ROUTE_PATH.DATASOURCE_TEMPLATE]);
      } else {
        this.navigateTo([ROUTE_PATH.DATASOURCE]);
      }
      return;
    }
    
    const refBackPage = this.modalService.open(ConfirmUnsavedDataDialogComponent, {
      header: COMMON_TEXT.CONFIRM_NOT_SAVE_DATA,
      width: '35%'
    });

    refBackPage.onClose.subscribe((x) => {
      this.onConfirmSaveData(x);
    });
  }

  checkValidateDatasourceName() {
    if (Utils.isNullOrEmpty(this.nameDataSource)) {
      this.nameParams.validate = true;
      this.nameParams.isValidate = true;
    }
    else {
      this.nameParams.validate = false;
      this.nameParams.isValidate = false;
    }
    this.nameParams = cloneDeep(this.nameParams);
  }

  canDeactivate(url: string | undefined): boolean | Observable<boolean> | Promise<boolean> {
    if (this.useCanDeactivate && !this.backActionFromBrowser) {
      if(this.isByPassConfirmDialog) {
        this.errorHandleService.isByPassConfirmDialogSub.next(false);
        return true;
      }
      if (this.mode != ScreenMode.PREVIEW) {
        const ref = this.modalService.open(ConfirmUnsavedDataDialogComponent, {
          header: COMMON_TEXT.CONFIRM_NOT_SAVE_DATA,
          width: '35%'
        });

        return firstValueFrom(ref.onClose.pipe(mergeMap(async x => {
          if (!x || x == SaveType.CANCEL) return false;
          
          return await this.onConfirmSaveData(x, false);
        })))
      }
      else {
        return true;
      }
    } else {
      this.useCanDeactivate = true;
      this.backActionFromBrowser = false;
      return true;
    }
  }

  navigateTo(commands: any[] = []) {
    this.useCanDeactivate = false;
    this.router.navigate(commands);
  }

  setModeInputName() {
    if (this.mode == ScreenMode.PREVIEW) {
      this.nameParams.pencil = false;
      this.nameParams.readonly = true;
      this.nameParams = cloneDeep(this.nameParams);
    }
    else {
      this.nameParams.pencil = true;
      this.nameParams.readonly = false;
      this.nameParams = cloneDeep(this.nameParams);
    }
  }

  openRangeDateModal( group: any) {
    if(group.value === "-1")  {
      this.isDisplayRangeDateModal = true;
    }
    else {
     if(this.dsType === DataSourceType.SEIKUY){
      this.selectedRangeDate = {startDate: group.startdateseikuy, endDate: group.enddateseikuy}
     }else{
      this.selectedRangeDate = {startDate: group.startdate, endDate: group.enddate}
     }
      this.isDisplayRangeDateModal = false;
    } 
    //Reset table if changing period option
    this.isOver100Records = false;
    let table = cloneDeep(this.tableData);
    table.body = [];
    table.header = (table.header) ? table.header.filter(h =>
      !(h.field.toUpperCase() === "TOTALDAYS" || 
      h.field.toUpperCase() === "ROOMATTR" || 
      h.field.toUpperCase() === "RESULTATR")) : [];
    this.tableData = table;
  }

  closeRangeDateModal() {
    this.isDisplayRangeDateModal = false;
  }

  setRangeDate(event: any){
    this.selectedRangeDate = event;
    this.isDisplayRangeDateModal = false;
  }

  formatRangeDate(rangeDate: any){
    if(Object.keys(rangeDate).length && rangeDate.startDate && rangeDate.endDate ) {
      if(moment(rangeDate.startDate).format("L") == moment(rangeDate.endDate).format("L"))
        return moment(rangeDate.startDate).format(DateFormat.FULL_SHORT_DATE);
      return `${moment(rangeDate.startDate).format(DateFormat.FULL_SHORT_DATE)} ~ ${moment(rangeDate.endDate).format(DateFormat.FULL_SHORT_DATE)}`;
    }
    return  "";
  }


  async changeTabTreeView(treeViewType: TreeViewTab, isInitData?: boolean, isMixSchedule?: boolean ) {
    if(!this.isInitEvent)
      this.errorHandleService.setFunctionTitle(FUNCTION_TITLE_TEXT.SWITCH_TABS_FAIL);
    if(this.isLoadingTree) return;
    this.errorHandleService.setFunctionTitle(FUNCTION_TITLE_TEXT.SWITCH_TABS_FAIL);
    if(!this.isInitEvent)
      this.errorHandleService.backURLSub.next("");
    this.isLoadingTree = true;
    this.treeViewList = [];
    this.selectedTabTree = treeViewType;
    switch(treeViewType){
      case TreeViewTab.MASTER:
        this.isShowAggregationType = this.selectedColumns.filter(item => !item.data?.delflg && item.data.table && item.data?.table == "HISIO").length > 0 ? true: false;
        let masterTreeData = this.generateMasterDataTree();
        this.treeViewNode = [masterTreeData];
        this.treeViewList = this.updateLazyTree(this.treeViewNode)
        if(isMixSchedule) {
          await this.loadTitleMapping([4], [0], [TreeViewTab.SCHEDULE, treeViewType]);
        }
        else {
          this.loadingService.isLoading.emit(false);
        }
        this.isLoadingTree = false;
        break;
      case TreeViewTab.RECORD: //Default NOTE.NOTEKBN='0' and NOTE.DOCKBN='0' and NOTE.OFFICECD='選択された事業所' (00)
        this.isShowAggregationType = false;
        if(this.titleMappingLoadList.filter(x=> x == treeViewType.toString()).length == 0) {
          await this.loadTitleMapping(isMixSchedule ? [0,4] : [0], [0], isMixSchedule ? [TreeViewTab.SCHEDULE, treeViewType]: [treeViewType] );
        }
        this.searchTitleMapping(0, 0);        
        break;
      case TreeViewTab.REPORT: // 　NOTE.NOTEKBN='0' and NOTE.DOCKBN='1' and NOTE.OFFICECD='選択された事業所' (00)
        this.isShowAggregationType = false;
        if(this.titleMappingLoadList.filter(x=> x == treeViewType.toString()).length == 0) {
          await this.loadTitleMapping(isMixSchedule ? [0,4] : [0],  isMixSchedule ? [0,1] : [1] , isMixSchedule ? [TreeViewTab.SCHEDULE, treeViewType]: [treeViewType]);
        }
        this.searchTitleMapping(0, 1);
        break;
      case TreeViewTab.SCHEDULE: // 　NOTE.NOTEKBN='4' and NOTE.DOCKBN='0' and NOTE.OFFICECD='選択された事業所' (00)
        this.isShowAggregationType = true;
        if(this.titleMappingLoadList.filter(x=> x == treeViewType.toString()).length == 0) {
          await this.loadTitleMapping([4], [0], [treeViewType]);
        }
        this.searchTitleMapping(4, 0);
        break;
      case TreeViewTab.SEIKUY: 
        this.isShowAggregationType = false;
        let seikuyTreeData = this.generateSeikuyDataTree();
        this.treeViewNode = [seikuyTreeData];
        this.treeViewList = this.updateLazyTree(this.treeViewNode)
        this.isLoadingTree = false;
        this.loadingService.isLoading.emit(false);
        break;
      default: 
        this.loadingService.isLoading.emit(false);
        await this.loadTitleMapping([4], [0], [treeViewType]);
        this.searchTitleMapping(4, 0);
        break;
    }
    if(isInitData !== true){
      this.filterPeriodOption();
    }
    this.setTreeHeight();
  }

  filterPeriodOption() {
    if(this.dsType == DataSourceType.SEIKUY) {
      this.groupedPeriod = this._groupedPeriod.filter((x: any) => x.value != "-1" && x.value != MstFilterDataTimeItem.ThisYear && x.value != MstFilterDataTimeItem.Today && x.value != MstFilterDataTimeItem.ThisWeek);
    }
    else {
      switch(this.selectedTabTree) {
        case TreeViewTab.SEIKUY:
          this.groupedPeriod = this._groupedPeriod.filter((x: any) => x.value != "-1" && x.value != MstFilterDataTimeItem.ThisYear && x.value != MstFilterDataTimeItem.Today && x.value != MstFilterDataTimeItem.ThisWeek);
          break;
        default:
          this.groupedPeriod = this._groupedPeriod;
          break;
      }
    }
  }

  searchTitleMapping(notekbn: number, dockbn: number){
    let dataWorker = {
      typeWorker: "dataSource",
      rawTitleMapping: this.rawTitleMapping,
      notekbn: notekbn,
      dockbn: dockbn,
      officeList: this.officeList,
      searchText: this.searchText
    }
    let localTitles = {};
    let localTags = {};

    if (typeof Worker !== 'undefined') {
      // Create a new
      const worker = new Worker(new URL('../../workers/data-source.worker.ts', import.meta.url),
        { name: 'data_source_worker', type: 'module' })
      worker.onmessage = ({ data }) => {
        if(data) {
          localTitles = data.localTitles;
          localTags = data.localTags;
          let titleTreeData: TreeNode = {};
          if (this.officeList.length === 1) {
            titleTreeData = this.generateTitleTree(localTitles);
          }
          let tagTreeData = this.generateTagTree(localTags);
          if (this.officeList.length === 1) {
            this.treeViewNode = [titleTreeData,tagTreeData]
            this.treeViewList = this.updateLazyTree(this.treeViewNode);

          }
          else {
            this.treeViewNode = [tagTreeData]
            this.treeViewList = this.updateLazyTree(this.treeViewNode);
          }
        }
        worker.terminate()
        this.isLoadingTree = false;
      };
      worker.postMessage(dataWorker);
    } else {

      this.isLoadingTree = false;
      // Web workers are not supported in this environment.
      // You should add a fallback so that your program still executes correctly.
    }
  }

  changeTitles(titles: any) {
    const tmpTitles = this.setTitleUse(titles);
    this.itag?.forEach(ttl => ttl.titleuse = tmpTitles);
    this.selectedColumns.forEach(s => {
      const findItag = this.itag.findIndex(t => t.id === s.data.id && t.displayname === s.data.displayname);
      if (findItag !== -1) {
        s.data.titleuse = tmpTitles;
        s.hilightColor = tmpTitles ? '#C8424C' : '';
      }
    });
    this.setListParams();
    this.isShowTitle = false;
  }

  setListParams() {
    if(this.dsType !== DataSourceType.SEIKUY) {
      this.dataTypeOptions = DataTypeOptions.map(x => { 
        x.visible = x.value == "FLOAT" ? false : true;
        return x;
      });
    }
    else {
      this.dataTypeOptions = DataTypeOptions.map(x => { 
        x.visible = x.value == "INT" ? false : true;
        return x;
      });
    }
    let columns = cloneDeep( orderBy(this.selectedColumns,["sortno"] ))
    this.columnListParams.items = columns.filter(item => !item.data?.delflg && item.data?.displayname !='利用日数');
    this.columnListParams.options = this.dataTypeOptions;
    this.columnListParams = cloneDeep(this.columnListParams);
  }

  setTitleUse(titles: any[]) {
    return titles.reduce((acc, ttl) => {
      const titleUseStr = `${ttl.ttlkbn}-${ttl.ttlcd}-${ttl.officecd}`;
      return acc.includes(titleUseStr) ? acc : [...acc, titleUseStr];
    }, []).join(',');
  }

  onClickColumnSelected(itag: any) {
    let item = itag?.value[0]?.data;
    
    if(item.columntype == DataSourceColumnType.TAG){
      this.titles = [];
      this.itag = [];
      this.officeCds = [];
      this.itag.push(item);
      this.officeCds = this.officeList.map(item => item.officeCd);
      this.isShowTitle = true;
    }else if(item.columntype == DataSourceColumnType.USER_DEFINED) {
      this.OpenUserDefinedColumnDialog(itag.value[0]);
    }
  }
  OpenUserDefinedColumnDialog(colSelected:any){
    this.userDefinedColumns = this.selectedColumns.filter((column) => {
      return  column.data? (column.data?.columntype == DataSourceColumnType.USER_DEFINED && !column.data?.delflg) : false;
    })
    this.selectedDsColumns =[...this.selectedColumns];
    let defaultColumn:any[] = [];
    let defaultMasterColumn:any[] = [];
    let extraColumn:any[] = [];
    if(this.dsType == DataSourceType.TITLE && this.selectedColumns && this.selectedColumns.length >0){
       [...DATA_SOURCE_DEFAULT_HEADERS].filter((col:any) => col.visible).map((col: any)=>{
       let dataCol = col;
        dataCol.displayname = col.title;
        defaultColumn.push({value: `COL${col.sortno}`, name: col.title, defaultValue: col.dataType, data: dataCol, columnId: col.field.toUpperCase()});
      });
    }else if(this.dsType == DataSourceType.MASTER && this.selectedColumns && this.selectedColumns.length >0){
      [...DATA_SOURCE_DEFAULT_HEADERS_MASTER].filter((col:any) => col.visible).map((col: any)=>{
        let dataCol = col;
         dataCol.displayname = col.title;
         defaultMasterColumn.push({value: `COL${col.sortno}`, name: col.title, defaultValue: col.dataType, data: dataCol, columnId: col.field.toUpperCase()});
       });
       
    }

    let hasNumberOfDaysColumn = (this.selectedColumns.filter(col => col.data?.columnname?.includes("NUMBEROFDAYS") && !col.data?.delflg).length > 0)
    let isShowOwsStfExtraCol = this.mode == ScreenMode.EDIT && !this.isShowOwnStfnmCol;

    getExtraHeader(this.tableData.body, this.isDspAge, this.isDspLapsedDay, !hasNumberOfDaysColumn, isShowOwsStfExtraCol).sort((a:any, b:any) => {return (a.sortno - b.sortno)}) .map((col:any)=>{
      let dataCol = col;
      dataCol.displayname = col.title;
      extraColumn.push({value: `COL${col.sortno}`, name: col.title, defaultValue: col.dataType, data: dataCol, columnId: col.field.toUpperCase()});
    });
    
    this.selectedDsColumns = [...defaultColumn,...defaultMasterColumn, ...this.selectedColumns,...extraColumn];
    this.selectedDsColumns = this.selectedDsColumns.sort((a:any, b:any) => {return (a?.data?.sortno - b?.data?.sortno)});
    
    if(colSelected){
      this.selectedDefinedColumns = colSelected;
    }else{
      this.selectedDefinedColumns = null;
    }
    this.isShowUserDefined = true;
  }
  closeTitleModal() {
    this.isShowTitle = false;
  }

  closeWarning() {
    this.isDisplayWarning = false;
  }

  clearDataSourceData() {
    let temp = cloneDeep(this.tableData);
    temp.body = [];
    this.tableData = temp;
    this.isOver100Records = false;
    this.loadingService.isLoading.emit(false)
  }
  changeOptionGetLastestData() {
    this.isOnlyLastestData = !this.isOnlyLastestData;
  }

  changeOptionGetAge() {
    this.isDspAge = !this.isDspAge;
  }

  changeOptionGetLapsedDay() {
    this.isDspLapsedDay = !this.isDspLapsedDay;
  }
  
  changeOptionDspBlockUnit() {
    this.isDspBlockUnit = !this.isDspBlockUnit;
  }

  isDisabledSyncAuto() {
    if(this.treeViewTab.MASTER == this.selectedTabTree 
      || this.treeViewTab.SEIKUY == this.selectedTabTree 
      || this.dsType == this.DSTYPE.MASTER 
      ||  this.dsType == this.DSTYPE.SEIKUY) 
      return false;
    return true;
  }
  chooseAggrerationType(type: number){
    this.isClickedAggregationTypeOnce = true;
    this.isUserChangedAction = true;
    switch(type) {
      case this.aggregationType.INCLUDINGSTARTDATE:
        this.includingstartdate = !this.includingstartdate;
        break;
      case  this.aggregationType.DISCHARGEDATE:
        this.dischargedate = !this.dischargedate;
        break;
    }
  }

  isDisabledCheckBoxByType(type: string): boolean {
    let columns: any[] = []
    this.selectedColumns.forEach(item => {
      columns.push({ ...item.data })
    })
    var activeColumns = columns.filter(item => !item.delflg);
    let uniqueColumnType = uniqBy(activeColumns, "columntype").map(obj => obj.columntype).filter(columnType => columnType != DataSourceColumnType.USER_DEFINED);
    let uniqueTabType =  uniqBy(activeColumns, "tabtype").map(obj => obj.tabtype);
    if(type == 'dspdate' || type == 'isBedDisplay') {
      let ischeck = activeColumns.filter(s=>s.tabtype != TreeViewTab.SCHEDULE && s.tabtype != TreeViewTab.MASTER && s.columntype != DataSourceColumnType.USER_DEFINED)?.length > 0 ? true : false;
      if(ischeck) {
        this.isDisplayDate = false;
        this.isBedDisplay = false
        return true;
      }
      else {
        if(activeColumns.filter(s=>s.tabtype == TreeViewTab.SCHEDULE)?.length == 0) {
          this.isDisplayDate = false;
          this.isBedDisplay = false
          return true;
        }
        else {
          let allowUsingDisplayDateFunctionTable: string[] = ["HISNIN", "DAI", "HISGEN", "JHISJK"];
          if(this.selectedColumns.filter(item => !item.data?.delflg && item.data.table 
            && !allowUsingDisplayDateFunctionTable.includes(item.data?.table))?.length > 0) {
            this.isDisplayDate = false;
            this.isBedDisplay = false
            return true;
          }
          return false;
        }    
      }
    }
    if(type == 'isDspAge') {
      let isEnable = activeColumns.filter(s=>s.tabtype == TreeViewTab.MASTER && s.table == 'DAI' && s.columnname == 'BIRTH')?.length > 0 ? true : false;
      if(!isEnable) this.isDspAge = false;
      return !isEnable;
    }
    if(type== 'dspBlockUnit') {
      let ischeck: boolean = false;
      ischeck = activeColumns.filter(s=>s.tabtype != TreeViewTab.SEIKUY && s.tabtype != TreeViewTab.MASTER)?.length > 0 ? false : true;
      if(ischeck) {
        this.isDspBlockUnit = false;
        return true;
      }
      else {
        ischeck = activeColumns.filter(s=>s.tabtype != TreeViewTab.SEIKUY && s.tabtype != TreeViewTab.MASTER)?.length > 0 ? true : false;
        return ischeck? false: true;
      }
    }
    if(type == 'isDspLapsedDay') {
      let columnHisio = activeColumns.filter(s=>s.tabtype == TreeViewTab.MASTER && s.table == 'HISIO' && s.tablealias == 'HS0') || [];
      let isEnable = (columnHisio?.length > 0 && columnHisio?.filter(s => s.columnname =='SDATE')?.length > 0 && columnHisio?.filter(s => s.columnname =='EDATE')?.length > 0) ? true: false;
      if(!isEnable) this.isDspLapsedDay = false;
      return !isEnable;
    }

    if(type == 'aggrerationtype') {
      let colYotei = activeColumns.filter(s=> s.tabtype == TreeViewTab.SCHEDULE) || [];
      let isEnable = colYotei.length > 0 ? true : false;
      if(isEnable) {
        this.checkAggrerationtype();
        return !isEnable;
      }
    }

    switch(true){
      case uniqueColumnType.length == 0:
        this.includingstartdate = false;
        this.dischargedate = false;
        this.isOnlyLastestData = false;
        return true;
      case uniqueColumnType.every(x=>x === DataSourceColumnType.MASTER): 
        this.checkAggrerationtype();
        let isShowAggregationType = this.selectedColumns.filter(item => !item.data?.delflg && item.data.table && (item.data?.table == "HISIO" || item.data?.table == "JHISIO")).length > 0 ? true: false;
        if(!isShowAggregationType) {
          this.includingstartdate = false;
          this.dischargedate = false;
        }
        else {
          this.includingstartdate ? this.includingstartdate = this.includingstartdate : false; //reset checkbox
          this.dischargedate ? this.dischargedate = this.dischargedate : false; //reset checkbox
        }
        this.isOnlyLastestData = false;
        return type == "lastestrecord" ? true : !isShowAggregationType;
      case uniqueColumnType.every(x=>x === DataSourceColumnType.SEIKUY):
        this.isOnlyLastestData = false;
        this.includingstartdate = false; //reset checkbox
        this.dischargedate = false; //reset checkbox
        return true;
      case uniqueColumnType.every(x=>x === DataSourceColumnType.DEFAULT || x === DataSourceColumnType.NORMAL || x === DataSourceColumnType.TAG):
        let isOnAggregationType = uniqueTabType.some(v => v == TreeViewTab.SCHEDULE);
        this.checkAggrerationtype();
        if(!isOnAggregationType) {
          this.includingstartdate = false;
          this.dischargedate = false;
        }
        else {
          this.includingstartdate ? this.includingstartdate = this.includingstartdate : false; //reset checkbox
          this.dischargedate ? this.dischargedate = this.dischargedate : false; //reset checkbox
        }
        return type == "lastestrecord" ? false : !isOnAggregationType;
      default:
        return false;
    }
  }
  checkAggrerationtype() {
    if(this.mode === ScreenMode.ADD && !this.isUserChangedAction){
      this.includingstartdate = true;
      this.dischargedate = true;
    }
    else if(this.mode === ScreenMode.EDIT && !this.isUserChangedAction) {
      this.includingstartdate = true;
      this.dischargedate = true;
    }
  }

  changeOptionDisplayDate(){
    this.isDisplayDate = !this.isDisplayDate;
  }

  changeOptionBedDislay(){
    this.isBedDisplay = !this.isBedDisplay;
  }

  showWidgetList() {
    this.isNavigate = true;
    if(this.mode === ScreenMode.EDIT) {
      this.usedWidgets.length ? this.isShowWidget = true : this.previewOrSaveDataSource(false);
    }
    else this.previewOrSaveDataSource(false);
  }

  saveDataSource(selectedWidgets: any) {
    this.saucerLogService.action(
      {
        content: JSON.stringify(selectedWidgets)
      },
      {
        action: this.isDataSourceTemplate ? SAUCER_LOG_ACTION.EDIT_DATA_SOURCE_TEMPLATE.SELECT_WIDGET : SAUCER_LOG_ACTION.EDIT_DATA_SOURCE.SELECT_WIDGET
      }
    );
    this.selectedWidgets = selectedWidgets;
    this.previewOrSaveDataSource(false, this.isConfirmSaveData);
    this.isConfirmSaveData = false;
  }

  closeWidgetModal() {
    this.isShowWidget = false;
  }

  onOpenDefinedColumnDialog(){
    this.OpenUserDefinedColumnDialog(null);

  }
  closeDefinedColumnDialog() {
    this.isShowUserDefined = false;
  }
  onRemoveColumn(itemRemove:any) {
    let opr = [], oprId = "", isClearInput = false;
    let lstChkColumns = this.selectedColumns.filter(item => item.operator !== undefined && item.operator !== null);
    if (lstChkColumns.length > 0) {
      lstChkColumns.forEach((item: any) => {
        opr = getOperatorFromString(item.operator);
        if (opr.length > 0) {
          opr.forEach((oprItem: any) => {
            oprId = oprItem.value.replace(/{|}/g, '');
            if (oprId == itemRemove.columnId) {
              isClearInput = true;
            }
          });
        }
        if (isClearInput) {
          item.operator = '';// clear operator
        }
      });
      this.userDefinedColumns = lstChkColumns;
    }
  }
  addIntervalIdToList(id: any) {
    if(this.intervalIdList.includes(id)) return;
    this.intervalIdList.push(id);
    this.clearProcessService.setIntervalId(id);
  }

  removeIntervalIdToList(id: any) {
    this.intervalIdList = this.intervalIdList.filter(e => e != id);
    this.clearProcessService.removeIntervalIdList(id);
    this.firstTimeCalling = true;
  }

  checkIfStopInterval(screenCode: any, intervalId: any): boolean {
    let result: boolean = false;
    if(this.firstTimeCalling) {
      this.firstTimeCalling = false;
      this.clearProcessService.setScreenCode(screenCode);
    }
    const holderCode = this.clearProcessService.getScreenCode();
    if(holderCode != screenCode) {
      clearInterval(intervalId);
      this.removeIntervalIdToList(intervalId);
      result = true;
    }

    return result;
  }
}