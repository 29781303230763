import { HttpClient, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { lastValueFrom, Observable } from 'rxjs';
import { map, timeout } from 'rxjs/operators';
import { Router } from '@angular/router';
import { ResponseData } from '../models/api-model';
import {ErrorHandleService} from './error-handle.service';
import {HTTP_STATUS} from '../config/app.config';

@Injectable({ providedIn: 'root' })
export class ApiService {
    protected _http: HttpClient;
    private _router: Router;
    private _errorHandle: ErrorHandleService

    constructor(
        protected http: HttpClient,
        private router: Router,
        protected errorHandle: ErrorHandleService
    ) {
        this._http = http;
        this._router = router;
        this._errorHandle = errorHandle;
    }

    /**
    * Funtion get: Get data by parameter
    * @param apiUrl
    * @param parameter
    */
    protected async get(apiUrl: string = '', parameter: any[] = [],  customHeaders?: HttpHeaders): Promise<any> {
        try {
            parameter.forEach(p => {
                apiUrl += ('/' + p);
            });
            const headers = customHeaders || new HttpHeaders().set('content-Type', 'application/json');
            return await lastValueFrom(
                this._http.get<any>(apiUrl, {
                    headers: headers,
                    observe: 'response'
                }).pipe(
                    // timeout(60000)      // <-- HTTP request will error out if no response for 60 seconds
                    map((response: HttpResponse<any>) => {
                        const statusCode = response.status;
                        const responseBody = response.body;

                        if(response.ok && response.ok !== true) {
                            this._errorHandle.errorRouting(statusCode, response);
                        }
                        return responseBody;
                    })
                ),
                {
                    defaultValue: this.defaultValue()
                }  // <-- default value to be used
            )
        } catch (error: any) {
            if( error.ok == false ) {
                this._errorHandle.errorRouting(error.status, error);
            }
            return this.defaultValue();
        }
    }
    protected async getS3(apiUrl: string = ''): Promise<any> {
      try {
        
          const headers = new HttpHeaders().set('content-Type', 'application/json');
          return await lastValueFrom(
              this._http.get<any>(apiUrl, {
                headers: headers
              }).pipe(
                  // timeout(60000)      // <-- HTTP request will error out if no response for 60 seconds
              ),
              {
                  defaultValue: this.defaultValue()
              }  // <-- default value to be used
          )
      } catch (error) {
          return this.defaultValue();
      }
  }
    /**
    * Funtion post: post data
    * @param apiUrl
    * @param parameter
    * @param bodyData
    */
    protected async post(apiUrl: string = '', parameter: any[] = [], bodyData: any): Promise<any> {
        try {
            parameter.forEach(p => {
                apiUrl += ('/' + p);
            });
            const headers = new HttpHeaders().set('content-Type', 'application/json');
            return await lastValueFrom(
                this._http.post<any>(apiUrl, bodyData, {
                    headers: headers,
                    observe: 'response'
                }
                ).pipe(
                    // timeout(60000)      // <-- HTTP request will error out if no response for 60 seconds
                    map((response: HttpResponse<any>) => {
                        const statusCode = response.status;
                        const responseBody = response.body;
                        
                        if(response.ok && response.ok !== true) {
                            this._errorHandle.errorRouting(statusCode, response);
                        }
                        
                        return responseBody;
                    })
                ),
                {
                    defaultValue: this.defaultValue()
                }  // <-- default value to be used
            )
        } catch (error: any) {
            if( error.ok == false ) {
                this._errorHandle.errorRouting(error.status, error);
            }
            return this.defaultValue();
        }

    }

    /**
    * Funtion delete: delete data by parameter
    * @param apiUrl
    * @param parameter
    */
    protected async httpdelete(apiUrl: string = '', parameter: any[] = []): Promise<any> {
        try {
            parameter.forEach(p => {
                apiUrl += ('/' + p);
            });
            const headers = new HttpHeaders().set('content-Type', 'application/json');
            return await lastValueFrom(
                this._http.delete<any>(apiUrl, {
                    headers: headers
                }).pipe(
                    // timeout(60000)      // <-- HTTP request will error out if no response for 60 seconds
                ),
                {
                    defaultValue: this.defaultValue()
                }  // <-- default value to be used
            )
        } catch (error) {
            return this.defaultValue();
        }
    }

    protected async getByParameter(apiUrl: string = '', parameter: any = {},  customHeaders?: HttpHeaders): Promise<any> {
        try {
            let params = new HttpParams();
            for (const key in parameter) {
                if (parameter.hasOwnProperty(key)) {
                    params = params.set(key, parameter[key]);
                }
            }

            const headers = customHeaders || new HttpHeaders().set('content-Type', 'application/json');
            return await lastValueFrom(
                this._http.get<any>(apiUrl, {
                    headers: headers,
                    observe: 'response',
                    params: params
                }).pipe(
                    // timeout(60000)      // <-- HTTP request will error out if no response for 60 seconds
                    map((response: HttpResponse<any>) => {
                        const statusCode = response.status;
                        const responseBody = response.body;

                        if(response.ok && response.ok !== true) {
                            this._errorHandle.errorRouting(statusCode, response);
                        }
                        return responseBody;
                    })
                ),
                {
                    defaultValue: this.defaultValue()
                }  // <-- default value to be used
            )
        } catch (error: any) {
            if( error.ok == false ) {
                this._errorHandle.errorRouting(error.status, error);
            }
            return this.defaultValue();
        }
    }

    postObservable(apiUrl: string = '', parameter: any[] = [], data: any): Observable<any> {
        parameter.forEach(p => {
            apiUrl += ('/' + p);
        });
        const headers = new HttpHeaders().set('content-Type', 'application/json');
        return this._http.post<any>(apiUrl, data, {
            headers: headers
        });
    }

    protected defaultValue() {
        let res = new ResponseData<any>();
        res.statuscode = 500;
        res.statusmessage = '500 Internal Server Error';
        res.data = null;
        return res;
    }
}
