import { ActivityLog, BaseModel } from "../api-model";
import { Staff } from "./staff.ro";

export class Role extends ActivityLog {
    id?: string;
    roleCd?: string;
    name: string;
    permissions: Array<string>; // MenuList
    description?: string;
    delflg?: boolean;
    isDisable?: boolean;
    style?: any;
    showEdit?: boolean = false ;
}

export class RoleList extends ActivityLog {
    id?: string;
    roleCd?: string;
    name: string;
    description?: string;
}

export class RoleModel extends BaseModel{
    rolecd: string;
    rolename?: string;
    menulst?: string;  
}

export class RoleDetail extends ActivityLog {
    id?: string;
    roleCd: string;
    staffCd: string;
    delflg?: boolean;
}