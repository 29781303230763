import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { cloneDeep, isEqual, orderBy } from 'lodash';
import { COMMON_TEXT } from '../../../../app/const/text-common';
import { SearchType } from '../../../../app/enum/common-enum';
import { FilterItem, SearchParams } from '../../../../app/models/common-model';
import Utils from '../../../../app/util/utils';

@Component({
  selector: 'pivot-filter-modal',
  templateUrl: './filter-modal.component.html',
  styleUrls: ['./filter-modal.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class FilterModalComponent implements OnInit {
  COMMON_TEXT = COMMON_TEXT;
  checkedAll: boolean = false;
  hasCheckedItems: boolean = false;
  listChildItem: any[] = [];
  lstItemRoot: any[] = [];
  searchParams: SearchParams = {
    type: SearchType.input,
    placeholder: COMMON_TEXT.ITEM_SEARCH,
    cssStyle: { width: '100%', height: '40px' },
    readonly: false,
    disabled: false
  }
  @Input() styleModal = {}
  @Input() itemList: Array<FilterItem> = [];
  @Input() position = {};
  @Input() selectedServices: any[] = [];
  selectedItemList: any[] = [];
  localData: any[] = [];
  @Output() checkRows = new EventEmitter<any>();
  @Output() closeModalHandler = new EventEmitter();

//  【改善案】
//  「01-1：【介護保険居宅介護サービス】」
//  「01-2：【介護保険介護予防サービス】」
//  「01-3：【介護保険入居入所サービス】」
//  「02-9：【医療保険】」
//  「05-7：【日常生活支援総合事業サービス】」
//  「04-9：【市町村独自サービス（介護）】」
//  「03-4：【障害者総合支援法サービス】」
//  「04-5：【障害児施設支援サービス】」
//  「08-9：【市町村独自サービス（総合支援）】」
//   ※数値部分は「KBN-FIXFLG」

  itemLevel1: FilterItem = {
    id: 'root1',
    name: '【介護保険居宅介護サービス】',
    checked: false,
    isLabel: true,
    parent: '',
    level: '1'
  };

  itemLevel2: FilterItem = {
    id: 'root2',
    name: '【介護保険介護予防サービス】',
    checked: false,
    isLabel: true,
    parent: '',
    level: '1'
  }

  itemLevel3: FilterItem = {
    id: 'root3',
    name: '【介護保険入居入所サービス】',
    checked: false,
    isLabel: true,
    parent: '',
    level: '1'
  }

  itemLevel4: FilterItem = {
    id: 'root4',
    name: '【医療保険】',
    checked: false,
    isLabel: true,
    parent: '',
    level: '1'
  }

  itemLevel5: FilterItem = {
    id: 'root5',
    name: '【日常生活支援総合事業サービス】',
    checked: false,
    isLabel: true,
    parent: '',
    level: '1'
  }

  itemLevel6: FilterItem = {
    id: 'root6',
    name: '【市町村独自サービス（介護）】',
    checked: false,
    isLabel: true,
    parent: '',
    level: '1'
  }

  itemLevel7: FilterItem = {
    id: 'root7',
    name: '【障害者総合支援法サービス】',
    checked: false,
    isLabel: true,
    parent: '',
    level: '1'
  }

  itemLevel8: FilterItem = {
    id: 'root8',
    name: '【障害児施設支援サービス】',
    checked: false,
    isLabel: true,
    parent: '',
    level: '1'
  }

  itemLevel9: FilterItem = {
    id: 'root9',
    name: '【市町村独自サービス（総合支援）】',
    checked: false,
    isLabel: true,
    parent: '',
    level: '1'
  }

  itemGeneral: FilterItem = {
    id: 'root0',
    name: '【その他】',
    checked: false,
    isLabel: true,
    parent: '',
    level: '1'
  }

  rootNodes: FilterItem[] = [this.itemLevel1, this.itemLevel2, this.itemLevel3, this.itemLevel4, this.itemLevel5, this.itemLevel6, this.itemLevel7, this.itemLevel8, this.itemLevel9, this.itemGeneral];

  constructor() { }

  ngOnInit(): void {
    this.itemList?.forEach((item: any) => {
      switch (item.level) {
        case '01_1':
          item.parent = this.itemLevel1.id;
          break;
      
        case '01_2':
          item.parent = this.itemLevel2.id;
          break;

        case '01_3':
          item.parent = this.itemLevel3.id;
          break;

        case '02_9':
          item.parent = this.itemLevel4.id;
          break;

        case '05_7':
          item.parent = this.itemLevel5.id;
          break;

        case '04_9':
          item.parent = this.itemLevel6.id;
          break;

        case '03_4':
          item.parent = this.itemLevel7.id;
          break;

        case '04_5':
          item.parent = this.itemLevel8.id;
          break;

        case '08_9':
          item.parent = this.itemLevel9.id;
          break;

        default:
          item.parent = this.itemGeneral.id;
          break;
      }
    });
    this.localData = orderBy([...this.itemList], ['id', 'name'],['asc', 'asc']);
    if (this.localData?.length == 0) {
      this.checkedAll = false;
    }
    else {
      this.checkedAll = !(this.itemList.filter(item => item.checked === false).length > 0);
    }

    this.showRootByChildCount();

    this.hasCheckedItems = (this.itemList.filter(item => item.checked === true).length > 0)
    this.selectedItemList = this.itemList.filter(item => item.checked === true);
    this.styleModal = { ...this.styleModal, ...this.position };
  }

  checkAll() {
    if (this.hasCheckedItems) {
      this.checkedAll = false;
      this.localData.forEach(item => {
        item.checked = false;
      });
      this.hasCheckedItems = false;
      this.checkRows.emit([]); //No select any records
    }
    else {
      this.checkedAll = !this.checkedAll;
      if (this.checkedAll) {
        this.localData.forEach(item => {
          item.checked = !item.isLabel ? true : false;
        });
        this.checkRows.emit(this.localData.filter(item => !item.isLabel));
      }
      else {
        this.selectedItemList = [];
        this.localData.forEach(item => {
          item.checked = false;
        });
        this.checkRows.emit([]); //No select any records
      }
    }
  }
  searchData(searchText: string) {
    let data = cloneDeep(this.itemList);
    if (Utils.isNullOrEmpty(searchText)) {
      this.localData = cloneDeep(this.itemList);
    }
    else {
      this.localData = data.filter(item => item.name?.toUpperCase()?.trim()?.includes(searchText?.toUpperCase()?.trim()));
    }
    if (this.localData?.length == 0) {
      this.lstItemRoot = [];
    }
    else {
      this.checkListRootItem();
    }
  }

  checkListRootItem() {
    this.lstItemRoot = [];

    this.showRootByChildCount();

    this.localData = orderBy([...this.localData], ['id', 'name'], ['asc', 'asc']);
  }

  showRootByChildCount() {
    this.rootNodes.forEach(rootNode => {
      const childs = this.localData?.filter(d => d.parent === rootNode.id);
      if (childs?.length > 0) {
        this.lstItemRoot.push(rootNode);
      }
    });
  }

  checkItem(item: any) {
    item.checked = !item.checked;
    this.localData?.map(data => {
      if (data.id === item.id && data.name === item.name) {
        data.checked = item.checked;
      }
    });
    let selectedRecords = cloneDeep(this.localData.filter(i => i.checked === true && !item.isLabel && item));
    this.selectedItemList = selectedRecords;
    let uniqueSelectedItemList = [];
    uniqueSelectedItemList = this.selectedItemList.filter((value: any, index, self) =>
      index === self.findIndex((t: any) => (
        isEqual(value, t)
      ))
    );
    this.selectedItemList = uniqueSelectedItemList;
  
    if(!item.checked) {
      let uncheckItem = cloneDeep(item);
      delete uncheckItem.checked;
      let selectedIndex = this.selectedServices?.findIndex((s: any) =>  {
        delete s.checked;
        return isEqual(uncheckItem, s)
        }
      );
      if (selectedIndex != -1) {
        this.selectedServices?.splice(selectedIndex, 1);
      }
    }
    else {
      this.selectedServices.push( ...this.selectedItemList);
    }

    //Remove property checked
    this.selectedServices.forEach(record => {
      if(record.checked) {
        delete record.checked;
      }
    });

    let uniqueSelectedService = [];
    uniqueSelectedService = this.selectedServices.filter((value: any, index, self) =>
      index === self.findIndex((t: any) => (
        isEqual(value, t)
      ))
    );

    this.selectedServices = uniqueSelectedService;
    if (this.selectedItemList && this.selectedItemList.length > 0) {
      this.hasCheckedItems = true;
    } else {
      this.hasCheckedItems = false;
    }
    if (this.selectedServices && this.selectedServices.length === this.itemList.length) {
      this.checkedAll = true;
    } else {
      this.checkedAll = false;
    }
   

    this.checkRows.emit(this.selectedServices);
  }

  closeModal() {
    this.closeModalHandler.emit();
  }

  getListChildItem(id: string) {
    this.listChildItem = [];
    if (!Utils.isNullOrEmpty(id)) {
      this.listChildItem = this.localData?.filter(item => item.parent == id);
    }
    return this.listChildItem;
  }
}
