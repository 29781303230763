<div class="container-lock-account">
    <div class="box" [ngClass]="{ 'box-mobile':  deviceType == DeviceType.MOBILE || deviceType == DeviceType.TABLET }">
        <div class="title">
           {{LOCK_ACCOUNT.TITLE}}
        </div>
        <div class="content">
          {{LOCK_ACCOUNT.CONTENT}}
        </div>
        <div class="link" (click)="resetPassword()">
          {{LOCK_ACCOUNT.BUTTON}}
        </div>
    </div>
</div>