import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ROUTE_PATH } from '../../../const/route-path';
import { MENU_NAME } from '../../../const/text-common';
import { Menu } from '../../../models/response/menu.ro';
import { HandleSyncDataDataService } from '../../../services/modules/handle-sync-data.service';
import { LocalStorageHelper, LocalStorageKey } from '../../../_helper/local-storage.helper';
import { Staff } from '../../../models/response/staff.ro';
import { AuthenticationService } from '../../../services/authentication.service';
import { ProcessLoadingService } from '../../../services/loading.service';
import { SideMenu } from '../../../const/const';
import { OfficeAPIService } from 'src/app/services/modules/office.service';

@Component({
  selector: 'pivot-page-outlet',
  templateUrl: './page-outlet.component.html'
})
export class PageOutletComponent implements OnInit {
  @Input() isLoggedIn: boolean = false;
  @Input() sidebarExpanded: boolean = false;
  @Output() toggleSidebar = new EventEmitter();
  private _handleSyncDataDataService: HandleSyncDataDataService;

  currentStaff: Staff = new Staff();
  sidebarData: Menu[] = [];
  isHasZaitaiku: boolean = true;

  homeMenu: any = {
    id: "1",
    name: MENU_NAME.HOME,
    routerLink: ROUTE_PATH.HOME,
    description: "",
    icon: "home"
  };
  aggregationMenu: any = {
    id: "2",
    routerLink: ROUTE_PATH.LIST_ITEM_TEMPLATE,
    name: MENU_NAME.AGGREGATION_LIBRARY,
    description: "",
    icon: "aggregation"
  };
  dataSourceMenu: any = {
    id: "3",
    routerLink: ROUTE_PATH.DATASOURCE,
    name: MENU_NAME.DATASOURCE,
    description: "",
    icon: "datasource",
  };
  dashboardMenu: any = {
    id: "4",
    routerLink: ROUTE_PATH.DASHBOARD_LIST,
    name: MENU_NAME.DASHBOARD_LIST,
    description: "",
    icon: "widget"
  };
  dataSourceTemplateMenu: any = {
    id: "5",
    routerLink: ROUTE_PATH.DATASOURCE_TEMPLATE,
    name: MENU_NAME.DATASOURCE_TEMPLATE,
    description: "",
    icon: "datasource-template",
  };
  dashboardTemplateMenu: any = {
    id: "6",
    routerLink: ROUTE_PATH.DASHBOARD_TEMPLATE,
    name: MENU_NAME.DASHBOARD_TEMPLATE,
    description: "",
    icon: "widget-template"
  };
  inputOutputMenu: any = {
    id: "7",
    routerLink: ROUTE_PATH.CK_REPORT,
    name: MENU_NAME.CK_REPORT,
    description: "",
    icon: "ckreport"
  };
  constructor(
    private authenticationService: AuthenticationService,
    private loadingService: ProcessLoadingService,
    private officeService: OfficeAPIService
  ) { }

  async ngOnInit() {
    this.loadingService.isLoading.emit(true);
    await this.checkIfHasZaitaku();
    this.authenticationService.currentUser.subscribe((user: any) => {
      if (!user || !user.token) {
        return;
      }
      this.sidebarData = [];
      if (user.loginstage === "console") {
        if (!this.sidebarData.includes(this.dataSourceTemplateMenu)) {
          this.sidebarData.push(this.dataSourceTemplateMenu);
        }
    
        if (!this.sidebarData.includes(this.dashboardTemplateMenu)) {
          this.sidebarData.push(this.dashboardTemplateMenu);
        }

      } else {
        if (!this.sidebarData.includes(this.homeMenu)) {
          this.sidebarData.push(this.homeMenu);
        }
        
        const roles = this.authenticationService.getData(LocalStorageKey.CURRENT_USER_ROLE);
        if (roles && roles.length > 0 && roles[0].permissions) {
          const datasourceMenu = roles[0].permissions.find((p: any) => p == SideMenu.DATASOURCE);
          const dashboardMenu = roles[0].permissions.find((p: any) => p == SideMenu.DASHBOARD);
          const templateMenu = roles[0].permissions.find((p: any) => p == SideMenu.QUICK_SETTING_WIDGET);

          if (templateMenu && !this.sidebarData.includes(this.aggregationMenu)) {
            this.sidebarData.push(this.aggregationMenu);
          }
  
          if (datasourceMenu && !this.sidebarData.includes(this.dataSourceMenu)) {
            this.sidebarData.push(this.dataSourceMenu);
          }
  
          if (dashboardMenu && !this.sidebarData.includes(this.dashboardMenu)) {
            this.sidebarData.push(this.dashboardMenu);
          }
        }

        if (!this.sidebarData.includes(this.inputOutputMenu) && this.isHasZaitaiku) {
          this.sidebarData.push(this.inputOutputMenu);
        }
      }
    });
    this.handleNotifySyncLog();
  }

	async checkIfHasZaitaku() {
		const services = await this.officeService.getAllOfficeServicesAllowDelFlg();
		if(services && services.length > 0) {
			let isHasZaitakuAndStop = false;
			services.forEach((element: any) => {
			if(isHasZaitakuAndStop)
				return;
			if(element.services.length > 0){
				for(let i = 0; i < element.services.length; i++) {
					let id = element.services[i].id;
					let kindOfService = id.split("_");
					if(parseInt(kindOfService[0]) == 52 && parseInt(kindOfService[1]) == 1) {
				  		this.isHasZaitaiku = true;
				  		isHasZaitakuAndStop = true;
              localStorage.setItem(LocalStorageKey.HAS_ZAITAKU, "1");
					}
			  }
			}
		});
		if(!isHasZaitakuAndStop)
			this.isHasZaitaiku = false;
		}
	}

  handleToggleSidebar(value: any) {
    this.toggleSidebar.emit(value);
  }

  handleNotifySyncLog() {
    let packCd = LocalStorageHelper.getPackCd();
    if (packCd && !this._handleSyncDataDataService.inProgress) {
      this._handleSyncDataDataService.repeatSyncData();
    }
  }
}
