import { ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { TreeNode, TreeViewInputParams } from '../../../models/common-model';
import Utils from '../../../util/utils';

// ツリービュー
@Component({
  selector: 'pivot-tree-view',
  templateUrl: './tree-view.component.html',
  styleUrls: ['./tree-view.component.scss']
})
export class TreeViewComponent implements OnChanges {
  @Input() isTemplate: boolean = false;
  @Input() params: TreeViewInputParams = new TreeViewInputParams();
  @Output() nodeMouseClick = new EventEmitter<any>();
  @Output() nodeMouseDoubleClick = new EventEmitter<any>();
  @Output() nodeDragStart = new EventEmitter<any>();
  @Output() nodeDragEnd = new EventEmitter<any>();
  @Output() nodeCheckboxChange = new EventEmitter<TreeNode>();
  @Output() infiniteScrollChange = new EventEmitter<number>();
  @Output() onDeleteNode = new EventEmitter<TreeNode>();
  @Output() isOnFetchData = new EventEmitter<boolean>();

  enableCheckbox: boolean = false;
  isFirstNodeChecked: boolean = true;
  constructor() { }

  ngOnChanges(changes: SimpleChanges): void {
    if (!Utils.isNullOrEmpty(changes['params'])) {
      this.enableCheckbox = Boolean(this.params?.checkbox);
      this.isFirstNodeChecked = Boolean(Utils.isNullOrEmpty(this.params?.allowParentSelect) || this.params?.allowParentSelect);
    }
  }

  childItemHandler(node: TreeNode) {
    if (node.singleSelected) {
      this.unselectNodes(node, this.params.nodes);
    }

    this.nodeMouseClick.emit(node);
  }

  childItemDoubleClickHandler(node: TreeNode) {
    this.nodeMouseDoubleClick.emit(node);
  }

  childNodeDragStart(event: any) {
    this.nodeDragStart.emit(event);
  }

  childNodeDragEnd(event: any) {
    this.nodeDragEnd.emit(event);
  }

  childNodeCheckboxChange(node: TreeNode) {
    this.nodeCheckboxChange.emit(node);
  }

  infiniteScroll(param: any){
    this.infiniteScrollChange.emit(param);
  }

  onFetchData(status: boolean) {
    this.isOnFetchData.emit(status);
  }

  unselectNodes(selectedNode: TreeNode, nodes: TreeNode[]) {
    if (nodes && nodes.length > 0) {
      for (const node of nodes) {
        if (node !== selectedNode) {
          node.singleSelected = false;
        }

        if (node.nodes) {
          this.unselectNodes(selectedNode, node.nodes);
        }
      }
    }
  }

  deleteNode(node: TreeNode) {
    this.onDeleteNode.emit(node)
  }
}
