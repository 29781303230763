import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { ModalTemplate, SearchParams } from '../../models/common-model';
import { COMMON_TEXT, BUTTON } from '../../../app/const/text-common';
import { ButtonType, DataType, SearchType } from '../../enum/common-enum';
import { TableData } from '../../models/table-model';
import { TITLE } from '../../const/table-col-define';
import { DataSourceService } from 'src/app/services/modules/data-source.service';
import { cloneDeep, orderBy } from 'lodash';

@Component({
  selector: 'pivot-dialog-select-title',
  templateUrl: './dialog-select-title.component.html',
  styleUrls: ['./dialog-select-title.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class DialogSelectTitleComponent implements OnInit, OnChanges {

  @Input() itag: Array<any> =[];
  @Input() officeCds: Array<string> = [];
  @Input() showModal: boolean = false;
  @Input() titles: Array<any> = [];
  @Output() closeModalHandler = new EventEmitter();
  @Output() selectedTitleHandler = new EventEmitter();
  modalTemplate: ModalTemplate = new ModalTemplate();
  currentTitles: Array<any> = [];
  allTitles: Array<any> = [];
  titleSelecteds:  Array<any> = [];
  titleUse: Array<any> = [];
  BUTTON = BUTTON;
  COMMON_TEXT = COMMON_TEXT;
  searchParams: SearchParams = {
    type: SearchType.input,
    placeholder: COMMON_TEXT.SEARCH,
    cssStyle: { width: '100%', height: '40px' },
    readonly: false,
    disabled: false
  };

  tableData: TableData = {
    config: {
      id: 'title-select',
      caption: '',
      emptyMessage: COMMON_TEXT.NO_DATA,
      showIndex: true,
      showCheckbox: true,
      hoverShowCheckbox: false,
      showPagination: false,
      isResponsive: false,
      tableStyle: {}

    },
    header: [
      {
        field: 'sortNo',
        title: TITLE.SORT,
        sortable: false,
        filterable: false,
        attribute: {
          header: { width: '20%' },
          row: {}
        },
        dataType: DataType.TEXT,
        visible: false
      },
      {
        field: 'ttlnm',
        title: TITLE.TITLENM,
        sortable: false,
        filterable: false,
        attribute: {
          header: { width: '80%' },
          row: {}
        },
        dataType: DataType.TEXT,
        visible: true
      },
    ],
    body: []
  };
  loadingTable: boolean = true;
  buttonType = ButtonType;
  
  constructor(private dtsourceService: DataSourceService) { }

    async ngOnInit() {
      this.modalTemplate.header = COMMON_TEXT.SELECT_TITILE;
      this.modalTemplate.style = { 'width': '581px', 'height': '600px' };
      let param = {
        tagList: this.itag,
        officeCds: this.officeCds
      };
      let titlesRequest = await this.dtsourceService.getTitleByTag(param); 
      if(titlesRequest.statuscode == 200) {
        this.allTitles = titlesRequest.data;
        this.titles = titlesRequest.data?.filter((item: any, index: any, self: any) =>
          index === self.findIndex((t: any) =>
            t.ttlnm == item.ttlnm
          )
        );
        this.titles.map(ttl => {
          let findTitle = this.itag?.filter(tl => tl.titleuse && tl.titleuse.includes(ttl.ttlkbn+ '-' +ttl.ttlcd + '-' + ttl.officecd));
            ttl.checked = findTitle?.length > 0 ? true: false;
        });
        this.titles = orderBy(this.titles, ["titlenm"]);
        this.titleUse = cloneDeep(this.titles);
      }
      let selectedRows = cloneDeep(this.titleUse).filter(item => item.checked);
      this.currentTitles = cloneDeep(this.titleUse);
      this.titleSelecteds = selectedRows.map(item => {
        delete item.checked;
        return item;
      });

      this.tableData.body = this.titleUse;
      this.tableData = cloneDeep(this.tableData);
      this.loadingTable = false;
  }

  onFilterTitle(filter: any) {
    if(filter) {
      this.titleUse = this.currentTitles.filter(t => t.ttlnm.toLowerCase().includes(filter.toLowerCase()?.trim()));
    }
    else {
      this.titleUse = cloneDeep(this.currentTitles);
    }
    this.titleUse.forEach((item: any) => {
      let tempItem = this.titleSelecteds.filter(e => e.ttlnm === item.ttlnm);
      if(tempItem.length > 0) {
        item.checked = true;
      }
      else
        item.checked = false;
    })
    this.tableData.body = this.titleUse;
    this.tableData = cloneDeep(this.tableData);
  }

  checkRowsTable(rows: any) {
    this.titleSelecteds = rows.data;
    this.mapdataFilter(rows.data);
  }

  cancel() {
    this.closeModalHandler.emit([]);
  }

  save() {
    let submitData: any[] = [];
    this.allTitles.forEach(ttl => {
        let title = this.titleSelecteds.find(title => title.ttlnm == ttl.ttlnm);
        if(title) {
          submitData.push(ttl);
        }
    });
    submitData = orderBy(submitData, ["ttlkbn", "ttlcd", "officecd"]);
    this.selectedTitleHandler.emit(submitData);
  }

  mapdataFilter(dataChecked: any) {
    if (dataChecked && Object.keys(dataChecked).length > 0) {
      this.titles?.forEach(ttl => {
        const titleIndex = dataChecked?.data?.findIndex((s: any) => s.ttlcd == ttl.ttlcd);
        const selectedIndex = this.titleSelecteds?.findIndex((s: any) => s.ttlcd == ttl.ttlcd);
        ttl.checked = (titleIndex !== -1) || (selectedIndex !== -1);
      });
    }
  }
  

  ngOnChanges(changes: SimpleChanges): void {
    
  }

}
