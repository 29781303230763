import { Component, EventEmitter, Input, OnChanges, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { DialogService, DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { MESSAGE_TEXT } from '../../../../app/const/message';
import { BUTTON, CK_REPORT_TEXT, COMMON_TEXT, DATASOURCE_SETTING } from '../../../../app/const/text-common';
import { ButtonIconType, ButtonType, DataSourceColumnType, DateFormat, DialogType, InputType, SaveType, SearchType, TreeViewTab } from '../../../../app/enum/common-enum';
import { InputParams, LazyTreeNode, ModalTemplate, SearchParams, TitleMapping, TreeNode } from '../../../../app/models/common-model';
import { DataSourceService } from 'src/app/services/modules/data-source.service';
import { ProcessLoadingService } from 'src/app/services/loading.service';
import { cloneDeep, isNil, orderBy } from 'lodash';
import * as moment from 'moment';
import Utils from 'src/app/util/utils';
import { LIST_COMPARISON, ZAITAKU_COLS } from 'src/app/const/table-col-define';
import { conditionItemModel, configColummModel, zaitakuSettingModel } from 'src/app/models/zaitaku-model';
import { v4 as uuidv4 } from 'uuid';
import { DatePipe } from '@angular/common';
import { ZaitakuService } from 'src/app/services/modules/zaitaku.service';
import { ZaitakuSettingDTO } from 'src/app/models/request/zaitaku-setting.dto';
import { ConfirmDialogComponent } from 'src/app/component/common/confirm-dialog/confirm-dialog.component';
import { MAXIMUM_MONTH } from 'src/app/const/const';
import { CONTENT_LOG, SAUCER_LOG_ACTION, SCREEN_NAME } from 'src/app/config/saucer-log.config';
import { SaucerLogService } from 'src/app/services/saucer-logs/saucer-log.service';



@Component({
  selector: 'pivot-zaitaku-setting-dialog',
  templateUrl: './zaitaku-setting-dialog.component.html',
  styleUrls: ['./zaitaku-setting-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ZaitakuSettingDialogComponent implements OnInit {

  buttonType = ButtonType;
  saveType = SaveType;
  BUTTON = BUTTON;
  MESSAGE_TEXT = MESSAGE_TEXT;
  CK_REPORT = CK_REPORT_TEXT;
  COMMON_TEXT = COMMON_TEXT;
  ZAITAKU_COLS = ZAITAKU_COLS;
  LIST_COMPARISON = LIST_COMPARISON;
  LIMIT_TITLEMAPPING = 6000;
  rawTitleMapping: any[] = [];
  titleMappingLoadList: string[] = [];
  iconType = ButtonIconType;

  treeViewNode: TreeNode[] = [];
  treeViewList: LazyTreeNode[] = [];
  isLoadingTree: boolean = false;
  lazyComposedData: LazyTreeNode[] = [];

  modalData: ModalTemplate = new ModalTemplate();

  searchText: string = "";
  duplicateArray: string[] = [];
  isCustomDialogForValidZaitaku: boolean = false;

  paramsForOffices: SearchParams = {
    type: SearchType.combo,
    cssStyle: { width: '100%', height: '40px', 'font-size': '0.875rem' },
    readonly: false,
    disabled: false,
    maxLength: 100,
    defaultValue: '',
    comboDisplayText: 'name'
  };

  paramsForCols: SearchParams = {
    type: SearchType.combo,
    cssStyle: { width: '100%', height: '40px', 'font-size': '0.875rem' },
    readonly: false,
    disabled: false,
    maxLength: 100,
    defaultValue: '',
    comboDisplayText: 'name'
  };

  paramsForConfigs: SearchParams = {
    type: SearchType.input,
    placeholder: COMMON_TEXT.SEARCH,
    cssStyle: { height: '46px', margin: '10px 0px 10px 0px' },
    readonly: false,
    disabled: false,
    maxLength: 100
  };

  paramsForConditionName: any = {
    type: InputType.text,
    borderFill: true,
    validateError: MESSAGE_TEXT.REQUIRE_INPUT,
    maxLength: 200
  };

  originData: zaitakuSettingModel[] = [];

  filteredConfigs: configColummModel[] = [];

  selectedOffice: any;
  initialOffice: any;
  initialStartDate: string;

  currentConfig: configColummModel = new configColummModel();

  validFormData = {
    name: true,
    date: true,
    column: true,
    title: true
  }

  notValidIds: any[] = [];


  errorMessages = {
    name: "",
    date: "",
    column: "",
    title: ""
  }


  dateFormat: string = DateFormat.SHORT_DATE.toLocaleLowerCase();


  delTxt: string = '';
  isDisplayModalDelete: boolean = false;
  modalDelete: ModalTemplate = new ModalTemplate();

  isAddConfig: boolean = false;
  changeSettingData: boolean = false;

  //#region LOG
  logAction: any = SAUCER_LOG_ACTION.RETURN_HOME;
  SAUCER_LOG_ACTION = SAUCER_LOG_ACTION;
  orginalZaitakuSetting: ZaitakuSettingDTO;
  //#endregion

  constructor(
    public ref: DynamicDialogRef,
    public dlgConfig: DynamicDialogConfig,
    private dataSourceService: DataSourceService,
    private loadingService: ProcessLoadingService,
    private zaitakuService: ZaitakuService,
    private modalService: DialogService,
    private datePipe: DatePipe,
    private saucerLogService: SaucerLogService,
  ) {
    this.dlgConfig.header = "自動データを取得設定";
    this.dlgConfig.showHeader = true;
    this.dlgConfig.draggable = true;
    this.dlgConfig.style = {
      width: '65%',
      header: ''
    };
    this.modalDelete.appendTo = "body";

    //Log
    this.saucerLogService.action({}, { 
      action: SAUCER_LOG_ACTION.RETURN_HOME.VIEW_DIALOG 
    });
  }







  //#region event angular & fetch data
  ngOnChanges(): void {
  }

  async ngOnInit() {

    this.loadingService.isLoading.emit(true);
    this.changeSettingData = false;
    // cols
    this.paramsForCols.comboDisplayText = 'name';
    this.paramsForCols.dataSource = ZAITAKU_COLS;
    this.paramsForCols = cloneDeep(this.paramsForCols);

    // offices
    this.paramsForOffices.comboDisplayText = 'name';
    this.paramsForOffices.dataSource = this.dlgConfig.data.listOffices;
    this.initialOffice = this.dlgConfig.data.initialOffice;
    this.selectedOffice = this.dlgConfig.data.listOffices.find((x: any) => x.jigno == this.initialOffice.jigno && x.siteino == this.initialOffice.siteino);
    this.paramsForOffices.defaultValue = this.selectedOffice.name;
    this.paramsForOffices.selected = this.paramsForOffices.defaultValue;
    this.paramsForOffices = cloneDeep(this.paramsForOffices);

    // initialStartDate
    this.initialStartDate = this.dlgConfig.data.initialStartDate;

    // fetch data setting
    const settingTask = await this.loadSettingData(this.initialOffice.jigno, this.initialOffice.siteino);
    const treeViewTask = await this.loadTreeView();
    await Promise.all([settingTask, treeViewTask]);

  }

  setOrginalZaitakuSettingForLog() {
    let item = new ZaitakuSettingDTO();
    item.isupdate = !this.isAddConfig;
    item.jigno = this.selectedOffice.jigno;
    item.siteino = this.selectedOffice.siteino;
    item.officecd = this.selectedOffice.officecd;
    item.id = this.currentConfig.id;
    item.conditionname = this.currentConfig?.conditionname?.trim();
    item.countingtype = this.currentConfig.countingtype;
    item.sdate = this.currentConfig.sdate;
    item.edate = this.currentConfig.edate || MAXIMUM_MONTH;
    item.columncd = this.currentConfig.columncd;
    this.currentConfig.conditions =this.currentConfig.conditions.map(item => ({...item, val: item.val ?? ""}));
    item.conditions = JSON.stringify(this.currentConfig.conditions);
    this.orginalZaitakuSetting = cloneDeep(item);
  }

  async loadSettingData(jigno: string, siteino: string, callApi: boolean = true) {

    // fetch data
    if (callApi) {
      let res = await this.zaitakuService.getSettingByOffice(jigno, siteino);
      this.originData = res;
    }

    if (this.originData && this.originData.length > 0) {
      let firstItem = this.originData[0];
      this.filteredConfigs = cloneDeep(firstItem.configs);
      if (this.currentConfig && this.currentConfig.id) {
        let configById = this.filteredConfigs.find(x => x.id == this.currentConfig.id);
        if (configById != undefined) {
          this.currentConfig = cloneDeep(configById);
        } else {
          this.currentConfig = cloneDeep(this.filteredConfigs[0]);
        }
      } else {
        this.currentConfig = cloneDeep(this.filteredConfigs[0]);
      }

      this.paramsForCols.selected = this.ZAITAKU_COLS.find(x => x.columncd == this.currentConfig.columncd)?.name;
      this.paramsForCols.defaultValue = this.ZAITAKU_COLS.find(x => x.columncd == this.currentConfig.columncd)?.name;
      this.paramsForCols = cloneDeep(this.paramsForCols);

      this.isAddConfig = false;
    } else {
      this.filteredConfigs = [];
      this.isAddConfig = true;
      this.createNewForm();
    }

    this.setOrginalZaitakuSettingForLog();
  }

  async loadTreeView() {
    this.treeViewNode = [];
    this.treeViewList = [];
    this.rawTitleMapping = [];
    //RECORD: Default NOTE.NOTEKBN='0' and NOTE.DOCKBN='0' and NOTE.OFFICECD='選択された事業所' (00)
    await this.loadTitleMapping([0], [0], [TreeViewTab.RECORD]);

    this.searchTitleMapping(0, 0);
  }
  //#endregion


  //#region button
  onClose() {
    this.ref.close(this.changeSettingData);
  }

  async onSave(closeDlg: boolean) {
    //validate
    if (!this.validForm()) return;

    this.loadingService.isLoading.emit(true);
    this.changeSettingData = true;

    let item = new ZaitakuSettingDTO();
    item.isupdate = !this.isAddConfig;
    item.jigno = this.selectedOffice.jigno;
    item.siteino = this.selectedOffice.siteino;
    item.officecd = this.selectedOffice.officecd;
    item.id = this.currentConfig.id;
    item.conditionname = this.currentConfig.conditionname.trim();
    item.countingtype = this.currentConfig.countingtype;
    item.sdate = this.currentConfig.sdate;
    item.edate = this.currentConfig.edate || MAXIMUM_MONTH;
    item.columncd = this.currentConfig.columncd;
    this.currentConfig.conditions =this.currentConfig.conditions.map(item => ({...item, val: item.val ?? ""}));
    item.conditions = JSON.stringify(this.currentConfig.conditions);
    let res = await this.zaitakuService.insertOrUpdateSetting(item, this.orginalZaitakuSetting, closeDlg);

    if (res && res.statuscode == 200) {

      if (res.data === "VALID_OVERLAP_BACKEND") {
        this.paramsForCols.borderRed = true;
        this.paramsForCols = cloneDeep(this.paramsForCols);
        this.loadingService.isLoading.emit(false);
        this.openDialogSuccess(DialogType.error);
        return;
      }
      if (!closeDlg) await this.loadSettingData(this.selectedOffice.jigno, this.selectedOffice.siteino);
      this.loadingService.isLoading.emit(false);
      this.openDialogSuccess(item.isupdate ? DialogType.update : DialogType.save);
      if (closeDlg) this.ref.close(this.changeSettingData);
    } else {
      this.loadingService.isLoading.emit(false);
      this.openDialogSuccess(DialogType.error);
    }
  }

  validForm() {
    this.clearErrorMessages();
    this.validName();
    if (!this.validFormData.name) return false;
    this.validColumn();
    if (!this.validFormData.column) return false;
    this.validDate();
    if (!this.validFormData.date) return false;
    this.validOverlap();
    if (!this.validFormData.date) return false;
    this.validTitle();
    if (!this.validFormData.title) return false;
    return true;
  }

  clearErrorMessages(type: string = "") {
    if (!type || type == 'name') {
      this.validFormData.name = true;
      this.errorMessages.name = "";
      this.paramsForConditionName.borderRed = false;
      this.paramsForConditionName = cloneDeep(this.paramsForConditionName);
    }
    if (!type || type == 'column') {
      this.validFormData.column = true;
      this.errorMessages.column = "";
      this.paramsForCols.borderRed = false;
      this.paramsForCols = cloneDeep(this.paramsForCols);
    }
    if (!type || type == 'date') {
      this.validFormData.date = true;
      this.errorMessages.date = "";
    }
    if (!type || type == 'title') {
      this.validFormData.title = true;
      this.errorMessages.title = "";
      this.notValidIds = [];
    }
  }

  openDialogSuccess(dialogType: DialogType) {
    this.modalService.open(ConfirmDialogComponent, {
      data: {
        dialogType: dialogType,
      }
    });
  }
  //#endregion

  //#region cols
  onChooseCol(event: any) {
    this.currentConfig.columncd = event[0]?.columncd || "";
    this.paramsForCols.selected = this.ZAITAKU_COLS.find(x => x.columncd == this.currentConfig.columncd)?.name;
    this.paramsForCols.defaultValue = this.ZAITAKU_COLS.find(x => x.columncd == this.currentConfig.columncd)?.name;
    this.paramsForCols = cloneDeep(this.paramsForCols);
    this.clearErrorMessages('column');
  }
  //#endregion

  //#region configs
  onAddConfig() {
    this.isAddConfig = true;
    this.clearErrorMessages();
    this.createNewForm();

    //Log
    this.saucerLogService.action({}, { action: this.logAction.CREATE_CONDITION });
  }

  onDeleteConfig() {
    if (this.isAddConfig) return;
    this.openConfirmDialog('delete');
  }

  openConfirmDialog(type: string) {
    if (type == 'delete') {
      this.modalDelete.header = COMMON_TEXT.CONFIRM_DELETE;
      this.modalDelete.style = { width: '25%', 'min-width': '300px' }
      this.delTxt = this.currentConfig.conditionname;
      this.duplicateArray = [];
      this.isCustomDialogForValidZaitaku = false;
    } else {
      this.modalDelete.header = COMMON_TEXT.INFORMATION;
      this.modalDelete.style = { width: '25%', 'min-width': '300px' }
      this.delTxt = "";
      this.isCustomDialogForValidZaitaku = true;
    }

    this.isDisplayModalDelete = true;
  }

  async onSubmitConfirmDialog(event: boolean) {
    if (!event) {
      this.isDisplayModalDelete = false;
      return;
    };

    this.loadingService.isLoading.emit(true);
    let res = await this.zaitakuService.deleteSetting(this.currentConfig);
    if (res && res.statuscode == 200) {
      this.currentConfig = new configColummModel();
      await this.loadSettingData(this.selectedOffice.jigno, this.selectedOffice.siteino);
      this.openDialogSuccess(DialogType.delete);
    } else {
      this.openDialogSuccess(DialogType.error);
    }
    this.loadingService.isLoading.emit(false);
    this.isDisplayModalDelete = false;
  }

  onSearchConfig(val: any) {
    let firstItem = this.originData[0];
    if (val == "") {
      this.filteredConfigs = cloneDeep(firstItem.configs);
      return;
    }

    this.filteredConfigs = cloneDeep(firstItem.configs.filter((s: any) => s.conditionname?.toUpperCase()?.trim().includes(val?.toUpperCase()?.trim()?.trim())))
  
    //Log
    this.saucerLogService.action({
      content: CK_REPORT_TEXT.SEARCH_CONDITION+ ': ' + val
      }, { 
      action: this.logAction.SEARCH_CONDITION
    });
  }

  onClickConfig(event: any) {
    this.isAddConfig = false;
    this.currentConfig = cloneDeep(event.value) || new configColummModel();
    this.clearErrorMessages();

    this.paramsForCols.selected = this.ZAITAKU_COLS.find(x => x.columncd == this.currentConfig.columncd)?.name;
    this.paramsForCols.defaultValue = this.ZAITAKU_COLS.find(x => x.columncd == this.currentConfig.columncd)?.name;
    this.paramsForCols = cloneDeep(this.paramsForCols);

    this.setOrginalZaitakuSettingForLog();
  }

  createNewForm() {
    this.currentConfig = new configColummModel();
    this.currentConfig.id = uuidv4();
    this.currentConfig.countingtype = 1;
    this.currentConfig.sdate = this.initialStartDate;
    this.currentConfig.edate = "";

    this.currentConfig.columncd = "";
    this.paramsForCols.selected = "";
    this.paramsForCols.defaultValue = "";
    this.paramsForCols = cloneDeep(this.paramsForCols);

    let item = new conditionItemModel();
    item.id = uuidv4();
    item.comparison = LIST_COMPARISON[2].value;
    this.currentConfig.conditions = [item];
  }

  //#endregion

  //#region condition item

  onAddConditionItem() {
    let item = new conditionItemModel();
    item.id = uuidv4();
    item.comparison = LIST_COMPARISON[2].value;
    this.currentConfig.conditions.push(item);

    this.saucerLogService.action({}, { action: this.logAction.CREATE_RECORD });
  }

  onRemoveConditionItem(event: any) {
    let removeItem = this.currentConfig.conditions?.find(x => x.id == event);
    this.currentConfig.conditions = this.currentConfig.conditions.filter(x => x.id !== event);

    const oldLog = {
      'itemCd': removeItem?.itemid || '',
      'itemName': removeItem?.itemname || ''
    }
    const newLog = null;
    this.saucerLogService.action({
      content: JSON.stringify({
        old: oldLog,
        new: newLog
      })
    }, { 
      action: this.logAction.DELETE_RECORD
    });
  }
  onInputConditionName(event: any) {
    this.currentConfig.conditionname = event.currentTarget?.value;
  }
  onChangeConditionName(event: any) {
    if (event?.trim()) this.clearErrorMessages('name');
  }

  onUpdateDataCondition(event: any, index: number) {
    this.currentConfig.conditions[index] = event;
    this.clearErrorMessages('title');
  }

  //#endregion condition item


  //#region offices
  async onSelectOffice(event: any) {
    this.loadingService.isLoading.emit(true);
    let oldOffice: any = cloneDeep(this.selectedOffice);
    this.selectedOffice = event.length > 0 ? event[0] : {};
    this.currentConfig = new configColummModel();
    this.clearErrorMessages();

    const settingTask = await this.loadSettingData(this.selectedOffice.jigno, this.selectedOffice.siteino);

    const treeViewTask = await this.loadTreeView();

    await Promise.all([settingTask, treeViewTask]);

    //this.validDate();

    //Log
    const oldLog = {
      'officeCd': oldOffice?.officecd,
      'officeName': oldOffice?.name
    }
    const newLog = {
      'officeCd':  this.selectedOffice?.officecd,
      'officeName': this.selectedOffice?.name
    };
    this.saucerLogService.action({
        content: JSON.stringify({
          old: oldLog,
          new: newLog
        })
      }, { 
      action: this.logAction.SWITCHING_BUSINESS_ESTABLISHMENT
    });
  }
  //#endregion

  //#region treenode

  async loadTitleMapping(notekbn: number[], dockbn: number[], selectedTabs?: TreeViewTab[]) {
    let officeCds = [this.selectedOffice.officecd];

    let titleMappingData: any[] = [];
    let loopTotal = 0;
    let countRequest = {
      officeIds: officeCds,
      isOnlyTag: false,
      noteKbn: notekbn.length ? notekbn : null,
      docKbn: dockbn.length ? dockbn : null,
      searchText: this.searchText
    }
    await this.dataSourceService.countTitleMappingByOffices(countRequest).then(async (countTitleRes) => {
      if (countTitleRes.statuscode === 200 && countTitleRes.data) {
        loopTotal = Math.ceil(countTitleRes.data / this.LIMIT_TITLEMAPPING);
        await Promise.all([...Array(loopTotal).keys()].map(index => {
          let titleMappingRequest = {
            officeIds: officeCds,
            offset: index * this.LIMIT_TITLEMAPPING,
            limit: this.LIMIT_TITLEMAPPING,
            isOnlyTag: false,
            noteKbn: notekbn.length ? notekbn : null,
            docKbn: dockbn.length ? dockbn : null,
            searchText: this.searchText
          }
          return this.dataSourceService.getTitleAndTagFromOffice(titleMappingRequest);
        })).then((responses: any) => {
          responses.forEach((resdata: any) => {
            if (resdata.statuscode === 200 && resdata.data) {
              /*
                wtype = 2 => number;
                wtype = 4 => datetime;
                wtype = 8 => image;
                else => string
               */
              let filteredRes: any[] = resdata.data.filter((item: any) => item.wtype != 8 && item.wtype != 4);
              if (filteredRes) titleMappingData.push(filteredRes);
            }
          })
          if (titleMappingData.length > 0) {
            titleMappingData = titleMappingData.flat();
          }
        }).finally(() => {
          if (this.searchText == "" && selectedTabs && selectedTabs.length) {
            this.titleMappingLoadList.push(...selectedTabs.map(x => x.toString()));
          }
          titleMappingData = orderBy(titleMappingData, ['officecd', 'ttlkbn', 'ttlcd', 'sortno']);
          this.rawTitleMapping = this.rawTitleMapping.concat(titleMappingData);
        });
      }
    }).finally(() => this.loadingService.isLoading.emit(false));
  }

  searchTitleMapping(notekbn: number, dockbn: number) {
    // case TreeViewTab.RECORD: //Default NOTE.NOTEKBN='0' and NOTE.DOCKBN='0' and NOTE.OFFICECD='選択された事業所' (00)
    let dataWorker = {
      typeWorker: "dataSource",
      rawTitleMapping: this.rawTitleMapping,
      notekbn: notekbn,
      dockbn: dockbn,
      officeList: [this.selectedOffice.officecd],
      searchText: this.searchText
    }
    let localTitles = {};

    if (typeof Worker !== 'undefined') {
      // Create a new
      const worker = new Worker(new URL('../../../workers/data-source.worker.ts', import.meta.url),
        { name: 'names' + notekbn + dockbn, type: 'module' })
      worker.onmessage = ({ data }) => {
        if (data) {
          localTitles = data.localTitles.filter((x: any) => x.officecd == this.selectedOffice.officecd);

          this.treeViewNode = this.generateTitleTree(localTitles, notekbn, dockbn);
          this.treeViewList = this.updateLazyTree(this.treeViewNode);
        }
        worker.terminate()
        this.isLoadingTree = false;
      };
      worker.postMessage(dataWorker);
    } else {

      this.isLoadingTree = false;
      // Web workers are not supported in this environment.
      // You should add a fallback so that your program still executes correctly.
    }
  }

  generateTitleTree(titleList: any, notekbn: number, dockbn: number): any {

    let emptyNode = this.generateEmptyNode();

    let kbnNodes: TreeNode[] = [];
    if (titleList.length) {
      titleList.map((item: any) => {
        //KBN
        let kbnNode = new TreeNode();
        kbnNode.id = `kbn+${item.officecd}+${item.ttlkbn}`;
        kbnNode.label = item.ttlkbnnm;
        kbnNode.labelId = kbnNode.id;
        kbnNode.isShowExpandIcon = false;
        kbnNode.isShowIcon = true;


        //TITLES
        let titles = Object.values(item.titles);
        let titleNodes: TreeNode[] = [];
        if (titles.length > 0) {
          titles.map((title: any, index: number) => {
            if (title) {
              title = Object.values(title);
              title = title[0]//Get first item
              if (!Utils.isNullOrEmpty(title.ttlcd)) {
                let titleNode = new TreeNode();
                titleNode.id = `title+${title.officecd}+${title.ttlkbn}+${title.ttlcd}`;
                titleNode.labelId = titleNode.id;
                titleNode.label = title.ttlnm;
                titleNode.isShowExpandIcon = false;
                titleNode.draggable = false;
                titleNode.isShowIcon = false;
                if (titleNode !== undefined && title.items?.length) {
                  const originalArray = title.items.map((item: any) => item.itemid);
                  const duplicateElements = originalArray.filter((item: string, index: number) => originalArray.indexOf(item) !== index)
                  if (duplicateElements.length > 0) {
                    let items = orderBy(title.items, ["sdate"], ['desc']);
                    let groupDate = this.groupDateTime(items);
                    if (Object.keys(groupDate).length > 0) {
                      let dateTimeNodes: TreeNode[] = [];
                      Object.keys(groupDate).map((key: any) => {
                        let dateTimeNode = new TreeNode();
                        dateTimeNode.id = `titledate+${key}`;
                        dateTimeNode.labelId = dateTimeNode.id;
                        dateTimeNode.label = key;
                        dateTimeNode.isShowExpandIcon = false;
                        dateTimeNode.draggable = true;
                        dateTimeNode.isShowIcon = false;
                        let itemNodes: TreeNode[] = [];
                        groupDate[key].items?.map((item: any) => {
                          //ITEMS
                          let itemData = this.createTitleMappingObject(item);
                          itemData.tooltip = `${item.ttlkbnnm} > ${item.ttlnm} > ${item.itemnm}`
                          let titleId = titleNode.id?.substring(6, titleNode.id.length); //01+001;
                          let itemNode = new TreeNode();
                          itemNode.id = `item+${titleId}+${item.itemid}+${item.datatype}+${item.id}`;
                          itemNode.data = [itemData];
                          itemNode.labelId = itemNode.id;
                          itemNode.label = item.itemnm;
                          itemNode.draggable = true;
                          itemNode.isShowExpandIcon = false;
                          itemNode.isLastNode = true;
                          itemNode.isShowIcon = false;
                          itemNodes.push(itemNode);
                        })
                        dateTimeNode.nodes = itemNodes;
                        dateTimeNodes.push(dateTimeNode);
                      })
                      titleNode.nodes = dateTimeNodes;
                    }
                  }
                  else {
                    let itemNodes: TreeNode[] = [];
                    //ITEMS
                    title.items.map((item: any) => {
                      let titleId = titleNode.id?.substring(6, titleNode.id.length); //01+001;
                      let itemData = this.createTitleMappingObject(item);
                      itemData.tooltip = `${item.ttlkbnnm} > ${item.ttlnm} > ${item.itemnm}`
                      let itemNode = new TreeNode();
                      itemNode.id = `item+${titleId}+${item.itemid}+${item.datatype}+${item.id}`;
                      itemNode.data = [itemData];
                      itemNode.labelId = itemNode.id;
                      itemNode.label = item.itemnm;
                      itemNode.draggable = false;
                      itemNode.selected = true;
                      itemNode.isShowExpandIcon = false;
                      itemNode.isLastNode = true;
                      itemNode.isShowIcon = false;
                      itemNodes.push(itemNode);
                    })
                    titleNode.nodes = itemNodes;
                  }
                }
                titleNodes.push(titleNode);
              }
            }
          });
          kbnNode.nodes = titleNodes;
          kbnNodes.push(kbnNode);
        }
        else {
          let titleNodes: TreeNode[] = [];
          titles.map((title: any) => {
            if (title) {
              title = Object.values(title);
              title = title[0]//Get first item
              let itemData = this.createTitleMappingObject(title);
              itemData.tooltip = `${item.ttlkbnnm} > ${item.ttlnm} > ${item.itemnm}`
              let titleNode = new TreeNode();
              titleNode.id = `title+${title.officecd}+${title.ttlkbn}+${title.ttlcd}`;
              titleNode.data = [itemData];
              titleNode.labelId = titleNode.id;
              titleNode.label = title.ttlnm;
              titleNode.isShowExpandIcon = false;
              titleNode.draggable = true;
              titleNode.isShowIcon = false;
              titleNodes.push(emptyNode);
            }
          });
          kbnNode.nodes = titleNodes;
          kbnNodes.push(kbnNode);
        }
      })
    }
    else {
      kbnNodes.push(emptyNode);
    }
    return kbnNodes;
  }

  updateLazyTree(nodes?: TreeNode[]) {
    if (nodes && nodes.length) {
      this.lazyComposedData = [];
      this.serializeNodes(nodes!, 0, undefined, true);
      return [...this.lazyComposedData];
    }
    else return [];
  }

  groupDateTime(date: any[]) {
    let items = date.reduce((r, {
      sdate,
      edate,
      ...item
    }) => {
      const dateKey = `${moment(sdate).format(DateFormat.FULL_SHORT_DATE)} ~ ${moment(edate).format(DateFormat.FULL_SHORT_DATE)}`;;
      r[dateKey] = r[dateKey] || { items: [] };
      r[dateKey]["items"].push({ ...item, sdate, edate });
      return r;
    }, {});

    return items;
  }

  generateEmptyNode() {
    let emptyNode = new TreeNode();
    emptyNode.id = `empty-node`;
    emptyNode.label = COMMON_TEXT.EMPTY_NODE;
    emptyNode.isShowExpandIcon = false;
    emptyNode.isShowIcon = false;
    emptyNode.draggable = false;
    return emptyNode;
  }

  serializeNodes(nodes: TreeNode[], level: number, parent?: LazyTreeNode, showIcon?: boolean) {
    if (nodes && nodes.length) {
      for (const [index, node] of nodes.entries()) {
        const rowNode = new LazyTreeNode();
        rowNode.parent = parent;
        rowNode.id = node.id;
        rowNode.level = level;
        rowNode.isFolder = (level == 0);
        rowNode.text = node.label ?? '';
        rowNode.tooltip = node.tooltip?.toString() || '';
        rowNode.expandable = Boolean(node.nodes && nodes.length);
        rowNode.node = node;
        rowNode.draggable = node.draggable;
        rowNode.style = node.style;
        rowNode.icon = node.icon;
        if (!rowNode.parent) {
          rowNode.isFirstNode = true;
        }

        if (!node.nodes || node.nodes.length === 0) {
          rowNode.isLastNode = true;
          rowNode.showIcon = showIcon
        }
        rowNode.isLastChild = (index == (nodes.length - 1));
        rowNode.isParentLastChild = level <= 1 ? [] : ([...(parent?.isParentLastChild ?? []), (parent?.isLastChild ?? true)]);
        this.lazyComposedData.push(rowNode);

        if (node.nodes && node.expanded) {
          rowNode.expanded = node.expanded;
          this.serializeNodes(node.nodes, level + 1, rowNode, showIcon);
        }
      }
    }
  }

  createTitleMappingObject(item: any): TitleMapping {
    let itemData: TitleMapping = {
      id: "",
      officecd: item.officecd,
      blockcd: item.blockcd,
      unitcd: item.unitcd,
      ttlkbn: item.ttlkbn,
      ttlcd: item.ttlcd,
      itemid: item.itemid,
      displayname: item.itemnm,
      sdate: moment(item.sdate).format(DateFormat.DS_FULL_SHORT_DATE),
      edate: moment(item.edate).format(DateFormat.DS_FULL_SHORT_DATE),
      columntype: DataSourceColumnType.NORMAL,
      datatype: item.datatype,
      sortno: -1,
      notekbn: item.notekbn,
      dockbn: item.dockbn,
      delflg: false,
      tooltip: "",
      tabtype: item.tabtype,
      wtype: item.wtype,
      im: item.im
    }
    return itemData;
  }

  //#endregion treenode


  //#region Datetime
  async onChangeDate(value: Date, type: number) {
    if (!value) return;
    let month = this.datePipe.transform(value, 'yyyy-MM') || "";
    if (type == 1) this.currentConfig.sdate = month;
    if (type == 2) this.currentConfig.edate = month;

    let actionLog = type == 1 ? this.logAction.SHOW_CALENDAR_START_DIALOG : this.logAction.SHOW_CALENDAR_END_DIALOG;

    this.saucerLogService.action({
      content: (type == 1 ? CONTENT_LOG.START_MONTH : CONTENT_LOG.END_MONTH) + ": " + month
    }, { action: actionLog });
    // this.validDate();
  }

  //#endregion


  //#region checkboxs
  onCheckedOptionChange(type: number) {
    this.currentConfig.countingtype = type;
  }
  //#endregion


  //#region valid
  validDate() {
    if (!this.currentConfig) return;

    this.validFormData.date = true;

    let sdate = this.currentConfig.sdate;
    let edate = this.currentConfig.edate || MAXIMUM_MONTH;

    if (!sdate || !edate) {
      this.validFormData.date = false;
      this.errorMessages.date = this.CK_REPORT.ERROR_OPTION;
      return;
    }

    if (sdate > edate) {
      this.validFormData.date = false;
      this.errorMessages.date = this.CK_REPORT.ERROR_EDATE_LATER_THAN_SDATE;
      return;
    }
  }

  validColumn() {
    this.validFormData.column = true;
    if (!this.currentConfig?.columncd) {
      this.validFormData.column = false;
      this.errorMessages.column = this.CK_REPORT.ERROR_OPTION;
      this.paramsForCols.borderRed = true;
      this.paramsForCols = cloneDeep(this.paramsForCols);
      return;
    }
  }

  validOverlap() {
    let sdate = this.currentConfig.sdate;
    let edate = this.currentConfig.edate || MAXIMUM_MONTH;

    let validDate = sdate && edate && (sdate <= edate);

    if (validDate) {
      let overlapItems = this.originData[0]?.configs.filter(item =>
        item.id != this.currentConfig.id
        && item.columncd == this.currentConfig.columncd
        && (sdate <= (item.edate || MAXIMUM_MONTH) && edate >= item.sdate)
      );
      if (overlapItems && overlapItems.length) {
        this.validFormData.date = false;
        this.duplicateArray = [overlapItems[0].conditionname]
        this.openConfirmDialog('duplicate');
      }
    }
  }

  validName() {
    this.validFormData.name = true;
    if (!this.currentConfig?.conditionname?.trim()) {
      this.validFormData.name = false;
      this.errorMessages.name = this.CK_REPORT.ERROR_NAME;
      this.paramsForConditionName.isValidate = true;
      this.paramsForConditionName.borderRed = true;
      this.paramsForConditionName = cloneDeep(this.paramsForConditionName);
      return;
    }
  }

  validTitle() {
    this.validFormData.title = true;

    if (!this.currentConfig?.conditions || this.currentConfig?.conditions.length == 0) {
      this.validFormData.title = false;
      this.errorMessages.title = this.CK_REPORT.ERROR_OPTION;
      this.notValidIds = [];
    }

    let errConditions = this.currentConfig?.conditions.filter(item => (!item?.itemid || item.itemid === "" || item.itemid === null));

    if (errConditions?.length) {
      this.notValidIds = errConditions.map(item => item.id);
      this.validFormData.title = false;
      this.errorMessages.title = this.CK_REPORT.ERROR_OPTION;
    } else {
      this.notValidIds = [];
    }
  }

  //#endregion
}
