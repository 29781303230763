import { Component, EventEmitter, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { DetectDeviceService, IDeviceType } from 'src/app/services/detect-device.service';
import { BUTTON } from '../../../../app/const/text-common';
import { SideMenu, SystemSettingPage } from '../../../const/const';
import { ROUTE_PATH } from '../../../const/route-path';
import { ButtonType, DeviceType } from '../../../enum/common-enum';
import { ModalTemplate } from '../../../models/common-model';
import { Staff } from '../../../models/response/staff.ro';
import { AuthenticationService } from '../../../services/authentication.service';
import { BasicInfoCommunicatorService } from '../../../services/modules/basic-info-communicator.service';
import { getAvatarUrl } from '../../../_helper/helper';
import { LocalStorageHelper, LocalStorageKey } from '../../../_helper/local-storage.helper';
import { HelpListService } from '../../../services/modules/help-list.service';
import { SaucerLogService } from 'src/app/services/saucer-logs/saucer-log.service';
import { SaucerLogDTO } from 'src/app/models/request/log.dto';
import { API_APP } from 'src/app/config/app.config';
import { CONTENT_LOG, SAUCER_LOG_ACTION } from 'src/app/config/saucer-log.config';

@Component({
  selector: 'pivot-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class HeaderComponent implements OnInit {

  vspProfile: boolean = false;
  userName: string = 'FDSスタッフ';
  position: string = '未登録';
  dialogLogout: boolean = false;

  ROUTE_PATH = ROUTE_PATH;
  BUTTON = BUTTON;
  buttonType = ButtonType;
  DeviceType = DeviceType;

  modalLogout = new ModalTemplate();
  isDisplayModalLogout: boolean = false;
  messageLogout: string = 'ログアウトします。よろしいでしょうか？';
  btnOkLogout: string = 'OK';
  btnCancelLogout: string = 'キャンセル';
  currentStaff: Staff = new Staff();
  isDisplaySystemSetting: boolean = false;

  userAvatarUrl: string = '';
  avatarValid: boolean = true;
  deviceType: string;

  countUnread = 0;

  @Output() logoutHandler = new EventEmitter<any>();
  @Output() settingHandler = new EventEmitter<any>();
  constructor(
    private router: Router,
    private authenticationService: AuthenticationService,
    private comtorService: BasicInfoCommunicatorService,
    private detectDeviceService: DetectDeviceService,
    private helpListService: HelpListService,
    private saucerLogService: SaucerLogService
  ) {
    this.authenticationService.currentUser.subscribe(async (user: any) => {
      if (!user || !user.token) {
        return;
      }

      if (user.avatarurl) {
        this.userAvatarUrl = getAvatarUrl(user.staffcode, false, user.avatarurl);
      }
      this.authenticationService.currentUser.subscribe((user: any) => {
        if (!user || !user.token) {
          return;
        }
        const roles = this.authenticationService.getData(LocalStorageKey.CURRENT_USER_ROLE);
        if (roles && roles.length > 0 && roles[0].permissions) {
          const item = roles[0].permissions.find((s: any) => s == SideMenu.SYSTEM_SETTING);
          if (item) this.isDisplaySystemSetting = true;
          else  this.isDisplaySystemSetting = false;
        }else{
          this.isDisplaySystemSetting = false;
        }
      });
      const roles = this.authenticationService.getData(LocalStorageKey.CURRENT_USER_ROLE);
      if (roles && roles.length > 0 && roles[0].permissions) {
        const item = roles[0].permissions.find((s: any) => s == SideMenu.SYSTEM_SETTING);
        if (item) this.isDisplaySystemSetting = true;
      }
    });

    this.comtorService.userAvatarEvents$.forEach(item => {
      this.avatarValid = true;
      this.userAvatarUrl = item;
    });

    this.detectDeviceService.currentDevice.subscribe((device: IDeviceType) => {
      if (device) this.deviceType = device.type;
    });

    this.helpListService.countUnReadHelp.subscribe((number)=>{
      this.countUnread = number;
    })
  }

  ngOnInit(): void {
    window.addEventListener('click', (event: any) => {
      let profile = document.getElementById('hdprofile');
      if (!profile?.contains(event.target)) {
        let avatar = document.getElementById('hdavatar');
        if (!avatar?.contains(event.target)) {
          this.vspProfile = !this.showProfile;
        }
      }
      else {
        let myAccount = document.getElementById('hdmyaccount');
        let systemSetting = document.getElementById('hdsystemsetting');
        if(myAccount?.contains(event.target) || systemSetting?.contains(event.target)) {
          this.vspProfile = !this.showProfile;
        }
      }
    });
    if(this.authenticationService.isLoggedIn()){
      let userID = this.authenticationService.getData(LocalStorageKey.USERID) || 0;
      const roles = this.authenticationService.getData(LocalStorageKey.CURRENT_USER_ROLE);
      let roleCd = "";
      if (roles && roles.length > 0) {
        roleCd = roles[0].roleCd;
      }
      let request = {
        user_id: userID,
        is_admin: (roleCd == 'RL00000001') || (roleCd == 'RL00000099')
      }
      this.getCountUnRead(request);
    }
    this.modalLogout.header = '確認';
  }

  showProfile() {
    this.vspProfile = !this.vspProfile;
  }

  openHelperDocument() {
    window.open('./assets/guideline/CAREKARTE＿Pivotマニュアルver1.0.0.pdf', '_blank');
  }

  // Reload dashboard component when click on logo
  reloadDashboardComponent() {

  }

  logout(): void {
    this.dialogLogout = false;

    this.isDisplayModalLogout = true;
  }
  gotoHelp(): void {
    this.router.navigate([LocalStorageHelper.getUrl(ROUTE_PATH.HELP)]);
    this.settingHandler.emit();
  }

  systemSetting(): void {
    this.router.navigate([LocalStorageHelper.getUrl(ROUTE_PATH.SYSTEM_SETTING) + '/' + SystemSettingPage.ROLE_SETTING]);
    this.settingHandler.emit();
  }

  gotoMyAccount() {
    this.router.navigate([LocalStorageHelper.getUrl(ROUTE_PATH.BASIC_INFO)]);
  }

  async onOkLogout(event: boolean) {
    this.isDisplayModalLogout = false;

    if (!event) {
      return;
    }
    
    // Start write saucer log
    const urlCurrent = this.router.url;
    const log: SaucerLogDTO = {
      apiPath: API_APP.AUTH.LOGOUT,
      content: CONTENT_LOG.LOGOUT + ": " + urlCurrent,
    };
    await this.saucerLogService.action(log, { action: SAUCER_LOG_ACTION.LOGOUT.SUCCESS });
    this.saucerLogService.sendLogImmediately();
    this.authenticationService.logout();

    this.logoutHandler.emit();
    this.vspProfile = false;
  }

  handleAvatarError(event: any) {
    this.avatarValid = false;
  }

 async getCountUnRead(request:any){
    const helpService = await this.helpListService.getHelpListPost(request);
    if(helpService.statuscode === 200){
      let lstHelp =  helpService.data;
      if(lstHelp.length > 0){
        let lstheplunread = lstHelp.filter((x:any) => {
          return (x.unread == '1')
        })
        this.helpListService.setCountUnRead(lstheplunread.length);
      }
    }
  }
}

