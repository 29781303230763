import { Injectable } from '@angular/core';
import {ActivatedRoute, ActivatedRouteSnapshot, CanActivate, NavigationEnd, Router, RouterStateSnapshot, UrlTree} from '@angular/router';
import {Observable} from 'rxjs';
import {ROUTE_PATH} from '../../const/route-path';
import {RoleSettingComponent} from '../../module/role-setting/role-setting.component';
import {AuthenticationService} from '../authentication.service';
import {LocalStorageKey} from '../../_helper/local-storage.helper';
import {ErrorHandleService} from '../error-handle.service';
import {ERROR_TEXT, FUNCTION_TITLE_TEXT} from '../../const/error-text';

@Injectable({
  providedIn: 'root'
})
export class CanActivateSystemSettings implements CanActivate {
  constructor(private router: Router, 
    private authenticationService: AuthenticationService,
    private errorHandleService: ErrorHandleService) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean | UrlTree {
    if (state?.url === ("/" + ROUTE_PATH.AUTH)) return true;
    let currentRole = this.authenticationService.getData(LocalStorageKey.CURRENT_USER_ROLE);
    if (currentRole && currentRole.length > 0 && currentRole[0].permissions) {
      if(currentRole[0].permissions.includes("#systemsetting")) {
        return true;
      }
      else {
        this.openForbiddenErrorScreen();
        return false;
      }
    } 
    else {
      this.openForbiddenErrorScreen();
      return false;
    }
  }

  openForbiddenErrorScreen(): void {
    this.errorHandleService.customErrorDetails(403, ERROR_TEXT.FORBIDDEN_TITLE, ERROR_TEXT.FORBIDDEN, "", FUNCTION_TITLE_TEXT.SCREEN_INIT_FAIL, false);
    this.errorHandleService.navigateTo([ROUTE_PATH.PAGE_ERROR])
  }
}
