import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ButtonIconType, ButtonType, DataType, LegendType } from 'src/app/enum/common-enum';
import { BUTTON, COMMON_TEXT, WIDGET_SETTING } from 'src/app/const/text-common';
import { ModalTemplate, WidgetGraphSetting } from 'src/app/models/common-model';
import { orderBy, uniqBy, cloneDeep } from 'lodash';
import { TableData } from 'src/app/models/table-model';

@Component({
  selector: 'pivot-combo-chart-setting',
  templateUrl: './combo-chart-setting.component.html',
  styleUrls: ['./combo-chart-setting.component.scss'],
})
export class ComboChartSettingComponent implements OnInit {
  @Input() chartData: any;
  @Input() widgetGraphSetting: WidgetGraphSetting[] = [];
  iconType = ButtonIconType;
  buttonType = ButtonType;
  options: any = {};
  BUTTON = BUTTON;
  modalData: ModalTemplate = new ModalTemplate();
  displaySetting = {column: true, row: false};

  chartTypeOptions: any[] = [{value: 0, title: "棒"}, {value: 1, title: '折れ線'}];
  tableData: TableData = {
    config: {
      id: 'office-service',
      caption: '',
      emptyMessage: COMMON_TEXT.NO_DATA,
      showIndex: true,
      showCheckbox: false,
      hoverShowCheckbox: false,
      showPagination: false,
      isResponsive: false,
      tableStyle: {}

    },
    header: [
      {
        field: 'targetColumn',
        title: WIDGET_SETTING.LIST,
        sortable: false,
        filterable: false,
        attribute: {
          header: { width: '50%' },
          row: {}
        },
        dataType: DataType.TEXT,
        visible: true
      },
      {
        field: 'graphType',
        title: WIDGET_SETTING.GRAPH,
        sortable: false,
        filterable: false,
        attribute: {
          header: { width: '20%' },
          row: {},
          dataCombobox: this.chartTypeOptions
        },
        dataType: DataType.COMBOBOX,
        visible: true
      },
      {
        field: 'isSecondAxis',
        title: WIDGET_SETTING.AXIS_2,
        sortable: false,
        filterable: false,
        attribute: {
          header: { width: '20%' , 'text-align': 'center'},
          row: {},
          rowClass: 'ck-row-center',
        },
        dataType: DataType.CHECKBOX,
        visible: true
      },
    ],
    body: []
  };;
  @Output() onSaveChartConfig: any = new EventEmitter<any>();
  widgetGraphSetting_Origin : WidgetGraphSetting[] = [];
  constructor() { }

  //>表示設定<
  ngOnInit(): void {
    this.modalData.header = WIDGET_SETTING.DISPLAY_SETTINGS;
    this.modalData.style = { width: '600px', 'max-height': '600px', 'min-width': '400px' };
    this.modalData.appendTo = 'body';
    this.tableData.body = this.widgetGraphSetting;
    this.widgetGraphSetting_Origin = cloneDeep(this.widgetGraphSetting);

  }

  onClose() {
    this.widgetGraphSetting = this.widgetGraphSetting_Origin;
    this.tableData.body = this.widgetGraphSetting_Origin;
    if(this.chartData && this.chartData.graphConfig) {
      this.chartData.graphConfig = this.widgetGraphSetting_Origin;
    }
    this.onSaveChartConfig.emit({isSet: false, data: this.widgetGraphSetting});
  }
  
  saveConfig() {
    this.onSaveChartConfig.emit({isSet: true, data: this.widgetGraphSetting});
  }

  changeLegend(type: LegendType) { //0- column, 1-row
    this.widgetGraphSetting = [];
    if(type == 0) {
      this.displaySetting.column != this.displaySetting.column;
      this.displaySetting.row = false;
      this.loadHeadersForGraphSetting();
    }

  }

  loadHeadersForGraphSetting() {
    let widgetGraphSetting: WidgetGraphSetting[] = [];
    if(this.chartData) {
      if(this.chartData.columns && this.chartData.columns.length > 0) {
        this.chartData.columns.forEach((row : any, rowIndex: number) => {
          let item: WidgetGraphSetting = { targetColumn: row[0], graphType: {value: 0, title: "棒"}, isSecondAxis: false, rowIndex};
          widgetGraphSetting.push(item);
        });
      }
    }
    if(widgetGraphSetting.length) {
      if(this.widgetGraphSetting && this.widgetGraphSetting.length) {
        let deletedSetting = this.widgetGraphSetting.filter((item: any) => widgetGraphSetting.filter((graph: any) => graph.targetColumn == item.targetColumn).length == 0 );
        let addedSettings = widgetGraphSetting.filter((item: any) =>   !this.widgetGraphSetting.some(existingItem => existingItem.targetColumn === item.targetColumn) );
        let order = [...addedSettings, ...this.widgetGraphSetting];
        if(deletedSetting && deletedSetting.length) {
          order = order.filter((item: any) => deletedSetting.filter((graph: any) => graph.targetColumn == item.targetColumn).length >0 ? false :  true );
        }

        let settings = uniqBy(order, 'targetColumn');
        this.widgetGraphSetting = orderBy(settings, ["rowIndex"]);
      }
      else {
        let order = [...widgetGraphSetting];
        this.widgetGraphSetting = orderBy(order, ["rowIndex"]);
      }
    }
  }
}
