export const ERROR_TEXT = {
    NOT_CONTENT: '予期せぬエラーにより処理を中断しました。',
    NOT_CONTENT_TITLE: '204 Not Content',

    BAD_REQUEST: '予期せぬエラーにより処理を中断しました。',
    BAD_REQUEST_TITLE: '400 Bad Request',

    UNAUTHORIZED: '長時間操作が行われなかったため、ログアウトした可能性があります。\n再度ログインしてください。',
    UNAUTHORIZED_TITLE: '401 Unauthorized',

    FORBIDDEN: '権限がありません。',
    FORBIDDEN_TITLE: '403 Forbidden',

    INTERNAL_SERVER_ERROR: '予期せぬエラーにより処理を中断しました。',
    INTERNAL_SERVER_ERROR_TITLE: '500 Internal Server Error',

    BAD_GATEWAY: '予期せぬエラーにより処理を中断しました。',
    BAD_GATEWAY_TITLE: '502 Bad Gateway ',

    TIME_OUT: '処理に時間がかかっており中断されました。',
    TIME_OUT_TITLE: '504 Gateway Timeout. ',

    CONNECTION_ERROR: 'インターネットへの接続状況をご確認下さい。',
    CONNECTION_ERROR_TITLE: '通信が切断されました。 \nインターネットへの接続状況をご確認下さい。',

    OHTHER: "予期せぬエラーにより処理を中断しました。",

    LAMBDA: "操作を中断しました。"
};

export const FUNCTION_TITLE_TEXT = {
    SCREEN_INIT_FAIL: '画面表示に失敗しました。',
    DATA_SYNC_FAIL: '更新に失敗しました。',
    SAVE_DATA_FAIL: '保存に失敗しました。',
    ADD_DATA_FAIL: '追加に失敗しました。',
    UPDATE_DATA_FAIL: '編集に失敗しました。',
    DELETE_DATA_FAIL: '削除に失敗しました。',
    SWITCH_TABS_FAIL: 'タブの切替えに失敗しました。',
    PREVIEW_FAIL: 'プレビュー表示に失敗しました。',
    DOWNLOAD_FAIL: 'ダウンロードに失敗しました。',
    SWITCHING_FAIL: '切替えに失敗しました。',
    IMPORT_DATA_FAIL: 'データ取込みに失敗しました。',
    SYNC_DATA_FAIL: '同期に失敗しました。'
};

export const SQL_ERROR_TITLE: { [key: string]: string } = {
    SELECT: 'データの取得に失敗しました。',
    INSERT: 'データの追加に失敗しました。',
    UPDATE: 'データの更新に失敗しました。',
    DELETE: 'データの削除に失敗しました。',
    OTHER: 'An error has occurred'
};