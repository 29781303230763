import { Component, EventEmitter, Input, OnChanges, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { MESSAGE_TEXT } from '../../../../app/const/message';
import { BUTTON, COMMON_TEXT, DATASOURCE_LIST } from '../../../../app/const/text-common';
import { ButtonType } from '../../../../app/enum/common-enum';
import { ModalTemplate } from '../../../../app/models/common-model';

@Component({
  selector: 'pivot-confirm-del-selected-dialog',
  templateUrl: './confirm-del-selected-dialog.component.html',
  styleUrls: ['./confirm-del-selected-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None,
})


export class ConfirmDeleteSelectedDialogComponent implements OnInit, OnChanges {

  // display modal 
  @Input() isDisplayModal: boolean = false;
  // modal data
  @Input() modalData: ModalTemplate = new ModalTemplate();
  @Input() deleteItems: string[] = [];
  @Input() duplicateItems: string[] = [];
  @Input() message: string = '';
  @Input() btnOkText: string = COMMON_TEXT.YES;
  @Input() btnCancelText: string = COMMON_TEXT.NO;
  @Input() isCustomDialogForDataSourceList: boolean = false;
  @Input() isCustomDialogForWidgetList: boolean = false;
  @Input() isCustomDialogForValidZaitaku: boolean = false;
  // submit data event
  @Output() onSubmitData: any = new EventEmitter<any>();
  // edit  data
  @Input() inputData: string = "";
  // button type
  buttonType = ButtonType;
  warningLabel: string;
  widgetLabel: string;
  dashboardLabel: string;
  emptyNode: string = COMMON_TEXT.EMPTY_NODE;
  @Input() renderArray: any[];
  COMMON_TEXT = COMMON_TEXT;
  isDarkMode: boolean;

  constructor() {
    const bodyElement = document.getElementsByTagName("body")[0];
    this.isDarkMode = bodyElement.classList.contains("navi") || bodyElement.classList.contains("dark") ? true : false;
   }

  // on change data
  ngOnChanges(): void {
    if(this.isCustomDialogForDataSourceList)
    {
      this.warningLabel = DATASOURCE_LIST.WARNING_WHEN_DELETE_DATASOURCE;
      this.widgetLabel = DATASOURCE_LIST.WIDGET_LIST;
      this.dashboardLabel = DATASOURCE_LIST.DASHBOARD_LIST;
    }
    else if(this.isCustomDialogForWidgetList)
    {
      this.warningLabel = DATASOURCE_LIST.WARNING_WHEN_DELETE_WIDGET;
      this.dashboardLabel = DATASOURCE_LIST.DASHBOARD_LIST;
    }
  }

  ngOnInit(): void {
    this.modalData.header = COMMON_TEXT.CONFIRM;
    this.modalData.style = { 'width': '27%' };
    this.modalData.breakpoints = { '960px': '75vw', '640px': '100vw' };
  }

  // on submit data event
  onSubmit(data: boolean) {
    this.onSubmitData.emit(data);
    this.isDisplayModal = false;
  }

  getMessage() {
    if (this.isCustomDialogForValidZaitaku) return MESSAGE_TEXT.PERIOD_CONDITIONS_DUPLICATED
    
    if (this.message) {
      return this.message;
    }
    return MESSAGE_TEXT.CONFIRM_DELETE_SELECTED_ITEM.replace('{0}', this.inputData);
  }
}
