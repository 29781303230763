<div modal-body>
    <div class="dlgu-content dlgu-flex-start-infords">
        <div class="name-data-source-type">
            <span>{{ this.nameDatasourceType }}</span>
        </div>
        <div class="dlgu-block2">
            <div *ngIf="!isDSMaster">
                <div *ngFor="let item of inforData">
                    <span class="ds-suggest-item-dialog">{{ item.name }}
                        <i *ngIf="item.iconNew" class="icon-new-img"></i>
                    </span>
                </div>
            </div>
            <div *ngIf="isDSMaster">
                <div *ngFor="let item of inforData">
                    <span class="ds-suggest-item-dialog">{{ item.name }}
                        <i *ngIf="item.iconNew" class="icon-new-img"></i>
                    </span>
                </div>
            </div>
        </div>
    </div>
</div>