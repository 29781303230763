import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ButtonIconType, ButtonType } from '../../../enum/common-enum';

@Component({
  selector: 'pivot-text-button',
  templateUrl: './text-button.component.html',
  styleUrls: ['./text-button.component.scss']
})
export class TextButtonComponent implements OnInit {
  @Input() iconType: ButtonIconType | undefined;
  @Input() buttonText: string = "";
  @Input() buttonType: ButtonType = ButtonType.PRIMARY;
  @Input() disabled: boolean = false;
  @Input() buttonStyle: any = {};
  @Input() imgUrl: any = null;
  @Output() btnClick = new EventEmitter();
  @Output() btnMouseOver = new EventEmitter();
  @Output() btnMouseOut = new EventEmitter();

  constructor() { }

  ngOnInit(): void {
  }
  clickHandler(){
    this.btnClick.emit();
  }

  onMouseOver() {
    this.btnMouseOver.emit();
  }

  onMouseOut() {
    this.btnMouseOut.emit();
  }

}
