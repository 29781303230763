import {
  Component, ElementRef, Output, EventEmitter, ViewChild, Input, TemplateRef, AfterContentChecked
} from '@angular/core';
import { getContentEditableCaretCoords, isInputOrTextAreaElement } from 'src/app/_helper/mention-helper';
import { TEXT_OPERATOR_CUSTOM } from 'src/app/const/operator-custom';
import { getCaretCoordinates } from 'src/app/models/caret-coords';




@Component({
  selector: 'pivot-mention-list',
  templateUrl: './mention-list.component.html',
  styleUrls: ['./mention-list.component.scss']
})




export class MentionListComponent implements AfterContentChecked {
  @Input() labelKey: string = 'label';
  @Input() itemTemplate: TemplateRef<any>;
  @Output() itemClick = new EventEmitter();
  @ViewChild('list', { static: true }) list: ElementRef;
  @ViewChild('defaultItemTemplate', { static: true }) defaultItemTemplate: TemplateRef<any>;
  itemsFutions :any []= [];
  itemsColunms :any []= [];
  activeIndex: number = 0;
  hidden: boolean = false;
  dropUp: boolean = false;
  styleOff: boolean = false;
  private coords: {top:number, left:number} = {top:0, left:0};
  private offset: number = 0;
  constructor(private element: ElementRef) {}
  TEXT_OPERATOR_CUSTOM = TEXT_OPERATOR_CUSTOM; 
  ngAfterContentChecked() {
 
  }
  ngOnInit(): void{

  }

  get activeItem(): any {
    return this.itemsColunms[this.activeIndex];
    // if(this.activeIndex < this.itemsFutions.length){
    //   return this.itemsFutions[this.activeIndex];
    // }else{
    //   return this.itemsColunms[ this.activeIndex - this.itemsFutions.length];
    // }
   
  }

  position(nativeParentElement: HTMLInputElement, iframe: (HTMLIFrameElement | null) = null) {
    if (isInputOrTextAreaElement(nativeParentElement)) {
      this.coords = getCaretCoordinates(nativeParentElement, nativeParentElement.selectionStart, null);
      this.coords.top = nativeParentElement.offsetTop + this.coords.top - nativeParentElement.scrollTop;
      this.coords.left = nativeParentElement.offsetLeft + this.coords.left - nativeParentElement.scrollLeft;
      this.offset = this.getBlockCursorDimensions(nativeParentElement).height;
    }
    else if (iframe && iframe.offsetParent) {
      let context: { iframe: HTMLIFrameElement, parent: Element } = { iframe: iframe, parent: iframe.offsetParent };
      this.coords = getContentEditableCaretCoords(context);
    }
    else {
      let doc = document.documentElement;
      let scrollLeft = (window.pageXOffset || doc.scrollLeft) - (doc.clientLeft || 0);
      let scrollTop = (window.pageYOffset || doc.scrollTop) - (doc.clientTop || 0);
      let caretRelativeToView = getContentEditableCaretCoords({ iframe: iframe });
      let parentRelativeToContainer: ClientRect = nativeParentElement.getBoundingClientRect();
      this.coords.top = caretRelativeToView.top - parentRelativeToContainer.top + nativeParentElement.offsetTop - scrollTop;
      this.coords.left = caretRelativeToView.left - parentRelativeToContainer.left + nativeParentElement.offsetLeft - scrollLeft;
    }
    this.positionElement();
  }
  getBlockCursorDimensions(nativeParentElement: HTMLInputElement) {
    const parentStyles = window.getComputedStyle(nativeParentElement);
    return {
      height: parseFloat(parentStyles.lineHeight),
      width: parseFloat(parentStyles.fontSize)
    };
  }
  private positionElement(left:number=this.coords.left, top:number=this.coords.top, dropUp:boolean=this.dropUp) {
    const el: HTMLElement = this.element.nativeElement;
    top += dropUp ? 0 : this.offset; // top of list is next line
    el.className = dropUp ? 'dropup' : '';
    el.style.position = "absolute";
    el.style.left = left + 'px';
    el.style.top = top + 'px';
  }
  activateNextItem() {
    // adjust scrollable-menu offset if the next item is out of view
    let listEl: HTMLElement = this.list.nativeElement;
    let activeEl = listEl.getElementsByClassName('active').item(0);
    if (activeEl) {
      let nextLiEl: HTMLElement = <HTMLElement> activeEl.nextSibling;
      if (nextLiEl && nextLiEl.nodeName == "LI") {
        let nextLiRect: ClientRect = nextLiEl.getBoundingClientRect();
        if (nextLiRect.bottom > listEl.getBoundingClientRect().bottom) {
          listEl.scrollTop = nextLiEl.offsetTop + nextLiRect.height - listEl.clientHeight;
        }
      }
    }
    // select the next item
    this.activeIndex = Math.max(Math.min(this.activeIndex + 1, this.itemsColunms.length - 1), 0);//Math.max(Math.min(this.activeIndex + 1, this.itemsFutions.length + this.itemsColunms.length - 1), 0);
  }

  activatePreviousItem() {
    // adjust the scrollable-menu offset if the previous item is out of view
    let listEl: HTMLElement = this.list.nativeElement;
    let activeEl = listEl.getElementsByClassName('active').item(0);
    if (activeEl) {
      let prevLiEl: HTMLElement = <HTMLElement> activeEl.previousSibling;
      if (prevLiEl && prevLiEl.nodeName == "LI") {
        let prevLiRect: ClientRect = prevLiEl.getBoundingClientRect();
        if (prevLiRect.top < listEl.getBoundingClientRect().top) {
          listEl.scrollTop = prevLiEl.offsetTop;
        }
      }
    }
    // select the previous item
    this.activeIndex = Math.max(Math.min(this.activeIndex - 1, this.itemsColunms.length - 1), 0);//this.activeIndex = Math.max(Math.min(this.activeIndex - 1, this.itemsFutions.length + this.itemsColunms.length - 1), 0);
  }

  reset() {
    this.list.nativeElement.scrollTop = 0;
    this.checkBounds();
  }

  hideList() {
    this.hidden = true;
  }

  private checkBounds() {
    let left = this.coords.left, top = this.coords.top, dropUp = this.dropUp;
    const bounds = this.list.nativeElement.getBoundingClientRect();
    // if off right of page, align right
    if (bounds.left + bounds.width > window.innerWidth) {
      left -= bounds.left + bounds.width - window.innerWidth + 10;
    }

    if (bounds.top<0) {
      dropUp = false;
    }
    // set the revised/final position
    this.positionElement(left, top, dropUp);
  }

}
