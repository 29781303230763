<div class="pivot-filter">
    <p-overlayPanel appendTo="{{appendToPosition}}" #op [styleClass]="class ? class + ' filterData' : 'filterData'"
        [style]="cssStyle" (onShow)="displayPopup()" (onHide)="removeClickEvent()">
        <ng-template pTemplate>
            <div class="box-action-filter" id="panelFilter">
                <span class="p-input-icon-right {{isMutiple ? 'text-box-mutiple-search' : 'text-box-search'}}"
                    *ngIf="isFilter">
                    <i [class]="inputSearch.value ? 'pi pi-times' : 'pi pi-search'"
                        (click)="onSearchData(inputSearch.value = ''); inputSearch.focus()"></i>
                    <input #inputSearch type="text" pInputText [maxLength]="maxLength" (input)="onSearchData($event)" 
                        [placeholder]="placeHolderFilter"  [(ngModel)]="searchText"/>
                </span>

                <div class="treenode-titles">
                  <pivot-lazy-tree-view 
                  [data]="treeViewList"  
                  [nodeClicked]="nodeClicked" 
                  [dragScope]="dragScopeTreeView"
                  [scrollHeight]=" scrollHeight" 
                  [rowHeight]="treeNodeHeight" 
                  [isZaitaku]="true"
                  (nodeClick)="handleItemClick($event)"  
                  (leafNodeClick)="onClickLeafNode($event)">
                 </pivot-lazy-tree-view>
                   
                </div>
                  
                <span class="p-listbox-empty-message" *ngIf="dataFilters && dataFilters.length === 0 && isGroup">
                    {{COMMON_TEXT.NO_DATA}}
                </span>
            </div>
        </ng-template>
    </p-overlayPanel>
</div>