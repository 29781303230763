<div class="spliter-panel-template">
    <div class="spliter-header">
      <div class="spliter-action left-action">
        <pivot-text-button class="back-button" [iconType]="iconType.BACK" [buttonText]="BUTTON.RETURN" 
        (click)="backPage()" [buttonType]="buttonType.SECONDARY"> 
        </pivot-text-button>
        <div class="edit-name">
          <pivot-textbox [inputParams]="nameParams" (onFocusOut)="onInputName($event)" [inputValue]="name"></pivot-textbox>
        </div>
      </div>
      <div *ngIf="isDisplayToolTip" class="title-page" [pTooltip]="titlePage" tooltipPosition="bottom"> {{titlePage}} </div>
      <div *ngIf="!isDisplayToolTip" class="title-page"> {{titlePage}} </div>
      <div class="spliter-action right-action">
        <ng-content select="[top-buttons]"></ng-content>
        <i *ngIf="isDisplayControlIcon" id="control-icon"  class="pi pi-ellipsis-h control-icon"
        (click)="openControlPopup()"></i>
        <div id="control-popup" *ngIf="showControlPopup" class="control-popup" >
          <ng-content select="[control-popup]"></ng-content>
      </div>
      </div>
    </div>
    <div [ngClass]="isDisplayDSSetting? 'body-wrapper-ds-setting' : 'body-wrapper'">
      <div id="pannel-wrapper" *ngIf="isDisplayPannel" class="pannel " [ngClass]="{'expanded': isExpanded}" style="width: 100% !important;">
        <div class="container content-wrapper">
          <div *ngIf="dockerList.length" class="docker-wrapper">
            <div (click)="showDocker(docker.pannel)" *ngFor="let docker of dockerList" class="docker-item">
              <span class="bold-text">{{docker.name}}</span>
              <i class="pi pi-chevron-right docker-icon"></i>
            </div>
          </div>
          <p-splitter 
              [styleClass]="'margin-main-panel'"
              [style]="{ height: 'calc(100vh - 146px)' , position: 'relative', 
              border: 'unset'}" 
              [gutterSize]="0"
              [minSizes]="minSizeLefPanel"
              [panelSizes]="panelLeftSize" 
              (onResizeStart) = "onResizePanelStart($event)"
              (onResizeEnd) = "onResizePanelLeft($event)">
              <ng-template pTemplate>
                  <div class="left-content left-pannel pannel-content" id="panelLeft">
                    <ng-content select="[left-pannel]"></ng-content>
                  </div>
              </ng-template>
              <ng-template pTemplate>
                <p-splitter
                    [style]="{ height: 'calc(100vh - 146px)' , position: 'relative'}" 
                    [gutterSize]="0"
                    [minSizes]="minSizeRightPanel"
                    [panelSizes]="panelRightSize"
                    (onResizeStart)= "onResizePanelStart($event)"
                    (onResizeEnd) = "onResizePanelRight($event)">
                    <ng-template pTemplate>
                        <div class="center-content pannel-content" id="panelCenter">
                          <ng-content select="[right-pannel]"></ng-content>
                        </div>
                    </ng-template>
                    <ng-template pTemplate>
                        <div class="right-content" id="panelRight">
                          <ng-content select="[body-content]"></ng-content>
                        </div>
                    </ng-template>
                  </p-splitter>
              </ng-template>
          </p-splitter>
        </div>
    </div>
    </div>
    </div>
  