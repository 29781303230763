import { Injectable, Inject, PLATFORM_ID, EventEmitter } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';

function _window(): any {
    return window;
  }

@Injectable({providedIn: 'root'})
export class WindowService {
  currentWidget  = new EventEmitter<string>();
  isRunning = new EventEmitter<boolean >()
  constructor(@Inject(PLATFORM_ID) private platformId: object) { }
  get nativeWindow(): any {
    if (isPlatformBrowser(this.platformId)) {
      return _window();
    }
  }
    
}