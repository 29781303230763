<pivot-modal-template class="title-select-modal form-modal" [modalTemplate]="modalTemplate"
  (closeModalHandler)="cancel()">
  <div class="title-select-body" modal-body>
    <div class="controls">
      <pivot-search-bar [searchParams]="searchParams" (onFilterData)="onFilterTitle($event)"></pivot-search-bar>
    </div>
    <pivot-table class="table" [data]="tableData" [isLoading]="loadingTable" 
    [selectedRows]="titleSelecteds" (checkRows)="checkRowsTable($event)"></pivot-table>
  </div>
  <div class="title-selec-bottom" modal-bottom>
    <pivot-text-button (btnClick)="cancel()" [buttonType]="buttonType.SECONDARY" [buttonText]="BUTTON.CANCEL">
    </pivot-text-button>
    <pivot-text-button (btnClick)="save()" [disabled]="titles.length === 0" [buttonType]="buttonType.SECONDARY"
      [buttonText]="BUTTON.SELECT"></pivot-text-button>
  </div>
</pivot-modal-template>