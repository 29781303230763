<div class="crate-wdg-tpl" id="create_template">
    <div class="panel-left">
        <div class="title-text">{{COMMON_TEXT.CREATE_WIDGE_TEMPLATE}}</div>
        <div class="back-grp">
            <button class="btn-back" (click)="backPage()">
                <i class="pi pi-chevron-left"></i>
                <label>{{BUTTON.RETURN}}</label>
            </button>
            <label class="lbl-report">{{COMMON_TEXT.REPORT_CREATE}}</label>
        </div>
        <div class="group-items" [ngClass]="isValidateOffice ? 'border-error' : ''">
          <div class="items" [ngClass]="isValidateOffice ? 'item-border-error' : ''">
            <div class="image-item office-icon"></div>
            <div class="label-content">
                <label class="lbl-text">{{COMMON_TEXT.OFFICE}}： <span>{{officeLabel}}</span></label>
                <label [ngClass]="isValidateOffice? 'lbl-error' : ''">{{COMMON_TEXT.SELECT_OFFICE}}</label>
            </div>
            <button class="btn-frm" (click)="showDialogSelectOffice()">{{COMMON_TEXT.CHOICE}}</button>
          </div>
        </div>

        <div class="group-items" [ngClass]="isValidatePeriod? 'border-error' : ''">
            <div class="items" [ngClass]="isValidatePeriod? 'border-error' : ''">
                <div class="image-item calendar-icon"></div>
                <div class="date-content">
                    <div class="date-frm">
                        <label class="min-text period-lbl">{{COMMON_TEXT.PERIOD}}</label> 
                        <pivot-calendar
                            class="input-date"
                            [appendTo]="'body'"
                            [dateFormat]="dateFormat"
                            [(value)]="startDate"
                            (valueChange)="onChangeDate($event, 1)"
                            [disabled]="isDisableRangeDate"
                            [canClear]="false">
                        </pivot-calendar>
                        <span>~</span>
                        <pivot-calendar
                            class="input-date custom-date-picker"
                            [appendTo]="'body'"
                            [dateFormat]="dateFormat"
                            [(value)]="endDate"
                            (valueChange)="onChangeDate($event, 2)"
                            [disabled]="isDisableRangeDate"
                            [canClear]="false">
                        </pivot-calendar>
                    </div>
                    <label class="office-label">{{'【'+ periodName +'】'+ COMMON_TEXT.SELECT_PERIOD}}</label>
                    <label *ngIf="isValidatePeriod" class="lbl-error">{{CREATE_DASHBOARD_TEMPLATE.CUSTOM_PERIOD_ERROR}}</label>
                </div>
                <button class="btn-frm" (click)="period.toggle($event)">{{COMMON_TEXT.CHOICE}}</button>
            </div>
          </div>
          <div class="group-items" [ngClass]="isValidateDtsFolder? 'border-error' : ''">
            <div class="items" [ngClass]="isValidateDtsFolder ? 'item-border-error' : ''">
                <div class="image-item datasource-icon"></div>
                <div class="label-content exist-dts">
                    <label class="lbl-text">{{CREATE_DASHBOARD_TEMPLATE.DATA_SOURCE_DESTINATION}}： <span class="content-label">{{selectedDtsFolder || !isOptionCreate ? datasourceNm : ''}}</span></label>
                    <label [ngClass]="isValidateDtsFolder ? 'lbl-error' : ''">{{CREATE_DASHBOARD_TEMPLATE.SELECT_DATASOURCE_DESTINATION}}</label>
                    <label class="lbl-error lbl-exist">{{isShowMsgExistDs? COMMON_TEXT.DATASOURCE_EXIST : '' }}</label>
                </div>

                <button class="btn-frm btn-bottom" (click)="showDialogDatasource()">{{COMMON_TEXT.CHOICE}}</button>
            </div>
          </div>
          <div class="group-items" [ngClass]="isValidateFolder? 'border-error' : ''">
            <div class="items" style="justify-content: start" [ngClass]="isValidateFolder ? 'item-border-error' : ''">
                <div class="image-item folder-icon"></div>
                <div class="label-content">
                    <label class="lbl-text">{{COMMON_TEXT.WIDGET_DESTINATION}}： <span class="content-label">{{selectedFolder ? selectedFolder.name : ''}}</span></label>
                    <label [ngClass]="isValidateFolder ? 'lbl-error' : ''">{{COMMON_TEXT.SELECT_FOLDER_TO_VIEW}}</label>
                </div>
                <button class="btn-frm btn-bottom" (click)="showDialogSelectWidgetFolder()">{{COMMON_TEXT.CHOICE}}</button>
            </div>
          </div>
          <div class="group-items" [ngClass]="isValidateDashboard? 'border-error' : ''">
            <div class="items" [ngClass]="isValidateDashboard ? 'item-border-error' : ''">
                <div class="image-item dashboard-icon"></div>
                <div class="label-content">
                    <label class="lbl-text">{{COMMON_TEXT.DASHBOARD_DESTINATION}}： <span class="content-label">{{dashboardSelected?.name || ''}}</span></label>
                    <label [ngClass]="isValidateDashboard? 'lbl-error' : ''">{{COMMON_TEXT.SELECT_DASHBOARD_TO_VIEW}}</label>
                </div>
                <button class="btn-frm btn-bottom" (click)="showDialogDashboardSelect()">{{COMMON_TEXT.CHOICE}}</button>  
            </div>
          </div>
          <div class="folder-icon"></div>
    </div>
    <div class="panel-right">
        <p-splitter #splitter
            [style]="{ height: '100%' , border: 'none'}" 
            styleClass="mb-5" 
            [gutterSize]="1"
            layout="vertical"
            [panelSizes]="[50, 50]"
            (onResizeStart) = "onResizeStart($event)"
            (onResizeEnd) = "onResizePanel($event)">
                <ng-template pTemplate>
                    <div class="panel-top">
                        <div class="config-chart">
                            <pivot-text-button class="btn-config"  *ngIf="(chartData && widget.charttype == GraphType.LINE_BAR_COMBINE_CHART)" [buttonType]="buttonType.SECONDARY" [buttonText]="BUTTON.GRAPH_SETTINGS" 
                                (click)="showGraphsetting()">
                            </pivot-text-button>
                            <div *ngIf="(chartData && widget.charttype == GraphType.STACKED_BAR)"  class="dlgu-content">
                                <pivot-switchbutton  [checked]="isStackedChartSetting" (onClick)="onClickSwitchButton()" class="switch-element" ></pivot-switchbutton>
                                <p class="dlgu-txt">{{WIDGET_SETTING.SWITCH_COLUMN_ROW}}</p>
                            </div>
                            <div *ngIf="(chartData && widget.charttype == GraphType.TABLE_CHART)"  class="dlgu-content">
                                <pivot-switchbutton  [checked]="!isKeepOriginTable" (onClick)="onClickSwitchButton(true)" class="switch-element" ></pivot-switchbutton>
                                <p class="dlgu-txt">{{WIDGET_SETTING.MERGE_TABLE_CELL}}</p>
                            </div>
                            <pivot-text-button  class="btn-setting" [buttonType]="buttonType.SECONDARY" [buttonText]="COMMON_TEXT.SETTING"
                                (click)="onshowDialogConfirm()">
                            </pivot-text-button>
                        </div>
                        <div class="display-chart" *ngIf="isShowChartData">
                            <pivot-chart	
                                [widgetId]="widget?.widgetcd || ''" 
                                [widgetdetails]="widget?.widgetdetails || []"
                                class="default-width"
                                [sortParams]="widget.sortArr || []" 
                                [widgetResult]="widget.widgetResult"  
                                [isLoadWidgetResultComplete]="widget.isLoadWidgetResultComplete"
                                [type]="widget.type" 
                                [width]="widget.width" 
                                [height]="widget.height" 
                                [officeList]="[]" 
                                [tableData]="widget.type == GraphType.TABLE_CHART? tableData: null"
                                [chartData]="widget.type != GraphType.TABLE_CHART ? chartData : null"
                                [isStackedChartSetting]="isStackedChartSetting"
                                [onShowGraphSettings]="showGraphSetting"
                                [graphConfig]="graphConfig"
                                (onRenderComplete)="onRenderChartComplete()" 
                                (onSaveChartConfig)="onSaveChartConfig($event)"
                                (onStartRender)="onStartRenderChart()"
                                [widgetConfig]="widget.widgetConfig">
                            </pivot-chart>
                        </div>
                    </div>
                </ng-template>
                <ng-template pTemplate>
                    <div class="panel-bottom" id="settingChart">
                        <div class="header-title">{{COMMON_TEXT.WIDGET_SETTING}}</div>
                        <div class="setting-graph" [ngStyle]="{ height: panelBottomHeight + 'px' }">
                            <div class="setting-chart">
                                <label>{{COMMON_TEXT.WIDGET_INFOMATION}}</label>
                                <div class="widget-info">
                                    <span class="lbl-info">{{COMMON_TEXT.WIDGET_NAME}}: </span>
                                    <pivot-textbox class="custom-widget-name"  [inputParams]="inputDesParams" (onInput)="onChangeWidgetName($event)" [inputValue]="widget.name"></pivot-textbox>
                                    <span class="lbl-info">{{COMMON_TEXT.WIDGET_DESC}}: </span> 
                                    <textarea id="autoResizeDes" class="widget-info--content" 
                                    maxlength="200"
                                    (input)="resizeTextArea('autoResizeDes')" 
                                    (blur)="onChangeWidgetDesc($event)" [(ngModel)]="widget.widgetdesc">
                                </textarea>
                                </div>

                                <label>{{COMMON_TEXT.GRAPH_TYPE}}</label>
                                <div class="setting-chart-group-btn">
                                    <div class="setting-chart-btn"  *ngFor="let item of itemCharts" 
                                        [ngClass]="widget.type == item.value ?'chart-ative': ''" 
                                        (click)="onChangeChartType(item.value)">
                                        <div class="image chart-item" [ngClass]="item.class">
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-template>
        </p-splitter>
    </div>
</div>

<p-overlayPanel #period [styleClass]="'period-custom-overlay'">
    <ng-template pTemplate>
        <p-listbox [options]="groupedPeriod" optionLabel="label" [group]="true"
            [(ngModel)]="optionSelected"
            (onClick)="onClickPeriod($event)"
            (onChange)="onSelectedPeriod($event.value)">
            <ng-template let-group pTemplate="group">
                <div class="p-d-flex p-ai-center">
                  <span>{{group.label}}</span>
                </div>
              </ng-template>
              <ng-template let-option pTemplate="item">
                <div class="option-item period-item-data-source">{{option.label}}</div>
              </ng-template>
        </p-listbox>
    </ng-template>
</p-overlayPanel>

<pivot-dialog-confirm-save-data *ngIf="isShowDlgConfirm" [data]="confirmModalData" (onSubmitData)="confirmSaveData($event)"></pivot-dialog-confirm-save-data>

<pivot-office-service
    [actionLog]="SAUCER_LOG_ACTION.MENU_TEMPLATE.BUSINESS_LOCATION_SELECTION_DIALOG"
    *ngIf="isDspOfficeModal" 
    [selectedOfficeServices]="officeSelecteds"
  (closeModalHandler)="closeOfficeModal()" (selectedOfficeHandler)="selectedOffice($event)" (selectAllOfficeHandler) = "selectAllOffice($event)">
</pivot-office-service>

<pivot-dashboard-service *ngIf="isDspDashboardModal" [selectedDashboardServices]="dashboardSelecteds" [data]="dashboardServiceData"
  (closeModalHandler)="closeDashboardModal($event)" (selectedOfficeHandler)="selectedDashboardData($event)">
</pivot-dashboard-service>

<pivot-dialog-select-datasource *ngIf="isDspDatasourceModal" [data] = "datasourceData" (closeModalHandler)="closeDataSourceModal($event)">
</pivot-dialog-select-datasource>

<pivot-dialog-folder-widget [folderOwners]="folderWidgets" *ngIf="isDspWidgetFolderModal" [selectedWidgetFolder]="selectedFolder"
    (closeModalHandler)="closeWidgetFolderModal($event)" (selectedFolderHandler)="selectedWidgetFolderData($event)">
</pivot-dialog-folder-widget>