<div class="pivot-filter-table">
    <div class="p-listbox-header">
        <p-checkbox 
            [(ngModel)]="selectAllChecked"
            [binary]="true"
            (onChange)="selectAllOptions($event)">
        </p-checkbox>
        <div class="p-listbox-filter-container">
            <input 
                class="p-listbox-filter p-inputtext p-component" 
                type="text" 
                pInputText
                [placeholder]="placeholderText" 
                (input)="onFilter($event)" />
            <span class="p-listbox-filter-icon pi pi-search"></span>
        </div>
    </div>
    <div class="scroll-container" [style.height]="scrollHeight">
        <div class="empty-text" *ngIf="filteredOptions.length === 0">{{emptyFilterMessage}}</div>
        <p-scroller [items]="filteredOptions" [itemSize]="36" scrollHeight="300px" [showLoader]="true" >
            <ng-template pTemplate="item" let-option>
                <div class="scroll-item">
                    <p-checkbox 
                        [(ngModel)]="option.checked"
                        [label]="option.name"
                        [binary]="true"
                        (onChange)="onItemChange(option, $event)">
                    </p-checkbox>
                </div>
            </ng-template>
        </p-scroller>
    </div>
</div>