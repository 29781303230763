<div class="pivot-help-detail-dialog">
  <pivot-modal-template *ngIf="showDialog" [modalTemplate]="modalTemplate" class="dlg-del-body">
    <div modal-body>
      <div class = "title">
        {{data.title}}
      </div>
      <div class="body">
        <markdown class="m-0 text-break-spaces view-content" [data]="displayContent" appTargetBlank></markdown>
      </div>
    </div>
    <div modal-bottom class="dlg-help-content">
      <div class="dlg-help-div-checkbox" *ngIf="isShowChecbox">
        <pivot-checkbox [value] = "isCheck" (onClick)="checkChange()"></pivot-checkbox>
        <div class="lb-checkbox">{{HELP_LIST.HIDE_POPUP}}</div>
      </div>
      <div class="dlg-help-div-btn">
        <pivot-text-button class="dlg-btn" (btnClick) = "onClickClose()" [buttonText]="'閉じる'" [buttonType]="buttonType.SHARE">
        </pivot-text-button>
      </div>
    </div>
  </pivot-modal-template>
</div>