import { Component, Input, OnInit } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { ButtonItem, ModalParam } from '../../../../app/models/common-model';
import { ButtonType } from 'src/app/enum/common-enum';

@Component({
  selector: 'pivot-dialog-modal',
  templateUrl: './dialog-modal.component.html',
  styleUrls: ['./dialog-modal.component.scss']
})
export class DialogModalComponent implements OnInit {
  @Input() modalParam: ModalParam | undefined;

  constructor(private config: DynamicDialogConfig,
    private ref: DynamicDialogRef) { 
    this.modalParam = this.config.data.modalParam;
  }

  ngOnInit(): void {
  }

  handleClickEvent(callBack: any) {
    if(!callBack) this.ref.close();
    else {
      this.ref.close();
      callBack();
    }
  }
  
  containsHyperlinkButton = (array: ModalParam["buttonArray"]) => array ? array.some(item => item instanceof ButtonItem && item.buttonType === ButtonType.HYPERLINK) : false;
}
