import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { ListboxModule } from 'primeng/listbox';
import { FilterDialogComponent } from './filter-dialog/filter-dialog.component';
import { SearchBarComponent } from './search-bar.component';
// Components

const libComponent: any = [
    FilterDialogComponent,
    SearchBarComponent
];

const libsModules = [
    ListboxModule,
    CommonModule,
    FormsModule,
    OverlayPanelModule
];

@NgModule({
    declarations: [...libComponent],
    imports: [
        [...libsModules]
    ],
    providers: [],
    exports: [
        [...libComponent],
        [...libsModules]
    ]
})
export class SearchBarModule { }
