import { BaseModel } from "../api-model";

export class CorpMstModel extends BaseModel{
    corpcd: string;
    contentcd: string;
    content: string;
    value: string;
}

export class CodeMstModel {
    id: string;
    classcd: string;
    itemcd: string;
    itemnm: string;
    itemnmr: string;
    sortno: number;
}