import { Component, EventEmitter, OnChanges, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { MESSAGE_TEXT } from '../../../../app/const/message';
import { BUTTON, COMMON_TEXT, DATASOURCE_SETTING } from '../../../../app/const/text-common';
import { ButtonType, SaveType } from '../../../../app/enum/common-enum';
import {  ListDatasourceMix, ModalTemplate } from '../../../../app/models/common-model';

@Component({
  selector: 'pivot-dialog-combine-information',
  templateUrl: './dialog-combine-information.component.html',
  styleUrls: ['./dialog-combine-information.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class DialogCombineInformationComponent implements OnInit, OnChanges {
  @Output() closeModalHandler = new EventEmitter();
  buttonType = ButtonType;
  saveType = SaveType;
  BUTTON = BUTTON;
  MESSAGE_TEXT = MESSAGE_TEXT;
  DATASOURCE_SETTING = DATASOURCE_SETTING;
  modalData: ModalTemplate = new ModalTemplate();
  
  nameDatasourceType: string
  isYotei: boolean = false; 
  isDSMaster: boolean = false; 
  inforData : ListDatasourceMix[] = [];
  inforDataHaveIconNew: string[] = [];

  constructor(public ref: DynamicDialogRef,
    public config: DynamicDialogConfig) {
      this.config.showHeader = true;
      if (this.config.data.isMater){
        this.config.style = { 
          width: '30%',
          header: COMMON_TEXT.COMBINE_INTELLIGENT
        };
      }else {
        this.config.style = { 
          width: '25%',
          header: COMMON_TEXT.COMBINE_INTELLIGENT
        };
      }
     
     }

  ngOnChanges(): void {
  }

  ngOnInit(): void {
    this.nameDatasourceType = this.config.data.nameDatasourceType;
    this.isYotei = this.config.data.isYotei;
    this.isDSMaster = this.config.data.isMater;
    let inforData = JSON.parse(this.config.data.listInfor);
    let inforDataItem = inforData.datasources
    const combinedInforData = [];
    const inforDataWithoutIconNew = [];
    for (let i = 0; i < inforDataItem.length; i++) {
        const item = inforDataItem[i];
        item.iconNew = !this.isOldTemplate(item.date);
        if (inforDataItem[i].iconNew) {
            combinedInforData.push({
                name: inforDataItem[i].name,
                iconNew: inforDataItem[i].iconNew
            });
        } else {
            inforDataWithoutIconNew.push({
              name : inforDataItem[i].name, 
              iconNew: inforDataItem[i].iconNew
            });
        }
    }
    const combinedInforDataNames = combinedInforData.map(item => ({ name: item.name, iconNew: item.iconNew }));
    this.inforData = inforDataWithoutIconNew.concat(combinedInforDataNames);
  }

  onSubmit(data: number) {
    this.ref.close(data);
  }

  isOldTemplate(upddate: string): boolean {
    const itemUpddate = new Date(upddate);
    itemUpddate.setHours(0,0,0,0);
    const currentDate = new Date();
    currentDate.setHours(0,0,0,0);
    const maxUpddateAllowToShowIcon = new Date();
    //calc date that is 30 day ago => max time to display "new icon"
    maxUpddateAllowToShowIcon.setDate(currentDate.getDate() - 29);
    maxUpddateAllowToShowIcon.setHours(0,0,0,0);
    //if itemUpddate <  thirtyDaysAgo(widget or dashboard hasn't update anything since 30 days ago) => return true
    return itemUpddate < maxUpddateAllowToShowIcon;
  }

}
