<div class="container-login">
    <div class="box" [ngClass]="{ 'box-mobile':  deviceType == DeviceType.MOBILE || deviceType == DeviceType.TABLET }">
        <div class="title">
            <img src="assets/images/pivotlogo_login.svg">
        </div>
        <div class="content">
            <label class="error-msg" *ngIf="hasErr">{{LOGIN.LOGIN_ERR_MSG}}</label><br>
            <div class="login-title" [ngClass]="{'macos-font': currentOS === OS.Mac, 'windows-font': currentOS === OS.Windows}">
                {{CORP_SELECTION.LABEL}}
            </div>
            <div class="corp-list">
                <pivot-text-button *ngIf="templateCorp" class="corp-button" [buttonType]="buttonType.SECONDARY"
                    [buttonText]="LOGIN.TEMPLATE_SETTING" (btnClick)="onChoose(templateCorp, true)">
                </pivot-text-button>
            
                <ng-container *ngFor="let corp of authcorplist;">
                    <pivot-text-button class="corp-button" [buttonType]="buttonType.SECONDARY" [buttonText]="corp.organizationname"
                        (btnClick)="onChoose(corp, false)">
                    </pivot-text-button>
                </ng-container>
            </div>
        </div>
    </div>
</div>