<pivot-modal-template class="widget-destination form-modal" [modalTemplate]="modalTemplate"
(closeModalHandler)="cancel()" >
<div class="widget-destination-body" modal-body>
  <div class="controls">
    <div *ngIf="isLoading" class="loading">
      <div class="loader"></div>
    </div>
    <div class="row">
      <label class="lbl-text">{{COMMON_TEXT.FOLDER}}</label>
      <div class="item-grp">
          <pivot-search-bar [filterLog]="SAUCER_LOG_ACTION.MENU_TEMPLATE.WIDGET_FOLDER_DIAGLOG.WIDGET_FOLDER_SEARCH"
            class="search-bar" [searchParams]="searchFolderParams" (onFilterData)="onFilterFolder($event)"
            [placeHolderFilter]="CREATE_DASHBOARD_TEMPLATE.SEARCH_FOLDER">
          </pivot-search-bar>
          <p-button icon="pi pi-plus" [ngClass]="'button-add'" (onClick)="showDialogAddFolder()"> </p-button>
      </div>
    
    </div>
  </div>
</div>
<div class="widget-destination-bottom" modal-bottom>
  <pivot-text-button (btnClick)="cancel()" [buttonType]="buttonType.SECONDARY" [buttonText]="BUTTON.CANCEL"></pivot-text-button>
  <pivot-text-button (btnClick)="save()" [disabled]="!selectedWidgetFolder" [buttonType]="buttonType.SECONDARY" [buttonText]="BUTTON.SELECT"></pivot-text-button>
</div>
</pivot-modal-template>

<pivot-dialog-folder-editor [folderActionLog]="SAUCER_LOG_ACTION.MENU_TEMPLATE.WIDGET_FOLDER_DIAGLOG"
  [isDisplayModal]="isDspFolder" [folderType]="folderType" [modalData]="modalFolder" 
  (onSubmitData)="onChangeDataFolder($event)">
</pivot-dialog-folder-editor>