import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import {ProcessLoadingService} from '../../services/loading.service';
import {ButtonType} from '../../enum/common-enum';
import {ModalTemplate} from '../../models/common-model';
import {ErrorHandleService, errorDialogTemplate} from '../../services/error-handle.service';
import Utils from '../../util/utils';

@Component({
  selector: 'pivot-error-details-dialog',
  templateUrl: './error-details-dialog.component.html',
  styleUrls: ['./error-details-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ErrorDetailsDialogComponent implements OnInit, OnDestroy {

  apiResponse: any;
  modalData: ModalTemplate =  {
    isShowHeader: true,
    header:  "エラー",
    isDismissableMask: false,
    isModal: true,
    breakpoints: {},
    style: {},
    hasBottom: true,
    isResizable: false,
    closable: true,
    appendTo: '',
    styleClass: '',
    closeOnEscape: true,
  };
  buttonType = ButtonType;
  backURL: string = "";
  constructor(
    private errorHandleService: ErrorHandleService,
    private cdr: ChangeDetectorRef,
    private processLoadingService: ProcessLoadingService,
  ) { 
   
  }

  ngOnInit(): void {
    this.checkPageLoadingHandler();
  }
  
  ngOnDestroy(): void {
    this.backURL = "";
  }

  checkPageLoadingHandler(): void {
    this.errorHandleService.responseValue
      .subscribe((data: any) => {
        if(data) {
          this.apiResponse = data;
        }
      });
    this.errorHandleService.backURL.subscribe((url: string) => {
      this.backURL = url;
    })
  }

  closeDialog() {
    //close dialog and send default status => just close dialog
    let res: errorDialogTemplate = {
      statuscode: 9999,
      title: "",
      subtitle: "",
      isDisplayErrorDialog: false,
      content: "",
      functionMessage: "",
    };
    this.errorHandleService.setIsCloseErrorDialogOrErrorScreen(true);
    this.errorHandleService.responseValueSub.next(res);
    this.errorHandleService.setFunctionTitle("");
  }

}
