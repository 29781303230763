import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'pivot-dialog-header',
  templateUrl: './dialog-header.component.html',
  styleUrls: ['./dialog-header.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class DialogHeaderComponent implements OnInit {

  @Input() header = '';
  @Output() closeEventHandler = new EventEmitter();

  constructor() { }

  ngOnInit(): void {
  }

  onCloseDialogHeader(): void {
    this.closeEventHandler.emit(true);
  }

}
