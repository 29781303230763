import { groupBy } from "lodash";
import { InvisibleColumn } from "../const/const";
import { bycd, TreeNode } from "../models/common-model";
import StringUtils from "./stringUtils";

export default class TreeUtils {
    /**
     * using recursive to find top parent node of any node
     * call it self to go uppper in the same branch until a node has no parent
     * need parent because we want to display a whole branch when search
     * @param node 
     * @returns 
     */
    public static searchTopParentOfNode(node: TreeNode): TreeNode {
        if (!node.parent) {
            return node;
        }

        return this.searchTopParentOfNode(node.parent);
    }

    /**
     * using recursive to search any tree node by its label
     * if any node in a branch (parent or child) has label includes search keyword then return
     * @param node 
     * @param searchTerm 
     * @returns 
     */
    public static searchTreeNode(node: TreeNode, searchTerm: string): TreeNode | null {
        if (this.checkTreeNodeLabel(node, searchTerm)) {
            return node;
        }

        if (node.nodes) {
            for (const item of node.nodes) {
                const res = this.searchTreeNode(item, searchTerm);
                if (res) {
                    return res;
                }
            }
        }

        return null;
    }

    /**
     * check if a node label includes search keyword
     * must trim and convert to full width JP
     * @param node 
     * @param searchTerm 
     * @returns 
     */
    public static checkTreeNodeLabel(node: TreeNode, searchTerm: string): boolean | undefined {
        return StringUtils.toFullWidthJP(node.label?.trim().toUpperCase())
            .includes(StringUtils.toFullWidthJP(searchTerm.trim().toUpperCase()));
    }

    /**
     * 
     * @param node Create parent property for tree node to browse the tree from child
     * @param parent 
     */
    public static assignTreeParent(node: TreeNode, parent: TreeNode | undefined) {
        if (!node.parent) {
          node.parent = parent;
        }
    
        if (node.nodes) {
          for (const item of node.nodes) {
            this.assignTreeParent(item, node);
          }
        }
      }

      public static arrayToTreeNode = (data: any[], groupCD: bycd[], selectedCD: string[] = [], parentNode?: TreeNode): TreeNode[] => {
        let nodes: TreeNode[] = []
        let groupX: bycd | undefined = groupCD.shift()
      
        if (groupX) {
          const { cd, labelcd }: bycd = groupX
          const x = groupBy(data, cd)
          const isLastNode = groupX.isLastestNode ?? !groupCD.length
      
          for (const key in x) {
            const selected = selectedCD.includes(key)
            let y: TreeNode = {
                id: key,
                label: x[key][0][labelcd as string],
                isLoaded: groupX.isLoaded ?? false,
                isShowIcon: groupX.showIcon || false,
                isShowCheckBox: groupX.checkbox || false,
                data: x[key],
                isLastNode: isLastNode,
                draggable: isLastNode,
                singleSelected: selected,
                // groupX.isLastestNode is custom setting for the lastest node
                hidden: (isLastNode && !groupX.isLastestNode) ? (InvisibleColumn[x[key][0].columnname] || false) : false,
            }
      
            y.nodes = this.arrayToTreeNode(x[key], [...groupCD], selectedCD, y)
      
            selected && parentNode && (parentNode.expanded = true)
      
            nodes.push(y);
          }
          if(isLastNode) {
            let notTDDNodes = nodes.filter(el => !el.id?.toUpperCase().startsWith("TTD_"));
            let TTDNodes = nodes.filter(el => el.id?.toUpperCase().startsWith("TTD_"));
            if(TTDNodes.length) {
              let orderTTDNodes = TTDNodes.sort((a: any, b: any) => {
                const numberA = parseInt(a.id.split("_")[1]);
                const numberB = parseInt(b.id.split("_")[1]);
                return numberA - numberB;
              })
      
              nodes = [...orderTTDNodes, ...notTDDNodes]
            }
          }
      
          return nodes
        }
      
        return []
      }
}