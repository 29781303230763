<pivot-multiple-pannel-template [nameParams]="nameParams" [titlePage]="titlePage" [isDisplayPannel]="false" [isDisplayToolTip]="isDisplayToolTip"
	[name]="dashboardName" (nameFieldHandler)="getDashboardName($event)" (backPageHandler)="backPage()"
	[isDisplayControlIcon]="mode === ScreenMode.PREVIEW ? true : false">
	<div top-buttons *ngIf="mode === ScreenMode.PREVIEW">
		<pivot-text-button class="back-button" [buttonText]="BUTTON.EDIT" [disabled]="checkPermission"
			[buttonType]="ButtonType.SECONDARY" (click)="handleUpdateBtnClick()">
		</pivot-text-button>
	</div>
	<div class="dashboard-top-buttons" top-buttons *ngIf="mode === ScreenMode.EDIT">
		<pivot-text-button class="back-button" [buttonType]="ButtonType.SECONDARY" [buttonText]="BUTTON.ADD_WIDGET"
			(click)="handleCreateBtnClick()">
		</pivot-text-button>
		<pivot-text-button class="back-button" [disabled]="isDisabledButton()" [buttonText]="BUTTON.SAVE"
			(click)="handleSaveBtnClick()">
		</pivot-text-button>
	</div>
	<div class="dashboard-top-buttons" top-buttons *ngIf="mode === ScreenMode.ADD">
		<pivot-text-button class="back-button" [buttonType]="ButtonType.SECONDARY" [buttonText]="BUTTON.ADD_WIDGET"
			(click)="handleCreateBtnClick()">
		</pivot-text-button>
		<pivot-text-button class="back-button" [disabled]="isDisabledButton()" [buttonText]="BUTTON.SAVE"
			(click)="handleSaveBtnClick()">
		</pivot-text-button>
	</div>
	<div class="dashboard-controls-popup" control-popup>
		<label class="dashboard-controls-popup--item" (click)="onDownloadImage()">{{DASHBOARD_SETTING.CP_DOWNLOAD}}</label>
		<label class="dashboard-controls-popup--item" (click)="onDuplicateDashboard()">{{BUTTON.COPY}}</label>
		<label class="dashboard-controls-popup--item" (click)="onNewDashboard()">{{DASHBOARD_SETTING.CP_NEW}}</label>
		<div class="dashboard-controls-popup--item" [ngClass]="checkPermission ? 'disable-del-option' : ''"
			(click)="onDeleteDashboard()">{{BUTTON.DELETE}}</div>
	</div>
	<div body-content>
		<div class="dashboard-setting">
			<div class="dashboard-setting-container">
				<div class="dashboard-setting-container--body">
					<gridster [options]="options" #mygridster id="dashboardSettingDownload">
						<ng-container *ngIf="dashboardSelected">
							<gridster-item [item]="widget" *ngFor="let widget of dashboardSelected.setting"
								(itemResize)="widgetResize($event)" (itemChange)="widgetChange($event)" 
								alight-items = "center"
								preserveAspectRatio="xMinYMid meet"
								>
								<div class="gridster-item-content" [id]="widget.widgetCd" (click)="onGetWidgetSelect(widget)">
									<div class="gridster-item-content--header">
										<label class="widget-title">{{ getWidgetTitle(widget.widgetCd) }}</label>
										<div class="widget-btn-group">
											<i class="pi pi-pencil pencil" *ngIf="mode != ScreenMode.PREVIEW"
												(click)="editWidgetSetting($event, widget)"></i>
											<i class="pi pi-times times" *ngIf="mode != ScreenMode.PREVIEW"
												(touchstart)="$event.stopPropagation()"
												(mousedown)="$event.stopPropagation()"
												(click)="handleRemoveWidgetClick($event, widget)"></i>
										</div>
									</div>
									<ng-container>
										<pivot-chart 
											*ngIf="widget.isHaveSize === false"
											[filterLog]="dashboardSettingActionLog.FILTER_WIDGET"
											[sortLog]="dashboardSettingActionLog.SORT_WIDGET"
											[widget]="widget"
											[widgetId]="widget.widgetCd" 
											[widgetdetails]="widget.widgetdetails"
											[sortParams]="widget.sortArr"
											[widgetResult]="widget.widgetResult"
											[isLoadWidgetResultComplete]="widget.isLoadWidgetResultComplete"  
											[type]="widget.type" 
											[width]="widget.width" 
											[height]="widget.height"
											[widgetConfig]="widget.widgetConfig"
											(handSortData)="handleSortColData($event)" >
										</pivot-chart>
										<div *ngIf="isLoading" class="loading">
											<div class="loader"></div>
										</div>
									</ng-container>
									<div class="gridster-item-content--description" *ngIf="widget.isHaveSize === false">
										<label class="lbl-date">【{{widget.startdate}}{{COMMON_TEXT.TILDE}}{{widget.enddate}}】</label>
										<label class="lbl-description" [pTooltip]="getWidgetDescription(widget.widgetCd)">{{getWidgetDescription(widget.widgetCd)}}</label>
									</div>
								</div>
							</gridster-item>
						</ng-container>
					</gridster>
				</div>
			</div>
		</div>
	</div>
</pivot-multiple-pannel-template>

<pivot-confirm-del-selected-dialog *ngIf="isDisplayConfirmDeleteModal" [isDisplayModal]="isDisplayConfirmDeleteModal" [modalData]="modalData"
	(onSubmitData)="onConfirmDeleteDlg($event)" [deleteItems]="nameDashboarDelete" [inputData]="delTxt">
</pivot-confirm-del-selected-dialog>

<pivot-dialog-dashboard-editor *ngIf="isCreate || isDuplicate" [data]="newDashboard" [isDisplayModal]="isDisplayDashboardModal" [folders]="folderOwners" [isDuplicate]="isDuplicate"
	[logAction]="logAction" [folderSelected]="folderCreate" [modalData]="modalAddDashboard" (onSubmitData)="onChangeDashboardData($event)">
</pivot-dialog-dashboard-editor>