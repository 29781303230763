import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ResponseData } from '../../../app/models/api-model';
import { Team } from '../../../app/models/response/team.ro';
import { API_APP, PIVOT_CLIENT_API_URL } from '../../config/app.config';
import { ApiService } from '../api.service';
import {ErrorHandleService} from '../error-handle.service';
import { SAUCER_LOG_ACTION, SaucerLogService } from '../saucer-logs/saucer-log.service';



@Injectable({ providedIn: 'root' })
export class TeamService extends ApiService {
  public sessionTeamData: any = null;
  constructor(
    http: HttpClient,
    router: Router, 
    errorHandle: ErrorHandleService,
    private saucerLogService : SaucerLogService
  ) {
    super(http,router, errorHandle);
  }

  async getAll() {
    let apiUrl = API_APP.TEAM.CONTROLLER + API_APP.TEAM.ALL;
    let result = await this.get(apiUrl);
    this.sessionTeamData = result?.data || [];
    return this.mapModel(result);
  }

  getDetail(code: string) {
    let parameter = [
      code
    ];
    let apiUrl = API_APP.TEAM.CONTROLLER + API_APP.TEAM.DETAIL;
    return this.get(apiUrl, parameter);
  }

  mapModel(data: any) {
    let res: ResponseData<Team[]> = new ResponseData<Team[]>();
    let dataArr = [];
    res.statuscode = data.statuscode;
    res.statusmessage = data.statusmessage;
    if (res.statuscode === 200) {
      for (const element of data.data) {
        let dash = new Team();
        dash.id = element.id;
        dash.teamCd = element.teamcd;
        dash.teamName = element.teamname;
        dash.insstfcd = element.insstfcd;
        dash.insdate = element.insdate;
        dash.updstfcd = element.updstfcd;
        dash.upddate = element.upddate;
        dataArr.push(dash);
      }
    }
    res.data = dataArr;
    return res;
  }

  async insertOrUpdate(teams: Team[], isUpdate: boolean) {
    let uri = API_APP.TEAM.CONTROLLER + API_APP.TEAM.INSERTORUPDATE + "?isUpdate=" + isUpdate;
    let resData = await this.post(uri, [], teams);

    let apiPath = uri.replace(PIVOT_CLIENT_API_URL, "");
    const ids = [...teams].map(item => item.teamCd);
    const oldUpdateDatas: any = [...this.sessionTeamData || []].filter((item: any) => ids.includes(item?.teamcd)) || [];
    const createData = JSON.stringify({
      old: null,
      new: teams
    });
    const editData = JSON.stringify({
      old: oldUpdateDatas,
      new: teams
    });
    if (resData.statuscode === 200) {
      this.saucerLogService.system(
        {
          apiPath,
          body: resData,
          statusCode: resData.statuscode,
          content: !isUpdate ? createData : editData
        },
        {
          action: !isUpdate ? SAUCER_LOG_ACTION.STAFF_INFO.CREATE_GROUP : SAUCER_LOG_ACTION.STAFF_INFO.UPDATE_GROUP
        }
      );
    } else {
      this.saucerLogService.error(
        {
          apiPath,
          body: resData,
          statusCode: resData.statuscode,
          content: !isUpdate ? createData : editData
        },
        {
          action: !isUpdate ? SAUCER_LOG_ACTION.STAFF_INFO.CREATE_GROUP : SAUCER_LOG_ACTION.STAFF_INFO.UPDATE_GROUP
        }
      );
    }


    return resData;
  }

  async delete(teams: Team[]) {
    let uri = API_APP.TEAM.CONTROLLER + API_APP.TEAM.DELETE;
    let apiPath = uri.replace(PIVOT_CLIENT_API_URL, "");
    let resData = await this.post(uri, [], teams);
    const ids = [...teams].map(item => item.teamCd);
    const oldDeleteDatas = [...this.sessionTeamData || []].filter((item: any) => ids.includes(item?.teamcd)) || [];
    if (resData.statuscode === 200) {
      this.saucerLogService.system({
        apiPath,
        body: resData,
        statusCode: resData.statuscode,
        content: JSON.stringify({
          old: {items: oldDeleteDatas},
          new: null
        })
      }, {action: SAUCER_LOG_ACTION.STAFF_INFO.DELETE_GROUP});
    } else {
      this.saucerLogService.error({
        apiPath,
        body: resData,
        statusCode: resData.statuscode,
        content: JSON.stringify({
          old: {items: oldDeleteDatas},
          new: null
        })
      }, {action: SAUCER_LOG_ACTION.STAFF_INFO.DELETE_GROUP});
    }

    return resData;
  }

  insertTeamDetail(teams: Team[], staffCd: string) {
    let uri = API_APP.TEAM.CONTROLLER + API_APP.TEAM.INSERTTEAMDETAIL + "?staffInsCd=" + staffCd;
    return this.post(uri, [], teams);
  }

  insertListStaffToTeamDetail(team: Team,listStaffInsCd: any ){
    let parameter = {
      team :team,
      listStaffInsCd: listStaffInsCd
    };
    let uri = API_APP.TEAM.CONTROLLER + API_APP.TEAM.INSERTLISTTEAMDETAIL;
    return this.post(uri, [], parameter);
  }

  deleteTeamDetail(teams: Team[], staffCd: string) {
    let uri = API_APP.TEAM.CONTROLLER + API_APP.TEAM.DELETETEAMDETAIL + "?staffDelCd=" + staffCd;
    return this.post(uri, [], teams);
  }
  
  removeListStaffOfTeamDetail(team: Team[],listStaffInsCd: any){
    let parameter = {
      team :team,
      listStaffInsCd: listStaffInsCd
    };
    let uri = API_APP.TEAM.CONTROLLER + API_APP.TEAM.REMOVELISTTEAMDETAIL;
    return this.post(uri, [], parameter);
  }
}
