import { Observable } from 'rxjs';
import { CanDeactivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { DataSourceSettingComponent } from '../../module/data-source-setting/data-source-setting.component';
import { ROUTE_PATH } from '../../const/route-path';
export class CanDeactivateDataSourceTemplate implements CanDeactivate<DataSourceSettingComponent> {
    constructor() {
    }
    canDeactivate(component: DataSourceSettingComponent,
        currentRoute: ActivatedRouteSnapshot,
        currentState: RouterStateSnapshot,
        nextState?: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
          if (nextState?.url === ("/" + ROUTE_PATH.HOME) ||
          (nextState?.url === ("/" + ROUTE_PATH.CHOOSE_CORP)||
          nextState?.url === ("/" + ""))) return false;
        return true;
    }
}

