import { Component, OnInit, Renderer2, ViewEncapsulation } from '@angular/core';
import { BUTTON, COMMON_TEXT, DEFAULT_VALUE_SETTING, SYSTEM_SETTING } from '../../../const/text-common';
import { ButtonItem, IconModal, InputParams, ModalParam, SearchParams } from '../../../models/common-model';
import { ButtonIconType, ButtonType, DataType, DialogType, GuardsMode, SaveType, SearchType } from '../../../enum/common-enum';
import { TableData } from '../../../models/table-model';
import { DEFAULT_VALUE } from '../../../const/table-col-define';
import { cloneDeep, orderBy } from 'lodash';
import { DialogService } from 'primeng/dynamicdialog';
import { ConfirmUnsavedDataDialogComponent } from '../../../component/common/confirm-unsaved-data-dialog/confirm-unsaved-data-dialog.component';
import { Subscription } from 'rxjs';
import Utils from 'src/app/util/utils';
import { DialogQuestionRoleNewValueEnteredComponent } from '../dialog-question-role-new-value-entered/dialog-question-role-new-value-entered.component';
import { Office } from '../../../models/response/office.ro';
import { ErrorHandleService } from '../../../services/error-handle.service';
import { FUNCTION_TITLE_TEXT } from '../../../const/error-text';
import { ProcessLoadingService } from 'src/app/services/loading.service';
import { DefaultValueSettingService } from 'src/app/services/modules/default-value-setting.service';
import { DefaultValueSetting, DefaultValueSettingDetail, DefaultValueSettingRO } from 'src/app/models/response/default-value-setting.ro';
import { v4 } from 'uuid';
import { MESSAGE_TEXT } from 'src/app/const/message';
import { OfficeAPIService } from 'src/app/services/modules/office.service';
import { DefaultValueSettingDTO } from 'src/app/models/request/default-value-setting.dto';
import { focusInput, getConfigTable, updateEditItem, updateSelected } from 'src/app/_helper/default-value-setting-helper';
import { DialogModalComponent } from '../../../component/common/dialog-modal/dialog-modal.component';
import { ConfirmDialogComponent } from '../../../component/common/confirm-dialog/confirm-dialog.component';
import { SAUCER_LOG_ACTION } from 'src/app/config/saucer-log.config';
import { SaucerLogService } from 'src/app/services/saucer-logs/saucer-log.service';
import { ProcessLoadingStackService } from 'src/app/services/loading-stack.service';

@Component({
  selector: 'pivot-default-value-setting',
  templateUrl: './default-value-setting.component.html',
  styleUrls: ['./default-value-setting.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class DefaultValueSettingComponent implements OnInit {

  // Common alias

  SYSTEM_SETTING: any = SYSTEM_SETTING;
  DEFAULT_VALUE_SETTING = DEFAULT_VALUE_SETTING;
  MESSAGE_TEXT = MESSAGE_TEXT;
  BUTTON: any = BUTTON;
  COMMON_TEXT = COMMON_TEXT;
  buttonType = ButtonType;
  GuardsMode = GuardsMode;
  iconTypePlus = ButtonIconType.PLUS
  iconTypeClose = ButtonIconType.CLOSE
  newRow: DefaultValueSetting = {
    id: "",
    isChecked: false,
    isEditing: true
  };
  kbnTagGroups: any[] = [];
  // Search component config
  searchConfig: SearchParams = {
    type: SearchType.input,
    placeholder: COMMON_TEXT.SEARCH,
    cssStyle: { height: '40px', margin: '10px 5px 10px 0px' },
    readonly: false,
    disabled: false,
    maxLength: 50
  };

  // Selected item of left list
  selectedItem: any;
  // Config of left list
  defaultValueSettingConfig: TableData;
  inputParams: InputParams = new InputParams();
  // Listbox behavior guard
  guards: GuardsMode = GuardsMode.IDLE;
  // Edit variable
  editItem: DefaultValueSetting;
  // Confirm dialog ref
  confirmDialogRef: any;
  refController: Subscription;

  isShowOffice: boolean = false;
  allOffices: Office[] = []
  officeList: Office[] = [];
  originalOfficeList: Office[] = [];
  listItemConditionActive: DefaultValueSettingDetail[] = [];
  listItemCondition: DefaultValueSettingDetail[] = [];
  originalListItemCondition: DefaultValueSettingDetail[] = [];
  defaultValueSettingList: DefaultValueSetting[] = [];
  alldefaultValueSettingList: DefaultValueSettingRO[] = [];
  inValid: boolean = false;
  orginalInvalid: boolean = false;
  isDisableSaveBtn = true;
  isDisableOfficeBtn = false;

  //#region Log
  SAUCER_LOG_ACTION = SAUCER_LOG_ACTION;
  //#endregion Log

  constructor(
    private _dialogSrv: DialogService,
    private errorHandleService: ErrorHandleService,
    private loadingService: ProcessLoadingService,
    private processLoadingStackService: ProcessLoadingStackService,
    private defaultValueSettingService: DefaultValueSettingService,
    private officeService: OfficeAPIService,
    private modalService: DialogService,
    private saucerLogService: SaucerLogService
  ) {

   }


  async ngOnInit(): Promise<void> {
    this.processLoadingStackService.loadingSomething('default-value-setting', true);
    // Textbox config
    this.inputParams.placeholder = MESSAGE_TEXT.PLEASE_ENTER;
    // Init data for left side
    this.defaultValueSettingConfig = getConfigTable();
    // this.testData();
    var resDefaultValueSettingList = await this.defaultValueSettingService.getAll();
    if (resDefaultValueSettingList && resDefaultValueSettingList.statuscode == 200) {
      this.alldefaultValueSettingList =  resDefaultValueSettingList.data ?? [];
      this.defaultValueSettingList = this.alldefaultValueSettingList.map((item: DefaultValueSettingRO) => item.defaultValueSetting)
                                    .filter((item): item is DefaultValueSetting => item !== undefined) || [];
    }
    this.defaultValueSettingConfig.body = cloneDeep(this.defaultValueSettingList);
    // sort body by insert date
    this.sortBodyValueSettingConfigByDate();
    this.allOffices = await this.officeService.getAllOfficeService();
    // Handle first load
    if(this.defaultValueSettingConfig.body.length   > 0) {
      this.defaultValueSettingConfig.body[0].isChecked = true;
      this.selectedItem = this.defaultValueSettingConfig.body[0];
      await this.loadDefaultValueSetting(this.selectedItem);
    }
    this.processLoadingStackService.loadingSomething('default-value-setting', false);
  }

  generateConfirmSaveDialog() {
    this.confirmDialogRef = this._dialogSrv.open(ConfirmUnsavedDataDialogComponent, {
      header: COMMON_TEXT.CONFIRM_NOT_SAVE_DATA,
      width: '35%'
    });
  }

  resetAllCheckedItem(){
    if (this.guards == GuardsMode.ADD) {
      // reset detail data
      this.changeEditRow(this.newRow);
    }
    // reset all checked item
    this.defaultValueSettingConfig.body.forEach((d: any) => {
      d.isChecked = false;
    });
  }

  async onClickItem(row: any) {
    if (this.guards == GuardsMode.EDIT || this.guards == GuardsMode.ADD) {
      this.generateConfirmSaveDialog();
      this.refController =  this.confirmDialogRef.onClose.subscribe(this.handleConfirmDialogSave.bind(this, row, GuardsMode.IDLE))
      return;
    }
    await this.selectRow(row);


  }

  onShowEdit(data: any) {
    // First edit action
    if (this.guards == GuardsMode.IDLE) {
      this.guards = GuardsMode.EDIT;
      // Hold editing row's data
      data.isEditing = true;
      this.editItem = cloneDeep(data);
      this.selectRow(data);
      focusInput(this.editItem.id, document);
      return;
    }

    // Confirm save or move to new row
    this.generateConfirmSaveDialog();
  
    this.refController =  this.confirmDialogRef.onClose.subscribe(this.handleConfirmDialogSave.bind(this, data, GuardsMode.EDIT))
  }
  async selectRow(row: any){
    this.isDisableOfficeBtn = false;
    var idCheck = this.defaultValueSettingConfig.body.find((d: any) => d.isChecked)?.id;
    updateSelected(row, this.defaultValueSettingConfig);

    if (idCheck != row.id) {
      this.selectedItem = row;
      this.loadingService.isLoading.emit(true);
      await this.loadDefaultValueSetting(this.selectedItem);
      this.loadingService.isLoading.emit(false);
    } 
  }
  async handleConfirmDialogSave( data: any, guardSet: any, result: any) {
    if (result == SaveType.CANCEL || !result) {
      this.refController.unsubscribe();
      let itemSelect = this.defaultValueSettingConfig.body.find((item: any)=>item.id == this.editItem.id);
      this.selectRow(itemSelect);
      this.selectedItem = itemSelect;
      return
    }
    else if (result == SaveType.NO) {
      // Not save, move to select row
      // Is on processing, but not save => remove new row
      if (this.guards == GuardsMode.ADD) {
        this.defaultValueSettingConfig.body.pop();
      } 
      this.selectRow(data);
    }
    else if (result == SaveType.SAVE) {
      // Save, after that go to select row
      // Update list --TODO
      if (!this.validateItem()) {
        this.showValidateNotifyDialog();
        return;
      }
      await this.saveRowEdit();

    }

    if(guardSet == GuardsMode.EDIT) {
      this.changeEditRow(data);
      focusInput(this.editItem.id, document);
    } else {
      updateEditItem(null, this.defaultValueSettingConfig);
      let itemSelect = this.defaultValueSettingConfig.body.find((item: any)=>item.id == data.id);
      this.selectRow(itemSelect);
      this.selectedItem = itemSelect;
    }
    this.guards = guardSet;
    this.refController.unsubscribe();
  }

  async saveRowEdit() {
    //--TODO
    // Call save api and update list
    // use this.onEditItem
    this.loadingService.isLoading.emit(true);
    if (this.guards === GuardsMode.ADD) {
      if (!this.validateItem()) {
        this.showValidateNotifyDialog();
        return;
      }

      let defaultValueSettingRequest:DefaultValueSettingDTO = {
        defaultvaluesetting: this.editItem,
        listdefaultvaluesettingdetail: [],
        isupdate: false
      };
  
      let reponse = await this.defaultValueSettingService.UpdateName(defaultValueSettingRequest, null);
      reponse.data.isChecked = true;
  
      this.defaultValueSettingConfig.body = this.defaultValueSettingConfig.body.map((e: any) => {
        return (e.id === this.editItem.id) ? { ...reponse.data} : { ...e };
      });
      // sort body by insert date
      this.sortBodyValueSettingConfigByDate();
      let clearData = cloneDeep(this.editItem);
      clearData.id = "reset-cd";
  
      updateEditItem(clearData, this.defaultValueSettingConfig);
      this.selectedItem =  this.defaultValueSettingConfig.body.find((e:any) => e.id === reponse.data.id);
      this.selectRow(reponse.data);
      
      this.guards = GuardsMode.IDLE;
      this.openDialogSuccess(DialogType.save);
    }
    else {
      if (!this.validateItem()) {
        this.showValidateNotifyDialog();
        return;
      }
     
      
      let defaultValueSettingRequest:DefaultValueSettingDTO = {
        defaultvaluesetting: this.editItem,
        listdefaultvaluesettingdetail: [],
        isupdate: this.guards == GuardsMode.EDIT
      };
      const oldLog = this.defaultValueSettingConfig.body.find(x => x.id == defaultValueSettingRequest?.defaultvaluesetting?.id);
      let reponse = await this.defaultValueSettingService.UpdateName(defaultValueSettingRequest, oldLog);
      reponse.data.isChecked = true;
  
      this.defaultValueSettingConfig.body = this.defaultValueSettingConfig.body.map((e: any) => {
        return (e.id === this.editItem.id) ? { ...reponse.data} : { ...e };
      });
  
      let clearData = cloneDeep(this.editItem);
      clearData.id = "reset-cd";
  
      updateEditItem(clearData, this.defaultValueSettingConfig);
      this.selectedItem =  this.defaultValueSettingConfig.body.find((e:any) => e.id === reponse.data.id);
      this.selectRow(reponse.data);
      
      this.guards = GuardsMode.IDLE;
      this.openDialogSuccess(DialogType.update);


    }
    this.loadingService.isLoading.emit(false);
  }

  // Add new row at end of list
  async onAddNewRow() {
    // Has new row on process
    if (this.guards == GuardsMode.ADD) return;

    if (this.guards != GuardsMode.IDLE) {
      // Add mode so scroll to bottom(new row add here)
      this.generateConfirmSaveDialog();
      // Handle user response
      this.refController = this.confirmDialogRef.onClose.subscribe(async (result: any) => {
        if (result == SaveType.CANCEL || !result) {
          this.refController.unsubscribe();
          return
        }

        if (result == SaveType.SAVE) {
          // Save, after that go to select row
          // Update list --TODO
          // Move to user selected row
          if (!this.validateItem()) {
            this.showValidateNotifyDialog();
            return;
          }
          await this.saveRowEdit();
        }

        this.addRowToList();
        this.changeEditRow(this.newRow);
        this.guards = GuardsMode.ADD;
        this.refController.unsubscribe();
      });
    }
    else {
      this.addRowToList();
      this.guards = GuardsMode.ADD;
    }
  }

  // Save change(edit/create row)
  async onRowSaveChange() {
    await this.saveRowEdit()
  }

  // Delete default value
  async onDeleteTeam(event: any) {
    // Confirm user action
    this.initConfirmDeleteDialog(event);
  }

  // Cancel row current changes
  rowCancel(row: any) {
    // Remove last item
    if (this.guards == GuardsMode.ADD) {
      this.defaultValueSettingConfig.body.pop();
    } else {
      this.selectRow(row);
      this.defaultValueSettingConfig.body.forEach((d: any) => {
        if (d.isEditing) {
          d.isChecked = true;
        } else {
          d.isChecked = false;
        }
        d.isEditing = false;
      });
    }
    this.isDisableOfficeBtn = !this.officeList?.length;
    this.guards = GuardsMode.IDLE;
  }

  // Update row selected and editing row flag
  changeEditRow(data: any) {
    this.selectRow(data);
    updateEditItem(data, this.defaultValueSettingConfig);
    this.editItem = cloneDeep(data);
  }

  // Add empty row at end of list, scroll to bottom
  async addRowToList() {
    // Change logic => has many cloneDeep(this is demo, not final logic)
    let tempTable = cloneDeep(this.defaultValueSettingConfig);
    this.newRow.id = v4().toString();
    tempTable.body.push(cloneDeep(this.newRow));
    this.defaultValueSettingConfig = tempTable;
    this.editItem = cloneDeep(this.newRow);
    await this.selectRow(this.newRow);
    updateEditItem(this.newRow, this.defaultValueSettingConfig);
    focusInput(this.newRow.id, document);
    setTimeout(() => this.scrollToLastestItem(), 300);
  }

  scrollToLastestItem() {
    const element = document.getElementById('lastestToScroll');
    if (!!element && !!(element.offsetWidth || element.offsetHeight)) {
      element.scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'nearest' });
    }
  }

  validateItem() {
    return !Utils.isNullOrEmpty(this.editItem.defaultvaluesettingname);
  }

  showValidateNotifyDialog() {
    this.loadingService.isLoading.emit(false);
    this._dialogSrv.open(DialogQuestionRoleNewValueEnteredComponent, {
      header: COMMON_TEXT.CONFIRM_NOT_SAVE_DATA,
      width: '35%'
    });
  }

  showOfficeModal() {
    if(!(this.allOffices && this.allOffices.length) || this.guards == GuardsMode.ADD) return;

    this.errorHandleService.setFunctionTitle(FUNCTION_TITLE_TEXT.SCREEN_INIT_FAIL);
    this.isShowOffice = true;

    //Log
    this.saucerLogService.action({
      content: JSON.stringify({
        conditionCd: this.selectedItem?.defaultvaluesettingcd,
        conditionName: this.selectedItem?.defaultvaluesettingname,
      })
    }, { action: SAUCER_LOG_ACTION.DEFAULT_SETTING.SELECT_BUSINESS_LOCATION });
  }

  closeOfficeModal() {
    this.isShowOffice = false;
  }

  async changeOffices(offices: any) {
    this.loadingService.isLoading.emit(true);
    if (JSON.stringify(offices) === JSON.stringify(this.officeList)) {
      this.loadingService.isLoading.emit(false);
      return
    }
    else {
      this.officeList = offices;

      let titleMappingRequest = {
        officeIds: this.officeList.map((office: any) => { return office.officeCd; }),
      }
      this.listItemCondition.map((item: DefaultValueSettingDetail) => {item.delflg = true});
      this.listItemConditionActive =  this.listItemCondition.filter((item: DefaultValueSettingDetail) => !item.delflg);

      this.kbnTagGroups = await this.defaultValueSettingService.getDataTTLTag(titleMappingRequest);

      this.loadingService.isLoading.emit(false);
    }
  }

  onClickBtnAdd() {
    if(!this.officeList.length || this.guards == GuardsMode.ADD) return;

    let itemConditopn: DefaultValueSettingDetail = {
      id: v4().toString(),
      delflg: false,
      invalidKbntag: false,
      invalidTtltag: false,
    }
    this.listItemCondition.push(itemConditopn);
    this.listItemConditionActive =  this.listItemCondition.filter((item: DefaultValueSettingDetail) => !item.delflg);
    this.validateSaveBtn();

    //Log
    this.saucerLogService.action(
      {
        content: JSON.stringify({
          conditionCd: this.selectedItem?.defaultvaluesettingcd,
          conditionName: this.selectedItem?.defaultvaluesettingname,
          officeCd: this.officeList?.map(x => x.officeCd),
        })
      }, 
      {
         action: SAUCER_LOG_ACTION.DEFAULT_SETTING.ADD_TITLE_TAG 
      }
    );
  }

  onClickBtnCancel() {
    if(this.guards == GuardsMode.ADD) return;

    this.listItemCondition = cloneDeep(this.originalListItemCondition);
    this.listItemConditionActive =  cloneDeep(this.originalListItemCondition);
    this.officeList = cloneDeep(this.originalOfficeList);
    this.inValid = cloneDeep(this.orginalInvalid);

    //Log
    this.saucerLogService.action({
      content: JSON.stringify({
        conditionCd: this.selectedItem?.defaultvaluesettingcd,
        conditionName: this.selectedItem?.defaultvaluesettingname,
        officeCd: this.officeList?.map(x => x.officeCd),
      })
    }, { action: SAUCER_LOG_ACTION.DEFAULT_SETTING.CANCEL_BUTTON });
  }

  async onClickBtnSave() {
    if(this.isDisableSaveBtn || this.guards == GuardsMode.ADD) return;
    if(this.guards == GuardsMode.EDIT){
      await this.saveRowEdit();
    }

    this.loadingService.isLoading.emit(true);
    this.selectedItem.officeidlst = this.officeList.map((office: any) => { return office.officeCd; }).join('-');
    this.selectedItem.invalidflg = this.inValid;
    let defaultValueSettingRequest:DefaultValueSettingDTO = {
      defaultvaluesetting: this.selectedItem,
      listdefaultvaluesettingdetail: this.listItemCondition,
      isupdate: true
    };
    const newLog = {
      conditionCd: this.selectedItem?.defaultvaluesettingcd,
      conditionName: this.selectedItem?.defaultvaluesettingname,
      officeCd: this.officeList?.map(x => x.officeCd),
      invalidflg: this.inValid,
      listdefaultvaluesettingdetail: this.listItemCondition.filter(x => !x.delflg && x.kbntag && x.ttltag)
      .map(x => ({
        kbntag: x.kbntag,
        ttltag: x.ttltag
      }))
    }
    const oldLog = {
      conditionCd: this.selectedItem?.defaultvaluesettingcd,
      conditionName: this.selectedItem?.defaultvaluesettingname,
      officeCd: this.originalOfficeList?.map(x => x.officeCd),
      invalidflg: this.orginalInvalid,
      listdefaultvaluesettingdetail: this.originalListItemCondition.filter(x => !x.delflg && x.kbntag && x.ttltag)
      .map(x => ({
        kbntag: x.kbntag,
        ttltag: x.ttltag
      }))
    }

    await this.defaultValueSettingService.insertOrUpdate(defaultValueSettingRequest, oldLog, newLog);
    //Original
    const valueSettingList = this.alldefaultValueSettingList.find(x => x.defaultValueSetting?.id == this.selectedItem.id);
    if(valueSettingList) {
      valueSettingList.listDefaultValueSettingDetail = this.listItemCondition;
    } else {
      //Add new defaultValueSettingConfig
      this.alldefaultValueSettingList.push({
        defaultValueSetting: this.selectedItem,
        listDefaultValueSettingDetail: this.listItemCondition
      });
    }
    //update lai lst
    await this.loadDefaultValueSetting(this.selectedItem);
    this.loadingService.isLoading.emit(false);
    this.openDialogSuccess(DialogType.save);


  }

  onChangeItem(event: any) {
    const index = this.listItemCondition.findIndex(item => item.id == event.id);
    if (index !== -1) {
      this.listItemCondition[index] = event;
    }
    this.validateSaveBtn();
  }

  onDeleteItem(event: any){
    const index = this.listItemCondition.findIndex(item => item.id == event.id);
    if (index !== -1) {
      this.listItemCondition[index].delflg = true;
      this.listItemConditionActive =  this.listItemCondition.filter((item: DefaultValueSettingDetail) => !item.delflg);
    }
    this.validateSaveBtn();

    //Log
    const itemDelete = this.listItemCondition.find(item => item.id == event.id);
    if(itemDelete) {
      this.saucerLogService.action({
        content: JSON.stringify({
          conditionCd: this.selectedItem?.defaultvaluesettingcd,
          conditionName: this.selectedItem?.defaultvaluesettingname,
          officeCd: this.officeList?.map(x => x.officeCd),
        })
      }, { action: SAUCER_LOG_ACTION.DEFAULT_SETTING.DELETE_TITLE_TAG });
    }
  }

  validateSaveBtn(){
    const markInvalidItems = this.markInvalidItems(this.listItemConditionActive);
    this.listItemConditionActive = cloneDeep(markInvalidItems);

    if(this.listItemConditionActive.some(x => x.invalidKbntag || x.invalidTtltag)) {
      this.isDisableSaveBtn = true;
      return;
    }


    if (this.listItemConditionActive.length <= 0) {
      this.isDisableSaveBtn = true;
    } else {
      for (let i = 0; i < this.listItemConditionActive.length; i++) {
        const item = this.listItemConditionActive[i];
        if (item?.kbntag && item?.ttltag) {
          this.isDisableSaveBtn = false;
        } else {
          this.isDisableSaveBtn = true;
        }
      }
    }
  }

  async loadDefaultValueSetting(defultValueSetting: any){
    let officeListDf:any[] =[];
    if (!Utils.isNullOrEmpty(defultValueSetting.officeidlst)) {
      let offices = defultValueSetting.officeidlst.split("-");
      if (!Utils.isNullOrEmpty(offices)) {
        officeListDf = this.allOffices.filter((o: any) => {
          return offices.filter((code: string) => code === o.officeCd).length > 0;
        }).map((item: any) => (
            {
              officeCd: item.officeCd,
              officeNm: item.officeNm
            }
        ));
        officeListDf = orderBy(officeListDf, ["officeNm"]);
      }
    }else{
      officeListDf = [];
    }

    if( officeListDf.length > 0 ){
      let titleMappingRequest = {
        officeIds: officeListDf.map((office: any) => { return office.officeCd; }),
      }
      this.kbnTagGroups = await this.defaultValueSettingService.getDataTTLTag(titleMappingRequest);
    }
    this.officeList = officeListDf;
    this.originalOfficeList = cloneDeep(this.officeList);
    const listItemCondition = this.alldefaultValueSettingList.find(x=>x.defaultValueSetting?.id == defultValueSetting.id)?.listDefaultValueSettingDetail ?? []
    this.listItemCondition = cloneDeep(listItemCondition);
    this.originalListItemCondition = cloneDeep(this.listItemCondition);
    this.listItemConditionActive =  this.listItemCondition.filter((item: DefaultValueSettingDetail) => !item.delflg);
    this.inValid =  this.selectedItem.invalidflg;
    this.orginalInvalid = cloneDeep(this.inValid);
    this.validateSaveBtn();
  }

  changeValid(){
    if(this.guards == GuardsMode.ADD || !this.defaultValueSettingConfig?.body?.length || this.isDisableOfficeBtn) return;
    this.inValid = !this.inValid

    //
    
    //Log
    this.saucerLogService.action({
      content: JSON.stringify({
        old: {
          conditionCd: this.selectedItem?.defaultvaluesettingcd,
          conditionName: this.selectedItem?.defaultvaluesettingname,
          inValid: !this.inValid 
        },
        new: {
          conditionCd: this.selectedItem?.defaultvaluesettingcd,
          conditionName: this.selectedItem?.defaultvaluesettingname,
          inValid: this.inValid 
        },
      })
    }, { action: SAUCER_LOG_ACTION.DEFAULT_SETTING.UPDATE_INVALID_CONDITION });
  }

  openDialogSuccess(dialogType: DialogType) {
    this.modalService.open(ConfirmDialogComponent, {
      data: {
        dialogType: dialogType,
      }
    });
  }

  sortBodyValueSettingConfigByDate() {
    const parseDate = (dateStr: string): Date => new Date(dateStr);
    this.defaultValueSettingConfig.body = this.defaultValueSettingConfig?.body?.sort((a, b) => parseDate(a?.insdate).getTime() - parseDate(b?.insdate).getTime());
  }

  initConfirmDeleteDialog(event: any) {
    // Create new question icon
    let icon = new IconModal();
    icon.iconName = "pi-question";
    icon.iconColor = "#0073BA";
    icon.isSmallSize = false;

    // Create OK button
    let btnOk = new ButtonItem();
    btnOk.buttonText = COMMON_TEXT.YES;
    btnOk.buttonType = ButtonType.SECONDARY;

    // Create cancel button
    let btnCancel = new ButtonItem();
    btnCancel.buttonText = COMMON_TEXT.NO;
    btnCancel.buttonType = ButtonType.SECONDARY;

    // Create and config dialog
    let modalParam = new ModalParam();
    modalParam.description = MESSAGE_TEXT.CONFIRM_DELETE_DEFAULT_VALUE;
    modalParam.icon = icon;
    modalParam.buttonArray = [btnOk, btnCancel];
    const confirmDialog = this._dialogSrv.open(DialogModalComponent, {
      header: COMMON_TEXT.CONFIRM,
      width: '30em',
      data: { modalParam },
    });

    // Handle cancel action
    btnCancel.clickFunction = () => {
      confirmDialog.destroy();
    };

    // Handle for Delete action
    btnOk.clickFunction = async () => {
      // Call api delete and update defaultValConfig
      this.loadingService.isLoading.emit(true);
      this.selectedItem.delflg = true;
      let defaultValueSettingRequest:DefaultValueSettingDTO = {
        defaultvaluesetting: this.selectedItem,
        listdefaultvaluesettingdetail: this.listItemCondition,
        isupdate: true
      };

      const oldLog = {
        conditionCd: this.selectedItem?.defaultvaluesettingcd,
        conditionName: this.selectedItem?.defaultvaluesettingname,
        officeCd: this.originalOfficeList?.map(x => x.officeCd),
        invalidflg: this.orginalInvalid,
        listdefaultvaluesettingdetail: this.originalListItemCondition.filter(x => !x.delflg && x.kbntag && x.ttltag)
        .map(x => ({
          kbntag: x.kbntag,
          ttltag: x.ttltag
        }))
      }

      await this.defaultValueSettingService.insertOrUpdate(defaultValueSettingRequest, oldLog, null);
      this.defaultValueSettingConfig.body.forEach((item, index) => {
        if (item.id === event.id) {
          this.defaultValueSettingConfig.body.splice(index, 1);
        }
      });
      // Update default selected row
      if (this.defaultValueSettingConfig.body.length > 0) {
        this.defaultValueSettingConfig.body[0].isChecked = true;
        this.selectedItem = this.defaultValueSettingConfig.body[0];
        await this.loadDefaultValueSetting(this.selectedItem);
      } else {
        this.selectedItem = null;
        // reset detail data
        this.changeEditRow(this.newRow);
      }

    
      // Change mode to idle
      this.guards = GuardsMode.IDLE;
      this.loadingService.isLoading.emit(false);
      this.openDialogSuccess(DialogType.delete);
      confirmDialog.destroy();
    };
  }

  async onCloneConstantConfig() {

    if (this.guards == GuardsMode.EDIT || this.guards == GuardsMode.ADD) {
      this.generateConfirmSaveDialog();
      this.refController =  this.confirmDialogRef.onClose.subscribe(this.handleConfirmDialogSave.bind(this, this.selectedItem, GuardsMode.IDLE))
      return;
    }

    this.loadingService.isLoading.emit(true);
    const cloneR = await this.defaultValueSettingService.cloneByConstCd(this.selectedItem.defaultvaluesettingcd);
    if(cloneR.statuscode !== 200 || !cloneR.data) {
      this.loadingService.isLoading.emit(false);
      return;
    }

    // Push to current data
    this.alldefaultValueSettingList.push({
      defaultValueSetting: cloneR.data[0].defaultvaluesetting,
      listDefaultValueSettingDetail: cloneR.data[0].listdefaultvaluesettingdetail
    })
    if(cloneR.data[0].defaultvaluesetting) {
      this.defaultValueSettingConfig.body.push(cloneR.data[0].defaultvaluesetting);
      this.defaultValueSettingList.push(cloneR.data[0].defaultvaluesetting);
      await this.selectRow(cloneR.data[0].defaultvaluesetting);
    }

    const contentLog = {
      defaultvaluesetting: {
        defaultvaluesettingcd: cloneR.data[0].defaultvaluesetting.defaultvaluesettingcd,
        defaultvaluesettingname: cloneR.data[0].defaultvaluesetting.defaultvaluesettingname,
        invalidflg: cloneR.data[0].defaultvaluesetting.invalidflg,
        officeCd: this.originalOfficeList?.map(x => x.officeCd),
      },
      listdefaultvaluesettingdetail: cloneR.data[0].listdefaultvaluesettingdetail?.filter((x:any) => !x.delflg).map((item: any) => ({
        kbntag: item.kbntag,
        ttltag: item.ttltag
      }))
    }
    //Log
    this.saucerLogService.action({
      content: JSON.stringify(contentLog)
    }, { action: SAUCER_LOG_ACTION.DEFAULT_SETTING.COPY_BUTTON });

    this.loadingService.isLoading.emit(false);
  }

  markInvalidItems(items: DefaultValueSettingDetail[]): DefaultValueSettingDetail[] {
    const combinedMap: { [key: string]: number[] } = {};
  
    items.forEach((item) => {
      item.invalidKbntag = false;
      item.invalidTtltag = false;
    });

    items.forEach((item, index) => {
      const key = `${item.kbntag}-${item.ttltag}`;
      if (!combinedMap[key]) {
        combinedMap[key] = [];
      }
      combinedMap[key].push(index);
    });
  
    // Đánh dấu invalidKbntag và invalidTtltag nếu có trùng lặp
    for (const indices of Object.values(combinedMap)) {
      if (indices.length > 1) {
        indices.forEach((i) => {
          items[i].invalidKbntag = true;
          items[i].invalidTtltag = true;
        });
      }
    }
  
    return items;
  }


  onFilterTable(value: any) {
    this.guards = GuardsMode.IDLE;
    if (!value) {
      this.defaultValueSettingConfig.body = cloneDeep(this.defaultValueSettingList);
      //this.listRoles = cloneDeep(this.rolesOrigin);
      return;
    }
    this.defaultValueSettingConfig.body = cloneDeep(this.defaultValueSettingList.filter((df:DefaultValueSetting) => df.defaultvaluesettingname?.toLowerCase().includes(value.trim().toLowerCase())));

  }

}
