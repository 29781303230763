export class ResponseData<T = any> {
    statuscode: number = 200;
    statusmessage: string = '';
    data?: T;
}

export class Request<T = any> {
    data?: T;
}

export class ActivityLog {
    insstfcd?: string;
    insstfnm?: string;
    insdate?: Date;
    updstfcd?: string;
    updstfnm?: string;
    upddate?: Date;
    displayNm?: string;
    displayDate?: string;
}

export class BaseModel {
    id: string;
    delflg: boolean;
    insstfcd: string;
    updstfcd: string;
    insdate?: Date;
    upddate?: Date;
}
